import { Warehouse as WmsIcon } from '@mui/icons-material';
import { lazy } from 'react';
import { Routes } from 'routing/route';
import { RouteTypeMassive } from 'routing/routesTypes';

const WarehouseSelectionControls_TablePage = lazy(() =>
    import('pages/docs/warehouse-selection-controls/WarehouseSelectionControls_TablePage').then(
        (module) => ({
            default: module.WarehouseSelectionControls_TablePage
        })
    )
);
const WarehouseRemainsAccounting_TablePage = lazy(() =>
    import('pages/docs/warehouse-remains-accounting/WarehouseRemains_TablePage').then((module) => ({
        default: module.WarehouseRemainsAccounting_TablePage
    }))
);
const WarehouseRemains_TablePage = lazy(() =>
    import('pages/docs/warehouse-remains/WarehouseRemains_TablePage').then((module) => ({
        default: module.WarehouseRemains_TablePage
    }))
);
const WarehouseTasks_TablePage = lazy(() =>
    import('pages/docs/warehouse-tasks/WarehouseTasks_TablePage').then((module) => ({
        default: module.WarehouseTasks_TablePage
    }))
);
const WmsExecutorTasks_TablePage = lazy(() =>
    import('pages/docs/wms-executor-tasks/WmsExecutorTasks_TablePage').then((module) => ({
        default: module.WmsExecutorTasks_TablePage
    }))
);
const InboundOrders_TablePage = lazy(() =>
    import('pages/docs/wms-inbound-orders/InboundOrders_TablePage').then((module) => ({
        default: module.InboundOrders_TablePage
    }))
);
const WmsInboundOrders_TablePage = lazy(() =>
    import('pages/docs/wms-inbound-orders/WmsInboundOrders_TablePage').then((module) => ({
        default: module.WmsInboundOrders_TablePage
    }))
);
const OutboundOrders_TablePage = lazy(() =>
    import('pages/docs/wms-outbound-orders/OutboundOrders_TablePage').then((module) => ({
        default: module.OutboundOrders_TablePage
    }))
);
const WmsOutboundOrders_TablePage = lazy(() =>
    import('pages/docs/wms-outbound-orders/WmsOutboundOrders_TablePage').then((module) => ({
        default: module.WmsOutboundOrders_TablePage
    }))
);
const WmsSelectionWaves_TablePage = lazy(() =>
    import('pages/docs/wms-selection-waves/WmsSelectionWaves_TablePage').then((module) => ({
        default: module.WmsSelectionWaves_TablePage
    }))
);
const WarehouseQuotas_TablePage = lazy(() =>
    import('pages/docs/warehouse-quotas/WarehouseQuotas_TablePage').then((module) => ({
        default: module.WarehouseQuotas_TablePage
    }))
);
const WmsDocsOverviewPage = lazy(() =>
    import('pages/wms-docs/WmsDocsOverviewPage').then((module) => ({
        default: module.WmsDocsOverviewPage
    }))
);

const WmsDocApps: RouteTypeMassive[] = [
    {
        path: '',
        tablePage: WmsDocsOverviewPage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'inbound-orders',
        tablePage: InboundOrders_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-inbound-orders',
        tablePage: WmsInboundOrders_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'outbound-orders',
        tablePage: OutboundOrders_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-outbound-orders',
        tablePage: WmsOutboundOrders_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-selection-waves',
        tablePage: WmsSelectionWaves_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'warehouse-selection-controls',
        tablePage: WarehouseSelectionControls_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-executor-tasks',
        tablePage: WmsExecutorTasks_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-tasks',
        tablePage: WarehouseTasks_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-remains',
        tablePage: WarehouseRemains_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-remains-accounting',
        tablePage: WarehouseRemainsAccounting_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-quotas',
        tablePage: WarehouseQuotas_TablePage,
        config: {
            icon: <WmsIcon />
        }
    }
];

export const WmsDocsRoutes = new Routes('/warehouse-docs', WmsDocApps);
