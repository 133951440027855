import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions, Flex, Form, Input, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ValueType = { newPassword: string; confirmPassword: string };

interface PasswordFieldProps {
    value: string;
    onChange: (newValue: string) => void;
    onConfirmChange?: (newValue: string) => void;
    confirmValue?: string;
    labels?: Partial<ValueType>;
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
    value,
    onChange,
    labels,
    confirmValue,
    onConfirmChange
}) => {
    const { t } = useTranslation();
    const [passwordCopy, setPasswordCopy] = useState('');

    const [isUncorrect, setIsUncorrect] = useState(false);

    useEffect(() => {
        // if (value && value !== (confirmValue || passwordCopy)) setIsUncorrect(true);
        // else setIsUncorrect(false);

        if (!value) setIsUncorrect(false);
    }, [confirmValue, passwordCopy, value]);

    const popoverContent = (
        <Form style={{ maxWidth: 600 }}>
            <Descriptions
                bordered
                column={1}
                size="small"
                labelStyle={{ width: '30%' }}
                contentStyle={{ width: '70%' }}
                items={[
                    {
                        key: 'new_password',
                        label: labels?.newPassword || t('new_password'),
                        children: (
                            <Flex gap={5} style={{ width: '100%' }}>
                                <Input.Password
                                    id="new_password"
                                    name="new_password"
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    style={{ width: '66%' }}
                                />
                                <Button
                                    style={{ width: 'calc(34% - 5px)' }}
                                    onClick={() =>
                                        onChange(
                                            window.crypto
                                                .getRandomValues(new BigUint64Array(1))[0]
                                                .toString(36)
                                        )
                                    }
                                >
                                    {t('gen_password')}
                                </Button>
                            </Flex>
                        )
                    },
                    {
                        key: 'confirm_password',
                        label: labels?.confirmPassword || t('confirm_password'),
                        children: (
                            <Flex gap={5}>
                                <Input.Password
                                    onBlur={() =>
                                        setIsUncorrect(
                                            !!(value && value !== (confirmValue || passwordCopy))
                                        )
                                    }
                                    onFocus={() => setIsUncorrect(false)}
                                    status={isUncorrect ? 'error' : undefined}
                                    style={{ width: 'calc(66%)' }}
                                    type="password"
                                    id="confirm_password"
                                    name="confirm_password"
                                    value={confirmValue || passwordCopy}
                                    onChange={(e) =>
                                        onConfirmChange
                                            ? onConfirmChange(e.target.value)
                                            : setPasswordCopy(e.target.value)
                                    }
                                />
                                {isUncorrect ? (
                                    <Popover
                                        placement="bottom"
                                        content={t('not_equal_with_pass')}
                                        trigger="hover"
                                        destroyTooltipOnHide
                                    >
                                        <InfoCircleOutlined style={{ color: 'red' }} />
                                    </Popover>
                                ) : undefined}
                            </Flex>
                        )
                    }
                ]}
                // column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 }}
            />
        </Form>
    );

    return popoverContent;
};
