import { GlobalFilterType } from 'components/DataTable/useTableBackendFeatures';
import { MRT_FilterOption, MRT_PaginationState } from 'material-react-table';
import { useEffect, useState } from 'react';
import { Filter, OrderBy } from 'modules/supabase/utils/supabaseClient';
import { useTranslation } from 'react-i18next';
import {
    convertComplexFilterToSupabaseFilter,
    convertUIFilterColumnName,
    convertUIFilterValue,
    mapMUITableFilterOperationAndSupabaseFilterOperation
} from 'modules/supabase/utils/supabaseFilterUtils';
import { convertUISortingColumnName } from 'modules/supabase/utils/supabaseSortingUtils';
import { TableOptionType } from './useTableOption';

export type RequestOptionType = {
    filters: Filter[];
    sorting: OrderBy[];
    pagination: MRT_PaginationState;
    globalFilter: GlobalFilterType;
    needRequest: boolean;
};
type useTableRequestOptionPropsType = {
    tableOption: TableOptionType;
    vdfLoaded: boolean;
    filter?: Filter[];
};
const DEFAULT_FILTER_STATE: MRT_FilterOption = 'contains';

export const useTableRequestOption = ({
    tableOption,
    vdfLoaded,
    filter
}: useTableRequestOptionPropsType) => {
    const { i18n } = useTranslation();
    const [requestOption, setRequestOption] = useState<RequestOptionType>({
        filters: [],
        sorting: [],
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        globalFilter: {},
        needRequest: false
    });
    useEffect(() => {
        if (tableOption.initialized) {
            if (!vdfLoaded) {
                return;
            }

            const allFilters =
                tableOption?.columnFilters?.map((item) => {
                    const operator = mapMUITableFilterOperationAndSupabaseFilterOperation(
                        tableOption.columnFilterFns[item.id]
                            ? tableOption.columnFilterFns[item.id]
                            : DEFAULT_FILTER_STATE
                    );

                    return {
                        column: convertUIFilterColumnName(item.id, operator, i18n.language),
                        operator,
                        value: convertUIFilterValue(item.value, item.id, i18n.language, operator)
                    };
                }) || [];

            if (tableOption.complexFilter) {
                const addToFilter = convertComplexFilterToSupabaseFilter(
                    tableOption.complexFilter,
                    i18n.language
                );

                for (let i = 0; i < addToFilter.length; i++) {
                    allFilters.push(addToFilter[i]);
                }
            }

            const allSorting: OrderBy[] = tableOption.sorting.map((item) => ({
                column: convertUISortingColumnName(item.id, i18n.language),
                direction: item.desc ? 'desc' : 'asc'
            }));
            setRequestOption((options) => {
                return {
                    ...options,
                    filters: filter ? [...allFilters, ...filter] : allFilters,
                    sorting: allSorting,
                    pagination: tableOption.pagination,
                    needRequest: true,
                    globalFilter: tableOption.globalFilter
                };
            });
        }
    }, [i18n.language, tableOption, filter, vdfLoaded]);

    return requestOption;
};
