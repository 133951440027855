import { RealtimeChannel } from '@supabase/supabase-js';
import { useEffect, useMemo, useState } from 'react';
import { SupabaseRow, TableName, ViewOrTableName } from '../../types/Dataset';
import { supabaseClient } from '../../contexts/SupabaseContext/SupabaseContext';
import {
    UseSupabaseViewOrTableDataProps,
    UseSupabaseViewOrTableDataResult,
    useSupabaseViewOrTableData
} from './useSupabaseViewOrTableData';

type AutoRefreshOptionType = {};

type UseSupabaseViewOrTableDataOptionsWithAutoRefresh<
    T extends ViewOrTableName,
    K extends TableName
> = UseSupabaseViewOrTableDataProps<T> & {
    tableName?: K; // can be undefined if no needed subscription
};

export const useSupabaseViewOrTableDataWithAutoRefresh = <
    T extends ViewOrTableName,
    K extends TableName
>({
    tableName,
    viewOrTableName,
    refresh,
    filters,
    orderBy,
    pageNumber,
    pageSize,
    virtualization,
    setRefresh,
    globalFilter,
    doRequest = true,
    channelName = 'any',
    autoRefresh = true
}: UseSupabaseViewOrTableDataOptionsWithAutoRefresh<T, K>): UseSupabaseViewOrTableDataResult<
    SupabaseRow<T>[]
> => {
    // TODO: use reducer here and one complex state:
    // { data, isLoading, error, count, setPage, setFilters, setOrderBy }
    const [data, setData] = useState<SupabaseRow<T>[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [count, setCount] = useState(0);
    const options = useMemo(
        () => ({
            refresh,
            orderBy,
            pageNumber,
            pageSize,
            virtualization,
            filters,
            setRefresh,
            viewOrTableName,
            globalFilter,
            doRequest
        }),
        [
            filters,
            orderBy,
            pageNumber,
            pageSize,
            virtualization,
            refresh,
            setRefresh,
            viewOrTableName,
            globalFilter,
            doRequest
        ]
    );
    const fetchResult = useSupabaseViewOrTableData(options);
    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }

        // Moved state updates here
        if (!fetchResult.isLoading || refresh) {
            if (setRefresh) setRefresh(false);
            setData(fetchResult.data);
            setError(fetchResult.error);
            setCount(fetchResult.count);

            if (setRefresh && refresh) {
                setIsLoading(true);
            }
        }
        // Rest of your code...
    }, [tableName, viewOrTableName, fetchResult.isLoading, isLoading, refresh, fetchResult.data]); // Also add fetchResult to the dependency array

    useEffect(() => {
        let channel: RealtimeChannel | null;

        if (tableName && autoRefresh) {
            console.info(
                `useSupabaseViewOrTableDataWithAutoRefresh: subscribe to changes in ${tableName}`
            );
            // Если нужна подписка на данные
            channel = supabaseClient
                .channel(channelName)
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: tableName
                    },
                    async (payload) => {
                        console.info(
                            'useSupabaseViewOrTableDataWithAutoRefresh: got changes',
                            payload
                        );

                        switch (payload.eventType) {
                            case 'INSERT': {
                                const insertedId = payload.new.id;

                                console.log('REFRESH INSERT ID: ', insertedId);

                                if (viewOrTableName) {
                                    const result = await supabaseClient
                                        .from(viewOrTableName)
                                        .select('*')
                                        .filter('id', 'eq', insertedId);

                                    const newData = result.data ?? [];

                                    console.log('REFRESH INSERT DATA: ', newData);

                                    if (newData.length === 0) {
                                        console.error('Could not find inserted item in DB');
                                        return;
                                    }

                                    setData((prevData) => [...newData, ...prevData]);
                                }

                                break;
                            }
                            case 'UPDATE': {
                                const updatedId = payload.new.id;

                                if (viewOrTableName) {
                                    const result = await supabaseClient
                                        .from(viewOrTableName)
                                        .select('*')
                                        .filter('id', 'eq', updatedId);

                                    const newData = result.data ?? [];
                                    if (newData.length === 0) {
                                        console.error('Could not find updated item in DB');
                                        return;
                                    }
                                    const updatedItem = newData[0] as SupabaseRow<T>;
                                    setData((prevData) => {
                                        const index = prevData.findIndex(
                                            (item: any) => item.id === updatedId
                                        );
                                        if (index !== -1) {
                                            const updatedData = [...prevData];

                                            updatedData[index] = updatedItem;
                                            return updatedData;
                                        }
                                        console.log('Cannot find item by id');
                                        return prevData;
                                    });
                                }

                                break;
                            }
                            case 'DELETE': {
                                const deletedId = payload.old.id;
                                setData((prevData) => {
                                    const index = prevData.findIndex(
                                        (item: any) => item.id === deletedId
                                    );
                                    if (index !== -1) {
                                        const updatedData = [...prevData];
                                        updatedData.splice(index, 1);
                                        return updatedData;
                                    }
                                    console.log('Cannot find item by id');
                                    return prevData;
                                });
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }
                )
                .subscribe((status, err) => {
                    console.log(
                        `useSupabaseViewOrTableDataWithAutoRefresh: status=${status} err=${err}`
                    );
                });
            if (channel) {
                console.log('useSupabaseViewOrTableDataWithAutoRefresh: channel.subscribe()');
            } else {
                console.error('useSupabaseViewOrTableDataWithAutoRefresh: channel is null');
            }
        }

        return () => {
            // controller.abort();

            // cancel fetch
            if (channel) {
                // если была подписка на данные то тут ее отменяем
                channel.unsubscribe();
                console.log('useSupabaseViewOrTableDataWithAutoRefresh: channel.unsubscribe()');
            }
        };
    }, [tableName, viewOrTableName, autoRefresh]);

    return {
        data,
        isLoading: isLoading || fetchResult.isLoading,
        error,
        count
    };
};
