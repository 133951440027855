import { Col, Form, Modal, Row } from 'antd';
import {
    Dispatch,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithTooltips } from 'ui';

import {
    CamundaVariable,
    camundaStartProcess
} from 'modules/services/backend-functions/camunda-start-process';
import { useMessages } from 'utils/hooks';

import {
    CheckboxField,
    PrefixedSelectField,
    StepByStepWarehouseGeneratorField
} from 'components/fields';

import { DatasetWrapper } from 'components/DataTable/EditableObjects_DataTable/hocs/DatasetWrapper';
import { OperationsDetail_DataTable } from 'components/DataTable/OperationsDetail_DataTable';
import { TableRefType } from 'components/DataTable/useTableBackendFeatures';
import { GroupCollapse } from 'components/GroupdCollaps/GroupCollaps';
import { Dataset, TableRow } from 'modules/supabase/types/Dataset';
import { TableDef } from 'modules/supabase/utils/supabaseClient';
import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';
import { TareGroupDetail_DataTable } from 'pages/docs/warehouse-storage-locations/TareGroupDetail_DataTable';
import { useStoreNavigate } from 'utils/store';

const CAMUNDA_PROCESS = 'WarehouseStorageLocationsGenerator';

type StorageLocationGeneratorData = {
    warehouse_functional_purpose_code?: string;
    warehouse_storage_category_code?: string;
    warehouse_id?: number;
    warehouse_zone_id?: number;
    warehouse_aisle_id?: number;
    warehouse_row_from?: number;
    warehouse_row_to?: number;
    warehouse_column_from?: number;
    warehouse_column_to?: number;
    warehouse_level_from?: number;
    warehouse_level_to?: number;
    warehouse_storage_location_size_id?: number;
    should_track_rate_level2?: boolean;
    should_track_rate_level3?: boolean;
    flow_code?: string;
    can_have_few_cargo_units?: boolean;
    can_mix_products?: boolean;
};

type StorageLocationTablesRef = {
    GetOperations: () => string[];
    GetTareGroups: () => number[];
};

const tableDefs: TableDef[] = [
    {
        tableName: 'docs_warehouse_storage_locations',
        viewName: 'vdocs_warehouse_storage_locations'
    },

    {
        tableName: 'docs_warehouse_storage_location_operations',
        viewName: 'vdocs_warehouse_storage_location_operations'
    },
    {
        tableName: 'docs_warehouse_storage_location_tare_groups',
        viewName: 'vdocs_warehouse_storage_location_tare_groups'
    }
];

const StorageLocationTables = forwardRef<StorageLocationTablesRef, { open: boolean }>(
    ({ open }, ref) => {
        const [dataset, setDataset] = useDataset();

        useEffect(() => {
            setDataset((prev) => {
                const newDataset = new Dataset(
                    prev.root_id,
                    'edit',
                    prev.tables.map((table) => {
                        const newTable = { ...table, rows: [] };
                        return newTable;
                    })
                );
                return newDataset;
            });
        }, [open]);

        const operationRef = useRef<TableRefType>(null);
        const tareGroupRef = useRef<TableRefType>(null);

        useImperativeHandle(
            ref,
            () => {
                return {
                    GetOperations: (): string[] => {
                        const operations: string[] = [];

                        const operationTable = operationRef?.current?.data;

                        if (operationTable) {
                            for (let i = 0; i < operationTable.length; i++) {
                                operations.push(
                                    operationTable[i].warehouse_storage_location_operation_code
                                );
                            }
                        }

                        return operations;
                    },

                    GetTareGroups: () => {
                        const tareGroups: number[] = [];

                        const tareGroupsTable = tareGroupRef?.current?.data;

                        if (tareGroupsTable) {
                            for (let i = 0; i < tareGroupsTable.length; i++) {
                                tareGroups.push(tareGroupsTable[i].warehouse_tare_group_id);
                            }
                        }

                        return tareGroups;
                    }
                };
            },
            [operationRef, tareGroupRef]
        );

        return (
            <>
                <Row gutter={100}>
                    <Col md={24} lg={12}>
                        <GroupCollapse titleTextId="operations">
                            {dataset.tables.find(
                                (table) =>
                                    table.viewName === 'vdocs_warehouse_storage_location_operations'
                            ) && (
                                <>
                                    <OperationsDetail_DataTable
                                        childViewName="vdocs_warehouse_storage_location_operations"
                                        ref={operationRef}
                                    />
                                </>
                            )}
                        </GroupCollapse>
                    </Col>

                    <Col md={24} lg={12}>
                        <GroupCollapse titleTextId="tare_groups">
                            {dataset.tables.find(
                                (table) =>
                                    table.viewName ===
                                    'vdocs_warehouse_storage_location_tare_groups'
                            ) && (
                                <TareGroupDetail_DataTable
                                    ref={tareGroupRef}
                                    childViewName="vdocs_warehouse_storage_location_tare_groups"
                                />
                            )}
                        </GroupCollapse>
                    </Col>
                </Row>
            </>
        );
    }
);

export const GenerateStorageLocationsModal = ({
    open,
    setOpen
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t, i18n } = useTranslation();
    const navigate = useStoreNavigate();

    const [data, setData] = useState<StorageLocationGeneratorData>(() => ({
        should_track_rate_level2: false,
        should_track_rate_level3: false,
        can_have_few_cargo_units: false,
        can_mix_products: false
    }));

    useEffect(() => {
        setData({
            should_track_rate_level2: false,
            should_track_rate_level3: false,
            can_have_few_cargo_units: false,
            can_mix_products: false
        });
    }, [open]);

    const { openMessage } = useMessages({ message: '' });

    const navigateToAsyncTaskResult = (asyncTaskId: number) => {
        navigate(`/settings/async-tasks/${asyncTaskId}`);
    };

    const formDataToCamundaVariables = (
        data: StorageLocationGeneratorData,
        operations: string[],
        tareGroups: number[]
    ): CamundaVariable => {
        const variables: CamundaVariable = {
            warehouse_functional_purpose_code: {
                type: 'String',
                value: data.warehouse_functional_purpose_code
            },
            warehouse_storage_category_code: {
                type: 'String',
                value: data.warehouse_storage_category_code
            },
            warehouse_id: {
                type: 'Integer',
                value: data.warehouse_id
            },
            warehouse_zone_id: {
                type: 'Integer',
                value: data.warehouse_zone_id
            },
            warehouse_aisle_id: {
                type: 'Integer',
                value: data.warehouse_aisle_id
            },
            warehouse_row_from: {
                type: 'Integer',
                value: data.warehouse_row_from
            },
            warehouse_row_to: {
                type: 'Integer',
                value: data.warehouse_row_to
            },
            warehouse_column_from: {
                type: 'Integer',
                value: data.warehouse_column_from
            },
            warehouse_column_to: {
                type: 'Integer',
                value: data.warehouse_column_to
            },
            warehouse_level_from: {
                type: 'Integer',
                value: data.warehouse_level_from
            },
            warehouse_level_to: {
                type: 'Integer',
                value: data.warehouse_level_to
            },
            warehouse_storage_location_size_id: {
                type: 'Integer',
                value: data.warehouse_storage_location_size_id
            },
            should_track_rate_level2: {
                type: 'Boolean',
                value: data.should_track_rate_level2
            },
            should_track_rate_level3: {
                type: 'Boolean',
                value: data.should_track_rate_level3
            }
        };

        variables.flow_code = {
            type: 'String',
            value: ['GATE', 'BUFFER'].includes(data.warehouse_functional_purpose_code as string)
                ? data.flow_code
                : ''
        };

        variables.can_have_few_cargo_units = {
            type: 'Boolean',
            value: ['STORAGE'].includes(data.warehouse_functional_purpose_code as string)
                ? data.can_have_few_cargo_units
                : false
        };

        variables.can_mix_products = {
            type: 'Boolean',
            value: ['BOXES', 'PIECES'].includes(data.warehouse_functional_purpose_code as string)
                ? data.can_mix_products
                : false
        };

        variables.tare_groups = {
            type: 'json',
            value: JSON.stringify(tareGroups)
        };

        variables.operations = {
            type: 'json',
            value: JSON.stringify(operations)
        };

        return variables;
    };

    const handleGenerateStorageLocations = useCallback(
        async (variables: CamundaVariable) => {
            const startCamundaProcess = async () => {
                const process = await camundaStartProcess(CAMUNDA_PROCESS, {
                    variables
                });

                if (process.error) {
                    openMessage({
                        message: t(`${process.error.message}`),
                        type: 'error'
                    });
                    return;
                }

                openMessage({
                    message: t('tares_generator_request_successfully_created'),
                    type: 'success'
                });

                setOpen(false);

                const asyncTaskId = process?.data?.asyncTaskId as number;

                if (asyncTaskId) {
                    navigateToAsyncTaskResult(asyncTaskId);
                }
            };

            startCamundaProcess();
        },
        [camundaStartProcess, openMessage, data, t, i18n]
    );

    const formFields = useMemo(() => {
        return Object.keys(data).map((field) => {
            return {
                name: field,
                value: (data as Record<string, any>)[field]
            };
        });
    }, [data, open]);

    const tablesRef = useRef<StorageLocationTablesRef>(null);

    return (
        <>
            <Modal
                centered
                title={t('generate_warehouse_storage_locations')}
                open={open}
                onCancel={() => setOpen(false)}
                width={'70%'}
                footer={<></>}
            >
                <Form
                    fields={formFields}
                    onFinish={(formData) => {
                        let operations: string[] = [];
                        let tareGroups: number[] = [];
                        if (tablesRef.current) {
                            operations = tablesRef.current.GetOperations();
                            tareGroups = tablesRef.current.GetTareGroups();
                        }
                        const variables = formDataToCamundaVariables(data, operations, tareGroups);
                        handleGenerateStorageLocations(variables);
                    }}
                >
                    <Col md={12} lg={24}>
                        <GroupCollapse titleTextId="common_info">
                            <PrefixedSelectField
                                id="warehouse_functional_purpose_code"
                                mode={'edit'}
                                prefix="warehouse_functional_purpose_"
                                value={data as TableRow}
                                required
                                viewName="vdicts_warehouse_functional_purposes"
                                onChange={(newValue) => {
                                    setData((prevValue: any) => {
                                        return {
                                            ...prevValue,
                                            ...newValue
                                        };
                                    });
                                }}
                            />
                            <PrefixedSelectField
                                id="warehouse_storage_category_code"
                                mode={'edit'}
                                required
                                prefix="warehouse_storage_category_"
                                value={data as TableRow}
                                viewName="vdicts_warehouse_storage_categories"
                                onChange={(newValue) => {
                                    setData((prevValue: any) => {
                                        return {
                                            ...prevValue,
                                            ...newValue
                                        };
                                    });
                                }}
                            />
                            {data?.warehouse_functional_purpose_code &&
                                ['GATE', 'BUFFER'].includes(
                                    data.warehouse_functional_purpose_code!
                                ) && (
                                    <>
                                        <PrefixedSelectField
                                            id="flow_code"
                                            viewName="vdicts_flows"
                                            prefix="flow_"
                                            value={data as TableRow}
                                            mode={'edit'}
                                            onChange={(newValue) => {
                                                setData((prevValue: any) => {
                                                    return {
                                                        ...prevValue,
                                                        ...newValue
                                                    };
                                                });
                                            }}
                                        />
                                    </>
                                )}
                        </GroupCollapse>
                    </Col>
                    <Col md={12} lg={24}>
                        <GroupCollapse titleTextId="permissions">
                            <PrefixedSelectField
                                id="warehouse_storage_location_size_id"
                                mode="edit"
                                required
                                prefix="warehouse_storage_location_size_"
                                value={data as TableRow}
                                viewName="vdocs_warehouse_storage_location_sizes"
                                onChange={(newValue) => {
                                    setData((prevValue: any) => {
                                        return {
                                            ...prevValue,
                                            ...newValue
                                        };
                                    });
                                }}
                            />
                            {data?.warehouse_functional_purpose_code &&
                                data.warehouse_functional_purpose_code === 'STORAGE' && (
                                    <CheckboxField
                                        id="can_have_few_cargo_units"
                                        mode="edit"
                                        value={
                                            data?.can_have_few_cargo_units
                                                ? data?.can_have_few_cargo_units
                                                : false
                                        }
                                        onChange={(newValue: any) => {
                                            setData((prevValue: any) => {
                                                return {
                                                    ...prevValue,
                                                    can_have_few_cargo_units: newValue
                                                };
                                            });
                                        }}
                                    />
                                )}
                            {data?.warehouse_functional_purpose_code &&
                                ['BOXES', 'PIECES'].includes(
                                    data.warehouse_functional_purpose_code!
                                ) && (
                                    <CheckboxField
                                        id="can_mix_products"
                                        mode="edit"
                                        value={
                                            data?.can_mix_products ? data?.can_mix_products : false
                                        }
                                        onChange={(newValue: any) => {
                                            setData((prevValue: any) => {
                                                return {
                                                    ...prevValue,
                                                    can_mix_products: newValue
                                                };
                                            });
                                        }}
                                    />
                                )}
                            <CheckboxField
                                id="should_track_rate_level2"
                                mode="edit"
                                value={
                                    data?.should_track_rate_level2
                                        ? data?.should_track_rate_level2
                                        : false
                                }
                                onChange={(newValue: any) => {
                                    setData((prevValue: any) => {
                                        return {
                                            ...prevValue,
                                            should_track_rate_level2: newValue
                                        };
                                    });
                                }}
                            />
                            <CheckboxField
                                id="should_track_rate_level3"
                                mode="edit"
                                value={
                                    data?.should_track_rate_level3
                                        ? data?.should_track_rate_level3
                                        : false
                                }
                                onChange={(newValue: any) => {
                                    setData((prevValue: any) => {
                                        return {
                                            ...prevValue,
                                            should_track_rate_level3: newValue
                                        };
                                    });
                                }}
                            />
                        </GroupCollapse>
                    </Col>

                    {open && (
                        <DatasetWrapper
                            tableName={'docs_warehouse_storage_locations'}
                            viewName={'vdocs_warehouse_storage_locations'}
                            mode="edit"
                            tableDefs={tableDefs}
                        >
                            <StorageLocationTables ref={tablesRef} open={open} />
                        </DatasetWrapper>
                    )}

                    <Col md={12} lg={12} style={{ width: '100%' }}>
                        <GroupCollapse titleTextId="coordinates">
                            <StepByStepWarehouseGeneratorField
                                id="warehouse_level_to"
                                mode="edit"
                                required
                                value={data as TableRow}
                                viewNameOfValue="vdocs_warehouse_storage_locations"
                                steps={[
                                    {
                                        id: 'warehouse',
                                        inParameterFieldNameFromValue: '',
                                        viewName: 'vdocs_warehouses',
                                        prefix: 'warehouse_'
                                    },
                                    {
                                        id: 'warehouse_zone',
                                        inParameterFieldNameFromValue: 'warehouse_id',
                                        filterBy: 'warehouse_id',
                                        viewName: 'vdocs_warehouse_zones',
                                        prefix: 'warehouse_zone_'
                                    },
                                    {
                                        id: 'warehouse_aisle',
                                        inParameterFieldNameFromValue: 'warehouse_zone_id',
                                        filterBy: 'warehouse_zone_id',
                                        viewName: 'vdocs_warehouse_aisles',
                                        prefix: 'warehouse_aisle_'
                                    },
                                    {
                                        id: 'warehouse_row',
                                        inParameterFieldNameFromValue: 'warehouse_aisle_id',
                                        filterBy: 'warehouse_aisle_id',
                                        viewName: 'vdocs_warehouse_rows',
                                        prefix: 'warehouse_row_'
                                    },
                                    {
                                        id: 'warehouse_column',
                                        inParameterFieldNameFromValue: 'warehouse_row_id',
                                        filterBy: 'warehouse_row_id',
                                        viewName: 'vdocs_warehouse_columns',
                                        prefix: 'warehouse_column_'
                                    },

                                    {
                                        id: 'warehouse_level',
                                        inParameterFieldNameFromValue: 'warehouse_column_id',
                                        filterBy: 'warehouse_column_id',
                                        viewName: 'vdocs_warehouse_levels',
                                        prefix: 'warehouse_level_'
                                    }
                                ]}
                                onChange={(newValue) => {
                                    setData((prevValue: any) => {
                                        return {
                                            ...prevValue,
                                            ...newValue
                                        };
                                    });
                                }}
                            />
                        </GroupCollapse>
                    </Col>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <ButtonWithTooltips
                            id="generate_warehouse_storage_locations"
                            tooltipTitle={t('generate_warehouse_storage_locations')}
                            key="submit"
                            htmlType="submit"
                        >
                            {t('generate_warehouse_storage_locations')}
                        </ButtonWithTooltips>
                    </div>
                </Form>
            </Modal>
        </>
    );
};
