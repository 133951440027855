import { AutoComplete, Button, Input, Modal, Typography } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitcherOutlined } from '@ant-design/icons';
import { SearchList } from 'components/SearchList/SearchList';
import { useSupabase } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { ViewName } from 'modules/supabase/types/Dataset';
import { useSelectedViewDisplayPreference } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ButtonWithTooltips, StoreLink } from 'ui';
import { useStoreNavigate } from 'utils/store';
import { BaseField } from '../BaseField/BaseField';

interface AutocompleteWithViewHelpFieldProps {
    id: string;
    value: any | null;
    valueShortTitle: any | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    viewName: ViewName;
    htmlId?: string;
    filters?: Filter[];
    withLabel?: boolean;
    required?: boolean;
    multiLanguageValue?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    fieldName?: string;
    navigateLinkInViewMode?: {
        navPath: string;
        navParamName: string;
    };
}

interface OptionType {
    value: string;
    label: string;
}

export const AutocompleteWithViewHelpField = memo<AutocompleteWithViewHelpFieldProps>(
    ({
        id,
        value,
        valueShortTitle,
        mode,
        popoverContainerHtmlId,
        viewName,
        filters,
        navigateLinkInViewMode,
        htmlId = id,
        withLabel = true,
        required = false,
        skipBlur = false,
        fieldName = '',
        onChange = () => {}
    }) => {
        const { t, i18n } = useTranslation();

        const navigate = useStoreNavigate();

        const [options, setOptions] = useState<OptionType[]>([]);

        const [searchValue, setSearchValue] = useState('');
        const [refresh, setRefresh] = useState(false);
        const [isDropDownOpen, setDropDownOpen] = useState(false);
        const [isOpen, setOpen] = useState(false);

        const handleClose = useCallback(() => {
            setOpen(false);
        }, []);

        const handleOpen = useCallback(() => {
            setOpen(true);
        }, []);

        const { vdfLoaded } = useSelectedViewDisplayPreference({
            title: 'SearchHelp',
            viewName
        });

        const supabase = useSupabase();

        useEffect(() => {
            const fetchData = async () => {
                let query = supabase.from(viewName).select('*');

                if (filters) {
                    filters.forEach((filter) => {
                        query = query.filter(filter.column, filter.operator, filter.value);
                    });
                }

                const result = await query;

                if (result.error) {
                    setOptions([]);
                }

                if (result.data) {
                    setOptions(
                        result.data.map((rowData) => {
                            return {
                                value: rowData[fieldName || 'id'],
                                label: rowData.short_title
                                    ? rowData.short_title[i18n.language]
                                    : rowData.key || rowData.code
                            };
                        })
                    );
                }
            };

            if (viewName && vdfLoaded) {
                fetchData();
            }
        }, [viewName, filters, supabase, vdfLoaded, fieldName, i18n.language]);

        const outputOptions = useMemo<OptionType[]>(() => {
            return options
                .map((option) => ({
                    value: option.value,
                    label: option.label
                }))
                .filter((option) => {
                    if (searchValue) {
                        return option.label.includes(searchValue);
                    }
                    return true;
                });
        }, [options, searchValue]);

        const getLabelById = useCallback(
            (id: string) => {
                const selectedOption = outputOptions.find(
                    ({ value }) => Number(value) === Number(id)
                );
                const label = selectedOption ? selectedOption.label : id;

                return label;
            },
            [outputOptions]
        );

        const renderInput = useCallback(
            (
                inputValue: string,
                onInputChange?: (newValue: string) => void,
                onBlur?: () => void
            ) => {
                const label = getLabelById(inputValue);

                const handleNavigate = () => {
                    navigate(`${navigateLinkInViewMode?.navPath}${inputValue}`);
                };
                return (
                    <AutoComplete
                        id={`${id}-value`}
                        placeholder={t('no_value') as string}
                        value={label}
                        options={outputOptions}
                        onSearch={setSearchValue}
                        onChange={(v) => {
                            if (onInputChange) onInputChange(v);
                            setDropDownOpen(true);
                        }}
                        onBlur={onBlur}
                        open={isDropDownOpen}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Button type="link" onClick={handleOpen}>
                                    {t('show_more')}
                                </Button>
                            </>
                        )}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    >
                        <Input.Search
                            suffix={
                                navigateLinkInViewMode ? (
                                    <ButtonWithTooltips
                                        id="language-translate-button"
                                        className="language-translate-button"
                                        type="text"
                                        onClick={handleNavigate}
                                        icon={<SwitcherOutlined />}
                                        style={{ padding: 0, width: '100%', height: '100%' }}
                                    />
                                ) : undefined
                            }
                            id={`${id}-value-search`}
                            placeholder={t('no_value') as string}
                            onSearch={() => setDropDownOpen(true)}
                            onBlur={() => setTimeout(() => setDropDownOpen(false), 100)}
                        />
                    </AutoComplete>
                );
            },
            [
                getLabelById,
                id,
                t,
                outputOptions,
                isDropDownOpen,
                popoverContainerHtmlId,
                navigateLinkInViewMode,
                navigate,
                handleOpen
            ]
        );

        const renderView = useCallback(
            (viewValue: string) => {
                const label = getLabelById(viewValue);

                return (
                    <>
                        {navigateLinkInViewMode && valueShortTitle ? (
                            <StoreLink
                                replace
                                to={`${navigateLinkInViewMode?.navPath}${viewValue}`}
                            >
                                {label || valueShortTitle[i18n.language] || null}
                            </StoreLink>
                        ) : (
                            <Typography.Text
                                id={`${id}-value`}
                                style={{
                                    paddingLeft: '10px',
                                    color: viewValue ? undefined : '#aeaeae'
                                }}
                                type={viewValue ? undefined : 'secondary'}
                            >
                                {label || t('no_value')}
                            </Typography.Text>
                        )}
                    </>
                );
            },
            [getLabelById, navigateLinkInViewMode, valueShortTitle, i18n.language, id, t]
        );

        return (
            <>
                <Modal centered open={isOpen} width={'75%'} footer={[]} onCancel={handleClose}>
                    <SearchList
                        multiSelect={false}
                        viewNameOrTableName={viewName}
                        refresh={refresh}
                        onSearchApply={(selectedRows) => {
                            onChange(String(selectedRows[0].id));
                            handleClose();
                        }}
                        onSearchCancel={handleClose}
                        title={id}
                    />
                </Modal>
                <BaseField
                    id={id}
                    htmlId={htmlId}
                    value={value}
                    mode={mode}
                    withLabel={withLabel}
                    required={required}
                    onChange={onChange}
                    renderInput={renderInput}
                    renderView={renderView}
                    skipBlur={skipBlur}
                    withTooltip={false}
                />
            </>
        );
    }
);
