/* eslint-disable no-param-reassign */
import React, {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import {
    MRT_ColumnFiltersState,
    MRT_ColumnSizingState,
    MRT_FilterOption,
    MRT_PaginationState,
    MRT_SortingState,
    MRT_Virtualizer,
    MRT_VirtualizerOptions
} from 'material-react-table';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { useTranslation } from 'react-i18next';

import { TableName, TableRow, ViewName, ViewOrTableName } from 'modules/supabase/types/Dataset';

import { Typography } from 'antd';

import { ViewDisplayPreferences } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import type { MRTColumnDef, MRTTableInstance } from './MRT_Types';

import { FieldVisibilityType } from './useGenerateColumns';

import { getHandlers } from './TableBackend/functions/getHandlers';
import { useTableInfiniteQuery } from './TableBackend/hooks/useTableInfiniteQuery';
import { TableOptionType, useTableOption } from './TableBackend/hooks/useTableOption';
import {
    RequestOptionType,
    useTableRequestOption
} from './TableBackend/hooks/useTableRequestOption';
import { getSupabaseSubscriptionData } from './useSupabaseSubscription';

export type ColumnDef = MRTColumnDef &
    Partial<{
        navigate_path: string;
        view_help_filter: Filter[];
    }>;

export type GroupingOption = {
    parentRefFieldName: string;
    childRefFieldName: string;
};

interface useTableBackendFeaturesProps {
    viewOrTableName: ViewOrTableName;
    tableName?: TableName;
    filter?: Filter[]; // TODO: убрать - т.к tableDisplayPreferenceSettings это в себя должно включать!!!
    getFilter?: () => Promise<Filter[]>;
    predefinedColumns?: ColumnDef[];
    viewDisplayPreferenceTitle?: string;
    enableViewDisplayPreferenceMenu?: boolean;
    refresh?: boolean; // TODO: а зачем такой параметр? если это авто-рефреш, то не достаточно передавать tableName и если он есть то авто-refresh разрешен? или tableName теперь всегда нужен чтобы мета-описание прочитать?
    setRefresh?: Function;
    defaultPageSize?: number;
    enableColumnHiding?: boolean;
    enableRowOrdering?: boolean;
    setColumnOrder?: (columnKeyList: string[]) => string[];
    extraToolbarButtons?: React.ReactNode;
    autoRefresh?: boolean;
    channelName?: string;
    enableRowSelection?: boolean;
    loadRowsPerPackage?: number;
    enableVirtualization?: boolean;
    // countAlghoritm?: 'exact' | 'planned' | 'estimated';
    skipOrder?: boolean;
    groupingOption?: GroupingOption;
}

export type GlobalFilterType = {
    column?: string;
    value?: string;
    config?: string;
};

type TableStates = {
    columnOrder: string[];
    columnFilters: MRT_ColumnFiltersState;
    isLoading: boolean;
    rowSelection: Record<string, boolean>;
    pagination: MRT_PaginationState;
    virtualization?: boolean;
    grouping: string[];
    sorting: MRT_SortingState;
    columnVisibility: FieldVisibilityType;
    columnSizing: MRT_ColumnSizingState;
    showProgressBars: boolean;
    columnFilterFns: {
        [key: string]: MRT_FilterOption;
    };
};

export type TableRefType = {
    tableApi: RefObject<MRTTableInstance | null>;
    data: TableRow[];
    states: Partial<TableStates>;
    tableOptions?: TableOptionType;
    setTableOptions?: Dispatch<SetStateAction<TableOptionType>>;
    requestOptions?: RequestOptionType;
} | null;

export interface TableBackendFeaturesReturn {
    data: Record<string, any>[];
    tableInstanceRef: RefObject<MRTTableInstance | null>;
    columns: MRTColumnDef[];

    muiTableContainerProps: Record<string, any>;
    enableRowVirtualization: boolean;
    rowVirtualizerProps: Partial<MRT_VirtualizerOptions<HTMLDivElement, HTMLTableRowElement>>;
    rowVirtualizerOptions: Partial<MRT_VirtualizerOptions<HTMLDivElement, HTMLTableRowElement>>;
    columnVirtualizerOptions: Partial<MRT_VirtualizerOptions<HTMLDivElement, HTMLTableCellElement>>;
    rowVirtualizerInstanceRef: RefObject<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>;
    enableHiding: boolean;
    enableRowSelection: boolean;
    enableColumnOrdering: boolean;
    enableColumnFilters: boolean;
    enableGrouping: boolean;
    enableColumnFilterModes: boolean;
    enableGlobalFilter: boolean;
    manualFiltering: boolean;
    enableExpanding?: boolean;
    // manualPagination: boolean;
    manualSorting: boolean;
    getRowId: (
        originalRow: Record<string, any>,
        index: number,
        parent: Record<string, any>
    ) => string;
    meta: {
        viewName: ViewName;
        tableName: TableName;
        selectedViewDisplayPreference: ViewDisplayPreferences | null;
    };
    enableColumnResizing: boolean;
    enablePagination: boolean;
    columnResizeMode: 'onEnd' | 'onChange';
    renderToolbarInternalActions:
        | ((props: { table: MRTTableInstance }) => React.ReactNode)
        | undefined;
    renderFetchedInfo?: (selectedCount?: number) => React.ReactNode;
    onColumnFiltersChange: Dispatch<SetStateAction<MRT_ColumnFiltersState>>;
    onColumnFilterFnsChange: Dispatch<
        SetStateAction<{
            [key: string]: MRT_FilterOption;
        }>
    >;
    onColumnSizingChange: Dispatch<SetStateAction<MRT_ColumnSizingState>>;
    onGlobalFilterChange: (newGlobalFilter: string) => void;
    // onPaginationChange: Dispatch<SetStateAction<MRT_PaginationState>>;
    onSortingChange: Dispatch<SetStateAction<MRT_SortingState>>;
    onColumnVisibilityChange: Dispatch<SetStateAction<FieldVisibilityType>>;
    onColumnOrderChange: Dispatch<SetStateAction<string[]>>;
    onGroupingChange: Dispatch<SetStateAction<string[]>>;
    onRowSelectionChange: Dispatch<SetStateAction<Record<string, boolean>>>;
    rowCount: number;
    state: TableStates;

    getSubRows?: (row: Record<string, any>) => Record<string, any>[];
    tableOptions: TableOptionType;
    setTableOptions: Dispatch<SetStateAction<TableOptionType>>;
    requestOptions: RequestOptionType;
    enableColumnVirtualization: boolean;
    // muiTablePaginationProps: Record<string, any>;
    // infiniteResult: UseInfiniteQueryResult<any>;
}

const rowVirtualizerProps = {
    overscan: 4, // сколько строк подгружать за приделами видимости таблицы при включенной виртуализации
    estimateSize: () => 15 // if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
};

const columnVirtualizerProps = {
    overscan: 0 // сколько строк подгружать за приделами видимости таблицы при включенной виртуализации
};

const getRowId: TableBackendFeaturesReturn['getRowId'] = (row) => {
    return row.id as unknown as string;
};
// Данный хук возвращает и данные и мета-информацию про колонки
// Хук ниже позволяет таблицы включить серверную логику(поиск данных, фильтрацию, сортировку, пагинацию и прочее)
// Результат хука достаточно передать в MUI таблицу без доп.махинаций
export const useTableBackendFeatures = ({
    viewOrTableName,
    tableName, // может быть не указан если не нужна подписка
    filter,
    getFilter,
    predefinedColumns,
    enableViewDisplayPreferenceMenu = true,
    viewDisplayPreferenceTitle,
    defaultPageSize = 10,
    refresh, // параметр, который меняется каждый раз когда жмем refresh
    setRefresh,
    extraToolbarButtons,
    setColumnOrder,
    // countAlghoritm = 'exact',
    skipOrder = false,
    enableColumnHiding = true,
    enableRowOrdering = false,
    channelName = 'any',
    autoRefresh = true,
    enableRowSelection = true,
    enableVirtualization = false,
    loadRowsPerPackage = 30,
    groupingOption
}: useTableBackendFeaturesProps): TableBackendFeaturesReturn => {
    const { t } = useTranslation();

    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

    const [doRequest, setDoRequest] = useState<boolean>(false);

    const [totalDBRowCount, setTotalDBRowCount] = useState<number>(0);

    const {
        tableOption,
        setTableOption,
        renderToolbarInternalActions,
        columnsOptions,
        vdfLoaded,
        selectedViewDisplayPreference
    } = useTableOption({
        viewDisplayPreferenceTitle,
        viewOrTableName,
        defaultPageSize,
        setColumnOrder,
        enableRowSelection,
        predefinedColumns,
        enableRowOrdering,
        extraToolbarButtons,
        enableColumnHiding,
        enableViewDisplayPreferenceMenu
    });

    const meta = useMemo(() => {
        return {
            viewName: viewOrTableName as ViewName,
            tableName: tableName as TableName,
            selectedViewDisplayPreference
        };
    }, [selectedViewDisplayPreference, tableName, viewOrTableName]);

    const {
        columns, // возвращают все-все колонки
        columnVisibility, // возвращает то что надо показать
        setColumnVisibility, // функция изменения видимости столбцов
        columnOrdering, // порядок столбцов
        setColumnOrdering, // изменения порядка
        columnSizing, // ширина колонок
        setColumnSizing // изменение ширины колонок,
    } = columnsOptions;

    const {
        handleGlobalFilterChange,
        handleColumnFiltersChange,
        handleGroupingChange,
        handleColumnFilterFnsChange,
        handleSortingChange
    } = getHandlers(setTableOption);

    const requestOption = useTableRequestOption({ tableOption, vdfLoaded, filter });

    useEffect(() => {
        if ((tableOption.initialized, requestOption.needRequest && vdfLoaded && !doRequest)) {
            setDoRequest(true);
            setRowSelection({});
        }
    }, [requestOption, tableOption, vdfLoaded, doRequest]);

    const SupabaseGetDataOptions = useMemo(
        () => ({
            viewOrTableName,
            tableName, // если будет не undefined, то будет подписка
            filters: requestOption.filters, // AllFilters, // changeFilterData(),
            getFilter,
            orderBy: requestOption.sorting, // AllSorters, // changeSortingData(),
            pageNumber: requestOption.pagination.pageIndex,
            pageSize:
                loadRowsPerPackage ||
                (requestOption.pagination.pageSize < 15 ? 15 : requestOption.pagination.pageSize), // минимум чтобы 15 строк
            refresh,
            setRefresh,
            doRequest,
            globalFilter: requestOption.globalFilter,
            autoRefresh,
            setTotalDBRowCount,
            skipOrder
        }),
        [
            viewOrTableName,
            tableName,
            requestOption.filters,
            requestOption.sorting,
            requestOption.pagination.pageIndex,
            requestOption.pagination.pageSize,
            requestOption.globalFilter,
            getFilter,
            loadRowsPerPackage,
            refresh,
            setRefresh,
            doRequest,
            autoRefresh,
            skipOrder
        ]
    );
    const infiniteResult = useTableInfiniteQuery(SupabaseGetDataOptions);
    const { data: infData, fetchNextPage, isError, isFetching, isLoading } = infiniteResult;

    const tableInstanceRef = useRef(null);

    const rowVirtualizerInstanceRef =
        useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

    const flatData = useMemo(() => (infData?.pages && infData?.pages.flat()) ?? [], [infData]);

    const totalFetched = flatData.length;

    const muiTableContainerProps = useMemo(() => {
        return {
            ref: tableInstanceRef,
            sx: { maxHeight: 580 }
        };
    }, []);

    const renderFetchedInfo = useCallback(
        (selectedCount?: number) => {
            return (
                <Typography.Text strong>
                    {selectedCount ? `${t('selected')} ${selectedCount} ${t('out_of')} ` : null}
                    {totalFetched} {t('rows')}
                </Typography.Text>
            );
        },
        [t, totalFetched]
    );

    const state = useMemo(() => {
        return {
            columnOrder: columnOrdering,
            columnSizing,
            columnFilters: tableOption.columnFilters,
            isLoading,
            pagination: tableOption.pagination,
            virtualization: enableVirtualization, // true,
            showProgressBars: isLoading || isFetching,
            sorting: tableOption.sorting,
            columnFilterFns: tableOption.columnFilterFns,
            columnVisibility,
            rowSelection,
            grouping: tableOption.grouping
        };
    }, [
        columnOrdering,
        columnSizing,
        tableOption.columnFilters,
        isLoading,
        tableOption.pagination,
        isFetching,
        tableOption.sorting,
        tableOption.columnFilterFns,
        columnVisibility,
        rowSelection,
        tableOption.grouping,
        enableVirtualization
    ]);

    // scroll to top of table when sorting or filters change
    useEffect(() => {
        // scroll to the top of the table when the sorting changes

        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex(0);
        } catch (error) {
            // console.error(error);
        }
    }, [
        SupabaseGetDataOptions.orderBy,
        SupabaseGetDataOptions.filters,
        SupabaseGetDataOptions.globalFilter
    ]);

    // Данные с подписки:
    const subscribeData = getSupabaseSubscriptionData({
        viewOrTableName,
        tableName,
        autoRefresh,
        channelName,
        flatData
    });

    return {
        data:
            groupingOption && SupabaseGetDataOptions.filters.length === 0
                ? subscribeData.filter((dataRow) => !dataRow[groupingOption.childRefFieldName])
                : subscribeData,
        tableInstanceRef,
        columns,
        // Закоментил т.к с ней сайт подтормаживает при скролле
        enableRowVirtualization: enableVirtualization, // true, // пагинация и виртуализация всегда включены, virtualization у tableOption регулирует количество данных в запросе
        rowVirtualizerOptions: rowVirtualizerProps,
        enableColumnVirtualization: enableVirtualization,
        columnVirtualizerOptions: columnVirtualizerProps,
        rowVirtualizerProps,
        rowVirtualizerInstanceRef,
        muiTableContainerProps,
        enableHiding: true,
        enableRowSelection: true,
        enableColumnOrdering: true,
        // enableColumnFilters: enableFilters,
        enableColumnFilters: false,
        enableColumnFilterModes: false,
        enableGlobalFilter: false,
        enableGrouping: true,
        manualFiltering: true,
        enablePagination: false,
        manualSorting: true,
        enableColumnResizing: true,
        meta,
        columnResizeMode: 'onChange',
        onRowSelectionChange: setRowSelection as Dispatch<SetStateAction<Record<string, boolean>>>,
        onColumnOrderChange: setColumnOrdering,
        onColumnSizingChange: setColumnSizing,
        onColumnFiltersChange: handleColumnFiltersChange as Dispatch<
            SetStateAction<MRT_ColumnFiltersState>
        >,
        onColumnFilterFnsChange: handleColumnFilterFnsChange as Dispatch<
            SetStateAction<{ [key: string]: MRT_FilterOption }>
        >,
        onGlobalFilterChange: handleGlobalFilterChange,
        onSortingChange: handleSortingChange as Dispatch<SetStateAction<MRT_SortingState>>,
        onColumnVisibilityChange: setColumnVisibility,
        onGroupingChange: handleGroupingChange as Dispatch<SetStateAction<string[]>>,
        rowCount: totalDBRowCount,
        getRowId,
        renderToolbarInternalActions,
        renderFetchedInfo,
        state,
        requestOptions: requestOption,
        tableOptions: tableOption,
        setTableOptions: setTableOption,
        enableExpanding: !!groupingOption,
        getSubRows: groupingOption
            ? (row) => {
                  return subscribeData.filter(
                      (dataRow) =>
                          dataRow[groupingOption.childRefFieldName] ===
                          row[groupingOption.parentRefFieldName]
                  );
              }
            : undefined
    };
};
