import {
    supabaseClient
} from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { SupabaseRow } from 'modules/supabase/types/Dataset';
import { useEffect, useState } from 'react';

interface IUser extends SupabaseRow<'vsettings_users'> {
    warehouses: {
        warehouse_id: number | undefined;
    }[];
}

export const usePublicUser = () => {
    const [user, setUser] = useState<SupabaseRow<'vsettings_users'> | null>(null);

    useEffect(() => {
        const controller = new AbortController();
        const fetchUser = async () => {
            const { data } = await supabaseClient.auth.getSession();

            if (data.session?.user) {
                const { data: userData } = await supabaseClient
                    .from('vsettings_users')
                    .select('*, warehouses: settings_user_warehouses(warehouse_id)')
                    .eq('user_uuid', data.session.user.id)
                    .eq('warehouses.is_current_warehouse', true)
                    .abortSignal(controller.signal)
                    .maybeSingle();

                if (userData) setUser(userData);
            }
        };

        fetchUser();

        return () => {
            controller.abort();
        };
    }, []);

    return user;
};

export const useUserWarehouse = () => {
    const user = usePublicUser() as IUser;

    if (user?.warehouses.length > 0) {
        return user.warehouses.at(0)?.warehouse_id;
    }

    return null;
};
