import { LabelValue } from '@gilbarbara/types';

import { IObjectWithId } from 'utils/store/MetaStore';

import { renderOptionLabel } from './renderOptionLabel';

interface SelectTreeOption extends LabelValue {
    children?: SelectTreeOption[];
}

export const transformDataToOptions = (
    data: IObjectWithId[],
    viewFieldName: string
): SelectTreeOption[] => {
    // if (data.every((item) => !item.children))
    //     return data.map((item) => ({
    //         value: item.Code || item.Id,
    //         label: renderOptionLabel(item, myViewFieldName || viewFieldName)
    //     }));

    return data.map<SelectTreeOption>((item) => ({
        value: item.Id,
        label: renderOptionLabel(item, viewFieldName),
        children: item.children ? transformDataToOptions(item.children, viewFieldName) : undefined
    }));
};
