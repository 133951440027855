import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { callBackendFunction } from './BackendFunctions';

type SaveMultiDatasetResponse = Record<string, any> | null;

export async function AddTripGroupToTrip(
    tripgroupid: number,
    tripids: number[]
): Promise<{ data: Record<string, any> | null; error: Error | null }> {
    const endpoint = 'add-trip-group-to-trip';

    const { data, error } = await callBackendFunction<SaveMultiDatasetResponse>(endpoint, {
        tripgroupid,
        tripids
    });

    if (error) {
        return { data: null, error: new Error(makeErrorReadable(error.message)) };
    }

    return { data, error: null };
}
