import { App as AntdApp } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type NotificationType = 'success' | 'info' | 'warning' | 'error' | 'loading';

export interface UseMessagesProps {
    message: string;
    type?: NotificationType;
    duration?: number;
    key?: string; // несколько сообщений с одним ключом - это одно сообщение. Задав key,мы можем менять состояние сообщения. Напимер, loading -> success/error
}

interface UseMessagesReturnType {
    openMessage: (options?: UseMessagesProps) => void;
    loadingMessage: (key?: string) => void;
    successMessage: (key?: string) => void;
    errorMessage: (key?: string) => void;
}

export const useMessages = ({
    message: content,
    duration,
    key,
    type = 'info'
}: UseMessagesProps): UseMessagesReturnType => {
    const antdApp = AntdApp.useApp();
    const { t } = useTranslation();

    const openMessage = useCallback(
        (options?: UseMessagesProps) => {
            antdApp.message.open({
                key: options?.key || key,
                content: options?.message || content,
                type: options?.type || type,
                duration
            });
        },
        [content, duration, type, key]
    );

    const loadingMessage = useCallback(
        (key?: string) => openMessage({ key, message: t('loading'), type: 'loading' }),
        [openMessage, t]
    );

    const successMessage = useCallback(
        (key?: string) => openMessage({ key, message: `${t('successfully')}!`, type: 'success' }),
        [openMessage, t]
    );

    const errorMessage = useCallback(
        (key?: string) => openMessage({ key, message: t('error'), type: 'error' }),
        [openMessage, t]
    );

    const optionalMessages = useMemo(
        () => ({ loadingMessage, successMessage, errorMessage }),
        [errorMessage, loadingMessage, successMessage]
    );

    return { openMessage, ...optionalMessages };
};
