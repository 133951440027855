import { Flex } from 'antd';
import { memo, useState } from 'react';

import { NumberField } from '../NumberField/NumberField';

interface CoordinatePointFieldProps {
    coordinate: string | null;
    mode: 'view' | 'edit';
    onChange: (newValue: any) => void;
    label?: string;
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
}

export const CoordinatePointField = memo(
    ({
        coordinate,
        mode,
        onChange = () => {},
        label,
        withLabel = true,
        required = false,
        skipBlur = false
    }: CoordinatePointFieldProps) => {
        const coordinates = coordinate
            ? coordinate.match(/POINT\((-?\d+(?:\.\d+)?) (-?\d+(?:\.\d+)?)\)/)
            : null; // Handle null case

        const initialLongitude = coordinates ? parseFloat(coordinates[1]) : null;
        const initialLatitude = coordinates ? parseFloat(coordinates[2]) : null;

        const [longitude, setLongitude] = useState(initialLongitude); // Set initial state to null when coordinates are not provided
        const [latitude, setLatitude] = useState(initialLatitude); // Set initial state to null when coordinates are not provided

        const handleLongitudeChange = (newValue: number | null) => {
            // Allow null values for newValue
            setLongitude(newValue);
            onChange(
                `POINT(${newValue !== null ? newValue : ''} ${latitude !== null ? latitude : ''})`
            );
        };

        const handleLatitudeChange = (newValue: number | null) => {
            // Allow null values for newValue
            setLatitude(newValue);
            onChange(
                `POINT(${longitude !== null ? longitude : ''} ${newValue !== null ? newValue : ''})`
            );
        };

        return (
            <Flex className="base-field" vertical>
                <NumberField
                    id="latitude"
                    value={latitude}
                    mode={mode}
                    withLabel={true}
                    min={-90}
                    max={90}
                    step={0.0001}
                    onChange={handleLatitudeChange}
                    required={required}
                />

                <NumberField
                    id="longitude"
                    value={longitude}
                    mode={mode}
                    withLabel={withLabel}
                    min={-180}
                    max={180}
                    step={0.0001}
                    onChange={handleLongitudeChange}
                    required={required}
                />
            </Flex>
        );
    }
);
