import { ColorPicker } from 'antd';
import { memo, useCallback } from 'react';

import { BaseField } from '../BaseField/BaseField';

interface ColorFieldProps {
    id: string;
    value: string | null; // Color string format, e.g. '#ff0000'
    mode: 'view' | 'edit';
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
}

export const ColorField = memo<ColorFieldProps>(
    ({ id, value, mode, required = false, onChange = () => {}, skipBlur = false }) => {
        const renderInput = useCallback(
            (
                inputValue: string,
                onInputChange?: (newValue: string) => void,
                onBlur?: () => void
            ) => (
                <ColorPicker
                    size="large"
                    value={inputValue ?? ''}
                    onChange={(value) => {
                        if (onInputChange) {
                            onInputChange(value.toHexString());
                        }
                        if (onBlur) {
                            onBlur();
                        }
                    }}
                />
            ),
            []
        );

        const renderView = useCallback(
            (viewValue: string) => (
                <div
                    style={{
                        backgroundColor: viewValue,
                        width: '50px',
                        height: '20px'
                    }}
                />
            ),
            []
        );

        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                mode={mode}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
