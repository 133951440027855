import { Location } from 'react-router-dom';

export const formatLocation = (location: Location, id: number | string) => {
    let pathname = `${location.pathname}/${id}`;

    if (location.pathname.endsWith('/')) {
        pathname = `${location.pathname}${id}`;
    }

    return pathname;
};
