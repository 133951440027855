import { lazy } from 'react';
import { RoutesType } from 'routing/routesTypes';

const Profile_DetailPage = lazy(() =>
    import('pages/profile/Profile_DetailPage').then((module) => ({
        default: module.Profile_DetailPage
    }))
);
const Profile_TablePage = lazy(() =>
    import('pages/profile/Profile_TablePage').then((module) => ({
        default: module.Profile_TablePage
    }))
);

export const profileRoutesDataApps: RoutesType[] = [
    { path: '/users', component: Profile_TablePage },
    { path: '/users/:id', component: Profile_DetailPage }
];

export const profileRoutes = profileRoutesDataApps;
