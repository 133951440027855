import { FileExcelOutlined, FileWordOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Base_DataTable } from 'components/DataTable/Base_DataTable';
import { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { camundaStartProcess } from 'modules/services/backend-functions/camunda-start-process';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { TableName, ViewName } from 'modules/supabase/types/Dataset';
import { Database } from 'modules/supabase/types/database.types';
import { buildFilter } from 'modules/supabase/utils/supabaseClient';
import { convertComplexFilterToSupabaseFilter } from 'modules/supabase/utils/supabaseFilterUtils';
import { AsyncTask_DetailPage } from 'pages/settings/async-tasks/AsyncTask_DetailPage';
import { ButtonWithTooltips } from 'ui';
import { useMessages } from 'utils/hooks';
import { useStoreNavigate } from 'utils/store';

type MassPrintOptions = {
    enabled?: boolean;
    ids?: number[];
    numberOfCopies?: number;
};

type PrintingFormTabProps = {
    tableName: TableName;
    viewName: ViewName;
    id?: number;
    massPrintOption?: MassPrintOptions;
    showAsyncTaskResult?: boolean;
    noPreview?: boolean;
};

const PRINTING_FORM_VIEW_NAME: ViewName = 'vsettings_printing_forms';
const CAMUNDA_PROCESS = 'PrintingForms';
const CAMUNDA_PROCESS_MASS = 'MassPrintingForms';

type ViewType = Database['public']['Views']['vsettings_printing_forms']['Row'];

export const PrintingFormTab = ({
    tableName,
    viewName,
    id,
    showAsyncTaskResult,
    massPrintOption = {
        enabled: false,
        ids: []
    },
    noPreview = false
}: PrintingFormTabProps) => {
    const [printingForms, setPrintingForms] = useState<ViewType[]>([]);
    const navigate = useStoreNavigate();

    const { openMessage } = useMessages({ message: '' });

    const [asyncTaskId, setAsyncTaskId] = useState<number>();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const forms: ViewType[] = [];

            // Определяем все печатные формы для текущей модели(view)
            const printingFormsData = await supabaseClient
                .from(PRINTING_FORM_VIEW_NAME)
                .select('*')
                .filter('model_view_name', 'eq', viewName);

            if (printingFormsData.error) {
                openMessage({ message: printingFormsData.error.message, type: 'error' });
                return;
            }

            const printingFormRows = printingFormsData.data as ViewType[];

            // Проходимся по найденным формам , ищем подходящие
            for (let i = 0; i < printingFormRows.length; i++) {
                const printingForm = printingFormRows[i];

                if (printingForm.model_view_name && printingForm.model_view_name !== '') {
                    // Если в настройках печатной формы указан фильтр - смотрим подходит ли текущая запись под этот фильтр
                    if (
                        printingForm.applying_model_filter &&
                        printingForm.applying_model_filter !== '' &&
                        !massPrintOption.enabled
                    ) {
                        const filters = convertComplexFilterToSupabaseFilter(
                            JSON.parse(printingForm.applying_model_filter),
                            i18n.language
                        );

                        let query = supabaseClient
                            .from(printingForm.model_view_name as string)
                            .select('*') as any;
                        const applyFilter = buildFilter(query, filters);

                        applyFilter.forEach((filter) => {
                            query = filter;
                        });

                        query.filter('id', 'eq', id);

                        const checkIfRowIsValidForPrintingForm = await query;

                        if (checkIfRowIsValidForPrintingForm.error) {
                            openMessage({
                                message: checkIfRowIsValidForPrintingForm.error.message,
                                type: 'error'
                            });
                            continue;
                        }

                        if (checkIfRowIsValidForPrintingForm.data.length > 0) {
                            forms.push(printingForm);
                        }

                        // Если фильтр не указан то считаем что печатная форма подходит под текущую запись
                    } else {
                        forms.push(printingForm);
                    }
                }
            }
            setPrintingForms(forms);
        };

        fetchData();
    }, [tableName, viewName, i18n, convertComplexFilterToSupabaseFilter, id]);

    const navigateToAsyncTaskResult = (asyncTaskId: number) => {
        navigate(`/settings/async-tasks/${asyncTaskId}`);
    };

    const downloadPrintingForm = useCallback(
        (form: ViewType) => {
            const startCamundaProcess = async () => {
                const process = await camundaStartProcess(CAMUNDA_PROCESS, {
                    variables: {
                        printingFormId: {
                            type: 'String',
                            value: form.id
                        },

                        documentId: {
                            type: 'String',
                            value: id
                        },

                        language: {
                            type: 'String',
                            value: i18n.language
                        },

                        printing_form_category_code: {
                            type: 'String',
                            value: form.printing_form_category_code
                        },

                        noPreview: {
                            type: 'Boolean',
                            value: noPreview
                        }
                    }
                });

                if (process.error) {
                    openMessage({
                        message: process.error.message,
                        type: 'error'
                    });
                    return;
                }

                console.log('process:', process?.data?.asyncTaskId);

                openMessage({
                    message: t('printing_form_request_successfully_created'),
                    type: 'success'
                });

                const asyncTaskId = process?.data?.asyncTaskId as number;

                if (asyncTaskId) {
                    if (showAsyncTaskResult) {
                        setAsyncTaskId(asyncTaskId);
                    } else {
                        navigateToAsyncTaskResult(asyncTaskId);
                    }
                }
            };

            const startCamundaProcessMass = async () => {
                const process = await camundaStartProcess(CAMUNDA_PROCESS_MASS, {
                    variables: {
                        printingFormId: {
                            type: 'String',
                            value: form.id
                        },

                        documentIds: {
                            type: 'String',
                            value: JSON.stringify(massPrintOption.ids)
                        },

                        language: {
                            type: 'String',
                            value: i18n.language
                        },

                        printing_form_category_code: {
                            type: 'String',
                            value: form.printing_form_category_code
                        },

                        numberOfCopies: {
                            type: 'Integer',
                            value: massPrintOption.numberOfCopies
                                ? massPrintOption.numberOfCopies
                                : 1
                        },

                        noPreview: {
                            type: 'Boolean',
                            value: noPreview
                        }
                    }
                });

                if (process.error) {
                    openMessage({
                        message: process.error.message,
                        type: 'error'
                    });
                    return;
                }

                console.log('process:', process?.data?.asyncTaskId);

                openMessage({
                    message: t('mass_printing_form_request_successfully_created'),
                    type: 'success'
                });

                const asyncTaskId = process?.data?.asyncTaskId as number;

                if (asyncTaskId) {
                    if (showAsyncTaskResult) {
                        setAsyncTaskId(asyncTaskId);
                    } else {
                        navigateToAsyncTaskResult(asyncTaskId);
                    }
                }
            };

            if (!massPrintOption.enabled) {
                startCamundaProcess();
            } else {
                startCamundaProcessMass();
            }
        },
        [
            camundaStartProcess,
            id,
            openMessage,
            i18n,
            massPrintOption,
            showAsyncTaskResult,
            noPreview
        ]
    );

    const columns: MRTColumnDef[] = useMemo(() => {
        return [
            {
                accessorKey: 'title',
                header: t('title') as string
            }
        ];
    }, [t]);

    // if (id <= 0) {
    //     return <></>;123
    // }
    return (
        <>
            <Base_DataTable
                columns={columns}
                data={printingForms}
                viewName={PRINTING_FORM_VIEW_NAME}
                enableRowSelection={false}
                enableRowDragging={false}
                enableTableHead={true}
                enableTopToolbar={true}
                enableRowActions={true}
                isHasActionColumn={true}
                positionActionsColumn="last"
                initialState={{
                    columnVisibility: {
                        'mrt-row-actions': true,
                        'mrt-row-selection': false
                    }
                }}
                renderRowActions={({ row }) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {(row.original as ViewType).printing_form_category_code === 'WORD' && (
                                <ButtonWithTooltips
                                    id="word_form"
                                    style={{
                                        marginRight: '0.2rem'
                                    }}
                                    type="text"
                                    tooltipTitle={t('word')}
                                    onClick={() => {
                                        downloadPrintingForm(row.original as ViewType);
                                    }}
                                    icon={<FileWordOutlined />}
                                ></ButtonWithTooltips>
                            )}

                            {(row.original as ViewType).printing_form_category_code === 'EXCEL' && (
                                <ButtonWithTooltips
                                    id="excel_form"
                                    style={{
                                        marginRight: '0.2rem'
                                    }}
                                    type="text"
                                    tooltipTitle={t('excel')}
                                    onClick={() => {
                                        downloadPrintingForm(row.original as ViewType);
                                    }}
                                    icon={<FileExcelOutlined />}
                                ></ButtonWithTooltips>
                            )}
                        </div>
                    );
                }}
            />

            {showAsyncTaskResult && asyncTaskId ? (
                <AsyncTask_DetailPage id={asyncTaskId.toString()} />
            ) : null}
        </>
    );
};
