import { SettingsModulesContext } from 'contexts/SettingsModulesProvider';
import { SettingsModules } from 'contexts/SettingsModulesProvider/types';
import React, { useContext, useEffect, useState } from 'react';

interface ModuleComponentAccess extends React.PropsWithChildren {
    component_name?: string;
    component_path?: string;
}
function checkComponentAccess(
    settingsModules: SettingsModules[],
    userWarehouse?: number | null,
    component_name?: string,
    component_path?: string
): boolean {
    // Проверяем, что settingsModules не пустой и имеет модули
    if (settingsModules.length > 0 && Array.isArray(settingsModules[0].modules)) {
        // Ищем компонент по component_name и component_path
        const foundModule = settingsModules[0].modules.find((module) => {
            // Проверяем, что module.module.components существует и является массивом
            if (module.module.components && Array.isArray(module.module.components)) {
                const matchingComponent = module.module.components.find(
                    (comp: { component_name?: string | null; component_path?: string | null }) =>
                        comp.component_name === component_name &&
                        comp.component_path === component_path
                );
                return matchingComponent;
            }
            return false;
        });

        // Если модуль или компонент не найдены, возвращаем true
        if (!foundModule || !foundModule.module.components) {
            return true;
        }
        // Если склад не найден возвращаем false
        if (!userWarehouse) {
            return false;
        }
        // Ищем значения is_enabled на уровне модуля
        const isEnabled = foundModule.is_enabled;

        // Ищем значения is_accessible на уровне компонента
        const matchingComponent = foundModule.module.components.find(
            (comp: {
                component_name?: string | null;
                component_path?: string | null;
                is_accessible?: boolean | null;
            }) => comp.component_name === component_name && comp.component_path === component_path
        );
        const isAccessible = matchingComponent ? matchingComponent.is_accessible : false;

        // Возвращаем результат
        return isEnabled === true && isAccessible === true;
    }

    return false;
}
type ComponentInfo = {
    component_name: string;
    isEnabled: boolean;
};

export function useFindComponentsByPath(component_path: string): ComponentInfo[] {
    const [result, setResult] = useState<ComponentInfo[]>([]);
    const dictsPrefixes = ['vtree', 'vdicts', 'vselect'];
    const { settingsModules } = useContext(SettingsModulesContext);
    useEffect(() => {
        if (!settingsModules || settingsModules.length === 0) {
            setResult([]);
            return;
        }
        if (component_path) {
            if (dictsPrefixes.some((prefix) => component_path.startsWith(prefix))) {
                setResult(
                    settingsModules
                        .flatMap((module) =>
                            (module.modules || []).flatMap((moduleItem) =>
                                (moduleItem.module?.components || [])
                                    .filter((comp) => comp.component_path === component_path)
                                    .map((matchingComponent) => ({
                                        component_name: matchingComponent.component_name || '',
                                        isEnabled:
                                            moduleItem.is_enabled === true &&
                                            matchingComponent.is_accessible === true
                                    }))
                            )
                        )
                        .flat()
                ); // "flat" here to flatten the nested arrays
            }
        }
    }, [component_path, settingsModules]);
    return result;
}

const useModuleComponentAccess = ({
    component_name,
    component_path
}: ModuleComponentAccess): boolean => {
    const { settingsModules, userWarehouse } = useContext(SettingsModulesContext);
    if (!settingsModules || settingsModules.length === 0) {
        return true;
    }
    const componentAccess = checkComponentAccess(
        settingsModules,
        userWarehouse,
        component_name,
        component_path
    );
    return componentAccess;
};

export const InspectModuleComponentAccess: React.FC<ModuleComponentAccess> = ({
    children,
    component_name,
    component_path
}) => {
    const componentAccess = useModuleComponentAccess({ component_name, component_path });

    if (!componentAccess) return null;

    return children;
};
