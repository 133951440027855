import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPage_DataTable } from 'components/DataTable/DetailPage_DataTable';
import { MRTColumnDef } from 'components/DataTable/MRT_Types';

import { ViewName } from 'modules/supabase/types/Dataset';
import { TableRefType } from 'components/DataTable/useTableBackendFeatures';

interface TareGroupsDetail_DataTableProps {
    childViewName: ViewName;
}

export const TareGroupDetail_DataTable = forwardRef<TableRefType, TareGroupsDetail_DataTableProps>(
    ({ childViewName }, ref) => {
        const { t } = useTranslation();

        const columns: MRTColumnDef[] = useMemo(
            () => [
                {
                    accessorKey: 'warehouse_tare_group_key',
                    header: t('warehouse_tare_group_key') as string
                },
                {
                    accessorKey: 'warehouse_tare_group_short_title',
                    header: t('warehouse_tare_group_short_title') as string,
                    enableEditing: false
                }
            ],
            [t]
        );

        return (
            <DetailPage_DataTable
                childViewName={childViewName}
                predefinedColumns={columns}
                enableRowOrdering={false}
                ref={ref}
            />
        );
    }
);
