import { PrinterOutlined } from '@ant-design/icons';
import { ButtonWithTooltips } from 'ui';
import { ViewOnlyDetailPage_DataTable } from 'components/DataTable/ViewOnlyDetailPage_DataTable';
import { SelectPrintingFormModal } from 'components/Modals/SelectPrintingFormModal';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewName } from 'modules/supabase/types/Dataset';
import { Database } from 'modules/supabase/types/database.types';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type StorageLocationGeneratorOutputProps = {
    task: ViewType;
};

export const StorageLocationGeneratorOutput = ({ task }: StorageLocationGeneratorOutputProps) => {
    const { t } = useTranslation();

    const [showPrinttingFormsModal, setPrinttingFormsModal] = useState<boolean>(false);

    console.log('task:', task);

    const warehouseStorageLocationIds = (task?.output_data as Record<string, any>)
        ?.warehouseStorageLocationIds
        ? (JSON.parse(
              (task?.output_data as Record<string, any>)?.warehouseStorageLocationIds
          ) as number[])
        : [];

    useEffect(() => {
        if (task?.output_data) {
            console.log('task?.output_data', task.output_data);
        }
    }, [task?.output_data]);

    const filter = useMemo(() => {
        return [
            {
                column: 'id',
                operator: 'in',
                value: `(${warehouseStorageLocationIds})`
            }
        ];
    }, [task]);

    return (
        <>
            <ButtonWithTooltips
                id="massPrintBtn"
                tooltipTitle={t('massPrintBtn')}
                tooltipPlacement="top"
                onClick={() => {
                    setPrinttingFormsModal(true);
                }}
                icon={<PrinterOutlined />}
            >
                {t('massPrintBtn')}
            </ButtonWithTooltips>

            <SelectPrintingFormModal
                open={showPrinttingFormsModal}
                setOpen={setPrinttingFormsModal}
                documentIds={warehouseStorageLocationIds}
                tableName={'docs_warehouse_storage_locations'}
                viewName={'vdocs_warehouse_storage_locations' as ViewName}
            />

            <ViewOnlyDetailPage_DataTable
                viewName="vdocs_warehouse_storage_locations"
                filter={filter}
                enableRowSelection={false}
                pageSize={50000}
                // enableMultiRowSelection={false}
            />
        </>
    );
};
