import {
    CheckOutlined,
    CloseOutlined,
    InfoOutlined,
    PrinterOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { Divider, List } from 'antd';
import { ButtonWithTooltips } from 'ui/ButtonWithTooltips/ButtonWithTooltips';
import { ViewOnlyDetailPage_DataTable } from 'components/DataTable/ViewOnlyDetailPage_DataTable';
import { SelectPrintingFormModal } from 'components/Modals/SelectPrintingFormModal';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewName } from 'modules/supabase/types/Dataset';
import { Database } from 'modules/supabase/types/database.types';
import { ProcessLog } from './ProcessLog';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type WarehouseTasksOutputProps = {
    task: ViewType;
};

type Log = {
    message: string;
    type: string;
}[];

export const WarehouseTasksOutput = ({ task }: WarehouseTasksOutputProps) => {
    const { t } = useTranslation();

    console.log('task?.output_data:', task?.output_data);

    const warehouseTaskIds = (task?.output_data as Record<string, any>)?.task_ids?.task_ids;

    useEffect(() => {
        if (task?.output_data) {
            console.log('task?.output_data', task.output_data);
        }
    }, [task?.output_data]);

    const filter = useMemo(() => {
        if (warehouseTaskIds) {
            return [
                {
                    column: 'id',
                    operator: 'in',
                    value: `(${warehouseTaskIds})`
                }
            ];
        }
    }, [warehouseTaskIds]);

    return (
        <>
            <ProcessLog task={task} />

            <Divider orientation="left">{t('result')}</Divider>

            {warehouseTaskIds && (
                <ViewOnlyDetailPage_DataTable
                    viewName="vdocs_warehouse_tasks"
                    filter={filter}
                    enableRowSelection={false}
                    // enableMultiRowSelection={false}
                />
            )}
        </>
    );
};
