import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { BaseField } from '../BaseField/BaseField';

type RangeValue<T> = [T | null, T | null];

type DateTimeRangeType = {
    from_datetime: string | null;
    to_datetime: string | null;
};

export interface DateTimeRangeFieldProps {
    id: string;
    value: { from_datetime: Dayjs | string | null; to_datetime: Dayjs | string | null } | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    onChange: (newValue: DateTimeRangeType) => void;
    withLabel?: boolean;
    required?: boolean;
    utc?: boolean; // если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    skipBlur?: boolean;
    htmlId?: string;
    isShowTime?: boolean;
}

export const DateTimeRangeField = memo<DateTimeRangeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        utc = false,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = true,
        htmlId = id,
        isShowTime = true
    }) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (
                inputValue: {
                    from_datetime: string | null;
                    to_datetime: string | null;
                },
                onInputChange?: (newValue: {
                    from_datetime: string | null;
                    to_datetime: string | null;
                }) => void,

                onBlur?: () => void
            ) => {
                let value: RangeValue<Dayjs>;

                if (utc) {
                    value = [
                        inputValue && inputValue.from_datetime
                            ? dayjs(inputValue.from_datetime).utc(true).local()
                            : null,
                        inputValue && inputValue.to_datetime
                            ? dayjs(inputValue.to_datetime).utc(true).local()
                            : null
                    ];
                } else {
                    value = [
                        inputValue && inputValue.from_datetime
                            ? dayjs(inputValue.from_datetime)
                            : null,
                        inputValue && inputValue.to_datetime ? dayjs(inputValue.to_datetime) : null
                    ];
                }

                const onChange = (dates: RangeValue<Dayjs>) => {
                    if (!onInputChange || !onBlur) return;
                    let [from_datetime, to_datetime]: [dayjs.Dayjs | null, dayjs.Dayjs | null] =
                        dates || [null, null];

                    if (!from_datetime) {
                        from_datetime = dayjs();
                    }

                    if (!to_datetime) {
                        to_datetime = dayjs();

                        to_datetime = to_datetime.set('hours', 23);
                        to_datetime = to_datetime.set('minutes', 59);
                        to_datetime = to_datetime.set('seconds', 59);
                    }

                    if (utc) {
                        onInputChange({
                            from_datetime:
                                from_datetime &&
                                from_datetime
                                    .utc()
                                    .format(UserSpecificFormat.getDateTimeLocalFormat()) // getDateTimeFormat())
                                    .toString(),
                            to_datetime:
                                to_datetime &&
                                to_datetime
                                    .utc()
                                    .format(UserSpecificFormat.getDateTimeLocalFormat()) // getDateTimeFormat())
                                    .toString()
                        });
                        onBlur();
                    } else {
                        onInputChange({
                            from_datetime:
                                from_datetime &&
                                from_datetime
                                    .local()
                                    .format(UserSpecificFormat.getDateTimeLocalFormat())
                                    .toString(),
                            to_datetime:
                                to_datetime &&
                                to_datetime
                                    .local()
                                    .format(UserSpecificFormat.getDateTimeLocalFormat())
                                    .toString()
                        });
                        onBlur();
                    }
                };

                return (
                    // DatePicker.RangePicker подразумевает, что пользователь выбирает две даты, если одна из дат не выбрана, то возвращается null

                    <DatePicker.RangePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-edit`}
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        showTime={isShowTime} // отображение времени в компоненте выбора диапазона дат и времени
                        // Установка значения диапазона дат и времени в компоненте выбора диапазона дат и времени
                        value={value}
                        // Обработка изменения значения диапазона дат и времени в компоненте выбора диапазона дат и времени
                        onChange={onChange}
                        format={
                            utc
                                ? UserSpecificFormat.getDateTimeLocalFormat() // getDateTimeFormat()
                                : UserSpecificFormat.getDateTimeLocalFormat()
                        }
                        // onPickerValueChange={(dates: any, value: any) => {}}
                        inputReadOnly
                        allowClear
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                        // onBlur={onBlur}
                    />
                );
            },
            [htmlId, popoverContainerHtmlId, t, utc]
        );

        const renderView = useCallback(
            (viewValue: { from_datetime: string | null; to_datetime: string | null }) => {
                let value: RangeValue<Dayjs>;

                if (utc) {
                    value = [
                        viewValue && viewValue.from_datetime
                            ? dayjs(viewValue.from_datetime) // .utc()
                            : null,
                        viewValue && viewValue.to_datetime
                            ? dayjs(viewValue.to_datetime) // .utc()
                            : null
                    ];
                } else {
                    value = [
                        viewValue && viewValue.from_datetime
                            ? dayjs(viewValue.from_datetime)
                            : null,
                        viewValue && viewValue.to_datetime ? dayjs(viewValue.to_datetime) : null
                    ];
                }

                return (
                    <DatePicker.RangePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-view`}
                        showTime
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        value={value}
                        format={
                            utc
                                ? UserSpecificFormat.getDateTimeFormat()
                                : UserSpecificFormat.getDateTimeLocalFormat()
                        }
                        variant="borderless"
                        inputReadOnly
                        allowClear={false}
                        open={false}
                        suffixIcon={<></>}
                    />
                );
            },
            [htmlId, t, utc]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
