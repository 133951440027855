import { Input, InputNumber, Typography } from 'antd';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

const MAX_NUMBER = 9007199254740991;
const MIN_NUMBER = -9007199254740991;

interface NumberFieldProps {
    id: string;
    value: number | null;
    mode: 'view' | 'edit';
    onChange?: (newValue: number) => void;
    step?: number | string;
    min?: number;
    max?: number;
    withLabel?: boolean;
    htmlId?: string;
    required?: boolean;
    skipBlur?: boolean;
    isInteger?: boolean;
    refLink?: React.Ref<any>;
}

const parseValue = (isInteger: boolean) => (value: string | undefined) => {
    if (isInteger && value) {
        return parseInt(value, 10);
    }
    return Number(value!.replace(/,/, '.'));
};

// TODO: Add validation (pozitive/negative)
export const NumberField = memo<NumberFieldProps>(
    ({
        id,
        value,
        mode,
        refLink,
        step = 0.1,
        min = MIN_NUMBER,
        max = MAX_NUMBER,
        withLabel = true,
        onChange = () => {},
        required = false,
        skipBlur = false,
        isInteger = false,
        htmlId = id
    }) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (
                inputValue: number,
                onInputChange?: (newValue: number) => void,
                onBlur?: () => void
            ) => {
                return (
                    <InputNumber
                        id={`${htmlId}-value`}
                        style={{ width: '100%' }}
                        placeholder={t('no_value') as string}
                        parser={parseValue(isInteger)}
                        value={inputValue}
                        step={step}
                        min={min}
                        max={max}
                        onChange={(value) => {
                            if (onInputChange && (value || value === 0)) {
                                onInputChange(value);
                            }
                        }}
                        onBlur={() => {
                            if (onBlur && onInputChange && value !== null) {
                                onInputChange(value);
                                onBlur();
                            } else if (onBlur) {
                                onBlur();
                            }
                        }}
                    />
                );
            },
            [htmlId, isInteger, max, min, step, t, value]
        );

        const renderView = useCallback(
            (viewValue: number) => {
                return (
                    // <Input
                    //     id={`${htmlId}-value`}
                    //     placeholder={t('no_value') as string}
                    //     variant="borderless"
                    //     value={viewValue}
                    //     readOnly
                    // />
                    <Typography.Text
                        id={`${htmlId}-value`}
                        style={{
                            paddingLeft: '10px',
                            color: viewValue ? undefined : '#aeaeae'
                        }}
                        type={viewValue ? undefined : 'secondary'}
                    >
                        {viewValue || t('no_value')}
                    </Typography.Text>
                );
            },
            [htmlId, t]
        );

        return (
            <BaseField
                ref={refLink}
                withLabel={withLabel}
                required={required}
                id={id}
                htmlId={htmlId}
                value={value}
                mode={mode}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
