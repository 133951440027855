import React, { memo } from 'react';

interface AutolinkIndicatorProps {
    value: boolean | null;
}
export const AutolinkIndicator = memo(({ value }: AutolinkIndicatorProps) => (
    <div className="flex-center">
        <div className={`circle ${value ? 'green' : 'red'}`} />
    </div>
));
