import { PostgrestFilterBuilder } from '@supabase/postgrest-js';

import { MRT_FilterOption } from 'material-react-table';

import { GlobalFilterType } from 'components/DataTable/useTableBackendFeatures';
import { supabaseClient } from '../contexts/SupabaseContext/SupabaseContext';
import { SupabaseRow, TableName, ViewName, ViewOrTableName } from '../types/Dataset';
import { Database } from '../types/database.types';

export type OrderBy = {
    column: string;
    direction: 'asc' | 'desc';
};

export type Filter = {
    column: string;
    operator: MRT_FilterOption; // eq
    value: any;
    ANDCondition?: Filter;
}; // example: "name.eq.alex"

interface RowMetaInfo {
    /*
    example: {
      "not_null": false,
      "data_type": "code",
      "column_name": "lifecycle_status_code",
      "referenced_table": "dicts_lifecycle_statuses",
      "referenced_column": "code"}
  */
    not_null: boolean;
    data_type: string;
    column_name: string;
    referenced_table?: string;
    referenced_column?: string;
}

export type TableMetaInfo = RowMetaInfo[];

export function isTableMetaInfo(value: any): value is TableMetaInfo {
    if (!Array.isArray(value)) return false;

    return value.every(
        (item) =>
            typeof item.not_null === 'boolean' &&
            typeof item.data_type === 'string' &&
            typeof item.column_name === 'string' &&
            (item.referenced_table === undefined || typeof item.referenced_table === 'string') &&
            (item.referenced_column === undefined || typeof item.referenced_column === 'string')
    );
}
export interface TableDef {
    tableName: TableName;
    viewName: ViewName;
    referenceField?: string;
    extraFilters?: Filter[];
    rootTable?: TableName;
}

type GetSupabaseTableDataOptions<T extends ViewOrTableName, K extends TableName> = {
    viewOrTableName: T;
    pageSize?: number;
    pageNumber?: number;
    virtualization?: boolean;
    filters?: Filter[];
    getFilter?: () => Promise<Filter[]>;
    orderBy?: OrderBy[];
    globalFilter?: GlobalFilterType;
    skipOrder?: boolean;
    abortController?: AbortController;
    // countAlghoritm?: 'exact' | 'planned' | 'estimated';
};

export type TVecturaSupabaseFilterBuilder<T extends ViewOrTableName> = PostgrestFilterBuilder<
    Database['public'],
    SupabaseRow<T>,
    SupabaseRow<T>[]
>;

const buildANDCondition = <T extends ViewOrTableName>(
    query: TVecturaSupabaseFilterBuilder<T>,
    filter: Filter
): TVecturaSupabaseFilterBuilder<T>[] => {
    const filterOut: TVecturaSupabaseFilterBuilder<T>[] = [];

    filterOut.push(query.filter(filter.column, filter.operator, filter.value));

    if (filter.ANDCondition) {
        const addAndCondition = buildANDCondition<T>(query, filter.ANDCondition);

        for (let i = 0; i < addAndCondition.length; i++) {
            filterOut.push(addAndCondition[i]);
        }
    }
    return filterOut;
};

const buildANDConditionString = (filter: Filter): string => {
    let filterOut = null;

    const getFilterText = (filter: Filter): string => {
        let filterOut = '';
        filterOut = `${filter.column}.${filter.operator}.${filter.value}`;

        if (filter.ANDCondition) {
            const anotherFilter = getFilterText(filter.ANDCondition);

            if (anotherFilter) {
                filterOut = `${filterOut},${anotherFilter}`;
            }
        }

        return filterOut;
    };

    const filterTokens = getFilterText(filter);

    filterOut = `and(${filterTokens})`;

    return filterOut;
};

export const buildFilter = <T extends ViewOrTableName>(
    query: TVecturaSupabaseFilterBuilder<T>,
    filters: Filter[]
): PostgrestFilterBuilder<Database['public'], SupabaseRow<T>, SupabaseRow<T>[]>[] => {
    const applyFilter: TVecturaSupabaseFilterBuilder<T>[] = [];

    const copyFilter = [...filters];

    const combinedFilter: {
        columnName: string;
        filter: Filter[];
    }[] = [];

    // Группируем фильтры
    for (let i = 0; i < copyFilter.length; i++) {
        const fieldName = copyFilter[i].column;
        const findCombinedFilter = combinedFilter.find((filter) => filter.columnName === fieldName);

        if (findCombinedFilter) {
            findCombinedFilter.filter.push(copyFilter[i]);
        } else {
            combinedFilter.push({
                columnName: fieldName,
                filter: [copyFilter[i]]
            });
        }
    }

    for (let i = 0; i < combinedFilter.length; i++) {
        const filter = combinedFilter[i];

        // Если одно значение в фильтре по поле - обычная фильтрация
        if (filter.filter.length === 1) {
            if (filter.filter[0].ANDCondition) {
                const andFilters = buildANDCondition<T>(query, filter.filter[0]);

                for (let i = 0; i < andFilters.length; i++) {
                    applyFilter.push(andFilters[i]);
                }
            } else {
                applyFilter.push(
                    query.filter(
                        filter.filter[0].column,
                        filter.filter[0].operator,
                        filter.filter[0].value
                    )
                );
            }
        }

        // Если значений больше одному по полю - ставим условие ИЛИ
        if (filter.filter.length > 1) {
            let orCondition = null;

            for (let j = 0; j < filter.filter.length; j++) {
                const filterItem = filter.filter[j];

                if (orCondition) {
                    orCondition = `${orCondition},${
                        filterItem.ANDCondition
                            ? buildANDConditionString(filterItem)
                            : `${filterItem.column}.${filterItem.operator}.${filterItem.value}`
                    }`;
                } else {
                    orCondition = filterItem.ANDCondition
                        ? buildANDConditionString(filterItem)
                        : `${filterItem.column}.${filterItem.operator}.${filterItem.value}`;
                }
            }

            if (orCondition) {
                applyFilter.push(query.or(orCondition));
            }
        }
    }

    return applyFilter;
};

// это - базовая функция для получения данных их Supabase - все должны ей пользоваться
export const getSupabaseViewOrTableData = async <T extends ViewOrTableName>(
    options: GetSupabaseTableDataOptions<T, TableName>
) => {
    const {
        viewOrTableName,
        pageSize = 1000,
        pageNumber = 0,
        virtualization,
        filters = [],
        orderBy = [],
        globalFilter,
        abortController = null,
        skipOrder
        // countAlghoritm = 'exact'
    } = options;

    if ((!orderBy || !orderBy.length) && skipOrder) {
        return { data: [], error: null };
    }
    let query = supabaseClient.from(viewOrTableName).select<string, SupabaseRow<T>>('*') as any;

    if (abortController) {
        query = query.abortSignal(abortController) as any;
    }

    // Для пагинации выгрузить данные с лимитом если виртуализация не применяется
    if (!virtualization) {
        query = query.range(pageNumber * pageSize, (pageNumber + 1) * pageSize - 1);
    }
    // Применяем фильтры
    const applyFilter = buildFilter<T>(query, filters);

    applyFilter.forEach((filter) => {
        query = filter;
    });

    if (options.getFilter) {
        const additionalFilter = await options.getFilter();

        const applyFilter = buildFilter<T>(query, additionalFilter);
        applyFilter.forEach((filter) => {
            query = filter;
        });
    }

    // Глобальный фильтр(полнотекстовый поиск)
    if (globalFilter && globalFilter.value && globalFilter.column && globalFilter.config) {
        query.textSearch(globalFilter.column, globalFilter.value, {
            type: 'websearch',
            config: globalFilter.config
        });
    }

    // Применяем сортировку
    if (!skipOrder) {
        orderBy.forEach((order) => {
            query = query.order(order.column, {
                ascending: order.direction === 'asc'
            });
        });
    }

    return query as TVecturaSupabaseFilterBuilder<typeof viewOrTableName>;
};
