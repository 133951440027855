import {
    baseRoutes,
    other,
    profile,
    transactional_data,
    wms,
    wmsDocs,
    wmsEmployee,
    wmsReports
} from 'routing/allRoutes';
import { RouteController } from 'routing/routesTypes';
// когда загружаем DetailPage у мастерданных и транзакционных данных (документов)
// то загружаем по KEY, а когда загружаем страницу с настрйоками то по ID

// Для компонентов, таких как ХК и Aside
export const routes: RouteController = {
    baseRoutes,
    transactional_data,
    warehouse_master_data: wms,
    warehouse_docs: wmsDocs,
    warehouse_reports: wmsReports,
    profile
    // examples // Для левого меню - лишнее
};

// Для App.tsx
export const allRoutes = [
    ...baseRoutes,
    ...transactional_data,
    ...wms,
    ...wmsDocs,
    ...wmsReports,
    ...profile,
    ...other,
    ...wmsEmployee
];
