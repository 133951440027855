import { LabelValue } from '@gilbarbara/types';
import { Button, Tag, Tooltip } from 'antd';
import { SelectProps } from 'antd/es/select';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { BaseTableRow, SupabaseViewRow, ViewName } from 'modules/supabase/types/Dataset';
import { checkAndMakeTreeData } from 'smart/utils';
import { Loader } from 'ui/Loader/Loader';
import { IObjectWithId } from 'utils/store/MetaStore';

import { SmartSearchList } from '../SmartSelectField/components';
import {
    filterOption,
    filterSort,
    renderOptionLabel,
    transformDataToOptions,
    useSelectData
} from '../SmartSelectField/utils';

import './SmartMultiSelectField.scss';
import { SmartSelectInner } from '../SmartSelectField/ui';

type SupabaseViewType = SupabaseViewRow<ViewName> & BaseTableRow;

interface SelectFieldProps {
    popoverContainerHtmlId?: string; // id
    filters?: string;
    onChange?: (newValue: SupabaseViewType[] | null) => void; // handler
    maxCount?: number; // ограничение на максимальное кол-во выбранных опций
    loading?: boolean;
    metaName: string;
    value?: any[];
    isDisabled?: boolean;
    treeOptions?: {
        forceDisable?: boolean;
        parentFieldName?: string;
    };
}

type TagRender = SelectProps['tagRender'];

const getCuttedData = (array: IObjectWithId[]) => {
    return array.map((data) => ({
        Id: data.Id,
        Code: data.Code,
        Key: data.Key,
        Name: data.Name,
        Title: data.Title,
        ShortTitle: data.ShortTitle,
        PluralName: data.PluralName
    }));
};

export const SmartMultiSelectField = memo<SelectFieldProps>(
    ({
        popoverContainerHtmlId,
        filters = '',
        metaName,
        onChange = () => {},
        value = [],
        maxCount,
        loading,
        isDisabled = false,
        treeOptions
    }) => {
        // const inputRef = useRef<BaseSelectRef>(null);
        const [isOpen, setOpen] = useState(false);

        const { dataSource, viewFieldName, modalFields, modalTitle, fetchData, isLoading } =
            useSelectData({
                meta: metaName,
                filters
            });

        useEffectOnce(() => {
            fetchData();
        });

        // useEffect(() => {
        //     fetchData();
        // }, []);

        const { t } = useTranslation();

        const handleOpen = useCallback(() => {
            setOpen(true);
        }, []);
        const handleClose = useCallback(() => {
            setOpen(false);
        }, []);

        // const renderOptionLabel = useCallback(
        //     (item: any, viewFieldName: string) => {
        //         if (item) {
        //             const multilangField = item.Title || item.ShortTitle || item.Name;

        //             const defaultLabel = multilangField
        //                 ? multilangField[language]
        //                 : item.Code || item.Key || item.Id;

        //             if (!item[viewFieldName]) {
        //                 return defaultLabel;
        //             }

        //             if (typeof item[viewFieldName] === 'object') {
        //                 return item[viewFieldName][language] || defaultLabel;
        //             }

        //             return item[viewFieldName];
        //         }

        //         return item;
        //     },
        //     [language]
        // );

        const tagRender = useCallback<NonNullable<TagRender>>(
            (props) => {
                const { onClose, isMaxTag, label } = props;
                const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                };

                const needle = value.find((data) => data.Id === label);

                return isMaxTag ? (
                    <Tag onMouseDown={onPreventMouseDown} className="multi_select_field__tag">
                        <span>{label}</span>
                    </Tag>
                ) : (
                    <Tag
                        onMouseDown={onPreventMouseDown}
                        closable={!isDisabled}
                        onClose={onClose}
                        className="multi_select_field__tag"
                    >
                        {/* <span>{label}</span> */}
                        <span>{needle ? renderOptionLabel(needle, viewFieldName) : label}</span>
                    </Tag>
                );
            },
            [isDisabled, value, viewFieldName]
        );

        const outputOptions = useMemo<LabelValue[]>(() => {
            const treeData = checkAndMakeTreeData(dataSource, treeOptions?.parentFieldName, false);

            const options = transformDataToOptions(treeData, viewFieldName);
            // return dataSource.map((item) => ({
            //     value: item.Id,
            //     label: renderOptionLabel(item, viewFieldName)
            // }));
            // console.log('[SmartSelectField] options:', options);

            return options;
        }, [dataSource, treeOptions?.parentFieldName, viewFieldName]);

        const selectValue = useMemo(() => {
            const selectValue: string[] = [];

            value.forEach((v) => {
                const needle = dataSource.find((data) => data.Id === v.Id);
                if (needle) {
                    selectValue.push(needle?.Id);
                } else {
                    selectValue.push(v.Id);
                }
            });

            return selectValue;
        }, [dataSource, value]);

        const handleChangeInline = useCallback(
            (ids: string[]) =>
                onChange(getCuttedData(dataSource.filter((data) => ids.includes(data.Id)))),
            [dataSource, onChange]
        );

        const handleChangeModal = useCallback(
            (selectedRows: IObjectWithId[]) => onChange(getCuttedData(selectedRows)),
            [onChange]
        );

        return (
            <>
                <SmartSearchList
                    fields={modalFields}
                    title={modalTitle}
                    meta={metaName}
                    open={isOpen}
                    selectionType="checkbox"
                    onCancel={handleClose}
                    data={dataSource}
                    initialSelectedRows={value || []}
                    onOk={handleChangeModal}
                />
                <SmartSelectInner
                    disableTree={treeOptions?.forceDisable}
                    loading={loading}
                    allowClear
                    getPopupContainer={
                        popoverContainerHtmlId
                            ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                            : undefined
                    }
                    multiple
                    maxTagCount="responsive"
                    placeholder={t('no_value')}
                    style={{ width: '100%' }}
                    maxCount={maxCount}
                    // ref={inputRef}
                    value={selectValue}
                    options={outputOptions}
                    tagRender={tagRender}
                    filterOption={filterOption}
                    filterTreeNode={filterOption}
                    filterSort={filterSort}
                    onFocus={fetchData}
                    notFoundContent={isLoading ? <Loader size="small" /> : undefined}
                    dropdownRender={
                        !isLoading
                            ? (menu) => (
                                  <>
                                      {menu}
                                      <Button type="link" onClick={handleOpen}>
                                          {t('show_more')}
                                      </Button>
                                  </>
                              )
                            : undefined
                    }
                    maxTagPlaceholder={(omittedValues) => {
                        return (
                            <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                                <span>+{omittedValues.length} ...</span>
                            </Tooltip>
                        );
                    }}
                    onChange={handleChangeInline}
                    disabled={isDisabled}
                />
            </>
        );
    }
);
