import React, { memo, useCallback, useEffect, useReducer, useState } from 'react';

import { ViewName } from '../../types/Dataset';
import { useSupabaseUser } from '../../utils/hooks/useSupabaseUser';
import {
    ViewDisplayPreferenceContext,
    ViewDisplayPreferenceFetchResult,
    ViewDisplayPreferences,
    ViewDisplayPreferencesReducer
} from '../../utils/hooks/useViewDisplayPreferences';
import { supabaseClient } from '../SupabaseContext/SupabaseContext';

export const ViewDisplayPreferencesProvider = memo(({ children }: React.PropsWithChildren) => {
    const { user } = useSupabaseUser();

    const [state, dispatch] = useReducer(ViewDisplayPreferencesReducer, {
        preferences: {},
        UserID: null,
        fetchViewDisplayPreference: null
    });

    const [vdpProviderInit, setVdpProviderInit] = useState<boolean>(false);

    // У контекста есть функция для запроса VDF
    const fetchViewDisplayPreference = useCallback(
        (
            UserID: string,
            viewName: ViewName,
            callBackWhenFetched?: (fetchResult: ViewDisplayPreferenceFetchResult) => void
        ) => {
            const fetchData = async () => {
                const { data, error } = await supabaseClient
                    .from('settings_user_view_display_preferences')
                    .select('*')
                    .filter('view_name', 'eq', viewName)
                    .or(`parent_user_uuid.is.null,  parent_user_uuid.eq.${UserID}`);

                if (error) {
                    // Ошибка при запросе - выходим с пометкой что загрузили ??????????????????
                    console.error('Error when fetching View display preferences:', error);
                    dispatch({
                        type: 'VIEW_DISPLAY_LOADED',
                        payload: {
                            viewName,
                            fetchResult: {
                                isLoading: false,
                                error,
                                data: []
                            }
                        }
                    });
                    return;
                }

                if (data) {
                    // Передаем в хранилище загруженный VDF
                    dispatch({
                        type: 'VIEW_DISPLAY_LOADED',
                        payload: {
                            viewName,
                            fetchResult: {
                                error: null,
                                isLoading: false,
                                data: data as ViewDisplayPreferences[]
                            }
                        }
                    });

                    // Callback при успешной загрузке VDF
                    if (callBackWhenFetched) {
                        callBackWhenFetched({
                            data: data as ViewDisplayPreferences[],
                            error: null,
                            isLoading: false
                        });
                    }
                }
            };

            if (viewName) {
                if (!state.preferences[viewName]) {
                    fetchData();
                }
            }
        },
        [state.preferences]
    );

    // Инициализация хранилища
    // Передаем функцию
    useEffect(() => {
        const controller = new AbortController()

        if (user && !vdpProviderInit) {
            dispatch({
                type: 'INIT',
                payload: { fetchViewDisplayPreference, UserID: user.id }
            });

            setVdpProviderInit(true);
        }

        return () => {
            controller.abort();
        }
    }, [fetchViewDisplayPreference, user, vdpProviderInit]);

    return (
        <ViewDisplayPreferenceContext.Provider value={{ state, dispatch }}>
            {children}
        </ViewDisplayPreferenceContext.Provider>
    );
});
