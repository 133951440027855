import * as AntdIcons from '@ant-design/icons';
import {
    CaretDownOutlined,
    CopyOutlined,
    DeleteOutlined,
    PlusOutlined,
    ReloadOutlined,
    SettingOutlined
} from '@ant-design/icons';
import { Dropdown, Flex, MenuProps } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ButtonWithTooltips } from 'ui';
import { LANGUAGES } from 'utils/i18n/i18n';
import { metaStore } from 'utils/store/MetaStore';

import { Response } from 'modules/services/backend-api/generated_api';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { HelpIcon } from 'smart/ui';

import { LocalSearchComponent } from '../LocalSearchComponent/LocalSearchComponent';
import { SmartTableFilterMenuToolbarButton } from '../SmartTableFilterMenu/SmartTableFilterMenuToolbarButton';
import { FilterField } from '../SmartTableFilterMenu/types';
import { ViewDisplayPreferencesModal } from './components';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

type AntdIconsType = Omit<
    typeof AntdIcons,
    'IconProvider' | 'setTwoToneColor' | 'getTwoToneColor' | 'createFromIconfontCN'
>;

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    hasSelected: boolean;
    searchFilter?: string;
    setSearchFilter: (filter: string) => void;
    filters: FilterField[];
    setFilters: Dispatch<SetStateAction<FilterField[]>>;
    onRefresh: () => any;
    onCreate: () => void | Promise<void>;
    onCopy: () => void | Promise<void>;
    onDelete: () => void | Promise<void>;
    navigateAfterRun: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    onClickAction: (action: Action, hardIds?: string[]) => Promise<Response | undefined>;
    // setContextMenuActions?: Dispatch<SetStateAction<MenuProps['items']>>;
    showComponents?: {
        isReload?: boolean;
        isSearch?: boolean;
        isFilter?: boolean;
        isRightPart?: boolean;
        isDisplayViewPreferences?: boolean;
        isCreate?: boolean;
        isDelete?: boolean;
        isDescription?: boolean;
        isActions?: boolean;
        isFollows?: boolean;
        isReports?: boolean;
        isActionButtons?: boolean;
    };
}

const initComponentsDisplay = {
    isReload: true,
    isSearch: true,
    isFilter: true,
    isRightPart: true,
    isDisplayViewPreferences: true,
    isCreate: true,
    isDelete: true,
    isDescription: true,
    isActions: true,
    isFollows: true,
    isReports: true,
    isActionButtons: true
};

export const SmartTablePageHeaderToolbar = observer<SmartTablePageToolbarProps>((props) => {
    const {
        meta,
        selectedRowKeys,
        hasSelected,
        searchFilter,
        setSearchFilter,
        filters,
        setFilters,
        onRefresh,
        onCreate,
        onCopy,
        onDelete,
        showComponents: showComponentsLocal,
        onClickAction,
        navigateAfterRun
        // setContextMenuActions
    } = props;

    const metaData = toJS(metaStore.meta.get(meta));

    const showComponents = useMemo(
        () => ({ ...initComponentsDisplay, ...showComponentsLocal }),
        [showComponentsLocal]
    );

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [dpModal, setDpModal] = useState(false);

    const isMetaReadOnly = metaData?.info?.IsReadOnly || false;
    const uiAllowCreate = metaData?.info?.UiAllowCreate;
    const uiAllowDelete = metaData?.info?.UiAllowDelete;

    const actionsMenuItems = useMemo(() => {
        const folders: MenuItem[] = [];
        const folderless: MenuItem[] = [];

        if (metaData && metaData.info && metaData.info.Actions) {
            for (const action of metaData.info.Actions.sort((action) =>
                action.Type_Code === 'FOLDER' ? -1 : 1
            )) {
                if (!action.IsVisibleInList || action.IsButton || !action.IsActive) {
                    continue;
                }

                if (action.IsFolder || action.Type_Code === 'FOLDER') {
                    folders.push({
                        key: action.Id!,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        children: []
                    });
                }

                if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                    const parentId = action.Parent_Id;

                    const folderIndex = folders.findIndex(
                        (folder) => folder && folder.key === parentId
                    );

                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled:
                            !hasSelected ||
                            action.IsDisabled ||
                            (action.IsSingle && selectedRowKeys.length > 1),
                        onClick: () => onClickAction(action)
                    };

                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }

                if (!action.IsFolder && action.Type_Code === 'EVENT') {
                    const parentId = action.Parent_Id;

                    const folderIndex = folders.findIndex(
                        (folder) => folder && folder.key === parentId
                    );

                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled:
                            !hasSelected ||
                            action.IsDisabled ||
                            (action.IsSingle && selectedRowKeys.length > 1),
                        onClick: () => onClickAction(action)
                    };

                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }
            }
        }

        return [...folders.sort((a, b) => Number(a?.index) - Number(b?.index)), ...folderless];
    }, [onClickAction, hasSelected, language, metaData, selectedRowKeys.length]);

    // useEffect(() => {
    //     const folders: NonNullable<MenuProps['items']> &
    //         { children: NonNullable<MenuProps['items']> }[] = [];
    //     const folderless: NonNullable<MenuProps['items']> = [];
    //     const reports: NonNullable<MenuProps['items']> = [];
    //     const follows: NonNullable<MenuProps['items']> = [];

    //     const actions: MenuProps['items'] = [];

    //     if (metaData && metaData.info && metaData.info.Actions) {
    //         for (const action of metaData.info.Actions) {
    //             if (!action.IsVisibleInContextMenu || action.IsButton || !action.IsActive) {
    //                 continue;
    //             }

    //             if (action.IsFolder || action.Type_Code === 'FOLDER') {
    //                 folders.push({
    //                     key: action.Id!,
    //                     label: action.Name[language as LANGUAGES] || action.Id,
    //                     disabled: action.IsDisabled,
    //                     children: []
    //                 });
    //             }

    //             if (!action.IsFolder && action.Type_Code === 'HANDLER') {
    //                 const parentId = action.Parent_Id;

    //                 const folderIndex = folders.findIndex(
    //                     (folder) => folder && folder.key === parentId
    //                 );

    //                 const item = {
    //                     key: action.Name[language as LANGUAGES] || action.Type_Code,
    //                     label: action.Name[language as LANGUAGES] || action.Id,
    //                     disabled:
    //                         !hasSelected ||
    //                         action.IsDisabled ||
    //                         (action.IsSingle && selectedRowKeys.length > 1),
    //                     onClick: (event) => {
    //                         // не даем всплытию вызвать клик на ячейке при выборе пункта из контекстного меню
    //                         event.domEvent.stopPropagation();
    //                         onClickAction(action);
    //                     }
    //                 };

    //                 if (folderIndex !== -1) {
    //                     folders[folderIndex]?.children.push(item);
    //                 } else {
    //                     folderless.push(item);
    //                 }
    //             }

    //             if (!action.IsFolder && action.Type_Code === 'REPORT') {
    //                 const item = {
    //                     key: action.Name[language as LANGUAGES] || action.Type_Code,
    //                     label: action.Name[language as LANGUAGES] || action.Id,
    //                     disabled: !hasSelected || action.IsDisabled,
    //                     onClick: async () => {
    //                         // debugger;

    //                         const response = await onClickAction(action);

    //                         if (response) {
    //                             navigateAfterRun(response, action, filters);
    //                             // const responseData = response.run[0].Result;
    //                             // const pathnameLastElem = location.pathname
    //                             //     .split('/')
    //                             //     .at(-1)
    //                             //     ?.trim();

    //                             // let pageTitle = `${
    //                             //     location.state?.pageTitle || pathnameLastElem
    //                             // } (${t('filtered')})`;
    //                             // let pathname = location.pathname;
    //                             // // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
    //                             // let cacheKey = fnv1aHash(
    //                             //     `${pathnameLastElem}_filtered_${JSON.stringify(filters)}`
    //                             // );

    //                             // if (action.NavItem && action.NavItem.Path) {
    //                             //     if (action.NavItem.Name) {
    //                             //         pageTitle = `${
    //                             //             action.NavItem.Name[language] || action.NavItem.Name.en
    //                             //         } (${t('filtered')})`;
    //                             //     }
    //                             //     // pathname = action.NavItem.Path;
    //                             //     pathname = action.NavItem.Path.includes('?')
    //                             //         ? action.NavItem.Path.split('?')[0]
    //                             //         : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
    //                             //     // используем просутю hash функцию по методу fnv1
    //                             //     cacheKey = fnv1aHash(
    //                             //         `${action.NavItem.Id}_${JSON.stringify(filters)}`
    //                             //     );
    //                             // }

    //                             // storeNavigate(
    //                             //     { pathname },
    //                             //     {
    //                             //         state: {
    //                             //             filters,
    //                             //             pageTitle,
    //                             //             cacheKey,
    //                             //             fromMeta: action.Meta_Code,
    //                             //             associationCode: action.Association_Code,
    //                             //             responseData
    //                             //             // metaFields: metaField ? [metaField] : []
    //                             //         }
    //                             //     }
    //                             // );
    //                         }
    //                     }
    //                 };

    //                 reports.push(item);
    //             }

    //             if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
    //                 const item = {
    //                     key: action.Name[language as LANGUAGES] || action.Type_Code,
    //                     label: action.Name[language as LANGUAGES] || action.Id,
    //                     disabled: !hasSelected || action.IsDisabled,
    //                     onClick: async () => {
    //                         let filters: Filter[] = [];
    //                         const metaField = metaData.info?.Fields.find(
    //                             (field) => field.FieldName === 'Id'
    //                         );

    //                         if (action.Association) {
    //                             const association = await metaStore.getAssociation({
    //                                 association: action.Association.Code,
    //                                 ids: selectedRowKeys
    //                             });

    //                             filters = [
    //                                 {
    //                                     column: 'id',
    //                                     operator: 'in_list',
    //                                     value: association?.ids
    //                                 }
    //                             ];
    //                         } else {
    //                             filters = [
    //                                 {
    //                                     column: 'id',
    //                                     operator: 'in_list',
    //                                     value: selectedRowKeys
    //                                 }
    //                             ];
    //                         }

    //                         navigateAfterRun(
    //                             { session_id: '', request_id: '', status_code: 200 },
    //                             action,
    //                             filters,
    //                             metaField ? [metaField] : []
    //                         );

    //                         // const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

    //                         // let pageTitle = `${location.state?.pageTitle || pathnameLastElem} (${t(
    //                         //     'filtered'
    //                         // )})`;
    //                         // let pathname = location.pathname;
    //                         // // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
    //                         // let cacheKey = fnv1aHash(
    //                         //     `${pathnameLastElem}_filtered_${JSON.stringify(filters)}`
    //                         // );

    //                         // if (action.NavItem && action.NavItem.Path) {
    //                         //     if (action.NavItem.Name) {
    //                         //         pageTitle = `${
    //                         //             action.NavItem.Name[language] || action.NavItem.Name.en
    //                         //         } (${t('filtered')})`;
    //                         //     }
    //                         //     // pathname = action.NavItem.Path;
    //                         //     pathname = action.NavItem.Path.includes('?')
    //                         //         ? action.NavItem.Path.split('?')[0]
    //                         //         : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
    //                         //     // используем просутю hash функцию по методу fnv1
    //                         //     cacheKey = fnv1aHash(
    //                         //         `${action.NavItem.Id}_${JSON.stringify(filters)}`
    //                         //     );
    //                         // }

    //                         // storeNavigate(
    //                         //     { pathname },
    //                         //     {
    //                         //         state: {
    //                         //             filters,
    //                         //             pageTitle,
    //                         //             cacheKey,
    //                         //             fromMeta: action.Meta_Code,
    //                         //             associationCode: action.Association_Code,
    //                         //             metaFields: metaField ? [metaField] : []
    //                         //         }
    //                         //     }
    //                         // );

    //                         // setSelectedRows([]);
    //                     }
    //                 };

    //                 follows.push(item);
    //             }

    //             if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
    //                 const item = {
    //                     key: action.Name[language as LANGUAGES] || action.Type_Code,
    //                     label: action.Name[language as LANGUAGES] || action.Id,
    //                     disabled: !hasSelected || action.IsDisabled,
    //                     onClick: async () => {
    //                         // debugger;
    //                         const response = await onClickAction(action);
    //                         // debugger;
    //                         if (response) {
    //                             navigateAfterRun(
    //                                 {
    //                                     session_id: '',
    //                                     request_id: '',
    //                                     status_code: 200,
    //                                     run: response.run
    //                                 },
    //                                 action,
    //                                 filters
    //                             );
    //                             // const responseData = response.run[0].Result;
    //                             // const pathnameLastElem = location.pathname
    //                             //     .split('/')
    //                             //     .at(-1)
    //                             //     ?.trim();

    //                             // let pageTitle = `${
    //                             //     location.state?.pageTitle || pathnameLastElem
    //                             // } (${t('filtered')})`;
    //                             // let pathname = location.pathname;
    //                             // // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
    //                             // let cacheKey = fnv1aHash(
    //                             //     `${pathnameLastElem}_filtered_${JSON.stringify(filters)}`
    //                             // );

    //                             // if (action.NavItem && action.NavItem.Path) {
    //                             //     if (action.NavItem.Name) {
    //                             //         pageTitle = `${
    //                             //             action.NavItem.Name[language] || action.NavItem.Name.en
    //                             //         } (${t('filtered')})`;
    //                             //     }
    //                             //     // pathname = action.NavItem.Path;
    //                             //     pathname = action.NavItem.Path.includes('?')
    //                             //         ? action.NavItem.Path.split('?')[0]
    //                             //         : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
    //                             //     // используем просутю hash функцию по методу fnv1
    //                             //     cacheKey = fnv1aHash(
    //                             //         `${action.NavItem.Id}_${JSON.stringify(filters)}`
    //                             //     );
    //                             // }

    //                             // storeNavigate(
    //                             //     { pathname },
    //                             //     {
    //                             //         state: {
    //                             //             filters,
    //                             //             pageTitle,
    //                             //             cacheKey,
    //                             //             fromMeta: action.Meta_Code,
    //                             //             associationCode: action.Association_Code,
    //                             //             responseData
    //                             //             // metaFields: metaField ? [metaField] : []
    //                             //         }
    //                             //     }
    //                             // );
    //                         }
    //                     }
    //                 };

    //                 reports.push(item);
    //             }
    //         }
    //     }

    //     if (folders.length > 0 || folderless.length > 0) {
    //         actions.push({
    //             key: 'actions',
    //             label: t('actions'),
    //             children: [...folders, ...folderless]
    //         });
    //     }
    //     if (reports.length > 0) {
    //         actions.push({ key: 'reports', label: t('reports'), children: reports });
    //     }
    //     if (follows.length > 0) {
    //         actions.push({ key: 'follows', label: t('follows'), children: follows });
    //     }

    //     if (actions.length > 0) {
    //         actions.unshift({ type: 'divider' });
    //         if (setContextMenuActions) setContextMenuActions(actions);
    //     }
    // }, [selectedRowKeys]);

    const reportsMenuItems = useMemo(() => {
        const reports: MenuItem[] = [];

        if (metaData && metaData.info && metaData.info.Actions) {
            for (const action of metaData.info.Actions) {
                if (!action.IsVisibleInList || !action.IsActive) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'REPORT') {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: !hasSelected || action.IsDisabled,
                        onClick: async () => {
                            // debugger;
                            const response = await onClickAction(action);

                            if (response) {
                                navigateAfterRun(response, action, filters);
                                // const responseData = response.run[0]?.Result;
                                // if (responseData) {
                                //     // if (responseData.Status != 'EXECUTION_FAILED') {
                                //     const pathnameLastElem = location.pathname
                                //         .split('/')
                                //         .at(-1)
                                //         ?.trim();
                                //     let pageTitle = `${
                                //         location.state?.pageTitle || pathnameLastElem
                                //     } (${t('filtered')})`;
                                //     let pathname = location.pathname;
                                //     // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                                //     let cacheKey = fnv1aHash(
                                //         `${pathnameLastElem}_filtered_${JSON.stringify(filters)}`
                                //     );
                                //     if (action.NavItem && action.NavItem.Path) {
                                //         if (action.NavItem.Name) {
                                //             pageTitle = `${
                                //                 action.NavItem.Name[language] ||
                                //                 action.NavItem.Name.en
                                //             } (${t('filtered')})`;
                                //         }
                                //         // pathname = action.NavItem.Path;
                                //         pathname = action.NavItem.Path.includes('?')
                                //             ? action.NavItem.Path.split('?')[0]
                                //             : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                                //         // используем просутю hash функцию по методу fnv1
                                //         cacheKey = fnv1aHash(
                                //             `${action.NavItem.Id}_${JSON.stringify(filters)}`
                                //         );
                                //     }
                                //     storeNavigate(
                                //         { pathname },
                                //         {
                                //             state: {
                                //                 filters,
                                //                 pageTitle,
                                //                 cacheKey,
                                //                 fromMeta: action.Meta_Code,
                                //                 associationCode: action.Association_Code,
                                //                 responseData
                                //                 // metaFields: metaField ? [metaField] : []
                                //             }
                                //         }
                                //     );
                                // }
                                // }
                            }
                        }
                    };

                    reports.push(item);
                }

                if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: !hasSelected || action.IsDisabled,
                        onClick: async () => {
                            const response = await onClickAction(action);

                            if (response) {
                                navigateAfterRun(
                                    {
                                        session_id: '',
                                        request_id: '',
                                        status_code: 200,
                                        run: response.run
                                    },
                                    action,
                                    filters
                                );
                                // const responseData = response.run[0].Result;
                                // const pathnameLastElem = location.pathname
                                //     .split('/')
                                //     .at(-1)
                                //     ?.trim();

                                // let pageTitle = `${
                                //     location.state?.pageTitle || pathnameLastElem
                                // } (${t('filtered')})`;
                                // let pathname = location.pathname;
                                // // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                                // let cacheKey = fnv1aHash(
                                //     `${pathnameLastElem}_filtered_${JSON.stringify(filters)}`
                                // );

                                // if (action.NavItem && action.NavItem.Path) {
                                //     if (action.NavItem.Name) {
                                //         pageTitle = `${
                                //             action.NavItem.Name[language] || action.NavItem.Name.en
                                //         } (${t('filtered')})`;
                                //     }
                                //     // pathname = action.NavItem.Path;
                                //     pathname = action.NavItem.Path.includes('?')
                                //         ? action.NavItem.Path.split('?')[0]
                                //         : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                                //     // используем просутю hash функцию по методу fnv1
                                //     cacheKey = fnv1aHash(
                                //         `${action.NavItem.Id}_${JSON.stringify(filters)}`
                                //     );
                                // }

                                // storeNavigate(
                                //     { pathname },
                                //     {
                                //         state: {
                                //             filters,
                                //             pageTitle,
                                //             cacheKey,
                                //             fromMeta: action.Meta_Code,
                                //             associationCode: action.Association_Code,
                                //             responseData
                                //             // metaFields: metaField ? [metaField] : []
                                //         }
                                //     }
                                // );
                            }
                        }
                    };

                    reports.push(item);
                }
            }
        }

        return reports;
    }, [onClickAction, filters, navigateAfterRun, hasSelected, language, metaData]);

    const followsMenuItems = useMemo(() => {
        const follows: MenuItem[] = [];

        if (metaData && metaData.info && metaData.info.Actions) {
            for (const action of metaData.info.Actions) {
                if (!action.IsVisibleInList || !action.IsActive) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: !hasSelected || action.IsDisabled,
                        onClick: async () => {
                            let filters: Filter[] = [];
                            const metaField = metaData.info?.Fields.find(
                                (field) => field.FieldName === 'Id'
                            );

                            if (action.Association) {
                                const association = await metaStore.getAssociation({
                                    association: action.Association.Code,
                                    ids: selectedRowKeys
                                });

                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: association?.ids
                                    }
                                ];
                            } else {
                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: selectedRowKeys
                                    }
                                ];
                            }

                            navigateAfterRun(
                                { session_id: '', request_id: '', status_code: 200 },
                                action,
                                filters,
                                metaField ? [metaField] : []
                            );

                            // const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                            // let pageTitle = `${location.state?.pageTitle || pathnameLastElem} (${t(
                            //     'filtered'
                            // )})`;
                            // let pathname = location.pathname;
                            // // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                            // let cacheKey = fnv1aHash(
                            //     `${pathnameLastElem}_filtered_${JSON.stringify(filters)}`
                            // );

                            // if (action.NavItem && action.NavItem.Path) {
                            //     if (action.NavItem.Name) {
                            //         pageTitle = `${
                            //             action.NavItem.Name[language] || action.NavItem.Name.en
                            //         } (${t('filtered')})`;
                            //     }
                            //     // pathname = action.NavItem.Path;
                            //     pathname = action.NavItem.Path.includes('?')
                            //         ? action.NavItem.Path.split('?')[0]
                            //         : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                            //     // используем просутю hash функцию по методу fnv1
                            //     cacheKey = fnv1aHash(
                            //         `${action.NavItem.Id}_${JSON.stringify(filters)}`
                            //     );
                            // }

                            // storeNavigate(
                            //     { pathname },
                            //     {
                            //         state: {
                            //             filters,
                            //             pageTitle,
                            //             cacheKey,
                            //             fromMeta: action.Meta_Code,
                            //             associationCode: action.Association_Code,
                            //             metaFields: metaField ? [metaField] : []
                            //         }
                            //     }
                            // );

                            // setSelectedRows([]);
                        }
                    };

                    follows.push(item);
                }
            }
        }

        return follows;
    }, [navigateAfterRun, hasSelected, language, metaData, selectedRowKeys]);

    const handleOpenDpModal = async () => {
        setDpModal(true);
    };

    const actionButtons = useMemo(() => {
        const metaData = toJS(metaStore.meta.get(meta));

        if (metaData && metaData.info && metaData.info.Actions) {
            return metaData.info.Actions.filter(
                (action) => action.IsButton && action.IsVisibleInList && action.IsActive
            ).map((action) => {
                const isDisabled =
                    selectedRowKeys?.length === 0 ||
                    (action.IsSingle && selectedRowKeys?.length > 1);

                const Icon = action.Icon
                    ? AntdIcons[action.Icon as keyof AntdIconsType]
                    : () => <></>;

                return (
                    <ButtonWithTooltips
                        type={'default'}
                        className={''}
                        disabled={isDisabled}
                        id={action.Id || ''}
                        onClick={() => onClickAction(action)}
                    >
                        <Icon />
                        {action.Name[language]}
                    </ButtonWithTooltips>
                );
            });
        }

        return null;
    }, [meta, selectedRowKeys?.length, language, onClickAction]);

    return (
        <Flex justify="space-between" className="smart_table__header">
            <Flex gap={5} className="smart_table__header_left">
                {/* ### Дейсвия (мета) ### */}
                {actionsMenuItems.length > 0 && showComponents.isActions && (
                    <Dropdown
                        menu={{ items: actionsMenuItems }}
                        trigger={['click']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <ButtonWithTooltips type={'default'} className={''} id="actions">
                            {t('actions')}
                            <CaretDownOutlined />
                        </ButtonWithTooltips>
                    </Dropdown>
                )}

                {/* ### Отчеты (мета) ### */}
                {reportsMenuItems.length > 0 && showComponents.isReports && (
                    <Dropdown
                        menu={{ items: reportsMenuItems }}
                        trigger={['click']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <ButtonWithTooltips type={'default'} className={''} id="reports">
                            {t('reports')}
                            <CaretDownOutlined />
                        </ButtonWithTooltips>
                    </Dropdown>
                )}

                {/* ### Переходы (мета) ### */}
                {followsMenuItems.length > 0 && showComponents.isFollows && (
                    <Dropdown
                        menu={{ items: followsMenuItems }}
                        trigger={['click']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <ButtonWithTooltips type={'default'} className={''} id="follows">
                            {t('follows')}
                            <CaretDownOutlined />
                        </ButtonWithTooltips>
                    </Dropdown>
                )}

                {/* ### Обновить ### */}
                {showComponents.isReload && (
                    <ButtonWithTooltips
                        id="reload"
                        tooltipTitle={t('reload')}
                        tooltipPlacement="top"
                        onClick={onRefresh}
                        icon={<ReloadOutlined />}
                        type={'default'}
                        className={''}
                    />
                )}

                {/* ### Удалить ### */}
                {uiAllowDelete && showComponents.isDelete && (
                    <ButtonWithTooltips
                        id="delete"
                        tooltipTitle={t('delete')}
                        tooltipPlacement="top"
                        onClick={onDelete}
                        icon={<DeleteOutlined />}
                        disabled={!hasSelected || isMetaReadOnly}
                        type={'default'}
                        className={''}
                    />
                )}

                {/* ### Создать ### */}
                {uiAllowCreate && showComponents.isCreate && (
                    <ButtonWithTooltips
                        id="create"
                        tooltipTitle={t('create')}
                        onClick={onCreate}
                        icon={<PlusOutlined />}
                        type={'default'}
                        className={''}
                        disabled={isMetaReadOnly}
                    />
                )}

                {/* ### Скопировать ### */}
                {uiAllowCreate && showComponents.isCreate && (
                    <ButtonWithTooltips
                        id="copy"
                        tooltipTitle={t('copy')}
                        onClick={onCopy}
                        disabled={!hasSelected || isMetaReadOnly}
                        icon={<CopyOutlined />}
                        type={'default'}
                        className={''}
                    />
                )}

                {/* ### Описание ### */}
                {showComponents.isDescription && (
                    <HelpIcon
                        text={metaStore.meta.get(meta)?.info?.Description?.[language]}
                        style={{ width: 32 }}
                        iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                        trigger="click"
                    />
                )}

                {actionButtons}
            </Flex>

            <Flex className="smart_table__header_center">
                {/* TODO: center part of toolbar */}
                <></>
            </Flex>

            {showComponents.isRightPart && (
                <Flex className="smart_table__header_right" gap={5}>
                    {showComponents.isDisplayViewPreferences && (
                        <ButtonWithTooltips
                            id="view_display_preferences"
                            type="default"
                            className=""
                            onClick={handleOpenDpModal}
                            icon={<SettingOutlined />}
                        />
                    )}
                    {showComponents.isSearch && (
                        <LocalSearchComponent
                            searchFilter={searchFilter}
                            setFilter={setSearchFilter}
                            placeholder={`${t('search')}...`}
                        />
                    )}
                    {showComponents.isFilter && (
                        <SmartTableFilterMenuToolbarButton
                            meta={meta}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    )}
                </Flex>
            )}

            {showComponents.isDisplayViewPreferences && (
                <ViewDisplayPreferencesModal
                    open={dpModal}
                    onCancel={() => setDpModal(false)}
                    onOk={() => setDpModal(false)}
                />
            )}
        </Flex>
    );
});
