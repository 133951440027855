// StorageLocationCoordinateField решили сделать отдельный филд, чтобы было легче управлять правами доступа к этим полям
import { memo } from 'react';
import { TableRow } from 'modules/supabase/types/Dataset';
import { MultilanguageField } from '../MultilanguageField/MultilanguageField';
import { StringField } from '../StringField/StringField';

interface StorageLocationCoordinateFieldProps {
    value: TableRow | null;
    mode?: 'view' | 'edit';
}

export const StorageLocationCoordinateField = memo<StorageLocationCoordinateFieldProps>(
    ({ value, mode = 'view' }) => {
        const warehouse_functional_purpose_code = value && value.warehouse_functional_purpose_code;
        let storage_location_coordinate;
        const storage_location_coordinate_long_title = (
            <>
                <MultilanguageField
                    id="storage_location_coordinate_long_title"
                    mode={mode}
                    value={value && value.storage_location_coordinate_long_title}
                />
            </>
        );
        const storage_location_coordinate_serial_numbers = (
            <>
                <StringField
                    id="storage_location_coordinate_serial_numbers"
                    mode={mode}
                    value={value && value.coordinates}
                />
            </>
        );
        if (warehouse_functional_purpose_code) {
            if (
                warehouse_functional_purpose_code === 'BUFFER' ||
                warehouse_functional_purpose_code === 'USER' ||
                warehouse_functional_purpose_code === 'GATE'
            ) {
                storage_location_coordinate = storage_location_coordinate_long_title;
            } else {
                storage_location_coordinate = storage_location_coordinate_serial_numbers;
            }
        }
        return <>{storage_location_coordinate}</>;
    }
);
