import { useCallback } from 'react';
import { NavigateFunction, useNavigate, type Path } from 'react-router-dom';

import { routeStore } from '../../index';

export const useStoreNavigate = () => {
    const navigate = useNavigate();

    const storeNavigate: NavigateFunction = useCallback(
        (...args) => {
            // console.log('store navigate', args);
            if (typeof args[0] === 'string') {
                const [pathname, search] = args[0].split('?');
                const currentRoute = routeStore.getStateByLocation({ pathname });

                if (currentRoute && pathname !== '/') {
                    routeStore.remove(currentRoute);
                }

                routeStore.push({
                    pathname,
                    search: search ? `?${search}` : '',
                    state: null
                });
            } else if (typeof args[0] === 'object' && typeof args[1] === 'object') {
                routeStore.push({
                    pathname: args[0].pathname?.split('?')[0],
                    search: args[0].search || '',
                    ...args[1]
                } as Path);
            } else if (typeof args[0] === 'object') {
                const currentRoute = routeStore.getStateByLocation({
                    pathname: args[0].pathname?.split('?')[0]
                });

                if (currentRoute) {
                    routeStore.remove(currentRoute);
                }
                routeStore.push({
                    pathname: args[0].pathname?.split('?')[0] as string,
                    search: args[0].search || '',
                    state: null
                });
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigate(...args);
        },
        [navigate]
    );

    return storeNavigate;
};
