import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useState } from 'react';

import { ButtonWithTooltips } from 'ui';
import { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { SearchList } from 'components/SearchList/SearchList';
import { DatasetTable, RowState, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';

interface IMultiInsertButton {
    data: DatasetTable;
    viewName: ViewName;
    setFilteredTable: ((newTable: DatasetTable) => void) | undefined;
    columns?: MRTColumnDef[];
    isDisabled: boolean;
    rootId: number;
    filters?: Filter[];
}

export const MultiInsertButton: React.FC<IMultiInsertButton> = ({
    data,
    viewName,
    setFilteredTable,
    columns = null,
    rootId,
    filters = [],
    isDisabled
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const accessorKey = columns?.find((column) => column?.accessorKey?.search('_id'))?.accessorKey;

    const handleCancel = () => setIsVisible(false);
    const handleOpen = () => setIsVisible(true);

    const handleApply = (searchedRows: TableRow[]) => {
        const prefix = accessorKey?.split('_')[0];

        const prefixedRows = searchedRows.map((row, index) => {
            const prefixedValues = {} as TableRow;

            Object.keys(row).forEach((key: string) => {
                if (key.startsWith(prefix!)) prefixedValues[key] = row[key];
                else prefixedValues[`${prefix}_${key}`] = row[key];
            });

            const viewPrefix = viewName.split('_')[0]; // vdocs_warehouse_storage_locations
            const prefixedTableObjectId = `${viewName
                .replace(`${viewPrefix}_`, '')
                .slice(0, -1)}_id`; // warehouse_storage_location_id

            return {
                ...prefixedValues,
                id: 0,
                _state: 'Inserted' as RowState,
                root_id: rootId,
                child_index: index,
                [prefixedTableObjectId]: row.id
            };
        });

        if (setFilteredTable) setFilteredTable({ ...data, rows: prefixedRows });
        handleCancel();
    };

    return (
        <>
            <Modal
                centered
                key="multi-insert-modal"
                open={isVisible}
                width="75vw"
                footer={[]}
                onCancel={handleCancel}
            >
                <SearchList
                    multiSelect
                    viewNameOrTableName={viewName}
                    onSearchApply={handleApply}
                    onSearchCancel={handleCancel}
                    searchListFilter={filters}
                />
            </Modal>
            <ButtonWithTooltips
                disabled={isDisabled}
                id="multi-insert"
                size="large"
                icon={
                    <>
                        <PlusOutlined />
                        <MenuOutlined />
                    </>
                }
                tooltipPlacement="top"
                onClick={handleOpen}
            />
        </>
    );
};
