import { UploadOutlined } from '@ant-design/icons';
import { StorageError } from '@supabase/storage-js';
import { Flex, Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { ButtonWithTooltips } from 'ui';
import { FILE_BUCKET } from 'utils/config/constants';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useNotifications } from 'utils/hooks';
import { downloadFile, getFileUrlForDownload } from 'smart/utils';

import './FilePickerField.scss';

// Способ подчеркнуть, что в некоторые поля должны присваивать именно URL. TS позволяет (:
type FileUrlType = string;

interface ValueType {
    file_path: FileUrlType;
    file_name: string;
    mime_type?: string;
    file_size?: number;
    file_md5?: any;
}

export interface FilePickerFieldProps {
    // root_id: string;
    // meta: string;
    value: ValueType | null;
    onChange: (options: ValueType) => void;
    onChangeExternalFileName?: (value: string) => void;
    // onChange: (options: ValueType) => void;
    path: string;
    bucket?: string;
    // uploadPathToFile?: string;
    // enableRemoveButton?: boolean;
    // enablePreview?: boolean;
    // enableDragger?: boolean;
}

const UploadInput: React.FC<UploadProps & { enableDragger?: boolean }> = ({
    children,
    ...props
}) => {
    return props.enableDragger ? (
        <Upload.Dragger {...props} customRequest={props.customRequest}>
            {children}
        </Upload.Dragger>
    ) : (
        <Upload
            {...props}
            customRequest={props.customRequest}
            itemRender={(originNode) => <div style={{ marginTop: -8 }}>{originNode}</div>}
        >
            {children}
        </Upload>
    );
};

export const FilePickerField = memo<FilePickerFieldProps>(
    ({
        // root_id,
        // meta,
        value,
        onChange,
        onChangeExternalFileName,
        path,
        bucket = FILE_BUCKET
        // enableRemoveButton = true,
        // enablePreview = true,
        // enableDragger = false,
        // uploadPathToFile,
    }) => {
        const { t } = useTranslation();

        const { openNotify } = useNotifications({ message: '' });

        const [fileList, setFileList] = useState<UploadFile[]>([]);
        // console.log(fileList);
        const [showButton, setShowButton] = useState(!value?.file_name && !value?.file_path);

        // Тут обновляем fileList для компонента upload, чтобы акутализировать текущий файл в ui и корректно отрисовать с функционалом
        useEffect(() => {
            (async () => {
                if (!value || !value.file_name || !value.file_path) return;

                setFileList(([prev]) => [
                    {
                        ...prev,
                        uid: '0',
                        name: value.file_path.split('/').at(-1) || '',
                        // status: 'done' as const,
                        size: value.file_size
                    }
                    // { ...prev, uid: '0', name: value.file_name || '', status: 'uploading' as const }
                ]);

                try {
                    const downloadUrl = await getFileUrlForDownload(bucket, value.file_path);
                    setFileList(([prev]) => [
                        {
                            ...prev,
                            url: downloadUrl,
                            status: 'done',
                            linkProps: {
                                // ...prev.linkProps,
                                download: value.file_name
                            }
                        }
                    ]);
                } catch (error) {
                    setFileList(([prev]) => [{ ...prev, status: 'error' }]);
                }
            })();
        }, [bucket, value]);

        // Функция для отправки файла на сервер
        const handleUpload = useCallback(
            async (file: RcFile) => {
                try {
                    setShowButton(false);

                    // save file name before replace th
                    if (onChangeExternalFileName) onChangeExternalFileName(file.name);

                    Object.defineProperty(file, 'name', {
                        // value: translitRu(file.name)
                        value: uuidv4()
                    });

                    if (typeof file === 'string')
                        throw new Error(t('file_uncorrect_type') as string);

                    // Проверяем, что файл выбран
                    if (!file) {
                        throw new Error(t('file_not_choosen') as string);
                    }

                    // const internalFileName = fileList.find((f) => f.uid === file.uid)?.linkProps
                    //     .internalFileName;

                    // const filePath = `${path}/${internalFileName}`;
                    const filePath = `${path}/${file.name}`;

                    const { data, error } = await supabaseClient.storage
                        .from(bucket)
                        .upload(filePath, file);

                    // Если ошибка, то бросаем исключение
                    if (error && !data) throw error;

                    // Успех
                    onChange({ file_name: file.name, file_path: data.path, file_size: file.size });
                } catch (error) {
                    openNotify({
                        message: t('file_upload_error') as string,
                        description: makeErrorReadable((error as Error).message),
                        duration: 0,
                        type: 'error'
                    });

                    // eslint-disable-next-line no-console
                    console.error((error as Error).message); // точно будет message, так как явно его передаем выше
                    throw error;
                }
            },
            [onChangeExternalFileName, t, path, bucket, onChange, openNotify]
        );

        // Функция удаления файла
        const handleRemove = useCallback(
            async (file: UploadFile) => {
                await supabaseClient.storage.from(bucket).remove([`${path}/${file.name}`]);
                // .remove([`${path}/${file.linkProps.internalFileName}`]);

                setFileList([]);
                setShowButton(true);

                onChange({ file_name: '', file_path: '' });
            },
            [bucket, onChange, path]
        ) as UploadProps['onRemove'];

        // Функция загрузки файла
        const handleDownload = useCallback(async () => {
            try {
                if (!value) {
                    openNotify({
                        message: t('file_download_error') as string,
                        description: t('no_value') as string,
                        type: 'error'
                    });

                    return;
                }

                await downloadFile(bucket, value.file_path, value.file_name);
            } catch (error) {
                openNotify({
                    message: t('file_download_error') as string,
                    description: makeErrorReadable((error as Error).message),
                    duration: 0,
                    type: 'error'
                });

                // eslint-disable-next-line no-console
                console.error((error as StorageError | Error).message);
            }
        }, [bucket, openNotify, t, value]);

        // const handleRemove = (file: UploadFile) => handleRemoveFile(file as RcFile);

        const handleRequest: UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
            const res = handleUpload(file as RcFile);
            if (res) {
                res.then(onSuccess).catch(onError);
            }
        };

        const handleChange: UploadProps['onChange'] = (info) => {
            const newFileList = [...info.fileList].map((file) => {
                const newFile = { ...file };

                if (file.response) {
                    newFile.url = file.response.url;
                }

                // if (!newFile.linkProps?.internalFileName) {
                //     let internalFileName = fileList.find((f) => f.uid === file.uid)?.linkProps
                //         .internalFileName;

                //     internalFileName = internalFileName || uuidv4();

                //     newFile.linkProps = {
                //         ...newFile.linkProps,
                //         internalFileName
                //     };
                // }

                return newFile;
            });

            setFileList(newFileList);
        };

        return (
            <Flex
                className="file_picker_field"
                style={{ height: 32, width: '100%' }}
                align="center"
            >
                <UploadInput
                    onDownload={handleDownload}
                    onRemove={handleRemove}
                    customRequest={handleRequest}
                    onChange={handleChange}
                    fileList={fileList}
                    // progress={{ size: 0, showInfo: false }}
                    // listType="picture"
                    showUploadList={{
                        extra: ({ size = 0 }) => {
                            return (
                                <span style={{ color: '#cccccc' }}>
                                    {' '}
                                    ({(size / 1024 / 1024).toFixed(2)}MB)
                                </span>
                            );
                        }
                        // showPreviewIcon: false
                    }}
                    // listType="picture"
                    // fileList={enablePreview ? fileList : []}
                    // enableDragger={enableDragger}
                >
                    {/* {enableDragger ? (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t('click_or_drag_for_upload')}</p>
                    </>
                ) :  */}
                    {showButton ? (
                        <ButtonWithTooltips
                            className="file_picker_upload"
                            type="default"
                            id={'upload'}
                            tooltipTitle={t('upload')}
                            icon={<UploadOutlined />}
                        >
                            {t('upload')}
                        </ButtonWithTooltips>
                    ) : null}
                </UploadInput>
            </Flex>
        );
    }
);
