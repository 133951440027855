import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { usePermissions } from 'modules/auth/contexts';
import { Loader } from 'ui/Loader/Loader';
import { useSupabaseUser } from 'modules/supabase/utils/hooks/useSupabaseUser';
import { useStoreNavigate } from 'utils/store';

interface RequirePermissionsProps {
    path: string;
    children: React.ReactNode;
}

export const RequirePermissions: React.FC<RequirePermissionsProps> = ({ path, children }) => {
    const navigate = useStoreNavigate();
    const location = useLocation();
    const { permissions, loading } = usePermissions();
    const { user } = useSupabaseUser();
    const { t } = useTranslation();

    useEffect(() => {
        if (!loading) {
            const isAllowed = permissions.routes[path ?? ''] ?? true;

            // eslint-disable-next-line no-console
            console.log('RequirePermissions', path, permissions, isAllowed);

            if (!isAllowed) {
                navigate('/no-access', { state: { from: location }, replace: true });
            }
        }
    }, [location, navigate, path, permissions, t, loading]);

    const loaderStatus = !user?.user_metadata?.roles?.includes('Admin') ? loading : false;

    return (
        // <Loader status={loaderStatus} customTip={t('checking_permissions') as string}>
        <>{children}</>
        // </Loader>
    );
};
