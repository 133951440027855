// SettingsLoadUnloadDuration_DetailPage.tsx
import React, { memo, useEffect, useState } from 'react';

import { RealtimeChannel } from '@supabase/supabase-js';
import { Spin } from 'antd';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Database } from 'modules/supabase/types/database.types';
import { IDetailPageLoaderProps } from 'pages/base/DetailPage/DetailPageLoader';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'utils/hooks';
import { ErrorOutput } from './ErrorOutput';
import { TaskOutputResolver } from './TaskOutputResolver';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

export const AsyncTask_DetailPage: React.FC<IDetailPageLoaderProps> = memo(({ id }) => {
    const { openMessage } = useMessages({ message: '' });

    const [asyncTask, setAsyncTask] = useState<ViewType>(null);

    const { t } = useTranslation();

    const fetchData = async (): Promise<ViewType> => {
        const result = await supabaseClient
            .from('vmisc_async_tasks')
            .select('*')
            .filter('id', 'eq', id);

        if (result.error) {
            openMessage({ message: result.error.message, type: 'error' });
            return null;
        }

        if (result?.data[0]) {
            return result.data[0] as ViewType;
        }

        return null;
    };

    useEffect(() => {
        const doRequest = async () => {
            const asyncTask = await fetchData();

            if (asyncTask) {
                setAsyncTask(asyncTask);
            }
        };

        if (id) {
            doRequest();
        }
    }, []);

    useEffect(() => {
        let channel: RealtimeChannel | null;

        if (id) {
            // Если нужна подписка на данные
            channel = supabaseClient
                .channel('async_task_channel')
                .on(
                    'postgres_changes',
                    {
                        event: 'UPDATE',
                        schema: 'public',
                        table: 'misc_async_tasks',
                        filter: `id=eq.${id}`
                    },
                    async (payload) => {
                        console.info('AsyncTask_DetailPage: got changes', payload);

                        const asyncTask = await fetchData();

                        if (asyncTask) {
                            setAsyncTask(asyncTask);
                        }
                    }
                )
                .subscribe((status, err) => {
                    console.log(`AsyncTask_DetailPage: status=${status} err=${err}`);
                });

            if (channel) {
                console.log('AsyncTask_DetailPage: channel.subscribe()');
            } else {
                console.error('AsyncTask_DetailPage: channel is null');
            }
        }

        return () => {
            if (channel) {
                // если была подписка на данные то тут ее отменяем
                channel.unsubscribe();
                console.log('AsyncTask_DetailPage: channel.unsubscribe()');
            }
        };
    }, [id]);
    return (
        <div style={{ margin: '2rem' }}>
            {asyncTask?.process_status_code !== 'FINISHED' &&
                asyncTask?.result_status_code !== 'ERROR' && (
                    <Spin tip={t('loading')} size="large">
                        <div
                            style={{
                                margin: '2rem',
                                width: '100%',
                                height: '50vh'
                            }}
                        />
                    </Spin>
                )}

            {asyncTask?.result_status_code === 'ERROR' && <ErrorOutput task={asyncTask} />}
            {asyncTask?.result_status_code === 'SUCCESS' && <TaskOutputResolver task={asyncTask} />}
        </div>
    );
});
