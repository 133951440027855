import { LabelValue } from '@gilbarbara/types';
import { DefaultOptionType } from 'antd/es/cascader';
// import { DefaultOptionType } from 'antd/es/select';
import { isString } from 'is-lite/exports';
import { FilterFunc } from 'rc-select/lib/Select';

export const filterOption: FilterFunc<DefaultOptionType> = (
    input: string,
    option?: DefaultOptionType
) => {
    const regex = new RegExp(input, 'i');

    if (isString(option?.label || '')) {
        return regex.test(String(option?.label) || '');
    }

    return false;
};
