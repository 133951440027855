import { Modal } from 'antd';
import _ from 'lodash';
import { memo, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Database } from 'modules/supabase/types/database.types';
import { TableName } from 'modules/supabase/types/Dataset';

import { createInvoicesFromCharges } from 'modules/services/backend-functions/create-invoices-from-charges';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useMessages, useNotifications } from 'utils/hooks';

import { Base_DataTable } from 'components/DataTable/Base_DataTable';
import type { MRTColumnDef, MRTTableInstance } from 'components/DataTable/MRT_Types';
import { ButtonWithTooltips } from 'ui';

interface GetChargeBPModalType {
    open: boolean;
    setOpen: Function;
    title?: string;
    description?: string;
    customLogic?: () => void;
    entityTableName: TableName;
    selectedRows: Record<string, any>;
}

type ViewType = Database['public']['Views']['vdocs_charges_business_partners_for_invoice'] | null;

export const GetChargeBPModal = memo(
    ({
        open,
        setOpen,
        title,
        description,
        customLogic,
        entityTableName,
        selectedRows
    }: GetChargeBPModalType) => {
        const { t } = useTranslation();
        const [chargeBPFullData, setChargeBPFullData] = useState<any[]>([]);
        const [chargeBPData, setChargeBPData] = useState<any[]>([]);

        const { openNotify } = useNotifications({ message: '' });
        const { openMessage } = useMessages({ message: '' });

        const tableInstanceRef: MutableRefObject<MRTTableInstance | null> = useRef(null);
        useEffect(() => {
            if (open) {
                console.log('selectedRows: ', selectedRows);
                const ids = _.map(selectedRows, 'id');
                fetchData(ids);
            }
        }, [entityTableName, selectedRows, open]);

        useEffect(() => {
            console.log('tableInstanceRef:', tableInstanceRef.current?.getSelectedRowModel);
        }, [tableInstanceRef]);

        const fetchData = async (ids: number[]) => {
            const BPData = await supabaseClient
                .from('vdocs_charges_business_partners_for_invoice')
                .select('*')
                .filter('charge_source_root_doc_table', 'eq', entityTableName)
                .filter('charge_source_root_doc_id', 'in', `(${ids?.join(', ')})`);

            if (BPData.error) {
                openMessage({ message: BPData.error.message, type: 'error' });
            }

            if (BPData.data && BPData.data?.length > 0) {
                const uniqueBPData = _.uniqWith(
                    BPData.data,
                    (BpA: any, BpB: any) =>
                        BpA.charge_agreement_id === BpB.charge_agreement_id &&
                        BpA.business_partner_id === BpB.business_partner_id &&
                        BpA.business_partner_role_code === BpB.business_partner_role_code
                );
                setChargeBPData(uniqueBPData);
                setChargeBPFullData(BPData.data);
            }
        };
        const handleClose = () => {
            setOpen(false);
        };

        const isRowSelected = () => selectedRows.length > 0;

        const columns: MRTColumnDef[] = useMemo(
            () => [
                {
                    accessorKey: 'charge_agreement_key',
                    header: t('charge_agreement_key') as string
                },
                {
                    accessorKey: 'business_partner_key',
                    header: t('business_partner_key') as string
                },
                {
                    accessorKey: 'business_partner_role_code',
                    header: t('business_partner_role_code') as string
                }
            ],
            [t]
        );

        const handleCreateInvoiceFromCharge = async () => {
            const selectedChargeRows = tableInstanceRef.current?.getSelectedRowModel().rows;
            if (selectedChargeRows?.length === 0) {
                openMessage({ message: 'Выберите БП + соглашение', type: 'error' });
            } else {
                const filteredData: Record<string, any>[] = [];
                const chargeBPAndAgreement: Record<string, any>[] = [];
                // Так как мы дедублицировали строки, то нам надо достать все charges для выбранных объектов
                selectedChargeRows?.map((row) => {
                    console.log('selectedChargeRows() row: ', row);
                    chargeBPAndAgreement.push({
                        charge_business_partner_id: row.original.id,
                        business_partner_id: row.original.business_partner_id,
                        charge_agreement_id: row.original.charge_agreement_id
                    });
                    const filtered = chargeBPFullData.filter((chargeBp: any) => {
                        return (
                            chargeBp.business_partner_id === row.original.business_partner_id &&
                            chargeBp.charge_agreement_id === row.original.charge_agreement_id
                            // chargeBp.business_partner_role_code ===
                            //     row.original.business_partner_role_code
                        );
                    });
                    filteredData.push(...[...filtered]);
                });
                console.log('filteredData: ', filteredData);
                console.log('selectedChargeRows:', selectedChargeRows);
                const chargeIdList: number[] = [];
                filteredData?.map((row) => {
                    if (row.root_id) {
                        chargeIdList.push(row.root_id);
                    }
                });
                const chargeIds = _.uniq(chargeIdList);
                console.log('chargeIds: ', chargeIds);
                console.log('chargeBPAndAgreement: ', chargeBPAndAgreement);
                const { data, error } = await createInvoicesFromCharges(
                    chargeIds,
                    chargeBPAndAgreement
                );

                const messageKey = 'create-invoices-from-charges';
                if (error && error.message) {
                    openMessage({
                        key: messageKey,
                        message: t('create-invoices-from-charges_error_message'),
                        type: 'error'
                    });

                    openNotify({
                        message: t('create-invoices-from-charges_error'),
                        description:
                            makeErrorReadable(error.message) ||
                            (t('create-invoices-from-charges_error_message') as string),
                        type: 'error',
                        duration: 0
                    });

                    console.error(error);
                }

                console.log('Create calculations dataset: ', data);

                if (data) {
                    openMessage({
                        key: messageKey,
                        message: t('create-invoices-from-charges-success'),
                        type: 'success'
                    });
                    setOpen(false);
                }

                // TODO: Если все ок, то выводим сообщение об успешном создании и закрываем модалку
            }
        };

        return (
            <>
                <Modal
                    closable
                    centered
                    title={title}
                    open={open}
                    onCancel={handleClose}
                    footer={[
                        <div className="button-modal-list">
                            <ButtonWithTooltips
                                id="select"
                                className="select"
                                onClick={() => {
                                    // handleClick();
                                    console.log(
                                        'tableInstanceRef:',
                                        tableInstanceRef.current?.getSelectedRowModel().rows
                                    );
                                    handleCreateInvoiceFromCharge();
                                    // setOpen(false);
                                }}
                                disabled={!isRowSelected()}
                            >
                                {t('select')}
                            </ButtonWithTooltips>
                        </div>
                    ]}
                >
                    <Base_DataTable
                        data={_.uniqWith(
                            chargeBPData,
                            (BpA, BpB) =>
                                BpA.charge_agreement_id === BpB.charge_agreement_id &&
                                BpA.business_partner_id === BpB.business_partner_id &&
                                BpA.business_partner_role_code === BpB.business_partner_role_code
                        )}
                        columns={columns}
                        viewName="vdocs_charges_business_partners_for_invoice"
                        tableInstanceRef={tableInstanceRef}
                    />
                </Modal>
            </>
        );
    }
);
