import { Checkbox } from 'antd';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

import './CheckboxField.scss';

interface CheckboxFieldProps {
    id: string;
    value: boolean | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: boolean) => void;
    skipBlur?: boolean;
    customDisplayText?: string;
    htmlId?: string;
}

export const CheckboxField = memo<CheckboxFieldProps>(
    ({
        id,
        value,
        mode,
        customDisplayText,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false,
        htmlId = id
    }) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (inputValue: boolean, onInputChange?: (newValue: boolean) => void) => (
                <Checkbox
                    id={`${htmlId}-value`}
                    className="base-field__checkbox"
                    checked={inputValue}
                    onChange={(e) => {
                        if (onInputChange) {
                            onInputChange(e.target.checked);
                            onChange(e.target.checked); // У компонента нет onBlur, решил так изменять
                        }
                    }}
                >
                    {customDisplayText || (inputValue ? t('answer_yes') : t('answer_no'))}
                </Checkbox>
            ),
            [htmlId, customDisplayText, t, onChange]
        );

        const renderView = useCallback(
            (viewValue: boolean) => {
                return (
                    <Checkbox
                        id={`${htmlId}-value`}
                        checked={viewValue}
                        className="base-field__checkbox"
                        disabled
                    >
                        {customDisplayText || (viewValue ? t('answer_yes') : t('answer_no'))}
                    </Checkbox>
                );
            },
            [htmlId, customDisplayText, t]
        );

        return (
            <BaseField
                required={required}
                id={id}
                htmlId={htmlId}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
