import React, { memo, useCallback } from 'react';
import { BaseField } from '../BaseField/BaseField';
import { ProvisionIndicator } from '../../../ui/ProvisionIndicator/ProvisionIndicator';

interface ProvisionIndicatorFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
}

export const ProvisionIndicatorField = memo<ProvisionIndicatorFieldProps>(
    ({
        id,
        value,
        mode,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false
    }) => {
        const renderView = useCallback(() => {
            return <ProvisionIndicator id={value} />;
        }, [value]);

        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderView}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
