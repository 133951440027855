import { CloseCircleFilled } from '@ant-design/icons';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { BaseField } from '../BaseField/BaseField';

interface TimeFieldProps {
    id: string;
    value: string | null; // ISO 8601 time string format, e.g. '12:34:56'
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    onChange: (newValue: string) => void;
    withLabel?: boolean;
    required?: boolean;
    htmlId?: string;
    skipBlur?: boolean;
}
// TimeField TimePicker с секундами HH:MM:SS TODO: возможно стоит переменовать в TimeFieldFull
export const TimeField = memo(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        onChange = () => {},
        withLabel = true,
        required = false,
        skipBlur = false,

        htmlId = id
    }: TimeFieldProps) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (
                inputValue: string | null,
                onInputChange?: (newValue: string | null) => void,
                onBlur?: () => void
            ) => {
                const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
                    e.stopPropagation();
                    if (onInputChange) {
                        onInputChange(null);
                    }
                };

                return (
                    <TimePicker
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        value={inputValue ? dayjs(inputValue, 'HH:mm:ss') : null}
                        onChange={(time: dayjs.Dayjs | null) => {
                            if (onInputChange && onBlur && time) {
                                const timeStr = time.local().format('HH:mm:ss').toString();
                                onInputChange(timeStr);
                            }
                        }}
                        format={UserSpecificFormat.getTimeFormatFull()}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        onBlur={onBlur}
                        inputReadOnly
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                );
            },
            [htmlId, popoverContainerHtmlId, t]
        );

        const renderView = useCallback(
            (viewValue: string | null) => {
                return (
                    <TimePicker
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        className="outlined-input"
                        value={viewValue ? dayjs(viewValue, 'HH:mm:ss') : null}
                        variant="borderless"
                        allowClear={false}
                        open={false}
                        inputReadOnly
                    />
                );
            },
            [htmlId, t]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                withLabel={withLabel}
                skipBlur={skipBlur}
            />
        );
    }
);
