import {
    MRT_Updater,
    MRT_VisibilityState as TableColumnsVisibilityState
} from 'material-react-table';
import { useEffect, useState } from 'react';

import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { useLocalStorage } from './useLocalStorage';

interface Feautures {
    expanding?: boolean;
    rowOrdering?: boolean;
    rowSelection?: boolean;
}

interface IUseColumnsVisibilityParams {
    columns?: MRTColumnDef[];
    features?: Feautures;
    lsKey: string;
}

// Хук для сохранения видимости колонок
export const useColumnsVisibility = ({
    columns,
    features = {},
    lsKey
}: IUseColumnsVisibilityParams) => {
    const { get: getLsValue, set: setLsValue } = useLocalStorage();
    const [visibilityMap, setVisibilityMap] = useState<TableColumnsVisibilityState>({});

    useEffect(() => {
        const lsVisibleKey = `${lsKey}:visible`;

        // Если есть ракурс то из него надо взять список видимых колонок
        let columnVisible = getLsValue<TableColumnsVisibilityState>(lsVisibleKey);

        if (!columnVisible) {
            // console.log('ZZZ COLUMNS:', columns);
            if (columns) {
                columnVisible = Object.fromEntries(
                    columns.map((column) => [column.accessorKey, true])
                );
            } else {
                columnVisible = {};
            }
        }

        if (features.expanding) {
            columnVisible!['mrt-row-expand'] = true;
        }

        if (features.rowOrdering) {
            columnVisible!['mrt-row-drag'] = true;
        }

        if (features.rowSelection) {
            columnVisible!['mrt-row-select'] = true;
        }

        setVisibilityMap(columnVisible!);
    }, [columns, setVisibilityMap, lsKey]);

    const onVisibilityChange = (updater: MRT_Updater<TableColumnsVisibilityState>) => {
        const lsVisibleKey = `${lsKey}:visible`;

        if (typeof updater !== 'function') {
            return;
        }

        if (visibilityMap) {
            const changes = updater(visibilityMap);

            // localStorage.setItem(lsKey, JSON.stringify(changes));
            setLsValue(lsVisibleKey, changes);

            setVisibilityMap(changes);
        }
    };

    return { visibilityMap, onVisibilityChange };
};
