import { Col, Form, Modal, Row } from 'antd';
import {
    Dispatch,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { DatasetWrapper } from 'components/DataTable/EditableObjects_DataTable/hocs/DatasetWrapper';
import { OperationsDetail_DataTable } from 'components/DataTable/OperationsDetail_DataTable';
import { TurnoverClassesDetail_DataTable } from 'components/DataTable/TurnoverClassesDetail_DataTable';
import { TableRefType } from 'components/DataTable/useTableBackendFeatures';
import { GroupCollapse } from 'components/GroupdCollaps/GroupCollaps';
import { CheckboxField, PrefixedSelectField } from 'components/fields';
import {
    CamundaVariable,
    camundaStartProcess
} from 'modules/services/backend-functions/camunda-start-process';
import { useSupabase } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Dataset, TableRow } from 'modules/supabase/types/Dataset';
import { Filter, TableDef } from 'modules/supabase/utils/supabaseClient';
import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';
import { TareGroupDetail_DataTable } from 'pages/docs/warehouse-storage-locations/TareGroupDetail_DataTable';
import { ButtonWithTooltips } from 'ui/ButtonWithTooltips/ButtonWithTooltips';
import { useMessages } from 'utils/hooks';
import { useStoreNavigate } from 'utils/store';

const CAMUNDA_PROCESS = 'GroupChange';

type GroupChangeOfStorageLocationsData = {
    objectIds: number[];
    Root: {
        data: {
            warehouse_functional_purpose_code?: string;
            warehouse_storage_category_code?: boolean;
            warehouse_storage_location_size_id?: number;
            are_all_operations_blocked?: boolean;
            should_track_rate_level2?: boolean;
            should_track_rate_level3?: boolean;
            can_have_few_cargo_units?: boolean;
            can_mix_products?: boolean;
        };
        editableFields: string[];
        tableName: string;
    };
    Child: {
        tableName: string;
        data: Record<string, any>;
    }[];
};

type OperationType = {
    warehouse_storage_location_operation_code: string;
    child_index: number;
};

type TurnoverClassesType = {
    turnover_class_code: string;
    child_index: number;
};

type TareGroupsType = {
    warehouse_tare_group_id: string;
    child_index: number;
};

type StorageLocationTablesRef = {
    GetTurnoverClasses: () => TurnoverClassesType[];
    GetOperations: () => OperationType[];
    GetTareGroups: () => TareGroupsType[];
};

const tableDefs: TableDef[] = [
    {
        tableName: 'docs_warehouse_storage_locations',
        viewName: 'vdocs_warehouse_storage_locations'
    },

    {
        tableName: 'docs_warehouse_storage_location_turnover_classes',
        viewName: 'vdocs_warehouse_storage_location_turnover_classes'
    },
    {
        tableName: 'docs_warehouse_storage_location_operations',
        viewName: 'vdocs_warehouse_storage_location_operations'
    },
    {
        tableName: 'docs_warehouse_storage_location_tare_groups',
        viewName: 'vdocs_warehouse_storage_location_tare_groups'
    }
];

const StorageLocationTables = forwardRef<
    StorageLocationTablesRef,
    { open: boolean; data: any; setData: any }
>(({ open, data, setData }, ref) => {
    const [dataset, setDataset] = useDataset();

    useEffect(() => {
        setDataset((prev) => {
            const newDataset = new Dataset(
                prev.root_id,
                'edit',
                prev.tables.map((table) => {
                    const newTable = { ...table, rows: [] };
                    return newTable;
                })
            );
            return newDataset;
        });
    }, [open]);

    const turnoverClassRef = useRef<TableRefType>(null);
    const operationRef = useRef<TableRefType>(null);
    const tareGroupRef = useRef<TableRefType>(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                GetTurnoverClasses: (): TurnoverClassesType[] => {
                    const turnoverClasses: TurnoverClassesType[] = [];

                    const turnoverClassesTable = turnoverClassRef?.current?.data;

                    if (turnoverClassesTable) {
                        for (let i = 0; i < turnoverClassesTable.length; i++) {
                            turnoverClasses.push({
                                turnover_class_code: turnoverClassesTable[i].turnover_class_code,
                                child_index: turnoverClassesTable[i].child_index
                            });
                        }
                    }

                    return turnoverClasses;
                },

                GetOperations: (): OperationType[] => {
                    const operations: OperationType[] = [];

                    const operationTable = operationRef?.current?.data;

                    if (operationTable) {
                        for (let i = 0; i < operationTable.length; i++) {
                            operations.push({
                                warehouse_storage_location_operation_code:
                                    operationTable[i].warehouse_storage_location_operation_code,
                                child_index: operationTable[i].child_index
                            });
                        }
                    }

                    return operations;
                },

                GetTareGroups: (): TareGroupsType[] => {
                    const tareGroups: TareGroupsType[] = [];

                    const tareGroupsTable = tareGroupRef?.current?.data;

                    if (tareGroupsTable) {
                        for (let i = 0; i < tareGroupsTable.length; i++) {
                            tareGroups.push({
                                warehouse_tare_group_id: tareGroupsTable[i].warehouse_tare_group_id,
                                child_index: tareGroupsTable[i].child_index
                            });
                        }
                    }

                    return tareGroups;
                }
            };
        },
        [operationRef, tareGroupRef, turnoverClassRef]
    );

    return (
        <>
            <Row gutter={100}>
                <Col md={24} lg={24}>
                    <GroupCollapse titleTextId={'turnover_classes'}>
                        {dataset.tables.find(
                            (table) =>
                                table.viewName ===
                                'vdocs_warehouse_storage_location_turnover_classes'
                        ) && (
                            <>
                                <CheckboxField
                                    id="isTurnoverClassesEditable"
                                    mode="edit"
                                    value={
                                        data?.isTurnoverClassesEditable
                                            ? data?.isTurnoverClassesEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isTurnoverClassesEditable: newValue
                                            };
                                        });
                                    }}
                                />
                                <TurnoverClassesDetail_DataTable
                                    childViewName="vdocs_warehouse_storage_location_turnover_classes"
                                    ref={turnoverClassRef}
                                />
                            </>
                        )}
                    </GroupCollapse>
                </Col>

                <Col md={24} lg={12}>
                    <GroupCollapse titleTextId="operations">
                        {dataset.tables.find(
                            (table) =>
                                table.viewName === 'vdocs_warehouse_storage_location_operations'
                        ) && (
                            <>
                                <CheckboxField
                                    id="isOperationsEditable"
                                    mode="edit"
                                    value={
                                        data?.isOperationsEditable
                                            ? data?.isOperationsEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isOperationsEditable: newValue
                                            };
                                        });
                                    }}
                                />
                                <OperationsDetail_DataTable
                                    childViewName="vdocs_warehouse_storage_location_operations"
                                    ref={operationRef}
                                />
                            </>
                        )}
                    </GroupCollapse>
                </Col>

                <Col md={24} lg={12}>
                    <GroupCollapse titleTextId="tare_groups">
                        {dataset.tables.find(
                            (table) =>
                                table.viewName === 'vdocs_warehouse_storage_location_tare_groups'
                        ) && (
                            <>
                                <CheckboxField
                                    id="isTareGroupsEditable"
                                    mode="edit"
                                    value={
                                        data?.isTareGroupsEditable
                                            ? data?.isTareGroupsEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isTareGroupsEditable: newValue
                                            };
                                        });
                                    }}
                                />
                                <TareGroupDetail_DataTable
                                    ref={tareGroupRef}
                                    childViewName="vdocs_warehouse_storage_location_tare_groups"
                                />
                            </>
                        )}
                    </GroupCollapse>
                </Col>
            </Row>
        </>
    );
});

export const GroupChangeOfStorageLocationsModal = ({
    open,
    setOpen,
    filters
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    filters: Filter[] | undefined;
}) => {
    const { t, i18n } = useTranslation();
    const navigate = useStoreNavigate();

    const [data, setData] = useState<any>(() => ({}));

    const { openMessage } = useMessages({ message: '' });

    const supabase = useSupabase();

    const navigateToAsyncTaskResult = (asyncTaskId: number) => {
        navigate(`/settings/async-tasks/${asyncTaskId}`);
    };

    const fetchData = async () => {
        let warehouseStorageLocationsData = supabase
            .from('vdocs_warehouse_storage_locations')
            .select('*');

        if (filters) {
            filters.forEach((filter) => {
                warehouseStorageLocationsData = warehouseStorageLocationsData.filter(
                    filter.column,
                    filter.operator,
                    filter.value
                );
            });
        }

        const result = await warehouseStorageLocationsData;

        if (result.data) {
            return result.data;
        }

        return [];
    };

    const updateEditableFields = (updatedData: any, fieldNames: string[], isEditable: boolean) => {
        let newEditableFields = [...updatedData.Root.editableFields];

        fieldNames.forEach((fieldName) => {
            if (isEditable) {
                newEditableFields.push(fieldName);
            } else {
                newEditableFields = newEditableFields.filter(
                    (field: string) => field !== fieldName
                );
            }
        });

        return {
            ...updatedData,
            Root: {
                ...updatedData.Root,
                editableFields: newEditableFields
            }
        };
    };

    const formDataToCamundaVariables = async (
        data: any,
        isTurnoverClassesEditable: boolean,
        isOperationsEditable: boolean,
        isTareGroupsEditable: boolean,
        turnoverClasses: TurnoverClassesType[],
        operations: OperationType[],
        tareGroups: TareGroupsType[]
    ): Promise<CamundaVariable> => {
        const warehouseStorageLocations = await fetchData();

        let updatedData: GroupChangeOfStorageLocationsData = {
            objectIds: warehouseStorageLocations.map(
                (warehouseStorageLocation: any) => warehouseStorageLocation.id
            ),
            Root: {
                data: {
                    warehouse_functional_purpose_code: data.warehouse_functional_purpose_code,
                    warehouse_storage_category_code: data.warehouse_storage_category_code,
                    warehouse_storage_location_size_id: data.warehouse_storage_location_size_id,
                    are_all_operations_blocked: data.are_all_operations_blocked,
                    should_track_rate_level2: data.should_track_rate_level2,
                    should_track_rate_level3: data.should_track_rate_level3,
                    can_have_few_cargo_units: data.can_have_few_cargo_units,
                    can_mix_products: data.can_mix_products
                },
                editableFields: [],
                tableName: 'docs_warehouse_storage_locations'
            },
            Child: []
        };

        if (isTareGroupsEditable) {
            updatedData.Child.push({
                tableName: 'docs_warehouse_storage_location_tare_groups',
                data: tareGroups
            });
        }

        if (isOperationsEditable) {
            updatedData.Child.push({
                tableName: 'docs_warehouse_storage_location_operations',
                data: operations
            });
        }

        if (isTurnoverClassesEditable) {
            updatedData.Child.push({
                tableName: 'docs_warehouse_storage_location_turnover_classes',
                data: turnoverClasses
            });
        }

        updatedData = updateEditableFields(
            updatedData,
            ['warehouse_functional_purpose_code'],
            data.isWarehouseFunctionalPurposeCodeEditable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['warehouse_storage_category_code'],
            data.isWarehouseStorageCategoryCodeEditable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['warehouse_storage_location_size_id'],
            data.isWarehouseStorageLocationSizeIdEditable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['are_all_operations_blocked'],
            data.isAreAllOperationsBlockedEditable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['should_track_rate_level2'],
            data.isShouldTrackRateLevel2Editable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['should_track_rate_level3'],
            data.isShouldTrackRateLevel3Editable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['can_have_few_cargo_units'],
            data.isCanHaveFewCargoUnitsEditable
        );

        updatedData = updateEditableFields(
            updatedData,
            ['can_mix_products'],
            data.isCanMixProductsEditable
        );

        const variables: CamundaVariable = {
            objectIds: {
                type: 'String',
                value: JSON.stringify(updatedData.objectIds)
            },
            rootData: {
                type: 'String',
                value: JSON.stringify(updatedData.Root.data)
            },
            rootEditableFields: {
                type: 'String',
                value: JSON.stringify(updatedData.Root.editableFields)
            },
            rootTableName: {
                type: 'String',
                value: updatedData.Root.tableName
            },
            childData: {
                type: 'String',
                value: JSON.stringify(updatedData.Child)
            }
        };

        return variables;
    };

    const handleGroupChangeOfStorageLocations = useCallback(
        async (variables: CamundaVariable) => {
            const startCamundaProcess = async () => {
                const process = await camundaStartProcess(CAMUNDA_PROCESS, {
                    variables
                });

                if (process.error) {
                    openMessage({
                        message: t(`${process.error.message}`),
                        type: 'error'
                    });
                    return;
                }

                openMessage({
                    message: t('group_change_request_successfully_created'),
                    type: 'success'
                });

                setOpen(false);

                const asyncTaskId = process?.data?.asyncTaskId as number;

                if (asyncTaskId) {
                    navigateToAsyncTaskResult(asyncTaskId);
                }
            };

            startCamundaProcess();
        },
        [camundaStartProcess, openMessage, data, t, i18n]
    );

    const formFields = useMemo(() => {
        return Object.keys(data).map((field) => {
            return {
                name: field,
                value: (data as Record<string, any>)[field]
            };
        });
    }, [data, open]);

    const tablesRef = useRef<StorageLocationTablesRef>(null);

    return (
        <>
            <Modal
                centered
                title={t('group_change')}
                open={open}
                onCancel={() => setOpen(false)}
                width={'70%'}
                footer={<></>}
            >
                <Form
                    fields={formFields}
                    onFinish={async (formData) => {
                        let operations: OperationType[] = [];
                        let tareGroups: TareGroupsType[] = [];
                        let turnoverClasses: TurnoverClassesType[] = [];
                        let isTurnoverClassesEditable: boolean = false;
                        let isOperationsEditable: boolean = false;
                        let isTareGroupsEditable: boolean = false;
                        if (tablesRef.current) {
                            operations = tablesRef.current.GetOperations();
                            tareGroups = tablesRef.current.GetTareGroups();
                            turnoverClasses = tablesRef.current.GetTurnoverClasses();
                            isTurnoverClassesEditable = data.isTurnoverClassesEditable
                                ? data.isTurnoverClassesEditable
                                : false;
                            isOperationsEditable = data.isOperationsEditable
                                ? data.isOperationsEditable
                                : false;
                            isTareGroupsEditable = data.isTareGroupsEditable
                                ? data.isTareGroupsEditable
                                : false;
                        }
                        const variables = await formDataToCamundaVariables(
                            data,
                            isTurnoverClassesEditable,
                            isOperationsEditable,
                            isTareGroupsEditable,
                            turnoverClasses,
                            operations,
                            tareGroups
                        );
                        handleGroupChangeOfStorageLocations(variables);
                    }}
                >
                    <Col md={12} lg={24}>
                        <GroupCollapse titleTextId="common_info">
                            <>
                                <PrefixedSelectField
                                    id="warehouse_functional_purpose_code"
                                    mode={'edit'}
                                    prefix="warehouse_functional_purpose_"
                                    value={data as TableRow}
                                    viewName="vdicts_warehouse_functional_purposes"
                                    onChange={(newValue) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                ...newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isWarehouseFunctionalPurposeCodeEditable"
                                    mode="edit"
                                    value={
                                        data?.isWarehouseFunctionalPurposeCodeEditable
                                            ? data?.isWarehouseFunctionalPurposeCodeEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isWarehouseFunctionalPurposeCodeEditable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                            <>
                                <PrefixedSelectField
                                    id="warehouse_storage_category_code"
                                    mode={'edit'}
                                    prefix="warehouse_storage_category_"
                                    value={data as TableRow}
                                    viewName="vdicts_warehouse_storage_categories"
                                    onChange={(newValue) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                ...newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isWarehouseStorageCategoryCodeEditable"
                                    mode="edit"
                                    value={
                                        data?.isWarehouseStorageCategoryCodeEditable
                                            ? data?.isWarehouseStorageCategoryCodeEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isWarehouseStorageCategoryCodeEditable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                        </GroupCollapse>
                    </Col>
                    <Col md={12} lg={24}>
                        <GroupCollapse titleTextId="permissions">
                            <>
                                <PrefixedSelectField
                                    id="warehouse_storage_location_size_id"
                                    mode="edit"
                                    prefix="warehouse_storage_location_size_"
                                    value={data as TableRow}
                                    viewName="vdocs_warehouse_storage_location_sizes"
                                    onChange={(newValue) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                ...newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isWarehouseStorageLocationSizeIdEditable"
                                    mode="edit"
                                    value={
                                        data?.isWarehouseStorageLocationSizeIdEditable
                                            ? data?.isWarehouseStorageLocationSizeIdEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isWarehouseStorageLocationSizeIdEditable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                            <>
                                <CheckboxField
                                    id="can_have_few_cargo_units"
                                    mode="edit"
                                    value={
                                        data?.can_have_few_cargo_units
                                            ? data?.can_have_few_cargo_units
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                can_have_few_cargo_units: newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isCanHaveFewCargoUnitsEditable"
                                    mode="edit"
                                    value={
                                        data?.isCanHaveFewCargoUnitsEditable
                                            ? data?.isCanHaveFewCargoUnitsEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isCanHaveFewCargoUnitsEditable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                            <>
                                <CheckboxField
                                    id="can_mix_products"
                                    mode="edit"
                                    value={data?.can_mix_products ? data?.can_mix_products : false}
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                can_mix_products: newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isCanMixProductsEditable"
                                    mode="edit"
                                    value={
                                        data?.isCanMixProductsEditable
                                            ? data?.isCanMixProductsEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isCanMixProductsEditable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                            <>
                                <CheckboxField
                                    id="are_all_operations_blocked"
                                    mode="edit"
                                    value={
                                        data?.are_all_operations_blocked
                                            ? data?.are_all_operations_blocked
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                are_all_operations_blocked: newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isAreAllOperationsBlockedEditable"
                                    mode="edit"
                                    value={
                                        data?.isAreAllOperationsBlockedEditable
                                            ? data?.isAreAllOperationsBlockedEditable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isAreAllOperationsBlockedEditable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                            <>
                                <CheckboxField
                                    id="should_track_rate_level2"
                                    mode="edit"
                                    value={
                                        data?.should_track_rate_level2
                                            ? data?.should_track_rate_level2
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                should_track_rate_level2: newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isShouldTrackRateLevel2Editable"
                                    mode="edit"
                                    value={
                                        data?.isShouldTrackRateLevel2Editable
                                            ? data?.isShouldTrackRateLevel2Editable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isShouldTrackRateLevel2Editable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                            <>
                                <CheckboxField
                                    id="should_track_rate_level3"
                                    mode="edit"
                                    value={
                                        data?.should_track_rate_level3
                                            ? data?.should_track_rate_level3
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                should_track_rate_level3: newValue
                                            };
                                        });
                                    }}
                                />
                                <CheckboxField
                                    id="isShouldTrackRateLevel3Editable"
                                    mode="edit"
                                    value={
                                        data?.isShouldTrackRateLevel3Editable
                                            ? data?.isShouldTrackRateLevel3Editable
                                            : false
                                    }
                                    onChange={(newValue: any) => {
                                        setData((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                isShouldTrackRateLevel3Editable: newValue
                                            };
                                        });
                                    }}
                                />
                            </>
                        </GroupCollapse>
                    </Col>

                    {open && (
                        <DatasetWrapper
                            tableName={'docs_warehouse_storage_locations'}
                            viewName={'vdocs_warehouse_storage_locations'}
                            mode="edit"
                            tableDefs={tableDefs}
                        >
                            <StorageLocationTables
                                ref={tablesRef}
                                open={open}
                                data={data}
                                setData={setData}
                            />
                        </DatasetWrapper>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <ButtonWithTooltips
                            id="group_change_of_storage_locations"
                            tooltipTitle={t('group_change_of_storage_locations')}
                            key="submit"
                            htmlType="submit"
                        >
                            {t('group_change_of_storage_locations')}
                        </ButtonWithTooltips>
                    </div>
                </Form>
            </Modal>
        </>
    );
};
