import { lazy } from 'react';
import { Routes } from 'routing/route';
import { RouteTypeMassive } from 'routing/routesTypes';

const WorkerTerminal = lazy(() =>
    import('pages/worker-terminal').then((module) => ({
        default: module.WorkerTerminal
    }))
);

const employeeApps: RouteTypeMassive[] = [
    {
        path: '',
        tablePage: WorkerTerminal
    }
];

export const WmsEmployeeRoutes = new Routes('/worker-terminal', employeeApps);
