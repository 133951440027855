import { QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Popover } from 'antd';

interface HelpIconProps extends React.PropsWithChildren {
    text?: string;
    trigger?: 'click' | 'hover';
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
}

export const HelpIcon: React.FC<HelpIconProps> = ({
    text,
    children,
    style,
    iconStyle,
    trigger = 'click'
}) => {
    return (
        <Flex
            style={{
                width: '100%',
                alignItems: !children ? 'center' : undefined,
                justifyContent: !children ? 'center' : undefined,
                ...style
            }}
            gap={5}
        >
            {children}
            {text ? (
                <Popover
                    placement="bottomLeft"
                    arrow={false}
                    content={text}
                    destroyTooltipOnHide
                    mouseEnterDelay={0.4}
                    trigger={[trigger]}
                >
                    <QuestionCircleOutlined
                        style={{
                            justifySelf: 'flex-end',
                            color: 'rgba(0, 0, 0, 0.2)',
                            transition: 'all 0.3s ease',
                            ...iconStyle
                        }}
                    />
                </Popover>
            ) : null}
        </Flex>
    );
};
