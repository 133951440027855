import _ from 'lodash';
import React, { createContext, useContext } from 'react';
import { Dataset, ViewOrTableName } from 'modules/supabase/types/Dataset';

export interface ContextFilter {
    key: string;
    value: number | string | boolean;
    currView?: ViewOrTableName;
}

export const DatasetContext = createContext<
    | [
          Dataset,
          React.Dispatch<React.SetStateAction<Dataset>>,
          ContextFilter[] | null,
          React.Dispatch<React.SetStateAction<ContextFilter[] | null>>,
          () => void
      ]
    | null
>(null);

// все кому нужен Dataset могут получить его так
export const useDataset = (isThrowError = true) => {
    const context = useContext(DatasetContext);

    if (!context) {
        if (isThrowError) {
            throw new Error('useDataset must be used within a DatasetProvider');
        }

        // Ожидаем, что без провайдера никто не будет прокидывать
        return [];
    }

    return context;
};
