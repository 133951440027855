import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { callBackendFunction } from './BackendFunctions';

type SaveMultiDatasetResponse = Record<string, any> | null;

export async function createInvoice(
    trips: number[],
    shouldSave: boolean
): Promise<{ data: Record<string, any> | null; error: Error | null }> {
    const endpoint = 'create-invoice';
    const { data, error } = await callBackendFunction<SaveMultiDatasetResponse>(endpoint, {
        trips,
        shouldSave
    });

    if (error) {
        return { data: null, error: new Error(makeErrorReadable(error.message)) };
    }
    if (!data) {
        return { data: null, error: new Error(makeErrorReadable('no_data_returned')) };
    }
    return { data, error: null };
}

export async function createInvoiceFromEtranAct(
    refIds: number[],
    shouldSave: boolean
): Promise<{ data: Record<string, any> | null; error: Error | null }> {
    const endpoint = 'create-invoice-from-etran-act';
    const { data, error } = await callBackendFunction<SaveMultiDatasetResponse>(endpoint, {
        refIds,
        shouldSave
    });

    if (error) {
        return { data: null, error: new Error(`${error.message}`) };
    }
    if (!data) {
        return { data: null, error: new Error('No data returned') };
    }
    return { data, error: null };
}
