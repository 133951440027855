import { Col, InputNumber, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import dayjs, { QUnitType } from 'dayjs';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

type DynamicDateRangeOptions =
    | 'OPTION_TODAY' // Сегодня
    | 'OPTION_YESTERDAY' // Вчера
    | 'OPTION_WEEK' // Текущая неделя
    | 'OPTION_MONTH' // Текущий месяц
    | 'OPTION_YEAR' // Текущий год
    | 'OPTION_QUARTER' // Текущий квартал
    | 'OPTION_LAST_N_DAYS' // Последние N дней
    | 'OPTION_LAST_N_MONTHS' // Последние N месяцев
    | 'OPTION_LAST_N_YEARS' // Последние N лет
    | 'OPTION_LAST_N_QUARTERS' // Последние N кварталов
    | 'OPTION_LAST_AND_NEXT_N_DAYS' // -N и +N дней от текущего дня ;
    | 'OPTION_LAST_AND_NEXT_N_MONTH' // -N и +N месяцев от текущего дня
    | 'OPTION_LAST_AND_NEXT_N_YEARS' // -N и +N лет от текущего дня
    | 'OPTION_LAST_AND_NEXT_N_QUARTER'; // -N и +N кварталов от текущего дня

const DATE_RANGE_OPTIONS: DynamicDateRangeOptions[] = [
    'OPTION_TODAY',
    'OPTION_YESTERDAY',
    'OPTION_WEEK',
    'OPTION_MONTH',
    'OPTION_QUARTER',
    'OPTION_YEAR',
    'OPTION_LAST_N_DAYS',
    'OPTION_LAST_N_MONTHS',
    'OPTION_LAST_N_QUARTERS',
    'OPTION_LAST_N_YEARS',
    'OPTION_LAST_AND_NEXT_N_DAYS',
    'OPTION_LAST_AND_NEXT_N_MONTH',
    'OPTION_LAST_AND_NEXT_N_QUARTER',
    'OPTION_LAST_AND_NEXT_N_YEARS'
];

const isOptionWithParameter = (option: DynamicDateRangeOptions | undefined): boolean => {
    if (
        option === 'OPTION_LAST_AND_NEXT_N_DAYS' ||
        option === 'OPTION_LAST_AND_NEXT_N_MONTH' ||
        option === 'OPTION_LAST_AND_NEXT_N_QUARTER' ||
        option === 'OPTION_LAST_AND_NEXT_N_YEARS' ||
        option === 'OPTION_LAST_N_DAYS' ||
        option === 'OPTION_LAST_N_MONTHS' ||
        option === 'OPTION_LAST_N_QUARTERS' ||
        option === 'OPTION_LAST_N_YEARS'
    ) {
        return true;
    }

    return false;
};

type DynamicDateRangeValueType = {
    option: DynamicDateRangeOptions;
    parametr?: number;
};

export const getDateRangeByDynamicDateRangeValue = (
    dynamicDateRangeValue: DynamicDateRangeValueType
): {
    from: Date;
    to: Date;
} | null => {
    if (!dynamicDateRangeValue.option) {
        return null;
    }

    if (isOptionWithParameter(dynamicDateRangeValue.option) && !dynamicDateRangeValue.parametr) {
        return null;
    }

    const today = dayjs();

    if (dynamicDateRangeValue.option) {
        switch (dynamicDateRangeValue.option) {
            case 'OPTION_TODAY':
                return {
                    from: today.startOf('day').toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_WEEK':
                return {
                    from: today.startOf('week').toDate(),
                    to: today.endOf('week').toDate()
                };
            case 'OPTION_MONTH':
                return {
                    from: today.startOf('month').toDate(),
                    to: today.endOf('month').toDate()
                };
            case 'OPTION_YEAR':
                return {
                    from: today.startOf('year').toDate(),
                    to: today.endOf('year').toDate()
                };
            case 'OPTION_QUARTER':
                return {
                    from: today.startOf('quarter' as QUnitType).toDate(),
                    to: today.endOf('quarter' as QUnitType).toDate()
                };
            case 'OPTION_YESTERDAY':
                return {
                    from: today.add(-1, 'day').startOf('day').toDate(),
                    to: today.add(-1, 'day').endOf('day').toDate()
                };
            case 'OPTION_LAST_N_DAYS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'day')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_N_MONTHS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'month')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_N_YEARS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'year')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_N_QUARTERS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'quarter')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_DAYS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'day')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'day')
                        .endOf('day')
                        .toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_MONTH':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'month')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'month')
                        .endOf('day')
                        .toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_QUARTER':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'quarter')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'quarter')
                        .endOf('day')
                        .toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_YEARS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'year')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'year')
                        .endOf('day')
                        .toDate()
                };
            default:
                return null;
        }
    }

    return null;
};

interface DynamicDateRangeFieldProps {
    id: string;
    value: DynamicDateRangeValueType | null | undefined;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: DynamicDateRangeValueType) => void;
    skipBlur?: boolean;
}

export const DynamicDateRangeField = memo<DynamicDateRangeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false
    }) => {
        const { t } = useTranslation();

        const options: DefaultOptionType[] = useMemo(() => {
            return DATE_RANGE_OPTIONS.map((option) => {
                return {
                    label: t(option.toLowerCase()),
                    value: option
                };
            });
        }, [t]);

        const renderInput = useCallback(() => {
            return (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col style={{ width: '50%' }}>
                        <Select
                            id={`${id}-value`}
                            placeholder={t('no_value')}
                            disabled={mode === 'view'}
                            getPopupContainer={
                                popoverContainerHtmlId
                                    ? () =>
                                          document.getElementById(
                                              popoverContainerHtmlId
                                          ) as HTMLElement
                                    : undefined
                            }
                            value={value?.option}
                            style={{ width: '100%' }}
                            options={options}
                            onChange={(newOption) => {
                                onChange({
                                    option: newOption,
                                    parametr: 0
                                });
                            }}
                        />
                    </Col>

                    {isOptionWithParameter(value?.option) && (
                        <Col style={{ width: '50%' }}>
                            <InputNumber
                                id={`${id}-value`}
                                value={value?.parametr}
                                type="number"
                                precision={0}
                                onChange={(newParametrValue) => {
                                    onChange({
                                        option: value?.option as DynamicDateRangeOptions,
                                        parametr: newParametrValue as number
                                    });
                                }}
                            />
                        </Col>
                    )}
                </Row>
            );
        }, [
            id,
            t,
            mode,
            popoverContainerHtmlId,
            value?.option,
            value?.parametr,
            options,
            onChange
        ]);

        const renderView = useCallback(() => {
            return (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col style={{ width: '50%' }}>
                        <Select
                            id={`${id}-value`}
                            placeholder={t('no_value')}
                            disabled={mode === 'view'}
                            value={value?.option}
                            style={{ width: '100%' }}
                            options={options}
                            onChange={(newOption) => {
                                onChange({
                                    option: newOption,
                                    parametr: 0
                                });
                            }}
                        />
                    </Col>

                    {isOptionWithParameter(value?.option) && (
                        <Col style={{ width: '50%' }}>
                            <InputNumber
                                id={`${id}-value`}
                                value={value?.parametr}
                                type="number"
                                disabled={mode === 'view'}
                                precision={0}
                                onChange={(newParametrValue) => {
                                    onChange({
                                        option: value?.option as DynamicDateRangeOptions,
                                        parametr: newParametrValue as number
                                    });
                                }}
                            />
                        </Col>
                    )}
                </Row>
            );
        }, [id, t, mode, value?.option, value?.parametr, options, onChange]);

        return (
            <BaseField
                required={required}
                id={id}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
