import { Form, Input, Modal } from 'antd';
import { Dispatch, SetStateAction, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditOutlined } from '@ant-design/icons';

import { ButtonWithTooltips } from 'ui';
import { BaseField } from '../BaseField/BaseField';
import { NumberField } from '../NumberField/NumberField';

type DurationComponentsType = {
    years: number;
    months: number;
    days: number;
    minutes: number;
    hours: number;
    seconds: number;
};

const parsePostgresIntervalValueToDuration = (value: string): DurationComponentsType => {
    const findYears = /([0-9]*) year/g;
    const yearsResult = findYears.exec(value);

    const findMonths = /([0-9]*) mon/g;
    const monthsResult = findMonths.exec(value);

    const findDays = /([0-9]*) day/g;
    const daysResult = findDays.exec(value);

    const findHoursMinutesSeconds = /([0-9]*):([0-9]*):([0-9]*)/g;
    const hmsResult = findHoursMinutesSeconds.exec(value);

    return {
        years: yearsResult && yearsResult[1] ? parseInt(yearsResult[1], 10) : 0,
        months: monthsResult && monthsResult[1] ? parseInt(monthsResult[1], 10) : 0,
        days: daysResult && daysResult[1] ? parseInt(daysResult[1], 10) : 0,
        hours: hmsResult && hmsResult[1] ? parseInt(hmsResult[1], 10) : 0,
        minutes: hmsResult && hmsResult[2] ? parseInt(hmsResult[2], 10) : 0,
        seconds: hmsResult && hmsResult[3] ? parseInt(hmsResult[3], 10) : 0
    };
};

const TimeDurationEditModal = ({
    value,
    setValue,
    open,
    setOpen
}: {
    value: string; // 3 years 2 months 2 days 02:02:02	postgres format
    setValue: Function;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();

    const [duration, setDuration] = useState<DurationComponentsType>({
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const parseDurationToPostgresValue = (dur: DurationComponentsType): string => {
        const durationValues = dur as Record<string, number>;

        const durationComponents = Object.keys(dur);
        let postgresValue = '';

        const hmsValue = {
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        for (let i = 0; i < durationComponents.length; i++) {
            const durationComponent = durationComponents[i];
            const durationValue = durationValues[durationComponent];

            let durationISOUnit = null;

            if (durationComponent) {
                switch (durationComponent) {
                    case 'years':
                        durationISOUnit = 'year';
                        break;
                    case 'months':
                        durationISOUnit = 'mon';
                        break;
                    case 'days':
                        durationISOUnit = 'day';
                        break;
                    case 'hours':
                        hmsValue.hours = durationValue;
                        break;
                    case 'minutes':
                        hmsValue.minutes = durationValue;
                        break;
                    case 'seconds':
                        hmsValue.seconds = durationValue;
                        break;
                    default:
                        continue;
                }

                if (durationISOUnit && durationValue) {
                    if (durationValue > 1) {
                        durationISOUnit = `${durationISOUnit}s`;
                    }

                    postgresValue = `${postgresValue} ${durationValue} ${durationISOUnit}`;
                }
            }
        }

        // FIXME: это плохой формат - надо поменять на ISO: P6Y5M4DT3H2M1S
        postgresValue = `${postgresValue} ${hmsValue.hours
            .toString()
            .padStart(2, '0')}:${hmsValue.minutes.toString().padStart(2, '0')}:${hmsValue.seconds
            .toString()
            .padStart(2, '0')}`;

        return postgresValue === '' ? '0 hours' : postgresValue;
    };

    useEffect(() => {
        if (value) {
            const duration = parsePostgresIntervalValueToDuration(value);
            setDuration(duration);
        }
    }, [value]);

    return (
        <Modal
            centered
            title={t('edit_duration_modal_title')}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <ButtonWithTooltips
                    id="cancel"
                    tooltipTitle={t('cancel')}
                    type="default"
                    className=""
                    key="back"
                    onClick={() => setOpen(false)}
                >
                    {t('cancel')}
                </ButtonWithTooltips>,
                <ButtonWithTooltips
                    id="apply"
                    tooltipTitle={t('apply')}
                    key="submit"
                    className=""
                    type="primary"
                    onClick={() => {
                        setValue(parseDurationToPostgresValue(duration));
                        setOpen(false);
                    }}
                >
                    {t('apply')}
                </ButtonWithTooltips>
            ]}
        >
            <Form>
                <NumberField
                    id="year"
                    mode="edit"
                    min={0}
                    value={duration.years}
                    onChange={(value) => {
                        setDuration((prevValue) => {
                            return {
                                ...prevValue,
                                years: value
                            };
                        });
                    }}
                />

                <NumberField
                    id="month"
                    mode="edit"
                    min={0}
                    value={duration.months}
                    onChange={(value) => {
                        setDuration((prevValue) => {
                            return {
                                ...prevValue,
                                months: value
                            };
                        });
                    }}
                />

                <NumberField
                    id="day"
                    mode="edit"
                    min={0}
                    value={duration.days}
                    onChange={(value) => {
                        setDuration((prevValue) => {
                            return {
                                ...prevValue,
                                days: value
                            };
                        });
                    }}
                />

                <NumberField
                    id="hour"
                    mode="edit"
                    min={0}
                    value={duration.hours}
                    onChange={(value) => {
                        setDuration((prevValue) => {
                            return {
                                ...prevValue,
                                hours: value
                            };
                        });
                    }}
                />

                <NumberField
                    id="minute"
                    mode="edit"
                    min={0}
                    value={duration.minutes}
                    onChange={(value) => {
                        setDuration((prevValue) => {
                            return {
                                ...prevValue,
                                minutes: value
                            };
                        });
                    }}
                />

                <NumberField
                    id="second"
                    mode="edit"
                    min={0}
                    value={duration.seconds}
                    onChange={(value) => {
                        setDuration((prevValue) => {
                            return {
                                ...prevValue,
                                seconds: value
                            };
                        });
                    }}
                />
            </Form>
        </Modal>
    );
};

interface TimeDurationFieldProps {
    id: string;
    value: string | null; // FIXME: почему тут разные типы?
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    utc?: boolean;
    htmlId?: string;
    skipBlur?: boolean;
}
export const TimeDurationField = memo<TimeDurationFieldProps>(
    ({
        id,
        value,
        mode,
        utc = false,
        withLabel = true,
        required = false,
        onChange = () => {},
        htmlId = id,
        skipBlur = false
    }) => {
        const { t } = useTranslation();

        const [modalOpen, setModalOpen] = useState<boolean>(false);

        const translateValue = useCallback(
            (value: string): string => {
                let copyValue = `${value}`;

                const year = t('year');
                const month = t('month');
                const day = t('day');

                const durationTextComponents = copyValue.split(' ');

                for (let i = 0; i < durationTextComponents.length; i++) {
                    const component = durationTextComponents[i];

                    switch (component) {
                        case 'years':
                            copyValue = copyValue.replace(component, year);
                            break;
                        case 'year':
                            copyValue = copyValue.replace(component, year);
                            break;
                        case 'mons':
                            copyValue = copyValue.replace(component, month);
                            break;
                        case 'mon':
                            copyValue = copyValue.replace(component, month);
                            break;
                        case 'days':
                            copyValue = copyValue.replace(component, day);
                            break;
                        case 'day':
                            copyValue = copyValue.replace(component, day);
                            break;
                        default:
                            continue;
                    }
                }

                return copyValue;
            },
            [t]
        );

        const renderInput = useCallback(
            (
                inputValue: string | null,
                onInputChange?: (newValue: string | null) => void,
                onBlur?: () => void
            ) => {
                const translatedValue = translateValue(inputValue || '');

                return (
                    <>
                        <TimeDurationEditModal
                            open={modalOpen}
                            setOpen={setModalOpen}
                            value={inputValue || ''}
                            setValue={(isoValue: string) => {
                                if (onInputChange && onBlur) {
                                    onInputChange(isoValue);
                                    onChange(isoValue);
                                }
                            }}
                        />
                        <Input
                            id={`${htmlId}-value`}
                            placeholder={t('no_value') as string}
                            variant="borderless"
                            value={translatedValue}
                            onBlur={onBlur}
                            addonAfter={
                                <EditOutlined
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                />
                            }
                            readOnly
                        />
                    </>
                );
            },
            [translateValue, modalOpen, htmlId, t, onChange]
        );

        const renderView = useCallback(
            (viewValue: string | null) => {
                const translatedValue = translateValue(viewValue || '');

                // Если есть точка, значит у нас есть миллисекунды и надо округлить секунды до целого значения
                const secIndex = translatedValue.lastIndexOf(':');
                const dotIndex = translatedValue.indexOf('.');
                let roundedValue = '';
                if (secIndex !== -1 && dotIndex !== -1) {
                    const stringSeconds = translatedValue.substring(secIndex + 1);
                    const roundedSeconds = Math.round(parseFloat(stringSeconds));
                    roundedValue =
                        translatedValue.substring(0, secIndex + 1) + roundedSeconds.toString();
                } else {
                    roundedValue = translatedValue; // Если нет точки, возвращаем исходную строку
                }

                return (
                    <Input
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        variant="borderless"
                        value={roundedValue}
                        readOnly
                    />
                );
            },
            [htmlId, t, translateValue]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
