import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import { ButtonWithTooltips } from 'ui';

interface BaseDetailPageType {
    open: boolean;
    setOpen: Function;
    handleClick: any;
    title?: string;
    description?: string;
    customLogic?: () => void;
    updateItemId?: any;
    updatableColumns?: any;
}

export const DialogModal = memo(
    ({
        open,
        setOpen,
        handleClick,
        title,
        description,
        customLogic,
        updateItemId,
        updatableColumns
    }: BaseDetailPageType) => {
        const { t } = useTranslation();
        const handleClose = () => {
            setOpen(false);
        };
        return (
            <Modal
                closable
                centered
                title={title}
                open={open}
                onCancel={handleClose}
                footer={[
                    <div className="button-modal-list">
                        <ButtonWithTooltips
                            id="disagree"
                            className="disagree"
                            onClick={handleClose}
                        >
                            {t('disagree')}
                        </ButtonWithTooltips>
                        {customLogic && (
                            <ButtonWithTooltips
                                id="keep_it_anyway"
                                className="keep_it_anyway"
                                onClick={() => {
                                    customLogic();
                                    setOpen();
                                }}
                            >
                                {t('keep_it_anyway')}
                            </ButtonWithTooltips>
                        )}
                        <ButtonWithTooltips
                            id="agree"
                            className="agree"
                            onClick={() => {
                                handleClick();
                                setOpen(false);
                            }}
                        >
                            {t('agree')}
                        </ButtonWithTooltips>
                    </div>
                ]}
            >
                {description}
            </Modal>
        );
    }
);
