import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { BaseField } from '../BaseField/BaseField';

type TimeRange = {
    from_time: string | null;
    to_time: string | null;
};

type RangeValue<T> = [T | null, T | null];

interface TimeRangeFieldProps {
    id: string;
    value: { from_time: string | null; to_time: string | null } | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    onChange: (newValue: TimeRange) => void;
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
    htmlId?: string;
}

export const TimeRangeField = memo<TimeRangeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false,
        htmlId = id
    }) => {
        const { t } = useTranslation();

        const convertTimeToDate = (time: string) => {
            const timeParts: Record<string, any> = time.split(':');
            return dayjs()
                .set('hours', timeParts[0])
                .set('minutes', timeParts[1])
                .set('seconds', timeParts[2]);
        };

        const renderInput = useCallback(
            (
                inputValue: { from_time: string | null; to_time: string | null },
                onInputChange?: (newValue: {
                    from_time: string | null;
                    to_time: string | null;
                }) => void,
                onBlur?: () => void
            ) => {
                const value: RangeValue<Dayjs> = [
                    inputValue && inputValue.from_time
                        ? convertTimeToDate(inputValue.from_time)
                        : null,
                    inputValue && inputValue.to_time ? convertTimeToDate(inputValue.to_time) : null
                ];

                return (
                    // DatePicker.RangePicker подразумевает, что пользователь выбирает две даты, если одна из дат не выбрана, то возвращается null

                    <TimePicker.RangePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-edit`}
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        // Установка значения диапазона дат в компоненте выбора диапазона дат
                        value={value}
                        // Обработка изменения значения диапазона дат в компоненте выбора диапазона дат
                        onChange={(time) => {
                            if (!onInputChange || !onBlur) return;
                            let [from_time, to_time]: [dayjs.Dayjs | null, dayjs.Dayjs | null] =
                                time || [null, null];

                            if (from_time) {
                                from_time = from_time.second(0);
                            }

                            if (to_time) {
                                to_time = to_time.second(0);
                            }

                            onInputChange({
                                from_time: from_time && from_time.local().format('HH:mm:ss'),
                                to_time: to_time && to_time.local().format('HH:mm:ss')
                            });

                            onBlur();
                        }}
                        format={UserSpecificFormat.getTimeFormat()}
                        // onBlur={onBlur}
                        // changeOnBlur={false}
                        inputReadOnly
                        allowClear
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                );
            },
            [htmlId, popoverContainerHtmlId, t]
        );

        const renderView = useCallback(
            (viewValue: { from_time: string; to_time: string }) => {
                const value: RangeValue<Dayjs> = [
                    viewValue && viewValue.from_time ? dayjs(viewValue.from_time) : null,
                    viewValue && viewValue.to_time ? dayjs(viewValue.to_time) : null
                ];

                return (
                    <TimePicker.RangePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-view`}
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        format={UserSpecificFormat.getTimeFormat()}
                        value={value}
                        variant="borderless"
                        allowClear={false}
                        open={false}
                        inputReadOnly
                    />
                );
            },
            [htmlId, t]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
