import { Database } from 'modules/supabase/types/database.types';
import { FileImportOutput } from './FileImportOutput';
import { PrintFormOutput } from './PrintFormOutput';
import { StorageLocationGeneratorOutput } from './StorageLocationGeneratorOutput';
import { SuccessOutput } from './SuccessOutput';
import { WarehouseTasksOutput } from './WarehouseTasksOutput';
import { GroupChangeOutput } from './GroupChangeOutput';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type TaskOutputResolverProps = {
    task: ViewType;
};
export const TaskOutputResolver = ({ task }: TaskOutputResolverProps) => {
    const getTaskOutputComponent = () => {
        switch (task?.async_task_category_code) {
            case 'PRINTING_FORMS':
                return <PrintFormOutput task={task} />;
            case 'MASS_PRINTING_FORMS':
                return <PrintFormOutput task={task} />;
            // case 'TARES_GENERATOR':
            //     return <TareGeneratorOutput task={task} />;
            case 'DATE_IMPORT_FROM_EXCEL':
                return <FileImportOutput task={task} />;
            case 'DATE_IMPORT_FROM_ETRAN':
                return <FileImportOutput task={task} />;
            case 'DATA_IMPORT':
                return <FileImportOutput task={task} />;
            case 'WAREHOUSE_STORAGE_LOCATIONS_GENERATOR':
                return <StorageLocationGeneratorOutput task={task} />;
            case 'WMS_PLANNING_PLACEMENT':
                return <WarehouseTasksOutput task={task} />;
            case 'GROUP_CHANGE':
                return <GroupChangeOutput task={task} />;
            default:
                return <SuccessOutput task={task} />;
        }
    };

    return <>{getTaskOutputComponent()}</>;
};
