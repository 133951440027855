import { TranslationOutlined } from '@ant-design/icons';
import { Input, Typography } from 'antd';
import _ from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltips } from 'ui';
import { i18n, LANGUAGES } from 'utils/i18n/i18n';
import { MultilanguageValueType } from '../types';
import { LanguageTranslateDialog } from './components/LanguageTranslateDialog';

export type { MultilanguageValueType };

interface SmartMultilanguageFieldProps {
    value: MultilanguageValueType;
    onChange: (newValue: MultilanguageValueType) => void;
    label?: string;
    disabled?: boolean;
    fieldName?: string;
}

const initialValue = _.zipObject(i18n.languages, Array(i18n.languages.length).fill(''));

export const SmartMultilanguageField = memo<SmartMultilanguageFieldProps>(
    ({ value, onChange, label, disabled, fieldName = '' }) => {
        const {
            i18n: { language: currentLanguage },
            t
        } = useTranslation();

        const [languageTranslateDialogOpen, setLanguageTranslateDialogOpen] = useState(false);

        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const copyValue = { ...value };
                copyValue[currentLanguage as LANGUAGES] = e.target.value;

                if (Object.keys(copyValue).length === 0) {
                    onChange(null);
                } else {
                    onChange(copyValue);
                }
            },
            [value, currentLanguage, onChange]
        );

        return (
            <>
                <LanguageTranslateDialog
                    open={languageTranslateDialogOpen}
                    fieldName={fieldName}
                    setOpen={setLanguageTranslateDialogOpen}
                    value={value || initialValue}
                    setValue={onChange}
                />
                {label && <Typography.Text type="secondary">{label}</Typography.Text>}
                <Input
                    disabled={disabled}
                    placeholder={t('no_value') as string}
                    allowClear
                    addonAfter={
                        <ButtonWithTooltips
                            id="language-translate-button"
                            className="language-translate-button"
                            type="text"
                            onClick={() => {
                                setLanguageTranslateDialogOpen(true);
                            }}
                            icon={<TranslationOutlined />}
                            style={{
                                padding: 0,
                                width: '100%',
                                height: '100%',
                                color: 'rgba(0,0,0,.25)'
                            }}
                        />
                    }
                    value={value ? value[currentLanguage as LANGUAGES] : undefined}
                    onChange={handleChange}
                />
            </>
        );
    }
);
