import { CloudDownloadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { StorageError } from '@supabase/storage-js';
import { Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadProps } from 'antd/lib/upload';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { TableName } from 'modules/supabase/types/Dataset';
import { ButtonWithTooltips } from 'ui';
import { FILE_BUCKET } from 'utils/config/constants';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { translitRu } from 'utils/helpers/translit';
import { useMessages, useNotifications } from 'utils/hooks';
import { BaseField } from '../BaseField/BaseField';

// Способ подчеркнуть, что в некоторые поля должны присваивать именно URL. TS позволяет (:
type FileUrlType = string;

interface ViewFileType {
    url: FileUrlType;
    name: string;
}

export interface FilePickerFieldProps {
    htmlId: string;
    root_id: string;
    rootTableName: TableName;
    uploadPathToFile?: string;
    value: ViewFileType | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (options: { file_name: string; file_path: string }) => void;
    skipBlur?: boolean;
    bucket?: string;
    enableRemoveButton?: boolean;
    enablePreview?: boolean;
    enableDragger?: boolean;
}

const UploadInput: React.FC<UploadProps & { enableDragger?: boolean }> = ({
    children,
    ...props
}) => {
    return props.enableDragger ? (
        <Upload.Dragger {...props} customRequest={props.customRequest}>
            {children}
        </Upload.Dragger>
    ) : (
        <Upload {...props} customRequest={props.customRequest}>
            {children}
        </Upload>
    );
};

export const FilePickerField = memo<FilePickerFieldProps>(
    ({
        htmlId,
        root_id,
        rootTableName,
        value,
        mode,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false,
        enableRemoveButton = true,
        enablePreview = true,
        enableDragger = false,
        uploadPathToFile,
        bucket = FILE_BUCKET
    }) => {
        const { t } = useTranslation();

        const { openMessage, loadingMessage, successMessage } = useMessages({
            message: ''
        });
        const { openNotify } = useNotifications({ message: '' });

        const [fileList, setFileList] = useState<UploadFile[]>([]);

        const getFileUrlForDownload = useCallback(
            async (url: string) => {
                // Скачиваем файл из хранилища
                const { data, error } = await supabaseClient.storage.from(bucket).download(url);

                // Если ошибка прилетела, то бросаем исключение
                if (error) {
                    throw error;
                }

                // Создаем ссылку на скачивание файла
                const downloadUrl = URL.createObjectURL(data);

                return downloadUrl;
            },
            [bucket]
        );

        // Тут обновляем fileList для компонента upload, чтобы акутализировать текущий файл в ui и корректно отрисовать с функционалом
        useEffect(() => {
            (async () => {
                if (!value || !value.name || !value.url) {
                    return;
                }

                setFileList(([prev]) => [
                    { ...prev, uid: '0', name: value.name, status: 'uploading' }
                ]);

                try {
                    const downloadUrl = await getFileUrlForDownload(value.url);
                    setFileList(([prev]) => [
                        {
                            ...prev,
                            url: downloadUrl,
                            status: 'done',
                            linkProps: {
                                download: value.name
                            }
                        }
                    ]);
                } catch (error) {
                    setFileList(([prev]) => [{ ...prev, status: 'error' }]);
                }
            })();
        }, [getFileUrlForDownload, value]);

        // Функция для отправки файла на сервер
        const handleUpload = useCallback(
            async (file: RcFile | File) => {
                const messageKey = 'file_upload';
                loadingMessage(messageKey);

                try {
                    // translit file's name
                    Object.defineProperty(file, 'name', {
                        value: translitRu(file.name)
                    });

                    if (typeof file === 'string')
                        throw new Error(t('file_uncorrect_type') as string);
                    // Проверяем, что файл выбран
                    if (!file) {
                        throw new Error(t('file_not_choosen') as string);
                    }

                    const filePath = uploadPathToFile
                        ? `${uploadPathToFile}/${file.name}`
                        : `${rootTableName}/${root_id}/${file.name}`;

                    const { data, error } = await supabaseClient.storage
                        .from(bucket)
                        .upload(filePath, file);

                    // Если ошибка, то бросаем исключение
                    if (error) {
                        throw error;
                    }

                    // Успех
                    onChange({ file_name: file.name, file_path: data.path });

                    openMessage({
                        key: messageKey,
                        message: t('file_upload_success'),
                        type: 'success'
                    });
                } catch (error) {
                    openMessage({
                        key: messageKey,
                        type: 'error',
                        message: t('file_upload_error_message')
                    });
                    openNotify({
                        message: t('file_upload_error') as string,
                        description: makeErrorReadable((error as Error).message),
                        duration: 0,
                        type: 'error'
                    });

                    // eslint-disable-next-line no-console
                    console.error((error as Error).message); // точно будет message, так как явно его передаем выше
                    throw error;
                }
            },
            [
                bucket,
                loadingMessage,
                onChange,
                openMessage,
                openNotify,
                rootTableName,
                root_id,
                t,
                uploadPathToFile
            ]
        );

        // Функция удаления файла
        const handleRemoveFile = useCallback(
            async (file: RcFile | File) => {
                await supabaseClient.storage
                    .from(FILE_BUCKET)
                    .remove([`${rootTableName}/${root_id}/${file.name}`]);

                setFileList([]);

                onChange({ file_name: '', file_path: '' });

                const messageKey = 'file_remove';

                openMessage({
                    key: messageKey,
                    message: t('file_remove_success'),
                    type: 'success'
                });
            },
            [onChange, openMessage, rootTableName, root_id, t]
        );

        // Функция загрузки файла
        const handleDownload = useCallback(async () => {
            const messageKey = 'file_download';

            loadingMessage(messageKey);

            try {
                if (!value) {
                    openMessage({ key: messageKey, message: t('no_value'), type: 'error' });

                    return;
                }

                // Скачиваем файл из bucket storage в supabase
                const downloadUrl = await getFileUrlForDownload(value.url);

                // Создаем ссылку для скачивания
                const link = document.createElement('a');

                link.href = downloadUrl;
                link.download = value.name;

                // Кликаем по ссылке для начала скачивания
                document.body.appendChild(link);

                successMessage(messageKey);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                openMessage({
                    key: messageKey,
                    type: 'error',
                    message: t('file_download_error_message')
                });
                openNotify({
                    message: t('file_download_error') as string,
                    description: makeErrorReadable((error as Error).message),
                    duration: 0,
                    type: 'error'
                });

                // eslint-disable-next-line no-console
                console.error((error as StorageError | Error).message);
            }
        }, [
            getFileUrlForDownload,
            loadingMessage,
            openMessage,
            openNotify,
            successMessage,
            t,
            value
        ]);

        // Функция для рендера в моде создания, клонирования, редактирования
        const renderInput = useCallback(
            (inputValue: ViewFileType, _: any) => {
                return (
                    <UploadInput
                        id={`${htmlId}-value`}
                        // listType="picture"
                        onDownload={handleDownload}
                        showUploadList={{
                            showRemoveIcon: enableRemoveButton
                        }}
                        onRemove={(file) => handleRemoveFile(file as RcFile)}
                        customRequest={({ file, onSuccess, onError }) => {
                            const res = handleUpload(file as RcFile);
                            if (res) {
                                res.then(onSuccess).catch(onError);
                            }
                        }}
                        onChange={(info) => {
                            const newFileList = [...info.fileList].map((file) => {
                                const newFile = { ...file };
                                if (file.response) {
                                    newFile.url = file.response.url;
                                }
                                return newFile;
                            });

                            setFileList(newFileList);
                        }}
                        fileList={enablePreview ? fileList : []}
                        enableDragger={enableDragger}
                    >
                        {enableDragger ? (
                            <>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{t('click_or_drag_for_upload')}</p>
                            </>
                        ) : !inputValue?.name && !inputValue?.url ? (
                            <ButtonWithTooltips
                                className="file_picker_upload"
                                type="default"
                                id={htmlId || 'upload'}
                                tooltipTitle={t('upload')}
                                icon={<UploadOutlined />}
                            >
                                {t('upload')}
                            </ButtonWithTooltips>
                        ) : (
                            ''
                        )}
                    </UploadInput>
                );
            },
            [
                htmlId,
                handleDownload,
                enableRemoveButton,
                enablePreview,
                fileList,
                enableDragger,
                t,
                handleRemoveFile,
                handleUpload
            ]
        );

        // Функци для рендера в моде просмотра
        const renderView = useCallback(
            (_: any) => {
                return (
                    <Upload
                        id={`${htmlId}-value`}
                        beforeUpload={() => false}
                        onDownload={handleDownload}
                        // listType="picture"
                        customRequest={({ file, onSuccess, onError }) => {
                            const res = handleUpload(file as RcFile);
                            if (res) {
                                res.then(onSuccess).catch(onError);
                            }
                        }}
                        showUploadList={{
                            showRemoveIcon: false,
                            showDownloadIcon: true,
                            downloadIcon: <CloudDownloadOutlined />
                        }}
                        fileList={fileList}
                    />
                );
            },
            [htmlId, handleDownload, fileList, handleUpload]
        );

        return (
            <>
                <BaseField
                    id={htmlId}
                    required={required}
                    value={value}
                    mode={mode}
                    withLabel={withLabel}
                    onChange={onChange}
                    renderInput={renderInput}
                    renderView={renderView}
                    skipBlur={skipBlur}
                />
            </>
        );
    }
);
