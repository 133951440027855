import { callBackendFunction } from './BackendFunctions';

export type CamundaVariableTypes =
    | 'Boolean'
    | 'String'
    | 'Integer'
    | 'Array'
    | 'Object'
    | 'Null'
    | 'json';

export type CamundaVariable = {
    [key: string]: {
        value: any;
        type: CamundaVariableTypes;
    };
};

export type CamundaStartProcessType = {
    variables?: CamundaVariable;
    businessKey?: string;
    caseInstanceId?: string;
};
export async function camundaStartProcess(
    processDefinitionKey: string,
    params: CamundaStartProcessType = {}
): Promise<{ data: any; error: Error | null }> {
    const endpoint = `camunda-start-process/${processDefinitionKey}`;

    return callBackendFunction(endpoint, params);
}

export async function camundaSubmitForm(
    taskId: string,
    params: CamundaStartProcessType = {}
): Promise<{ data: any; error: Error | null }> {
    const endpoint = `camunda-sumbit-form/${taskId}`;

    return callBackendFunction(endpoint, params);
}

export async function camundaBPMNError(
    taskId: string,
    params: CamundaStartProcessType = {}
): Promise<{ data: any; error: Error | null }> {
    const endpoint = `camunda-handle-bpmn-error/${taskId}`;

    return callBackendFunction(endpoint, params);
}
