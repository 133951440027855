import { MRTTableInstance } from 'components/DataTable/MRT_Types';
import { FilterField } from 'components/DataTable/TableFilterMenu/TableFilterMenuTypes';
import {
    ViewDisplayPreferenceMenuProps,
    ViewDisplayPreferenceUIDataType
} from 'components/DataTable/ViewDisplayPreferenceToolBarButton';
import { useGenerateColumns } from 'components/DataTable/useGenerateColumns';
import { FilterButtonType } from 'components/FilterButton/FilterButton';
import { i18n } from 'utils/i18n/i18n';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { TableName, ViewName } from 'modules/supabase/types/Dataset';
import { Filter, buildFilter } from 'modules/supabase/utils/supabaseClient';
import { convertComplexFilterToSupabaseFilter } from 'modules/supabase/utils/supabaseFilterUtils';
import { useSelectedViewDisplayPreference } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { generateTableOptions } from 'components/DataTable/TableBackend/functions/generateTableOptions';
import _ from 'lodash';
import { IViewDisplayPreferencesContext } from './types';

export const ViewDisplayPreferencesDataContext = createContext<IViewDisplayPreferencesContext>({
    loaded: false,
    viewDisplayPreferenceMenuProps: null,
    filterButtonProps: null,
    getQueryFilter: () => {},
    filters: []
});

type ViewDisplayPreferencesProviderProps = {
    children?: ReactNode | undefined;
    viewName: ViewName;
    tableName: TableName;
    viewDisplayPreferenceTitle?: string;
};

const initPreferencesUIData = (): ViewDisplayPreferenceUIDataType => {
    return {
        columnOrder: [],
        columnFilterFns: {},
        columnSizing: {},
        columnVisibility: {},
        complexFilter: [],
        filtering: [],
        grouping: [],
        pageSize: 0,
        sorting: []
    };
};

export const ViewDisplayPreferencesDataProvider: React.FC<ViewDisplayPreferencesProviderProps> = ({
    children,
    tableName,
    viewName,
    viewDisplayPreferenceTitle
}) => {
    const [preferencesUIData, setPreferencesUIData] =
        useState<ViewDisplayPreferenceUIDataType>(initPreferencesUIData);

    const [filters, setFilters] = useState<{
        data: Filter[];
        processed: boolean;
    }>({
        processed: false,
        data: []
    });

    const {
        selectedViewDisplayPreference,
        setSelectedViewDisplayPreference,
        vdfLoaded,
        viewDisplayPreferenceSetting
    } = useSelectedViewDisplayPreference({
        viewName,
        title: viewDisplayPreferenceTitle
    });

    // Параметры из View Display Preferences
    useEffect(() => {
        const fetchData = async () => {
            if (vdfLoaded) {
                const options = generateTableOptions(viewDisplayPreferenceSetting);

                setPreferencesUIData((prev) => {
                    return {
                        ...prev,
                        columnFilterFns: options.columnFilterFns,
                        filtering: options.columnFilters,
                        complexFilter: options.complexFilter,
                        grouping: options.grouping,
                        sorting: options.sorting
                    };
                });
            }
        };
        fetchData();
    }, [viewDisplayPreferenceSetting, vdfLoaded]);

    const {
        columnVisibility,
        columnOrdering,
        columnSizing,
        loaded: columnsLoaded
    } = useGenerateColumns({
        viewOrTableName: viewName,
        viewDisplayPreferenceSettings: viewDisplayPreferenceSetting,
        enableExpanding: false,
        enableRowOrdering: false,
        enableRowSelection: false,
        doRequest: vdfLoaded
    });

    useEffect(() => {
        setPreferencesUIData((prev) => {
            return {
                ...prev,
                columnOrder: columnOrdering,
                columnSizing,
                columnVisibility
            };
        });
    }, [columnVisibility, columnOrdering, columnSizing]);

    const viewDisplayPreferenceMenuProps: ViewDisplayPreferenceMenuProps = useMemo(() => {
        return {
            viewDisplayPreferenceUIData: preferencesUIData,
            table: {
                options: {
                    meta: {
                        tableName,
                        viewName
                    }
                }
            } as unknown as MRTTableInstance, // TODO: unknown fix
            viewName,
            selectedViewDisplayPreference,
            setSelectedViewDisplayPreference
        };
    }, [
        preferencesUIData,
        tableName,
        viewName,
        selectedViewDisplayPreference,
        setSelectedViewDisplayPreference
    ]);

    const setFilter = useCallback(
        (complexFilter: FilterField[]) => {
            setPreferencesUIData((prev) => {
                return {
                    ...prev,
                    complexFilter
                };
            });
        },
        [setPreferencesUIData]
    );

    const filterButtonProps: FilterButtonType = useMemo(() => {
        return {
            filters: preferencesUIData.complexFilter,
            setFilters: setFilter,
            viewName,
            tableName
        };
    }, [preferencesUIData.complexFilter, setFilter, tableName, viewName]);

    const getQueryFilter = useCallback(
        (query: any) => {
            if (preferencesUIData?.complexFilter) {
                const addToFilter = convertComplexFilterToSupabaseFilter(
                    preferencesUIData?.complexFilter,
                    i18n.language
                );

                const applyFilter = buildFilter(query, addToFilter);

                return applyFilter;
            }

            return null;
        },
        [preferencesUIData?.complexFilter]
    );

    useEffect(() => {
        if (columnsLoaded && vdfLoaded) {
            const addToFilter = convertComplexFilterToSupabaseFilter(
                preferencesUIData?.complexFilter,
                i18n.language
            );

            if (!_.isEqual(addToFilter, filters.data)) {
                setFilters({
                    data: addToFilter,
                    processed: true
                });
            } else if (addToFilter.length === 0 && !filters.processed) {
                setFilters({
                    data: [],
                    processed: true
                });
            }
        }
    }, [preferencesUIData.complexFilter, columnsLoaded, vdfLoaded, i18n.language, filters]);

    // useEffect(() => {}, [vdfLoaded, columnsLoaded, processed]);

    return (
        <ViewDisplayPreferencesDataContext.Provider
            value={{
                loaded: vdfLoaded && columnsLoaded && filters.processed,
                viewDisplayPreferenceMenuProps,
                filterButtonProps,
                getQueryFilter,
                filters: filters.data
            }}
        >
            {children}
        </ViewDisplayPreferencesDataContext.Provider>
    );
};
