import { MetaField } from 'modules/services/backend-api/generated_info';
import { fieldRender as render } from 'smart/utils';
import { LANGUAGES } from 'utils/i18n/i18n';
import { IObjectWithId } from 'utils/store/MetaStore';

type FieldRenderType = {
    (options: {
        field: MetaField;
        language: LANGUAGES;
        fields: MetaField[];
        isViewAsLink?: boolean;
        rootMeta?: string;
        rootDataSource?: IObjectWithId;
    }): (value: unknown, row: IObjectWithId, rowIndex: any) => React.ReactNode;
};

export const fieldRender: FieldRenderType = ({
    field,
    language,
    fields,
    rootMeta,
    rootDataSource,
    isViewAsLink = false
}) => {
    return (value, row) => {
        return render({
            data: value,
            dataSource: row,
            language,
            metaFieldData: fields,
            fieldName: field.FieldName,
            isForTable: true,
            isViewAsLink,
            rootMeta,
            rootDataSource
        });
    };
};
