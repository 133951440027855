export const getPercentFormat = (percent = 0, successPercent = 0): React.ReactNode => {
    let color = '#3185D2';

    if (percent < 40) {
        color = '#EBBA3D';
    }

    if (percent < 30) {
        color = '#D71A21';
    }

    return <span style={{ color }}>{percent}%</span>;
};
