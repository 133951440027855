import { Input, Modal } from 'antd';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Cron, Locale } from 'react-js-cron';

import { EditOutlined } from '@ant-design/icons';
import { ButtonWithTooltips } from 'ui';
import { BaseField } from '../BaseField/BaseField';

interface CronFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    htmlId?: string;
}

const CronEditModal = ({
    value,
    setValue,
    open,
    setOpen
}: {
    setValue: Function;
    value: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t, i18n } = useTranslation();
    const [modalkValue, setModalValue] = useState<string>('');
    const locale = useMemo((): Locale => {
        return {
            everyText: t('everyText') as string,
            emptyMonths: t('emptyMonths') as string,
            emptyMonthDays: t('emptyMonthDays') as string,
            emptyMonthDaysShort: t('emptyMonthDaysShort') as string,
            emptyWeekDays: t('emptyWeekDays') as string,
            emptyWeekDaysShort: t('emptyWeekDaysShort') as string,
            emptyHours: t('emptyHours') as string,
            emptyMinutes: t('emptyMinutes') as string,
            emptyMinutesForHourPeriod: t('emptyMinutesForHourPeriod') as string,
            yearOption: t('yearOption') as string,
            monthOption: t('monthOption') as string,
            weekOption: t('weekOption') as string,
            dayOption: t('dayOption') as string,
            hourOption: t('hourOption') as string,
            minuteOption: t('minuteOption') as string,
            rebootOption: t('rebootOption') as string,
            prefixPeriod: t('prefixPeriod') as string,
            prefixMonths: t('prefixMonths') as string,
            prefixMonthDays: t('prefixMonthDays') as string,
            prefixWeekDays: t('prefixWeekDays') as string,
            prefixWeekDaysForMonthAndYearPeriod: t('prefixWeekDaysForMonthAndYearPeriod') as string,
            prefixHours: t('prefixHours') as string,
            prefixMinutes: t('prefixMinutes') as string,
            prefixMinutesForHourPeriod: t('prefixMinutesForHourPeriod') as string,
            suffixMinutesForHourPeriod: t('suffixMinutesForHourPeriod') as string,
            errorInvalidCron: t('errorInvalidCron') as string,
            clearButtonText: t('clearButtonText') as string,
            // altMonths: [
            //     t('January'),
            //     t('February'),
            //     t('March'),
            //     t('April'),
            //     t('May'),
            //     t('June'),
            //     t('July'),
            //     t('August'),
            //     t('September'),
            //     t('October'),
            //     t('November'),
            //     t('December')
            // ],
            // altWeekDays: [],
            months: [
                t('january'),
                t('february'),
                t('march'),
                t('april'),
                t('may'),
                t('june'),
                t('july'),
                t('august'),
                t('september'),
                t('october'),
                t('november'),
                t('december')
            ],
            weekDays: [
                t('sunday'),
                t('monday'),
                t('tuesday'),
                t('wednesday'),
                t('thursday'),
                t('friday'),
                t('saturday')
            ]
        };
    }, [t, i18n.language]);

    return (
        <>
            <Modal
                centered
                title={t('edit_duration_modal_title')}
                open={open}
                width={'10%'}
                onCancel={() => setOpen(false)}
                footer={[
                    <ButtonWithTooltips
                        id="apply"
                        tooltipTitle={t('apply')}
                        key="submit"
                        className=""
                        type="primary"
                        onClick={() => {
                            setValue(modalkValue);
                            setOpen(false);
                        }}
                    >
                        {t('apply')}
                    </ButtonWithTooltips>
                ]}
            >
                <div>
                    <Cron
                        className="cron-in-modal"
                        value={value}
                        setValue={(newValue: string) => {
                            setModalValue(newValue);
                        }}
                        leadingZero={true}
                        locale={locale}
                        clearButton={true}
                    />
                </div>
            </Modal>
        </>
    );
};

export const CronField = ({
    id,
    mode,
    value,
    onChange = () => {},
    withLabel = true,
    required = false,
    skipBlur = true,
    htmlId = id
}: CronFieldProps) => {
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const renderInput = useCallback(
        (inputValue: string, onInputChange?: (newValue: string) => void, onBlur?: () => void) => {
            return (
                <>
                    <CronEditModal
                        open={modalOpen}
                        setOpen={setModalOpen}
                        value={inputValue}
                        setValue={(val: string) => {
                            if (onInputChange) {
                                onInputChange(val);
                            }
                        }}
                    />
                    <Input
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        allowClear
                        value={inputValue}
                        onChange={(e) => {
                            if (onInputChange) {
                                onInputChange(e.target.value);
                            }
                        }}
                        addonAfter={
                            <EditOutlined
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            />
                        }
                        onBlur={onBlur}
                    />
                </>
            );
        },
        [id, t, modalOpen]
    );

    const renderView = useCallback(
        (viewValue: string) => {
            return (
                <Input
                    id={`${htmlId}-value`}
                    placeholder={t('no_value') as string}
                    variant="borderless"
                    value={viewValue}
                    readOnly
                />
            );
        },
        [id, t]
    );

    return (
        <BaseField
            id={id}
            htmlId={htmlId}
            required={required}
            value={value}
            mode={mode}
            withLabel={withLabel}
            onChange={onChange}
            renderInput={renderInput}
            renderView={renderView}
            skipBlur={skipBlur}
        />
    );
};
