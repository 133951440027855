import { Input as InputAntd } from 'antd';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import tr from 'react-phone-input-2/lang/tr.json';
import 'react-phone-input-2/lib/high-res.css';

import { ValidateStatus } from 'antd/lib/form/FormItem';
import { BaseField } from '../BaseField/BaseField';

interface PhoneFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    htmlId?: string;
    validateStatus?: ValidateStatus;
    validateMessage?: string;
}

// TODO: Add mask and validation
export const PhoneField = memo(
    ({
        id,
        value,
        mode,
        onChange = () => {},
        withLabel = true,
        required = false,
        skipBlur = false,
        htmlId = id,
        validateStatus,
        validateMessage
    }: PhoneFieldProps) => {
        const {
            t,
            i18n: { languages, language: lang }
        } = useTranslation();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        let visibleValue = value || '';

        const onBlurPhoneFieldValue = useCallback(() => {
            onChange(visibleValue);
        }, [visibleValue, onChange]);

        const renderInput = useCallback(() => {
            return (
                <PhoneInput
                    inputProps={{ id: `${htmlId}-value` }}
                    country={lang}
                    localization={lang === 'ru' ? ru : lang === 'tr' ? tr : undefined}
                    preferredCountries={
                        languages.map((l) => {
                            if (l === 'kk') return 'kz';
                            if (l === 'en') return 'gb';
                            return l;
                        }) as string[]
                    }
                    value={visibleValue}
                    onChange={(phone) => {
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        visibleValue = phone;
                    }}
                    onBlur={() => onChange(visibleValue)}
                />
            );
        }, [onBlurPhoneFieldValue, lang, languages]);

        const renderView = useCallback(() => {
            return (
                <InputAntd
                    id={`${htmlId}-value`}
                    placeholder={t('no_value') as string}
                    variant="borderless"
                    value={value ? `+${value}` : ''}
                    readOnly
                />
            );
        }, [htmlId, t, value]);

        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                htmlId={htmlId}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
                validateStatus={validateStatus}
                validateMessage={validateMessage}
            />
        );
    }
);
