/* eslint-disable prettier/prettier */
export const translitRu = (word = '') => {
    let answer = '';

    const converter: {[key: string]: string} = {
        а: 'a',     ё: 'e',     л: 'l',     с: 's',     ч: 'ch',    ь: '',
        б: 'b',     ж: 'zh',    м: 'm',     т: 't',     ш: 'sh',    ъ: '',
        в: 'v',     з: 'z',     н: 'n',     у: 'u',     щ: 'sch',   я: 'ya',
        г: 'g',     и: 'i',     о: 'o',     ф: 'f',     ы: 'y',
        д: 'd',     й: 'y',     п: 'p',     х: 'h',     э: 'e',
        е: 'e',     к: 'k',     р: 'r',     ц: 'c',     ю: 'yu',
        
        А: 'A',     Ё: 'E',     Л: 'L',     С: 'S',     Ч: 'Ch',    Э: 'E',
        Б: 'B',     Ж: 'Zh',    М: 'M',     Т: 'T',     Ш: 'Sh',    Ю: 'Yu',
        В: 'V',     З: 'Z',     Н: 'N',     У: 'U',     Щ: 'Sch',   Я: 'Ya',
        Г: 'G',     И: 'I',     О: 'O',     Ф: 'F',     Ь: '',
        Д: 'D',     Й: 'Y',     П: 'P',     Х: 'H',     Ы: 'Y',
        Е: 'E',     К: 'K',     Р: 'R',     Ц: 'C',     Ъ: ''
    };

    for (let i = 0; i < word.length; ++i) {
        if (!converter[word[i]]) {
            answer += word[i];
        } else {
            answer += converter[word[i]];
        }
    }

    return answer;
};
