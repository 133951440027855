import { MRT_RowSelectionState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableName, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';

import { useGenerateColumnsFromType } from 'modules/supabase/utils/tableCellUtils/generateColumnsFromType';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';
import {
    ConvertedTreeNode,
    ListItem,
    convertTableListToTree
} from 'utils/helpers/convertListToTree';
import { Base_DataTable } from './Base_DataTable';
import type { MRTColumnDef, MRTRow } from './MRT_Types';
import { GroupingOption, useTableBackendFeatures } from './useTableBackendFeatures';

interface TablePageDataGridProps {
    multiSelect: boolean;
    viewNameOrTableName: ViewName | TableName;
    filter?: Filter[];
    getFilter?: () => Promise<Filter[]>;
    onRowSelect?: (row: any) => void;
    searchLishColumns?: MRTColumnDef[];

    refresh?: boolean;

    expandOptions?: {
        enabled: boolean;
        columnSorting?: (fieldA: string, fieldB: string) => number;
    };
    defaultTablePageSize?: number;
}

const muiSelectCheckboxProps = ({ row }: { row: MRTRow }) => {
    if ((row.original as { subRows: object[] }).subRows?.length > 0) {
        return { sx: { display: 'none' } };
    }
    return {};
};

const muiTableBodyRowProps = ({ row }: { row: MRTRow }) => ({
    // add onClick to row to select upon clicking anywhere in the row
    onClick: row.getToggleSelectedHandler(),
    sx: { cursor: 'pointer' }
});

const initialState = {
    showGlobalFilter: true
};

export const Select_DataTable = ({
    multiSelect,
    viewNameOrTableName,
    filter,
    getFilter,
    onRowSelect,
    searchLishColumns,
    expandOptions,
    refresh = false,
    defaultTablePageSize
}: TablePageDataGridProps) => {
    const groupingOptions: GroupingOption | undefined = useMemo(() => {
        if (viewNameOrTableName === 'vdocs_product_groups') {
            return {
                childRefFieldName: 'parent_product_group_id',
                parentRefFieldName: 'id'
            };
        }

        if (viewNameOrTableName === 'vdocs_product_kinds') {
            return {
                childRefFieldName: 'parent_product_kind_id',
                parentRefFieldName: 'id'
            };
        }

        if (viewNameOrTableName === 'vdocs_administrative_areas') {
            return {
                childRefFieldName: 'parent_administrative_area_id',
                parentRefFieldName: 'id'
            };
        }

        return undefined;
    }, [viewNameOrTableName]);

    const tableBackendFeatures = useTableBackendFeatures({
        viewOrTableName: viewNameOrTableName,
        filter,
        getFilter,
        autoRefresh: false,
        viewDisplayPreferenceTitle: 'SearchHelp',
        enableViewDisplayPreferenceMenu: false,
        // defaultPageSize: defaultTablePageSize,
        // groupingOption: groupingOptions,
        enableColumnHiding: false,
        enableVirtualization: true,
        loadRowsPerPackage: 50000,
        refresh
    });

    const { i18n } = useTranslation();

    // получаем данные для использования с типами древовидного селекта

    const [treeData, setTreeData] = useState<ConvertedTreeNode[]>([]);

    const exampleRow = treeData[0];

    const { columnOrdering, columnVisibility, columns } = useGenerateColumnsFromType(
        exampleRow,
        i18n,
        viewNameOrTableName,
        expandOptions?.columnSorting,
        false
    );

    // Конвертируем полученные данные в дерево
    useEffect(() => {
        if (expandOptions && expandOptions.enabled) {
            const treeData = convertTableListToTree(tableBackendFeatures.data as ListItem[]);

            setTreeData(treeData);
        }
    }, [expandOptions, tableBackendFeatures.data]);

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    useEffect(() => {
        if (onRowSelect) {
            onRowSelect(
                // другая реализация т.к там id это не int, а строка
                viewNameOrTableName === 'vusers'
                    ? Object.keys(rowSelection).map((rowId: string) =>
                          tableBackendFeatures.data.find((row) => row.id === rowId)
                      )
                    : Object.keys(rowSelection).map((rowId: string) =>
                          tableBackendFeatures.data.find((row) => {
                              if (typeof row.id === 'number') {
                                  return row.id === parseInt(rowId, 10);
                              }
                              return row.id === rowId;
                          })
                      )
            );
        }
    }, [rowSelection, onRowSelect]);

    const resolvedColumns = expandOptions?.enabled
        ? searchLishColumns || columns
        : tableBackendFeatures.columns;

    //  expandOptions?.enabled
    //     ? searchLishColumns || columns;
    let columnVisibilityProp = expandOptions?.enabled
        ? columnVisibility
        : tableBackendFeatures.state.columnVisibility;

    if (viewNameOrTableName?.endsWith('_types')) {
        columnVisibilityProp = tableBackendFeatures.state.columnVisibility;
    }

    const state = {
        ...tableBackendFeatures.state,
        rowSelection,
        columnOrder:
            expandOptions?.enabled || !!groupingOptions
                ? [
                      'mrt-row-expand',
                      'mrt-row-select',
                      ...columnOrdering.filter(
                          (item) => item !== 'mrt-row-expand' && item !== 'mrt-row-select'
                      )
                  ]
                : tableBackendFeatures.state.columnOrder,
        columnVisibility: { ...columnVisibilityProp }
    };

    if (!tableBackendFeatures.data) {
        return <EmptyMarker />;
    }
    return (
        <Base_DataTable
            {...tableBackendFeatures}
            data={
                groupingOptions
                    ? tableBackendFeatures.data.filter(
                          (dataRow) => !dataRow[groupingOptions.childRefFieldName]
                      )
                    : tableBackendFeatures.data
            }
            columns={resolvedColumns || []}
            state={state}
            initialState={initialState}
            viewName={viewNameOrTableName as ViewName}
            onRowSelectionChange={setRowSelection}
            muiSelectCheckboxProps={muiSelectCheckboxProps}
            muiTableBodyRowProps={muiTableBodyRowProps}
            // Тут логика разворачиваемой таблицы зашита
            positionToolbarAlertBanner="none"
            paginateExpandedRows={(expandOptions && expandOptions.enabled) || !!groupingOptions}
            enableExpandAll={(expandOptions && expandOptions.enabled) || !!groupingOptions}
            enableExpanding={(expandOptions && expandOptions.enabled) || !!groupingOptions}
            enableMultiRowSelection={multiSelect}
            enableEditing={false}
            filterFromLeafRows={false}
            enableRowNumbers={false}
            enableSubRowSelection={false}
            enableRowSelection
            getSubRows={
                groupingOptions
                    ? (row) => {
                          return tableBackendFeatures.data.filter(
                              (dataRow) =>
                                  dataRow[groupingOptions.childRefFieldName] ===
                                  row[groupingOptions.parentRefFieldName]
                          );
                      }
                    : undefined
            }
        />
    );
};
