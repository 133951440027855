import React, { memo } from 'react';

interface ProvisionIndicatorProps {
    id: string | null;
}
export const ProvisionIndicator = memo(({ id }: ProvisionIndicatorProps) => (
    <div className="flex-center">
        <div className={`circle ${id === '1' ? 'green' : ''}`} />
        <div className={`circle ${id === '2' ? 'yellow' : ''}`} />
        <div className={`circle ${id === '3' ? 'red' : ''}`} />
    </div>
));
