import { CheckOutlined, CloseOutlined, PrinterOutlined } from '@ant-design/icons';
import { Divider, List, Typography } from 'antd';
import { ButtonWithTooltips } from 'ui/ButtonWithTooltips/ButtonWithTooltips';
import { ViewOnlyDetailPage_DataTable } from 'components/DataTable/ViewOnlyDetailPage_DataTable';
import { SelectPrintingFormModal } from 'components/Modals/SelectPrintingFormModal';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewName } from 'modules/supabase/types/Dataset';
import { Database } from 'modules/supabase/types/database.types';
import { ProcessLog } from './ProcessLog';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type GroupChangeOutputProps = {
    task: ViewType;
};

export const GroupChangeOutput = ({ task }: GroupChangeOutputProps) => {
    return (
        <>
            <ProcessLog task={task} />
        </>
    );
};
