export interface ConvertedTreeNode {
    id: number;
    level: number;
    parent?: ConvertedTreeNode;
    subRows: ConvertedTreeNode[];
    [key: string]: any;
}

export interface ListItem {
    id: number;
    level: number;
    [key: string]: any;
}

export const convertTableListToTree = (list: ListItem[]): ConvertedTreeNode[] => {
    const tree: ConvertedTreeNode[] = [];

    const isNodeHasPath = !!list && !!list.length && !!list[0]?.path && !!list[0]?.path.length;

    // Array.sort мутирует исходный массив! Поэтому делаю поверхносную копию
    const sortedList = isNodeHasPath
        ? [...list].sort(({ level: a }, { level: b }) => b - a)
        : [...list];

    // делаю хэшмап, чтобы не искать, а сразу обратиться к ноде
    const hashedList = sortedList.reduce(
        (prev, current) => ({ ...prev, [current.id]: { ...current, subRows: [] } }),
        {} as Record<string, ListItem>
    );

    // Логика такая, мы получили все дерево, идем от последнего уровня и собираем в дерево
    // как только все ячейки в рутовой ноде собраны, пушаем рутовые в дерево на вывод
    sortedList.forEach((node) => {
        // главное, чтобы рутовые уровни были в конце списка
        if (node.level === 0) {
            tree.push(hashedList[node.id] as ConvertedTreeNode);
        } else {
            const nodeParentId = node.path.at(-2);
            hashedList[nodeParentId].subRows.push(hashedList[node.id]);
        }
    });

    return tree;
};

// TODO: Написать unit-тесты для этой функции
// Функция конвертации списка в дерево
export const convertListToTree = (list: ListItem[]): ConvertedTreeNode[] => {
    const tree: ConvertedTreeNode[] = [];

    const nodeHasPath = !!list && !!list.length && !!list[0]?.path && !!list[0]?.path.length;

    // Array.sort мутирует исходный массив! Поэтому делаю поверхносную копию
    const sortedList = nodeHasPath
        ? [...list]
              .sort(({ id: a }, { id: b }) => a - b)
              .sort(({ level: a }, { level: b }) => a - b) // сортирую сначала по id, потом по level, дабы сформировать правильный порядок объектов
        : [...list];

    sortedList.forEach((node, index) => {
        const nodeCopy = { ...node } as ConvertedTreeNode;
        nodeCopy.subRows = [];

        const [nodeRootParentId] = nodeHasPath ? nodeCopy.path : [0]; // Если path нет или н пустой массив, то parentId у ноды будет 0, что эквивалентно его отсутствию

        if (nodeCopy.level === 0) {
            tree.push(nodeCopy);
        }

        if (nodeCopy.level > 0 && tree.length) {
            let subRowsArray: ConvertedTreeNode[] = [];

            for (let j = 0; j < tree.length; j++) {
                if (tree[j].level === 0) {
                    if (subRowsArray.includes(nodeCopy)) {
                        break;
                    }

                    subRowsArray = tree[j].subRows;
                    for (let searchLevel = 0; searchLevel < nodeCopy.level; searchLevel++) {
                        if (
                            nodeHasPath &&
                            (nodeRootParentId !== tree[j].path[0] ||
                                nodeRootParentId !== tree[j].id) // Если в id родителя текущей ноды не совпадает с id рассматриваемого элемента делева с level 0, то мы пропускаем процесс и идем смотреть на другой элемент
                        ) {
                            break;
                        }

                        if (searchLevel === nodeCopy.level - 1) {
                            subRowsArray.push(nodeCopy);
                            break;
                        }

                        if (subRowsArray.length) {
                            if (nodeHasPath) {
                                for (let k = 0; k < subRowsArray.length; k++) {
                                    subRowsArray = subRowsArray[k].subRows;
                                }
                            } else {
                                subRowsArray = subRowsArray[subRowsArray.length - 1].subRows;
                            }
                        }
                    }
                }
            }
        }
    });

    return tree;
};
