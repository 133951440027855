import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { Dataset } from '../../supabase/types/Dataset';
import { callBackendFunction } from './BackendFunctions';

type SaveMultiDatasetResponse = Record<string, any> | null;

export async function AssignmentTrip(
    vehicleid: number,
    tripid: number[]
): Promise<{ data: Record<string, any> | null; error: Error | null }> {
    const endpoint = 'assignment-trip';

    const { data, error } = await callBackendFunction<SaveMultiDatasetResponse>(endpoint, {
        vehicleid,
        tripid
    });

    if (error) {
        return { data: null, error: new Error(makeErrorReadable(error.message)) };
    }

    return { data, error: null };
}
