import { Modal } from 'antd';
import _ from 'lodash';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMessages, useNotifications } from 'utils/hooks';

import { PrefixedSelectField } from 'components/fields';
import { AssignmentTrip } from 'modules/services/backend-functions';
import { ButtonWithTooltips } from 'ui';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';

interface AssignmentModalType {
    open: boolean;
    setOpen: Function;
    selectedRows: Record<string, any>;
}

export const AssignmentModal = memo(({ open, setOpen, selectedRows }: AssignmentModalType) => {
    const { t } = useTranslation();

    const { openNotify } = useNotifications({ message: '' });
    const { openMessage, loadingMessage, errorMessage, successMessage } = useMessages({
        message: ''
    });

    const [vehicle, setVehicle] = useState<any>({});

    const clearData = () => {
        setVehicle({});
    };

    const handleClose = useCallback(() => {
        setOpen(false);
        clearData();
    }, [setOpen]);

    const extractIds = (arrayOfObjects: Record<string, any>): number[] => {
        try {
            // Создаем новый массив для хранения значений поля "id"
            const idArray: number[] = [];

            // Проходим по каждому объекту в массиве
            arrayOfObjects.forEach((obj: any) => {
                // Проверяем, есть ли у объекта поле "original" и "id"
                if (obj.original && obj.original.id) {
                    // Извлекаем значение поля "id" из объекта и добавляем его в массив
                    idArray.push(obj.original.id);
                }
            });

            // Возвращаем полученный массив значений "id"
            return idArray;
        } catch (error) {
            openNotify({
                message: t('error'),
                description: makeErrorReadable(`An error occurred while extracting IDs: ${error}`),
                type: 'error',
                duration: 0
            });

            return [];
        }
    };

    const handleAssignment = useCallback(async () => {
        const messageKey = 'createAgreementFromRfqRequestAndRfqResponse';
        loadingMessage(messageKey);
        if (_.isEmpty(vehicle)) {
            errorMessage(messageKey);

            openNotify({
                message: t('error'),
                description: '',
                type: 'error',
                duration: 0
            });
        } else {
            const { data, error } = await AssignmentTrip(
                vehicle.id as number,
                extractIds(selectedRows)
            );

            if (error) {
                errorMessage(messageKey);

                openNotify({
                    message: t('error'),
                    description: makeErrorReadable(error.message),
                    type: 'error',
                    duration: 0
                });
            }

            openMessage({
                key: messageKey,
                message: t('success'),
                type: 'success'
            });

            handleClose();
        }
    }, [
        loadingMessage,
        vehicle,
        errorMessage,
        openNotify,
        t,
        selectedRows,
        openMessage,
        handleClose
    ]);

    return (
        <>
            <Modal
                closable
                centered
                title={t('vehicle_assignment')}
                open={open}
                onCancel={handleClose}
                maskClosable={false}
                footer={[
                    <ButtonWithTooltips
                        id="cancel"
                        className="btn-red"
                        key="back"
                        onClick={handleClose}
                    >
                        {t('cancel')}
                    </ButtonWithTooltips>,
                    <ButtonWithTooltips
                        id="assign"
                        key="submit"
                        type="primary"
                        onClick={handleAssignment}
                        disabled={_.isEmpty(vehicle)}
                    >
                        {t('assign')}
                    </ButtonWithTooltips>
                ]}
            >
                <PrefixedSelectField
                    mode="edit"
                    id="vehicle"
                    prefix=""
                    viewName="vdocs_vehicles"
                    value={vehicle}
                    onChange={(value) => {
                        setVehicle((prevValue: any) => {
                            return {
                                ...prevValue,
                                ...value
                            };
                        });
                    }}
                />
            </Modal>
        </>
    );
});
