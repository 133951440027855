import { ArrowDownOutlined } from '@ant-design/icons';
import { Database } from 'modules/supabase/types/database.types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltips } from 'ui';

import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useMessages, useNotifications } from 'utils/hooks';

const FILE_BUCKET = 'printing_forms';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type PrintFormOutputProps = {
    task: ViewType;
};

export const PrintFormOutput = ({ task }: PrintFormOutputProps) => {
    const [filePath, setFilePath] = useState<string>();

    const [fileContentUrl, setFileContentUrl] = useState<string>();

    const { openNotify } = useNotifications({ message: '' });

    const { openMessage, loadingMessage, successMessage } = useMessages({
        message: ''
    });

    const { t } = useTranslation();

    const noPreview = (task?.output_data as Record<string, any>).noPreview || false;

    useEffect(() => {
        if (task?.output_data) {
            const filePath = (task.output_data as Record<string, any>).file_path;
            // const fileName = (task.output_data as Record<string, any>).file_name;

            setFilePath(filePath);
        }
    }, [task?.output_data]);

    const handleDownload = useCallback(
        async (url: string) => {
            const messageKey = 'file_download';

            loadingMessage(messageKey);

            try {
                const { data, error } = await supabaseClient.storage
                    .from(FILE_BUCKET)
                    .download(url);

                if (error) {
                    throw error;
                }

                if (data && task) {
                    const downloadUrl = URL.createObjectURL(data);
                    const fileName = (task.output_data as Record<string, any>).file_name;

                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = fileName;
                    document.body.appendChild(link);
                    successMessage(messageKey);
                    link.click();
                    document.body.removeChild(link);
                }
            } catch (error) {
                openMessage({
                    key: messageKey,
                    type: 'error',
                    message: t('file_download_error_message')
                });
                openNotify({
                    message: t('file_download_error') as string,
                    description: makeErrorReadable((error as Error).message),
                    duration: 0,
                    type: 'error'
                });
            }
        },
        [loadingMessage, openMessage, openNotify, successMessage, t, task]
    );

    const getFileUrlForDownload = useCallback(async (url: string) => {
        // Скачиваем файл из хранилища
        const { data, error } = await supabaseClient.storage
            .from(FILE_BUCKET)
            .createSignedUrl(url, 1200, {
                download: true
            });
        // Если ошибка прилетела, то бросаем исключение
        if (error) {
            throw error;
        }
        const downloadUrl = data?.signedUrl;
        return downloadUrl;
    }, []);

    useEffect(() => {
        const downloadFile = async () => {
            if (filePath) {
                const url = await getFileUrlForDownload(filePath);

                if (url) {
                    setFileContentUrl(url);
                }
            }
        };

        downloadFile();
    }, [filePath]);

    return (
        <div>
            <div>
                <ButtonWithTooltips
                    id="download_printing_form"
                    style={{
                        width: '100%',
                        // left: '50%',
                        marginBottom: '20px'
                    }}
                    type="text"
                    size="large"
                    tooltipTitle={t('download_printing_form')}
                    onClick={async () => {
                        if (filePath) {
                            await handleDownload(filePath);
                        }
                    }}
                    icon={<ArrowDownOutlined />}
                >
                    {t('print')}
                </ButtonWithTooltips>

                {fileContentUrl && !noPreview && (
                    <>
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileContentUrl}`}
                            width="100%"
                            height="650px"
                        ></iframe>
                    </>
                )}
            </div>
        </div>
    );
};
