import { CloseCircleFilled } from '@ant-design/icons';
import { AutoComplete, Modal, Typography } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { BaseSelectRef } from 'rc-select';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidateStatus } from 'antd/lib/form/FormItem';
import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { useFindComponentsByPath } from 'components/ModuleComponentAccess/ModuleComponentAccess';
import { SearchList } from 'components/SearchList/SearchList';
import { BaseTableRow, SupabaseViewRow, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { useViewDisplayPreferenceByTitle } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { getColumnByPreferenceTitleForSelectFieldByViewDisplayPreference } from 'modules/supabase/utils/preferencesUtils';
import { Filter, OrderBy } from 'modules/supabase/utils/supabaseClient';
import { convertUISortingColumnName } from 'modules/supabase/utils/supabaseSortingUtils';
import { StoreLink } from 'ui';
import { statusColorMarker } from 'utils/helpers/colorMarkers';
import { useResize } from 'utils/hooks';
import { BaseField } from '../BaseField/BaseField';
import { SelectWithViewHelp } from './SelectWithViewHelp';

import { checkIfMultiLanguageFieldByValue } from '../MultilanguageField/MultilanguageField';

type outputField = {
    value: string;
    valuePath: string;
    multilanguage: boolean;
};

type SupabaseViewType = SupabaseViewRow<ViewName> & BaseTableRow;

interface SelectFieldProps {
    id: string;
    htmlId?: string;
    prefix?: string;
    value: TableRow | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    searchLishColumns?: MRTColumnDef[];
    viewName: ViewName;
    withLabel?: boolean;
    required?: boolean;
    expandOptions?: {
        enabled: boolean;
        columnSorting?: (fieldA: string, fieldB: string) => number;
    };
    searchListFilter?: Filter[];
    searchListGetFilter?: () => Promise<Filter[]>;
    navigateToLink?: string;
    asDictionarySelectFieldWithOneColumn?: boolean; // Показывать обычный Select без popover
    // тип newValue - тип данных по ViewName
    onChange?: (newValue: SupabaseViewType | null) => void;
    'data-testid'?: string; // нужно для тестов
    popoverPlacement?: TooltipPlacement;
    navigateLinkInViewMode?: {
        navPath: string;
        navParamName: string;
    };
    defaultTablePageSize?: number;
    skipBlur?: boolean;
    alwaysFetchAfterOpen?: boolean;
    validateStatus?: ValidateStatus;
    validateMessage?: string;
    isFullSizeLabel?: boolean;
}

export const SelectField = memo<SelectFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        viewName,
        searchLishColumns,
        searchListFilter = [],
        searchListGetFilter,
        expandOptions,
        navigateLinkInViewMode,
        asDictionarySelectFieldWithOneColumn,
        withLabel = true,
        required = false,
        alwaysFetchAfterOpen = false,

        htmlId = id,
        onChange = () => {},
        'data-testid': dataTestId, // for tests
        popoverPlacement = 'bottom',
        defaultTablePageSize = 5,
        prefix,
        skipBlur = false, // TODO: реализовать выключение Blur. Возможно, это тут не нужно
        validateStatus,
        validateMessage
    }) => {
        const inputRef = useRef<BaseSelectRef>(null);
        const { isScreenMd } = useResize();

        const {
            i18n: { language },
            t
        } = useTranslation();

        // За что закрепляется Popover
        const [anchorEl, setAnchorEl] = useState<BaseSelectRef | null>(null);

        // // Отображаемое значение
        const [outputField, setOutputField] = useState<outputField>({
            value: '',
            valuePath: '',
            multilanguage: false
        });
        const [sorting, setSorting] = useState<OrderBy[]>([]);

        const [functionalsModulesExcludeFilter, SetFunctionalsModulesExcludeFilter] = useState<
            Filter[]
        >([]);

        // Настройка ViewDisplayPreference содержит информацию какие колонки показываем в какой порядке
        // Считаем что у всех View есть такая настройка
        const displayPrefsOptions = useMemo(
            () => ({
                viewName,
                viewDisplayPreferenceTitle: 'SearchHelp'
            }),
            [viewName]
        );

        const [refresh, setRefresh] = useState<boolean>(false);

        const viewDisplayPreferenceSettings = useViewDisplayPreferenceByTitle(displayPrefsOptions);

        const setOutputFieldValue = useCallback(
            (outputField: string) => {
                // Учитываем MultilanguageText
                if (value) {
                    if (
                        checkIfMultiLanguageFieldByValue(value[outputField]) &&
                        value[outputField]
                    ) {
                        setOutputField({
                            value: value[outputField][language],
                            valuePath: outputField,
                            multilanguage: true
                        });
                    } else if (value[outputField]) {
                        setOutputField({
                            value: value[outputField],
                            valuePath: outputField,
                            multilanguage: false
                        });
                    } else if (mode === 'view' && prefix) {
                        setOutputField({
                            value: value[`${prefix}key`] || value[`${prefix}code`],
                            valuePath: outputField,
                            multilanguage: false
                        });
                    } else {
                        setOutputField({
                            value: value.key || value.code,
                            valuePath: outputField,
                            multilanguage: false
                        });
                    }
                } else {
                    setOutputField({
                        value: '',
                        valuePath: outputField,
                        multilanguage: false
                    });
                }
            },
            [language, mode, prefix, value]
        );

        useEffect(() => {
            if (mode === 'edit') {
                if (
                    viewDisplayPreferenceSettings?.sort &&
                    typeof viewDisplayPreferenceSettings.sort === 'object'
                ) {
                    const orderBy: OrderBy[] = [];
                    for (
                        let i = 0;
                        i < Object.keys(viewDisplayPreferenceSettings?.sort).length;
                        i++
                    ) {
                        const column = Object.keys(viewDisplayPreferenceSettings?.sort)[i];
                        const direction = viewDisplayPreferenceSettings?.sort[column];

                        orderBy.push({
                            column: convertUISortingColumnName(column, language),
                            direction
                        });
                    }

                    setSorting(orderBy);
                }
            }
        }, [viewDisplayPreferenceSettings?.sort, language, mode]);

        // Берем из ViewDisplayPreference первую колонку - считаем что ее и надо отображать
        useEffect(() => {
            if (viewName && viewDisplayPreferenceSettings) {
                getColumnByPreferenceTitleForSelectFieldByViewDisplayPreference(
                    viewDisplayPreferenceSettings
                ).then((outputField) => {
                    if (prefix) {
                        const field = mode === 'view' ? prefix + outputField : outputField;

                        setOutputFieldValue(field);
                    } else {
                        setOutputFieldValue(outputField);
                    }
                });
            } else if (!viewDisplayPreferenceSettings) {
                if (prefix) {
                    const field = mode === 'view' ? `${prefix}short_title` : 'short_title';

                    setOutputFieldValue(field);
                } else {
                    setOutputFieldValue('short_title');
                }
            }

            // Если нет значения - считаем что все пусто
            if (!value) {
                if (prefix) {
                    const field = mode === 'view' ? `${prefix}short_title` : 'short_title';
                    setOutputFieldValue(field);
                } else {
                    setOutputFieldValue('short_title');
                }
            }
        }, [
            value,
            viewName,
            language,
            viewDisplayPreferenceSettings,
            mode,
            prefix,
            setOutputFieldValue
        ]);

        const open = !!anchorEl;

        const handleClose = useCallback(() => {
            setAnchorEl(null);
        }, []);

        const handleClick = () => setAnchorEl(inputRef.current);

        // Фильтры из модулей для справочников
        const components = useFindComponentsByPath(viewName);

        useEffect(() => {
            if (alwaysFetchAfterOpen && open) {
                setRefresh((pre) => !pre);
            }
        }, [alwaysFetchAfterOpen, open]);

        useEffect(() => {
            if (
                mode === 'edit' &&
                functionalsModulesExcludeFilter.length === 0 &&
                components?.length
            ) {
                SetFunctionalsModulesExcludeFilter(() => {
                    const notEnabledComponents = components.filter((value) => !value.isEnabled);
                    const filter = [
                        {
                            column: 'code',
                            operator: 'not.in',
                            value: `(${notEnabledComponents
                                .map((value) => value.component_name)
                                .join(',')})`
                        }
                    ];
                    return filter;
                });
            }
        }, [components, functionalsModulesExcludeFilter.length, mode]);

        const filter = useMemo(
            () => [...searchListFilter, ...functionalsModulesExcludeFilter],
            [searchListFilter, functionalsModulesExcludeFilter]
        );

        const renderInput = useCallback(() => {
            // Если явно задано asDictionarySelectFieldWithOneColumn или же viewDisplayPreferenceSettings содержит только одну колонку
            // Показываем обычный Select
            if (
                asDictionarySelectFieldWithOneColumn ||
                (viewDisplayPreferenceSettings &&
                    viewDisplayPreferenceSettings.columns.length === 1)
            ) {
                return (
                    <SelectWithViewHelp
                        popoverContainerHtmlId={popoverContainerHtmlId}
                        id={id}
                        mode="edit"
                        viewName={viewName}
                        searchListFilter={filter}
                        value={value}
                        viewNameFieldOutput={outputField.valuePath}
                        isMultilanguageValue={outputField.multilanguage}
                        searchListOrdering={sorting}
                        onChange={(value) => {
                            onChange(value as SupabaseViewType);
                        }}
                    />
                );
            }

            // Иначе Popover
            return (
                <>
                    <Modal
                        centered
                        open={open}
                        width={isScreenMd ? '100%' : '75%'}
                        footer={[]}
                        onCancel={handleClose}
                    >
                        <SearchList
                            multiSelect={false}
                            viewNameOrTableName={viewName}
                            searchLishColumns={searchLishColumns}
                            searchListFilter={filter}
                            refresh={refresh}
                            searchListGetFilter={searchListGetFilter}
                            onSearchApply={(selectedRows) => {
                                onChange(
                                    selectedRows.length > 0
                                        ? (selectedRows[0] as SupabaseViewType)
                                        : null
                                );
                                handleClose();
                            }}
                            onSearchCancel={() => {
                                handleClose();
                            }}
                            expandOptions={expandOptions}
                        />
                    </Modal>

                    <AutoComplete
                        id={`${id}-value`}
                        placeholder={t('no_value') as string}
                        value={outputField.value || ''}
                        ref={inputRef}
                        options={[]}
                        onClick={() => {
                            if (open) handleClose();
                            if (!open) handleClick();
                        }}
                        allowClear={{
                            clearIcon: (
                                <CloseCircleFilled
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onChange(null);
                                    }}
                                />
                            )
                        }}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                </>
            );
        }, [
            asDictionarySelectFieldWithOneColumn,
            viewDisplayPreferenceSettings,
            open,
            isScreenMd,
            handleClose,
            viewName,
            searchLishColumns,
            filter,
            refresh,
            searchListGetFilter,
            expandOptions,
            id,
            t,
            outputField.value,
            outputField.valuePath,
            outputField.multilanguage,
            popoverContainerHtmlId,
            value,
            sorting,
            onChange
        ]);

        const renderView = useCallback(() => {
            const isStatusField =
                id === 'lifecycle' ||
                id === 'approval' ||
                id === 'execution' ||
                id === 'planning' ||
                id === 'sell_planning' ||
                id === 'status' ||
                id === 'tender_status' ||
                id === 'execution_status';

            let viewValue = '';

            let statusMarker: React.CSSProperties = {};
            if (value && isStatusField) {
                let idValuePath = '';
                if (prefix) {
                    idValuePath = `${prefix}status_code`;
                } else {
                    idValuePath = 'status_code';
                }

                statusMarker = statusColorMarker(value[idValuePath], viewName) || {};
            }
            if (outputField.value) {
                viewValue = outputField.value;
            } else if (asDictionarySelectFieldWithOneColumn && value) {
                if (prefix) {
                    viewValue = value[`${prefix}short_title`]?.[language];
                } else {
                    viewValue = value.short_title?.[language];
                }
            }

            if (prefix) {
                if (!viewValue && value) {
                    if (value[`${prefix}key`] || value[`${prefix}id`] || value[`${prefix}code`]) {
                        viewValue =
                            value[`${prefix}key`] || value[`${prefix}id`] || value[`${prefix}code`];
                    }
                }
            } else if (!viewValue && value) {
                if (value.key || value.id || value.code) {
                    viewValue = value.key || value.id || value.code;
                }
            }
            // }

            // Если задана настройка куда навигироваться - отображаем Link
            if (navigateLinkInViewMode && value) {
                const navLink = `${navigateLinkInViewMode.navPath}${
                    navigateLinkInViewMode.navParamName
                        ? value[`${prefix}${navigateLinkInViewMode.navParamName}`]
                        : ''
                }`;

                return (
                    <>
                        {viewValue ? (
                            <StoreLink replace to={navLink}>
                                {viewValue}
                            </StoreLink>
                        ) : (
                            <Typography.Text type="secondary" className="no_value_text">
                                {t('no_value')}
                            </Typography.Text>
                        )}
                    </>
                );
            }

            return (
                <Typography.Text
                    id={`${htmlId}-value`}
                    data-testid={dataTestId}
                    style={{
                        paddingLeft: '10px',
                        color: viewValue ? undefined : '#aeaeae',
                        ...statusMarker
                    }}
                    type={viewValue ? undefined : 'secondary'}
                >
                    {viewValue || t('no_value')}
                </Typography.Text>
            );
            // <Input
            //     id={`${htmlId}-value`}
            //     data-testid={dataTestId}
            //     style={{
            //         cursor: 'pointer !important',
            //         ...statusMarker
            //     }}
            //     placeholder={t('no_value') as string}
            //     variant="borderless"
            //     value={viewValue}
            //     readOnly
            // />
        }, [
            asDictionarySelectFieldWithOneColumn,
            dataTestId,
            htmlId,
            id,
            language,
            navigateLinkInViewMode,
            outputField,
            t,
            value,
            prefix,
            viewName
        ]);

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                withLabel={withLabel}
                value={value}
                mode={mode}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                validateStatus={validateStatus}
                withTooltip={false}
                validateMessage={validateMessage}
                isFullSizeLabel={!withLabel}
            />
        );
    }
);
