import { Flex, Input, Popover } from 'antd';
import { memo } from 'react';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { BaseField } from '../BaseField/BaseField';

interface BarcodeFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    popover?: boolean;
}
// Сделал BarcodeField без лейбла чтобы выводить его в отдельный колапс и он выглядил как на макете продукта
export const BarcodeField = memo(
    ({
        id,
        value,
        mode,
        onChange = () => {},
        withLabel = false,
        required = false,
        skipBlur = false,
        popover = false
    }: BarcodeFieldProps) => {
        const { t } = useTranslation();

        const renderInput = (
            inputValue: string,
            onTextAreaChange?: (newValue: string) => void,
            onBlur?: () => void
        ) => {
            let barcode;

            if (popover) {
                barcode = null; // не показывать barcode если poopover, так как скорее всего это таблица а там не нужно показывать штрихкод
            } else if (inputValue) {
                barcode = <Barcode value={inputValue} />;
            } else {
                barcode = <Barcode value="no_value" displayValue={false} lineColor="#fc5e53" />;
            }
            return (
                <Flex vertical>
                    {barcode}
                    <Input.TextArea
                        id={`${id}-value`}
                        placeholder={t('no_value') as string}
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        value={inputValue || ''}
                        onChange={(e) => {
                            if (onTextAreaChange) {
                                onTextAreaChange(e.target.value);
                            }
                        }}
                        onBlur={onBlur}
                        allowClear
                    />
                </Flex>
            );
        };

        const renderView = (viewValue: string) => {
            if (popover) {
                return (
                    <Popover
                        content={
                            viewValue ? <Barcode value={viewValue} displayValue={false} /> : null
                        }
                    >
                        <Input.TextArea
                            id={`${id}-value`}
                            value={viewValue}
                            variant="borderless"
                            autoSize={{ minRows: 1, maxRows: 5 }}
                            readOnly
                        />
                    </Popover>
                );
            }
            if (!viewValue) {
                return <Barcode value="no_value" displayValue={false} lineColor="#fc5e53" />; // не надо добавлять перевод value, Barcode не поддерживает в value языки кроме английского
            }
            return <Barcode value={viewValue} />;
        };

        return (
            <BaseField
                id={id}
                value={value}
                mode={mode}
                onChange={onChange}
                withLabel={withLabel}
                required={required}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
                withTooltip={false}
            />
        );
    }
);
