import { EditOutlined, EyeOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Flex, FormItemProps, MenuProps, Table, TableProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { ColumnType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MetaField } from 'modules/services/backend-api/generated_info';
import { checkAndMakeTreeData, getDetailPageTitle, getExcludeFieldsPattern } from 'smart/utils';
import { EmptyMarker } from 'ui';
import { Loader } from 'ui/Loader/Loader';
// import { toPascalCase } from 'utils/helpers/toPascalCase';
import { LANGUAGES, i18n } from 'utils/i18n/i18n';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

// import { useResize } from 'utils/hooks';
import { EditableCell, EditableRow, RowModal, ViewRow } from './components';
import { fieldEditRender, fieldRender } from './helpers';

import './SmartTable.scss';
// import { ViewCell } from './components/ViewCell/ViewCell';

type TColumnType = ColumnType<IObjectWithId>;

interface EditableOptionsType {
    onRow?: TableProps['onRow'];
    components?: TableProps['components'];
}

export interface SmartTableProps {
    meta: string;
    fields?: MetaField[];
    data?: IObjectWithId[];
    setData?: (
        updater: IObjectWithId[] | ((prevValue: IObjectWithId[]) => IObjectWithId[])
    ) => void;
    loading?: boolean;
    selectedRows?: IObjectWithId[];
    onRowSelectionChange?: (selectedRows: IObjectWithId[]) => void;
    titleRender?: () => React.ReactNode;
    footerRender?: () => React.ReactNode;
    fixedFields?: { left?: string[]; right?: string[] };
    virtual?: boolean;
    selectable?: boolean;
    selectionType?: 'checkbox' | 'radio';
    layout?: 'auto' | 'fixed';
    editable?: boolean;
    clickable?: boolean;
    doubleClickable?: boolean;
    onDoubleClick?: (row: any, index?: number) => void;
    validation?: { rules: FormItemProps['rules'] };
    defaultSortOrder?: {
        descend?: string[];
        ascend?: string[];
    };
    viewMode?: 'inline' | 'modal';
    rowContextMenuItems?: MenuProps['items'];
    cellContextMenuItems?: (Partial<ItemType> & {
        onClick: (...args: any[]) => () => void;
    })[];
    isSmartTablePage?: boolean;
    rootMeta?: string;
    rootData?: IObjectWithId;
}

const rowsKeyColumnName = 'Id';

export const columnFilterRule = (column: TColumnType, index: number, columns: TColumnType[]) => {
    if (!column?.dataIndex || typeof column?.dataIndex !== 'string') return false;

    return getExcludeFieldsPattern(column, columns, { replaceFieldNameKey: 'dataIndex' });

    // return (
    //     !dataIndex.includes('ValueType') &&
    //     // dataIndex !== 'Property' &&
    //     // dataIndex !== 'Parameter' &&
    //     !(
    //         dataIndex.includes('MeasureUnit') &&
    //         columns.find((column) =>
    //             column.dataIndex?.includes(`${dataIndex.split('MeasureUnit').join('')}Value`)
    //         )
    //     ) &&
    //     // composite curreny value
    //     !(
    //         dataIndex.includes('Currency') &&
    //         dataIndex !== 'Currency' &&
    //         !dataIndex.includes('CurrencyValue') &&
    //         columns.find((column) =>
    //             column.dataIndex?.includes(`${dataIndex.split('Currency').join('')}Value`)
    //         )
    //     )
    // );
};

// // Удаляем пустые массивы children
// function cleanEmptyChildren(items: IMetaWithChildren[]) {
//     const cleanedItems = [...items];

//     cleanedItems.forEach((item) => {
//         if (item.children && item.children.length === 0) {
//             delete item.children;
//         } else if (item.children) {
//             cleanEmptyChildren(item.children);
//         }
//     });

//     return cleanedItems;
// }

// const checkAndMakeTreeData = (data: IObjectWithId[]) => {
//     const elementsByKey: { [key: string | number]: IMetaWithChildren } = {};
//     const dataWithChildren = data;

//     // Создаем словарь для быстрого доступа к элементам по ключам
//     dataWithChildren.forEach((item) => {
//         elementsByKey[item.Id] = item;
//         runInAction(() => {
//             item.children = [];
//         });
//     });

//     const result: IMetaWithChildren[] = [];

//     // Проходимся по всем элементам массива
//     dataWithChildren.forEach((item) => {
//         // Проверяем, есть ли у элемента ParentId
//         if (item.Parent?.Id) {
//             const parent = elementsByKey[item.Parent?.Id];
//             // Если родитель присутсвует в общем массиве данных, то...
//             if (parent && parent.children) {
//                 // ...добавляем текущий элемент в массив children родителя
//                 parent.children.push(item);
//                 // Иначе будем отображать в корне
//             } else result.push(item);
//         } else {
//             // Если у элемента нет ParentId, добавляем его в корневой массив
//             result.push(item);
//         }
//     });

//     // Сортируем детей если у них есть ChildIndex
//     result.forEach((itemWithChildren) => {
//         itemWithChildren.children?.sort((a, b) => {
//             if (a.ChildIndex && b.ChildIndex) {
//                 const indexA = a.ChildIndex as number;
//                 const indexB = b.ChildIndex as number;
//                 return indexA < indexB ? -1 : 1;
//             }

//             return 1;
//         });
//     });

//     return cleanEmptyChildren(result);
// };

const tableEditableComponents: TableProps['components'] = {
    body: {
        row: EditableRow,
        cell: EditableCell
    }
};

const getColumnSorterRule = (columnName: string, valueType?: string) => (a: any, b: any) => {
    let valA = a[columnName];
    let valB = b[columnName];

    // Чтобы обрабатывались пустые значения
    if (typeof valA === 'object' && typeof valB === 'undefined') {
        valB = {};
    }
    if (typeof valB === 'object' && typeof valA === 'undefined') {
        valA = {};
    }

    if (typeof valA === 'string' && typeof valB === 'undefined') {
        valB = '';
    }
    if (typeof valB === 'string' && typeof valA === 'undefined') {
        valA = '';
    }

    if (typeof valA === 'object' && typeof valB === 'object') {
        if (columnName.includes('Status')) {
            valA = valA?.Status?.Code || valA?.Code || '';
            valB = valB?.Status?.Code || valB?.Code || '';
        }
        // обработка тайтлов
        else if (['ShortTitle', 'LongTitle', 'Name', 'Description'].includes(columnName)) {
            valA = valA?.[i18n.language] || '';
            valB = valB?.[i18n.language] || '';
        }
        // обработка меты
        else if (['Meta'].includes(columnName)) {
            valA = valA?.Code || '';
            valB = valB?.Code || '';
        }
        // обработка рефов
        else {
            valA =
                valA?.ShortTitle?.[i18n.language] || valA?.Name?.[i18n.language] || valA?.Key || '';
            valB =
                valB?.ShortTitle?.[i18n.language] || valB?.Name?.[i18n.language] || valB?.Key || '';
        }
    }

    if (typeof valA === 'string' && typeof valB === 'string') {
        if (valueType?.includes('key')) {
            const keyNumA = Number(valA.split('-')[1]);
            const keyNumB = Number(valB.split('-')[1]);
            return keyNumA - keyNumB;
        }
        return valA.localeCompare(valB);
    }

    if (typeof valA === 'number' && typeof valB === 'number') {
        return valA - valB;
    }

    if (typeof valA === 'boolean' && typeof valB === 'boolean') {
        return Number(valA) - Number(valB);
    }

    return 0;
};

const tableViewComponents: TableProps['components'] = {
    body: {
        row: ViewRow
        // cell: ViewCell
    }
};

export const SmartTable = observer<SmartTableProps>(
    ({
        meta,
        data,
        setData,
        fields,
        titleRender,
        footerRender,
        selectedRows,
        onRowSelectionChange,
        fixedFields,
        loading,
        validation,
        onDoubleClick,
        rowContextMenuItems,
        // cellContextMenuItems,
        rootMeta,
        rootData,
        defaultSortOrder = { descend: ['Code', 'Key'] },
        selectable = true,
        clickable = true,
        doubleClickable = false,
        editable = false,
        virtual = false,
        selectionType = 'checkbox',
        layout = 'fixed',
        viewMode = 'inline',
        isSmartTablePage = false
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const location = useLocation();
        // const { isScreenMd, isScreenSm } = useResize();

        const storeNavigate = useStoreNavigate();

        // TODO: записывать изменения в стор, если не пришло setData
        const [dataSource, setDataSource] = useState<IObjectWithId[]>([]);

        const tableData = data || dataSource;

        const treeData = useMemo(() => {
            return checkAndMakeTreeData(tableData);
        }, [tableData]);

        const [rowModalOpen, setRowModalOpen] = useState(false);
        const [rowModalData, setRowModalData] = useState<any>({});

        useEffect(() => {
            console.log('[SmartTable] table data from PROPS:', data);
            if (!data) {
                const tableData = metaStore.meta.get(meta)?.select?.objects || [];
                console.log('[SmartTable] table data from META:', tableData);
                setDataSource(tableData);
            }
        }, [data, meta]);

        const handleDataSourceChange = useCallback(
            (row: IObjectWithId) => {
                // console.log('[SmartTable] handleChange row:', row);

                if (setData) {
                    setData((prevData) => {
                        const newData = [...prevData];

                        const changedIndex = newData.findIndex((dataRow) => dataRow.Id === row.Id);

                        if (changedIndex >= 0) {
                            newData[changedIndex] = {
                                ...newData[changedIndex],
                                ...row
                            };
                        }
                        return newData;
                    });
                }
            },
            [setData]
        );

        const getInitialRowSorting = useCallback(() => {
            const res = {} as { [keys: string]: 'ascend' | 'descend' | undefined };

            defaultSortOrder.descend?.forEach((fieldName) => {
                res[fieldName] = 'descend';
            });
            defaultSortOrder.ascend?.forEach((fieldName) => {
                res[fieldName] = 'ascend';
            });
            fixedFields?.left?.forEach((fieldName) => {
                res[fieldName] = 'descend';
            });

            return res;
        }, [defaultSortOrder.ascend, defaultSortOrder.descend, fixedFields?.left]);

        const [rowSorting, setRowSorting] = useState<{
            [keys: string]: 'ascend' | 'descend' | undefined;
        }>();

        useEffect(() => {
            if (!rowSorting || !Object.keys(rowSorting).length) {
                setRowSorting(getInitialRowSorting());
            }
        }, [getInitialRowSorting, rowSorting]);

        // console.log(rowSorting, fixedFields);

        const [selectedData, setSelectedData] = useState<IObjectWithId[]>([]);

        const handleSelectRow = useCallback(
            (selectedRows: IObjectWithId[]) => {
                if (onRowSelectionChange) onRowSelectionChange(selectedRows);
                else setSelectedData(selectedRows);
            },
            [onRowSelectionChange]
        );

        const getSelectedRowKeys = useCallback(() => {
            return (selectedRows || selectedData).map((row) => row[rowsKeyColumnName]);
        }, [selectedData, selectedRows]);

        // ### ВЫДЕЛЕННЫЕ СТРОКИ и их опции
        const rowSelection: TableProps<IObjectWithId>['rowSelection'] = useMemo(
            () => ({
                type: selectionType,
                columnWidth: 50,
                fixed: 'left',
                selectedRowKeys: getSelectedRowKeys(),
                onChange: (selectedRowKeys: React.Key[], selectedRows: IObjectWithId[]) => {
                    console.log('[SmartTable] selectedRows:', selectedRows);
                    console.log('[SmartTable] selectedRowKeys:', selectedRowKeys);

                    handleSelectRow(selectedRows);
                }
            }),
            [selectionType, getSelectedRowKeys, handleSelectRow]
        );

        const getHandleClickRow = useCallback(
            (row: IObjectWithId) => () => {
                const selectedIndex = getSelectedRowKeys().findIndex(
                    (key) => key === row[rowsKeyColumnName]
                );

                if (selectedIndex === -1) handleSelectRow([row]);
                else {
                    handleSelectRow([]);
                }
            },
            [getSelectedRowKeys, handleSelectRow]
        );

        const getHandleRightClickRow = useCallback(
            (row: IObjectWithId) => (open: boolean) => {
                if (treeData.length) {
                    handleSelectRow([row]);
                    // не обнулять выбранные строки, поскольку при выборе контекстного меню спадают выбранные строки
                }
            },
            [handleSelectRow, treeData.length]
        );

        const getHandleCtrlClickRow = useCallback(
            (row: IObjectWithId) => () => {
                const selectedIndex = getSelectedRowKeys().findIndex(
                    (key) => key === row[rowsKeyColumnName]
                );

                if (selectedIndex === -1) handleSelectRow([...(selectedRows || selectedData), row]);
                else {
                    handleSelectRow([
                        ...(selectedRows || selectedData).slice(0, selectedIndex),
                        ...(selectedRows || selectedData).slice(selectedIndex + 1)
                    ]);
                }
            },
            [getSelectedRowKeys, handleSelectRow, selectedData, selectedRows]
        );

        const getHandleShiftClick = useCallback(
            (row: IObjectWithId) => () => {
                const lastSelectedKey = getSelectedRowKeys().at(-1);
                const firstSelectedKey = getSelectedRowKeys().at(0);
                const currentKey = row[rowsKeyColumnName];

                const allRows = data || dataSource;

                const startReverse = allRows.findIndex((item) => {
                    return item[rowsKeyColumnName] === lastSelectedKey;
                });

                const start = allRows.findIndex((item) => {
                    return item[rowsKeyColumnName] === firstSelectedKey;
                });

                const end = allRows.findIndex((item) => item[rowsKeyColumnName] === currentKey);

                if (start > -1 && end > -1 && startReverse > -1) {
                    if (end > startReverse) handleSelectRow([...allRows.slice(start, end + 1)]);
                    else if (end < start) {
                        handleSelectRow([...allRows.slice(end, startReverse + 1)]);
                    } else {
                        handleSelectRow([...allRows.slice(start, end + 1)]);
                    }
                }
            },
            [data, dataSource, getSelectedRowKeys, handleSelectRow]
        );

        const getHandleDoubleClick = useCallback(
            (row: IObjectWithId) => () => {
                const metaRoutes = metaStore.meta.get(meta)?.routes;
                const metaDetailRouteId = metaRoutes?.find((route) =>
                    route.path.includes(`${location.pathname}`)
                )?.detail_route_id;

                let tunedDetailPageRoute;
                if (metaDetailRouteId) {
                    tunedDetailPageRoute = metaStore.meta
                        .get(meta)
                        ?.routes?.find((route) => route.id === metaDetailRouteId);
                }

                if (row.Id) {
                    const pageTitle = getDetailPageTitle({
                        pathname: location.pathname,
                        state: location.state,
                        data: row,
                        meta
                    });

                    const state = {
                        ...location.state,
                        pageTitle
                    };

                    if (tunedDetailPageRoute) {
                        storeNavigate(
                            { pathname: `${tunedDetailPageRoute.path}/${row.Id}`, search: '' },
                            { state }
                        );
                    } else
                        storeNavigate(
                            { pathname: `${location.pathname}/${row.Id}`, search: '' },
                            { state }
                        );
                }
            },
            [location.pathname, location.state, meta, storeNavigate]
        );

        const fieldsSource = useMemo(() => {
            if (fields) {
                console.log('[SmartTable] table fields from PROPS:', fields);
                return fields || [];
            }

            const fieldsSource = metaStore.meta.get(meta)?.info?.Fields || [];
            console.log('[SmartTable] table fields from META:', fieldsSource);

            return fieldsSource;
        }, [fields, meta]);

        const getFixed = useCallback(
            (dataIndex: string) => {
                let fixed: 'left' | 'right' | undefined;

                if (fixedFields) {
                    if (fixedFields.left?.includes(dataIndex)) fixed = 'left';
                    else if (fixedFields.right?.includes(dataIndex)) fixed = 'right';
                }

                return fixed;
            },
            [fixedFields]
        );

        // ### КОЛОНКИ, которые формируем по данным из меты (info)
        const columns = useMemo(() => {
            const columns: TColumnType[] = [];

            const fixedFirstColumnDataIndex = fixedFields?.left?.[0];

            for (const field of fieldsSource) {
                if (field.IsHiddenOnTable) continue;

                const dataIndex = field.FieldName;
                const key = field.ColumnName || dataIndex;
                const readOnly = field.IsReadOnly || field.ValueType?.includes('value:');

                const title = field.Name
                    ? field.Name[language] || t(key || dataIndex)
                    : t(key || dataIndex);

                let addMethod: 'push' | 'unshift' = 'push';
                if (dataIndex === fixedFirstColumnDataIndex) {
                    addMethod = 'unshift';
                }

                columns[addMethod]({
                    width: dataIndex.includes('Title') || dataIndex.includes('Name') ? 300 : 200,
                    className: 'smart_table__column',
                    key,
                    dataIndex,
                    title,
                    // hidden: field.IsHiddenOnTable,
                    // ? TODO: ellipsis with custom tooltip
                    ellipsis: true,
                    // ### сортировка ###
                    // defaultSortOrder: defaultSortOrder.descend?.includes(dataIndex)
                    //     ? 'descend'
                    //     : defaultSortOrder.ascend?.includes(dataIndex)
                    //     ? 'ascend'
                    //     : undefined,
                    // defaultSortOrder: 'descend',
                    // sortOrder: defaultSortOrder.descend?.includes(dataIndex)
                    //     ? 'descend'
                    //     : defaultSortOrder.ascend?.includes(dataIndex)
                    //     ? 'ascend'
                    //     : undefined,
                    sortOrder: rowSorting?.[dataIndex],
                    sorter: getColumnSorterRule(dataIndex, field.ValueType),
                    // ### закреп ###
                    fixed: getFixed(dataIndex),
                    // ### режим редактирования ###
                    // editable, // TODO: !!!! отключил. посмотреть на что повлияет
                    // передаем пропсы для компонента ячейки ###
                    onCell:
                        editable && viewMode === 'inline' && !readOnly
                            ? (row: IObjectWithId, rowIndex?: number) => ({
                                  row,
                                  rowIndex,
                                  dataIndex,
                                  title,
                                  editable,
                                  validation,
                                  onTableDataChange: (rowValue: any) => {
                                      handleDataSourceChange(rowValue);
                                  },
                                  render:
                                      //   !readOnly
                                      //       ?
                                      fieldEditRender({
                                          field,
                                          language,
                                          fields: fieldsSource,
                                          rootMeta,
                                          rootDataSource: rootData
                                      })
                                  //   : fieldRender({
                                  //         field,
                                  //         language: language as LANGUAGES,
                                  //         fields: metaFieldData,
                                  //         isViewAsLink: true,
                                  //         rootMeta
                                  //     })
                              })
                            : // : (row: IObjectWithId, rowIndex?: number) => ({
                              //       row,
                              //       rowIndex,
                              //       dataIndex
                              //   contextMenuItems: rowContextMenuItems?.map((item) => {
                              //       if (item?.key !== 'copy') return item;

                              //       const reactNode = fieldRender({
                              //           field,
                              //           language: language as LANGUAGES,
                              //           fields: metaFieldData,
                              //           isViewAsLink: true,
                              //           rootMeta
                              //       })(row[dataIndex], row, rowIndex);

                              //       return {
                              //           ...item,
                              //           onClick: item?.onClick(
                              //               typeof reactNode === 'object'
                              //                   ? reactNode?.props?.children
                              //                   : reactNode
                              //           )
                              //       };
                              //   }),
                              //   onRightClick:
                              //       rowContextMenuItems && clickable
                              //           ? getHandleRightClickRow(row)
                              //           : undefined
                              //   }),
                              undefined,
                    // ### рендерим ячейки (просмотр) ###
                    render:
                        dataIndex === fixedFirstColumnDataIndex
                            ? (value, row, rowIndex) => (
                                  <Flex justify="space-between">
                                      {fieldRender({
                                          field,
                                          language: language as LANGUAGES,
                                          fields: fieldsSource,
                                          isViewAsLink: viewMode !== 'inline',
                                          rootMeta,
                                          rootDataSource: rootData
                                      })(value, row, rowIndex)}

                                      {row.TotalDocumentsCount && <PaperClipOutlined />}
                                  </Flex>
                              )
                            : fieldRender({
                                  field,
                                  language: language as LANGUAGES,
                                  fields: fieldsSource,
                                  isViewAsLink: viewMode !== 'inline',
                                  rootMeta,
                                  rootDataSource: rootData
                              })
                });
            }

            if (viewMode === 'modal') {
                columns.push({
                    width: 50,
                    className: 'smart_table__column',
                    key: editable ? 'edit' : 'view',
                    dataIndex: editable ? 'Edit' : 'View',
                    title: '',
                    // ### закреп ###
                    fixed: 'right',
                    // ### рендерим ячейки ###
                    render: (_, row) =>
                        editable ? (
                            <EditOutlined
                                style={{ marginTop: '3.5px', display: 'block' }}
                                onClick={() => {
                                    setRowModalData(row);
                                    setRowModalOpen(true);
                                }}
                            />
                        ) : (
                            <EyeOutlined
                                style={{ marginTop: '3.5px', display: 'block' }}
                                onClick={() => {
                                    setRowModalData(row);
                                    setRowModalOpen(true);
                                }}
                            />
                        )
                });
            }
            // }
            // console.log('[SmartTable] table columns:', columns);

            return columns;
        }, [
            fieldsSource,
            fixedFields?.left,
            viewMode,
            language,
            t,
            rowSorting,
            getFixed,
            editable,
            validation,
            rootMeta,
            handleDataSourceChange
        ]);

        const getCustomRowProps = useCallback<NonNullable<TableProps['onRow']>>(
            (row, index) => ({
                row,
                index,
                virtual,
                contextMenuItems: rowContextMenuItems,
                onDoubleClick: doubleClickable
                    ? onDoubleClick
                        ? () => onDoubleClick(row, index)
                        : getHandleDoubleClick(row)
                    : undefined,
                onRightClick:
                    rowContextMenuItems && clickable ? getHandleRightClickRow(row) : undefined,
                onClick: selectable && clickable ? getHandleClickRow(row) : undefined,
                onCtrlClick:
                    selectable && clickable
                        ? selectionType === 'checkbox'
                            ? getHandleCtrlClickRow(row)
                            : getHandleClickRow(row)
                        : undefined,
                onShiftClick:
                    selectable && clickable
                        ? selectionType === 'checkbox'
                            ? getHandleShiftClick(row)
                            : getHandleClickRow(row)
                        : undefined
            }),
            [
                clickable,
                doubleClickable,
                getHandleClickRow,
                getHandleCtrlClickRow,
                getHandleDoubleClick,
                getHandleRightClickRow,
                getHandleShiftClick,
                onDoubleClick,
                rowContextMenuItems,
                selectable,
                selectionType,
                virtual
            ]
        );

        // ### Найстройки для РЕЖИМА РЕДАКТИРОВАНИЯ
        const editableOptions = useMemo(() => {
            const options: EditableOptionsType = {};

            if (editable && viewMode === 'inline') {
                options.onRow = getCustomRowProps;
                options.components = tableEditableComponents;
            }

            return options;
        }, [editable, viewMode, getCustomRowProps]);

        const scrollOptions = useMemo(
            () => ({
                y: virtual ? window.innerHeight - 22 * 13.48 : 'calc(100vh - 22rem)',
                x:
                    // isScreenMd || isScreenSm
                    //     ? 50
                    // : // : columns.filter((c) => !c.hidden).length *
                    columns.length * (isSmartTablePage ? 200 : 1)
            }),
            [columns.length, isSmartTablePage, virtual]
        );

        const handleSort = useCallback<NonNullable<TableProps['onChange']>>(
            (pagination, filters, sorter) => {
                setRowSorting({ [sorter.field]: sorter.order });
            },
            []
        );

        // TODO: drag сортировка колонок (?)

        return (
            <>
                <Table
                    className="smart_table"
                    // ### статус загрузки ###
                    // loading={loading}
                    loading={
                        loading ? { spinning: loading, indicator: <Loader />, delay: 100 } : loading
                    }
                    // ### данные таблицы ###
                    // expandable={{
                    //     // defaultExpandAllRows: true
                    //     defaultExpandedRowKeys: tableData.map(
                    //         (item) => item[rowsKeyColumnName] || ''
                    //     )
                    // }}
                    columns={columns.filter(columnFilterRule)}
                    dataSource={treeData}
                    rowKey={rowsKeyColumnName}
                    // ### внешний вид таблицы ##
                    tableLayout={layout}
                    size="small"
                    bordered
                    // ### выбор строки ###
                    rowSelection={selectable ? rowSelection : undefined}
                    // ### виртуализация ###
                    pagination={false}
                    // TODO: пока работает лучше без виртуального скролла, надо определиться
                    // когда его показывать
                    // вернул, т.к были проблемы при показе 1к заказов
                    virtual={virtual}
                    onChange={handleSort}
                    showSorterTooltip={false}
                    // ### рамер тела таблицы ###
                    scroll={scrollOptions}
                    // ### тулбары ###
                    title={titleRender}
                    footer={footerRender}
                    locale={{ emptyText: <EmptyMarker size="small" noImage /> }}
                    components={tableViewComponents}
                    onRow={getCustomRowProps}
                    // ### РЕЖИМ РЕДАКТИРОВАНИЯ
                    {...editableOptions}
                />

                {viewMode === 'modal' && (
                    <RowModal
                        rootMeta={rootMeta}
                        rootDataSource={rootData}
                        open={rowModalOpen}
                        data={rowModalData}
                        setData={setRowModalData}
                        metaFields={fields}
                        onClose={() => {
                            setRowModalData({});
                            setRowModalOpen(false);
                        }}
                        onOk={() => {
                            handleDataSourceChange(rowModalData);
                            setRowModalData({});
                            setRowModalOpen(false);
                        }}
                        mode={editable ? 'edit' : 'view'}
                    />
                )}
            </>
        );
    }
);
