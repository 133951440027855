import { Timeline, TimelineItemProps } from 'antd';
import { BaseTableRow, SupabaseViewRow, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessages } from 'utils/hooks';

import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';

import { NumberField, PrefixedSelectField } from 'components/fields';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { BaseField } from '../BaseField/BaseField';

type StepOption = {
    id: string; // ID поля
    prefix: string; // Префикс
    viewName: ViewName; // View
    inParameterFieldNameFromValue?: string; // Поле из контекста(value) из которого будет браться значение для фильтра
    filterBy?: string; // По какому полю будем фильтровать View
};

interface StepByStepFieldProps<
    T extends ViewName,
    SupabaseViewType extends SupabaseViewRow<T> & BaseTableRow // Тип данных по ViewName
> {
    id: string;
    viewNameOfValue: T;
    value: TableRow | null;
    mode: 'view' | 'edit';
    steps: StepOption[];
    onChange?: (newValue: SupabaseViewType | null) => void;
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean; // TODO: реализовать выключение Blur. возможно, это тут и не нужно
}

// Поле StepByStepField не должно использоваться в таблицах!
export const StepByStepWarehouseGeneratorField = <
    T extends ViewName,
    SupabaseViewType extends SupabaseViewRow<T> & BaseTableRow
>({
    id,
    mode,
    steps,
    value,
    onChange = () => {},
    required = false,
    skipBlur = false // TODO: реализовать выключение Blur. возможно, это тут и не нужно
}: StepByStepFieldProps<T, SupabaseViewType>) => {
    const { t } = useTranslation();

    // Позиции timeline, актуальны в режиме edit
    const [items, setItems] = useState<TimelineItemProps[]>([]);

    const [aisleId, setAisleId] = useState<number>();

    const { openMessage } = useMessages({ message: '' });

    const fetchData = async () => {
        const result = await supabaseClient
            .from('vdocs_warehouse_rows')
            .select('*')
            .filter('warehouse_aisle_id', 'eq', aisleId);

        if (result.data) {
            return result?.data;
        }

        return null;
    };

    // Заполнение Items по указанным steps
    useEffect(() => {
        if (steps) {
            const timelineItems: TimelineItemProps[] = [];
            let isRowRangeInvalid = false;
            const fetchDataAndProcess = async () => {
                for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
                    const step = steps[stepIndex];

                    const filters: Filter[] = [];

                    let noFilter = false; // Определяем заполнено ли поле из контекста по которому фильтруем ,
                    // если нет - считаем что пред.шаг не был выполнен

                    // Определяем фильтр на каждом шаге
                    if (step.filterBy && step.inParameterFieldNameFromValue) {
                        // Получаем значение фильтра
                        const filterValue = (value as Record<string, any>)[
                            step.inParameterFieldNameFromValue
                        ];

                        const fromValue = (value as Record<string, any>)[
                            `${steps[stepIndex - 1].id}_from`
                        ];

                        const toValue = (value as Record<string, any>)[
                            `${steps[stepIndex - 1].id}_to`
                        ];

                        if (steps[stepIndex].id === 'warehouse_row') {
                            const currentToValue = (value as Record<string, any>)[
                                `${steps[stepIndex].id}_to`
                            ];
                            const currentFromValue = (value as Record<string, any>)[
                                `${steps[stepIndex].id}_from`
                            ];
                            // Получаем разность между введенными значениями для проверки на корректность ввода
                            const rowRangeDifference = currentToValue - currentFromValue;
                            if (currentToValue !== undefined && currentFromValue !== undefined) {
                                // Получаем данные о рядах в проходе
                                const rowData = await fetchData();

                                let filteredData = [];

                                // Если найдены дынные об имеющихся рядах в выбранном проходе
                                if (rowData && rowData?.length > 0) {
                                    // Находим, есть ли в проходе ряды, где порядковые номера равны текущим введенным значениям
                                    filteredData = rowData.filter((item: any) => {
                                        const serialNumber = parseInt(item.serial_number, 10);
                                        return (
                                            serialNumber === currentFromValue ||
                                            serialNumber === currentToValue
                                        );
                                    });
                                }

                                if (
                                    // Выводим ошибку о неккоректных интервалах, если:
                                    // 1.Разность между введенными значениями не 1 и не 0, так как рядов может быть только два последовательных числа
                                    // 2.Если у прохода уже существуют два ряда, то filteredData.length должны содержать либо их значения, либо только одного из значений, но
                                    // filteredData.length не может быть пустой в данном случае!
                                    // 3.Если у прохода только 1 ряд, то разница между введенным значением "До" (правая граница) и серийным номером ряда в текущем проходе может быть
                                    // либо 0, либо 1, либо -1, если серийный номер ряда больше, чем правая граница, например, заведен один ряд с серийным номером 2, тогда
                                    // пользователь может в генераторе ввести в рядах либо от 1 до 1, от 2 до 2, от 1 до 2, от 2 до 3, от 3 до 3
                                    (rowRangeDifference !== 0 && rowRangeDifference !== 1) ||
                                    (rowData &&
                                        rowData.length === 2 &&
                                        filteredData.length === 0) ||
                                    (rowData &&
                                        rowData.length === 1 &&
                                        currentToValue - parseInt(rowData[0].serial_number, 10) !==
                                            0 &&
                                        currentToValue - parseInt(rowData[0].serial_number, 10) !==
                                            1 &&
                                        currentToValue - parseInt(rowData[0].serial_number, 10) !==
                                            -1)
                                ) {
                                    openMessage({
                                        message: t('incorrectly_specified_range'),
                                        type: 'error'
                                    });

                                    isRowRangeInvalid = true;
                                }
                            }
                        }

                        if (filterValue || (fromValue && toValue && !isRowRangeInvalid)) {
                            filters.push({
                                column: step.filterBy,
                                operator: 'eq',
                                value: filterValue
                            });
                        } else {
                            noFilter = true;
                        }
                    }

                    timelineItems.push({
                        label: t(step.id),
                        position: 'left',
                        color: noFilter ? 'red' : 'blue',
                        children: !noFilter ? ( // Если noFilter = true -> выводим пустоту - надо заполнить пред.шаг
                            step.id === 'warehouse_row' ||
                            step.id === 'warehouse_column' ||
                            step.id === 'warehouse_level' ? (
                                <div
                                    style={{
                                        minWidth: '20rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}
                                >
                                    <NumberField
                                        id={`${step.id}`}
                                        mode={mode}
                                        value={value ? value[`${step.id}_from`] : undefined}
                                        isInteger
                                        withLabel={false}
                                        step={1}
                                        min={1}
                                        max={999}
                                        onChange={(fromValue) => {
                                            const newData = {
                                                ...value,
                                                [`${step.id}_from`]: fromValue
                                            };
                                            onChange(newData as SupabaseViewType);
                                        }}
                                    />
                                    {'→'}
                                    <NumberField
                                        id={`${step.id}`}
                                        mode={mode}
                                        isInteger
                                        step={1}
                                        min={1}
                                        max={999}
                                        withLabel={false}
                                        value={value ? value[`${step.id}_to`] : undefined}
                                        onChange={(fromValue) => {
                                            const newData = {
                                                ...value,
                                                [`${step.id}_to`]: fromValue
                                            };
                                            onChange(newData as SupabaseViewType);
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ minWidth: '20rem' }}>
                                    {/* // Каждый шаг это PrefixedSelectField // Изменение каждого шага идет
                                в DataSet // Ожидаем что у используемого TableRow в Dataset есть
                                указанные префиксом поля на каждом шаге в View шага */}
                                    <PrefixedSelectField
                                        id={step.id} // ID шага пускай будет ID филдом - соотв. по нему определяется Label
                                        mode={mode}
                                        withLabel={false}
                                        prefix={step.prefix}
                                        viewName={step.viewName}
                                        value={value}
                                        searchListFilter={filters}
                                        onChange={(newValue) => {
                                            // Изменение шага предполагает что мы очищаем данные нижестоящих шагов
                                            // Получаем ключи текущих данных
                                            const valueKeys: string[] = Object.keys({ ...value });
                                            const clearBelowStepsFields: Record<string, any> = {};

                                            // Проходимся по всем нижестоящим шагам
                                            for (let j = stepIndex + 1; j < steps.length; j++) {
                                                const step = steps[j];

                                                // Ищем все поля которые подходят под префикс
                                                const fieldsByStepPrefix = valueKeys.filter((key) =>
                                                    key.startsWith(step.prefix)
                                                );

                                                if (fieldsByStepPrefix) {
                                                    // Обнуляем все поля с префиксами нижестоящих шагов
                                                    for (const field of fieldsByStepPrefix) {
                                                        clearBelowStepsFields[field] = null;
                                                    }
                                                }
                                            }

                                            if (newValue?.warehouse_aisle_id) {
                                                setAisleId(newValue?.warehouse_aisle_id);
                                            }

                                            // Обновляем данные
                                            const newData = {
                                                ...newValue,
                                                ...clearBelowStepsFields
                                            };

                                            onChange(newData as SupabaseViewType);
                                        }}
                                    />
                                </div>
                            )
                        ) : (
                            <></> // Выводим пустоту
                        )
                    });
                }

                setItems(timelineItems);
            };

            fetchDataAndProcess();
        }
    }, [steps]); // не добавлять больше параметров!

    const renderInput = useCallback(
        (inputValue: number, onInputChange?: (newValue: number) => void, onBlur?: () => void) => {
            return <Timeline items={items} mode="left" />;
        },
        [items]
    );

    const renderView = useCallback(
        (viewValue: SupabaseViewType) => {
            return (
                <>
                    {steps.map((step) => (
                        <PrefixedSelectField
                            id={step.id}
                            mode="view"
                            prefix={step.prefix}
                            viewName={step.viewName}
                            value={value}
                        />
                    ))}
                </>
            );
        },
        [steps, value]
    );

    return (
        <BaseField
            withLabel={false}
            required={required}
            id={id}
            value={value}
            mode={mode}
            onChange={onChange}
            renderInput={renderInput}
            renderView={renderView}
        />
    );
};
