import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { MultilanguageField } from '../MultilanguageField/MultilanguageField';
import { PrefixedSelectField } from '../PrefixedSelectField/PrefixedSelectField';

interface DictionaryPrefixedSelectFieldProps {
    fieldName: string;
    mode: 'view' | 'edit';
    dictionary_view_name: ViewName;
    value: TableRow | null;
    withLabel?: boolean;
    onChange?: (newValue: Record<string, any>) => void;
}

const renderMultilanguageField = ({ row }: { row: Record<string, any> }) => (
    <MultilanguageField
        id="short_title"
        mode="view"
        withLabel={false}
        value={row.original.short_title}
    />
);

export const DictionaryPrefixedSelectField = memo<DictionaryPrefixedSelectFieldProps>(
    ({ fieldName, mode, value, dictionary_view_name, onChange, withLabel }) => {
        const { t } = useTranslation();

        const getPrefixFromFieldName = useCallback(() => {
            const parseFieldName = fieldName.split('_');

            const prefix = parseFieldName.reduce((prefixValue, fieldNameToken, index) => {
                if (index > parseFieldName.length - 2) {
                    return prefixValue;
                }
                return index === parseFieldName.length - 2
                    ? `${prefixValue}${fieldNameToken}`
                    : `${prefixValue}${fieldNameToken}_`;
            }, '');

            return prefix;
        }, [fieldName]);

        const [prefix] = useState(getPrefixFromFieldName());

        const columns = useMemo<MRTColumnDef[]>(
            () => [
                {
                    header: t('code'),
                    accessorKey: 'code',
                    enableEditing: false
                },

                {
                    header: t('short_title'),
                    accessorKey: 'short_title',
                    Cell: renderMultilanguageField,
                    enableEditing: false
                }
            ],
            [t]
        );

        return (
            <PrefixedSelectField
                id={fieldName}
                mode={mode}
                withLabel={withLabel}
                viewName={dictionary_view_name}
                searchLishColumns={columns}
                prefix={`${prefix}_`}
                value={value}
                onChange={(newValue) => {
                    if (onChange && newValue) {
                        onChange(newValue);
                    }
                }}
            />
        );
    }
);
