import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import { MouseEventHandler, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { CheckboxField, TimeDurationField } from 'components/fields';
import { actToInvoiceAutolink } from 'modules/services/backend-functions/act-to-invoice-autolink';
import { ButtonWithTooltips } from 'ui';

import { MultiInsertButton } from 'components/Modals/MultiInsertButton';
import { SelectPrintingFormModal } from 'components/Modals/SelectPrintingFormModal';
import { DatasetTable, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { useMessages } from 'utils/hooks';
import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';
import { MRTColumnDef } from './MRT_Types';

// dayjs.extend(duration);
interface DetailPage_DataTableToolbarProps {
    mode: 'view' | 'edit';
    data: DatasetTable;
    selectedRows: Record<string, boolean>;
    setFilteredTable?: (newTable: DatasetTable) => void;
    onAddNewRowButtonPress?: MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLButtonElement>;
    onDeleteRowButtonPress?: MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLButtonElement>;
    canRowBeDeleted?: (row: Record<string, boolean>, data: DatasetTable) => boolean;
    maxRows?: number;
    childViewName?: ViewName;
    // проверка на то, может ли строка быть добавлена
    canRowBeAdded?: boolean;
    columns?: MRTColumnDef[];
    filters?: Filter[];
    extraToolbar?: React.ReactNode;
}

const parseTimeString = (
    timeString: string
): { hours: number; minutes: number; seconds: number } | null => {
    const timeComponents = timeString.split(':');

    if (timeComponents.length === 3) {
        const hours = timeComponents[0].trim();
        const minutes = timeComponents[1].trim();
        const seconds = timeComponents[2].trim();
        const parsedHours = parseInt(hours, 10);
        const parsedMinutes = parseInt(minutes, 10);
        const parsedSeconds = parseInt(seconds, 10);

        return { hours: parsedHours, minutes: parsedMinutes, seconds: parsedSeconds };
    }

    return null;
};

// ---Правки-для-Рейсов-по-задаче-массовое-изменение-времени-у-стопов
const extractDigits = (input: { [key: string]: boolean }): number[] => {
    const digits: number[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in input) {
        // eslint-disable-next-line no-prototype-builtins, no-restricted-globals
        if (input.hasOwnProperty(key) && !isNaN(Number(key))) {
            digits.push(Number(key));
        }
    }

    return digits;
};

// type ViewType = Database['public']['Views']['vsettings_printing_forms']['Row'];
export const DetailPage_DataTableToolbar = memo<DetailPage_DataTableToolbarProps>(
    ({
        onAddNewRowButtonPress,
        onDeleteRowButtonPress,
        mode,
        selectedRows,
        setFilteredTable,
        canRowBeDeleted = () => false,
        data,
        maxRows,
        childViewName,
        canRowBeAdded = () => true,
        columns,
        extraToolbar,
        filters = []
    }) => {
        const location = useLocation();
        const [isRowsSelected, setIsRowsSelected] = useState(false);
        const [IsMaxRowsLimitReached, setIsMaxRowsLimitReached] = useState(false);
        const [dataset] = useDataset();
        const { t } = useTranslation();

        const { openMessage } = useMessages({ message: '' });

        const tableIndex = dataset.tables.findIndex((table) => table.viewName === childViewName);

        // ---Правки-для-Рейсов-по-задаче-массовое-изменение-времени-у-стопов
        const [popoverVisible, setPopoverVisible] = useState(false);
        const [stopDeltaTime, setStopDeltaTime] = useState('');
        const [isNegative, setIsNegative] = useState(false);
        const [showPrintingFormModal, setShowPrintingFormModal] = useState<boolean>(false);

        const adjustTimeHandler = () => {
            setPopoverVisible(!popoverVisible);
        };

        const tripStopsHandler = () => {
            if (selectedRows && setFilteredTable && stopDeltaTime !== '') {
                const parsedDeltaTime = parseTimeString(stopDeltaTime);

                if (parsedDeltaTime) {
                    const deltaTime = dayjs.duration(parsedDeltaTime);
                    const stops = data.rows;
                    const selectedKeys = Object.keys(selectedRows);
                    let copyData: DatasetTable | null = null;
                    stops.forEach((stop, index) => {
                        copyData = { ...data };
                        const stopId = String(stop.id);
                        const state = copyData.rows[index]._state;
                        if (selectedKeys.includes(stopId)) {
                            let arrival_planned_at: dayjs.Dayjs = dayjs(stop.arrival_planned_at);
                            let departure_planned_at: dayjs.Dayjs = dayjs(
                                stop.departure_planned_at
                            );
                            if (isNegative) {
                                arrival_planned_at = arrival_planned_at.subtract(
                                    deltaTime.asMilliseconds(),
                                    'milliseconds'
                                );
                                departure_planned_at = departure_planned_at.subtract(
                                    deltaTime.asMilliseconds(),
                                    'milliseconds'
                                );
                            } else {
                                arrival_planned_at = arrival_planned_at.add(
                                    deltaTime.asMilliseconds(),
                                    'milliseconds'
                                );
                                departure_planned_at = departure_planned_at.add(
                                    deltaTime.asMilliseconds(),
                                    'milliseconds'
                                );
                            }
                            copyData.rows[index].arrival_planned_at = arrival_planned_at;
                            copyData.rows[index].departure_planned_at = departure_planned_at;
                            copyData.rows[index]._state =
                                state !== 'Deleted' && state !== 'Inserted' ? 'Updated' : state;
                        }
                    });
                    if (copyData) {
                        setFilteredTable(copyData);
                    }
                }
            }
            setPopoverVisible(false);
        };

        // Правки-для-Автолинка
        const autolinkHandler = useCallback(async () => {
            const actSfSfRecIds = extractDigits(selectedRows);
            const { data, error } = await actToInvoiceAutolink(actSfSfRecIds);
            if (error) {
                openMessage({
                    key: 'add_link_to_invoice_in_acts',
                    message: t('add_link_to_invoice_in_acts_error'),
                    type: 'error'
                });
            } else {
                openMessage({
                    key: 'add_link_to_invoice_in_acts_success',
                    message: t('add_link_to_invoice_in_acts_success'),
                    type: 'success'
                });
            }
        }, [openMessage, selectedRows, t]);

        // Правки-для-Автолинка
        // смотрим превышен ли лимит по строкам в таблице
        useEffect(() => {
            let isLimit;
            const datasetDataRowsLength = dataset.tables[tableIndex]?.rows.filter(
                (row) => row._state !== 'Deleted'
            ).length;
            if (maxRows && datasetDataRowsLength >= maxRows) {
                isLimit = true;
                setIsMaxRowsLimitReached(isLimit);
            } else {
                isLimit = false;
                setIsMaxRowsLimitReached(isLimit);
            }
        }, [dataset.tables[tableIndex]?.rows]);

        useEffect(() => {
            if (selectedRows) {
                let selected = false;

                for (let i = 0; i < Object.keys(selectedRows).length; i += 1) {
                    if (selectedRows[Object.keys(selectedRows)[i]] === true) {
                        selected = true;
                        break;
                    }
                }
                setIsRowsSelected(selected);
            }
        }, [selectedRows]);

        const rootObjectView = dataset.tables[0].viewName.slice(0, -1);
        const childView = data.viewName;

        // пример, рутовая таблица vdocs_transportation_trips
        // таблица из вкладки docs_transportation_trip_access_areas
        // вырезаем рутовую таблицу из названия и получаем нужную вью для ключа
        // vdocs_access_areas
        const keyView = `vdocs${childView.replace(rootObjectView, '')}` as ViewName;

        return (
            <div
                style={{
                    display: 'flex',
                    gap: '0.5rem'
                }}
            >
                {extraToolbar}

                {mode !== 'view' && (
                    <>
                        <ButtonWithTooltips
                            id="plus"
                            className="plus"
                            type="link"
                            onClick={onAddNewRowButtonPress as () => void}
                            disabled={IsMaxRowsLimitReached || !canRowBeAdded}
                            icon={<PlusOutlined />}
                        />
                        <ButtonWithTooltips
                            id="minus"
                            className="minus"
                            type="link"
                            danger
                            disabled={!(isRowsSelected && !canRowBeDeleted(selectedRows, data))}
                            onClick={onDeleteRowButtonPress as () => void}
                            icon={<MinusOutlined />}
                        />
                        {location.pathname.match('warehouse-master-data') && (
                            <MultiInsertButton
                                viewName={keyView}
                                columns={columns}
                                rootId={dataset.root_id}
                                setFilteredTable={setFilteredTable}
                                isDisabled={false}
                                data={data}
                                filters={filters}
                            />
                        )}
                    </>
                )}
                {childViewName === 'vdocs_etran_act_sf_sf_recs' ? (
                    <ButtonWithTooltips
                        id="act-to-invoice-autolink"
                        className="act-to-invoice-autolink"
                        onClick={autolinkHandler}
                        type="primary"
                        disabled={!(Object.keys(selectedRows).length > 0)}
                    >
                        {t('act-to-invoice-autolink')}
                    </ButtonWithTooltips>
                ) : null}
                {childViewName === 'vdocs_product_sku_packages' ? (
                    <>
                        <SelectPrintingFormModal
                            open={showPrintingFormModal}
                            setOpen={setShowPrintingFormModal}
                            documentIds={[parseInt(Object.keys(selectedRows)[0], 10)]}
                            tableName={'docs_product_sku_packages'}
                            viewName={'vdocs_product_sku_packages'}
                        />
                        <ButtonWithTooltips
                            id="product-skus"
                            onClick={() => setShowPrintingFormModal(true)}
                            tooltipTitle={t('printForms')}
                            type="primary"
                        >
                            {t('printForms')}
                        </ButtonWithTooltips>
                    </>
                ) : null}
                {childViewName === 'vdocs_transportation_trip_stops' &&
                dataset.tables[0].viewName !== 'vdocs_transportation_trips' ? (
                    <Popover
                        overlayStyle={{ width: '50vw' }}
                        zIndex={10}
                        placement={'bottomRight'}
                        open={popoverVisible}
                        content={
                            <div>
                                <CheckboxField
                                    id="is-negative"
                                    mode="edit"
                                    value={isNegative}
                                    onChange={(result) => {
                                        setIsNegative(result);
                                    }}
                                />
                                <TimeDurationField
                                    id="stop-delta-time"
                                    mode="edit"
                                    value={stopDeltaTime}
                                    onChange={(result) => {
                                        setStopDeltaTime(result);
                                    }}
                                />
                                <ButtonWithTooltips
                                    id="cancel"
                                    type="link"
                                    className="adjust-time"
                                    onClick={() => {
                                        setPopoverVisible(false);
                                    }}
                                >
                                    {t('cancel')}
                                </ButtonWithTooltips>
                                <ButtonWithTooltips
                                    id="ok"
                                    type="primary"
                                    className="adjust-time"
                                    onClick={tripStopsHandler}
                                >
                                    {t('ok')}
                                </ButtonWithTooltips>
                            </div>
                        }
                        trigger="click"
                        title=""
                    >
                        <ButtonWithTooltips
                            id="adjust-time"
                            className="adjust-time"
                            onClick={adjustTimeHandler}
                            type="primary"
                            disabled={!(Object.keys(selectedRows).length > 0 && mode === 'edit')}
                        >
                            {t('adjust-time')}
                        </ButtonWithTooltips>
                    </Popover>
                ) : null}
            </div>
        );
    }
);
