import { Button, Form, Input, Popover, Typography } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import i18n from 'i18next';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableName, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { useModeContext } from 'pages/base/DetailPage/BaseDetailPageProvider';
import { extractObjectsByKey } from 'utils/helpers/convertData';

import { TableRowWithChangeFunction } from 'components/DataTable/DetailPage_DataTable';
import {
    BarcodeField,
    CheckboxField,
    DateField,
    DateRangeField,
    DateTimeField,
    DateTimeRangeField,
    EmailField,
    MultilanguageField,
    NumberField,
    PhoneField,
    PrefixedSelectField,
    ProductField,
    StringField,
    TimeDurationField,
    TimeField,
    TimeRangeField
} from 'components/fields';

import { ViewOnlyDetailPage_DataTable } from 'components/DataTable/ViewOnlyDetailPage_DataTable';
import { AutolinkIndicator } from 'ui/AutolinkIndicator/AutolinkIndicator';

import { ProvisionIndicator } from 'ui/ProvisionIndicator/ProvisionIndicator';

import { Row } from '@tanstack/table-core/build/lib/types';
import { CronField } from 'components/fields/CronField/CronField';
import { MRT_AggregationFn, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { StoreLink } from 'ui';
import { flowColorMapper, statusColorMarker } from 'utils/helpers/colorMarkers';
import {
    ObjectLifecycleStatusGroupTitleFilter,
    ObjectTypeViewMapping,
    UIColumnType
} from './FieldMapping';

// Получаем префикс по названию колонки
export const getPrefixByColumnID = (columnId: string, userField?: boolean) => {
    // if (userField) {
    //     return columnId.slice(0, -2);
    // }

    const splittedColumnName = columnId.split('_');

    let prefix = '';
    for (let i = 0; i < splittedColumnName.length - 1; i += 1) {
        prefix = `${prefix}${splittedColumnName[i]}_`;
    }

    if (splittedColumnName.length === 1 && columnId !== 'id' && columnId !== 'key') {
        prefix = `${prefix}${columnId}_`;
    }

    return prefix;
};

// Получаем префикс по окончанию
const getPrefix = (value: string, endWith: string) => {
    if (value && value.endsWith(endWith) && value.substring(0, endWith.length) !== endWith) {
        return value.substring(0, value.length - endWith.length);
    }
    return '';
};

// Хук возвращает ссылку - куда навигироваться при клике в режиме просмотра
const useNavigateLink = ({
    rowData,
    columnType,
    navigateTo,
    columnId,
    viewName
}: {
    rowData: Record<string, any>;
    columnType: UIColumnType;
    navigateTo: string | undefined;
    columnId: string;
    viewName?: ViewName;
}): string | null => {
    const [navigateLink, setNavigateLink] = useState<string | null>(null);

    useEffect(() => {
        // Логика для полей key
        if (columnType === 'key') {
            // Получаем префикс
            const prefix = getPrefixByColumnID(columnId);

            // По префикс + id - поле где хранится ID
            const idField = `${prefix}id`;
            const keyField = `${prefix}key`;

            const keyValue = String(rowData[keyField]); // В случае ЕТРАН может прийти Int а не string и в итоге упадёт в ошибку keyValue.includes
            const idValue = rowData[idField];

            if (navigateTo) {
                setNavigateLink(`${navigateTo}${idValue}`);
            } else if (columnId === 'key') {
                // For TCP Control Panel
                if (keyValue && keyValue.includes('TUN')) {
                    setNavigateLink(`/orders-management/transportation-units/${idValue}`);
                } else if (keyValue && keyValue.includes('RFQRES')) {
                    setNavigateLink(`/mutual-settlements/rfq-responses/${idValue}`);
                } else if (keyValue && keyValue.includes('TTR')) {
                    console.log('это тут');
                    setNavigateLink(`/transactional-data/transportation-trips/${idValue}`);
                } else if (keyValue && keyValue.includes('RES')) {
                    setNavigateLink(`/master-data/vehicles/${idValue}`);
                } else if (idValue) {
                    setNavigateLink(idValue);
                }
            }
        }

        // Логика для полей id
        if (columnType === 'id') {
            const idValue = rowData[columnId];
            if (navigateTo && idValue) {
                setNavigateLink(`${navigateTo}${idValue}`);
            } else if (columnId === 'id') {
                setNavigateLink(idValue);
            }
        }

        // Логика для полей code
        if (columnType === 'code') {
            const idValue = rowData[columnId];
            if (navigateTo && idValue) {
                setNavigateLink(`${navigateTo}${idValue}`);
            }
        }
    }, [navigateTo, columnId, rowData, columnType]);

    return navigateLink;
};

type CellRenderFunctionType = {
    mode: 'view' | 'edit';
    aggregationFn?: MRT_AggregationFn<any>;
    footerAggregationFn?: MRT_AggregationFn<any>;
    columnId: string;
    rowData?: TableRowWithChangeFunction;
    columnData?: any;
    viewName?: ViewName;
    view_help_filter?: Filter[];
    textValueField?: string;
    dictValueField?: string;
    dictTextField?: string;
    tableName?: TableName;
    fieldRequired?: boolean;
    step?: number;
    fieldId?: string;
    validation?: {
        status: ValidateStatus;
        message: string;
        validator: (data?: any) => boolean;
    };
    order?: 'first' | 'last';
    refLink?: React.Ref<any>;
    table?: MRT_TableInstance<any>;
    row?: MRT_Row<any>;
    popoverContainerHtmlId?: string;
};

const LinkCell = (navigateLink: string | null, value: any) => {
    return value && (typeof value === 'string' || typeof value === 'number') ? (
        <StoreLink replace to={`${navigateLink}`}>
            {value.toString()}
        </StoreLink>
    ) : (
        <Typography.Text type="secondary" className="no_value_text">
            {i18n.t('no_value')}
        </Typography.Text>
    );
};

// Желательно отображать компоненты AS IS с нашими филдами
export const PrefixedSelectCell = ({
    columnId,
    mode,
    rowData,
    viewName,
    view_help_filter,
    asDictionarySelectFieldWithOneColumn,
    asUserField,
    navigate_path,
    fieldId = columnId,
    navigate_param_name,
    popoverContainerHtmlId
}: CellRenderFunctionType & {
    asDictionarySelectFieldWithOneColumn?: boolean;
    asUserField?: boolean;
    navigate_path?: string | null;
    navigate_param_name?: string | null;
}) => {
    const prefix = getPrefixByColumnID(columnId, asUserField);

    const navigateLinkInViewMode = navigate_path
        ? { navPath: navigate_path, navParamName: navigate_param_name || '' }
        : undefined;

    return (
        <PrefixedSelectField
            withLabel={false}
            id={columnId}
            value={rowData as TableRow}
            mode={mode}
            htmlId={fieldId}
            prefix={prefix}
            searchListFilter={view_help_filter}
            viewName={viewName as ViewName}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRow(value);
                }
            }}
            asDictionarySelectFieldWithOneColumn={asDictionarySelectFieldWithOneColumn}
            popoverPlacement="right"
            navigateLinkInViewMode={navigateLinkInViewMode}
            popoverContainerHtmlId={popoverContainerHtmlId}
        />
    );
};

const ProductCell = ({
    columnId,
    mode,
    rowData,
    viewName,
    fieldId = columnId,
    view_help_filter,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    const prefix = getPrefixByColumnID(columnId);

    return (
        <ProductField
            withLabel={false}
            id={columnId}
            fields={rowData as TableRow}
            mode={mode}
            prefix={prefix}
            searchListFilter={view_help_filter}
            viewName={viewName as ViewName}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRow(value);
                }
            }}
            popoverContainerHtmlId={popoverContainerHtmlId}
        />
    );
};

const PlanningDriverInOrderCell = ({
    columnId,
    mode,
    rowData,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <PlanningDriverInOrderPrefixSelectField
            withLabel={false}
            id={columnId}
            value={rowData as TableRow}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRow(value);
                }
            }}
            popoverContainerHtmlId={popoverContainerHtmlId}
        />
    );
};

// const AutoCompleteCell = ({
//     columnId,
//     rowData,
//     columnData,
//     mode,
//     fieldId = columnId,
//     viewName,
//     fieldRequired
// }: CellRenderFunctionType) => {
//     const filters: Filter[] = [];
//     if (columnId === 'region' && rowData) {
//         filters.push({
//             column: 'country_code',
//             operator: 'eq',
//             value: rowData.country_code
//         });
//     }
//     return (
//         <AutocompleteWithViewHelpField
//             withLabel={false}
//             id={columnId}
//             value={columnData}
//             mode={mode}
//             required={fieldRequired}
//             viewName={viewName as ViewName}
//             onChange={(value) => {
//                 if (rowData) {
//                     rowData.changeRowField(columnId, value);
//                 }
//             } }
//             filters={filters}
//             multiLanguageValue={false} prefix={''}        />
//     );
// };

export const NumberCell = ({
    rowData,
    columnData,
    mode,
    columnId,
    step,
    fieldId = columnId,
    fieldRequired,
    refLink
}: CellRenderFunctionType) => {
    return (
        <NumberField
            refLink={refLink}
            withLabel={false}
            step={step}
            id={columnId}
            htmlId={fieldId}
            required={fieldRequired}
            value={columnData}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

export const StringCell = ({
    rowData,
    columnData,
    mode,
    columnId,
    fieldId = columnId,
    fieldRequired,
    validation
}: CellRenderFunctionType) => {
    const validate = (): { status: ValidateStatus; message: string } => {
        if (validation && validation.validator(columnData)) {
            const { status, message } = validation;
            return { status, message };
        }
        return { status: '', message: '' };
    };

    return (
        <StringField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            value={columnData}
            mode={mode}
            required={fieldRequired}
            validateStatus={validate().status}
            validateMessage={validate().message}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const CheckboxCell = ({
    rowData,
    columnData,
    mode,
    columnId,
    fieldId = columnId
}: CellRenderFunctionType) => {
    // console.log('mode:', mode);
    return (
        <CheckboxField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            value={columnData}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

export const MultilanguageCell = ({
    columnData,
    rowData,
    columnId,
    mode,
    fieldId = columnId,
    fieldRequired
}: CellRenderFunctionType) => {
    return (
        <MultilanguageField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            value={columnData}
            required={fieldRequired}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

export const DateCell = ({
    mode,
    columnId,
    columnData,
    rowData,
    fieldId = columnId,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <DateField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            value={columnData}
            mode={mode}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const TimeCell = ({
    mode,
    columnId,
    columnData,
    fieldId = columnId,
    rowData,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <TimeField
            withLabel={false}
            id={columnId}
            value={columnData}
            popoverContainerHtmlId={popoverContainerHtmlId}
            htmlId={fieldId}
            mode={mode}
            required={fieldRequired}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};
export const getDateTimezoneNumber = (
    row: TableRowWithChangeFunction | undefined,
    order?: 'first' | 'last'
) => {
    if (!row) return '0';

    let location_timezone_code = row.location_timezone_code;
    let location_timezone_long_title = row.location_timezone_long_title;
    if (order === 'first') {
        location_timezone_code = row.first_location_timezone_code;
        location_timezone_long_title = row.first_location_timezone_long_title;
    } else if (order === 'last') {
        location_timezone_code = row.last_location_timezone_code;
        location_timezone_long_title = row.last_location_timezone_long_title;
    }

    if (location_timezone_code) {
        if (location_timezone_long_title) {
            const needle = location_timezone_long_title.ru.match(/UTC([+|-][0-9]+)/);
            const INDEX_TIMEZONE_IN_NUMBER = 1;

            if (needle) {
                return needle[INDEX_TIMEZONE_IN_NUMBER]; // вернет, к примеру +03
            }
        }
    }

    return '0';
};

export const getDateTimezoneNumberFromZone = (
    row: TableRowWithChangeFunction | undefined,
    zonePrefix: string
) => {
    if (!row) return '0';

    const location_timezone_code = row[`${zonePrefix}_code`];
    const location_timezone_long_title = row[`${zonePrefix}_long_title`];

    if (location_timezone_code) {
        if (location_timezone_long_title) {
            const needle = location_timezone_long_title.ru.match(/UTC([+|-][0-9]+)/);
            const INDEX_TIMEZONE_IN_NUMBER = 1;

            if (needle) {
                return needle[INDEX_TIMEZONE_IN_NUMBER]; // вернет, к примеру +03
            }
        }
    }

    return '0';
};

export const DateTimeCell = ({
    mode,
    columnId,
    columnData,
    rowData,
    fieldId = columnId,
    fieldRequired,
    order,
    tableName,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    // const columnId = columnId;

    let timeZoneNumberAsString: string | undefined;

    if (tableName === 'docs_transportation_orders') {
        switch (columnId) {
            case 'early_pickup_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'pickup_location_timezone'
                );
                break;
            case 'late_pickup_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'pickup_location_timezone'
                );
                break;
            case 'early_delivery_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'delivery_location_timezone'
                );
                break;
            case 'late_delivery_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'delivery_location_timezone'
                );
                break;
            default:
                break;
        }
    }

    if (tableName === 'docs_order_movements') {
        switch (columnId) {
            case 'early_pickup_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'source_location_timezone'
                );
                break;
            case 'late_pickup_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'source_location_timezone'
                );
                break;
            case 'early_delivery_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'destination_location_timezone'
                );
                break;
            case 'late_delivery_at':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'destination_location_timezone'
                );
                break;
            default:
                break;
        }
    }

    if (tableName === 'docs_transportation_trips') {
        switch (columnId) {
            case 'trip_start_date_by_stop':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'first_location_timezone'
                );
                break;
            case 'trip_finish_date_by_stop':
                timeZoneNumberAsString = getDateTimezoneNumberFromZone(
                    rowData,
                    'last_location_timezone'
                );
                break;
            default:
                break;
        }
    }

    return (
        <DateTimeField
            withLabel={false}
            id={columnId}
            value={columnData}
            mode={mode}
            htmlId={fieldId}
            utc
            timeZoneNumberString={timeZoneNumberAsString || getDateTimezoneNumber(rowData, order)}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const DateTimeLocalCell = ({
    mode,
    columnId,
    columnData,
    rowData,
    fieldId = columnId,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <DateTimeField
            withLabel={false}
            id={columnId}
            value={columnData}
            htmlId={fieldId}
            mode={mode}
            utc={false}
            popoverContainerHtmlId={popoverContainerHtmlId}
            required={fieldRequired}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const DateTimeRangeLocalCell = ({
    columnId,
    mode,
    columnData,
    rowData,
    fieldId = columnId,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <DateTimeRangeField
            withLabel={false}
            id={columnId}
            value={columnData}
            htmlId={fieldId}
            mode={mode}
            utc={false}
            skipBlur={true}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const DateTimeRangeCell = ({
    columnId,
    mode,
    fieldId = columnId,
    columnData,
    rowData,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <DateTimeRangeField
            withLabel={false}
            id={columnId}
            value={columnData}
            mode={mode}
            htmlId={fieldId}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            utc
            skipBlur={true}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const TimeRangeCell = ({
    columnId,
    mode,
    columnData,
    fieldId = columnId,
    rowData,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <TimeRangeField
            withLabel={false}
            id={columnId}
            value={columnData}
            htmlId={fieldId}
            skipBlur={true}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const ProductSkuStampsCell = ({
    mode,
    columnId,
    columnData,
    fieldId = columnId,
    rowData,
    fieldRequired
}: CellRenderFunctionType) => {
    const { t } = useTranslation();

    const filter = columnData ? columnData.filter((col: any) => col).join(',') : '';

    return (
        <Popover
            content={
                <ViewOnlyDetailPage_DataTable
                    viewName="vdocs_product_sku_stamps"
                    filter={[
                        {
                            column: 'id',
                            operator: 'in',
                            value: `(${filter})`
                        }
                    ]}
                />
            }
            trigger="click"
        >
            {columnData && columnData[0] ? (
                <Button type="text">{`${t('number_of_marks')} : ${
                    columnData && columnData[0] ? columnData.length : 0
                }`}</Button>
            ) : (
                <></>
            )}
        </Popover>
    );
};

export const StatusCell = ({
    columnId,
    rowData,
    mode,
    viewName,
    view_help_filter,
    i18n,
    fieldId = columnId,
    root_view_name,
    t,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType & {
    t: Function;
    i18n: Record<string, any>;
    root_table_name?: TableName;
    root_view_name?: ViewName;
}) => {
    const fieldName = columnId && columnId.replace('_code', '_short_title');

    if (mode === 'edit') {
        const statusFieldName = columnId && columnId.replace('_status_code', '');

        const filter: Filter[] = view_help_filter || [];

        if (columnId === 'lifecycle_status_code' && root_view_name) {
            filter.push({
                column: 'status_group_title',
                operator: 'eq',
                value: ObjectLifecycleStatusGroupTitleFilter[root_view_name]
                    ? ObjectLifecycleStatusGroupTitleFilter[root_view_name]
                    : 'Standard Statuses'
            });
        }

        return PrefixedSelectCell({
            rowData,
            columnId: statusFieldName,
            mode,
            fieldId,
            viewName,
            view_help_filter: filter,
            asDictionarySelectFieldWithOneColumn: true,
            fieldRequired,
            popoverContainerHtmlId
        });
    }

    return (
        <Form.Item>
            <Input
                id={fieldId}
                placeholder={t('no_value') as string}
                variant="borderless"
                value={rowData && rowData[fieldName] ? rowData[fieldName][i18n.language] : ''}
                readOnly
                style={statusColorMarker(
                    rowData && rowData[columnId] ? rowData[columnId] : '',
                    viewName
                )}
            />
        </Form.Item>
    );
};

export const FlowCell = ({
    columnId,
    rowData,
    mode,
    viewName,
    fieldId = columnId,
    view_help_filter,
    t,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType & {
    t: Function;
}) => {
    const fieldName = columnId && columnId.replace('_code', '_long_title');

    if (mode === 'edit') {
        const filter: Filter[] = view_help_filter || [];

        return PrefixedSelectCell({
            rowData,
            columnId,
            mode,
            fieldId,
            viewName,
            view_help_filter: filter,
            asDictionarySelectFieldWithOneColumn: true,
            fieldRequired,
            popoverContainerHtmlId
        });
    }

    const value = rowData && rowData[fieldName] ? rowData[fieldName][i18n.language] : '';

    return (
        <Typography.Text
            id={fieldId}
            style={{
                paddingLeft: '10px',
                color: value ? undefined : '#aeaeae',
                ...flowColorMapper(rowData && rowData[columnId] ? rowData[columnId] : '')
            }}
            type={value ? undefined : 'secondary'}
        >
            {value || t('no_value')}
        </Typography.Text>
    );
};

const UserCell = ({
    columnId,
    rowData,
    mode,
    popoverContainerHtmlId,
    fieldId = columnId,
    fieldRequired
}: CellRenderFunctionType) => {
    return PrefixedSelectCell({
        rowData,
        columnId,
        mode,
        fieldId,
        fieldRequired,
        viewName: 'vusers',
        asUserField: true,
        popoverContainerHtmlId
    });
};
// поставил StringField, так как TypeField показывает short_title типа, а тут нужен именно тип
const TypeCell = ({
    columnId,
    rowData,
    mode,
    i18n,
    fieldId = columnId,
    columnData,
    root_view_name,
    fieldRequired,
    popoverContainerHtmlId,
    t,
    view_help_filter
}: CellRenderFunctionType & {
    t: Function;
    i18n: Record<string, any>;
    root_table_name?: TableName;
    root_view_name?: ViewName;
}) => {
    if (mode === 'edit' && root_view_name && ObjectTypeViewMapping[root_view_name]) {
        const viewName = ObjectTypeViewMapping[root_view_name];

        return PrefixedSelectCell({
            rowData,
            columnId,
            fieldRequired,
            fieldId,
            mode,
            viewName: viewName as ViewName,
            asDictionarySelectFieldWithOneColumn: true,
            view_help_filter,
            popoverContainerHtmlId
        });
    }

    let type_short_title = '';
    if (rowData?.type_short_title?.[i18n.language]) {
        type_short_title = rowData?.type_short_title?.[i18n.language]; // если у типа есть перевод в активном языке, то показать его
    } else if (columnData === 'DEFAULT') {
        type_short_title = t('DEFAULT'); // если перевода нет и тип DEFAULT то показать перевод DEFAULT TODO: как избавимся везде от DEFAULT можно убрать
    } else {
        type_short_title = `${t('add_type_short_title')} ${rowData?.type_code}`;
    } // если перевода нет и тип не DEFAULT то показать текст "Нет названия типа type_code"
    return (
        <StringField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            value={type_short_title}
            mode="view"
        />
    );
};

const PhoneCell = ({
    columnId,
    rowData,
    columnData,
    mode,
    fieldId = columnId,
    fieldRequired
}: CellRenderFunctionType) => {
    return (
        <PhoneField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            required={fieldRequired}
            value={columnData}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const EmailCell = ({
    columnId,
    rowData,
    columnData,
    fieldId = columnId,
    mode,
    fieldRequired
}: CellRenderFunctionType) => {
    return (
        <EmailField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            required={fieldRequired}
            value={columnData}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const CronCell = ({
    columnId,
    rowData,
    columnData,
    fieldId = columnId,
    mode,
    fieldRequired
}: CellRenderFunctionType) => {
    return (
        <CronField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            required={fieldRequired}
            value={columnData}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const DateRangeCell = ({
    columnId,
    rowData,
    columnData,
    fieldId = columnId,
    mode,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <DateRangeField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            value={columnData}
            skipBlur={true}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const DateRangeWithDateTimeReturnCell = ({
    columnId,
    rowData,
    columnData,
    mode,
    fieldRequired,
    popoverContainerHtmlId
}: CellRenderFunctionType) => {
    return (
        <DateRangeField
            withLabel={false}
            id={columnId}
            value={columnData}
            mode={mode}
            required={fieldRequired}
            popoverContainerHtmlId={popoverContainerHtmlId}
            returnLocalDateTimeAllDay
            skipBlur
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

export const ProvisionIndicatorCell = (id: any) => {
    return <ProvisionIndicator id={id} />;
};

export const AutolinkIndicatorCell = (value: any) => {
    return <AutolinkIndicator value={value} />;
};

export const TimeDurationCell = ({
    columnId,
    rowData,
    fieldId = columnId,
    columnData,
    mode
}: CellRenderFunctionType) => {
    return (
        <TimeDurationField
            withLabel={false}
            id={columnId}
            htmlId={fieldId}
            value={columnData as string}
            mode={mode}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
        />
    );
};

const BarcodeCell = ({
    rowData,
    columnData,
    mode,
    columnId,
    fieldId = columnId,
    fieldRequired
}: CellRenderFunctionType) => {
    return (
        <BarcodeField
            withLabel={false}
            id={columnId}
            value={columnData}
            mode={mode}
            required={fieldRequired}
            onChange={(value) => {
                if (rowData) {
                    rowData.changeRowField(columnId, value);
                }
            }}
            popover
        />
    );
};

const ValueFooterAndAggreagatedCell = ({
    columnData,
    columnId,
    aggregationFn,
    table,
    row
}: CellRenderFunctionType) => {
    const calcFunc =
        table?.options?.aggregationFns && table?.options?.aggregationFns[aggregationFn as string]
            ? table?.options?.aggregationFns[aggregationFn as string]
            : () => {};

    return (
        <Typography.Text>
            {i18n.t(aggregationFn as string)}:
            <Typography.Text strong>
                {calcFunc(
                    columnData[columnId],
                    row?.getLeafRows() || [],
                    (row?.subRows || []) as Row<any>[]
                )}
            </Typography.Text>
        </Typography.Text>
    );
};

export const BuildCell = ({
    columnType,
    columnData,
    columnId,
    mode,
    navigateLink,
    rowData,
    viewName,
    view_help_filter,
    filterOption = {
        buildForFilter: false,
        renderRange: false
        // dynamicRange: false,
    },
    i18n,
    t,
    fieldRequired,
    tableMeta,
    table,
    row,
    aggregationFn,
    footerAggregationFn,
    fieldId = columnId,
    popoverContainerHtmlId
}: {
    columnType: UIColumnType;
    navigateLink?: string | null;
    columnData: any;
    i18n: Record<string, any>;
    t: Function;
    rowData: TableRowWithChangeFunction;
    columnId: string;
    mode: 'view' | 'edit';
    viewName: ViewName;
    fieldRequired?: boolean;
    fieldId?: string;
    view_help_filter?: Filter[];
    tableMeta?: {
        tableName: TableName;
        viewName: ViewName;
    };
    table?: MRT_TableInstance<any>;
    row?: MRT_Row<any>;
    aggregationFn?: MRT_AggregationFn<any>;
    footerAggregationFn?: MRT_AggregationFn<any>;
    filterOption?: {
        buildForFilter: boolean;
        renderRange: boolean;
        // dynamicRange: boolean;
    }; // Отображение поля в фильтрах, в фильтре у поля мб другое предствавление
    popoverContainerHtmlId?: string;
}) => {
    switch (columnType) {
        // для id,key хотим видить навигацию,
        // подумать мб вынести это в PrefixedSelectField,
        // но в таком случае PrefixedSelectField должен как то понимать
        // куда переходить
        case 'id': {
            const result = {
                // Cell: LinkCell(navigateLink as string, columnData),
                Cell: PrefixedSelectCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldRequired,
                    fieldId,
                    view_help_filter,
                    navigate_path: navigateLink,
                    popoverContainerHtmlId
                }),
                Edit: PrefixedSelectCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    viewName,
                    fieldRequired,
                    view_help_filter,
                    navigate_path: navigateLink,
                    popoverContainerHtmlId
                })
            };

            const prefix = getPrefixByColumnID(columnId, false); // (colId, asUserField)

            if (!prefix) {
                result.Cell = LinkCell(navigateLink as string, columnData);
            }

            return result;
        }
        case 'key': {
            const result = {
                // Cell: LinkCell(navigateLink as string, columnData),
                Cell: PrefixedSelectCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldRequired,
                    fieldId,
                    view_help_filter,
                    navigate_path: navigateLink,
                    popoverContainerHtmlId
                }),
                Edit: PrefixedSelectCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldId,
                    fieldRequired,
                    view_help_filter,
                    navigate_path: navigateLink,
                    popoverContainerHtmlId
                })
            };

            const prefix = getPrefixByColumnID(columnId, false); // (colId, asUserField)

            if (!prefix) {
                result.Cell = LinkCell(navigateLink as string, columnData);
            }

            return result;
        }
        case 'product':
            return {
                Cell: ProductCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldId,
                    fieldRequired,
                    view_help_filter,
                    popoverContainerHtmlId
                }),
                Edit: ProductCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    viewName,
                    fieldRequired,
                    view_help_filter,
                    popoverContainerHtmlId
                })
            };

        case 'array_id':
            return {
                Cell: StringCell({
                    columnId,
                    mode,
                    fieldId,
                    rowData,
                    columnData,
                    fieldRequired
                }),
                Edit: filterOption.buildForFilter
                    ? PrefixedSelectCell({
                          rowData,
                          columnId,
                          mode,
                          fieldId,
                          viewName,
                          fieldRequired,
                          view_help_filter,
                          popoverContainerHtmlId
                      })
                    : StringCell({
                          columnId,
                          mode,
                          rowData,
                          fieldId,
                          columnData,
                          fieldRequired
                      })
            };
        case 'code':
            return {
                Cell: PrefixedSelectCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldId,
                    view_help_filter,
                    fieldRequired,
                    asDictionarySelectFieldWithOneColumn: true,
                    popoverContainerHtmlId
                }), // LinkCell(navigateLink, columnData),
                Edit: PrefixedSelectCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    viewName,
                    view_help_filter,
                    // asDictionarySelectFieldWithOneColumn: true,
                    fieldRequired,
                    popoverContainerHtmlId
                })
            };

        // case 'autoCompleteField':
        //     return {
        //         Cell: AutoCompleteCell({
        //             rowData,
        //             columnId,
        //             mode,
        //             fieldId,
        //             viewName,
        //             columnData,
        //             fieldRequired,
        //             view_help_filter,
        //             popoverContainerHtmlId
        //         }),
        //         Edit: AutoCompleteCell({
        //             rowData,
        //             columnId,
        //             mode,
        //             viewName,
        //             fieldId,
        //             columnData,
        //             fieldRequired,
        //             view_help_filter,
        //             popoverContainerHtmlId
        //         })
        //     };
        case 'multilang_text':
            return {
                Cell: MultilanguageCell({
                    rowData,
                    columnId,
                    mode,
                    columnData,
                    fieldId,
                    viewName,
                    fieldRequired
                }),
                Edit: MultilanguageCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    columnData,
                    viewName,
                    fieldRequired
                })
            };

        case 'text':
            return {
                Cell: StringCell({ columnId, mode, rowData, columnData, fieldRequired, fieldId }),
                Edit: StringCell({ columnId, mode, rowData, columnData, fieldRequired, fieldId })
            };

        case 'code_text':
            return {
                Cell: StringCell({
                    columnId,
                    mode,
                    rowData,
                    fieldId,
                    columnData,
                    fieldRequired,
                    validation: {
                        status: 'error',
                        message:
                            'Code must be written by latin in upper case and has non-nullable value',
                        validator: (data) => {
                            if (/^[0-9A-Z_]+$/.test(data)) {
                                return false;
                            }
                            return true;
                        }
                    }
                }),
                Edit: StringCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    validation: {
                        status: 'error',
                        message:
                            'Code must be written by latin in upper case and has non-nullable value',
                        validator: (data) => {
                            if (/^[0-9A-Z_]+$/.test(data)) {
                                return false;
                            }
                            return true;
                        }
                    }
                })
            };

        case 'date':
            return {
                Cell: DateCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: !filterOption.renderRange
                    ? DateCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          popoverContainerHtmlId
                      })
                    : DateRangeCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          popoverContainerHtmlId
                      })
            };

        case 'time':
            return {
                Cell: TimeCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: !filterOption.renderRange
                    ? TimeCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          popoverContainerHtmlId
                      })
                    : TimeRangeCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          popoverContainerHtmlId
                      })
            };

        case 'datetime':
            return {
                Cell: DateTimeCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    tableName: tableMeta?.tableName,
                    popoverContainerHtmlId
                }),
                Edit: !filterOption.renderRange
                    ? DateTimeCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          tableName: tableMeta?.tableName,
                          popoverContainerHtmlId
                      })
                    : DateTimeRangeLocalCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          popoverContainerHtmlId
                      })
            };

        case 'datetimelocal':
            return {
                Cell: DateTimeLocalCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: !filterOption.renderRange
                    ? DateTimeLocalCell({
                          columnId,
                          mode,
                          rowData,
                          columnData,
                          fieldId,
                          fieldRequired,
                          tableName: tableMeta?.tableName,
                          popoverContainerHtmlId
                      })
                    : DateTimeRangeLocalCell({
                          columnId,
                          mode,
                          rowData,
                          fieldId,
                          columnData,
                          fieldRequired,
                          tableName: tableMeta?.tableName,
                          popoverContainerHtmlId
                      })
            };

        case 'datetimerange':
            return {
                Cell: DateTimeRangeCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit:
                    filterOption.buildForFilter && !filterOption.renderRange
                        ? DateTimeCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
                        : DateTimeRangeCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
            };

        case 'datetimelocalrange':
            return {
                Cell: DateTimeRangeLocalCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit:
                    filterOption.buildForFilter && !filterOption.renderRange
                        ? DateTimeLocalCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
                        : DateTimeRangeLocalCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
            };

        case 'timerange':
            return {
                Cell: TimeRangeCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit:
                    filterOption.buildForFilter && !filterOption.renderRange
                        ? TimeCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
                        : TimeRangeCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
            };

        case 'daterange':
            return {
                Cell: DateRangeCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit:
                    filterOption.buildForFilter && !filterOption.renderRange
                        ? DateCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
                        : DateRangeCell({
                              columnId,
                              mode,
                              rowData,
                              columnData,
                              fieldId,
                              fieldRequired,
                              popoverContainerHtmlId
                          })
            };

        case 'daterange_with_datetime_return':
            return {
                Cell: DateRangeWithDateTimeReturnCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: DateRangeWithDateTimeReturnCell({
                    columnId,
                    mode,
                    rowData,
                    columnData,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                })
            };

        case 'status':
            return {
                Cell: StatusCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    viewName,
                    view_help_filter,
                    i18n,
                    t,
                    root_table_name: tableMeta?.tableName,
                    root_view_name: tableMeta?.viewName,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: StatusCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldId,
                    view_help_filter,
                    i18n,
                    t,
                    root_table_name: tableMeta?.tableName,
                    root_view_name: tableMeta?.viewName,
                    fieldRequired,
                    popoverContainerHtmlId
                })
            };

        case 'flow':
            return {
                Cell: FlowCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldId,
                    view_help_filter,
                    t,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: FlowCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    fieldId,
                    view_help_filter,
                    t,
                    fieldRequired,
                    popoverContainerHtmlId
                })
            };

        case 'value':
            return {
                aggregationFn: 'max',
                Cell: NumberCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: NumberCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                AggregatedCell: aggregationFn
                    ? ValueFooterAndAggreagatedCell({
                          columnId,
                          columnData,
                          aggregationFn,
                          table,
                          mode,
                          row
                      })
                    : null,
                Footer: footerAggregationFn
                    ? ValueFooterAndAggreagatedCell({
                          columnId,
                          columnData,
                          aggregationFn: footerAggregationFn,
                          table,
                          mode,
                          row
                      })
                    : null
            };

        case 'userid':
            return {
                Cell: UserCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: UserCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    fieldRequired,
                    popoverContainerHtmlId
                })
            };

        case 'type':
            return {
                Cell: TypeCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    view_help_filter,
                    i18n,
                    fieldId,
                    t,
                    root_table_name: tableMeta?.tableName,
                    root_view_name: tableMeta?.viewName,
                    fieldRequired,
                    popoverContainerHtmlId
                }),
                Edit: TypeCell({
                    rowData,
                    columnId,
                    mode,
                    fieldId,
                    viewName,
                    view_help_filter,
                    i18n,
                    t,
                    root_table_name: tableMeta?.tableName,
                    root_view_name: tableMeta?.viewName,
                    fieldRequired,
                    popoverContainerHtmlId
                })
            };

        case 'product_sku_stamps':
            return {
                Cell: ProductSkuStampsCell({
                    rowData,
                    columnId,
                    mode,
                    viewName,
                    columnData,
                    fieldId,
                    fieldRequired,
                    view_help_filter
                }),
                Edit: ProductSkuStampsCell({
                    rowData,
                    columnId,
                    fieldId,
                    mode,
                    viewName,
                    columnData,
                    fieldRequired,
                    view_help_filter
                })
            };
        case 'phone':
            return {
                Cell: PhoneCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: !filterOption.buildForFilter
                    ? PhoneCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
                    : StringCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };

        case 'email':
            return {
                Cell: EmailCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: !filterOption.buildForFilter
                    ? EmailCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
                    : StringCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };
        case 'cron':
            return {
                Cell: CronCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: CronCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };
        case 'number':
            return {
                Cell: NumberCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: NumberCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };
        case 'provision_indicator':
            return {
                Cell: ProvisionIndicatorCell(rowData[columnId]),
                Edit: !filterOption.buildForFilter
                    ? ProvisionIndicatorCell(rowData[columnId])
                    : NumberCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };
        case 'boolean':
            return {
                Cell: CheckboxCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: CheckboxCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };

        case 'duration':
            return {
                Cell: TimeDurationCell({ columnId, mode, rowData, fieldId, columnData }),
                Edit: TimeDurationCell({ columnId, mode, rowData, fieldId, columnData })
            };

        case 'barcode':
            return {
                Cell: BarcodeCell({ columnId, mode, rowData, fieldId, columnData }),
                Edit: BarcodeCell({ columnId, mode, rowData, fieldId, columnData })
            };

        case 'planning_driver_in_order':
            return {
                Cell: PlanningDriverInOrderCell({
                    columnId,
                    mode,
                    rowData,
                    fieldId,
                    columnData,
                    popoverContainerHtmlId
                }),
                Edit: PlanningDriverInOrderCell({
                    columnId,
                    mode,
                    rowData,
                    fieldId,
                    columnData,
                    popoverContainerHtmlId
                })
            };

        default:
            return {
                Cell: StringCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired }),
                Edit: StringCell({ columnId, mode, rowData, columnData, fieldId, fieldRequired })
            };
    }
};

// Функция по заданным параметрам определяет что и как рендерить в колонке
export const CellBuilder = ({
    row,
    column,
    cell,
    columnType,
    navigateTo,
    viewName,
    table,
    view_help_filter,
    aggregationFn,
    footerAggregationFn
}: {
    row: Record<string, any>;
    cell: Record<string, any>;
    column: Record<string, any>;
    table: Record<string, any>;
    columnType: UIColumnType;
    navigateTo?: string;
    viewName?: ViewName;
    view_help_filter?: Filter[];
    aggregationFn?: MRT_AggregationFn<any>;
    footerAggregationFn?: MRT_AggregationFn<any>;
}): {
    Cell: ReactNode; // Что показываем в режиме просмотра
    Edit: ReactNode; // Что показываем в режиме изменения
    Footer?: ReactNode; // Что показываем в режиме изменения
    AggregatedCell?: ReactNode;
} => {
    const [modeFromContext] = useModeContext();

    const [mode, setMode] = useState<'view' | 'edit'>('view');

    useEffect(() => {
        if (!table.options.enableEditing) {
            return setMode('view');
        }

        if (
            typeof column.columnDef.enableEditing === 'function' &&
            column.columnDef.enableEditing(row) === false
        ) {
            return setMode('view');
        }

        if (
            typeof column.columnDef.enableEditing === 'boolean' &&
            column.columnDef.enableEditing === false
        ) {
            return setMode('view');
        }

        return setMode(modeFromContext);
    }, [modeFromContext]);

    const navigateLink = useNavigateLink({
        rowData: row.original,
        columnType,
        navigateTo,
        columnId: column.id,
        viewName
    });

    const { t, i18n } = useTranslation();

    const columnData =
        column.accessorFn(row.original) !== null ? column.accessorFn(row.original) : null;
    const rowData = row.original as TableRowWithChangeFunction;

    const fieldId = cell.id;

    const columnId = column.id;

    const fieldRequired = column.columnDef.fieldRequired;

    return BuildCell({
        columnData,
        columnId,
        columnType,
        mode,
        navigateLink,
        rowData,
        i18n,
        t,
        view_help_filter,
        fieldId,
        table: table as MRT_TableInstance<any>,
        row: row as MRT_Row<any>,
        fieldRequired,
        tableMeta: {
            tableName: table?.options?.meta?.tableName,
            viewName: table?.options?.meta?.viewName
        },
        viewName: viewName as ViewName,
        aggregationFn,
        footerAggregationFn
    });
};

// TODO: обобщить для Dictionaries (у них code) и для Settings Table (у них только id).
// а может быть пока оставить id в URL - тогда для всех это подходит
export const KeyLinkCell = ({
    row,
    column,
    navigateTo
}: {
    row: Record<string, any>;
    column: Record<string, any>;
    navigateTo?: string;
}) => {
    const [navigateLink, setNavigateLink] = useState<string | null>(null);

    // Куда навигироваться передаем в пропс to={${navigateLink}}. navigateLink как раз определяем до return
    // Содержимое компонента Link - это текст ссылки
    // Т.е. ожидаем что отображается key, а переходим по id
    // пример:
    // отображаем WRH-1
    // переходим /master-data/warehouses/1

    useEffect(() => {
        // Получаем префикс
        const prefix = getPrefixByColumnID(column.id);

        // По префикс + id - поле где хранится ID
        const idField = `${prefix}id`;

        const idValue = row.original[idField];

        // Если задана настройка куда навигироваться - в link подставляем настройку, иначе навигируемся на текущую страницу
        if (navigateTo) {
            setNavigateLink(`${navigateTo}${idValue}`);
        }
    }, [navigateTo, column, row.original]);

    return column.accessorFn(row.original) ? (
        <StoreLink replace to={`${navigateLink}`}>
            {column.accessorFn(row.original)}
        </StoreLink>
    ) : (
        <Typography.Text type="secondary" className="no_value_text">
            {i18n.t('no_value')}
        </Typography.Text>
    );
};
export const KeyLinkArrayCell = ({
    row,
    column,
    navigateTo
}: {
    row: Record<string, any>;
    column: Record<string, any>;
    navigateTo?: string;
}) => {
    // Куда навигироваться передаем в пропс to={${navigateLink}}. navigateLink как раз определяем до return
    // Содержимое компонента Link - это текст ссылки
    // Т.е. ожидаем что отображается key, а переходим по id
    // пример:
    // отображаем WRH-1
    // переходим /master-data/warehouses/1

    // Получаем префикс
    const prefix = getPrefixByColumnID(column.id);

    // По префикс + ids - массив где хранятся ID
    // По префикс + keys - массив где хранится key
    const idField = `${prefix}ids`;
    const keyField = `${prefix}keys`;

    const idValue = row.original[idField];
    const keyValue = row.original[keyField];
    // Используя два массива получаем div с элементами Link, если это не массивы, то тогда создается только 1 link и все норм
    const arrayLink = (
        <>
            {idValue ? (
                idValue.map((idValue: string, index: number) =>
                    keyValue[index] ? (
                        <StoreLink
                            to={`${navigateTo}${idValue}`}
                            className="link-cell"
                            style={{ marginRight: '6px' }}
                            key={idValue}
                        >
                            {`${keyValue[index]}`}
                        </StoreLink>
                    ) : (
                        <Typography.Text type="secondary" className="no_value_text">
                            {i18n.t('no_value')}
                        </Typography.Text>
                    )
                )
            ) : (
                <Typography.Text type="secondary" className="no_value_text">
                    {i18n.t('no_value')}
                </Typography.Text>
            )}
        </>
    );

    return arrayLink;
};

export const IdLinkCell = ({
    row,
    column,
    navigateTo
}: {
    row: Record<string, any>;
    column: Record<string, any>;
    navigateTo?: string;
}) => {
    const [navigateLink, setNavigateLink] = useState<string | null>(null);

    useEffect(() => {
        if (navigateTo && column.accessorFn(row.original)) {
            setNavigateLink(`${navigateTo}${column.accessorFn(row.original)}`);
        } else {
            setNavigateLink(column.accessorFn(row.original));
        }
    }, [navigateTo, column, row.original]);

    return column.accessorFn(row.original) ? (
        <StoreLink replace to={`${navigateLink}`}>
            {column.accessorFn(row.original)}
        </StoreLink>
    ) : (
        <Typography.Text type="secondary" className="no_value_text">
            {i18n.t('no_value')}
        </Typography.Text>
    );
};

export const CodeLinkCell = ({
    row,
    column,
    navigateTo
}: {
    row: Record<string, any>;
    column: Record<string, any>;
    navigateTo?: string;
}) => {
    const [navigateLink, setNavigateLink] = useState<string | null>(null);

    useEffect(() => {
        if (navigateTo && column.accessorFn(row.original)) {
            setNavigateLink(`${navigateTo}${column.accessorFn(row.original)}`);
        } else {
            setNavigateLink(column.accessorFn(row.original));
        }
    }, [navigateTo, column, row.original]);

    return column.accessorFn(row.original) ? (
        <StoreLink replace to={`${navigateLink}`}>
            {column.accessorFn(row.original)}
        </StoreLink>
    ) : (
        <Typography.Text type="secondary" className="no_value_text">
            {i18n.t('no_value')}
        </Typography.Text>
    );
};

const objectToString = (object: any) => {
    let text = '';
    Object.keys(object).forEach((key: string) => {
        text += `${key}: ${object[key]} `;
    });
    return text;
};

export const HistoryChangeCell = ({
    row,
    column
}: {
    row: Record<string, any>;
    column: Record<string, any>;
}) => {
    const value =
        column.accessorFn(row.original) && typeof column.accessorFn(row.original) === 'object'
            ? objectToString(column.accessorFn(row.original))
            : column.accessorFn(row.original);
    return <div>{value}</div>;
};

// Отображение колонок для дашбордов

export const OrderListCell = (row: Record<string, any>) => {
    const { original } = row;
    const { vehicle_units } = original;

    const orders = extractObjectsByKey(vehicle_units, 'transportation_orders');
    const filteredOrders = _.uniqBy(orders, 'id');
    return (
        <>
            {filteredOrders.map((order: { [key: string]: any }) =>
                order.key ? (
                    <StoreLink
                        to={`/orders-management/transportation-orders/${order.id}`}
                        className="link-cell"
                        key={order.id}
                    >
                        {`${order.key} `}
                    </StoreLink>
                ) : (
                    <Typography.Text type="secondary" className="no_value_text">
                        {i18n.t('no_value')}
                    </Typography.Text>
                )
            )}
        </>
    );
};

export const TitleLinkCell = ({
    row,
    column,
    navigateTo,
    refLink
}: {
    row: Record<string, any>;
    column: Record<string, any>;
    navigateTo?: string;
    refLink?: React.Ref<any>;
}) => {
    const titleField = `${getPrefix(column.id, 'id')}short_title`;
    const titleValue = row.original[titleField];
    const idValue = column.accessorFn(row.original);
    let navigateLink = '';

    if (navigateTo && idValue) {
        navigateLink = `${navigateTo}${idValue}`;
    } else {
        navigateLink = `${idValue}`;
    }

    return titleValue && titleValue[i18n.language] ? (
        <StoreLink replace to={navigateLink} className="link-cell" ref={refLink}>
            {titleValue[i18n.language]}
        </StoreLink>
    ) : (
        <Typography.Text ref={refLink} type="secondary" className="no_value_text">
            {i18n.t('no_value')}
        </Typography.Text>
    );
};

export const TripCell = ({
    row,
    column,
    regNumberKey
}: {
    row: Record<string, any>;
    column: Record<string, any>;
    regNumberKey: string;
}) => {
    const keyCell = KeyLinkCell({
        row,
        column,
        navigateTo: '/transactional-data/transportation-trips/'
    });
    return (
        <div>
            {keyCell}
            <div className="reg-number">{row.original[regNumberKey]}</div>
        </div>
    );
};
