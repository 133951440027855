import { Typography } from 'antd';
import { i18n } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableName, ViewName, ViewOrTableName } from 'modules/supabase/types/Dataset';
import { RowProps } from 'types';

import { ColumnDef } from 'components/DataTable/useTableBackendFeatures';
import { useColumnOrdering } from 'utils/hooks/useColumnOrdering';
import { useColumnsVisibility } from 'utils/hooks/useColumnsVisibility';
import { MRTTableInstance } from 'components/DataTable/MRT_Types';
import { MRT_AggregationFn, MRT_Cell, MRT_Column, MRT_Header, MRT_Row } from 'material-react-table';
import { supabaseClient } from '../../contexts/SupabaseContext/SupabaseContext';
import { Filter } from '../supabaseClient';
import { Mapping, TableMapping, UIColumnType, mapSupabaseAndUiType } from './FieldMapping';
import {
    CellBuilder,
    CodeLinkCell,
    HistoryChangeCell,
    IdLinkCell,
    KeyLinkCell,
    MultilanguageCell
} from './UICells';
import { ViewDisplayPreferenceSettings } from '../hooks/useViewDisplayPreferences';

export type SupabaseColumns = {
    not_null: boolean;
    column_name: string;
    data_type: UIColumnType;
    referenced_table?: TableName;
    referenced_column?: string;
    navigate_path?: string;
    view_help?: ViewName;
    view_help_filter?: Filter[];
};

type GetSupabaseColumnsDataProps = {
    viewOrTableName: ViewOrTableName;
};

const isFieldNotValidForGrouping = (columnName: string, data_type: UIColumnType): boolean => {
    if (
        data_type === 'multilang_text' ||
        data_type === 'timerange' ||
        data_type === 'daterange' ||
        data_type === 'daterange_with_datetime_return' ||
        data_type === 'datetimelocalrange' ||
        data_type === 'product_sku_stamps' ||
        data_type === 'datetimerange'
    ) {
        return true;
    }

    return false;
};

const getSupabaseColumnsData = async ({
    viewOrTableName
}: GetSupabaseColumnsDataProps): Promise<SupabaseColumns[] | null> => {
    if (viewOrTableName) {
        const { error, data } = await supabaseClient
            .rpc('table_columns', {
                table_name: viewOrTableName
            })
            .select('*');

        if (error) {
            console.error(error);
            return null;
        }

        const columns: SupabaseColumns[] = [];

        if (data) {
            console.log('table_columns:', data);

            // не получилось получать данные о связях для View поэтому будем их тут самостоятельно добавлять
            // бежим в цикле по всем полям, далее будем смотреть на тип данных и на суффиксы
            for (let i = 0; i < data.length; i += 1) {
                const { column_name, data_type, not_null } = data[i];

                const column: SupabaseColumns = {
                    column_name,
                    not_null,
                    data_type: mapSupabaseAndUiType(column_name, data_type) // мэппим тип supabase и ui
                };

                const tableName =
                    viewOrTableName[0] === 'v' ? viewOrTableName.slice(1) : viewOrTableName;

                // попробуем найти поле в Mapping
                const columnRef = Mapping[column_name];
                const columnRefByTableName = TableMapping[tableName as TableName];
                if (columnRef) {
                    // если нашли, то добавим в поле ссылку на таблицу
                    column.referenced_table = columnRef.referenced_table;
                    column.referenced_column = columnRef.referenced_column;

                    if (columnRef.navigate_path) column.navigate_path = columnRef.navigate_path;
                    else if (columnRefByTableName)
                        column.navigate_path = columnRefByTableName.navigate_path;

                    column.view_help = columnRef.view_help;
                    column.view_help_filter = columnRef.view_help_filter;
                } else if (columnRefByTableName)
                    column.navigate_path = columnRefByTableName.navigate_path;

                columns.push(column);
            }
        }

        return columns;
    }
    return [];
};

// Функция генерит колонки по примеру данных exampleRow(локально)
export const useGenerateColumnsFromType = (
    exampleRow: Record<string, any>,
    i18nInstance: i18n,
    viewOrTableName: string,
    columnSorting?: (fieldA: string, fieldB: string) => number,
    renderLinks = true
) => {
    const cols = useMemo(() => {
        let hasSubRows = false;

        const keys = exampleRow && Object.keys(exampleRow);

        const columns: ColumnDef[] =
            keys &&
            keys.map((field) => {
                if (field === 'subRows') hasSubRows = true;
                const col: ColumnDef = {
                    accessorKey: field,
                    header: i18nInstance.t(field) as string,
                    minSize: 150
                };

                const isMultilangText =
                    field && (field.includes('short_title') || field.includes('long_title'));

                if (isMultilangText) {
                    col.Cell = ({ row }: RowProps) =>
                        MultilanguageCell({
                            columnData: row.original[field],
                            rowData: row.original,
                            columnId: field,
                            mode: 'view'
                        });
                }

                const isValueObject = field && (field === 'new_value' || field === 'old_value');
                if (isValueObject) {
                    col.Cell = HistoryChangeCell;
                }
                if (renderLinks) {
                    if (field === 'key' || field.includes('key')) {
                        col.Cell = ({
                            row,
                            column
                        }: {
                            row: Record<string, any>;
                            column: Record<string, any>;
                        }) => {
                            const TableName = row.original.table_name as TableName;
                            const tableMapping = TableMapping[TableName];
                            return KeyLinkCell({
                                row,
                                column,
                                navigateTo: tableMapping?.navigate_path
                            });
                        };
                    }
                    if (field === 'id' || field.includes('_id')) {
                        col.Cell = ({
                            row,
                            column
                        }: {
                            row: Record<string, any>;
                            column: Record<string, any>;
                        }) => {
                            const TableName = row.original.table_name as TableName;
                            const tableMapping = TableMapping[TableName];
                            return IdLinkCell({
                                row,
                                column,
                                navigateTo: tableMapping?.navigate_path
                            });
                        };
                    }
                    if (field === 'code' || field.includes('_code')) {
                        col.Cell = CodeLinkCell;
                    }
                }

                // TODO: логика показа дат и даты-времени.
                // ВОПРОС: из БД не приходит ли случайно сразу тип данных дата-время???

                // TODO: могут быть ссылки на другие объект - нужна настроечная таблица:
                // имя поля с id => компонент detailpage

                // TODO: уметь настраивать ссылку на title

                return col;
            });

        let res = columns;

        if (hasSubRows) {
            res = columns.filter(
                ({ accessorKey }) => accessorKey !== 'subRows' && accessorKey !== 'level'
            );
        }

        return res;
    }, [exampleRow, i18nInstance, renderLinks]);

    const { visibilityMap, onVisibilityChange } = useColumnsVisibility({
        columns: cols,
        lsKey: viewOrTableName,
        features: {
            expanding: true,
            rowSelection: true
        }
    });

    const { orderState, onOrderChange } = useColumnOrdering({
        columns: cols,
        lsKey: viewOrTableName,
        features: {
            expanding: true,
            rowSelection: true
        },
        columnSorting
    });

    return {
        columns: cols, // возвращают все-все колонки
        columnVisibility: visibilityMap, // возвращает то что надо показать
        setColumnVisibility: onVisibilityChange, // функция изменения видимости столбцов
        columnOrdering: orderState, // порядок столбцов
        setColumnOrdering: onOrderChange // изменения порядка};
    };
};

interface RenderCellOrEditType {
    data_type: UIColumnType;
    navigate_path?: string;
    viewName: ViewName;
    view_help_filter?: Filter[];
    mode: 'edit' | 'view';
}

interface RenderFooterOrAggreagator extends RenderCellOrEditType {
    aggregationFn?: MRT_AggregationFn<any>;
    footerAggregationFn?: MRT_AggregationFn<any>;
}

const RenderCellOrEdit = ({
    data_type,
    navigate_path,
    viewName,
    view_help_filter,
    mode
}: RenderCellOrEditType) => {
    return mode === 'edit'
        ? (props: {
              column: Record<string, any>;
              row: Record<string, any>;
              cell: Record<string, any>;
              table: Record<string, any>;
          }) => {
              const { column, row, table, cell } = props;

              return (
                  <>
                      {
                          CellBuilder({
                              column,
                              row,
                              cell,
                              table,
                              columnType: data_type,
                              navigateTo: navigate_path,
                              view_help_filter,
                              viewName
                          }).Edit
                      }
                  </>
              );
          }
        : (props: {
              column: Record<string, any>;
              cell: Record<string, any>;
              row: Record<string, any>;
              table: Record<string, any>;
              renderedCellValue?: any;
          }) => {
              const { column, cell, row, table, renderedCellValue } = props;

              return (
                  <>
                      {
                          CellBuilder({
                              column,
                              row,
                              cell,
                              table,
                              columnType: data_type,
                              navigateTo: navigate_path,
                              view_help_filter,
                              viewName
                          }).Cell
                      }
                  </>
              );
          };
};

const RenderFooterOrAggreagator = ({
    data_type,
    navigate_path,
    viewName,
    view_help_filter,
    footerAggregationFn,
    aggregationFn
}: RenderFooterOrAggreagator) => {
    return footerAggregationFn
        ? (props: {
              column: MRT_Column<any>;
              footer: MRT_Header<any>;
              table: MRTTableInstance;
          }) => {
              const { column, table } = props;

              return (
                  <>
                      {
                          CellBuilder({
                              column,
                              row: {},
                              cell: {},
                              table,
                              columnType: data_type,
                              navigateTo: navigate_path,
                              view_help_filter,
                              viewName,
                              footerAggregationFn
                          })?.Footer
                      }
                  </>
              );
          }
        : aggregationFn
          ? (props: {
                cell: MRT_Cell<any, unknown>;
                column: MRT_Column<any, unknown>;
                row: MRT_Row<any>;
                table: MRTTableInstance;
            }) => {
                const { column, table } = props;

                return (
                    <>
                        {
                            CellBuilder({
                                column,
                                row: {},
                                cell: {},
                                table,
                                columnType: data_type,
                                navigateTo: navigate_path,
                                view_help_filter,
                                viewName,
                                aggregationFn
                            })?.AggregatedCell
                        }
                    </>
                );
            }
          : null;
};

export const useGenerateUIColumnsBySupabaseViewOrTableName = ({
    viewOrTableName,
    predefinedColumns,
    viewDisplayPreferenceSettings,
    ableToFetch = true
}: {
    viewOrTableName: ViewOrTableName;
    viewDisplayPreferenceSettings?: ViewDisplayPreferenceSettings | null;
    predefinedColumns?: ColumnDef[];

    ableToFetch?: boolean;
}): { columns: ColumnDef[]; supabaseColumns: SupabaseColumns[] } => {
    const [columnsGenerated, setColumnsGenerated] = useState<boolean>(false);
    const [supabaseColumns, setSupabaseColumns] = useState<SupabaseColumns[]>([]);

    const { i18n } = useTranslation();

    // useEffect(() => { // TODO: зачем тут это?? clumnGenerated и так будет false при инициализации, а при иных условиях этот эффект не сработает
    //     if (viewOrTableName) {
    //         setColumnsGenerated(false);
    //     }
    // }, [viewOrTableName]);

    if (ableToFetch && !columnsGenerated) {
        const fetchData = async () => {
            setColumnsGenerated(true);
            const supabaseCols = await getSupabaseColumnsData({ viewOrTableName });

            console.log('columns:', supabaseCols);

            setSupabaseColumns(supabaseCols as SupabaseColumns[]);
        };
        fetchData();
    }

    const vdfColumns = useMemo(
        () =>
            viewDisplayPreferenceSettings?.columns ? viewDisplayPreferenceSettings?.columns : [],
        [viewDisplayPreferenceSettings]
    );

    const columns = useMemo(() => {
        if (!ableToFetch) {
            return [];
        }

        if (supabaseColumns && supabaseColumns.length > 0) {
            // Метка что модель этран , если этран - другая логика вывода полей
            const isEtranModel = viewOrTableName?.includes('etran');

            // Убираем тех.поля
            const filteredColumns = [...supabaseColumns].filter(
                (column) => !column.column_name.includes('_fts')
            );

            let MRT_columns: ColumnDef[] = filteredColumns.map(
                ({
                    column_name: columnName,
                    data_type, // Тип уже resolved обработкой в getSupabaseColumnsData
                    view_help,
                    navigate_path,
                    view_help_filter
                }: SupabaseColumns): ColumnDef => {
                    const col: ColumnDef = {
                        accessorKey: columnName,
                        header: i18n.t(columnName) as string,
                        enableHiding: true,
                        enableColumnOrdering: true,
                        enableGrouping: true
                    };

                    let navPath = navigate_path;
                    let filter = view_help_filter;

                    if (isFieldNotValidForGrouping(columnName, data_type)) {
                        col.enableGrouping = false;
                    }

                    const predefinedCol = predefinedColumns?.find(
                        (col) => col.accessorKey === columnName
                    );
                    // если есть преднастроенный фильтр из predefinedColumns то берём него
                    if (predefinedCol && (predefinedCol as Record<string, any>).view_help_filter) {
                        filter = (predefinedCol as Record<string, any>).view_help_filter;
                    }

                    // если в predefinedColumns есть навигация, берем навигацию оттуда
                    if (predefinedCol && (predefinedCol as Record<string, any>).navigate_path) {
                        navPath = (predefinedCol as Record<string, any>).navigate_path;
                    }

                    // если в predefinedColumns есть навигация, берем навигацию оттуда
                    if (predefinedCol && (predefinedCol as Record<string, any>).navigate_path) {
                        navPath = (predefinedCol as Record<string, any>).navigate_path;
                    }

                    // if (isEtranModel && data_type === 'code') {
                    //     col.Cell = () => (
                    //         <Typography.Text type="secondary" className="no_value_text">
                    //             {i18n.t('no_value')}
                    //         </Typography.Text>
                    //     );
                    // }

                    if (isEtranModel && data_type === 'code') {
                        col.Cell = ({
                            column,
                            row
                        }: {
                            column: Record<string, any>;
                            row: Record<string, any>;
                        }) => (
                            <>
                                {column.accessorFn(row.original) !== null ? (
                                    column.accessorFn(row.original)
                                ) : (
                                    <Typography.Text type="secondary" className="no_value_text">
                                        {i18n.t('no_value')}
                                    </Typography.Text>
                                )}
                            </>
                        );
                    }

                    if (!isEtranModel || data_type !== 'code') {
                        col.Cell = RenderCellOrEdit({
                            data_type,
                            mode: 'view',
                            navigate_path: navPath,
                            view_help_filter: filter,
                            viewName: view_help as ViewName
                        });
                        col.Edit = RenderCellOrEdit({
                            data_type,
                            mode: 'edit',
                            navigate_path: navPath,
                            view_help_filter: filter,
                            viewName: view_help as ViewName
                        });
                        // col.Footer = RenderFooterOrAggreagator({
                        //     data_type,
                        //     mode: 'edit',
                        //     navigate_path: navPath,
                        //     view_help_filter: filter,
                        //     viewName: view_help as ViewName,
                        //     footerAggregationFn: aggregationFn
                        // });
                        // col.AggregatedCell = RenderFooterOrAggreagator({
                        //     data_type,
                        //     mode: 'edit',
                        //     navigate_path: navPath,
                        //     view_help_filter: filter,
                        //     viewName: view_help as ViewName,
                        //     aggregationFn
                        // });
                    }

                    return col;
                }
            );

            if (MRT_columns.length > 0) {
                // Учитываем пред.настроенные колонки
                if (predefinedColumns && predefinedColumns.length > 0) {
                    MRT_columns = MRT_columns.map((column) => {
                        const predefinedColumn = predefinedColumns.find(
                            (col) => col.accessorKey === column.accessorKey
                        );

                        if (predefinedColumn) {
                            predefinedColumn.Cell = predefinedColumn.Cell || column.Cell;
                            predefinedColumn.Edit = predefinedColumn.Edit || column.Edit;
                            // predefinedColumn.Footer = predefinedColumn.Footer || column.Footer;
                        }

                        return predefinedColumn || column;
                    });

                    // Сортируем колонки по порядку как они заданы в predefinedColumns
                    MRT_columns.sort((field_a, field_b) => {
                        const fieldAIndex = predefinedColumns.findIndex(
                            (field) => field.accessorKey === field_a.accessorKey
                        );
                        const fieldBIndex = predefinedColumns.findIndex(
                            (field) => field.accessorKey === field_b.accessorKey
                        );

                        if (fieldAIndex >= 0 && fieldBIndex >= 0) {
                            return fieldAIndex > fieldBIndex ? 1 : -1;
                        }

                        if (fieldAIndex >= 0) {
                            return -1;
                        }

                        return 1;
                    });
                }

                // Сортируем колонки по порядку как они заданы в viewDisplayPreferenceSettings
                if (vdfColumns && vdfColumns.length > 0) {
                    MRT_columns.sort((field_a, field_b) => {
                        const fieldAIndex = vdfColumns.findIndex(
                            (field) => field === field_a.accessorKey
                        );
                        const fieldBIndex = vdfColumns.findIndex(
                            (field) => field === field_b.accessorKey
                        );

                        if (fieldAIndex >= 0 && fieldBIndex >= 0) {
                            return fieldAIndex > fieldBIndex ? 1 : -1;
                        }

                        if (fieldAIndex >= 0) {
                            return -1;
                        }

                        return 1;
                    });
                }
            }
            return MRT_columns;
        }

        return [];
    }, [supabaseColumns, predefinedColumns, vdfColumns, viewOrTableName, ableToFetch]);

    return { columns, supabaseColumns };
};

export type onChangeTableDataType = (
    rowIndex: number,
    changedData: Record<string, any> | null
) => void;
