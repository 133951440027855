import { MRT_FilterOption } from 'material-react-table';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import {
    convertUIFilterColumnName,
    convertUIFilterValue,
    mapMUITableFilterOperationAndSupabaseFilterOperation
} from 'modules/supabase/utils/supabaseFilterUtils';
import { UIColumnType } from 'modules/supabase/utils/tableCellUtils/FieldMapping';
import {
    AVAILABLE_FILTER_OPERATORS,
    DEFAULT_FILTER_OPERTAION,
    FilterField,
    FilterOption
} from './TableFilterMenuTypes';

export const isArrayIdType = (type: UIColumnType): boolean => {
    if (type === 'array_id') {
        return true;
    }
    return false;
};

const isArrayType = (type: UIColumnType): boolean => {
    if (type === 'array_id') {
        return true;
    }
    return false;
};

export const isRangeType = (type: UIColumnType): boolean => {
    if (
        type.includes('daterange') ||
        type.includes('datetimerange') ||
        type.includes('datetimelocalrange') ||
        type.includes('timerange')
    ) {
        return true;
    }
    return false;
};

export const isFieldFilterable = (fieldName: string): boolean => {
    if (
        (!fieldName.includes('_short_title') && // будем фильтровать по SelectField по *prefix*_key/code
            !fieldName.includes('_long_title') && // будем фильтровать по SelectField по *prefix*_key/code
            !fieldName.includes('_id') && // будем фильтровать по SelectField по *prefix*_key/code
            fieldName !== 'ver' && // Исключаем тех поля для блокировки
            !fieldName.includes('locked') && // Исключаем тех поля для блокировки
            fieldName !== 'id' && // Нет смысла в фильтрах по объекту искать сам объект
            // fieldName !== 'key' && // Нет смысла в фильтрах по объекту искать сам объект
            !fieldName.includes('lifecycle_status_group_')) || // Убираем группу ?
        fieldName.includes('_ids') // могут быть поля с массивом ID вот по ним фильтруем
    ) {
        return true;
    }
    return false;
};

export const getDefaultFilterOperator = (filterField: FilterField): MRT_FilterOption => {
    if (
        filterField.fieldSetting.data_type.includes('code') ||
        filterField.fieldSetting.data_type.includes('key') ||
        filterField.fieldSetting.data_type.includes('id') ||
        filterField.fieldSetting.data_type.includes('type') ||
        filterField.fieldSetting.data_type.includes('status') ||
        filterField.fieldSetting.data_type.includes('provision_indicator')
    ) {
        return 'equals';
    }

    if (filterField.fieldSetting.data_type.includes('array_id')) {
        return 'contains';
    }

    if (
        filterField.fieldSetting.data_type.includes('date') ||
        filterField.fieldSetting.data_type.includes('datetime')
    ) {
        return 'between';
    }

    if (
        filterField.fieldSetting.data_type.includes('text') ||
        filterField.fieldSetting.data_type.includes('multilang_text')
    ) {
        return 'contains';
    }

    if (isRangeType(filterField.fieldSetting.data_type)) {
        return 'contains';
    }

    if (isArrayType(filterField.fieldSetting.data_type)) {
        return 'contains';
    }

    return DEFAULT_FILTER_OPERTAION;
};

export const getAvailableFilterOperators = (filterField: FilterField): FilterOption[] => {
    if (
        filterField.fieldSetting.data_type === 'text' ||
        filterField.fieldSetting.data_type === 'multilang_text' ||
        filterField.field === 'key'
    ) {
        return [
            'fuzzy',
            'contains',
            'equals',
            'notEquals',
            'startsWith',
            'endsWith',
            'empty',
            'notEmpty'
        ];
    }

    if (
        filterField.fieldSetting.data_type === 'code' ||
        filterField.fieldSetting.data_type === 'key' ||
        filterField.fieldSetting.data_type === 'id' ||
        filterField.fieldSetting.data_type === 'type' ||
        filterField.fieldSetting.data_type === 'status' ||
        filterField.fieldSetting.data_type === 'provision_indicator' ||
        filterField.fieldSetting.data_type === 'flow'
    ) {
        return ['equals', 'notEquals', 'empty', 'notEmpty', 'in_list', 'not_in_list'];
    }

    if (filterField.fieldSetting.data_type === 'date') {
        return [
            'equals',
            'notEquals',
            'between',
            'lessThanOrEqualTo',
            'lessThan',
            'greaterThan',
            'greaterThanOrEqualTo',
            'empty',
            'notEmpty',
            'dynamic_range'
        ];
    }

    if (
        filterField.fieldSetting.data_type === 'datetime' ||
        filterField.fieldSetting.data_type === 'datetimelocal'
    ) {
        return [
            'between',
            'lessThanOrEqualTo',
            'lessThan',
            'greaterThan',
            'greaterThanOrEqualTo',
            'empty',
            'notEmpty',
            'dynamic_range'
        ];
    }

    if (
        filterField.fieldSetting.data_type === 'daterange' ||
        filterField.fieldSetting.data_type === 'daterange_with_datetime_return' ||
        filterField.fieldSetting.data_type === 'datetimerange' ||
        filterField.fieldSetting.data_type === 'datetimelocalrange' ||
        filterField.fieldSetting.data_type === 'timerange'
    ) {
        return [
            'contains',
            'lessThanOrEqualTo',
            'lessThan',
            'greaterThan',
            'greaterThanOrEqualTo',
            'empty',
            'notEmpty'
        ];
    }

    if (isArrayType(filterField.fieldSetting.data_type)) {
        return ['contains', 'empty', 'notEmpty'];
    }

    return AVAILABLE_FILTER_OPERATORS;
};

export const convertFilterFieldsToSupabaseFitler = (
    filterFields: FilterField[],
    language: string
): Filter[] => {
    const filter: Filter[] = [];

    for (let i = 0; i < filterFields.length; i++) {
        const field = filterFields[i];

        for (let j = 0; j < field.values.length; j++) {
            const filterValue = field.values[j];

            if (filterValue.operator) {
                const operator = mapMUITableFilterOperationAndSupabaseFilterOperation(
                    filterValue.operator
                );

                filter.push({
                    column: convertUIFilterColumnName(
                        field.field,
                        field.fieldSetting.data_type,
                        language
                    ),
                    operator,
                    value: convertUIFilterValue(filterValue, field.field, language, operator)
                });
            }
        }
    }

    return filter;
};
