import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { MRT_ColumnFiltersState, MRT_FilterOption, MRT_SortingState } from 'material-react-table';
import { useTranslation } from 'react-i18next';

import { mapOurI18LanguagesAndSupabaseFullTextSearchConfig } from 'modules/supabase/utils/supabaseFilterUtils';
import { FilterField } from 'components/DataTable/TableFilterMenu/TableFilterMenuTypes';
import { TableOptionType } from '../hooks/useTableOption';

export const getHandlers = (setTableOption: Dispatch<SetStateAction<TableOptionType>>) => {
    const { i18n } = useTranslation();

    const handleGlobalFilterChange = useCallback(
        (newGlobalFilter: string | undefined) => {
            const config = mapOurI18LanguagesAndSupabaseFullTextSearchConfig(i18n.language);

            setTableOption((option) => {
                return {
                    ...option,
                    globalFilter: {
                        column: `_fts_${i18n.language}`,
                        value: newGlobalFilter,
                        config
                    }
                };
            });
        },
        [i18n.language, setTableOption]
    );
    const handleColumnFiltersChange = useCallback(
        (newColumnFilterState: (filter: MRT_ColumnFiltersState) => MRT_ColumnFiltersState) => {
            setTableOption((option) => {
                return {
                    ...option,
                    columnFilters: newColumnFilterState(option.columnFilters)
                };
            });
        },
        [setTableOption]
    );

    const handleGroupingChange = useCallback(
        (newGroupingState: (group: string[]) => string[]) => {
            setTableOption((option) => {
                return {
                    ...option,
                    grouping: newGroupingState(option.grouping ? option.grouping : [])
                };
            });
        },
        [setTableOption]
    );
    const handleComplexFilterChange = useCallback(
        (newComplexFilter: FilterField[]) => {
            setTableOption((option) => {
                return {
                    ...option,
                    complexFilter: newComplexFilter
                };
            });
        },
        [setTableOption]
    );
    const handleColumnFilterFnsChange = useCallback(
        (
            newColumnFilterFnsState: (columnFns: { [key: string]: MRT_FilterOption }) => {
                [key: string]: MRT_FilterOption;
            }
        ) => {
            setTableOption((option) => {
                return {
                    ...option,
                    columnFilterFns: newColumnFilterFnsState(option.columnFilterFns)
                };
            });
        },
        [setTableOption]
    );
    const handleSortingChange = useCallback(
        (newSortingState: (sorting: MRT_SortingState) => MRT_SortingState) => {
            setTableOption((option) => {
                return {
                    ...option,
                    sorting: newSortingState(option.sorting)
                };
            });
        },
        [setTableOption]
    );

    const res = useMemo(
        () => ({
            handleGlobalFilterChange,
            handleColumnFiltersChange,
            handleGroupingChange,
            handleComplexFilterChange,
            handleColumnFilterFnsChange,
            handleSortingChange
        }),
        [
            handleColumnFilterFnsChange,
            handleColumnFiltersChange,
            handleComplexFilterChange,
            handleGlobalFilterChange,
            handleGroupingChange,
            handleSortingChange
        ]
    );

    return res;
};
