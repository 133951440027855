import { Flex, Progress, Typography } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPercentFormat, getProgressColor } from './utils';

interface ProgressBarFieldProps {
    value: number;
}

export const ProgressBarField = memo<ProgressBarFieldProps>(({ value }) => {
    const { t } = useTranslation();

    return (
        <Progress
            style={{ width: '100%' }} // Примерное соотношение ширины текста к прогрессбару
            format={getPercentFormat}
            percent={value}
            strokeColor={getProgressColor(value)}
            trailColor="#D9D9D9"
        />
    );
});
