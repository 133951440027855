import { Descriptions, DescriptionsProps, Flex } from 'antd';
import { memo, useMemo } from 'react';

import './FieldsBox.scss';
import { SmartTooltip } from 'smart/ui';

interface FieldsBoxProps {
    items: (NonNullable<DescriptionsProps['items']>[number] & { vertical?: boolean })[];
    size: 'small' | 'middle' | 'default';
    fixedColumnCount?: number;
    labelWidth?: number | string;
    contentWidth?: number | string;
    longestElementWidth?: number;
}

const getLabelStyle = (labelWidth?: number | string) => ({
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    padding: 5,
    width: labelWidth || 'auto',
    display: 'flex',
    backgroundColor: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});

const getContentLabel = (contentWidth?: number | string) => ({
    paddingLeft: 5,
    alignSelf: 'center',
    width: contentWidth || 'calc(100% - 250px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

export const FieldsBox = memo<FieldsBoxProps>(
    ({
        items,
        fixedColumnCount,
        contentWidth,
        // labelWidth = '40%',
        // labelWidth = '240px',
        labelWidth = '17vw',
        size = 'default',
        longestElementWidth
    }) => {
        const tooltipedItems = useMemo(
            () =>
                items.map((item) => ({
                    ...item,
                    label: item.label ? (
                        <SmartTooltip style={{ color: 'rgba(0,0,0,0.45)' }}>
                            {item.label}
                        </SmartTooltip>
                    ) : undefined,
                    children: <SmartTooltip style={{ width: '100%' }}>{item.children}</SmartTooltip>
                })),
            [items]
        );

        const span1 = tooltipedItems?.filter((item) => item.span !== 2 && !item.vertical) || [];
        const span2 = tooltipedItems?.filter((item) => item.span === 2 && !item.vertical) || [];
        const vertical = tooltipedItems?.filter((item) => item.vertical) || [];

        return (
            <Flex vertical gap={5}>
                {!!span1.length && (
                    <Descriptions
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(longestElementWidth || labelWidth)}
                        contentStyle={getContentLabel(contentWidth)}
                        items={span1}
                        column={fixedColumnCount || { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                    />
                )}
                {!!span2.length && (
                    <Descriptions
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelWidth)}
                        contentStyle={getContentLabel(contentWidth)}
                        items={span2}
                        column={fixedColumnCount || { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                    />
                )}
                {!!vertical.length && (
                    <Descriptions
                        layout="vertical"
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelWidth)}
                        contentStyle={getContentLabel(contentWidth)}
                        items={vertical}
                        column={fixedColumnCount || { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                    />
                )}
            </Flex>
        );
    }
);
