import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import { saveDataset } from 'modules/services/backend-functions';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Dataset, TableRow } from 'modules/supabase/types/Dataset';
import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';
import { ButtonWithTooltips } from 'ui';
import { useMessages } from 'utils/hooks';
import { CheckboxField } from '../fields/CheckboxField/CheckboxField';
import { MultilanguageValueType } from '../fields/MultilanguageField/MultilanguageField';
import { PrefixedSelectField } from '../fields/PrefixedSelectField/PrefixedSelectField';

export const SelectProductModal = ({
    open,
    setOpen,
    productId,
    data,
    onChange
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    productId?: number;
    onChange: (newValue: any, fieldId?: string) => void;
    data: TableRow | null;
}) => {
    const { t } = useTranslation();

    const { openMessage, loadingMessage } = useMessages({ message: '' });

    const [generateLevel2Tare, setGenerateLevel2Tare] = useState<boolean>(false);
    const [generateLevel3Tare, setGenerateLevel3Tare] = useState<boolean>(false);

    const product_sku_id = data?.product_sku_id;

    useEffect(() => {
        setGenerateLevel2Tare(false);
        setGenerateLevel3Tare(false);
    }, [open]);

    const [dataset, setDataset] = useDataset(false);

    const confirmWMSProperties = useCallback(() => {
        const generateTares = async () => {
            let changeStruct: Record<string, any> | null = null;

            if (generateLevel2Tare && data?.level_2_tare_group_id) {
                const ds = new Dataset(
                    0,
                    'edit',
                    [
                        {
                            is_root: true,
                            isLoading: false,
                            tableName: 'docs_warehouse_tares',
                            viewName: 'vdocs_warehouse_tares',
                            rows: [],
                            error: null
                        }
                    ],
                    0
                );

                const tareTable = ds.tables[0];

                const result = await supabaseClient
                    .from('vtares_in_group')
                    .select('*')
                    .filter('warehouse_tare_group_id', 'eq', data?.level_2_tare_group_id);

                const level2Tare = result.data ? result.data[0] : null;

                if (level2Tare) {
                    const prefix: string = level2Tare.warehouse_tare_group_prefix
                        ? level2Tare.warehouse_tare_group_prefix.ru
                        : '';

                    const number: number = level2Tare.tares_number || 0;

                    const title: MultilanguageValueType = {
                        ru: `${prefix}-${String(number + 1).padStart(10, '0')}`,
                        en: `${prefix}-${String(number + 1).padStart(10, '0')}`,
                        kk: `${prefix}-${String(number + 1).padStart(10, '0')}`
                    };

                    const newTareId = ds.getNextTempId();

                    const newTare: TableRow = {
                        _state: 'Inserted',
                        id: newTareId,
                        warehouse_tare_group_id: level2Tare.warehouse_tare_group_id,
                        lifecycle_status_code: 'CREATED',
                        type_code: 'DEFAULT',
                        short_title: title,
                        long_title: title
                    };

                    tareTable.rows.push(newTare);

                    const { data, error } = await saveDataset(ds);

                    if (error) {
                        openMessage({
                            key: 'generate_tare',
                            message: error.message,
                            type: 'error'
                        });
                    }

                    if (data) {
                        const tareId = data;

                        const result = await supabaseClient
                            .from('vdocs_warehouse_tares')
                            .select('*')
                            .filter('id', 'eq', tareId);

                        openMessage({
                            key: 'generate_tare',
                            message: `Tare ${
                                result?.data ? result?.data[0]?.key : ''
                            } successfully generated`,
                            type: 'success'
                        });

                        const tare = result?.data ? result?.data[0] : {};

                        if (!changeStruct) {
                            changeStruct = {};
                        }

                        changeStruct.level_2_tare_id = tare.id;
                        changeStruct.level_2_tare_key = tare.key;
                        changeStruct.level_2_tare_short_title = tare.short_title;
                        changeStruct.level_2_tare_long_title = tare.long_title;
                    }
                }
            }

            if (generateLevel3Tare && data?.level_3_tare_group_id) {
                const ds = new Dataset(
                    0,
                    'edit',
                    [
                        {
                            is_root: true,
                            isLoading: false,
                            tableName: 'docs_warehouse_tares',
                            viewName: 'vdocs_warehouse_tares',
                            rows: [],
                            error: null
                        }
                    ],
                    0
                );

                const tareTable = ds.tables[0];

                const result = await supabaseClient
                    .from('vtares_in_group')
                    .select('*')
                    .filter('warehouse_tare_group_id', 'eq', data?.level_3_tare_group_id);

                const level3Tare = result.data ? result.data[0] : null;

                if (level3Tare) {
                    const prefix: string = level3Tare.warehouse_tare_group_prefix
                        ? level3Tare.warehouse_tare_group_prefix.ru
                        : '';

                    const number: number = level3Tare.tares_number || 0;

                    const title: MultilanguageValueType = {
                        ru: `${prefix}-${String(number + 1).padStart(10, '0')}`,
                        en: `${prefix}-${String(number + 1).padStart(10, '0')}`,
                        kk: `${prefix}-${String(number + 1).padStart(10, '0')}`
                    };

                    const newTareId = ds.getNextTempId();

                    const newTare: TableRow = {
                        _state: 'Inserted',
                        id: newTareId,
                        warehouse_tare_group_id: level3Tare.warehouse_tare_group_id,
                        lifecycle_status_code: 'CREATED',
                        type_code: 'DEFAULT',
                        short_title: title,
                        long_title: title
                    };

                    tareTable.rows.push(newTare);

                    const { data, error } = await saveDataset(ds);

                    if (error) {
                        openMessage({
                            key: 'generate_tare',
                            message: error.message,
                            type: 'error'
                        });
                    }

                    if (data) {
                        const tareId = data;

                        const result = await supabaseClient
                            .from('vdocs_warehouse_tares')
                            .select('*')
                            .filter('id', 'eq', tareId);

                        openMessage({
                            key: 'generate_tare',
                            message: `Tare ${
                                result?.data ? result?.data[0]?.key : ''
                            } successfully generated`,
                            type: 'success'
                        });

                        const tare = result?.data ? result?.data[0] : {};

                        if (!changeStruct) {
                            changeStruct = {};
                        }

                        changeStruct.level_3_tare_id = tare.id;
                        changeStruct.level_3_tare_key = tare.key;
                        changeStruct.level_3_tare_short_title = tare.short_title;
                        changeStruct.level_3_tare_long_title = tare.long_title;
                    }
                }
            }

            if (changeStruct) {
                onChange(changeStruct);
            }
        };

        generateTares();
        setOpen(false);
    }, [
        setOpen,
        generateLevel2Tare,
        data?.level_2_tare_group_id,
        data?.level_3_tare_group_id,
        generateLevel3Tare,
        openMessage,
        onChange
    ]);

    return (
        <>
            <Modal
                centered
                title={t('select_product')}
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <ButtonWithTooltips
                        id="apply"
                        tooltipTitle={t('apply')}
                        key="submit"
                        onClick={confirmWMSProperties}
                    >
                        {t('apply')}
                    </ButtonWithTooltips>
                ]}
            >
                <PrefixedSelectField
                    mode="edit"
                    id="product_sku"
                    prefix="product_sku_"
                    viewName="vdocs_product_skus"
                    value={data}
                    onChange={onChange}
                    searchListFilter={[
                        {
                            column: 'parent_product_id',
                            operator: 'eq',
                            value: productId
                        }
                    ]}
                />

                <PrefixedSelectField
                    mode="edit"
                    id="product_stock_category"
                    prefix="product_stock_category_"
                    viewName="vdicts_product_stock_categories"
                    value={data}
                    onChange={onChange}
                />

                {product_sku_id && (
                    <PrefixedSelectField
                        mode="edit"
                        id="product_sku_package"
                        prefix="product_sku_package_"
                        viewName="vdocs_product_sku_packages"
                        value={data}
                        asDictionarySelectFieldWithOneColumn
                        onChange={onChange}
                        searchListFilter={[
                            {
                                column: 'root_id',
                                operator: 'eq',
                                value: product_sku_id
                            }
                        ]}
                    />
                )}

                {product_sku_id && (
                    <PrefixedSelectField
                        mode="edit"
                        id="product_batch"
                        prefix="product_batch_"
                        viewName="vdocs_product_sku_batches"
                        value={data}
                        onChange={onChange}
                        searchListFilter={[
                            {
                                column: 'parent_product_sku_id',
                                operator: 'eq',
                                value: product_sku_id
                            }
                        ]}
                    />
                )}

                <CheckboxField
                    id="generateLevel2Tare"
                    mode="edit"
                    value={generateLevel2Tare}
                    onChange={(newVal) => {
                        setGenerateLevel2Tare(newVal);
                    }}
                />

                {generateLevel2Tare ? (
                    <PrefixedSelectField
                        mode="edit"
                        id="level_2_tare_group"
                        prefix="level_2_tare_group_"
                        viewName="vdocs_warehouse_tare_groups"
                        value={data}
                        onChange={onChange}
                        searchListFilter={[
                            {
                                column: 'level_code',
                                operator: 'eq',
                                value: 'LEVEL_2'
                            }
                        ]}
                    />
                ) : (
                    <PrefixedSelectField
                        mode="edit"
                        id="level_2_tare"
                        prefix="level_2_tare_"
                        viewName="vdocs_warehouse_tares"
                        value={data}
                        onChange={onChange}
                        searchListFilter={[
                            {
                                column: 'warehouse_tare_group_level_code',
                                operator: 'eq',
                                value: 'LEVEL_2'
                            }
                        ]}
                    />
                )}

                <CheckboxField
                    id="generateLevel3Tare"
                    mode="edit"
                    value={generateLevel3Tare}
                    onChange={(newVal) => {
                        setGenerateLevel3Tare(newVal);
                    }}
                />

                {generateLevel3Tare ? (
                    <PrefixedSelectField
                        mode="edit"
                        id="level_3_tare_group"
                        prefix="level_3_tare_group_"
                        viewName="vdocs_warehouse_tare_groups"
                        value={data}
                        onChange={onChange}
                        searchListFilter={[
                            {
                                column: 'level_code',
                                operator: 'eq',
                                value: 'LEVEL_3'
                            }
                        ]}
                    />
                ) : (
                    <PrefixedSelectField
                        mode="edit"
                        id="level_3_tare"
                        prefix="level_3_tare_"
                        viewName="vdocs_warehouse_tares"
                        value={data}
                        onChange={onChange}
                        searchListFilter={[
                            {
                                column: 'warehouse_tare_group_level_code',
                                operator: 'eq',
                                value: 'LEVEL_3'
                            }
                        ]}
                    />
                )}
            </Modal>
        </>
    );
};
