import { User } from '@supabase/supabase-js';
import { ConfigProvider } from 'antd';
import { memo } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { Locale } from 'antd/lib/locale';
import AZLocale from 'antd/lib/locale/az_AZ';
import ENLocale from 'antd/lib/locale/en_US';
import KKLocale from 'antd/lib/locale/kk_KZ';
import RULocale from 'antd/lib/locale/ru_RU';
import TRLocale from 'antd/lib/locale/tr_TR';

import { TablePageInfoProvider } from 'components/DataTable/useTablePageInfo';
import { SettingsModulesProvider } from 'contexts';
import { DictsContextProvider } from 'contexts/DictsContextProvider/DictsContextProvider';
import { MeasureUnitCalculationProvider } from 'contexts/MeasureUnitCalculationContext/MeasureUnitCalculationContext';
import { PermissionsProvider } from 'modules/auth/contexts';
import { ViewDisplayPreferencesProvider } from 'modules/supabase/contexts/ViewDisplayPreferencesProvider/ViewDisplayPreferencesProvider';
import { BreadcrumbsProvider } from 'utils/hooks/useBreadcrumbs';
import { i18n } from 'utils/i18n/i18n';

interface AppProvidersWrapperProps extends React.PropsWithChildren {
    user: User | null;
    i18n: typeof i18n;
}

const locales: Record<string, Locale> = {
    ru: RULocale,
    en: ENLocale,
    kk: KKLocale,
    tr: TRLocale,
    az: AZLocale
};

export const AppProvidersWrapper = memo<AppProvidersWrapperProps>(({ user, i18n, children }) => {
    const { i18n: i18nT } = useTranslation();
    return (
        <ConfigProvider
            locale={locales[i18nT.language || 'en']}
            theme={{
                components: {
                    Button: {
                        paddingInline: 8
                    }
                }
            }}
        >
            <PermissionsProvider user={user}>
                <I18nextProvider i18n={i18n}>
                    <BreadcrumbsProvider>
                        <SettingsModulesProvider>
                            <ViewDisplayPreferencesProvider>
                                <DictsContextProvider>
                                    <TablePageInfoProvider>
                                        <MeasureUnitCalculationProvider children={children} />
                                    </TablePageInfoProvider>
                                </DictsContextProvider>
                            </ViewDisplayPreferencesProvider>
                        </SettingsModulesProvider>
                    </BreadcrumbsProvider>
                </I18nextProvider>
            </PermissionsProvider>
        </ConfigProvider>
    );
});
