import { useEffect, useMemo, useState } from 'react';

import { Dataset } from 'modules/supabase/types/Dataset';
import { TableDef } from 'modules/supabase/utils/supabaseClient';
import { DatasetContext } from 'pages/base/DetailPage/hooks/useDataset';
import { DatasetProviderType, DatasetWrapperProps } from '../types';

export const DatasetWrapper = ({
    viewName,
    tableName,
    tableDefs,
    children,
    mode: inboundMode,
    externalDataset,
    externalSetDataset,
    createInternalDataset = false
}: DatasetWrapperProps) => {
    const mode = inboundMode || (window.location.search.includes('mode=view') ? 'view' : 'edit');
    const initDataset = new Dataset(-1, externalDataset ? mode : 'view', []);
    const [internalDataset, internalSetDataset] = useState<Dataset>(initDataset);

    const dataset = externalDataset || internalDataset;
    const setDataset = externalSetDataset || internalSetDataset;

    useEffect(() => {
        if (!externalDataset || createInternalDataset === true) {
            const tableDefinitions: TableDef[] = tableDefs || [
                {
                    tableName,
                    viewName
                }
            ];

            const newDataset = new Dataset(
                -1,
                inboundMode || (externalDataset ? mode : 'view'),
                []
            );

            for (let index = 0; index < tableDefinitions.length; index += 1) {
                const tableDef = tableDefinitions[index];
                const { tableName, viewName, referenceField } = tableDef;

                newDataset.tables.push({
                    tableName,
                    viewName,
                    is_root: index === 0 || Boolean(referenceField),
                    rows: [],
                    error: null,
                    isLoading: false
                });
            }

            setDataset(newDataset);
        }
    }, [tableDefs, tableName, viewName, externalDataset, inboundMode, createInternalDataset]);

    const providerValue = useMemo<DatasetProviderType>(
        () => [dataset, setDataset, null, () => {}, () => {}],
        [dataset]
    );

    return (
        <DatasetContext.Provider value={providerValue}>
            {dataset.tables.length === 0 ? <>tech error</> : children}
        </DatasetContext.Provider>
    );
};
