import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterOutlined } from '@ant-design/icons';
import { ButtonWithTooltips } from 'ui';
import { SmartTableFilterMenuModal } from './SmartTableFilterMenuModal';
import { FilterField } from './types';

export interface TableFilterMenuToolbarButtonProps {
    filters: FilterField[];
    setFilters: Dispatch<SetStateAction<FilterField[]>>;
    meta: string;
}

export const SmartTableFilterMenuToolbarButton = ({
    filters,
    setFilters,
    meta
}: TableFilterMenuToolbarButtonProps) => {
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

    const { t } = useTranslation();

    const tooltipText = useMemo(() => {
        if (filters.length === 0) {
            return t('filtering');
        }

        let text = `${t('filter_by')}: `;

        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];

            text = `${text}${t(filter.field.ColumnName)}${i === filters.length - 1 ? '' : ', '}`;
        }

        return text;
    }, [t, filters]);

    return (
        <>
            <SmartTableFilterMenuModal
                open={showFilterModal}
                setOpen={setShowFilterModal}
                meta={meta}
                filters={filters}
                setFilters={setFilters}
            />

            <ButtonWithTooltips
                id="filter-menu-toolbar-button"
                type="default"
                tooltipTitle={tooltipText}
                className="filter-menu-toolbar-button"
                onClick={() => {
                    setShowFilterModal(true);
                }}
                icon={<FilterOutlined />}
            >
                {filters.length > 0 && <span>{filters.length}</span>}
            </ButtonWithTooltips>
        </>
    );
};
