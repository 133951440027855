const getSubstringBeforeKey = (inputString: string) => {
    const lastIndex = inputString.lastIndexOf('_key');
    return lastIndex !== -1 ? inputString.substring(0, lastIndex) : null;
};

export const convertUISortingColumnName = (columnName: string, language: string): string => {
    if (columnName.endsWith('short_title') || columnName.endsWith('long_title')) {
        return `${columnName}->>${language}`;
    }

    if (columnName.endsWith('key')) {
        const substringBeforeKey = getSubstringBeforeKey(columnName);

        if (!substringBeforeKey) {
            return `id`;
        }
        return `${substringBeforeKey}_id`;
    }

    return columnName;
};
