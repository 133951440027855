import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { BaseField } from '../BaseField/BaseField';
import './DateTimeField.scss';

interface DateTimeFieldProps {
    id: string;
    value: string | null; // ISO string (from Supabase DB) aka DbDateTime
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    onChange?: (newValue: string | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    withLabel?: boolean;
    required?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    skipBlur?: boolean;
    htmlId?: string;
    timeZoneNumberString?: string; // TODO: переделать нормально с использованием ISO-timezone из словаря
    style?: { [key: string]: any };
    disabled?: boolean;
}
// The component is used to display and edit date and time values using user's preferred format!

export const DateTimeField = memo<DateTimeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        utc = false,
        required = false,
        withLabel = true,
        onChange = () => {},
        skipBlur = false,
        htmlId = id,
        timeZoneNumberString = '0',
        style,
        disabled
    }) => {
        const { t, i18n } = useTranslation();

        // Определение функции для рендеринга компонента выбора даты и времени
        const renderInput = useCallback(
            (
                inputValue: string | null,
                onInputChange?: (newValue: string | null) => void,
                onBlur?: () => void
            ) => {
                const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
                    e.stopPropagation();
                    if (onInputChange) {
                        onInputChange(null);
                    }
                };

                const onChange = (date: dayjs.Dayjs | null) => {
                    if (onInputChange && onBlur && date) {
                        if (utc) {
                            onInputChange(
                                date &&
                                    date
                                        .locale(i18n.language)
                                        .utc()
                                        .format(UserSpecificFormat.getDateTimeFormat())
                            );
                        } else {
                            onInputChange(
                                date &&
                                    date
                                        .locale(i18n.language)
                                        .local()
                                        .format(UserSpecificFormat.getDateTimeLocalFormat())
                            );
                        }

                        onBlur(); // При нажатии на кнопку сохранить, может не поменятся датасет, то есть не снятся focus
                    }
                };

                return (
                    <DatePicker
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        data-testid={`${htmlId}-edit`}
                        value={
                            inputValue
                                ? utc && timeZoneNumberString !== '0'
                                    ? dayjs(inputValue)
                                          .locale(i18n.language)
                                          .utcOffset(Number(timeZoneNumberString) * 60)
                                    : dayjs(inputValue).locale(i18n.language)
                                : null
                        } // TODO: сделать нормально. Пока временный костыль
                        onChange={onChange}
                        format={
                            utc
                                ? UserSpecificFormat.getDateTimeFormat()
                                : UserSpecificFormat.getDateTimeLocalFormat()
                        }
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        inputReadOnly
                        showTime={true}
                        style={style}
                        disabled={disabled}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                );
            },
            [disabled, htmlId, popoverContainerHtmlId, style, t, timeZoneNumberString, utc]
        );
        // Определение функции для рендеринга компонента просмотра даты и времени
        const renderView = useCallback(
            (viewValue: string | null) => {
                return (
                    <DatePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-view`}
                        placeholder={t('no_value') as string}
                        value={
                            viewValue
                                ? utc && timeZoneNumberString !== '0'
                                    ? dayjs(viewValue)
                                          .locale(i18n.language)
                                          .utcOffset(Number(timeZoneNumberString) * 60)
                                    : dayjs(viewValue).locale(i18n.language)
                                : null
                        }
                        format={
                            utc
                                ? UserSpecificFormat.getDateTimeFormat()
                                : UserSpecificFormat.getDateTimeLocalFormat()
                        }
                        variant="borderless"
                        inputReadOnly
                        allowClear={false}
                        open={false}
                        showTime
                        suffixIcon={<></>}
                        style={style || { width: '100%' }}
                    />
                );
            },
            [id, t, utc]
        );

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={true}
                withTooltip={false}
            />
        );
    }
);
