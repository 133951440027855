import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Select, Skeleton, Typography } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DynamicDateRangeField, StringField } from 'components/fields';
import { ViewName } from 'modules/supabase/types/Dataset';
import { BuildCell } from 'modules/supabase/utils/tableCellUtils/UICells';

import { MultiSelectField } from 'components/fields/MultiSelectField/MultiSelectField';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import {
    ObjectLifecycleStatusGroupTitleFilter,
    ObjectTypeViewMapping
} from 'modules/supabase/utils/tableCellUtils/FieldMapping';
import { FilterField, FilterFieldValue, LocalFilterValueType } from './TableFilterMenuTypes';
import {
    getAvailableFilterOperators,
    getDefaultFilterOperator,
    isRangeType
} from './TableFilterUtils';

export const TableFilterItem = ({
    item,
    changeItem,
    table,
    removeAppliedField,
    mode = 'edit'
}: {
    item: FilterField;
    changeItem: (item: FilterField) => void;
    table?: Record<string, any>;
    removeAppliedField: (fieldName: string) => void;
    mode?: 'edit' | 'view';
}) => {
    const { t, i18n } = useTranslation();

    const addFilterValue = useCallback(() => {
        const copy = { ...item };
        copy.values = [
            ...copy.values,
            {
                operator: getDefaultFilterOperator(item)
            }
        ];

        changeItem(copy);
    }, [item, changeItem]);

    const deleteFilterValue = useCallback(
        (index: number) => {
            const copy = { ...item, values: [...item.values] };
            copy.values.splice(index, 1);
            changeItem(copy);
        },
        [item, changeItem]
    );

    const changeFilterValue = useCallback(
        (filterValue: LocalFilterValueType, changedFilterValue: FilterFieldValue) => {
            const copy = { ...item, values: [...item.values] };
            copy.values[filterValue.index] = {
                ...copy.values[filterValue.index],
                ...changedFilterValue
            };

            changeItem(copy);
        },
        [item, changeItem]
    );

    const listData: LocalFilterValueType[] = item.values.map((value, index) => {
        return {
            index,
            lastValue: index === item.values.length - 1,
            operator: value.operator,
            value: value.value
        };
    });

    const viewName: ViewName =
        item.field === 'type_code' && table?.options?.meta?.viewName
            ? (ObjectTypeViewMapping[table.options.meta.viewName as ViewName] as ViewName)
            : (item.fieldSetting.view_help as ViewName);

    const filter: Filter[] = useMemo(() => {
        const ft: Filter[] = item.fieldSetting.view_help_filter || [];

        const root_view_name = table?.options?.meta?.viewName as ViewName;

        if (item.field === 'lifecycle_status_code' && viewName) {
            ft.push({
                column: 'status_group_title',
                operator: 'eq',
                value: ObjectLifecycleStatusGroupTitleFilter[root_view_name]
                    ? ObjectLifecycleStatusGroupTitleFilter[root_view_name]
                    : 'Standard Statuses'
            });
        }

        return ft;
    }, [item]);

    return (
        <>
            <div className="div-item">
                {listData.length > 0 ? (
                    <List
                        header={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography.Text>
                                    {t(item.field)}
                                    <PlusOutlined
                                        onClick={() => {
                                            addFilterValue();
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '16px',
                                            color: 'green',
                                            marginLeft: '8px',
                                            visibility: mode === 'view' ? 'hidden' : undefined
                                        }}
                                    />
                                </Typography.Text>
                                <CloseOutlined
                                    onClick={() => {
                                        removeAppliedField(item.field);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        visibility: mode === 'view' ? 'hidden' : undefined,
                                        color: 'red',
                                        padding: '0 8px'
                                    }}
                                />
                            </div>
                        }
                        dataSource={listData || []}
                        itemLayout="horizontal"
                        renderItem={(valueItem) => (
                            <List.Item
                                className="sub_applied_filter"
                                actions={[
                                    <CloseOutlined
                                        onClick={() => {
                                            deleteFilterValue(valueItem.index);
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            visibility: mode === 'view' ? 'hidden' : undefined
                                        }}
                                    />
                                ]}
                            >
                                <Skeleton active loading={false}>
                                    <List.Item.Meta />

                                    <div
                                        className="item_meta"
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Select
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'table_filter_menu'
                                                ) as HTMLElement
                                            }
                                            className="select_item_meta"
                                            style={{ width: '15rem' }}
                                            value={valueItem.operator}
                                            disabled={mode === 'view'}
                                            onChange={(operator) => {
                                                changeFilterValue(valueItem, {
                                                    operator,
                                                    value: null
                                                });
                                            }}
                                            options={getAvailableFilterOperators(item).map(
                                                (filterFNSKey) => {
                                                    const isRange = isRangeType(
                                                        item.fieldSetting.data_type
                                                    );

                                                    let label = filterFNSKey;

                                                    if (
                                                        isRange &&
                                                        (filterFNSKey === 'lessThan' ||
                                                            filterFNSKey === 'lessThanOrEqualTo')
                                                    ) {
                                                        label = `${label}Start`;
                                                    }

                                                    if (
                                                        isRange &&
                                                        (filterFNSKey === 'greaterThan' ||
                                                            filterFNSKey === 'greaterThanOrEqualTo')
                                                    ) {
                                                        label = `${label}End`;
                                                    }

                                                    return {
                                                        label: t(label),
                                                        value: filterFNSKey
                                                    };
                                                }
                                            )}
                                        />

                                        <div
                                            className="item_meta_value"
                                            style={{ width: '100%', marginLeft: '1rem' }}
                                        >
                                            {(item.field === 'key' || item.field === 'st_code') &&
                                            valueItem.operator !== 'empty' &&
                                            valueItem.operator !== 'notEmpty' &&
                                            valueItem.operator !== 'dynamic_range' ? (
                                                <StringField
                                                    id={item.field}
                                                    value={
                                                        valueItem.value
                                                            ? valueItem.value[item.field]
                                                            : null
                                                    }
                                                    mode="edit"
                                                    withLabel={false}
                                                    onChange={(value) => {
                                                        changeFilterValue(valueItem, {
                                                            value: { [item.field]: value }
                                                        });
                                                    }}
                                                />
                                            ) : valueItem.operator !== 'empty' &&
                                              valueItem.operator !== 'notEmpty' &&
                                              valueItem.operator !== 'in_list' &&
                                              valueItem.operator !== 'not_in_list' &&
                                              valueItem.operator !== 'dynamic_range' ? (
                                                BuildCell({
                                                    columnType: item.fieldSetting.data_type,
                                                    rowData: {
                                                        ...valueItem.value,
                                                        changeRow: (data) => {
                                                            changeFilterValue(valueItem, {
                                                                value: data
                                                            });
                                                        },

                                                        changeRowField: (field_name, data) => {
                                                            changeFilterValue(valueItem, {
                                                                value: data
                                                            });
                                                        },
                                                        _state: 'Unchanged',
                                                        id: 0
                                                    },
                                                    i18n,
                                                    t,
                                                    columnId: item.field,
                                                    mode,
                                                    viewName: item.fieldSetting
                                                        .view_help as ViewName,
                                                    navigateLink: item.fieldSetting.navigate_path,
                                                    view_help_filter:
                                                        item.fieldSetting.view_help_filter,
                                                    columnData: valueItem.value,
                                                    filterOption: {
                                                        buildForFilter: true,
                                                        renderRange:
                                                            valueItem.operator === 'between' ||
                                                            valueItem.operator ===
                                                                'betweenInclusive'
                                                    },
                                                    tableMeta: {
                                                        tableName:
                                                            table && table.options.meta.tableName,
                                                        viewName:
                                                            table && table.options.meta.viewName
                                                    },
                                                    popoverContainerHtmlId: 'table_filter_menu'
                                                }).Edit
                                            ) : valueItem.operator === 'dynamic_range' ? (
                                                <DynamicDateRangeField
                                                    mode="edit"
                                                    withLabel={false}
                                                    value={valueItem.value}
                                                    id={item.field}
                                                    onChange={(dynamicDateRange) => {
                                                        changeFilterValue(valueItem, {
                                                            value: dynamicDateRange
                                                        });
                                                    }}
                                                    popoverContainerHtmlId="table_filter_menu"
                                                />
                                            ) : valueItem.operator === 'in_list' ||
                                              valueItem.operator === 'not_in_list' ? (
                                                <MultiSelectField
                                                    popoverContainerHtmlId="table_filter_menu"
                                                    id={item.field}
                                                    withLabel={false}
                                                    value={valueItem.value}
                                                    viewName={viewName}
                                                    filters={filter}
                                                    onChange={(multipleValue) => {
                                                        changeFilterValue(valueItem, {
                                                            value: multipleValue
                                                        });
                                                    }}
                                                    mode={mode}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </Skeleton>
                            </List.Item>
                        )}
                    ></List>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
};
