import { ViewDisplayPreferenceSettings } from './hooks/useViewDisplayPreferences';

export const getColumnByPreferenceTitleForSelectFieldByViewDisplayPreference = async (
    viewDisplayPreferenceSettings?: ViewDisplayPreferenceSettings
): Promise<string> => {
    if (viewDisplayPreferenceSettings && viewDisplayPreferenceSettings.columns[0]) {
        // const longTitle = viewDisplayPreferenceSettings.columns.find(
        //     (col) => col === 'long_title' || col.includes('long_title')
        // );
        // if (longTitle) return longTitle;

        return viewDisplayPreferenceSettings.columns[0];
    }

    return 'long_title';
};
