import { Warehouse as WmsIcon } from '@mui/icons-material';
import { lazy } from 'react';
import { Routes } from 'routing/route';
import { RouteTypeMassive } from 'routing/routesTypes';

const ConsolidatedMovementReport_Page = lazy(() =>
    import('pages/wms-reports/consolidated-movement-report/ConsolidatedMovementReport_Page').then(
        (module) => ({
            default: module.ConsolidatedMovementReport_Page
        })
    )
);
const MovementReport_Page = lazy(() =>
    import('pages/wms-reports/movement-report/MovementReport_Page').then((module) => ({
        default: module.MovementReport_Page
    }))
);
const TotalBalanceReport_Page = lazy(() =>
    import('pages/wms-reports/total-balance-report/TotalBalanceReport_Page').then((module) => ({
        default: module.TotalBalanceReport_Page
    }))
);
const KPIReport_Page = lazy(() =>
    import('pages/wms-reports/KPI-report/KPIReport_Page').then((module) => ({
        default: module.KPIReport_Page
    }))
);
const WmsReportsOverviewPage = lazy(() =>
    import('pages/wms-reports/WmsReportsOverviewPage').then((module) => ({
        default: module.WmsReportsOverviewPage
    }))
);
const AnalyzePlacementByStorageArea_TablePage = lazy(() =>
    import('pages/wms-reports/analyze/placementByStorageArea/PlacemenByStorageArea_TablePage').then(
        (module) => ({
            default: module.AnalyzePlacementByStorageArea_TablePage
        })
    )
);
const AnalyzeProducts_TablePage = lazy(() =>
    import('pages/wms-reports/analyze/products/Products_TablePage').then((module) => ({
        default: module.AnalyzeProducts_TablePage
    }))
);
const AnalyzeProductStock_TablePage = lazy(() =>
    import('pages/wms-reports/analyze/productStock/ProductStock').then((module) => ({
        default: module.AnalyzeProductStock_TablePage
    }))
);
const AnalyzeSkuTornover_TablePage = lazy(() =>
    import('pages/wms-reports/analyze/skuTurnover/SkuTurnover').then((module) => ({
        default: module.AnalyzeSkuTornover_TablePage
    }))
);

const WmsReportApps: RouteTypeMassive[] = [
    {
        path: '',
        tablePage: WmsReportsOverviewPage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'consolidated-movement-report',
        tablePage: ConsolidatedMovementReport_Page,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'movement-report',
        tablePage: MovementReport_Page,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'total-balance-report',
        tablePage: TotalBalanceReport_Page,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'KPI-report',
        tablePage: KPIReport_Page,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'analyze-placement-by-storage-area',
        tablePage: AnalyzePlacementByStorageArea_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'analyze-products',
        tablePage: AnalyzeProducts_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'analyze-product-stock',
        tablePage: AnalyzeProductStock_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'analyze-sku-turnover',
        tablePage: AnalyzeSkuTornover_TablePage,
        config: {
            icon: <WmsIcon />
        }
    }
];

export const WmsReportsRoutes = new Routes('/warehouse-reports', WmsReportApps);
