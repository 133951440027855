import { makeAutoObservable } from 'mobx';
import { Filter } from 'modules/supabase/utils/supabaseClient';

interface RouteStoreStateType {
    filters?: Filter[];
    filterString?: string;
    pageTitle?: string;
    data?: any;
    cacheKey?: string | number;
    mode: 'edit' | 'view';
}

export interface RouteStoreItemType {
    pathname: string;
    search?: string | null;
    state?: RouteStoreStateType | null;
    [key: string]: any;
}

const initialStore = [{ pathname: '/', search: '', state: null }];

export class RouteStore {
    routes: RouteStoreItemType[] = initialStore;

    constructor() {
        makeAutoObservable(this);
    }

    push = (route: RouteStoreItemType) => {
        if (route.pathname === '/login' || route.pathname === '/signup') return;

        if (
            !this.routes.map(({ pathname }) => pathname).includes(route.pathname) ||
            !this.routes
                .filter(({ pathname }) => route.pathname === pathname)
                .map(({ state }) => JSON.stringify(state))
                .includes(JSON.stringify(route.state))
        ) {
            this.routes.push(route);
        }
    };

    clear = () => {
        if (this.routes) this.routes = initialStore;
    };

    findFirstIndexByPathname = (pathname: string) => {
        return this.routes.findIndex((item) => {
            return item.pathname === pathname;
        });
    };

    findIndex = (route: RouteStoreItemType) => {
        return this.routes.findIndex((item) => {
            return (
                route.pathname === item.pathname &&
                JSON.stringify(item.state) === JSON.stringify(route.state)
            );
        });
    };

    // ###
    getStateByLocation = (loc: Omit<RouteStoreItemType, 'state'>) => {
        return this.routes.find(({ pathname }) => pathname === loc.pathname);
    };

    remove = (route: RouteStoreItemType) => {
        if (this.routes)
            this.routes = this.routes.filter(
                (r) =>
                    r.pathname !== route.pathname ||
                    JSON.stringify(r.state) !== JSON.stringify(route.state)
            );
    };

    replace = (prevRoute: RouteStoreItemType, newRoute: RouteStoreItemType) => {
        const prevIndex = this.findIndex(prevRoute);
        this.routes.splice(prevIndex, 1, newRoute);
    };
}

export const routeStore = new RouteStore();
