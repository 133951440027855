import { memo, useState } from 'react';

import { SelectProductModal } from 'components/Modals/SelectProductModal';

import { TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { PrefixedSelectField } from '../PrefixedSelectField/PrefixedSelectField';

interface ProductFieldsProps {
    id: string;
    prefix: string;
    fields: TableRow | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    searchListFilter?: Filter[];
    viewName: ViewName;
    onChange: (newValue: any, fieldId?: string) => void;
    label?: string;
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
}

export const ProductField = memo(
    ({
        id,
        prefix,
        fields,
        viewName,
        mode,
        popoverContainerHtmlId,
        searchListFilter,
        onChange = () => {},
        withLabel = true,
        required = false,
        skipBlur = false
    }: ProductFieldsProps) => {
        const [showProductModal, setShowProductModal] = useState<boolean>(false);

        const product_id = fields?.product_id;

        return (
            <>
                <SelectProductModal
                    open={showProductModal}
                    setOpen={setShowProductModal}
                    productId={product_id}
                    data={fields}
                    onChange={onChange}
                />
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ width: '80%' }}>
                        <PrefixedSelectField
                            required={required}
                            id={id}
                            prefix={prefix}
                            viewName={viewName}
                            value={fields}
                            mode={mode}
                            withLabel={withLabel}
                            onChange={onChange}
                            searchListFilter={searchListFilter}
                            skipBlur={skipBlur}
                            navigateLinkInViewMode={{
                                navParamName: 'id',
                                navPath: '/master-data/products/'
                            }}
                            popoverContainerHtmlId={popoverContainerHtmlId}
                        />
                    </div>
                </div>
            </>
        );
    }
);
