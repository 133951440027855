import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltips } from 'ui';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';

import { LogMessagesTable } from '../LogMessagesTable/LogMessagesTable';

interface ILogMessagesModal {
    logMessages?: LogMessage[];
    onCancel: () => void;
}

export const LogMessagesModal = observer<ILogMessagesModal>(({ logMessages = [], onCancel }) => {
    const isMessagesIsset = logMessages.length > 0;

    const { t } = useTranslation();

    return (
        <Modal
            open={isMessagesIsset}
            onCancel={onCancel}
            destroyOnClose
            afterClose={onCancel}
            footer={
                <ButtonWithTooltips id="close" key="close" onClick={onCancel}>
                    {t('close')}
                </ButtonWithTooltips>
            }
            title={t('messages')}
            width="75%"
            centered
        >
            <LogMessagesTable logMessages={logMessages} />
        </Modal>
    );
});

interface ILogMessagesModalOuter {
    logMessages?: LogMessage[];
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const LogMessagesModalOuter = observer<ILogMessagesModalOuter>(
    ({ logMessages = [], open, setOpen }) => {
        const { t } = useTranslation();

        return (
            <Modal
                open={open}
                onCancel={() => setOpen(false)}
                footer={
                    <ButtonWithTooltips id="close" key="close" onClick={() => setOpen(false)}>
                        {t('close')}
                    </ButtonWithTooltips>
                }
                title={t('messages')}
                width="75%"
                centered
            >
                <LogMessagesTable logMessages={logMessages} />
            </Modal>
        );
    }
);
