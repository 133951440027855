import { Dispatch, SetStateAction, forwardRef, useImperativeHandle } from 'react';

import { TableName, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';

import { Collapse } from 'antd';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';
import { SkeletonLoader } from 'ui/Skeleton/Skeleton';
import { useTranslation } from 'react-i18next';
import { Base_DataTable } from './Base_DataTable';
import type { MRTColumnDef, MRTRow } from './MRT_Types';
import { TableRefType, useTableBackendFeatures } from './useTableBackendFeatures';

interface ViewOnlyDetailPage_DataTableProps {
    viewName: ViewName;
    filter?: Filter[];
    predefinedColumns?: MRTColumnDef[]; // Если нужны уникальные коломки, а не key и short_title
    onClickRow?: (row: MRTRow) => void;
    withGroupGroupCollapse?: boolean;
    extraGroupCollapseTitle?: string;
    enableMultiRowSelection?: boolean;
    enableRowSelection?: boolean;
    extraButtons?: React.ReactNode;
    refresh?: boolean;
    pageSize?: number;
    renderToolbarInternalActions?: any;
    setRefresh?: Dispatch<SetStateAction<boolean>>;
}

export const ViewOnlyDetailPage_DataTable = forwardRef<
    TableRefType,
    ViewOnlyDetailPage_DataTableProps
>(
    (
        {
            viewName,
            filter,
            predefinedColumns,
            onClickRow,
            withGroupGroupCollapse = false,
            extraGroupCollapseTitle,
            enableMultiRowSelection = true,
            enableRowSelection = true,
            extraButtons,
            renderToolbarInternalActions,
            refresh = false,
            pageSize,
            setRefresh
        },
        ref
    ) => {
        const { t } = useTranslation();
        const tableName: TableName = viewName.slice(1) as TableName; // отрезал v
        const collapseTitleText = extraGroupCollapseTitle || `${tableName}.title`;

        const tableBackendFeatures = useTableBackendFeatures({
            viewOrTableName: viewName,
            predefinedColumns,
            enableViewDisplayPreferenceMenu: false,
            enableRowSelection,
            refresh,
            setRefresh,

            loadRowsPerPackage: pageSize,
            // viewDisplayPreferenceSettings,
            filter // Pass the 'filter' prop to useTableBackendFeatures
        });

        useImperativeHandle(
            ref,
            () => {
                return {
                    tableApi: tableBackendFeatures.tableInstanceRef,
                    data: tableBackendFeatures.data as TableRow[],
                    states: tableBackendFeatures.state,
                    requestOptions: tableBackendFeatures.requestOptions,
                    setTableOptions: tableBackendFeatures.setTableOptions,
                    tableOptions: tableBackendFeatures.tableOptions
                    // test: tableBackendFeatures.state.
                };
            },
            [
                tableBackendFeatures.tableInstanceRef,
                tableBackendFeatures.data,
                tableBackendFeatures.state,
                tableBackendFeatures.tableOptions,
                tableBackendFeatures.requestOptions
            ]
        );

        const paginationOption = pageSize
            ? {
                  pagination: {
                      pageSize,
                      pageIndex: 0
                  }
              }
            : undefined;

        if (!tableBackendFeatures.data) {
            return <EmptyMarker />;
        }
        return (
            <>
                {withGroupGroupCollapse ? (
                    <Collapse
                        ghost
                        items={[
                            {
                                key: 1,
                                label: t(collapseTitleText),
                                children: (
                                    <SkeletonLoader
                                        status={
                                            !tableBackendFeatures.columns ||
                                            !tableBackendFeatures.columns.length
                                        }
                                    >
                                        <Base_DataTable
                                            {...tableBackendFeatures}
                                            viewName={viewName}
                                            enableMultiRowSelection={enableMultiRowSelection}
                                            enableRowSelection={enableRowSelection}
                                            renderToolbarInternalActions={
                                                renderToolbarInternalActions
                                            }
                                            state={{
                                                ...tableBackendFeatures.state,
                                                ...paginationOption
                                            }}
                                            muiTableBodyRowProps={({ row }) => ({
                                                onClick: () => {
                                                    if (onClickRow) {
                                                        onClickRow(row);
                                                    }
                                                },
                                                sx: onClickRow
                                                    ? { cursor: 'pointer' }
                                                    : { cursor: 'default' }
                                            })}
                                        />
                                    </SkeletonLoader>
                                )
                            }
                        ]}
                    />
                ) : (
                    <SkeletonLoader
                        status={
                            // !tableBackendFeatures.columns || !tableBackendFeatures.columns.length
                            tableBackendFeatures.state.isLoading
                        }
                    >
                        <Base_DataTable
                            {...tableBackendFeatures}
                            viewName={viewName}
                            state={{ ...tableBackendFeatures.state }}
                            enableMultiRowSelection={enableMultiRowSelection}
                            enableRowSelection={enableRowSelection}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => {
                                    if (onClickRow) {
                                        onClickRow(row);
                                        return;
                                    }

                                    if (enableMultiRowSelection) {
                                        tableBackendFeatures.onRowSelectionChange((prev) => ({
                                            ...prev,
                                            [row.id]: prev[row.id] ? !prev[row.id] : true
                                        }));
                                    } else {
                                        row.toggleSelected(!row.getIsSelected());
                                    }
                                },
                                sx: onClickRow ? { cursor: 'pointer' } : { cursor: 'default' }
                            })}
                            renderTopToolbarCustomActions={() =>
                                extraButtons ? <> {extraButtons} </> : null
                            }
                        />
                    </SkeletonLoader>
                )}
            </>
        );
    }
);
