import { MenuItem } from '@mui/material';
import { TableMeta } from '@tanstack/react-table';
import { Flex } from 'antd';
import {
    MRT_ColumnDef,
    MRT_DensityState,
    MRT_Row,
    MRT_RowVirtualizer,
    MRT_TableInstance,
    MRT_TableOptions,
    MRT_TableState,
    MaterialReactTable,
    useMaterialReactTable
} from 'material-react-table';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AccessControl } from 'modules/auth/components';
import { usePermissions } from 'modules/auth/contexts';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { RootTableRow, TableName, ViewName } from 'modules/supabase/types/Dataset';
import { Loader } from 'ui/Loader/Loader';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useMessages, useNotifications, useResize } from 'utils/hooks';
import { useStoreNavigate } from 'utils/store';
import { LocalSearchComponent } from './components/LocalSearchComponent/LocalSearchComponent';
import { ListView } from './components/mobile/ListView/ListView';
import { filterData } from './utils/helpers/filterData';
import { formatColumns } from './utils/helpers/formatColumns';
import { formatLocation } from './utils/helpers/formatLocation';
import { getLocalization } from './utils/helpers/getLocalization';
import {
    icons,
    muiCheckboxProps,
    muiTableBodyCellProps,
    muiTableDetailPanelProps,
    muiTableHeadCellProps,
    muiTableHeadRowProps,
    muiTablePaperProps,
    renderEmptyRowsFallback
} from './utils/tableCustomOptions';

type TTableMeta = TableMeta<object> & { tableName: TableName; viewName: ViewName };

interface BaseDataTableProps extends MRT_TableOptions<any> {
    viewName: ViewName;
    data: any[];
    columns: MRT_ColumnDef<any>[];
    hideColumnHeader?: boolean;
    columnVisibilityKey?: string;
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
    isHasActionColumn?: boolean;
    state?: Partial<MRT_TableState<any> & { virtualization: boolean }>;
    tableInstanceRef?: React.MutableRefObject<MRT_TableInstance<any> | null>;
    emptyRowsMarker?: ((props: { table: MRT_TableInstance<any> }) => React.ReactNode) | undefined;
    extraExpandHide?: (props: { row: MRT_Row<any>; table?: MRT_TableInstance<any> }) => boolean;
    renderFetchedInfo?: (selectedCount?: number) => React.ReactNode;
    meta?: TTableMeta;
    mobileOptions?: {
        titleColumns: { name: string; accessorKeySubstr?: string }[];
        avatarColumn?: { name: string; accessorKeySubstr?: string };
    };
    // infiniteResult?: UseInfiniteQueryResult<any>;
}

// Base component for displaying data tables - a wrapper over MaterialReactTable
export const BaseDataTable = memo<BaseDataTableProps>((props) => {
    const {
        data,
        columns,
        viewName,
        state,
        hideColumnHeader = true,
        meta,
        setRefresh,
        isHasActionColumn = false,
        tableInstanceRef,
        emptyRowsMarker,
        extraExpandHide,
        mobileOptions,
        enablePinning = false,
        // infiniteResult,
        ...otherProps
    } = props;

    const { isScreenMd } = useResize();

    // const newColumnsProp = useMemo(() => (data.length > 0 ? columns : []), [columns, data.length]);

    const [localFilter, setLocalFilter] = useState('');

    const { t, i18n } = useTranslation();
    const navigate = useStoreNavigate();

    const { permissions } = usePermissions();

    const { openMessage, loadingMessage } = useMessages({ message: '' });

    const { openNotify } = useNotifications({ message: '' });

    const localization = getLocalization(hideColumnHeader);
    const location = useLocation();
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

    useEffect(() => {
        rowVirtualizerInstanceRef.current?.scrollToOffset?.(1);
    }, [props]);

    const handleEdit = useCallback(
        (rowId: string | number) => {
            const pathname = formatLocation(location, rowId);

            navigate(`${pathname}?mode=edit`);
        },
        [location, navigate]
    );

    const handleClone = useCallback(
        (rowId: string | number) => {
            const pathname = formatLocation(location, rowId);

            navigate(`${pathname}?mode=clone`);
        },
        [location, navigate]
    );

    const handleDelete = useCallback(
        async (row: RootTableRow) => {
            const messageKey = 'deleting_documents';

            loadingMessage(messageKey);

            const promises: any = [];

            if (meta?.tableName.includes('settings')) {
                promises.push(
                    supabaseClient.from(meta.tableName).delete().match({ id: row.id, ver: row.ver })
                );
            } else {
                let recordsToUpdate: object = {
                    lifecycle_status_code: 'DELETED'
                };

                if (meta) {
                    if (meta.tableName === 'docs_transportation_trips') {
                        recordsToUpdate = {
                            ...recordsToUpdate,
                            planning_status_code: null,
                            approval_status_code: null
                        };
                    }

                    promises.push(
                        supabaseClient
                            .from(meta.tableName)
                            .update(recordsToUpdate)
                            .match({ id: row.id, ver: row.ver })
                    );
                }
            }

            try {
                await Promise.all(promises);

                openMessage({
                    key: messageKey,
                    message: t('deleting_success'),
                    type: 'success'
                });

                if (setRefresh) {
                    setRefresh(true);
                }
            } catch (error) {
                // В UI
                openMessage({
                    key: messageKey,
                    message: t('deliting_failed_dataset'),
                    type: 'error'
                });
                openNotify({
                    message: t('deleting_error'),
                    description: makeErrorReadable(
                        (error as Error)?.message || t('deliting_failed_dataset')
                    ),
                    type: 'error',
                    duration: 0 // Выключаем автоматическое закрытие нотификации
                });

                // В консоль
                console.warn(t('deleting_error'));
                console.error(error);
            }
        },
        [loadingMessage, meta, openMessage, openNotify, setRefresh, t]
    );

    const forceDisableExpandIfNoSubRows = useCallback(() => {
        const isNotTree = data.every((item) => item.subRows && item.subRows.length === 0);

        if (isNotTree) return { enableExpanding: false, enableExpandAll: false };

        return {
            enableExpanding: props.enableExpanding,
            enableExpandAll: props.enableExpandAll
        };
    }, [data, props.enableExpandAll, props.enableExpanding]);

    const { enableExpanding, enableExpandAll } = forceDisableExpandIfNoSubRows();

    const generateClassNames = `data_table_wrapper ${
        !props.data.length ? 'data_table_wrapper__not_loaded' : ''
    }`;

    const renderRowActionMenuItems: (props: {
        closeMenu: () => void;
        row: MRT_Row<any>;
        table: MRT_TableInstance<any>;
    }) => React.ReactNode[] = useCallback(
        ({ row }) => [
            <MenuItem id="edit" key={row.original.id} onClick={() => handleEdit(row.original.id)}>
                {t('edit')}
            </MenuItem>,
            <AccessControl key={`${row.original.id}-2`} id="clone" permissions={permissions}>
                <MenuItem id="clone" onClick={() => handleClone(row.original.id)}>
                    {t('clone')}
                </MenuItem>
            </AccessControl>,
            <AccessControl key={`${row.original.id}-3`} id="delete" permissions={permissions}>
                <MenuItem
                    id="delete"
                    style={{ color: 'red' }}
                    onClick={() => handleDelete(row.original)}
                    disabled={row.original?.lifecycle_status_code === 'DELETED'}
                >
                    {row.original?.lifecycle_status_code === 'DELETED' ? t('deleted') : t('delete')}
                </MenuItem>
            </AccessControl>
        ],
        [handleClone, handleDelete, handleEdit, permissions, t]
    );

    const modifiedState = useMemo(
        () => ({
            ...state,
            columnSizing: {
                ...state?.columnSizing,
                'mrt-row-select': 40,
                'mrt-row-drag': 50,
                'mrt-row-actions': 20
            },
            columnOrder: ['mrt-row-drag', 'mrt-row-select', ...(state?.columnOrder || [])],
            showProgressBars: false,
            initialState: { columnPinning: { left: ['key'] } },
            columnPinning: [
                'vdocs_transportation_orders',
                'vdocs_transportation_trips',
                'vdocs_invoices',
                'vdocs_trip_groups'
            ].includes(viewName)
                ? { left: ['mrt-row-select', 'key'] }
                : { left: [], right: [] }
        }),
        [state]
    );

    const modifiedInitialState = useMemo(
        () => ({
            ...props.initialState,
            sorting: props?.initialState?.sorting || [{ id: 'id', desc: true }],
            density: 'compact' as MRT_DensityState
        }),
        [props.initialState]
    );

    const newDataProp = useMemo(
        () =>
            columns.length > 0
                ? filterData(
                      data,
                      localFilter,
                      Object.keys(modifiedState?.columnVisibility || {}),
                      i18n.language
                  )
                : [],
        [columns.length, data, i18n.language, localFilter, modifiedState?.columnVisibility]
    );

    const muiExpandButtonProps = useCallback(
        ({ row }: { row: MRT_Row<any> }) => {
            if (
                (row.original as { subRows: object[] }).subRows?.length === 0 ||
                (extraExpandHide && extraExpandHide({ row }))
            ) {
                return { sx: { display: 'none' } };
            }
            return {};
        },
        [extraExpandHide]
    );

    const table = useMaterialReactTable({
        data: newDataProp,
        columns: formatColumns(columns),
        memoMode: 'cells',
        enableColumnPinning: enablePinning,
        enableFilterMatchHighlighting: false,
        enableRowSelection:
            props.enableRowSelection !== undefined ? props.enableRowSelection : true,
        enableExpanding,
        enableExpandAll,
        meta,
        enableRowActions: isHasActionColumn,
        enableStickyHeader: true,
        enableStickyFooter: true,
        // renderBottomToolbarCustomActions,
        enableDensityToggle: false,
        renderRowActionMenuItems: props.renderRowActions ? undefined : renderRowActionMenuItems,
        initialState: modifiedInitialState,
        icons,
        muiTableBodyCellProps,
        muiExpandButtonProps,
        muiDetailPanelProps: muiTableDetailPanelProps,
        muiTablePaperProps,
        state: modifiedState,
        localization,
        renderEmptyRowsFallback: emptyRowsMarker || renderEmptyRowsFallback,
        muiSelectAllCheckboxProps: muiCheckboxProps,
        sortDescFirst: true,
        ...otherProps,
        // ## pagination
        paginationDisplayMode: 'pages',
        // ### custom table header ###
        layoutMode: 'grid',
        muiTableHeadCellProps,
        muiTableHeadRowProps,
        defaultColumn: {
            minSize: 10 // allow columns to get smaller than default
            // maxSize: 1000, // allow columns to get larger than default
            // size: 260 // make columns wider by default
        },
        muiSelectCheckboxProps: muiCheckboxProps,
        // ## disable row selection toolbar alert ##
        positionToolbarAlertBanner: 'none',
        positionToolbarDropZone: 'none',
        // ### disable column dragging ###
        enableColumnDragging: false,
        // ### disable default table filters ###
        // columnFilterDisplayMode: 'custom',
        enableColumnFilters: false,
        enableColumnFilterModes: false,
        // ### loading status styles ###
        muiCircularProgressProps: {
            Component: <Loader />
        },
        // ### render custom toolbar for correct displaying ###
        renderTopToolbarCustomActions:
            otherProps.renderTopToolbarCustomActions || (() => <div></div>),
        renderBottomToolbar: ({ table }) => (
            <Flex justify="space-between" style={{ padding: '5px' }}>
                {otherProps.renderBottomToolbarCustomActions ? (
                    <otherProps.renderBottomToolbarCustomActions table={table} />
                ) : (
                    <div />
                )}

                {otherProps.renderFetchedInfo
                    ? otherProps.renderFetchedInfo(table.getSelectedRowModel().rows.length)
                    : null}
            </Flex>
        ),
        renderToolbarInternalActions(propsTable) {
            return (
                <Flex gap={5}>
                    {!isScreenMd ? (
                        <LocalSearchComponent
                            placeholder={`${t('search')}...`}
                            setFilter={setLocalFilter}
                        />
                    ) : null}
                    {props.renderToolbarInternalActions
                        ? props.renderToolbarInternalActions(propsTable)
                        : undefined}
                </Flex>
            );
        },
        // ### mobile view ###
        muiTableContainerProps: {
            ...otherProps.muiTableContainerProps,
            sx: {
                ...otherProps.muiTableContainerProps?.sx,
                overflow: isScreenMd ? 'hidden' : undefined
            }
        },
        muiTableProps: isScreenMd
            ? ({ table }) => {
                  return {
                      component: () => (
                          <ListView
                              table={table}
                              data={data}
                              titleColumns={mobileOptions?.titleColumns}
                              avatarColumn={mobileOptions?.avatarColumn}
                          />
                      )
                  };
              }
            : undefined,
        rowVirtualizerInstanceRef
    });

    if (tableInstanceRef) tableInstanceRef.current = table;

    return (
        <>
            <AccessControl id={viewName} permissions={permissions}>
                <div className={generateClassNames} id={viewName}>
                    <MaterialReactTable table={table as any} />
                </div>
            </AccessControl>
        </>
    );
});
