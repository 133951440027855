import { memo, useState } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
// Подключаем модуль для автоформатирования
import { Ace } from 'ace-builds';
import 'ace-builds/src-noconflict/ext-beautify';
import { ButtonWithTooltips } from 'ui';
import { TFunction } from 'i18next';

interface JsonFieldType {
    jsonValue: string | null;
    handleSingleFieldChange: (newFieldValue: any, fieldName: string) => void;
    readOnly: boolean;

    showValidate?: boolean;

    t: TFunction<'translation', undefined, 'translation'>;
}

export const JsonField = memo<JsonFieldType>(
    ({ jsonValue, handleSingleFieldChange, readOnly, t, showValidate = true }) => {
        const [json, setJson] = useState(jsonValue || '');
        const [annotations, setAnnotations] = useState<Ace.Annotation[]>();

        const onChange = (newValue: string) => {
            try {
                setAnnotations([]);

                handleSingleFieldChange(newValue, 'template_request');
                setJson(newValue);
            } catch (error) {
                const errorMessage = (error as Error).message;
                let lineNumber = 0;
                let columnNumber = 0;

                if (errorMessage) {
                    // eslint-disable-next-line no-console
                    console.error('JSON validation error:', errorMessage);

                    // Поиск совпадений в строке. Необходимо для поиска в строке номера строки и колонки где появилась ошибка
                    const lineIndex = errorMessage.indexOf('line');
                    const columnIndex = errorMessage.indexOf('column');

                    if (lineIndex !== -1 && columnIndex !== -1) {
                        // Извлечение цифр после слова "line" и "column"
                        lineNumber = parseInt(errorMessage.slice(lineIndex + 4), 10);
                        columnNumber = parseInt(errorMessage.slice(columnIndex + 6), 10);
                    }
                }

                const annotation: Ace.Annotation = {
                    text: errorMessage,
                    type: 'error',
                    column: columnNumber,
                    row: lineNumber
                };

                setAnnotations([annotation]);
                handleSingleFieldChange(newValue, 'template_request');
                setJson(newValue);
            }
        };

        const validateJson = () => {
            try {
                let parcedJson = JSON.parse(json);

                parcedJson = JSON.stringify(parcedJson, null, 4);
                setJson(parcedJson);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        };

        return (
            <div>
                {showValidate && (
                    <ButtonWithTooltips
                        id="drop"
                        key="drop"
                        className="validate"
                        onClick={validateJson}
                        style={{ marginRight: '0.5dvh' }}
                    >
                        {t('validate')}
                    </ButtonWithTooltips>
                )}

                <AceEditor
                    mode="json"
                    value={json}
                    theme="ace/theme/monokai"
                    name="json-editor"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true
                    }}
                    fontSize={15}
                    width="100%"
                    placeholder="test placeholder"
                    annotations={annotations}
                    readOnly={readOnly}
                    // Enable live editing for syntax highlighting and error detection
                    onChange={onChange}
                />
            </div>
        );
    }
);
