import { Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { MetaField } from 'modules/services/backend-api/generated_info';
import { FieldsBox } from 'smart/modules/SmartDetailPage/ui';
import { HelpIcon } from 'smart/ui';
import { fieldEditRender, fieldRender, getExcludeFieldsPattern } from 'smart/utils';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { useEffect, useState } from 'react';

interface RowModalProps {
    open: boolean;
    data: any;
    mode: 'edit' | 'view';
    setData: (data: any) => void;
    onClose: () => void;
    onOk: () => void;
    metaFields?: MetaField[];
    rootMeta?: string;
    rootDataSource?: IObjectWithId;
}

const fieldFilterRule = (field: MetaField, index: number, metaFields: MetaField[]) =>
    !field.IsHiddenOnDetail && getExcludeFieldsPattern(field, metaFields, { isDetail: true });

export const RowModal: React.FC<RowModalProps> = ({
    open,
    data,
    metaFields,
    setData,
    onClose,
    onOk,
    mode,
    rootMeta,
    rootDataSource
}) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [modalTitle, setModalTitle] = useState(t(mode));

    useEffect(() => {
        (async () => {
            if (metaFields?.length && !metaStore.meta.get(metaFields?.[0]?.Meta_Code)?.info) {
                await metaStore.getInfo(metaFields?.[0]?.Meta_Code);
            }

            setModalTitle(
                metaFields?.length
                    ? metaStore.meta.get(metaFields?.[0]?.Meta_Code)?.info?.SingularName?.[
                          language
                      ] || t(metaStore.meta.get(metaFields?.[0]?.Meta_Code)?.info?.TableName)
                    : t(mode)
            );
        })();
    }, [language, metaFields, mode, t]);

    const items = metaFields?.filter(fieldFilterRule)?.map((field) => ({
        key: field.FieldName,
        label: field.Name
            ? field.Name[language] || t(field.ColumnName || field.FieldName)
            : t(field.ColumnName || field.FieldName),
        vertical:
            field.ValueType?.includes('password') ||
            (field.ValueType?.includes('json') &&
                field.FieldName.includes('DefaultValue') &&
                !field.ValueType?.includes('json_type') &&
                !field.ValueType?.includes('multilang_text')),
        // span:
        //     field.ValueType?.includes('json') &&
        //     field.FieldName.includes('DefaultValue') &&
        //     !field.ValueType?.includes('json_type') &&
        //     !field.ValueType?.includes('multilang_text')
        //         ? 2
        //         : 1,
        children: (
            <HelpIcon text={field.Description?.[language]}>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {mode === 'edit' && !field.IsReadOnly && !field.ValueType?.includes('value:')
                        ? fieldEditRender({
                              data: data[field.FieldName],
                              onChange: (key: string, value: any) =>
                                  setData({ ...data, [key]: value }),
                              language,
                              metaFieldData: metaFields,
                              fieldName: field.FieldName,
                              dataSource: data,
                              rootMeta,
                              rootDataSource
                          })
                        : fieldRender({
                              data: data[field.FieldName],
                              language,
                              metaFieldData: metaFields,
                              fieldName: field.FieldName,
                              dataSource: data,
                              rootMeta,
                              rootDataSource
                          })}
                </div>
            </HelpIcon>
        )
    }));

    return (
        <Modal
            title={modalTitle}
            centered
            open={open}
            onCancel={onClose}
            onOk={onOk}
            destroyOnClose
            width={'90%'}
        >
            <Flex vertical gap={5}>
                <FieldsBox items={items || []} size="small" />
            </Flex>
        </Modal>
    );
};
