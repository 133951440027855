import { MRT_ColumnSizingState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { ViewOrTableName } from 'modules/supabase/types/Dataset';
import { useGenerateUIColumnsBySupabaseViewOrTableName } from 'modules/supabase/utils/tableCellUtils/generateColumnsFromType';
import { ViewDisplayPreferenceSettings } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { ColumnDef } from './useTableBackendFeatures';
// import { useViewDisplayPreferences } from 'supabase/useViewDisplayPreferences';

export type FieldVisibilityType = {
    [key: string]: boolean;
};

interface useGenerateColumnsProps {
    viewOrTableName: ViewOrTableName;
    viewDisplayPreferenceSettings?: ViewDisplayPreferenceSettings | null;
    predefinedColumns?: ColumnDef[]; // это настройка для ситуации, где преднастроенные колонки - где что-то не стандартное - например таблицы ЭТРАН с полями со ссылками на их словари
    // для стандратных таблиц - наших - тут может быть просто список accessor key
    // header всегда = accesor key кроме select fields - они делают его из префикса
    // для ссылок: для словарей передаем _code, для МД и ТД передаем _key, для остальных передаем _id
    doRequest?: boolean;

    setColumnOrder?: (columnKeyList: string[]) => string[];
    enableRowSelection?: boolean;
    enableRowOrdering?: boolean;
    enableExpanding?: boolean;
}

// TODO: @Anton надо осбудить: зачем вызывать useGenerateColumns внутри DetailedPage DataTable? если мы там все руками перечисляем? И почему мы там все руками перечисляем - разве нельзя это сгенерить?
export const useGenerateColumns = ({
    viewOrTableName,
    predefinedColumns,
    viewDisplayPreferenceSettings,
    enableRowOrdering,
    doRequest = true,
    enableRowSelection = true,
    enableExpanding,
    setColumnOrder
}: useGenerateColumnsProps) => {
    const [columnVisibility, setColumnVisibility] = useState<FieldVisibilityType>({}); // это defaulColumnVisibility
    const [columnOrdering, setColumnOrdering] = useState<string[]>([]);

    const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>({});

    const [processingStates, setProcessingState] = useState({
        sizingProcessed: false,
        orderProcessed: false,
        visible: false,
        visibleProcessed: false
    });

    // Получаем все колонки по ViewOrTableName
    const { columns, supabaseColumns } = useGenerateUIColumnsBySupabaseViewOrTableName({
        viewOrTableName,
        predefinedColumns,
        viewDisplayPreferenceSettings,
        ableToFetch: doRequest
    });

    // Sizing
    useEffect(() => {
        if (viewDisplayPreferenceSettings) {
            setColumnSizing(viewDisplayPreferenceSettings.columnSizing || {});
        }

        setProcessingState((prev) => {
            return {
                ...prev,
                sizingProcessed: true
            };
        });
    }, [viewDisplayPreferenceSettings?.columnSizing]);

    // Order
    useEffect(() => {
        if (columns.length > 0) {
            const columnOrder: string[] = ['actions'];

            if (enableRowOrdering) {
                columnOrder.push('mrt-row-drag');
            }
            if (enableRowSelection) {
                columnOrder.push('mrt-row-select');
            }
            // if (enableExpanding) {
            columnOrder.push('mrt-row-expand');
            // }

            columnOrder.push('mrt-row-expand');

            //  При условии, что есть predefinedColumns, форсированно выстраиваем порядок колонок согласно оному

            let finalColumnsOrder: string[] = [];

            if (predefinedColumns) {
                finalColumnsOrder = predefinedColumns.map(
                    ({ accessorKey }) => accessorKey as string
                );
                columnOrder.push(...finalColumnsOrder);
            }

            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];

                if (finalColumnsOrder.includes(column.accessorKey as string)) continue;

                columnOrder.push(column.accessorKey as string);
            }

            if (setColumnOrder) {
                setColumnOrdering(setColumnOrder(columnOrder));
            } else {
                setColumnOrdering(columnOrder);
            }
            setProcessingState((prev) => {
                return {
                    ...prev,
                    orderProcessed: true
                };
            });
        }
    }, [columns]);

    // Visible
    useEffect(() => {
        // Если есть преднастроенные колонки - по умолчанию отображаем их
        if (predefinedColumns && predefinedColumns?.length > 0 && columns.length > 0) {
            const columnsVisible: Record<string, any> = columns.reduce((prevValue, column) => {
                // FIXME: Не надо пользоваться функциональщиной там где можно без нее
                if (predefinedColumns?.find((col) => col.accessorKey === column.accessorKey)) {
                    const fieldVisibility: FieldVisibilityType = {};

                    if (column.accessorKey) {
                        fieldVisibility[column.accessorKey] = true;
                    }

                    return {
                        ...prevValue,
                        ...fieldVisibility
                    };
                }

                const fieldVisibility: FieldVisibilityType = {};

                if (column.accessorKey) {
                    fieldVisibility[column.accessorKey] = false;
                }

                return {
                    ...prevValue,
                    ...fieldVisibility
                };
            }, {});

            if (enableExpanding) {
                columnsVisible['mrt-row-expand'] = true;
            }

            if (enableRowOrdering) {
                columnsVisible['mrt-row-drag'] = true;
            }

            if (enableRowSelection) {
                columnsVisible['mrt-row-select'] = true;
            }
            setColumnVisibility(columnsVisible);
        }

        setProcessingState((prev) => {
            return {
                ...prev,
                visible: true
            };
        });
    }, [predefinedColumns, columns]);

    // Visible
    useEffect(() => {
        // Если нет predefind columns
        if (!predefinedColumns || viewDisplayPreferenceSettings?.title !== 'Default') {
            // Если есть ракурс то из него надо взять список видимых колонок
            const columnVisible: FieldVisibilityType = {};

            if (
                viewDisplayPreferenceSettings &&
                viewDisplayPreferenceSettings?.columns &&
                viewDisplayPreferenceSettings.columns.length > 0
            ) {
                // FIXME: in logs I see it is called twice, why?
                viewDisplayPreferenceSettings?.columns.forEach((column) => {
                    columnVisible[column] = true;
                });

                // В columnVisibility надо передавать информацию о всех колонках в структуре, с значениями false(скрыто) и true(отображаем)
                if (columns && columns.length > 0) {
                    columns.forEach((column) => {
                        if (column.accessorKey && !columnVisible[column.accessorKey]) {
                            columnVisible[column.accessorKey] = false;
                        }
                    });
                }

                if (enableExpanding) {
                    columnVisible['mrt-row-expand'] = true;
                }

                if (enableRowOrdering) {
                    columnVisible['mrt-row-drag'] = true;
                }

                if (enableRowSelection) {
                    columnVisible['mrt-row-select'] = true;
                }

                setColumnVisibility(columnVisible);

                console.log(
                    'Applied tableDisplayPreferenceSettings to set visible columns to: ',
                    columnVisible
                );
            }
        }

        setProcessingState((prev) => {
            return {
                ...prev,
                visibleProcessed: true
            };
        });
    }, [viewDisplayPreferenceSettings, columns, predefinedColumns]);

    const res = useMemo(
        () => ({
            columns, // возвращают все-все колонки
            columnVisibility, // возвращает то что надо показать
            setColumnVisibility, // функция изменения видимости столбцов
            columnOrdering, // порядок столбцов
            setColumnOrdering, // изменения порядка
            columnSizing, // ширина колонок
            setColumnSizing, // изменение ширины колонок,
            supabaseColumns,
            loaded:
                processingStates.orderProcessed &&
                processingStates.sizingProcessed &&
                processingStates.visible &&
                processingStates.visibleProcessed
        }),
        [
            columnOrdering,
            columnSizing,
            columnVisibility,
            columns,
            processingStates.orderProcessed,
            processingStates.sizingProcessed,
            processingStates.visible,
            processingStates.visibleProcessed
        ]
    );

    return res;
};
