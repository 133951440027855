import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { BaseField } from '../BaseField/BaseField';

interface DateFieldProps {
    id: string; // идентификатор поля ввода
    value: string | null; // строковый формат даты ISO 8601
    mode: 'view' | 'edit'; // режим отображения: просмотр или редактирование
    popoverContainerHtmlId?: string;
    onChange?: (newValue: string) => void; // функция для обработки изменения значения поля ввода
    withLabel?: boolean;
    required?: boolean;
    htmlId?: string;
    skipBlur?: boolean;
}

// Экспорт компонента DateField
export const DateField = memo<DateFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false,
        htmlId = id
    }) => {
        const { t } = useTranslation();

        // Определение функции для рендеринга компонента выбора даты
        const renderInput = useCallback(
            (
                inputValue: string | null,
                onInputChange?: (newValue: string | null) => void,
                onBlur?: () => void
            ) => {
                const handleChange = (date: dayjs.Dayjs | null) => {
                    if (onInputChange && onBlur && date) {
                        onInputChange(date.local().format('YYYY-MM-DD').toString());
                        onBlur();
                    }
                };

                const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
                    e.stopPropagation();
                    if (onInputChange) {
                        onInputChange(null);
                    }
                };

                return (
                    <DatePicker
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        data-testid={`${htmlId}-edit`}
                        value={inputValue ? dayjs(inputValue) : null}
                        onChange={handleChange}
                        format={UserSpecificFormat.getDateFormat()}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        inputReadOnly
                        onPickerValueChange={() => {}}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                );
            },
            [htmlId, popoverContainerHtmlId, t]
        );

        // Определение функции для рендеринга компонента просмотра даты
        const renderView = useCallback(
            (viewValue: string | null) => {
                return (
                    <DatePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-view`}
                        placeholder={t('no_value') as string}
                        value={viewValue ? dayjs(viewValue) : null}
                        format={UserSpecificFormat.getDateFormat()}
                        variant="borderless"
                        allowClear={false}
                        open={false}
                        inputReadOnly
                        suffixIcon={<></>}
                    />
                );
            },
            [htmlId, t]
        );

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты
        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={true}
            />
        );
    }
);
