import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

type RangeValue<DateType> = [DateType | null, DateType | null];

interface LocalDateTimeRangeFieldProps {
    id: string;
    value: { from_datetime: string | null; to_datetime: string | null } | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    onChange: (newValue: { from_datetime: string | null; to_datetime: string | null }) => void;
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
}

export const LocalDateTimeRangeField = memo<LocalDateTimeRangeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false
    }) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (
                inputValue: { from_datetime: string | null; to_datetime: string | null },
                onInputChange?: (newValue: {
                    from_datetime: string | null;
                    to_datetime: string | null;
                }) => void,
                onBlur?: () => void
            ) => {
                const value: RangeValue<Dayjs> = [
                    inputValue && inputValue.from_datetime ? dayjs(inputValue.from_datetime) : null,
                    inputValue && inputValue.to_datetime ? dayjs(inputValue.to_datetime) : null
                ];
                return (
                    // DatePicker.RangePicker подразумевает, что пользователь выбирает две даты, если одна из дат не выбрана, то возвращается null
                    <DatePicker.RangePicker
                        id={`${id}-value`}
                        data-testid={`${id}-edit`}
                        showTime // отображение времени в компоненте выбора диапазона дат и времени
                        // Установка значения диапазона дат и времени в компоненте выбора диапазона дат и времени
                        value={value}
                        // Обработка изменения значения диапазона дат и времени в компоненте выбора диапазона дат и времени
                        onChange={(dates) => {
                            const [from_datetime, to_datetime] = dates || [null, null];
                            if (onInputChange && onBlur) {
                                onInputChange({
                                    from_datetime: from_datetime && from_datetime.toISOString(),
                                    to_datetime: to_datetime && to_datetime.toISOString()
                                });

                                onBlur();
                            }
                        }}
                        onBlur={onBlur}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                );
            },
            [id, popoverContainerHtmlId]
        );

        const renderView = useCallback(
            (viewValue: { from_datetime: string | null; to_datetime: string | null }) => {
                const value: RangeValue<Dayjs> = [
                    viewValue && viewValue.from_datetime ? dayjs(viewValue.from_datetime) : null,
                    viewValue && viewValue.to_datetime ? dayjs(viewValue.to_datetime) : null
                ];

                return (
                    <DatePicker.RangePicker
                        id={`${id}-value`}
                        data-testid={`${id}-view`}
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        value={value}
                        variant="borderless"
                        inputReadOnly
                        open={false}
                        showTime
                    />
                );
            },
            [id, t]
        );

        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
