import { DynamicDateRangeField } from 'components/fields';
import { SmartDateRangeField, SmartMultiSelectField } from 'smart/components';
import { ColumnOptionsType, fieldEditRender } from 'smart/utils';

import { i18n } from 'utils/i18n/i18n';
import { FilterField, FilterFieldValue, LocalFilterValueType } from '../../../types';

interface RenderValueProps {
    value: LocalFilterValueType;
    data: FilterField;
    onChange: (filterValue: LocalFilterValueType, changedFilterValue: FilterFieldValue) => void;
    options: ColumnOptionsType | null;
}

export const renderValue = ({ value, data, onChange, options }: RenderValueProps) => {
    if (!value.operator) return value.value;

    if (['empty', 'notEmpty'].includes(value.operator)) {
        return null;
    }

    if (['in_list', 'not_in_list'].includes(value.operator)) {
        if (options && options.ref) {
            return (
                <SmartMultiSelectField
                    metaName={options.ref.meta}
                    onChange={(v: any) => {
                        onChange(value, {
                            value: v
                        });
                    }}
                    value={value.value || []}
                    filters={options.filters}
                    treeOptions={{ parentFieldName: options?.group }}
                />
            );
        }

        return null;
    }

    if (value.operator === 'dynamic_range') {
        return (
            <DynamicDateRangeField
                mode="edit"
                withLabel={false}
                value={value.value}
                id={data.field.ColumnName}
                onChange={(dynamicDateRange) => {
                    onChange(value, {
                        value: dynamicDateRange
                    });
                }}
                popoverContainerHtmlId="table_filter_menu"
            />
        );
    }

    if (value.operator === 'between') {
        return (
            <SmartDateRangeField
                value={value.value}
                onChange={(dynamicDateRange) => {
                    onChange(value, {
                        value: dynamicDateRange
                    });
                }}
            />
        );
    }

    return fieldEditRender({
        data: value.value,
        onChange: (key: string, localValue: any) => {
            onChange(value, {
                value: localValue
            });
        },
        language: i18n.language,
        isEditable: true,
        metaFieldData: value.field
    });
};
