import { CaretRightOutlined } from '@ant-design/icons';

interface CollapseIconProps {
    style: React.CSSProperties;
}

export const CollapseIcon: React.FC<CollapseIconProps> = ({ style }) => {
    return (
        <CaretRightOutlined
            className="collapse-icon"
            style={{
                ...style,
                transform: `rotate(${style.transform?.includes('-180') ? 90 : 0}deg)`
            }}
        />
    );
};
