import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ViewDisplayPreferenceSettings } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { MRT_ColumnFiltersState, MRT_FilterOption, MRT_SortingState } from 'material-react-table';
import { TableOptionType } from '../hooks/useTableOption';

export const setPaginationOptions = (
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings,
    prevOptions: TableOptionType
) => {
    if (viewDisplayPreferenceSettings?.page_size) {
        prevOptions = {
            ...prevOptions,
            pagination: {
                pageSize: viewDisplayPreferenceSettings.page_size,
                pageIndex: 0
            }
        };
    }
    return prevOptions;
};
export const setFilterOptions = (
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings,
    prevOptions: TableOptionType
): TableOptionType => {
    if (viewDisplayPreferenceSettings?.filter) {
        const filter: MRT_ColumnFiltersState = [];
        const filterFNS: {
            [key: string]: MRT_FilterOption;
        } = {};

        for (let i = 0; i < viewDisplayPreferenceSettings?.filter.length; i++) {
            const filterFromSetting = viewDisplayPreferenceSettings.filter[i];

            filter.push({
                id: filterFromSetting.id,
                value: filterFromSetting.value
            });

            filterFNS[filterFromSetting.id] = filterFromSetting.operator;
        }

        return {
            ...prevOptions,
            columnFilters: filter,
            columnFilterFns: filterFNS
        };
    }

    return prevOptions;
};

export const setComplexFilterOptions = (
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings,
    prevOptions: TableOptionType
): TableOptionType => {
    if (viewDisplayPreferenceSettings?.complexFilter) {
        return {
            ...prevOptions,
            complexFilter: viewDisplayPreferenceSettings.complexFilter || []
        };
    }

    return prevOptions;
};

export const setSortingOptions = (
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings,
    prevOptions: TableOptionType
): TableOptionType => {
    if (
        viewDisplayPreferenceSettings?.sort &&
        typeof viewDisplayPreferenceSettings?.sort === 'object'
    ) {
        const orderBy: MRT_SortingState = [];
        for (let i = 0; i < Object.keys(viewDisplayPreferenceSettings?.sort).length; i++) {
            const column = Object.keys(viewDisplayPreferenceSettings?.sort)[i];
            const direction = viewDisplayPreferenceSettings?.sort[column];

            orderBy.push({
                id: column,
                desc: direction === 'desc'
            });
        }

        return {
            ...prevOptions,
            sorting: orderBy
        };
    }

    return prevOptions;
};

export const setGroupingOptions = (
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings,
    prevOptions: TableOptionType
): TableOptionType => {
    if (
        viewDisplayPreferenceSettings?.grouping &&
        Array.isArray(viewDisplayPreferenceSettings?.grouping)
    ) {
        return {
            ...prevOptions,
            grouping: viewDisplayPreferenceSettings.grouping || []
        };
    }

    return prevOptions;
};

export const generateTableOptions = (
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings | null,
    defaultPageSize = 10
) => {
    let options: TableOptionType = {
        columnFilterFns: {},
        columnFilters: [],
        complexFilter: [],
        sorting: [],
        grouping: [],
        globalFilter: {},
        pagination: {
            pageIndex: 0,
            pageSize: viewDisplayPreferenceSettings?.page_size || defaultPageSize // использование значения по умолчанию, если page_size не установлен
        },
        initialized: false
    };

    if (viewDisplayPreferenceSettings) {
        options = setPaginationOptions(viewDisplayPreferenceSettings, options);
        options = setFilterOptions(viewDisplayPreferenceSettings, options);
        options = setComplexFilterOptions(viewDisplayPreferenceSettings, options);
        options = setSortingOptions(viewDisplayPreferenceSettings, options);
        options = setGroupingOptions(viewDisplayPreferenceSettings, options);
        options = { ...options, initialized: true };
    }
    return options;
};
