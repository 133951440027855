import { ColorPicker, Select } from 'antd';
import { memo, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { BaseField } from '../BaseField/BaseField';

const DEFAULT_ARBITARY_COLOR = '#F0FFFF';

type PredefinedColorName =
    | 'Neutral'
    | 'Blue'
    | 'Green'
    | 'Red'
    | 'Yellow'
    | 'Gray'
    | 'Black'
    | 'Brown'
    | 'Arbitrary';

type ColorType = Record<PredefinedColorName, string | null>;

export const PREDEFINED_COLORS: ColorType = {
    Neutral: '#F6F9FC',
    Blue: '#3185D2',
    Gray: '#A9B3BC',
    Green: '#14AD6D',
    Brown: '#964B00',
    Red: '#D71A21',
    Yellow: '#FFBB0B',
    Black: '#374254',
    Arbitrary: null
};

interface PredefinedColorFieldProps {
    id: string;
    value: string | null; // Color string format, e.g. '#ff0000'
    mode: 'view' | 'edit';
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    withLabel?: boolean;
}

export const PredefinedColorField = memo<PredefinedColorFieldProps>(
    ({
        id,
        value,
        mode,
        required = false,
        onChange = () => {},
        skipBlur = false,
        withLabel = true
    }) => {
        const { t } = useTranslation();

        const isColorIsPredefined = useCallback(
            (colorValue: string | undefined | null): boolean => {
                if (!colorValue) {
                    return false;
                }

                const findColor = Object.keys(PREDEFINED_COLORS).find(
                    (color) => (PREDEFINED_COLORS as Record<string, any>)[color] === colorValue
                );

                if (findColor) {
                    return true;
                }

                return false;
            },
            []
        );

        const showColorPicker = useMemo(() => {
            return !isColorIsPredefined(value);
        }, [isColorIsPredefined, value]);

        const options = useMemo(() => {
            return Object.keys(PREDEFINED_COLORS).map((color) => {
                return (
                    <Select.Option
                        value={
                            color === 'Arbitrary'
                                ? isColorIsPredefined(value)
                                    ? DEFAULT_ARBITARY_COLOR
                                    : value
                                : (PREDEFINED_COLORS as Record<string, any>)[color]
                        }
                        label={t(color)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {t(color)}

                            {color !== 'Arbitrary' && (
                                <div
                                    style={{
                                        backgroundColor: (PREDEFINED_COLORS as Record<string, any>)[
                                            color
                                        ],
                                        width: '20px',
                                        height: '20px'
                                    }}
                                />
                            )}
                        </div>
                    </Select.Option>
                );
            });
        }, [isColorIsPredefined, value, t]);

        const renderInput = useCallback(
            () => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        id={`${id}-value`}
                        value={value}
                        optionLabelProp="label"
                        onChange={(colorValue) => {
                            if (isColorIsPredefined(colorValue)) {
                                onChange(colorValue);
                            } else {
                                onChange(DEFAULT_ARBITARY_COLOR);
                            }
                        }}
                    >
                        {options}
                    </Select>

                    <ColorPicker
                        style={{ marginLeft: '1rem' }}
                        size="large"
                        disabled={!showColorPicker}
                        value={value ?? ''}
                        onChange={(value) => {
                            if (onChange) {
                                onChange(value.toHexString().toLocaleUpperCase());
                            }
                        }}
                    />
                </div>
            ),
            [id, value, options, showColorPicker, isColorIsPredefined, onChange]
        );

        const renderView = useCallback(
            (viewValue: string) => (
                <div
                    style={{
                        backgroundColor: viewValue,
                        width: '50px',
                        height: '20px'
                    }}
                />
            ),
            []
        );

        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
