import { Tooltip, Typography } from 'antd';
import { useRef, useState } from 'react';

export const SmartTooltip: React.FC<React.PropsWithChildren<{ style?: React.CSSProperties }>> = ({
    children,
    style
}) => {
    // const ref = useRef<HTMLSpanElement>(null);

    // console.log(
    //     children,
    //     ref?.current?.clientWidth,
    //     ref?.current?.scrollWidth,
    //     ref.current?.parentElement?.clientWidth,
    //     ref?.current?.parentElement?.scrollWidth
    // );
    // const [truncated, setTruncated] = useState<React.ReactNode>(undefined);

    // const hasBrowserTitle =
    //     typeof children === 'string' &&
    //     !ref.current?.parentElement?.clientWidth &&
    //     !ref?.current?.parentElement?.scrollWidth;

    return (
        // <Tooltip
        //     open={
        //         ref?.current?.parentElement?.clientWidth ===
        //         ref?.current?.parentElement?.scrollWidth
        //             ? false
        //             : undefined
        //     }
        //     mouseEnterDelay={0.7}
        //     destroyTooltipOnHide
        //     title={children}
        // >
        // <Tooltip title={truncated}>
        // {/* <span
        //     title={hasBrowserTitle ? children : undefined}
        //     // style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        //     ref={ref}
        // > */}
        <Typography.Paragraph
            ellipsis={{
                tooltip: { children, overlayStyle: { maxWidth: 'none' } }
                // onEllipsis: (isTruncated: boolean) =>
                //     setTruncated(isTruncated ? children : undefined)
            }}
            style={{ marginBottom: 0, ...style }}
        >
            {children}
        </Typography.Paragraph>
        // </Tooltip>
        // {/* </span> */}
        // </Tooltip>
    );
};
