import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Descriptions, DescriptionsProps, Input, InputNumber, Modal } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StringField } from 'components/fields';
import {
    Action,
    HandlerParam,
    HandlerRun,
    MetaField
} from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';
import { camelize } from 'utils';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { Response } from 'modules/services/backend-api/generated_api';
import { ANY_DATA } from 'modules/services/backend-api/generated_types';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { IHandlerWithId } from 'smart/modules/SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';
import { parseValueType } from 'smart/utils';

import { FilePickerField, PasswordField, SmartDateRangeField, SmartMultilanguageField } from '..';
import { LogMessagesTable } from '../LogMessagesTable/LogMessagesTable';
import { SmartDateField } from '../SmartDateField/SmartDateField';
import { SmartDatetimeRangeField } from '../SmartDatetimeRangeField/SmartDatetimeRangeField';
import { SmartDurationField } from '../SmartDurationField/SmartDurationField';
import { SmartMultiSelectField } from '../SmartMultiSelectField/SmartMultiSelectField';
import { SmartSelectField } from '../SmartSelectField/SmartSelectField';
import { useChooseFieldsModal } from './ui/useChooseFieldsModal';

interface HandlerRunModal {
    open: boolean;
    onCancel: () => void;
    action: Action | null;
    ids: string[];
    metaName: string;
    onRefresh: () => void;
    onResponseCallback?: (response: (HandlerRun | undefined)[]) => void;
    setSelectedRows?: (rows: any[]) => void;
    // filters?: FilterField[] | Filter[]; // filters for navigate if exists
    filters?: FilterField[]; // filters for navigate if exists
    // navigate function if need after run
    navigateAfterRun?: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    row?: any;
    meta?: string;
}

const getParamCode = (param: HandlerParam) => {
    const paramCode = param?.ParamName || camelize(param.Name?.en) || `p${param.ChildIndex}`;

    return paramCode;
};

export const HandlerRunModal = observer<HandlerRunModal>(
    ({
        open,
        onCancel,
        action,
        ids,
        metaName,
        onResponseCallback,
        setSelectedRows,
        onRefresh,
        filters,
        navigateAfterRun,
        row,
        meta
    }) => {
        const data = action?.Handler as IHandlerWithId;

        const [choosenFields, setChoosenFields] = useState<HandlerParam[]>([]);
        const { chooseFieldsModal, openModal } = useChooseFieldsModal({
            meta: metaName,
            setData: setChoosenFields,
            selectedIds: ids
        });

        useEffect(() => {
            setChoosenFields([]);
        }, [ids]);

        const isMassUpdate = data?.Code === 'MassUpdate';

        const params = useMemo(() => {
            if (choosenFields?.length > 0) return choosenFields;

            if (data) return data?.ChildParams;

            return [];
        }, [data, choosenFields]);

        const [logMessages, setLogMessages] = useState<LogMessage[]>([]);
        const [confirmLoading, setConfirmLoading] = useState(false);

        const isMessagesIsset = logMessages.length > 0;

        const { openNotify } = useNotifications({
            type: 'success',
            message: 'handler_responce_success',
            duration: 4
        });

        const {
            t,
            i18n: { language }
        } = useTranslation();

        const modalTitle = data && data?.Name ? data.Name[language] : t(data?.Code || 'handler');

        const [args, setArgs] = useState<ANY_DATA>({});

        useEffect(() => {
            const defaultArgsData: ANY_DATA = {};

            for (let i = 0; i < params.length; i++) {
                const param = params[i];
                const paramCode = getParamCode(param);

                if (param.DefaultValue) {
                    if (param.DefaultValue.includes('false')) {
                        defaultArgsData[paramCode] = false;
                    } else if (param.DefaultValue.includes('true')) {
                        defaultArgsData[paramCode] = true;
                    } else defaultArgsData[paramCode] = param.DefaultValue.replaceAll('"', '');
                }
            }

            setArgs(defaultArgsData);
        }, [params]);

        const handleRun = useCallback(async () => {
            const withEmptyArgs = { ...args };

            // только для массового редактирования
            if (choosenFields.length > 0) {
                choosenFields.forEach((field) => {
                    if (!withEmptyArgs[field.ParamName]) {
                        withEmptyArgs[field.ParamName] = null;
                    }
                });
            }

            setConfirmLoading(true);
            let responseLogMessagesIsset = false;
            if (data) {
                const response = await metaStore.makeRun({
                    Action_Id: data.Id,
                    meta: metaName,
                    ids: ids.map((id) => id.toString()),
                    handler: data.Code,
                    args: withEmptyArgs
                });

                console.log('[HandlerRunModal] action response:', response);

                if (response?.run && onResponseCallback) {
                    onResponseCallback(response.run);
                }

                if (response?.run?.[0]?.Status === 'EXECUTION_SUCCEEDED') {
                    openNotify({
                        description: response?.error_text || modalTitle,
                        message: t('handler_response_success')
                    });
                }

                if (response?.run?.[0]?.Status === 'EXECUTION_FAILED') {
                    openNotify({
                        description: response?.error_text || modalTitle,
                        message: t('handler_response_fail'),
                        type: 'error'
                    });
                } else if (response?.error_text) {
                    openNotify({
                        description: response?.error_text,
                        message: t('handler_response_fail'),
                        type: 'error'
                    });
                }

                if (response?.log_messages) {
                    if (response.log_messages.length > 0) {
                        responseLogMessagesIsset = true;
                        // console.log(response);
                        setLogMessages(response.log_messages!);
                    }
                }

                setConfirmLoading(false);

                // После выполнения экшна надо сделать обязательно рефреш данных!!
                onRefresh();

                if (navigateAfterRun && response && action && action.NavItem)
                    navigateAfterRun(response, action, filters || []);

                if (setSelectedRows) setSelectedRows([]);
            }

            setChoosenFields([]);
            if (!responseLogMessagesIsset) {
                onCancel();
            }
        }, [
            args,
            choosenFields,
            data,
            metaName,
            ids,
            onResponseCallback,
            onRefresh,
            navigateAfterRun,
            action,
            filters,
            setSelectedRows,
            openNotify,
            modalTitle,
            t,
            onCancel
        ]);

        const metaInfo = useMemo(() => {
            if (meta) return toJS(metaStore.meta.get(meta)?.info);
            return undefined;
        }, [meta]);

        const fields = useMemo(() => {
            const fields: DescriptionsProps['items'] = [];

            console.log(params);

            for (const param of params.sort((a, b) => (a.ChildIndex || 0) - (b.ChildIndex || 0))) {
                if (param.IsRequested) {
                    const paramCode = getParamCode(param);

                    const { type: fieldType, options } = parseValueType(param.ValueType, {
                        root: { ...row, ids },
                        current: { ...args, ids },
                        info: metaInfo,
                        self: args[paramCode]
                    });

                    const label = param.Name ? param.Name[language] : paramCode;

                    if (fieldType.includes('password')) {
                        const confirmPassParamName = 'confirm_password';

                        if (paramCode === confirmPassParamName) continue;

                        const confirmPassParam = params.find(
                            ({ ParamName }) => ParamName === confirmPassParamName
                        );

                        fields.push({
                            key: paramCode,
                            label: undefined,
                            children: (
                                <PasswordField
                                    confirmValue={
                                        confirmPassParam ? args[confirmPassParamName] : undefined
                                    }
                                    onConfirmChange={
                                        confirmPassParam
                                            ? (value) =>
                                                  setArgs((prev) => ({
                                                      ...prev,
                                                      [confirmPassParamName]: value
                                                  }))
                                            : undefined
                                    }
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    labels={{
                                        newPassword: label,
                                        confirmPassword: confirmPassParam
                                            ? confirmPassParam?.Name?.[language] ||
                                              getParamCode(confirmPassParam)
                                            : undefined
                                    }}
                                />
                            )
                        });
                        continue;
                    }

                    if (fieldType.includes('multilang_text')) {
                        const fieldName =
                            param.Name[language || 'en'] ||
                            t((param.ParamName || '')?.toLowerCase());

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartMultilanguageField
                                    fieldName={fieldName}
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                />
                            )
                        });
                        continue;
                    }

                    if (fieldType.substring(0, 2) === '[]') {
                        let metaParam = metaName;

                        if (options && options.ref) {
                            metaParam = options.ref.meta;
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartMultiSelectField
                                    value={args[paramCode]}
                                    metaName={metaParam}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    treeOptions={{
                                        parentFieldName: options?.group
                                    }}
                                />
                            )
                        });
                        continue;
                    }

                    if (fieldType.includes('int')) {
                        if (
                            data?.Code === 'doc_trips.create_execution_event' &&
                            paramCode === 'stop_num'
                        ) {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartSelectField
                                        value={args.StopNum}
                                        meta="DocTripStops"
                                        filters={`Root=eq.${ids[0]}`}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value?.StopNum,
                                                StopNum: value
                                            }))
                                        }
                                        myViewFieldName="StopNum"
                                    />
                                )
                            });

                            continue;
                        }
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%' }}
                                    value={args[paramCode]}
                                    onChange={(v) => {
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: v
                                        }));
                                    }}
                                />
                            )
                        });
                        continue;
                    }

                    if (fieldType.includes('file')) {
                        const isAttachments = options?.bucket === 'attachments';

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <FilePickerField
                                    // root_id={ids.join('_')}
                                    // meta={meta || ''}
                                    path={
                                        isAttachments
                                            ? `${meta}/${ids.join('_')}`
                                            : options?.path || ''
                                    }
                                    value={{
                                        file_path: args[paramCode],
                                        file_name: args.file_name,
                                        file_size: args.file_size
                                    }}
                                    onChange={(value) => {
                                        setArgs((prevArgs) => ({
                                            ...prevArgs,
                                            file_path: value.file_path,
                                            file_size: value.file_size
                                        }));
                                    }}
                                    onChangeExternalFileName={(value) => {
                                        setArgs((prevArgs) => ({
                                            ...prevArgs,
                                            file_name: value
                                        }));
                                    }}
                                    bucket={options?.bucket}
                                />
                            )
                        });
                        continue;
                    }

                    switch (fieldType.replaceAll('*', '')) {
                        case 'date': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartDateField
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                        utc={false}
                                    />
                                )
                            });
                            break;
                        }
                        case 'datetime': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartDateField
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                        utc={false}
                                        showTime
                                    />
                                )
                            });
                            break;
                        }
                        case 'text': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <Input
                                        placeholder={t('no_value') as string}
                                        value={args[paramCode]}
                                        onChange={(e) => {
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: e.target.value
                                            }));
                                        }}
                                    />
                                )
                            });
                            break;
                        }

                        case 'datetimerange': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartDatetimeRangeField
                                        value={args[paramCode]}
                                        onChange={(dynamicDateRange) => {
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: dynamicDateRange
                                            }));
                                        }}
                                    />
                                )
                            });
                            break;
                        }
                        case 'daterange': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartDateRangeField
                                        value={args[paramCode]}
                                        onChange={(dynamicDateRange) => {
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: dynamicDateRange
                                            }));
                                        }}
                                    />
                                )
                            });
                            break;
                        }
                        case 'string': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <StringField
                                        id={paramCode}
                                        htmlId={paramCode}
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                        withLabel={false}
                                        mode="edit"
                                    />
                                )
                            });
                            break;
                        }
                        case 'int': {
                            if (
                                data?.Code === 'doc_trips.create_execution_event' &&
                                paramCode === 'stop_num'
                            ) {
                                fields.push({
                                    key: paramCode,
                                    label,
                                    children: (
                                        <SmartSelectField
                                            value={args.StopNum}
                                            meta="DocTripStops"
                                            filters={`Root=eq.${ids[0]}`}
                                            onChange={(value) =>
                                                setArgs((prev) => ({
                                                    ...prev,
                                                    [paramCode]: value?.StopNum,
                                                    StopNum: value
                                                }))
                                            }
                                            myViewFieldName="StopNum"
                                        />
                                    )
                                });

                                break;
                            }

                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <InputNumber
                                        placeholder={t('no_value') as string}
                                        style={{ width: '100%', maxWidth: 170 }}
                                        defaultValue={options ? Number(options.default) : undefined}
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                    />
                                )
                            });
                            break;
                        }
                        case 'number': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <InputNumber
                                        placeholder={t('no_value') as string}
                                        style={{ width: '100%', maxWidth: 170 }}
                                        defaultValue={options ? Number(options.default) : undefined}
                                        value={args[paramCode]}
                                        step="0.05"
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                    />
                                )
                            });
                            break;
                        }
                        case 'bool': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <Checkbox
                                        defaultChecked={!!(options && options.default === 'true')}
                                        checked={args[paramCode]}
                                        onChange={(e) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: e.target.checked
                                            }))
                                        }
                                    ></Checkbox>
                                )
                            });
                            break;
                        }
                        case 'boolean': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <Checkbox
                                        defaultChecked={!!(options && options.default === 'true')}
                                        checked={args[paramCode]}
                                        onChange={(e) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: e.target.checked
                                            }))
                                        }
                                    ></Checkbox>
                                )
                            });
                            break;
                        }
                        case 'duration': {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartDurationField
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                    />
                                )
                            });
                            break;
                        }
                        case 'key':
                        case 'id':
                        case 'object_id':
                        case 'code': {
                            if (
                                data?.Code === 'doc_trips.create_execution_event' &&
                                paramCode === 'service_id'
                            ) {
                                const stopNumFilter = args.stop_num
                                    ? `&StopNum=eq.${args.stop_num}`
                                    : '';

                                fields.push({
                                    key: paramCode,
                                    label,
                                    children: (
                                        <SmartSelectField
                                            value={args[paramCode]}
                                            disabled={!args.stop_num}
                                            meta="DocTripOperations"
                                            filters={`Root=eq.${ids[0]}${stopNumFilter}`}
                                            onChange={(value) =>
                                                setArgs((prev) => ({
                                                    ...prev,
                                                    [paramCode]: value
                                                }))
                                            }
                                            myViewFieldName="Service"
                                        />
                                    )
                                });

                                break;
                            }
                            if (options && options.ref) {
                                const { meta } = options.ref;

                                fields.push({
                                    key: paramCode,
                                    label,
                                    children: (
                                        <SmartSelectField
                                            value={args[paramCode]}
                                            meta={meta}
                                            filters={options.filters}
                                            onChange={(value) =>
                                                setArgs((prev) => ({
                                                    ...prev,
                                                    [paramCode]: value
                                                }))
                                            }
                                            treeOptions={{ parentFieldName: options?.group }}
                                            navigateMetaOptions={{ metaName: options.ref.meta }}
                                        />
                                    )
                                });
                            } else {
                                fields.push({
                                    key: paramCode,
                                    label,
                                    children: (
                                        <Input
                                            value={args[paramCode]}
                                            onChange={(value) =>
                                                setArgs((prev) => ({
                                                    ...prev,
                                                    [paramCode]: value
                                                }))
                                            }
                                        />
                                    )
                                });
                            }
                            break;
                        }
                        default: {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <Input
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                    />
                                )
                            });

                            break;
                        }
                    }
                }
            }

            return fields;
        }, [args, data?.Code, ids, language, meta, metaInfo, metaName, params, row, t]);

        const fieldsWithLabel = useMemo(() => fields.filter((f) => !!f.label), [fields]);
        const fieldsWithoutLabel = useMemo(() => fields.filter((f) => !f.label), [fields]);

        return (
            <>
                <Modal
                    open={open}
                    onCancel={() => {
                        onCancel();
                        setChoosenFields([]);
                    }}
                    onOk={handleRun}
                    destroyOnClose
                    afterClose={() => setLogMessages([])}
                    okText={navigateAfterRun && action?.NavItem ? t('follow') : t('run')}
                    confirmLoading={confirmLoading}
                    cancelText={t('cancel')}
                    footer={
                        isMessagesIsset ? (
                            <ButtonWithTooltips id="close" key="close" onClick={onCancel}>
                                {t('close')}
                            </ButtonWithTooltips>
                        ) : undefined
                    }
                    title={isMessagesIsset ? t('messages') : modalTitle}
                    width="75%"
                    centered
                >
                    {isMassUpdate && !isMessagesIsset && (
                        <ButtonWithTooltips
                            id="all_fields"
                            type="default"
                            className=""
                            icon={<PlusOutlined />}
                            style={{ marginRight: 'auto', marginBottom: 10 }}
                            onClick={openModal}
                        >
                            {t('all_fields')}
                        </ButtonWithTooltips>
                    )}
                    {isMessagesIsset ? (
                        <LogMessagesTable logMessages={logMessages} />
                    ) : (
                        <>
                            {fieldsWithLabel?.length ? (
                                <Descriptions
                                    bordered
                                    size="small"
                                    items={fields.filter((f) => !!f.label)}
                                    column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 }}
                                    labelStyle={{ width: '22%' }}
                                />
                            ) : null}
                            {fieldsWithoutLabel.map((f) => f.children)}
                        </>
                    )}
                </Modal>
                {chooseFieldsModal}
            </>
        );
    }
);
