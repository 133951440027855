import { App as AntdApp, Typography } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface UseNotificationsProps {
    message: string;
    description?: string;
    smallInfo?: string;
    placement?: NotificationPlacement;
    type?: NotificationType;
    duration?: number;
    descriptionContent?: React.ReactNode;
}

interface UseNotificationsReturnType {
    openNotify: (options?: UseNotificationsProps) => void;
}

export const useNotifications = ({
    message,
    description,
    smallInfo,
    duration = 0,
    placement = 'bottomRight',
    type = 'info'
}: UseNotificationsProps): UseNotificationsReturnType => {
    const antdApp = AntdApp.useApp();
    const { t } = useTranslation();

    const openNotify = useCallback(
        (options?: UseNotificationsProps) => {
            antdApp.notification.open({
                message: options?.message || message,
                description: (
                    <>
                        {options?.descriptionContent ? (
                            options?.descriptionContent
                        ) : (
                            <Typography.Text
                                style={{
                                    padding: 0,
                                    borderRadius: 0,
                                    whiteSpace: 'unset',
                                    overflow: 'unset',
                                    textOverflow: 'unset'
                                }}
                            >
                                {options?.description || description}
                            </Typography.Text>
                        )}

                        <br />
                        <Typography.Text
                            type="secondary"
                            style={{
                                fontSize: '11px',
                                padding: 0,
                                borderRadius: 0,
                                whiteSpace: 'unset',
                                overflow: 'unset',
                                textOverflow: 'unset'
                            }}
                        >
                            {options?.smallInfo ||
                                smallInfo ||
                                `${t('today')}, ${dayjs().format('HH:mm:ss')}`}
                        </Typography.Text>
                    </>
                ),
                placement: options?.placement || placement,
                className: `vectura-notifications vectura-notifications-${options?.type || type}`,
                duration
            });
        },
        [description, duration, message, placement, smallInfo, t, type]
    );

    return { openNotify };
};
