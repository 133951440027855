import dayjs from 'dayjs';

import { MRT_FilterOption } from 'material-react-table';

import {
    FilterField,
    FilterFieldValue,
    FilterOption
} from 'components/DataTable/TableFilterMenu/TableFilterMenuTypes';
import { isArrayIdType, isRangeType } from 'components/DataTable/TableFilterMenu/TableFilterUtils';

import { getDateRangeByDynamicDateRangeValue } from 'components/fields';
import { UserSpecificFormat } from 'utils/helpers/dates';
import { Filter } from './supabaseClient';
import { UIColumnType } from './tableCellUtils/FieldMapping';

type SupabaseFilterOperator =
    | 'eq'
    | 'neq'
    | 'gt'
    | 'gte'
    | 'lt'
    | 'lte'
    | 'like'
    | 'ilike'
    | 'is'
    | 'in'
    | 'cs'
    | 'cd'
    | 'sl'
    | 'sr'
    | 'nxl'
    | 'nxr'
    | 'adj'
    | 'ov'
    | 'fts'
    | 'plfts'
    | 'phfts'
    | 'wfts'
    | 'not_in'
    | MRT_FilterOption;

export const mapMUITableFilterOperationAndSupabaseFilterOperation = (
    muiFilterOperation: FilterOption
): SupabaseFilterOperator => {
    switch (muiFilterOperation) {
        // FIXME: а зачем мы делаем разные тексты - зачем мы свой собственный язык изобретаем если уже есть супабейзовский? надо его и использовать - а так мы сами себе сложность изобрели!
        case 'equals':
            return 'eq';
        case 'greaterThan':
            return 'gt';
        case 'greaterThanOrEqualTo':
            return 'gte';
        case 'lessThanOrEqualTo':
            return 'lte';
        case 'lessThan':
            return 'lt';
        case 'contains':
            return 'like';
        case 'notEmpty':
            return 'neq';
        case 'empty':
            return 'is';
        case 'betweenInclusive':
            return 'adj';
        case 'between':
            return 'adj';
        case 'notEquals':
            return 'neq';
        case 'endsWith':
            return 'ilike';
        case 'startsWith':
            return 'ilike';
        case 'fuzzy':
            return 'ilike';
        case 'fts':
            return 'fts';
        case 'in_list':
            return 'in';
        case 'not_in_list':
            return 'not.in';
        default:
            return muiFilterOperation;
    }
};
export const mapOurI18LanguagesAndSupabaseFullTextSearchConfig = (language: string) => {
    switch (language) {
        case 'ru':
            return 'russian';
        case 'en':
            return 'english';
        case 'kk':
            return 'simple';
        case 'az':
            return 'simple';
        case 'tr':
            return 'turkish';
        default:
            return 'simple';
    }
};

export const convertUIFilterColumnName = (
    columnName: string,
    operator: SupabaseFilterOperator,
    language: string,
    dataType?: UIColumnType
): string => {
    if (columnName.endsWith('short_title') || columnName.endsWith('long_title')) {
        return `${columnName}->>${language}`;
    }

    if (dataType === 'daterange') {
        // Если DateRange и опция меньше - фильтруем как меньше чем первая дата
        if (operator === 'lt' || operator === 'lte') {
            return `${columnName}->>from_date`;
        }

        // Если DateRange и опция больше - фильтруем как больше чем вторая дата
        if (operator === 'gt' || operator === 'gte') {
            return `${columnName}->>to_date`;
        }
    }

    if (dataType === 'datetimerange' || dataType === 'datetimelocalrange') {
        // Если DateTimeRange и опция меньше - фильтруем как меньше чем первая дата
        if (operator === 'lt' || operator === 'lte') {
            return `${columnName}->>from_datetime`;
        }

        // Если DateTimeRange и опция больше - фильтруем как больше чем вторая дата
        if (operator === 'gt' || operator === 'gte') {
            return `${columnName}->>to_datetime`;
        }
    }

    if (dataType === 'timerange') {
        // Если TimeRange и опция меньше - фильтруем как меньше чем первое время
        if (operator === 'lt' || operator === 'lte') {
            return `${columnName}->>from_time`;
        }

        // Если TimeRange и опция больше - фильтруем как больше чем второе время
        if (operator === 'gt' || operator === 'gte') {
            return `${columnName}->>to_time`;
        }
    }

    return columnName;
};

const convertDatetimeFilter = (dateTimeValue: string, utc: boolean) => {
    const date = dayjs(dateTimeValue).utc(utc);
    return date.format('YYYY-MM-DDTHH:mm:ss').toString();
};

export const convertUIFilterValue = (
    filterValue: unknown,
    columnName: string,
    language: string,
    operator: SupabaseFilterOperator,
    dataType?: UIColumnType
): unknown => {
    if (operator === 'like') {
        return `%${filterValue}%`;
    }

    // Фильтр по таким полям превращается в больше/меньше конкретной даты/время ИЛИ разбивается на два фильтра, поэтому сюда уже приходит не структура
    if (dataType && (dataType === 'datetimelocalrange' || dataType === 'datetimelocal')) {
        return convertDatetimeFilter(filterValue as string, true);
    }

    if (dataType && (dataType === 'datetimerange' || dataType === 'datetime')) {
        return convertDatetimeFilter(filterValue as string, false);
    }

    if (dataType && dataType === 'array_id') {
        return `{${filterValue}}`;
    }

    // if (filterValue.)
    return filterValue;
};

type ConvertFilterFunctionType = {
    complexFilter: FilterField;
    complexFilterValue: FilterFieldValue;
    language: string;
};

const convertBetweenFilter = (rawFilterSetting: ConvertFilterFunctionType): Filter => {
    const rangeKeys = Object.keys(rawFilterSetting.complexFilterValue.value);

    rangeKeys.sort();

    const lessThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('lessThanOrEqualTo');

    const filterValueTo: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            lessThanOrEqualTo,
            rawFilterSetting.language,
            rawFilterSetting.complexFilter.fieldSetting.data_type
        ),
        operator: lessThanOrEqualTo,
        value: rawFilterSetting.complexFilterValue.value[rangeKeys[1]]
    };

    const greaterThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('greaterThanOrEqualTo');

    const filterValueFrom: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            greaterThanOrEqualTo,
            rawFilterSetting.language,
            rawFilterSetting.complexFilter.fieldSetting.data_type
        ),
        operator: greaterThanOrEqualTo,
        value: rawFilterSetting.complexFilterValue.value[rangeKeys[0]],
        ANDCondition: filterValueTo
    };

    return filterValueFrom;
};

const convertIdArrayFilterWithContainsOptionToFilter = (
    rawFilterSetting: ConvertFilterFunctionType
): Filter => {
    const column = convertUIFilterColumnName(
        rawFilterSetting.complexFilter.field,
        rawFilterSetting.language,
        rawFilterSetting.complexFilter.fieldSetting.data_type
    );

    const filterValue: Filter = {
        column,
        operator: 'cs',
        value: rawFilterSetting.complexFilterValue.value[column.slice(0, -1)]
    };

    const value = convertUIFilterValue(
        filterValue.value,
        filterValue.column,
        rawFilterSetting.language,
        filterValue.operator,
        rawFilterSetting.complexFilter.fieldSetting.data_type
    );

    filterValue.value = value;

    return filterValue;
};

const convertInListToFilter = (rawFilterSetting: ConvertFilterFunctionType): Filter => {
    const inOperator = mapMUITableFilterOperationAndSupabaseFilterOperation(
        rawFilterSetting?.complexFilterValue?.operator as FilterOption
    );

    const valueArray = Array.isArray(rawFilterSetting.complexFilterValue.value)
        ? rawFilterSetting.complexFilterValue.value.map((val) => {
              if (rawFilterSetting.complexFilter.field.endsWith('code')) {
                  return val.code;
              }

              if (rawFilterSetting.complexFilter.field.endsWith('id')) {
                  return val.id;
              }

              if (rawFilterSetting.complexFilter.field.endsWith('key')) {
                  return val.key;
              }

              return val.id;
          })
        : [];

    return {
        column: rawFilterSetting.complexFilter.field,
        operator: inOperator,
        value: `(${valueArray.join(',')})`
    };
};

const convertNotInListToFilter = (rawFilterSetting: ConvertFilterFunctionType): Filter => {
    const inOperator = mapMUITableFilterOperationAndSupabaseFilterOperation(
        rawFilterSetting?.complexFilterValue?.operator as FilterOption
    );

    const valueArray = Array.isArray(rawFilterSetting.complexFilterValue.value)
        ? rawFilterSetting.complexFilterValue.value.map((val) => {
              if (rawFilterSetting.complexFilter.field.endsWith('code')) {
                  return val.code;
              }

              if (rawFilterSetting.complexFilter.field.endsWith('id')) {
                  return val.id;
              }

              if (rawFilterSetting.complexFilter.field.endsWith('key')) {
                  return val.key;
              }

              return val.id;
          })
        : [];

    return {
        column: rawFilterSetting.complexFilter.field,
        operator: inOperator,
        value: `(${valueArray.join(',')})`
    };
};

const convertDynamicDateRangeToFiler = (rawFilterSetting: ConvertFilterFunctionType): Filter => {
    const rangeDates = getDateRangeByDynamicDateRangeValue(
        rawFilterSetting.complexFilterValue.value
    );

    let toValue = ''; // dayjs(rangeDates.to).local().format('YYYY-MM-DD').toString();
    let fromValue = ''; // dayjs(rangeDates.from).local().format('YYYY-MM-DD').toString();

    // const rangeKeys = Object.keys(rawFilterSetting.complexFilterValue.value);
    const lessThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('lessThanOrEqualTo');

    const filterValueTo: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            lessThanOrEqualTo,
            rawFilterSetting.language,
            rawFilterSetting.complexFilter.fieldSetting.data_type
        ),
        operator: lessThanOrEqualTo,
        value: null
    };

    const greaterThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('greaterThanOrEqualTo');

    const filterValueFrom: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            greaterThanOrEqualTo,
            rawFilterSetting.language,
            rawFilterSetting.complexFilter.fieldSetting.data_type
        ),
        operator: greaterThanOrEqualTo,
        value: null,
        ANDCondition: filterValueTo
    };

    if (rangeDates) {
        if (rawFilterSetting.complexFilter.fieldSetting.data_type === 'date') {
            fromValue = dayjs(rangeDates.from).local().format('YYYY-MM-DD').toString();
            toValue = dayjs(rangeDates.to).local().format('YYYY-MM-DD').toString();
        }

        if (rawFilterSetting.complexFilter.fieldSetting.data_type === 'datetime') {
            fromValue = dayjs(rangeDates.from)
                .local()
                .format(UserSpecificFormat.getDateTimeFormat())
                .toString();
            toValue = dayjs(rangeDates.to)
                .local()
                .format(UserSpecificFormat.getDateTimeFormat())
                .toString();
        }

        if (rawFilterSetting.complexFilter.fieldSetting.data_type === 'datetimelocal') {
            fromValue = dayjs(rangeDates.from)
                .local()
                .format(UserSpecificFormat.getDateTimeLocalFormat())
                .toString();
            toValue = dayjs(rangeDates.to)
                .local()
                .format(UserSpecificFormat.getDateTimeLocalFormat())
                .toString();
        }
    }

    filterValueFrom.value = fromValue;
    filterValueTo.value = toValue;

    return filterValueFrom;
};
const convertRangeFieldWithContainOptionToFilter = (
    rawFilterSetting: ConvertFilterFunctionType
): Filter => {
    const lessThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('lessThanOrEqualTo');

    const filterValueFrom: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            lessThanOrEqualTo,
            rawFilterSetting.language,
            rawFilterSetting.complexFilter.fieldSetting.data_type
        ),
        operator: lessThanOrEqualTo,
        value: rawFilterSetting.complexFilterValue.value
    };

    filterValueFrom.value = convertUIFilterValue(
        filterValueFrom.value,
        filterValueFrom.column,
        rawFilterSetting.language,
        filterValueFrom.operator,
        rawFilterSetting.complexFilter.fieldSetting.data_type
    );

    const greaterThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('greaterThanOrEqualTo');

    const filterValueTo: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field,
            greaterThanOrEqualTo,
            rawFilterSetting.language,
            rawFilterSetting.complexFilter.fieldSetting.data_type
        ),
        operator: greaterThanOrEqualTo,
        value: rawFilterSetting.complexFilterValue.value,
        ANDCondition: filterValueFrom
    };

    filterValueTo.value = convertUIFilterValue(
        filterValueTo.value,
        filterValueTo.column,
        rawFilterSetting.language,
        filterValueTo.operator,
        rawFilterSetting.complexFilter.fieldSetting.data_type
    );

    return filterValueTo;
};

// Функция преобразует complex filter к supabase формату
export const convertComplexFilterToSupabaseFilter = (
    complexFilters: FilterField[],
    language: string
): Filter[] => {
    const filter: Filter[] = [];

    for (let i = 0; i < complexFilters.length; i++) {
        const complexFilter = complexFilters[i];

        const dataType: UIColumnType = complexFilter.fieldSetting.data_type;

        for (let j = 0; j < complexFilter.values.length; j++) {
            const complexFilterValue = complexFilter.values[j];

            if (complexFilterValue.operator) {
                // Обработка оператора in_list
                if (complexFilterValue.operator === 'in_list') {
                    const inListFilter = convertInListToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push(inListFilter);
                    continue;
                }

                // Обработка оператора not_in_list
                if (complexFilterValue.operator === 'not_in_list') {
                    const inListFilter = convertNotInListToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push(inListFilter);
                    continue;
                }

                // Обработка оператора dynamic_range
                if (complexFilterValue.operator === 'dynamic_range') {
                    const dynamicRangeFilter = convertDynamicDateRangeToFiler({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push(dynamicRangeFilter);
                    continue;
                }

                // Обработка поля с типом массив ID [1,2,3...]
                if (
                    isArrayIdType(complexFilter.fieldSetting.data_type) &&
                    complexFilterValue.operator === 'contains'
                ) {
                    const arrayIdContainsFilter = convertIdArrayFilterWithContainsOptionToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push(arrayIdContainsFilter);
                    continue;
                }

                // Обработка поля с типом RANGE с оператором contains
                if (
                    isRangeType(complexFilter.fieldSetting.data_type) &&
                    complexFilterValue.operator === 'contains'
                ) {
                    const rangeContainFilter = convertRangeFieldWithContainOptionToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push(rangeContainFilter);

                    continue;
                }

                // Обработка оператора between для полей с типом ДАТА / ДАТАВРЕМЯ
                if (complexFilterValue.operator === 'between') {
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push(betweenFilter);

                    continue;
                }

                // Обработка всех остальных полей
                const operator = mapMUITableFilterOperationAndSupabaseFilterOperation(
                    complexFilterValue.operator
                );

                const filterValue: Filter = {
                    column: convertUIFilterColumnName(
                        complexFilter.field,
                        operator,
                        language,
                        complexFilter.fieldSetting.data_type
                    ),
                    operator,
                    value: null
                };

                // Обработка опции пусто/не пусто
                if (
                    complexFilterValue.operator === 'empty' ||
                    complexFilterValue.operator === 'notEmpty'
                ) {
                    filter.push(filterValue);
                    continue;
                }

                // По dataType определяем какое значение положить в фильтр
                switch (dataType) {
                    case 'multilang_text':
                        filterValue.value = complexFilterValue.value[language];
                        break;
                    case 'code':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    case 'id':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    case 'key':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    case 'status':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    case 'type':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    case 'userid':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    case 'flow':
                        filterValue.value = complexFilterValue.value[filterValue.column];
                        break;
                    default:
                        filterValue.value = complexFilterValue.value;
                        break;
                }

                filterValue.value = convertUIFilterValue(
                    filterValue.value,
                    filterValue.column,
                    language,
                    filterValue.operator,
                    complexFilter.fieldSetting.data_type
                );

                filter.push(filterValue);
            }
        }
    }
    return filter;
};
