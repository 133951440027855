import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
// import type { RangeValue } from 'rc-picker/lib/interface';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSpecificFormat } from 'utils/helpers/dates';
import { BaseField } from '../BaseField/BaseField';

type DateRange = {
    from_date: string | null;
    to_date: string | null;
};

type DateTimeRange = {
    from_datetime: string | null;
    to_datetime: string | null;
};

type RangeValue<T> = [T | null, T | null];

interface DateRangeFieldProps {
    id: string;
    value: DateRange | DateTimeRange | null;
    mode: 'view' | 'edit';
    onChange: (newValue: DateRange) => void;
    popoverContainerHtmlId?: string;
    withLabel?: boolean;
    htmlId?: string;
    required?: boolean;
    returnLocalDateTimeAllDay?: boolean; // DateRange может работать с DateTimeRange типами если задан параметр returnLocalDateTimeAllDay
    // Если задан returnLocalDateTimeAllDay то на вход и на выход ожидаем {from_datetime, to_datetime}
    // В таком случае дата время возвращается от 00:00:00 первого дня до 23:59:59 последнего(без учета часовой зоны)
    // utc?: boolean;
    skipBlur?: boolean;
    isFullSizeLabel?: boolean;
}

// Экспорт компонента DateRangeField
export const DateRangeField = memo<DateRangeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        htmlId = id,
        withLabel = true,
        required = false,
        onChange = () => {},
        returnLocalDateTimeAllDay,
        skipBlur = false,
        isFullSizeLabel = false
    }) => {
        const { t } = useTranslation();

        // Функция определяет с какой структурой мы работаем в зависимости от returnLocalDateTimeAllDay(DateRange или DateTimeRange)
        const resolveDateValue = useCallback(
            (dateOrDateTimeValue: DateRange | DateTimeRange): RangeValue<Dayjs> => {
                let value: RangeValue<Dayjs> = [null, null];

                if (returnLocalDateTimeAllDay) {
                    const dateTimeRangeValue = dateOrDateTimeValue as DateTimeRange;
                    value = [
                        dateTimeRangeValue && dateTimeRangeValue.from_datetime
                            ? dayjs(dateTimeRangeValue.from_datetime)
                            : null,
                        dateTimeRangeValue && dateTimeRangeValue.to_datetime
                            ? dayjs(dateTimeRangeValue.to_datetime)
                            : null
                    ];
                } else {
                    const dateRangeValue = dateOrDateTimeValue as DateRange;
                    value = [
                        dateRangeValue && dateRangeValue.from_date
                            ? dayjs(dateRangeValue.from_date)
                            : null,
                        dateRangeValue && dateRangeValue.to_date
                            ? dayjs(dateRangeValue.to_date)
                            : null
                    ];
                }

                return value;
            },
            [returnLocalDateTimeAllDay]
        );

        // Определение функции для рендеринга компонента выбора диапазона дат
        const renderInput = useCallback(
            (
                inputValue: DateRange | DateTimeRange,
                onInputChange?: (
                    newValue:
                        | { from_date: string | null; to_date: string | null }
                        | { from_datetime: string | null; to_datetime: string | null }
                ) => void,
                onBlur?: () => void
            ) => {
                const value = resolveDateValue(inputValue);

                return (
                    // DatePicker.RangePicker подразумевает, что пользователь выбирает две даты, если одна из дат не выбрана, то возвращается null

                    <DatePicker.RangePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-edit`}
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        // Установка значения диапазона дат в компоненте выбора диапазона дат
                        value={value}
                        // Обработка изменения значения диапазона дат в компоненте выбора диапазона дат
                        onChange={(dates) => {
                            if (!onInputChange || !onBlur) return;

                            let [from_date, to_date]: [dayjs.Dayjs | null, dayjs.Dayjs | null] =
                                dates || [null, null];

                            // Если проставлен returnLocalDateTimeAllDay то проставляем время и возвращаем DateTimeRange
                            if (returnLocalDateTimeAllDay) {
                                if (from_date) {
                                    from_date = from_date.local().set('hours', 0);
                                    from_date = from_date.local().set('minutes', 0);
                                    from_date = from_date.local().set('seconds', 0);
                                }

                                if (to_date) {
                                    to_date = to_date.local().set('hours', 23);
                                    to_date = to_date.local().set('minutes', 59);
                                    to_date = to_date.local().set('seconds', 59);
                                }

                                onInputChange({
                                    from_datetime:
                                        from_date &&
                                        from_date.local().format('YYYY-MM-DD HH:mm:ss').toString(),
                                    to_datetime:
                                        to_date &&
                                        to_date.local().format('YYYY-MM-DD HH:mm:ss').toString()
                                });

                                onBlur();
                            } else {
                                // Иначе возвращаем DateRange
                                onInputChange({
                                    from_date:
                                        from_date &&
                                        from_date.local().format('YYYY-MM-DD').toString(),
                                    to_date:
                                        to_date && to_date.local().format('YYYY-MM-DD').toString()
                                });

                                onBlur();
                            }
                        }}
                        onPickerValueChange={() => {}}
                        format={UserSpecificFormat.getDateFormat()}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                        inputReadOnly
                        allowClear
                    />
                );
            },
            [resolveDateValue, htmlId, t, popoverContainerHtmlId, returnLocalDateTimeAllDay]
        );

        // Определение функции для рендеринга компонента просмотра диапазона дат
        const renderView = useCallback(
            (viewValue: DateRange | DateTimeRange) => {
                const value = resolveDateValue(viewValue);

                return (
                    <DatePicker.RangePicker
                        id={`${htmlId}-value`}
                        data-testid={`${htmlId}-view`}
                        placeholder={[t('no_value_start'), t('no_value_end')]}
                        value={value}
                        format={UserSpecificFormat.getDateFormat()}
                        variant="borderless"
                        allowClear={false}
                        open={false}
                        inputReadOnly
                        suffixIcon={<></>}
                    />
                );
            },
            [resolveDateValue, htmlId, t]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
                isFullSizeLabel={isFullSizeLabel}
            />
        );
    }
);
