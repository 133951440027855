import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { Dataset } from '../../supabase/types/Dataset';
import { callBackendFunction } from './BackendFunctions';

type DatasetForSave = Omit<
    Dataset,
    'root_id' | 'mode' | 'tempCounter' | 'getNextTempId' | 'toJSON'
>;

type SaveMultiDatasetResponse = { id: number } | null;

export async function saveDataset(
    dataset: DatasetForSave
): Promise<{ data: number | null; error: Error | null }> {
    const endpoint = 'save-dataset';
    const { data, error } = await callBackendFunction<SaveMultiDatasetResponse>(endpoint, dataset);
    if (error) {
        return { data: null, error: new Error(makeErrorReadable(error.message)) };
    }
    if (!data) {
        return { data: null, error: new Error(makeErrorReadable('no_data_returned')) };
    }
    if (!data.id) {
        return { data: null, error: new Error(makeErrorReadable('no_id_returned')) };
    }

    console.log('callBackendFunction(): data', data);
    if (typeof data.id === 'number' && data.id < 1) {
        return { data: null, error: new Error(makeErrorReadable('id_is_less_than_1')) };
    }
    if (typeof data.id === 'object') {
        return { data: data.id[0], error: null }; /// // сделал чтобы если id массив вида [1] то возвращалось именно значение 1
    }
    return { data: data.id, error: null };
}
