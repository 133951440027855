import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import {
    supabaseClient
} from '../../modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Database } from '../../modules/supabase/types/database.types';

type MeasureUnitType = Database['public']['Views']['vdicts_measure_units_with_base_unit']['Row'];

type measureValueUnitConvertType = (
    measure_value: number,
    source_measure_unit_code: string,
    target_measure_unit_code: string
) => number;

type measureValueUnitConvertInBaseUnitType = (
    measure_value: number,
    source_measure_unit_code: string
) => number;

type MeasureUnitContextType = {
    units: MeasureUnitType[];
    measureValueUnitConvert: measureValueUnitConvertType;
    measureValueUnitConvertInBaseUnit: measureValueUnitConvertInBaseUnitType;
};

export const MeasureUnitCalculationContext = createContext<MeasureUnitContextType>({
    units: [],
    measureValueUnitConvert: () => {
        return 1;
    },
    measureValueUnitConvertInBaseUnit: () => {
        return 1;
    }
});

export const MeasureUnitCalculationProvider = ({ children }: { children: ReactNode }) => {
    const [units, setUnits] = useState<MeasureUnitType[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        const fetchData = async () => {
            const { data, error } = await supabaseClient
                .from('vdicts_measure_units_with_base_unit')
                .select('*')
                .abortSignal(controller.signal);

            if (error) {
                setUnits([]);
                return;
            }

            console.log('UNITS DATA:', data);
            setUnits(data);
        };

        fetchData();

        return () => {
            controller.abort();
        };
    }, []);

    const measureValueUnitConvert: measureValueUnitConvertType = useCallback(
        (
            measure_value: number,
            source_measure_unit_code: string,
            target_measure_unit_code: string
        ): number => {
            // Находим ЕИ из справочника
            const sourceUnit = units.find(
                (unit) => unit.measure_unit_code === source_measure_unit_code
            );
            const targetUnit = units.find(
                (unit) => unit.measure_unit_code === target_measure_unit_code
            );

            if (
                !sourceUnit ||
                !targetUnit ||
                !sourceUnit.base_measure_unit_coeff ||
                !targetUnit.base_measure_unit_coeff
            ) {
                console.error(
                    `Error when unit conversion, measure_value=${measure_value}, 
                    source_measure_unit_code=${source_measure_unit_code},
                     target_measure_unit_code=${target_measure_unit_code}`
                );
                return 0;
            }

            // Значение в базовой ЕИ
            const measureValueInBaseUnit = measure_value * sourceUnit.base_measure_unit_coeff;

            // Значение в целевой ЕИ
            const measureValueInTargetUnit =
                measureValueInBaseUnit / targetUnit.base_measure_unit_coeff;

            return measureValueInTargetUnit;
        },
        [units]
    );

    const measureValueUnitConvertInBaseUnit: measureValueUnitConvertInBaseUnitType = useCallback(
        (measure_value: number, source_measure_unit_code: string): number => {
            // Находим ЕИ из справочника
            const sourceUnit = units.find(
                (unit) => unit.measure_unit_code === source_measure_unit_code
            );

            if (!sourceUnit || !sourceUnit.base_measure_unit_coeff) {
                console.error(
                    `Error when base unit conversion, measure_value=${measure_value}, 
                    source_measure_unit_code=${source_measure_unit_code}`
                );
                return 0;
            }

            // Значение в базовой ЕИ
            const measureValueInBaseUnit = measure_value * sourceUnit.base_measure_unit_coeff;

            return measureValueInBaseUnit;
        },
        [units]
    );

    return (
        <MeasureUnitCalculationContext.Provider
            value={{
                units,
                measureValueUnitConvert,
                measureValueUnitConvertInBaseUnit
            }}
        >
            {children}
        </MeasureUnitCalculationContext.Provider>
    );
};
