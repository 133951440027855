import {
    FullscreenExitOutlined,
    FullscreenOutlined,
    HolderOutlined,
    MoreOutlined
} from '@ant-design/icons';
import { CheckboxProps, TableCellProps } from '@mui/material';
import { MRT_TableInstance } from 'material-react-table';

import { CollapseIcon, EmptyMarker } from 'ui';
import { TableHeadCell, TableHeadCellProps } from '../components/TableHeadCell/TableHeadCell';

export const muiTableBodyCellProps: TableCellProps = {
    classes: {
        root: 'outline-none'
    },
    size: 'small'
};

export const muiTableDetailPanelProps = { sx: { zIndex: 1 } };

export const muiTablePaperProps = ({ table }: { table: MRT_TableInstance<any> }) => ({
    style: {
        height: table.getState().isFullScreen ? '100vh-64px' : undefined,
        width: table.getState().isFullScreen ? '100vw-50px' : undefined,
        marginTop: table.getState().isFullScreen ? 64 : undefined,
        marginLeft: table.getState().isFullScreen ? 50 : undefined
    }
});

export const muiCheckboxProps: CheckboxProps = {
    size: 'small',
    color: 'primary',
    sx: { width: '1rem', height: '1rem', margin: '0rem' }
};

export const renderEmptyRowsFallback = () => <EmptyMarker />;

export const icons = {
    ExpandMoreIcon: CollapseIcon,
    MoreVertIcon: () => <MoreOutlined style={{ color: 'black' }} />,
    FullscreenIcon: FullscreenOutlined,
    FullscreenExitIcon: FullscreenExitOutlined,
    DragHandleIcon: HolderOutlined,
    MoreHorizIcon: () => <MoreOutlined style={{ color: 'black' }} />
};

export const muiTableHeadCellProps = ({ table, column }: TableHeadCellProps) => ({
    classes: {
        root: 'head-cell-content-wrapper'
    },
    component: () => <TableHeadCell table={table} column={column} />
});

export const muiTableHeadRowProps = { sx: { display: 'flex' } };
