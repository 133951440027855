import { memo } from 'react';
import { Checkbox, Divider, Flex, List } from 'antd';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import VirtualList from 'rc-virtual-list';

import './ListView.scss';

const ColumnRender = ({
    table,
    value,
    id,
    columnName,
    hasNeighbour = false,
    accessorKeySubstr = columnName
}: {
    table: MRT_TableInstance<any>;
    value: React.ReactNode;
    id: string;
    columnName: string;
    hasNeighbour?: boolean;
    accessorKeySubstr?: string;
}) => {
    try {
        const row = table.getRow(id);

        const cell = row.getAllCells().find((cell) => cell.row.id === id) || { id: 'id' };

        const column = table.getColumn(columnName);

        const columns = table._getColumnDefs() as unknown as MRT_ColumnDef<any>[];

        const Renderer =
            columns.find((col) => col.accessorKey?.includes(accessorKeySubstr))?.Cell ||
            (() => <></>);

        // console.log(columnName, column);

        return (
            <span style={{ marginTop: '-6px', maxWidth: hasNeighbour ? '32vw' : undefined }}>
                <Renderer
                    table={table}
                    cell={cell}
                    column={column}
                    renderedCellValue={value}
                    row={row}
                />
            </span>
        );
    } catch (e) {
        // console.log(e);

        return <></>;
    }
};

interface ListViewProps {
    data: any[];
    table: MRT_TableInstance<any>;
    titleColumns?: { name: string; accessorKeySubstr?: string }[];
    avatarColumn?: { name: string; accessorKeySubstr?: string };
    loading?: boolean;
}

export const ListView = memo<ListViewProps>(
    ({ data, table, avatarColumn, titleColumns = [{ name: 'short_title' }] }) => {
        const listHeight = data.length * 69;
        return (
            <List className="list_view">
                <VirtualList
                    data={data}
                    height={listHeight > 660 ? 660 : listHeight}
                    fullHeight
                    itemHeight={69}
                    itemKey="id"
                >
                    {(item) => (
                        <List.Item
                            className="list_view__item"
                            style={{
                                backgroundColor: table.getState().rowSelection[item.id]
                                    ? '#D1E4F6'
                                    : 'inherit'
                            }}
                        >
                            <Flex className="list_view__item_flex_box">
                                <Checkbox
                                    checked={table.getState().rowSelection[item.id]}
                                    onChange={(e) =>
                                        table.setRowSelection((prev) => ({
                                            ...prev,
                                            [item.id]: e.target.checked
                                        }))
                                    }
                                />
                                <List.Item.Meta
                                    className="list_view__item_meta"
                                    avatar={
                                        avatarColumn ? (
                                            <ColumnRender
                                                table={table}
                                                value={item.key}
                                                id={item.id}
                                                columnName={avatarColumn.name}
                                                accessorKeySubstr={avatarColumn.accessorKeySubstr}
                                            />
                                        ) : item.key ? (
                                            <ColumnRender
                                                table={table}
                                                value={item.key}
                                                id={item.id}
                                                columnName="key"
                                            />
                                        ) : item.code ? (
                                            <ColumnRender
                                                table={table}
                                                value={item.code}
                                                id={item.id}
                                                columnName="code"
                                            />
                                        ) : (
                                            <ColumnRender
                                                table={table}
                                                value={item.id}
                                                id={item.id}
                                                columnName="id"
                                            />
                                        )
                                    }
                                    title={
                                        <Flex align="center" style={{ margin: '-6px' }}>
                                            {titleColumns.map((column, index) => (
                                                <>
                                                    <ColumnRender
                                                        key={column.name}
                                                        table={table}
                                                        value={item.key}
                                                        id={item.id}
                                                        columnName={column.name}
                                                        hasNeighbour={titleColumns.length > 1}
                                                        accessorKeySubstr={
                                                            column.accessorKeySubstr || column.name
                                                        }
                                                    />
                                                    {titleColumns.length > 1 &&
                                                    index !== titleColumns.length - 1 ? (
                                                        <Divider
                                                            type="vertical"
                                                            style={{ margin: 0 }}
                                                        />
                                                    ) : null}
                                                </>
                                            ))}
                                        </Flex>
                                    }
                                    description={
                                        <Flex
                                            align="center"
                                            style={{ margin: '-6px', marginTop: '12px' }}
                                        >
                                            {item.lifecycle_status_code ? (
                                                <ColumnRender
                                                    table={table}
                                                    value={item.key}
                                                    id={item.id}
                                                    columnName="lifecycle_status_code"
                                                    accessorKeySubstr="status"
                                                />
                                            ) : (
                                                <ColumnRender
                                                    table={table}
                                                    value={item.key}
                                                    id={item.id}
                                                    columnName="created_at"
                                                    // accessorKeySubstr="status"
                                                />
                                            )}
                                        </Flex>
                                    }
                                />
                            </Flex>
                        </List.Item>
                    )}
                </VirtualList>
            </List>
        );
    }
);
