// BaseField.tsx
import { Form, Tooltip, Typography } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import React, { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InspectModuleComponentAccess } from 'components/ModuleComponentAccess/ModuleComponentAccess';
import { AccessControl } from 'modules/auth/components';
import { usePermissions } from 'modules/auth/contexts';
import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';

type ViewEditMode = 'view' | 'edit';

export interface BaseFieldProps {
    id: string;
    htmlId?: string;
    value: any;
    mode: ViewEditMode;
    onChange: (newValue: any) => void;
    renderInput: (
        value: any,
        onChange?: (newValue: any) => void,
        onBlur?: (newValue?: any) => void
    ) => React.ReactNode;
    renderView: (value: any, ref?: any) => React.ReactNode;
    withLabel?: boolean;
    isFullSizeLabel?: boolean; // чтобы в инпуте перенести все что находится дальше label на новую строку, делаем label максимальной длины (Col=24)
    withTooltip?: boolean;
    required?: boolean;
    skipBlur?: boolean;
    validateStatus?: ValidateStatus;
    validateMessage?: string;
    ref?: React.Ref<any>;
}

export const BaseField = memo(
    forwardRef<any, BaseFieldProps>(
        (
            {
                id,
                value,
                mode,
                onChange,
                renderInput,
                renderView,
                validateStatus,
                validateMessage,
                required = false,
                withLabel = true,
                isFullSizeLabel = !withLabel,
                withTooltip = true,
                skipBlur = false,
                htmlId = id
            },
            ref
        ) => {
            const {
                t,
                i18n: { language }
            } = useTranslation();
            const dataset = useDataset(false);
            const { permissions } = usePermissions();
            const accessLevel = permissions.components[id] || 'read_write'; // default access level is no_access!

            const [val, setVal] = useState(value);

            useEffect(() => {
                setVal(value);
            }, [value]);

            const handleChange = useCallback(
                (newValue: any) => {
                    if (skipBlur) {
                        if (mode === 'edit' && accessLevel === 'read_write') {
                            onChange(newValue);
                        }
                    } else setVal(newValue);
                },
                [accessLevel, mode, onChange, skipBlur]
            );

            const handleBlur = useCallback(() => {
                if (skipBlur) return;

                if (mode === 'edit' && accessLevel === 'read_write') {
                    onChange(val);
                }
            }, [skipBlur, mode, accessLevel, onChange, val]);

            const renderContent = () => {
                if (accessLevel === 'no_access') {
                    return null;
                }

                if (mode === 'edit' && accessLevel === 'read_write') {
                    return renderInput(val, handleChange, handleBlur);
                }

                return renderView(value);
            };

            const formItemRules = [
                {
                    required: required && mode !== 'view', // Филд будет обязательным, если он не в режиме просмотра и мы указали флаг. В ином случае, это не оябзательное поле, даже не смотря на доки. Режим просмотра нам валидировать незачем
                    message: `${t('field')} "${t(id)}" ${t('requiredField')}!` // составное сообщение валидации
                }
            ];

            const label = withLabel ? (
                <Typography.Text id={`${id}-label`} type="secondary">{`${t(id)}:`}</Typography.Text> // конструируем label
            ) : undefined;

            const tooltipTitle = useMemo(() => {
                if (withTooltip) {
                    if (!value) return '';

                    if (typeof value === 'object') {
                        if (value[language]) return value[language];
                        if (value.long_title) {
                            if (value.long_title[language]) return value.long_title[language];
                            return value.long_title[language];
                        }
                        if (value.short_title) {
                            if (value.short_title[language]) return value.short_title[language];
                            return value.short_title[language];
                        }

                        return '';
                    }

                    return value;
                }
                return undefined;
            }, [language, value, withTooltip]);

            return (
                <AccessControl id={id} permissions={permissions}>
                    <InspectModuleComponentAccess
                        component_name={id}
                        component_path={
                            (dataset.length > 0 && dataset[0].tables[0]?.tableName) || ''
                        }
                    >
                        {/* без предупреждения не правьте строку пож */}
                        <div id={htmlId} className="base-field" ref={ref}>
                            <Form.Item
                                className={
                                    isFullSizeLabel
                                        ? 'base-field__form_item base-field__form_item_full'
                                        : 'base-field__form_item'
                                }
                                name={htmlId}
                                id={htmlId}
                                htmlFor={`${htmlId}-value`}
                                colon={false}
                                label={label}
                                validateStatus={validateStatus}
                                help={validateMessage}
                                rules={formItemRules}
                                labelCol={
                                    label ? { xs: { span: isFullSizeLabel ? 24 : 12 } } : undefined
                                } // grid-system для label
                                labelAlign="left"
                                hasFeedback={mode !== 'view' && required} // значки success и fail как суффикс в инпуте
                            >
                                <Tooltip
                                    placement="topLeft"
                                    title={tooltipTitle}
                                    mouseEnterDelay={0.7}
                                    destroyTooltipOnHide
                                >
                                    <Typography.Text id={`${htmlId}-value-container`} aria-required>
                                        {renderContent()}
                                    </Typography.Text>
                                </Tooltip>
                            </Form.Item>
                        </div>
                    </InspectModuleComponentAccess>
                </AccessControl>
            );
        }
    )
);
