import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { UserSpecificFormat } from 'utils/helpers/dates';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField } from '../BaseField/BaseField';

interface YearFieldProps {
    id: string; // идентификатор поля ввода
    value: number | null; // строковый формат даты ISO 8601
    mode: 'view' | 'edit'; // режим отображения: просмотр или редактирование
    onChange?: (newValue: string) => void; // функция для обработки изменения значения поля ввода
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
}

export const YearField = memo<YearFieldProps>(
    ({
        id,
        value,
        mode,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false
    }) => {
        const { t } = useTranslation();

        // Определение функции для рендеринга компонента выбора даты
        const renderInput = useCallback(
            (
                inputValue: number | null,
                onInputChange?: (newValue: number | null) => void,
                onBlur?: () => void
            ) => {
                const handleChange = (date: dayjs.Dayjs | null) => {
                    if (onInputChange && onBlur && date) {
                        onInputChange(date.year());
                    }
                };

                const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
                    e.stopPropagation();
                    if (onInputChange) {
                        onInputChange(null);
                    }
                };

                return (
                    <DatePicker
                        id={`${id}-value`}
                        placeholder={t('no_value') as string}
                        data-testid={`${id}-edit`}
                        value={inputValue ? dayjs().year(inputValue) : null}
                        onChange={handleChange}
                        onBlur={onBlur}
                        format={UserSpecificFormat.getYearFormat()}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        picker="year"
                        inputReadOnly
                    />
                );
            },
            [id, t]
        );

        // Определение функции для рендеринга компонента просмотра даты
        const renderView = useCallback(
            (viewValue: number | null) => {
                return (
                    <DatePicker
                        id={`${id}-value`}
                        data-testid={`${id}-view`}
                        placeholder={t('no_value') as string}
                        value={viewValue ? dayjs().year(viewValue) : null}
                        format={UserSpecificFormat.getYearFormat()}
                        variant="borderless"
                        allowClear={false}
                        open={false}
                        picker="year"
                        inputReadOnly
                    />
                );
            },
            [id, t]
        );

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты
        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
