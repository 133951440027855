// CreatePage.tsx
import { Col, Row } from 'antd';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectField, CheckboxField } from 'components/fields';
import { getTableDictNames } from 'utils/helpers/getTableDictNames';

import { ButtonWithTooltips } from 'ui';
import { TableRowWithChangeFunction } from 'components/DataTable/DetailPage_DataTable';
import type { MRTColumnDef } from 'components/DataTable/MRT_Types';

import { TableName, TableRow, TypeInfoProps, ViewName } from 'modules/supabase/types/Dataset';
import { MultilanguageCell } from 'modules/supabase/utils/tableCellUtils/UICells';
import { PageHeader } from 'components';
import { CloseOutlined } from '@ant-design/icons';
import { routeStore, useStoreNavigate } from 'utils/store';
import { useLocation } from 'react-router-dom';

interface ICreatePageProps {
    table_name: TableName;
    onTypeSelected: (typeInfo: TypeInfoProps) => void;
    onCancel: () => void;
}

const expandOptions: {
    enabled: boolean;
    columnSorting: (fieldA: string, fieldB: string) => number;
} = {
    enabled: true,
    columnSorting: (fieldA: string, fieldB: string) => {
        if (fieldA.startsWith('mrt') && fieldB !== 'short_title') {
            return -1;
        }

        if (fieldA === 'short_title' && !fieldB.startsWith('mrt')) {
            return -1;
        }

        if (fieldA === 'code' && fieldB !== 'short_title' && !fieldB.startsWith('mrt')) {
            return -1;
        }

        return 1;
    }
};

const OPERATION_TYPE_NEW_LABEL_TABLES = ['docs_warehouse_tasks', 'docs_wms_executor_tasks'];

export const CreatePage: React.FC<ICreatePageProps> = ({
    table_name,
    onTypeSelected,
    onCancel
}) => {
    const { t } = useTranslation();
    const { pathname, search, state: locationState } = useLocation();
    const navigate = useStoreNavigate();

    const [type, setType] = useState<TypeInfoProps>();
    const [isTemplate, setIsTemplate] = useState(false);
    // mice  => mouse, TODO: букву s в конце убирать не очень надежно - надо в общем виде придумать как определять таблицу с типами для таблицы с объектами
    // convert docs_products to dicts_product_types
    // Replace 'docs_' with 'dicts_' and append '_types' to the end
    const { treeTypeTableName } = getTableDictNames(table_name);

    const columns: MRTColumnDef[] = useMemo(
        () => [
            {
                accessorKey: 'short_title',
                header: t('short_title') as string,
                Cell: ({ row }) =>
                    MultilanguageCell({
                        columnData: (row.original as { short_title: any }).short_title,
                        rowData: row.original as TableRowWithChangeFunction, // no changable cell this has a BaseTableRow type
                        columnId: 'short_title',
                        mode: 'view'
                    })
            },
            {
                accessorKey: 'code',
                header: t('code') as string
            }
        ],
        [t]
    );

    const typeLabel = OPERATION_TYPE_NEW_LABEL_TABLES.includes(table_name)
        ? 'warehouse_task_type_code'
        : 'type';

    const handleCloseNav = useCallback(() => {
        const closedIndex = routeStore.findIndex({ pathname, search, state: locationState });
        navigate(routeStore.routes[closedIndex - 1]);
        routeStore.remove({ pathname, search, state: locationState });
    }, [locationState, navigate, pathname, search]);

    const handleCancel = useCallback(() => {
        onCancel();
        handleCloseNav();
    }, [handleCloseNav, onCancel]);

    return (
        <>
            <PageHeader
                title={t('create_settings')}
                right={
                    <ButtonWithTooltips
                        id="close"
                        type="text"
                        className=""
                        onClick={handleCloseNav}
                        tooltipTitle={t('close')}
                        icon={<CloseOutlined />}
                    />
                }
            />
            <Row data-testid="select-field" gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
                <Col md={24} lg={8}>
                    <SelectField
                        data-testid="select-field"
                        id={typeLabel}
                        mode="edit"
                        viewName={treeTypeTableName as ViewName}
                        value={(type as TableRow) || null} // нам не нужно передавать значение в SelectField так как после выбора вью мы переходим в DetailPage и берём всю информацию из вью
                        onChange={(newValue) => {
                            // кинули тип целиком
                            setType((newValue || {}) as TypeInfoProps);
                        }}
                        searchLishColumns={columns}
                        expandOptions={expandOptions}
                        popoverPlacement="right"
                        // asDictionarySelectFieldWithOneColumn // TODO: Стандартный селект антд с деревом
                        // TODO: 21.07.23 для вложенных списков при 10 в размере страницу у таблицы, не отображаются все строки. Поэтому для тр. средств это 100. Пока временное решение до показа, потом взять в работу, чтобы это по нормальному настроено было и отрабатывало
                        defaultTablePageSize={100}
                    />
                </Col>
            </Row>
            <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
                <Col md={24} lg={8}>
                    <CheckboxField
                        id="is_template"
                        mode="edit"
                        value={isTemplate}
                        onChange={setIsTemplate}
                    />
                </Col>
            </Row>
            <br />
            <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
                <Col md={24} lg={8}>
                    <ButtonWithTooltips
                        id="cancel"
                        className="btn-red"
                        data-testid="cancel-button"
                        onClick={handleCancel}
                        tooltipPlacement="bottom"
                    >
                        {t('cancel')}
                    </ButtonWithTooltips>{' '}
                    <ButtonWithTooltips
                        id="confirm"
                        data-testid="confirm-button"
                        onClick={() => onTypeSelected((type || {}) as TypeInfoProps)}
                        tooltipPlacement="bottom"
                    >
                        {t('confirm')}
                    </ButtonWithTooltips>
                </Col>
            </Row>
        </>
    );
};
