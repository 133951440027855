import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'utils/i18n/i18n';

import { googleTranslateText } from 'utils/i18n/libs/material-react-table/translateUtils';

import { ButtonWithTooltips } from 'ui';
import { Flex, Modal } from 'antd';
import { StringField } from 'components/fields/StringField/StringField';
import { MultilanguageValueType } from '../../types/FieldTypes';

interface LanguageTranslateDialogProps {
    value: MultilanguageValueType;
    setValue: (newValue: MultilanguageValueType) => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LanguageTranslateDialog = ({
    value,
    setValue,
    open,
    setOpen
}: LanguageTranslateDialogProps) => {
    const {
        t,
        i18n: { language: currentLanguage }
    } = useTranslation();

    const [multilanguageValue, setMultilanguageValue] = useState<MultilanguageValueType>(value);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleApply = useCallback(() => {
        setValue(multilanguageValue);
        setOpen(false);
    }, [setValue, setOpen, multilanguageValue]);

    const translateText = useCallback(
        async (textToTranslate: string, fromLanguage: LANGUAGES, toLanguage: LANGUAGES) => {
            const translationText = await googleTranslateText(
                textToTranslate,
                fromLanguage,
                toLanguage
            );

            const copyVal: MultilanguageValueType = { ...multilanguageValue };

            copyVal[toLanguage] = translationText;

            setMultilanguageValue(copyVal);
        },
        [setMultilanguageValue, multilanguageValue]
    );

    const handleValueChange = useCallback(
        (newValue: string, language: LANGUAGES) => {
            const copyVal: MultilanguageValueType = { ...multilanguageValue };

            copyVal[language] = newValue;

            setMultilanguageValue(copyVal);
        },
        [setMultilanguageValue, multilanguageValue]
    );

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            onOk={handleApply}
            title={`${t('currentLanguage')}: ${t(currentLanguage)}`}
            cancelText={t('cancel')}
            okText={t('apply')}
            cancelButtonProps={{ type: 'text', className: 'btn btn-red' }}
            okButtonProps={{ className: 'btn btn-blue' }}
            centered
            destroyOnClose
        >
            <Flex gap={5} vertical>
                {multilanguageValue &&
                    Object.keys(value || {})
                        .sort((language) => (language === currentLanguage ? -1 : 1))
                        .map((language) => (
                            <Flex gap={5} key={language}>
                                <StringField
                                    id={`textFieldTranslate_${language}`}
                                    value={
                                        multilanguageValue
                                            ? multilanguageValue[language as LANGUAGES] || null
                                            : null
                                    }
                                    mode="edit"
                                    onChange={(newValue) => {
                                        handleValueChange(newValue, language as LANGUAGES);
                                    }}
                                    withLabel={false}
                                />

                                <ButtonWithTooltips
                                    type="link"
                                    id="languageTranslate"
                                    className="languageTranslate"
                                    style={{
                                        minWidth: '35%',
                                        textAlign: 'left'
                                    }}
                                    onClick={() => {
                                        translateText(
                                            multilanguageValue[currentLanguage as LANGUAGES] || '',
                                            currentLanguage as LANGUAGES,
                                            language as LANGUAGES
                                        );
                                    }}
                                    disabled={language === currentLanguage}
                                >
                                    {`${t(currentLanguage)} -> ${t(language)}`}
                                </ButtonWithTooltips>
                            </Flex>
                        ))}
            </Flex>
        </Modal>
    );
};
