import { DeleteTwoTone, SettingOutlined } from '@ant-design/icons';
import { Col, Form, List, Modal, Row } from 'antd';
import _ from 'lodash';
import {
    MRT_ColumnFiltersState,
    MRT_ColumnSizingState,
    MRT_FilterOption,
    MRT_SortingState
} from 'material-react-table';
import React, {
    Dispatch,
    Suspense,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxField, StringField } from 'components/fields';
import { usePermissions } from 'modules/auth/contexts';
import { ButtonWithTooltips } from 'ui';

import { useSupabase } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { ViewName } from 'modules/supabase/types/Dataset';
import { Database } from 'modules/supabase/types/database.types';
import { useSupabaseUser } from 'modules/supabase/utils/hooks/useSupabaseUser';
import {
    ViewDisplayPreferenceContext,
    ViewDisplayPreferenceSettings,
    useViewDisplayPreferences
} from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useMessages, useResize } from 'utils/hooks';
import { Base_DataTable } from './Base_DataTable';
import type { MRTColumnDef, MRTRow, MRTTableInstance } from './MRT_Types';
import { FilterField } from './TableFilterMenu/TableFilterMenuTypes';
import './ViewDisplayPreferenceToolBarButton.scss';

const Popover = React.lazy(() => import('antd').then(({ Popover }) => ({ default: Popover })));

const VIEW_DISPLAY_PREFERENCE_TABLE_NAME = 'settings_user_view_display_preferences';

type ViewDisplayPreferences =
    Database['public']['Tables']['settings_user_view_display_preferences']['Row'];

export type ViewDisplayPreferenceUIDataType = {
    columnOrder: string[];
    sorting: MRT_SortingState;
    filtering: MRT_ColumnFiltersState;
    columnFilterFns: {
        [key: string]: MRT_FilterOption;
    };
    columnVisibility: {
        [key: string]: boolean;
    };
    pageSize: number;
    columnSizing: MRT_ColumnSizingState;
    complexFilter: FilterField[];
    grouping: string[];
};

type ViewDisplayPreferenceEditType = (
    | (ViewDisplayPreferences & {
          _state?: 'Updated' | 'Deleted' | null;
      })
    | Record<string, any>
)[];

type RenderBooleanCellType = (id: string) => MRTColumnDef['Cell'];

// Функция для удаления колонок и полей из объекта
const removeColumnsAndFields = (
    settings: ViewDisplayPreferenceSettings,
    columnsToExclude: string[],
    fieldsToExclude: string[]
) => {
    const settingsClone = { ...settings };
    settingsClone.columns = settingsClone.columns.filter((col) => !columnsToExclude.includes(col));
    fieldsToExclude.forEach((field) => {
        if (
            _.isNil(settingsClone[field as keyof ViewDisplayPreferenceSettings]) ||
            _.isEmpty(settingsClone[field as keyof ViewDisplayPreferenceSettings])
        ) {
            delete settingsClone[field as keyof ViewDisplayPreferenceSettings];
        }
    });
    return settingsClone;
};

const ConfigureViewDisplayPreferenceModal = ({
    viewName,
    open,
    setOpen
}: {
    viewName: ViewName;
    open: boolean;
    setOpen: Dispatch<boolean>;
}) => {
    const { t } = useTranslation();

    const { data } = useViewDisplayPreferences({ viewName });
    const { openMessage } = useMessages({ message: '' });

    const { user } = useSupabaseUser();
    const { permissions } = usePermissions();

    const supabase = useSupabase();

    const [dataOutput, setDataOutput] = useState<ViewDisplayPreferenceEditType>([]);

    const { dispatch } = useContext(ViewDisplayPreferenceContext);

    useEffect(() => {
        if (data) {
            const newData: ViewDisplayPreferenceEditType = [];

            for (let i = 0; i < data.length; i++) {
                const row = data[i];

                if (row.title !== 'SearchHelp') {
                    newData.push({
                        ...row,
                        _state: null
                    });
                }
            }

            setDataOutput(newData);
        }
    }, [data, open]);

    const getDisabledByAccess = (id: string, row: MRTRow) => {
        const accessLevel = permissions.components[id] || 'read_write';

        const rowData = row.original as Record<string, any>;

        const disabled =
            ((!user?.user_metadata.roles.includes('Admin') || accessLevel === 'no_access') &&
                user?.id !== rowData.parent_user_uuid) ||
            rowData.title === 'Default';

        return disabled;
    };

    const changeField = (rowNumber: number, fieldName: string, value: any) => {
        const copyData = [...dataOutput];
        const copyRow = copyData[rowNumber] as Record<string, any>;

        copyRow._state = 'Updated';
        copyRow[fieldName] = value;

        if (fieldName === 'as_default' && value === true) {
            copyData.forEach((row, index) => {
                if (index !== rowNumber && row.title !== 'Default') {
                    copyData[index]._state = 'Updated';
                    copyData[index].as_default = false;
                }
            });
        }

        setDataOutput(copyData);
    };

    const deleteRow = (rowNumber: number) => {
        const copyData = [...dataOutput];

        copyData[rowNumber] = {
            ...copyData[rowNumber],
            _state: 'Deleted'
        };

        setDataOutput(copyData);
    };

    const renderBooleanCell: RenderBooleanCellType =
        (id: string) =>
        ({ row }) => {
            const rowData = row.original as Record<string, any>;
            const value = rowData[id];

            const disabled = getDisabledByAccess(id, row);

            return (
                <CheckboxField
                    withLabel={false}
                    id={id}
                    mode={disabled ? 'view' : 'edit'}
                    value={value}
                    onChange={() => changeField(row.index, id, !value)}
                />
            );
        };

    const columns: MRTColumnDef[] = [
        {
            accessorKey: 'mrt-row-delete_preference',
            header: '',
            size: 30,
            minSize: 30,
            Cell: ({ row }) => {
                const id = 'delete_preference';

                const rowData = row.original as Record<string, any>;
                const accessLevel = permissions.components[id] || 'read_write';

                const disabled =
                    ((!user?.user_metadata.roles.includes('Admin') ||
                        accessLevel === 'no_access') &&
                        user?.id !== rowData.parent_user_uuid) ||
                    rowData.title === 'Default';

                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <ButtonWithTooltips
                            id={id}
                            tooltipTitle={t('delete')}
                            tooltipPlacement="left"
                            className="delete_preference"
                            type="text"
                            onClick={() => deleteRow(row.index)}
                            icon={
                                <DeleteTwoTone twoToneColor={!disabled ? '#fc5e53' : '#bfbfbf'} />
                            }
                            disabled={disabled}
                        />
                    </div>
                );
            },
            enableEditing: false
        },
        {
            accessorKey: 'title',
            header: t('title'),
            Cell: ({ row }) => {
                const id = 'title';
                const rowData = row.original as Record<string, any>;

                const disabled = getDisabledByAccess(id, row);

                return (
                    <StringField
                        withLabel={false}
                        id={id}
                        mode={disabled ? 'view' : 'edit'}
                        value={rowData.title}
                        onChange={(newValue) => changeField(row.index, id, newValue)}
                    />
                );
            },
            enableEditing: false
        },
        {
            accessorKey: 'as_default',
            header: t('as_default'),
            size: 100,
            Cell: renderBooleanCell('as_default'),
            enableEditing: false
        },
        {
            accessorKey: 'as_global',
            header: t('as_global'),
            size: 100,
            Cell: renderBooleanCell('as_global'),
            enableEditing: false
        }
    ];

    const columnsState = {
        columnSizing: { 'mrt-row-select': 30 },
        columnVisibility: {
            'mrt-row-actions': false,
            'mrt-row-delete_preference': true,
            title: true,
            as_default: true,
            as_global: true
        },
        columnOrder: [
            'mrt-row-delete_preference',
            'mrt-row-select',
            ...columns.slice(1).map((item) => item.accessorKey as string)
        ]
    };

    const saveChanges = () => {
        const sendChangeRequest = async () => {
            const unsavedRows = [];
            for (let i = 0; i < dataOutput.length; i++) {
                const rowData = { ...dataOutput[i] };
                const rowState = rowData._state;

                if (rowState) {
                    if (rowState === 'Updated') {
                        delete rowData._state;
                        unsavedRows.push(
                            supabase
                                .from(VIEW_DISPLAY_PREFERENCE_TABLE_NAME)
                                .update(rowData)
                                .eq('id', rowData.id)
                        );
                    } else if (rowState === 'Deleted') {
                        delete rowData._state;
                        unsavedRows.push(
                            supabase
                                .from(VIEW_DISPLAY_PREFERENCE_TABLE_NAME)
                                .delete()
                                .eq('id', rowData.id)
                        );
                    }
                }
            }

            const results = await Promise.allSettled(unsavedRows);
            const errors = [];

            setOpen(false);
            dispatch({
                type: 'UPDATE_VIEW_DISPLAY',
                payload: {
                    viewName
                }
            });

            for (let j = 0; j < results.length; j++) {
                const result = results[j];
                if (result.status === 'rejected') {
                    errors.push(result);
                }
            }

            for (let a = 0; a < errors.length; a++) {
                openMessage({ message: errors[a].reason, type: 'error' });
            }

            if (errors.length === 0) {
                openMessage({ message: t('success'), type: 'success' });
            }
        };

        sendChangeRequest();
    };

    return (
        <Modal
            centered
            title={t('config_view_display_preference')}
            open={open}
            footer={[
                <ButtonWithTooltips
                    id="cancel"
                    key="cancel"
                    className="btn-red"
                    onClick={() => setOpen(false)}
                >
                    {t('cancel')}
                </ButtonWithTooltips>,
                <ButtonWithTooltips id="save" key="save" className="btn-blue" onClick={saveChanges}>
                    {t('save')}
                </ButtonWithTooltips>
            ]}
            onCancel={() => setOpen(false)}
        >
            <Base_DataTable
                viewName={viewName}
                columns={columns}
                data={dataOutput.filter((rowOutput) => rowOutput._state !== 'Deleted')}
                editDisplayMode="table"
                enableEditing={(row) => (row.original as Record<string, any>).as_global !== true}
                enableSorting={false}
                enableColumnActions={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableColumnResizing={false}
                enableRowActions
                muiEditTextFieldProps={({ cell, row }) => ({
                    // onBlur is more efficient, but could use onChange instead
                    onBlur: (event) => {
                        if (cell.column.columnDef.accessorKey === 'title') {
                            changeField(row.index, 'title', event.target.value);
                        }
                    }
                })}
                state={columnsState}
                muiTableContainerProps={{ sx: { overflowX: 'hidden' } }}
            />
        </Modal>
    );
};

const CreateNewViewDisplayPreferenceModal = ({
    open,
    setOpen,
    viewName,
    viewDisplayPreferenceSettings
}: {
    open: boolean;
    setOpen: Dispatch<boolean>;
    viewName: ViewName;
    viewDisplayPreferenceSettings: ViewDisplayPreferenceSettings;
}) => {
    const { t } = useTranslation();

    const { dispatch } = useContext(ViewDisplayPreferenceContext);

    const [formData, setFormData] = useState<{
        title: string;
        as_default: boolean;
        as_global: boolean;
    }>({
        title: '',
        as_default: false,
        as_global: false
    });

    useEffect(() => {
        setFormData({
            title: '',
            as_default: false,
            as_global: false
        });
    }, [open]);

    const { openMessage } = useMessages({ message: '' });
    const { user } = useSupabaseUser();
    const supabase = useSupabase();

    const createNewViewDisplayPreference = () => {
        const sendCreateRequest = async () => {
            const newViewDisplayPreference: Partial<ViewDisplayPreferences | Record<string, any>> =
                {
                    title: formData.title,
                    as_default: formData.as_default,
                    as_global: formData.as_global,
                    parent_user_uuid: formData.as_global ? null : user?.id,
                    view_name: viewName,
                    settings: viewDisplayPreferenceSettings
                };

            // newViewDisplayPreference
            const result = await supabase
                .from(VIEW_DISPLAY_PREFERENCE_TABLE_NAME)
                .insert(newViewDisplayPreference);

            if (result.error) {
                openMessage({
                    message: makeErrorReadable(result.error.message),
                    type: 'error',
                    key: result.error.code
                });
            } else {
                dispatch({
                    type: 'UPDATE_VIEW_DISPLAY',
                    payload: {
                        viewName
                    }
                });
                openMessage({ message: t('success'), type: 'success' });
            }
        };

        sendCreateRequest();
        setOpen(false);
    };

    return (
        <Modal
            centered
            title={t('create_new_view_display_preference')}
            open={open}
            footer={[
                <ButtonWithTooltips
                    id="cancel"
                    key="cancel"
                    className="btn-red"
                    onClick={() => setOpen(false)}
                >
                    {t('cancel')}
                </ButtonWithTooltips>,
                <ButtonWithTooltips
                    id="save"
                    key="save"
                    className="btn-blue"
                    onClick={createNewViewDisplayPreference}
                >
                    {t('save')}
                </ButtonWithTooltips>
            ]}
            onCancel={() => setOpen(false)}
        >
            <Form>
                <StringField
                    id="title"
                    mode="edit"
                    value={formData.title}
                    onChange={(newValue) => {
                        setFormData((prevValue) => {
                            return {
                                ...prevValue,
                                title: newValue
                            };
                        });
                    }}
                />

                <CheckboxField
                    id="as_default"
                    mode="edit"
                    value={formData.as_default}
                    onChange={(newValue) => {
                        setFormData((prevValue) => {
                            return {
                                ...prevValue,
                                as_default: newValue
                            };
                        });
                    }}
                />

                <CheckboxField
                    id="as_global"
                    mode="edit"
                    value={formData.as_global}
                    onChange={(newValue) => {
                        setFormData((prevValue) => {
                            return {
                                ...prevValue,
                                as_global: newValue
                            };
                        });
                    }}
                />
            </Form>
        </Modal>
    );
};

export interface ViewDisplayPreferenceMenuProps {
    table: MRTTableInstance;
    viewName: ViewName;
    selectedViewDisplayPreference: ViewDisplayPreferences | Record<string, any> | null;
    setSelectedViewDisplayPreference: Dispatch<ViewDisplayPreferences | Record<string, any>>;
    viewDisplayPreferenceUIData: ViewDisplayPreferenceUIDataType;
}

export const ViewDisplayPreferenceMenu = ({
    table,
    viewName,
    selectedViewDisplayPreference,
    setSelectedViewDisplayPreference,
    viewDisplayPreferenceUIData
}: ViewDisplayPreferenceMenuProps) => {
    const { t } = useTranslation();
    const { isScreenMd } = useResize();

    const { data } = useViewDisplayPreferences({ viewName });

    const [popoverVisible, setPopoverVisible] = useState(false);

    const handlePopoverVisibleChange = () => {
        setPopoverVisible(!popoverVisible);
    };

    const [showNewViewDisplayPreferenceModal, setShowNewViewDisplayPreferenceModal] =
        useState<boolean>(false);

    const [showConfigViewDisplayPreferenceModal, setShowConfigViewDisplayPreferenceModal] =
        useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState(false);

    const { dispatch } = useContext(ViewDisplayPreferenceContext);

    const supabase = useSupabase();

    const { openMessage } = useMessages({ message: '' });

    const viewDisplayPreferenceSetting: ViewDisplayPreferenceSettings = useMemo(() => {
        const columns: string[] = [];

        for (let i = 0; i < viewDisplayPreferenceUIData.columnOrder.length; i++) {
            const column = viewDisplayPreferenceUIData.columnOrder[i];

            if (viewDisplayPreferenceUIData.columnVisibility[column]) {
                columns.push(column);
            }
        }

        const sort: {
            [key: string]: 'asc' | 'desc';
        } = {};

        for (let i = 0; i < viewDisplayPreferenceUIData.sorting.length; i++) {
            const sortUI = viewDisplayPreferenceUIData.sorting[i];
            sort[sortUI.id] = sortUI.desc ? 'desc' : 'asc';
        }

        const filter: {
            id: string;
            operator: MRT_FilterOption;
            value: any;
        }[] = [];

        if (viewDisplayPreferenceUIData.filtering) {
            for (let i = 0; i < viewDisplayPreferenceUIData.filtering.length; i++) {
                const filterUI = viewDisplayPreferenceUIData.filtering[i];

                filter.push({
                    id: filterUI.id,
                    value: filterUI.value,
                    operator: viewDisplayPreferenceUIData.columnFilterFns[filterUI.id]
                        ? viewDisplayPreferenceUIData.columnFilterFns[filterUI.id]
                        : 'contains'
                });
            }
        }

        const complexFilter: FilterField[] = [];
        if (viewDisplayPreferenceUIData.complexFilter) {
            for (let i = 0; i < viewDisplayPreferenceUIData.complexFilter.length; i++) {
                complexFilter.push(viewDisplayPreferenceUIData.complexFilter[i]);
            }
        }

        const grouping: string[] = [];

        if (viewDisplayPreferenceUIData.grouping) {
            for (let i = 0; i < viewDisplayPreferenceUIData.grouping.length; i++) {
                grouping.push(viewDisplayPreferenceUIData.grouping[i]);
            }
        }

        return {
            columns,
            columnSizing: viewDisplayPreferenceUIData.columnSizing,
            page_size: viewDisplayPreferenceUIData.pageSize,
            sort,
            filter,
            grouping,
            complexFilter
        };
    }, [viewDisplayPreferenceUIData]);

    const updateViewDisplayPreferenceSetting = useCallback(() => {
        const sendUpdateRequest = async () => {
            if (selectedViewDisplayPreference) {
                const copyViewDisplayPreference = { ...selectedViewDisplayPreference };

                copyViewDisplayPreference.settings = viewDisplayPreferenceSetting;

                const result = await supabase
                    .from(VIEW_DISPLAY_PREFERENCE_TABLE_NAME)
                    .update(copyViewDisplayPreference)
                    .eq('id', selectedViewDisplayPreference.id);

                if (result.error) {
                    openMessage({
                        message: result.error.message,
                        type: 'error',
                        key: result.error.code
                    });
                } else {
                    dispatch({
                        type: 'UPDATE_VIEW_DISPLAY',
                        payload: {
                            viewName
                        }
                    });

                    openMessage({ message: t('success'), type: 'success' });
                }
            }
        };

        sendUpdateRequest();
        handlePopoverVisibleChange();
    }, [supabase, viewDisplayPreferenceSetting, selectedViewDisplayPreference, openMessage, t]);

    useEffect(() => {
        if (!selectedViewDisplayPreference || !selectedViewDisplayPreference.settings) {
            // Если selectedViewDisplayPreference или его settings равны null, считаем, что нет изменений
            setHasChanges(false);
            return;
        }
        // проверка чтобы не проверялся пока не получит данные
        if (viewDisplayPreferenceSetting.columns.length > 0) {
            // Указываем колонки и поля для исключения
            const columnsToExclude = ['mrt-row-expand', 'mrt-row-drag', 'mrt-row-select'];
            const fieldsToExclude = ['filter', 'complexFilter', 'columnSizing'];

            // Создаем копии объектов и удаляем указанные колонки и пустые поля из объектов
            const viewSettingsClone = removeColumnsAndFields(
                viewDisplayPreferenceSetting,
                columnsToExclude,
                fieldsToExclude
            );
            const selectedSettingsClone = removeColumnsAndFields(
                selectedViewDisplayPreference.settings,
                columnsToExclude,
                fieldsToExclude
            );

            // Проверяем, есть ли различия между viewSettingsClone и selectedSettingsClone
            const hasViewSettingChanges = !_.isEqual(viewSettingsClone, selectedSettingsClone);

            // Обновляем состояние hasChanges
            setHasChanges(hasViewSettingChanges);
        }
    }, [viewDisplayPreferenceSetting, selectedViewDisplayPreference]);

    return (
        <>
            <CreateNewViewDisplayPreferenceModal
                viewName={viewName}
                open={showNewViewDisplayPreferenceModal}
                setOpen={setShowNewViewDisplayPreferenceModal}
                viewDisplayPreferenceSettings={viewDisplayPreferenceSetting}
            />

            <ConfigureViewDisplayPreferenceModal
                open={showConfigViewDisplayPreferenceModal}
                setOpen={setShowConfigViewDisplayPreferenceModal}
                viewName={viewName}
            />
            <Suspense fallback={<></>}>
                {selectedViewDisplayPreference ? (
                    <Popover
                        destroyTooltipOnHide
                        trigger="click"
                        content={
                            <List
                                header={<div>{t('my_preferences')}</div>}
                                footer={
                                    <Row gutter={5}>
                                        {!selectedViewDisplayPreference?.as_global && (
                                            <Col>
                                                <ButtonWithTooltips
                                                    id="save"
                                                    style={{ padding: '5px' }}
                                                    type="primary"
                                                    onClick={updateViewDisplayPreferenceSetting}
                                                >
                                                    {t('save')}
                                                </ButtonWithTooltips>
                                            </Col>
                                        )}

                                        <Col>
                                            <ButtonWithTooltips
                                                id="save_as"
                                                style={{ padding: '5px' }}
                                                type="primary"
                                                onClick={() => {
                                                    setShowNewViewDisplayPreferenceModal(true);
                                                    handlePopoverVisibleChange();
                                                }}
                                            >
                                                {t('save_as')}
                                            </ButtonWithTooltips>
                                        </Col>

                                        <Col>
                                            <ButtonWithTooltips
                                                id="settings"
                                                style={{ padding: '5px' }}
                                                type="text"
                                                onClick={() => {
                                                    setShowConfigViewDisplayPreferenceModal(true);
                                                    handlePopoverVisibleChange();
                                                }}
                                            >
                                                {t('settings')}
                                            </ButtonWithTooltips>
                                        </Col>
                                    </Row>
                                }
                                dataSource={data.filter((vdf) => vdf.title !== 'SearchHelp')} // SearchHelp технический vdf , используется только в Select_DataTable
                                renderItem={(item) => (
                                    <List.Item
                                        className={`viewDisplayPreferenceItem ${
                                            item.id === selectedViewDisplayPreference?.id
                                                ? 'viewDisplayPreferenceDefaulItem'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSelectedViewDisplayPreference(item);
                                            handlePopoverVisibleChange();
                                        }}
                                    >
                                        {t(`${item.title}`)}
                                    </List.Item>
                                )}
                            />
                        }
                        placement={isScreenMd ? 'bottomRight' : 'left'}
                        open={popoverVisible}
                        onOpenChange={handlePopoverVisibleChange}
                    >
                        <ButtonWithTooltips
                            id={`${selectedViewDisplayPreference?.title}`}
                            className="select-view-display-preference-title"
                            tooltipTitle={t('config_view_display_preference')}
                            type="default"
                            // Логику сунул в scss чтобы тут не было много тернарников, ну и просто затестить
                            data-has-changes={hasChanges}
                            icon={<SettingOutlined />}
                            onClick={handlePopoverVisibleChange}
                        >
                            {t(`${selectedViewDisplayPreference?.title}`)}
                            {/* <span className="changes-indicator">*</span> TODO:нужно сделать чтобы при изменение ракурса только была эта звездочка, сейчас при изменение и ещё просто так показывается так как не все технические колонки которых нет в бд отсеивает из проверки */}
                        </ButtonWithTooltips>
                    </Popover>
                ) : null}
            </Suspense>
        </>
    );
};
