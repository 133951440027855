import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Database } from 'modules/supabase/types/database.types';

// const rootViewName: ViewName = 'vmisc_async_tasks';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type ErrorOutputProps = {
    task: ViewType;
};

export const ErrorOutput = ({ task }: ErrorOutputProps) => {
    const { t } = useTranslation();

    return (
        <div style={{ margin: '2rem' }}>
            <Alert
                message={t('error_processed_async_task')}
                description={task?.result_status_text}
                type="error"
                closable={false}
            />
        </div>
    );
};
