// ActionsPanel.tsx
import {
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    FormOutlined,
    ReloadOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { Dispatch, ReactNode, SetStateAction, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Flex } from 'antd';
import { TabItemProps } from 'components/Tabs/TabsBox';
import { toJS } from 'mobx';
import { ButtonWithTooltips } from 'ui';
import { useResize } from 'utils/hooks';
import { routeStore, useStoreNavigate } from 'utils/store';
import { TabsModal } from './TabsModal';

interface ActionsPanelProps {
    mode: 'view' | 'edit';
    onEdit: () => void;
    onCancel: () => Promise<boolean> | boolean;
    onClone: () => void;
    onSave: () => Promise<boolean> | boolean;
    onDelete: () => void;
    onRefresh: () => void;
    tabList: TabItemProps[];
    setTabList: Dispatch<SetStateAction<TabItemProps[]>>;
    disableUpdate: boolean;
    isRecordDeleted?: boolean;
    extraButtons: ReactNode[];
}

export const ActionsPanel = memo(
    ({
        mode,
        onEdit,
        onCancel,
        onClone,
        onSave,
        onDelete,
        onRefresh,
        tabList,
        setTabList,
        disableUpdate,
        isRecordDeleted = false,
        extraButtons
    }: ActionsPanelProps) => {
        const { t } = useTranslation();
        const navigate = useStoreNavigate();
        const { isScreenMd } = useResize();

        const { pathname, search, state } = useLocation();

        const [isTabsModalOpen, setTabsModalOpen] = useState<boolean>(false);

        const handleCloseNav = useCallback(() => {
            const closedIndex = routeStore.findIndex({ pathname, search, state });
            console.log('[ActionsPanel] closed route index:', closedIndex);

            const storeItem = toJS(routeStore.routes[closedIndex - 1]);
            console.log('[ActionsPanel] to route:', storeItem);

            navigate(
                { pathname: storeItem.pathname, search: storeItem.search },
                { state: storeItem.state }
            );

            routeStore.remove(routeStore.routes[closedIndex]);
        }, [navigate, pathname, search, state]);

        const handleSaveAndClose = useCallback(async () => {
            try {
                const isSuccessSave = await onSave();

                console.log('[ActionsPanel] is save success:', isSuccessSave);

                if (isSuccessSave) {
                    handleCloseNav();
                }
            } catch (err) {
                console.error(err);
            }
        }, [handleCloseNav, onSave]);

        const handleClose = useCallback(async () => {
            try {
                let isCancelSuccess = true;
                if (mode === 'edit') isCancelSuccess = await onCancel();

                console.log('[ActionsPanel] is cancel success:', isCancelSuccess);
                if (isCancelSuccess) {
                    handleCloseNav();
                }
            } catch (err) {
                console.error(err);
            }
        }, [handleCloseNav, mode, onCancel]);

        const buttonReload = (
            <ButtonWithTooltips
                id="reload"
                tooltipTitle={t('reload')}
                onClick={onRefresh}
                icon={<ReloadOutlined />}
            />
        );
        const buttonClone = (
            <ButtonWithTooltips
                id="clone"
                tooltipTitle={t('clone')}
                icon={<CopyOutlined />}
                onClick={onClone}
            />
        );

        return (
            <Flex justify="center" align="flex-start" gap={5}>
                {mode === 'view' && (
                    <>
                        {extraButtons}
                        <ButtonWithTooltips
                            id="delete"
                            tooltipTitle={t('delete')}
                            onClick={onDelete}
                            icon={<DeleteOutlined />}
                            disabled={isRecordDeleted}
                        />
                        {buttonClone}
                        {buttonReload}
                        <ButtonWithTooltips
                            id="edit"
                            onClick={onEdit}
                            icon={<FormOutlined />}
                            disabled={disableUpdate || isRecordDeleted}
                        >
                            {!isScreenMd ? t('edit') : null}
                        </ButtonWithTooltips>
                    </>
                )}
                {mode === 'edit' && (
                    <>
                        <ButtonWithTooltips
                            id="cancel"
                            className="btn-red"
                            onClick={onCancel}
                            icon={<CloseOutlined />}
                        >
                            {!isScreenMd ? t('cancel') : null}
                        </ButtonWithTooltips>
                        <ButtonWithTooltips
                            id="save"
                            type="default"
                            className=""
                            onClick={onSave}
                            icon={<SaveOutlined />}
                        >
                            {!isScreenMd ? t('save') : null}
                        </ButtonWithTooltips>
                        <ButtonWithTooltips
                            id="save_and_close"
                            onClick={handleSaveAndClose}
                            icon={<SaveOutlined />}
                        >
                            {!isScreenMd ? t('save_and_close') : <CloseOutlined />}
                        </ButtonWithTooltips>
                    </>
                )}
                {/* <ButtonWithTooltips
                    id="close"
                    type="text"
                    className=""
                    onClick={handleClose}
                    tooltipTitle={t('close')}
                    icon={<CloseOutlined />}
                /> */}
                <TabsModal
                    open={isTabsModalOpen}
                    setOpen={setTabsModalOpen}
                    tabList={tabList}
                    setTabList={setTabList}
                />
            </Flex>
        );
    }
);
