import { PlusOutlined } from '@ant-design/icons';
import { Drawer, List, Skeleton, Space } from 'antd';
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { SupabaseColumns } from 'modules/supabase/utils/tableCellUtils/generateColumnsFromType';
import { ButtonWithTooltips } from 'ui';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';
import { useNotifications, useResize } from 'utils/hooks';
import { TableFilterItem } from './TableFilterItem';
import './TableFilterMenuModal.scss';
import { FilterField, InitialFilterFieldValue, ValuesWithErrorType } from './TableFilterMenuTypes';
import {
    convertFilterFieldsToSupabaseFitler,
    getDefaultFilterOperator,
    isFieldFilterable
} from './TableFilterUtils';

export const TableFilterMenuModal = ({
    open,
    setOpen,
    columns,
    supabaseColumns,
    filters,
    setFilters,
    table,
    initFilterValues,
    showInModal = true,
    mode = 'edit'
}: {
    open?: boolean;
    setOpen?: Dispatch<boolean>;
    columns: MRTColumnDef[];
    supabaseColumns: SupabaseColumns[];
    filters: FilterField[];
    setFilters: Dispatch<FilterField[]>;
    table: Record<string, any>;
    initFilterValues?: InitialFilterFieldValue[];
    showInModal?: boolean;
    mode?: 'edit' | 'view';
}) => {
    const { t } = useTranslation();
    const { isScreenMd, isScreenFullHd } = useResize();

    const [appliedFilterFields, setAppliedFilterFields] = useState<FilterField[]>([]);
    const { openNotify } = useNotifications({ message: '' });
    const [initFilterApplied, setInitFilterApplied] = useState<boolean>(false);

    const availableFilters = useMemo(() => {
        const filterFields: string[] = [];
        if (columns)
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];

                if (
                    isFieldFilterable(column.accessorKey as string) &&
                    !appliedFilterFields.find(
                        (field) => field.field === (column.accessorKey as string)
                    )
                ) {
                    filterFields.push(column.accessorKey as string);
                }
            }

        return filterFields;
    }, [columns, appliedFilterFields]);

    const addAppliedField = useCallback(
        (field: FilterField) => {
            setAppliedFilterFields((prevValue) => {
                return [...prevValue, field];
            });
        },
        [setAppliedFilterFields]
    );

    const removeAppliedField = useCallback(
        (fieldName: string) => {
            setAppliedFilterFields((prevValue) => {
                const copy = [...prevValue];
                const fieldIndex = copy.findIndex((field) => field.field === fieldName);

                if (fieldIndex >= 0) {
                    copy.splice(fieldIndex, 1);
                }

                return copy;
            });
        },
        [setAppliedFilterFields]
    );
    // функция для сброса фильтров при клике на кнопку drop в футере
    const dropAllAppliedField = useCallback(() => {
        setFilters([]);
        setAppliedFilterFields([]);

        if (setOpen) {
            setOpen(false);
        }
    }, [setAppliedFilterFields, setFilters]);

    // функция для сохранения фильтров при клике на кнопку save в футере
    const applyFilter = useCallback(
        (appliedFilterFields: FilterField[]) => {
            if (showInModal) {
                const valuesWithError: ValuesWithErrorType[] = [];
                // Поиск всех объектов без свойства value
                appliedFilterFields.forEach((field) => {
                    field.values.forEach((valueObj) => {
                        if (valueObj.operator !== 'empty' && valueObj.operator !== 'notEmpty') {
                            if (valueObj.value === undefined || valueObj.value === null) {
                                valuesWithError.push({
                                    field: field.field,
                                    operator: valueObj.operator,
                                    error: 'withoutValue'
                                });
                            }
                        }
                    });
                });

                if (valuesWithError.length > 0) {
                    // Вывод сообщения для каждого объекта без значения value c ошибкой "withoutValue" которую запушили выше
                    valuesWithError.forEach((obj) => {
                        if (obj.error === 'withoutValue') {
                            openNotify({
                                message: `${t('filter_column')} '${t(obj.field as string)}' ${t(
                                    'with_operator'
                                )} '${t(obj.operator as string)}' ${t('not_have_value')}`,
                                // description: makeErrorReadable(error.message),
                                type: 'error',
                                duration: 0
                            });
                        }
                    });
                    return; // Прерываем выполнение, так как есть объекты c ошибками
                }
            }

            // если ошибок нет тогда сохраняем фильтры и закрываем модалку
            setFilters(appliedFilterFields);
        },
        [t, appliedFilterFields, setFilters, convertFilterFieldsToSupabaseFitler]
    );

    useEffect(() => {
        setInitFilterApplied(false);
    }, [initFilterValues]);

    useEffect(() => {
        if (
            !initFilterApplied &&
            initFilterValues &&
            supabaseColumns.length > 0 &&
            columns.length > 0
        ) {
            const copyFilter: FilterField[] = filters ? [...filters] : [];

            initFilterValues.forEach((filterValue) => {
                const filterOnDelete = copyFilter.findIndex(
                    (filter) => filter.field === filterValue.field
                );

                if (filterOnDelete >= 0) {
                    copyFilter.splice(filterOnDelete, 1);
                }

                const column = columns.find((col) => col.accessorKey === filterValue.field);

                const supabaseColumn = supabaseColumns.find(
                    (col) => col.column_name === filterValue.field
                );

                if (column && supabaseColumn) {
                    copyFilter.push({
                        field: filterValue.field as string,
                        fieldSetting: supabaseColumn,
                        UIColumn: column,
                        values: filterValue.values
                    });
                }
            });

            setInitFilterApplied(true);

            if (copyFilter.length > 0) {
                applyFilter(copyFilter);
            }
        }
    }, [initFilterApplied, initFilterValues, supabaseColumns, columns, applyFilter, filters]);

    useEffect(() => {
        setAppliedFilterFields(filters);
    }, [
        filters,
        supabaseColumns,
        columns,
        setAppliedFilterFields,
        initFilterValues,
        initFilterApplied
    ]);

    const changeAppliedFilter = useCallback(
        (changedfield: FilterField) => {
            const copy = [...appliedFilterFields];
            const fieldIndex = copy.findIndex((field) => field.field === changedfield.field);

            if (fieldIndex >= 0) {
                copy[fieldIndex] = {
                    ...copy[fieldIndex],
                    ...changedfield
                };
            }

            if (copy[fieldIndex].values.length === 0) {
                removeAppliedField(copy[fieldIndex].field);
            } else {
                setAppliedFilterFields(copy);
            }
        },
        [appliedFilterFields, setAppliedFilterFields, removeAppliedField]
    );

    useEffect(() => {
        if (!showInModal) {
            if (!_.isEqual(filters, appliedFilterFields) && appliedFilterFields.length > 0) {
                applyFilter(appliedFilterFields);
            }
        }
    }, [appliedFilterFields, showInModal, filters]);

    const modalContent = (
        <>
            <List
                className="applied_filters"
                header={`${t('applied_filters')}:`}
                dataSource={appliedFilterFields || []}
                locale={{ emptyText: <EmptyMarker size="small" /> }}
                itemLayout="horizontal"
                renderItem={(item) => (
                    <List.Item>
                        <Skeleton active loading={false}>
                            {/* <List.Item.Meta /> */}

                            <TableFilterItem
                                item={item}
                                changeItem={changeAppliedFilter}
                                table={table}
                                mode={mode}
                                removeAppliedField={removeAppliedField}
                            />
                        </Skeleton>
                    </List.Item>
                )}
            ></List>

            <List
                className="available_filters"
                header={`${t('available_filters')}:`}
                dataSource={availableFilters || []}
                itemLayout="horizontal"
                pagination={{
                    position: 'bottom',
                    align: 'center',
                    simple: true,
                    responsive: true,
                    showQuickJumper: false,
                    showSizeChanger: false,
                    pageSize: isScreenMd ? 7 : isScreenFullHd ? 13 : 17
                }}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <PlusOutlined
                                onClick={() => {
                                    const filterField: FilterField = {
                                        field: item,
                                        fieldSetting: supabaseColumns.find(
                                            (column) => column.column_name === item
                                        ) as SupabaseColumns,
                                        UIColumn: columns.find(
                                            (column) => column.accessorKey === item
                                        ) as MRTColumnDef,
                                        values: []
                                    };

                                    filterField.values.push({
                                        operator: getDefaultFilterOperator(filterField)
                                    });

                                    addAppliedField(filterField);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    visibility: mode === 'view' ? 'hidden' : undefined
                                }}
                            />
                        ]}
                    >
                        <List.Item.Meta title={t(item)} />
                    </List.Item>
                )}
            ></List>
        </>
    );

    return (
        <>
            <Drawer
                title={t('filter_modal_title')}
                placement="right"
                width={isScreenMd ? '100%' : '33%'}
                onClose={() => {
                    if (setOpen) {
                        setOpen(false);
                    }
                }}
                id="table_filter_menu"
                open={open}
                extra={
                    <Space>
                        <ButtonWithTooltips
                            id="drop"
                            key="drop"
                            className="btn-red"
                            onClick={dropAllAppliedField}
                        >
                            {t('drop')}
                        </ButtonWithTooltips>

                        <ButtonWithTooltips
                            id="save"
                            key="save"
                            className="btn-blue"
                            onClick={() => {
                                applyFilter(appliedFilterFields);

                                if (setOpen) {
                                    setOpen(false);
                                }
                            }}
                        >
                            {t('save')}
                        </ButtonWithTooltips>
                    </Space>
                }
            >
                {modalContent}
            </Drawer>
        </>
    );
};
