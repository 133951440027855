import { CheckOutlined, CloseOutlined, InfoOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Database } from 'modules/supabase/types/database.types';
import { ProcessLog } from './ProcessLog';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

interface FileImportOutputProps {
    task: ViewType;
}

export const FileImportOutput = ({ task }: FileImportOutputProps) => {
    return (
        <>
            <ProcessLog task={task} />

            {/* <div style={{ margin: '2rem' }}>
                <Alert message={t('file_import_successfully')} type="success" closable={false} />
            </div> */}
        </>
    );
};
