import { MetaField } from 'modules/services/backend-api/generated_info';
import { fieldEditRender as render } from 'smart/utils';
import { IObjectWithId } from 'utils/store/MetaStore';

// TODO: Hightlighter
// value.toString().includes(searchString) ? (
//     <Highlighter
//         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
//         searchWords={[searchText]}
//         autoEscape
//         textToHighlight={text ? text.toString() : ''}
//     />
// ) : (
//     value
// );

type FieldRenderType = {
    (options: {
        field: MetaField;
        language: string;
        fields: MetaField[];
        rootMeta?: string;
        rootDataSource?: IObjectWithId;
    }): (
        value: any,
        row: any,
        rowIndex: any,
        onChange: (key: string, value: any) => void
    ) => React.ReactNode;
};

export const fieldEditRender: FieldRenderType = ({ field, language, fields, rootMeta }) => {
    return (value, row, rowIndex, onChange) => {
        return render({
            data: value,
            dataSource: row,
            language,
            metaFieldData: fields,
            fieldName: field.FieldName,
            onChange,
            isForTable: true,
            rootMeta
        });
    };
};
