export function filterData(data: any[], filterString: string, fields: string[], language: string) {
    return data.filter((item) => {
        if (!fields.length) return true;
        return fields.some((field) => {
            if (field.includes('code')) return false;
            if (item[field] !== null) {
                if (typeof item[field] === 'string' || typeof item[field] === 'number') {
                    return String(item[field]).toLowerCase().includes(filterString.toLowerCase());
                }
                if (typeof item[field] === 'object' && item[field][language]) {
                    return String(item[field][language])
                        .toLowerCase()
                        .includes(filterString.toLowerCase());
                }
                return false;
            }
            return false;
        });
    });
}
