// DetailPage_DataTable.tsx
import { MRT_Row, MRT_ShowHideColumnsButton, MRT_TableInstance } from 'material-react-table';
import React, {
    MutableRefObject,
    ReactNode,
    Suspense,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import { BaseDataTable } from 'components/tables';
import { DatasetTable, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { onChangeTableDataType } from 'modules/supabase/utils/tableCellUtils/generateColumnsFromType';
import { useModeContext, useTableContext } from 'pages/base/DetailPage/BaseDetailPageProvider';
import { Loader } from 'ui/Loader/Loader';
import { SkeletonLoader } from 'ui/Skeleton/Skeleton';
import { DetailPage_DataTableToolbar } from './DetailPage_DataTableToolbar';
import type { MRTColumnDef, MRTRow, MRTTableInstance } from './MRT_Types';
import { useGenerateColumns } from './useGenerateColumns';
import { TableRefType } from './useTableBackendFeatures';

const LazyBase_DataTable = React.lazy(() =>
    import('components/DataTable/Base_DataTable').then(({ Base_DataTable }) => ({
        default: Base_DataTable
    }))
);

const LazyLoader = React.lazy(() =>
    import('ui/Loader/Loader').then(({ Loader }) => ({
        default: Loader
    }))
);

type DeleteExtraFunstionType = (
    selectedRows?: Record<string, boolean>[] | Record<string, boolean>,
    otherTable?: DatasetTable
) => void;

interface DetailPage_DataTable_Interface {
    childViewName: ViewName;
    predefinedColumns: MRTColumnDef[];
    onClickRow?: ((row: MRTRow) => void) | ((rows: MRTRow[]) => void);
    onSelectedRows?: (selectedLength: number) => void;
    onCreateNewRow?: () => void;
    canRowBeDeleted?: (row: Record<string, boolean>, data: DatasetTable) => boolean;
    canRowBeAdded?: boolean;
    validateFieldChange?: (
        row: Record<string, any>,
        updatedFields: Record<string, any>,
        rowIndex: number
    ) => boolean;
    validateDataChange?: (
        row: Record<string, any>,
        updatedFields: any,
        rowIndex: number
    ) => boolean;
    enableExpandAll?: boolean;
    enableExpanding?: boolean;
    filterFromLeafRows?: boolean;
    paginateExpandedRows?: boolean;
    enableRowOrdering?: boolean;
    maxRows?: number;
    columnVisibilityKey?: string;
    enableRowSelection?: boolean;
    isViewOnly?: boolean;
    localFilterData?: Function;
    onChangeRowAddExtraFieldsOnUpdate?: (
        /* Нужен был в WarehouseStorageLocationsTab т.к колонка была овер63 символа и не помещалась при выборе с prefixSelectField, сократил название колонки, а этой настройкой прокинул доп замену поля id в доксе от id сокращеной колокни вью, но возможны и множество других приминений */
        rowIndex: number,
        changeData: Record<string, any> | null,
        tableData: DatasetTable
    ) => Record<string, any>;
    enableMultiRowSelection?: boolean;
    filters?: Filter[];
    deleteExtraFunction?: DeleteExtraFunstionType;
    customToolbar?: ReactNode;
    customNewRowData?: Partial<TableRow>;
    renderDetailPanel?: (props: { row: MRT_Row<any>; table: MRT_TableInstance<any> }) => ReactNode;
    extraExpandHide?: (props: { row: MRT_Row<any>; table?: MRT_TableInstance<any> }) => boolean;
    enableToolbarInternalActions?: boolean;
    enableEditing?: boolean;
    extraToolbar?: React.ReactNode;
    lazy?: boolean;
}

interface LazyWrapperProps extends React.PropsWithChildren {
    showIndicator: boolean;
    loaderIndicator: boolean;
    viewName: ViewName;
    enableLazy: boolean;
}

const LazyWrapper = memo<LazyWrapperProps>(
    ({ children, showIndicator, loaderIndicator, viewName, enableLazy }) => {
        return (
            <>
                {enableLazy ? (
                    <Suspense fallback={<SkeletonLoader />}>
                        {showIndicator ? (
                            children
                        ) : (
                            <LazyLoader status={loaderIndicator}>
                                <LazyBase_DataTable
                                    viewName={viewName}
                                    data={[]}
                                    columns={[]}
                                    renderBottomToolbar={() => <></>}
                                />
                            </LazyLoader>
                        )}
                    </Suspense>
                ) : (
                    <Loader status={loaderIndicator}>{children}</Loader>
                )}
            </>
        );
    }
);

interface StateRowsInterface {
    draggingRow: any;
    hoveredRow: any;
}

export type TableRowWithChangeFunction = TableRow & {
    changeRow: (rowData: any) => void;
    changeRowField: (columnName: string | null, value: any) => void;
};

const tablePageContainerStyles = { maxHeight: 'calc(100vh - 20rem)' };

const muiTableContainerProps = {
    sx: tablePageContainerStyles
};

export const DetailPage_DataTable = memo(
    forwardRef<TableRefType, DetailPage_DataTable_Interface>(
        (
            {
                childViewName,
                predefinedColumns,
                canRowBeAdded,
                maxRows,
                filterFromLeafRows,
                paginateExpandedRows,
                columnVisibilityKey,
                localFilterData,
                onClickRow,
                onSelectedRows,
                onCreateNewRow,
                canRowBeDeleted,
                onChangeRowAddExtraFieldsOnUpdate,
                enableExpandAll = false,
                enableExpanding = false,
                enableRowOrdering = true,
                isViewOnly = false,
                enableMultiRowSelection = true,
                enableRowSelection = true,
                filters = [],
                deleteExtraFunction,
                validateFieldChange = () => true,
                validateDataChange = () => true,
                customToolbar,
                customNewRowData,
                renderDetailPanel,
                extraExpandHide,
                enableToolbarInternalActions = true,
                enableEditing = true,
                extraToolbar,
                lazy = false
            },
            ref
        ) => {
            const [mode] = useModeContext();

            const [filteredTable, setFilteredTable, , originalData] =
                useTableContext(childViewName);
            const [tableData, setTableData] = useState<TableRowWithChangeFunction[]>([]);
            const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

            // TODO: Переделать на примере setDataset. Чтоб бралось текущие изменения
            const handleRowChange: onChangeTableDataType = useCallback(
                (rowId, changedData) => {
                    const copyData = { ...filteredTable };
                    let extraFields = {};

                    const rowIndex = copyData.rows.findIndex((row) => row.id === rowId);

                    const state = copyData.rows[rowIndex]._state;
                    // copyData.rows[rowIndex].root_id = dataset.root_id;
                    if (onChangeRowAddExtraFieldsOnUpdate) {
                        extraFields = onChangeRowAddExtraFieldsOnUpdate(
                            rowIndex,
                            changedData,
                            copyData
                        );
                    }

                    copyData.rows[rowIndex] = {
                        ...copyData.rows[rowIndex],
                        ...changedData,
                        ...extraFields,
                        _state: state !== 'Deleted' && state !== 'Inserted' ? 'Updated' : state
                    };
                    setFilteredTable(copyData);
                },
                [filteredTable, onChangeRowAddExtraFieldsOnUpdate, setFilteredTable]
            );

            // Функция по добавлении новой строчки по умолчанию, вызывается при условии если нет onCreateNewRow
            const handleNewRow = useCallback(() => {
                // const copyTable = { ...filteredTable };
                const copyTable = { ...originalData };
                const lastRowIndex = copyTable?.rows?.length ?? 0;

                // Создаем массив, содержащий все child_index
                const childIndexes = copyTable.rows.map((row) => row.child_index);

                // Находим максимальное значение в массиве childIndexes
                const maxChildIndex = Math.max(...childIndexes);

                const newRow: TableRow = {
                    id: 0, // Так как insert будет заменен из генератора
                    _state: 'Inserted',
                    child_index: maxChildIndex > 0 ? maxChildIndex + 1 : lastRowIndex + 1 // поменял логику чтобы если есть child_index у таблицы то его макс значение +1, а если у таблицы нет child_index то количество строк +1
                };

                const combinedRow: TableRow = {
                    ...newRow,
                    ...(customNewRowData || {}) // Используем combinedRow, если он определен, иначе пустой объект
                };
                const newTable = { ...copyTable, rows: [...copyTable.rows, combinedRow] };

                setFilteredTable(newTable);
            }, [customNewRowData, originalData, setFilteredTable]);

            // Функции могут переопределятся родительским компонентов
            const changeData = handleRowChange;
            const createNewRow = onCreateNewRow || handleNewRow;

            // do something when the row selection changes...
            useEffect(() => {
                if (onSelectedRows) {
                    onSelectedRows(Object.keys(selectedRows).length);
                }
            }, [selectedRows]);

            useEffect(() => {
                // В полученные строки передаем функции для изменения - необходимо для генерации колонок
                if (filteredTable.rows) {
                    // Не показываем удаленные записи
                    const rowsDataWithChangedFunction = filteredTable?.rows
                        .filter((row) => row._state !== 'Deleted')
                        .map((rowData, index) => {
                            return {
                                ...rowData,
                                // Функция для изменения всей строки/несколько полей в строчке(по умолчанию работает по деструктуризации(newValue = {...newValue, ...rowData}))
                                changeRow: (changedData: any) => {
                                    const field: Record<string, any> = { ...changedData };
                                    if (validateDataChange(rowData, changedData, index)) {
                                        changeData(rowData.id, field);
                                    }
                                },
                                // Функция для изменения одного поля в строчке
                                changeRowField: (columnName: string | null, value: any) => {
                                    const field: Record<string, any> = {};
                                    // Если задан columnName - обновляем поле по columnName
                                    if (columnName) {
                                        field[columnName] = value;
                                    }
                                    if (validateFieldChange(rowData, field, index)) {
                                        changeData(rowData.id, field);
                                    }
                                }
                            };
                        });
                    if (enableRowOrdering) {
                        // Если enobleRowOrdering = true и есть child_index тогда сортируем по нему
                        rowsDataWithChangedFunction.sort(
                            (a: Record<string, any>, b: Record<string, any>) => {
                                if (a.child_index !== undefined && b.child_index !== undefined) {
                                    if (a.child_index < b.child_index) {
                                        return -1;
                                    }
                                    if (a.child_index > b.child_index) {
                                        return 1;
                                    }
                                }
                                return 0;
                            }
                        );
                    }

                    setTableData(rowsDataWithChangedFunction || []);
                }
            }, [filteredTable, changeData]);

            useEffect(() => {
                if (onClickRow) {
                    const rows = Object.keys(selectedRows).map((key) => ({
                        id: key,
                        index: Number(key) - 1
                    }));

                    onClickRow(rows as any);
                }
            }, [selectedRows]);

            const tableInstanceRef: MutableRefObject<MRTTableInstance | null> = useRef(null);

            const {
                columns,
                columnVisibility,
                setColumnVisibility,
                columnOrdering,
                setColumnOrdering
            } = useGenerateColumns({
                viewOrTableName: childViewName,
                predefinedColumns,
                enableRowOrdering,
                enableRowSelection: true
            });

            const deleteRows = useCallback(() => {
                const copyData = { ...filteredTable };

                copyData.rows.forEach((row, index) => {
                    if (selectedRows[row.id] === true) {
                        copyData.rows[index]._state = 'Deleted';
                        setSelectedRows((old) => ({
                            ...old,
                            [row.id]: false
                        }));
                    }
                });

                const filteredNewRows = copyData.rows.filter(
                    (row) => !(row._state === 'Deleted' && row.id <= 0)
                );

                const newCopyData = { ...copyData, rows: [...filteredNewRows] };

                if (!deleteExtraFunction) {
                    setFilteredTable(newCopyData);
                } else {
                    deleteExtraFunction(selectedRows, newCopyData);
                }
            }, [selectedRows, filteredTable, setFilteredTable, deleteExtraFunction]);

            const handleDragEnd = useCallback(
                (table: MRTTableInstance) => {
                    // Шаг 1: Получение информации о перемещаемой и наведенной строках
                    const { draggingRow, hoveredRow }: StateRowsInterface = table.getState();
                    if (draggingRow && hoveredRow) {
                        const copyData = { ...filteredTable };
                        const draggedRowIndex = draggingRow.index;
                        const hoveredRowIndex = hoveredRow.index;
                        const draggedRow = copyData.rows[draggedRowIndex];
                        // Шаг 2: Удаляем перемещаемую строку из массива и вставляем ее на новое место
                        copyData.rows.splice(draggedRowIndex, 1);
                        copyData.rows.splice(hoveredRowIndex, 0, draggedRow);

                        // Шаг 3: Обновляем индексы child_index после перемещения строк
                        // 3.1: Находим стартовый индекс для обновления
                        const startIndex = Math.min(draggedRowIndex, hoveredRowIndex);
                        // 3.2: Находим максимальный индекс child_index среди всех строк
                        const maxIndex = Math.max(...copyData.rows.map((row) => row.child_index));

                        const updatedRows = copyData.rows.map((row, index) => {
                            if (index >= startIndex) {
                                // 3.3: Обновляем child_index каждой строки, начиная с startIndex,
                                // с учетом максимального индекса, чтобы сохранить уникальность
                                const rowCopy = row;
                                rowCopy.child_index = maxIndex + index; // поправил на index, нужно было для правильного перемещения Разрешеных видов запаса на участке храненеия
                                rowCopy._state = 'Updated';
                                return rowCopy;
                            }
                            return row;
                        });

                        const newCopyData = { ...copyData, rows: updatedRows };
                        setFilteredTable(newCopyData);
                    }
                },
                [filteredTable, setFilteredTable]
            );

            useImperativeHandle(
                ref,
                () => ({
                    tableApi: tableInstanceRef,
                    data: tableData as TableRow[],
                    states: {
                        columnVisibility,
                        rowSelection: selectedRows
                    }
                }),
                [tableInstanceRef, tableData, columnVisibility, selectedRows]
            );

            const renderToolbar = customToolbar || (
                <DetailPage_DataTableToolbar
                    mode={mode as 'view' | 'edit'}
                    columns={columns}
                    filters={filters}
                    onAddNewRowButtonPress={createNewRow}
                    onDeleteRowButtonPress={deleteRows}
                    selectedRows={selectedRows}
                    setFilteredTable={setFilteredTable}
                    data={filteredTable}
                    canRowBeDeleted={canRowBeDeleted}
                    canRowBeAdded={canRowBeAdded}
                    childViewName={childViewName}
                    maxRows={maxRows}
                    extraToolbar={extraToolbar}
                />
            );

            const renderToolbarInternalActions = useCallback(
                ({ table }: { table: MRTTableInstance }) => {
                    return <MRT_ShowHideColumnsButton table={table} className="btn-base-toolbar" />;
                },
                []
            );

            useEffect(() => {
                if (mode === 'view') {
                    setSelectedRows({});
                }
            }, [mode]);

            const DataTable = lazy ? LazyBase_DataTable : BaseDataTable;

            const paginationOption = maxRows
                ? {
                      pagination: {
                          pageSize: maxRows,
                          pageIndex: 0
                      }
                  }
                : undefined;

            console.log(columns);

            return (
                <LazyWrapper
                    enableLazy={lazy}
                    loaderIndicator={columns && columns.length === 0}
                    showIndicator={!!(tableData?.length && columns?.length)}
                    viewName={childViewName}
                >
                    <DataTable
                        viewName={childViewName}
                        renderTopToolbarCustomActions={() => {
                            // Отображаем пустой div, что бы функции самой таблицы были справа
                            return isViewOnly ? <div /> : renderToolbar;
                        }}
                        renderToolbarInternalActions={renderToolbarInternalActions}
                        enableMultiRowSelection={enableMultiRowSelection}
                        tableInstanceRef={tableInstanceRef}
                        enableRowSelection={enableRowSelection}
                        enableRowOrdering={enableRowOrdering}
                        enableSorting={false}
                        enableColumnOrdering
                        // enableGlobalFilter
                        getRowId={(row) => (row as TableRow).id.toString()}
                        enableColumnFilterModes
                        enableHiding
                        editDisplayMode="table"
                        enableEditing={mode === 'edit' || enableEditing}
                        data={
                            localFilterData
                                ? tableData.filter(
                                      localFilterData as (
                                          value: TableRowWithChangeFunction,
                                          index: number,
                                          array: TableRowWithChangeFunction[]
                                      ) => unknown
                                  )
                                : tableData
                        }
                        columns={columns}
                        onColumnOrderChange={setColumnOrdering}
                        onRowSelectionChange={setSelectedRows}
                        onColumnVisibilityChange={setColumnVisibility}
                        state={{
                            columnVisibility,
                            columnOrder: columnOrdering,
                            rowSelection: selectedRows,
                            ...paginationOption
                        }}
                        muiEditTextFieldProps={({ column, row }) => ({
                            onBlur: (event) => {
                                if (column.columnDef.accessorKey) {
                                    const field: Record<string, any> = {};

                                    field[column.columnDef.accessorKey as string] =
                                        event.target.value;

                                    changeData(row.index, field);
                                }
                            },
                            variant: 'outlined'
                        })}
                        muiRowDragHandleProps={({ table }) => ({
                            onDragEnd: () => {
                                handleDragEnd(table as MRTTableInstance);
                            }
                        })}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (e) => {
                                const t = e.target as any;

                                if (
                                    mode === 'view' ||
                                    (!t.className?.includes('ant-picker') &&
                                        t.nodeName !== 'INPUT' &&
                                        t.nodeName !== 'BUTTON' &&
                                        t.parentElement.nodeName !== 'BUTTON' &&
                                        mode === 'edit')
                                ) {
                                    if (enableMultiRowSelection) {
                                        setSelectedRows(() => ({
                                            [row.id]: true
                                        }));
                                    } else {
                                        row.toggleSelected(!row.getIsSelected());
                                    }
                                }
                            },

                            sx: onClickRow ? { cursor: 'pointer' } : { cursor: 'default' }
                        })}
                        muiTableContainerProps={muiTableContainerProps}
                        enableBottomToolbar={false}
                        enableExpandAll={enableExpandAll}
                        enableExpanding={enableExpanding}
                        filterFromLeafRows={filterFromLeafRows}
                        paginateExpandedRows={paginateExpandedRows}
                        columnVisibilityKey={childViewName || columnVisibilityKey}
                        enableToolbarInternalActions={enableToolbarInternalActions}
                        renderDetailPanel={renderDetailPanel}
                        extraExpandHide={extraExpandHide}
                        enablePagination={false}
                        mobileOptions={{
                            titleColumns: [
                                {
                                    name: columns && columns[1] ? columns[1].accessorKey : 'id',
                                    accessorKeySubstr:
                                        columns && columns[1] ? columns[1].accessorKey : 'id'
                                }
                            ],
                            avatarColumn: {
                                name: columns && columns[0] ? columns[0].accessorKey : 'id',
                                accessorKeySubstr:
                                    columns && columns[0] ? columns[0].accessorKey : 'id'
                            }
                        }}
                        enableRowVirtualization
                    />
                </LazyWrapper>
            );
        }
    )
);
