import { PostgrestError } from '@supabase/postgrest-js';
import { MRT_FilterOption } from 'material-react-table';
import { Dispatch, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { FilterField } from 'components/DataTable/TableFilterMenu/TableFilterMenuTypes';
import { Database } from 'modules/supabase/types/database.types';
import { ViewName } from '../../types/Dataset';

type UseViewDisplayPreferencesProps = {
    viewName: ViewName;
};

export type ViewDisplayPreferences =
    | Database['public']['Tables']['settings_user_view_display_preferences']['Row']
    | Record<string, any>;

export type ViewDisplayPreferenceSettings = {
    columns: string[];
    sort: { [key: string]: 'asc' | 'desc' };
    page_size: number; // в ракурсе только размер страницы а индекс не храним т.к. это будет странно загружать данные не на первой странице
    complexFilter?: FilterField[];
    filter?: {
        id: string;
        operator: MRT_FilterOption;
        value: any;
    }[];

    columnSizing?: {
        [key: string]: number;
    };

    grouping?: string[];
    is_loaded?: boolean;
    title?: string;
};

export type ViewDisplayPreferenceFetchResult = {
    data: ViewDisplayPreferences[];
    isLoading: boolean;
    error: PostgrestError | null;
};

type ViewDisplayPreferenceDispatchAction =
    | {
          type: 'ADD_VIEW_DISPLAY';
          payload: {
              viewName: ViewName;
              callBackWhenFetched?: (fetchResult: ViewDisplayPreferenceFetchResult) => void;
          };
      }
    | {
          type: 'UPDATE_VIEW_DISPLAY';
          payload: { viewName: ViewName };
      }
    | {
          type: 'VIEW_DISPLAY_LOADED';
          payload: { viewName: ViewName; fetchResult: ViewDisplayPreferenceFetchResult };
      }
    | {
          type: 'INIT';
          payload: Partial<{
              UserID: string;
              fetchViewDisplayPreference: (
                  UserID: string,
                  viewName: ViewName,
                  callBackWhenFetched?: (data: ViewDisplayPreferenceFetchResult) => void
              ) => void;
              preferences: Partial<Record<ViewName, ViewDisplayPreferenceFetchResult>> | null;
          }>;
      };

type ViewDisplayPreferenceStateType = {
    UserID: string | null;
    preferences: Partial<Record<ViewName, ViewDisplayPreferenceFetchResult>>;
    fetchViewDisplayPreference:
        | ((
              UserID: string,
              viewName: ViewName,
              callBackWhenFetched?: (data: ViewDisplayPreferenceFetchResult) => void
          ) => void)
        | null;
};

export const ViewDisplayPreferenceContext = createContext<{
    state: ViewDisplayPreferenceStateType;
    dispatch: Dispatch<ViewDisplayPreferenceDispatchAction>;
}>({
    state: {
        UserID: null,
        preferences: {},
        fetchViewDisplayPreference: () => {}
    },
    dispatch: () => {}
});

export const ViewDisplayPreferencesReducer = (
    state: ViewDisplayPreferenceStateType,
    action: ViewDisplayPreferenceDispatchAction
): ViewDisplayPreferenceStateType => {
    // Копируем состояние
    const copyState: ViewDisplayPreferenceStateType = {
        ...state
    };

    if (!copyState.preferences) {
        copyState.preferences = {};
    } else {
        copyState.preferences = {
            ...state.preferences
        };
    }

    switch (action.type) {
        // Запрос на добавление VDF
        case 'ADD_VIEW_DISPLAY':
            if (copyState.fetchViewDisplayPreference && copyState.UserID) {
                // Проверяем есть ли уже загруженные VDF
                if (!copyState.preferences[action.payload.viewName]) {
                    // Если нет запрашиваем
                    copyState.preferences[action.payload.viewName] = {
                        data: [],
                        error: null,
                        isLoading: true
                    };
                    copyState.fetchViewDisplayPreference(
                        copyState.UserID,
                        action.payload.viewName,
                        action.payload.callBackWhenFetched
                    );
                } else if (
                    (action.payload.callBackWhenFetched, action.payload.callBackWhenFetched)
                ) {
                    // Если уже такой VDF есть возвращаем если есть callback
                    const preferences = copyState.preferences[action.payload.viewName];
                    if (preferences) {
                        if (preferences.data.length > 0)
                            action.payload.callBackWhenFetched(preferences);
                    }
                }
            }

            return copyState;

        // TODO: сделать автообновление
        case 'UPDATE_VIEW_DISPLAY':
            if (copyState.fetchViewDisplayPreference && copyState.UserID) {
                copyState.fetchViewDisplayPreference(copyState.UserID, action.payload.viewName);
            }

            return copyState;

        // Инициализация VDF хранилища
        case 'INIT':
            console.log('INIT VIEW DISPLAY PREFERENCE');
            // Передаем функцию которая определяется в ViewDisplayPreferencesProvider
            if (action.payload.fetchViewDisplayPreference) {
                copyState.fetchViewDisplayPreference = action.payload.fetchViewDisplayPreference;
            }

            if (action.payload.UserID) {
                copyState.UserID = action.payload.UserID;
            }

            copyState.preferences = {};

            return copyState;

        // действие когда VDF загружен и надо обновить хранилище
        case 'VIEW_DISPLAY_LOADED':
            copyState.preferences[action.payload.viewName] = action.payload.fetchResult;

            return copyState;
        default:
            return copyState;
    }
};

// React hook that gets current user's table display preferences (ракурсы отображения таблицы)
export const useViewDisplayPreferences = ({ viewName }: UseViewDisplayPreferencesProps) => {
    // рендерится дважны - сначала isLoading = true, потом false (но useEffect уже не вызывается т.к. tableName не изменилось)
    const [data, setData] = useState<ViewDisplayPreferenceFetchResult>({
        data: [],
        error: null,
        isLoading: false
    });

    const [vdfLoaded, setVdfLoaded] = useState<boolean>(false);

    const {
        state: { preferences, UserID },
        dispatch
    } = useContext(ViewDisplayPreferenceContext);

    useEffect(() => {
        if (viewName && UserID) {
            const viewDisplayPreferences = preferences[viewName];

            if (!viewDisplayPreferences) {
                dispatch({
                    type: 'ADD_VIEW_DISPLAY',
                    payload: {
                        viewName,
                        callBackWhenFetched: (preferences) => {
                            if (preferences.data.length > 0) setData(preferences);
                            setVdfLoaded(true);
                        }
                    }
                });
            } else {
                setData(viewDisplayPreferences);
                setVdfLoaded(true);
            }
        }
    }, [viewName, UserID, dispatch, preferences]);

    return { data: data.data, error: data.error, isLoading: data.isLoading, vdfLoaded };
};

export const useSelectedViewDisplayPreference = ({
    viewName,
    title
}: {
    viewName: ViewName;
    title?: string;
    enableFetch?: boolean;
}): {
    viewDisplayPreferenceSetting: ViewDisplayPreferenceSettings | null;
    selectedViewDisplayPreference: ViewDisplayPreferences | null;
    setSelectedViewDisplayPreference: Dispatch<ViewDisplayPreferences>;
    vdfLoaded: boolean;
} => {
    const [selectedViewDisplayPreference, setSelectedViewDisplayPreference] =
        useState<ViewDisplayPreferences | null>(null);

    const {
        data: manyViewDisplayPreferences,
        isLoading,
        error,
        vdfLoaded
    } = useViewDisplayPreferences({
        viewName
    });

    useEffect(() => {
        // Если задан конкретный ракурс - возврвщаем конкретный ракурс
        if (title) {
            const viewDisplayPreferenceByTitle = manyViewDisplayPreferences.find(
                (vdf) => vdf.title === title
            );
            if (viewDisplayPreferenceByTitle) {
                setSelectedViewDisplayPreference(viewDisplayPreferenceByTitle);
            }
        } else {
            // Ищем личный ракурс по умолчанию
            const defaultUserViewDisplayPreference = manyViewDisplayPreferences.find(
                (vdf) => vdf.as_default === true && vdf.parent_user_uuid !== null
            );

            if (defaultUserViewDisplayPreference) {
                setSelectedViewDisplayPreference(defaultUserViewDisplayPreference);
            } else {
                // Ищем общий ракурс по умолчанию который не системный
                const defaultViewDisplayPreference = manyViewDisplayPreferences.find(
                    (vdf) => vdf.as_default === true && vdf.title !== 'Default'
                );

                if (defaultViewDisplayPreference) {
                    setSelectedViewDisplayPreference(defaultViewDisplayPreference);
                } else {
                    // Ищем системный ракурс по умолчанию
                    const defaultViewDisplayPreference = manyViewDisplayPreferences.find(
                        (vdf) => vdf.title === 'Default'
                    );

                    if (defaultViewDisplayPreference) {
                        setSelectedViewDisplayPreference(defaultViewDisplayPreference);
                    }
                }
            }
        }
    }, [manyViewDisplayPreferences, title]);

    const settings = useMemo((): ViewDisplayPreferenceSettings | null => {
        if (selectedViewDisplayPreference && selectedViewDisplayPreference.settings) {
            return {
                ...selectedViewDisplayPreference.settings,
                title: selectedViewDisplayPreference.title
            } as ViewDisplayPreferenceSettings;
        }
        return null;
    }, [selectedViewDisplayPreference]);

    return {
        viewDisplayPreferenceSetting: settings,
        selectedViewDisplayPreference,
        setSelectedViewDisplayPreference,
        vdfLoaded
    };
};

// Хук возвращает ViewDisplayPreferences по ViewName и Title
export const useViewDisplayPreferenceByTitle = ({
    viewDisplayPreferenceTitle,
    viewName
}: {
    viewName: ViewName;
    viewDisplayPreferenceTitle?: string;
}): ViewDisplayPreferenceSettings | null => {
    const [viewDisplayPreferenceSettings, setViewDisplayPreferenceSettings] =
        useState<ViewDisplayPreferenceSettings>();

    const displayPrefOptions = useMemo(() => ({ viewName }), [viewName]);

    const {
        data: manyViewDisplayPreferences,
        isLoading,
        error
    } = useViewDisplayPreferences(displayPrefOptions);

    useEffect(() => {
        if (manyViewDisplayPreferences && viewDisplayPreferenceTitle) {
            const viewDisplayPreference = manyViewDisplayPreferences.find(
                (pref) => pref.title === viewDisplayPreferenceTitle
            );

            if (viewDisplayPreference) {
                setViewDisplayPreferenceSettings(
                    viewDisplayPreference.settings as ViewDisplayPreferenceSettings
                );
            }
        }
    }, [manyViewDisplayPreferences, isLoading, error, viewDisplayPreferenceTitle]);

    return viewDisplayPreferenceSettings || null;
};

// Хук возвращает первую запись ViewDisplayPreferences по ViewName
export const useFirstViewDisplayPreference = ({ viewName }: UseViewDisplayPreferencesProps) => {
    const [viewDisplayPreferenceSettings, setViewDisplayPreferenceSettings] =
        useState<ViewDisplayPreferenceSettings>();

    const displayPrefOptions = useMemo(() => ({ viewName }), [viewName]);

    const { data: manyViewDisplayPreferences, vdfLoaded } =
        useViewDisplayPreferences(displayPrefOptions);

    useEffect(() => {
        if (manyViewDisplayPreferences && manyViewDisplayPreferences.length) {
            const viewDisplayPreference = manyViewDisplayPreferences[0];

            setViewDisplayPreferenceSettings(
                viewDisplayPreference.settings as ViewDisplayPreferenceSettings
            );
        }
    }, [manyViewDisplayPreferences, viewName]);

    return { viewDisplayPreferenceSettings, vdfLoaded };
};
