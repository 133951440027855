import { Select, SelectProps, TreeSelect, TreeSelectProps } from 'antd';
import { memo } from 'react';

export const SmartSelectInner = memo<
    SelectProps &
        TreeSelectProps & {
            disableTree?: boolean;
        }
>((props) => {
    if (props.disableTree)
        return (
            <Select
                {...props}
                mode={props.multiple || props.mode === 'multiple' ? 'multiple' : props.mode}
            />
        );

    return (
        <TreeSelect
            {...props}
            treeData={props.options as TreeSelectProps['treeData']}
            treeIcon
            treeNodeLabelProp="label"
            multiple={props.multiple || props.mode === 'multiple'}
        />
    );
});
