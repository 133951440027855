import { Typography } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';
import { InspectModuleComponentAccess } from 'components/ModuleComponentAccess/ModuleComponentAccess';

interface GroupCollapseProps extends React.PropsWithChildren {
    titleTextId?: string;
}

const styles = { marginTop: 5 };

export const GroupCollapse = memo<GroupCollapseProps>(
    ({ children, titleTextId = 'other_information' }) => {
        const { t } = useTranslation();
        const dataset = useDataset(false);
        return (
            <InspectModuleComponentAccess
                component_name={titleTextId}
                component_path={(dataset.length > 0 && dataset[0]?.tables[0]?.tableName) || ''}
            >
                {/* без предупреждения не правьте строку пож */}
                <div className="collapse-custom" id={titleTextId} style={styles}>
                    <Typography.Title level={4} style={{ marginTop: 12 }}>
                        {t(titleTextId)}
                    </Typography.Title>
                    <div className="collapse-custom-children" style={styles}>
                        {children}
                    </div>
                </div>
            </InspectModuleComponentAccess>
        );
    }
);
