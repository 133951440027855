// Dataset.tsx
import { LANGUAGES } from 'utils/i18n/i18n';
import { Database } from './database.types';

type AllTablesDef = Database['public']['Tables'];
type AllViewsDef = Database['public']['Views'];

export type TableName = keyof AllTablesDef; // Get all table names from Supabase generated types
export type ViewName = keyof AllViewsDef; // Get all view names from Supabase generated types
export type ViewOrTableName = TableName | ViewName; // Используется в запросах к Supabase

type AllDef = AllTablesDef & AllViewsDef;

export type SupabaseTableRow<T extends TableName> = AllTablesDef[T]['Row'];
export type SupabaseViewRow<T extends ViewName> = AllViewsDef[T]['Row'];

export type SupabaseRow<T extends ViewOrTableName> = AllDef[T]['Row'];

// RowState represents the state of a row in the dataset
export type RowState = 'Unchanged' | 'Inserted' | 'Updated' | 'Deleted';
// FIXME: это пока что не используется а надо использовать
export type BaseTableRow = {
    _state: RowState;
    id: number;
    [key: string]: any;
};
export interface TypeInfoProps {
    id: number;
    code?: string;
    parent_code?: string;
    short_title?: Record<string, LANGUAGES>;
    long_title?: Record<string, LANGUAGES>;
    level?: number;
    path?: number;
    settings_lifecycle_status_group_id?: number;
}

export interface RootTableRow extends BaseTableRow {
    locked_by_uuid?: string | null;
    locked_at?: Date | null;
    ver: number;
    // lifecycle_status_code: string;
}

// interface DocsRootTableRow необходим для обработки удаления, так как нам необходимо изменять статус lifecycle_status_code
// interface DocsRootTableRow вынесен отдельным интерфейсом потому что lifecycle_status_code есть только у docs
export interface DocsRootTableRow extends RootTableRow {
    lifecycle_status_code: string;
}
export interface TableNameAndViewName {
    tableName: TableName;
    viewName: ViewName;
    extraLabel?: string;
}
// TableRow is a row in the dataset with an additional '_state' field
export type TableRow = BaseTableRow & Record<string, any>;

// Table is a generic type representing a table in the dataset
// Each row in the table has a state and an id (which can be temporary or real)
export type DatasetTable = {
    // Used to load data and display it in the data grid - this is key to find table in a Dataset
    viewName: ViewOrTableName;
    tableName: TableName; // Uset to save data // TODO: mapping view to table
    isLoading: boolean;
    error: any;
    // в разных таблицах тут разный набор полей - по сути хэш-мапа, но с типизированными значениями
    rows: TableRow[];
    is_root: boolean; // is this table root table  - это важное поле чтобы SaveDataset мог работатьс несколькими корневыми объектами - это важно для синхронизатора с внешними системами
};
// TODO: подумать про иконки и свой рендер ячеек - для спец страниц или в общем виде через конфиг

// Modes type
export type Mode = 'edit' | 'view';

// extend mode with create and clone
export type ModeExtended = Mode | 'create' | 'clone';

export class Dataset {
    // id of root object (however it is is just created then it is negative temp id)
    root_id: number;

    mode: Mode; // edit, view

    // нам важен порядок поэтому используем массив. четкий порядок может сократить
    // необходимость второго прохода
    tables: DatasetTable[];

    tempCounter: number; // counter for temp ids

    private hashMap: Map<string, number>;

    constructor(root_id: number, mode: Mode, tables: DatasetTable[], tempCounter = 0) {
        this.root_id = root_id;
        this.mode = mode;
        this.tables = tables;
        this.tempCounter = tempCounter;
        this.hashMap = new Map<string, number>();
    }

    // getNextTempId - it decreases the number and returns it
    getNextTempId(): number {
        this.tempCounter -= 1;
        return this.tempCounter;
    }

    // Add this method to the class
    toJSON(): Record<string, any> {
        return {
            // root_id: this.root_id, not needed to send to SaveDataset()
            // mode: this.mode, not needed to send to SaveDataset()
            tables: this.tables
            // tempCounter: this.tempCounter, not needed to send to SaveDataset()
        };
    }

    getOrCreateTempId(hash: string): number {
        let id = this.hashMap.get(hash);

        if (id === undefined) {
            id = this.getNextTempId();
            this.hashMap.set(hash, id);
        }

        return id;
    }
}

// ID mapper
export class IdMapper {
    // map of old table name and id to new id
    private idMap: Partial<Record<TableName, Record<number, number>>> = {};

    // add mapping
    add(tableName: TableName, oldId: number, newId: number): void {
        const tableIdMap = this.idMap[tableName] ?? {};
        tableIdMap[oldId] = newId;
        this.idMap[tableName] = tableIdMap;
    }

    // get new id
    get(tableName: TableName, oldId: number): number | undefined {
        const tableIdMap = this.idMap[tableName];
        if (!tableIdMap) return undefined;
        return tableIdMap[oldId];
    }
}
