import { Flex, Typography } from 'antd';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableRow, ViewName } from 'modules/supabase/types/Dataset';

import { NumberField } from '../NumberField/NumberField';
import { PrefixedSelectField } from '../PrefixedSelectField/PrefixedSelectField';

interface UnitValueFieldProps {
    prefix: string;
    fields: TableRow | null;
    mode: 'view' | 'edit';
    unitDictionary: ViewName;
    onChange: (newValue: any, fieldId?: string) => void;
    label?: string;
    min?: number;
    withLabel?: boolean;
    required?: boolean;
    measureUnitCategoryCodeFilter?: string;
    skipBlur?: boolean;
}

export const UnitValueField = memo<UnitValueFieldProps>(
    ({
        prefix,
        fields,
        unitDictionary,
        mode,
        measureUnitCategoryCodeFilter,
        onChange = () => {},
        label,
        min,
        withLabel = true,
        required = false,
        skipBlur = false
    }) => {
        const unitCodeField = `${prefix}measure_unit_`;
        const valueField = `${prefix}value`;

        const { t } = useTranslation();

        const numericValue = useMemo(() => {
            if (fields && fields[valueField]) {
                if (typeof fields[valueField] === 'number' && !Number.isNaN(fields[valueField])) {
                    return fields[valueField];
                }
                return null;
            }

            return null;
        }, [fields, valueField]);

        const handleNumberChange = useCallback(
            (newValue: number) => {
                onChange(newValue || newValue === 0 ? { [valueField]: newValue } : null);
            },
            [onChange, valueField]
        );

        // Нужно добавить стили
        return (
            <Flex align="center" justify="space-between" style={{ marginTop: 10 }}>
                {withLabel && (
                    <div style={{ width: '50%' }}>
                        <Typography.Text id={`${label || valueField}-label`} type="secondary">{`${t(
                            label || valueField
                        )}:`}</Typography.Text>
                    </div>
                )}
                <div style={{ width: withLabel ? '25%' : '50%', paddingRight: 3 }}>
                    <NumberField
                        id={label || valueField}
                        value={numericValue}
                        min={min}
                        mode={mode}
                        withLabel={false}
                        // Добавила, что бы сразу приходила на изменение структура key:value
                        onChange={handleNumberChange}
                        required={required}
                        skipBlur={skipBlur}
                    />
                </div>
                <div style={{ width: withLabel ? '25%' : '50%', paddingLeft: 3 }}>
                    <PrefixedSelectField
                        required={required}
                        id={`${unitCodeField}code`}
                        prefix={unitCodeField}
                        viewName={unitDictionary}
                        value={fields}
                        mode={mode}
                        withLabel={false}
                        onChange={onChange}
                        asDictionarySelectFieldWithOneColumn
                        searchListFilter={
                            measureUnitCategoryCodeFilter
                                ? [
                                      {
                                          column: 'measure_unit_category_code',
                                          operator: 'eq',
                                          value: measureUnitCategoryCodeFilter
                                      }
                                  ]
                                : []
                        }
                        skipBlur={skipBlur}
                    />
                </div>
            </Flex>
        );
    }
);
