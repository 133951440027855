import { FilterField } from 'components/DataTable/TableFilterMenu/TableFilterMenuTypes';
import { GlobalFilterType } from 'components/DataTable/useTableBackendFeatures';
import {
    MRT_ColumnFiltersState,
    MRT_FilterOption,
    MRT_PaginationState,
    MRT_SortingState
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { ViewName, ViewOrTableName } from 'modules/supabase/types/Dataset';
import { useSelectedViewDisplayPreference } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { useGenerateColumns } from 'components/DataTable/useGenerateColumns';
import {
    ColumnDef,
    generateToolbarInternalActions
} from '../functions/generateToolbarInternalActions';
import { getHandlers } from '../functions/getHandlers';
import { generateTableOptions } from '../functions/generateTableOptions';

export type TableOptionType = {
    columnFilters: MRT_ColumnFiltersState;
    columnFilterFns: { [key: string]: MRT_FilterOption };
    complexFilter: FilterField[];
    grouping: string[];
    globalFilter: GlobalFilterType;
    sorting: MRT_SortingState;
    pagination: MRT_PaginationState;
    virtualization?: boolean;
    initialized: boolean;
};
type TableInfiniteQueryOptions<T extends ViewOrTableName> = {
    viewDisplayPreferenceTitle?: string;
    viewOrTableName: T;
    defaultPageSize: number;
    setColumnOrder?: (columnKeyList: string[]) => string[];
    enableRowSelection: boolean;
    predefinedColumns?: ColumnDef[];
    enableRowOrdering: boolean;
    extraToolbarButtons?: React.ReactNode;
    enableColumnHiding?: boolean;
    enableViewDisplayPreferenceMenu?: boolean;
};

export const useTableOption = <T extends ViewOrTableName>({
    viewDisplayPreferenceTitle,
    viewOrTableName,
    defaultPageSize,
    setColumnOrder,
    enableRowSelection,
    predefinedColumns,
    enableRowOrdering,
    extraToolbarButtons,
    enableColumnHiding,
    enableViewDisplayPreferenceMenu
}: TableInfiniteQueryOptions<T>) => {
    const [tableOption, setTableOption] = useState<TableOptionType>({
        columnFilterFns: {},
        columnFilters: [],
        complexFilter: [],
        sorting: [],
        grouping: [],
        globalFilter: {},
        pagination: {
            pageIndex: 0,
            pageSize: defaultPageSize
        },
        virtualization: false, // true, // virtualization регулирует количество данных в запросе, если true то загружает всё, если false то только то что необх для пагинации
        initialized: false
    });

    const {
        viewDisplayPreferenceSetting: viewDisplayPreferenceSettings,
        selectedViewDisplayPreference,
        setSelectedViewDisplayPreference,
        vdfLoaded
    } = useSelectedViewDisplayPreference({
        viewName: viewOrTableName as ViewName,
        title: viewDisplayPreferenceTitle
    });

    // Параметры из View Display Preferences
    useEffect(() => {
        const fetchData = async () => {
            if (vdfLoaded && viewDisplayPreferenceSettings) {
                const options = generateTableOptions(
                    viewDisplayPreferenceSettings,
                    defaultPageSize
                );
                setTableOption(options);
            }
        };

        fetchData();
    }, [viewDisplayPreferenceSettings, vdfLoaded]);

    const generateColumnsOptions = useMemo(
        () => ({
            viewOrTableName,
            setColumnOrder,
            viewDisplayPreferenceSettings,
            enableExpanding: true,
            enableRowOrdering,
            enableRowSelection,
            doRequest: vdfLoaded,
            predefinedColumns // тут колонки не передаются и видимость по ним НЕ настраивается - видимость  настраивается по initColumnVisibility
        }),
        [
            enableRowOrdering,
            enableRowSelection,
            predefinedColumns,
            setColumnOrder,
            vdfLoaded,
            viewDisplayPreferenceSettings,
            viewOrTableName
        ]
    );

    const columnsOptions = useGenerateColumns(generateColumnsOptions);

    const { handleComplexFilterChange } = getHandlers(setTableOption);

    const generateToolbarOptions = useMemo(
        () => ({
            viewOrTableName,
            selectedViewDisplayPreference,
            setSelectedViewDisplayPreference,
            columnsOptions,
            tableOption,
            handleComplexFilterChange,
            extraToolbarButtons,
            enableColumnHiding,
            enableViewDisplayPreferenceMenu
        }),
        [
            columnsOptions,
            enableColumnHiding,
            enableViewDisplayPreferenceMenu,
            extraToolbarButtons,
            handleComplexFilterChange,
            selectedViewDisplayPreference,
            setSelectedViewDisplayPreference,
            tableOption,
            viewOrTableName
        ]
    );

    const renderToolbarInternalActions = generateToolbarInternalActions(generateToolbarOptions);

    // нужна для того чтобы инициализировать в случае если не инициализировалась после generateTableOptions
    const { columns } = columnsOptions;
    useEffect(() => {
        if (columns.length > 0 && !tableOption.initialized) {
            setTableOption((options) => {
                return {
                    ...options,
                    initialized: true
                };
            });
        }
    }, [columns, tableOption.initialized]);

    return {
        tableOption,
        setTableOption,
        renderToolbarInternalActions,
        columnsOptions,
        vdfLoaded,
        selectedViewDisplayPreference
    };
};
