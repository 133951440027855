import React, { Dispatch, SetStateAction, Suspense, useCallback } from 'react';
import {
    MRT_ColumnSizingState,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleFullScreenButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table';

import { MRTColumnDef, MRTTableInstance } from 'components/DataTable/MRT_Types';
import { TableFilterMenuToolbarButton } from 'components/DataTable/TableFilterMenu/TableFilterMenuToolbarButton';
import { ViewDisplayPreferenceMenu } from 'components/DataTable/ViewDisplayPreferenceToolBarButton';
import { TableBackendFeaturesReturn } from 'components/DataTable/useTableBackendFeatures';
import { ViewName, ViewOrTableName } from 'modules/supabase/types/Dataset';
import { SupabaseColumns } from 'modules/supabase/utils/tableCellUtils/generateColumnsFromType';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ViewDisplayPreferences } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { useResize } from 'utils/hooks';
import { FilterField } from 'components/DataTable/TableFilterMenu/TableFilterMenuTypes';
import { TableOptionType } from '../hooks/useTableOption';

type FieldVisibilityType = {
    [key: string]: boolean;
};
export type ColumnDef = MRTColumnDef &
    Partial<{
        navigate_path: string;
        view_help_filter: Filter[];
    }>;
type ColumnOptionsType = {
    columns: ColumnDef[];
    columnVisibility: FieldVisibilityType;
    setColumnVisibility: Dispatch<SetStateAction<FieldVisibilityType>>;
    columnOrdering: string[];
    setColumnOrdering: Dispatch<SetStateAction<string[]>>;
    columnSizing: MRT_ColumnSizingState;
    setColumnSizing: Dispatch<SetStateAction<MRT_ColumnSizingState>>;
    supabaseColumns: SupabaseColumns[];
};
type generateToolbarInternalActionsType = {
    viewOrTableName: ViewOrTableName;
    selectedViewDisplayPreference: ViewDisplayPreferences | null;
    setSelectedViewDisplayPreference: Dispatch<ViewDisplayPreferences>;
    extraToolbarButtons?: React.ReactNode;
    columnsOptions: ColumnOptionsType;
    tableOption: TableOptionType;
    handleComplexFilterChange: (newComplexFilter: FilterField[]) => void;
    enableColumnHiding?: boolean;
    enableViewDisplayPreferenceMenu?: boolean;
};
export const generateToolbarInternalActions = ({
    viewOrTableName,
    selectedViewDisplayPreference,
    setSelectedViewDisplayPreference,
    extraToolbarButtons,
    columnsOptions,
    tableOption,
    handleComplexFilterChange,
    enableColumnHiding = true,
    enableViewDisplayPreferenceMenu = true
}: generateToolbarInternalActionsType) => {
    const { isScreenMd } = useResize();
    const { columns, columnVisibility, columnOrdering, columnSizing, supabaseColumns } =
        columnsOptions;

    const renderToolbarInternalActions: TableBackendFeaturesReturn['renderToolbarInternalActions'] =
        useCallback(
            ({ table }: { table: MRTTableInstance }) => {
                return (
                    // <div className="custom-toolbar-container">
                    <>
                        {extraToolbarButtons}

                        {enableViewDisplayPreferenceMenu && !isScreenMd && (
                            <ViewDisplayPreferenceMenu
                                table={table}
                                selectedViewDisplayPreference={selectedViewDisplayPreference}
                                setSelectedViewDisplayPreference={setSelectedViewDisplayPreference}
                                viewName={viewOrTableName as ViewName}
                                viewDisplayPreferenceUIData={{
                                    columnOrder: columnOrdering,
                                    columnSizing,
                                    columnVisibility,
                                    filtering: tableOption.columnFilters,
                                    columnFilterFns: tableOption.columnFilterFns,
                                    pageSize: tableOption.pagination.pageSize,
                                    sorting: tableOption.sorting,
                                    grouping: tableOption.grouping,
                                    complexFilter: tableOption.complexFilter
                                }}
                            />
                        )}

                        <TableFilterMenuToolbarButton
                            columns={columns}
                            supabaseColumns={supabaseColumns}
                            filters={tableOption.complexFilter}
                            setFilters={handleComplexFilterChange}
                            table={table}
                        />

                        {/* <MRT_ToggleGlobalFilterButton table={table} className="btn-base-toolbar" /> */}

                        {/* {!isScreenMd ? (
                            <MRT_ToggleFiltersButton table={table} className="btn-base-toolbar" />
                        ) : null} */}

                        {enableColumnHiding && !isScreenMd && (
                            <MRT_ShowHideColumnsButton table={table} className="btn-base-toolbar" />
                        )}

                        {/* {!isScreenMd ? (
                            <MRT_ToggleDensePaddingButton
                                table={table}
                                className="btn-base-toolbar"
                            />
                        ) : null} */}
                        {/* {!isScreenMd ? (
                            <MRT_ToggleFullScreenButton
                                table={table}
                                className="btn-base-toolbar"
                            />
                        ) : null} */}
                    </>
                );
            },
            [
                columnOrdering,
                columnSizing,
                columnVisibility,
                columns,
                enableColumnHiding,
                enableViewDisplayPreferenceMenu,
                extraToolbarButtons,
                handleComplexFilterChange,
                // isScreenMd,
                selectedViewDisplayPreference,
                setSelectedViewDisplayPreference,
                supabaseColumns,
                tableOption.columnFilterFns,
                tableOption.columnFilters,
                tableOption.complexFilter,
                tableOption.grouping,
                tableOption.pagination.pageSize,
                tableOption.sorting,
                viewOrTableName
            ]
        );

    return renderToolbarInternalActions;
};
