import { Select, Tag, Tooltip } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { BaseSelectRef } from 'rc-select';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { BaseTableRow, SupabaseViewRow, ViewName } from 'modules/supabase/types/Dataset';
import { useSupabaseViewOrTableDataWithAutoRefresh } from 'modules/supabase/utils/hooks/useSupabaseViewOrTableDataWithAutoRefresh';
import { useViewDisplayPreferenceByTitle } from 'modules/supabase/utils/hooks/useViewDisplayPreferences';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';

import { BaseField } from '../BaseField/BaseField';
import { checkIfMultiLanguageFieldByValue } from '../MultilanguageField/MultilanguageField';

import './MultiSelectField.scss';

type SupabaseViewType = SupabaseViewRow<ViewName> & BaseTableRow;

interface SelectFieldProps {
    id: string; // id
    popoverContainerHtmlId?: string; // id
    htmlId?: string; // html id
    value: any[] | null; // value - филд из data.fields типа массив, например data.fields.someArray
    mode: 'view' | 'edit'; // мод
    viewName: ViewName; // view
    filters?: Filter[];
    withLabel?: boolean; // label true or false
    required?: boolean; // required (for validation todo)
    onChange?: (newValue: SupabaseViewType[] | null) => void; // handler
    onClear?: () => void; // handler
    validateStatus?: ValidateStatus; // for validation todo
    validateMessage?: string; // for validation todo
    optionsFieldName?: string; // название поля (из справочника), которое нужно выводить в тэгах и опциях
    maxCount?: number; // ограничение на максимальное кол-во выбранных опций
    loading?: boolean;
}

type TagRender = SelectProps['tagRender'];

export const MultiSelectField = memo<SelectFieldProps>(
    ({
        id,
        popoverContainerHtmlId,
        value,
        mode,
        viewName,
        filters,
        optionsFieldName,
        withLabel = true,
        required = false,
        htmlId = id,
        onChange = () => {},
        onClear = () => {},
        validateStatus,
        validateMessage,
        maxCount,
        loading
    }) => {
        const inputRef = useRef<BaseSelectRef>(null);

        const [options, setOptions] = useState<DefaultOptionType[]>([]);

        const [outputValueInOptions, setOutputValueInOptions] = useState(optionsFieldName);

        const displayPrefsOptions = useMemo(
            () => ({
                viewName,
                viewDisplayPreferenceTitle: 'SearchHelp'
            }),
            [viewName]
        );

        const viewDisplayPreferenceSettings = useViewDisplayPreferenceByTitle(displayPrefsOptions);

        useEffect(() => {
            if (!outputValueInOptions && viewDisplayPreferenceSettings) {
                const outputField = viewDisplayPreferenceSettings.columns[0];
                setOutputValueInOptions(outputField);
            }
        }, [outputValueInOptions, viewDisplayPreferenceSettings]);

        const {
            i18n: { language },
            t
        } = useTranslation();

        const { data } = useSupabaseViewOrTableDataWithAutoRefresh({
            viewOrTableName: viewName,
            filters
        });

        const tagRender = useCallback<NonNullable<TagRender>>(
            (props) => {
                const { label, onClose } = props;
                const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                };

                return (
                    <Tag
                        onMouseDown={onPreventMouseDown}
                        closable={mode === 'edit'}
                        onClose={onClose}
                        className="multi_select_field__tag"
                    >
                        <span>{label}</span>
                    </Tag>
                );
            },
            [mode]
        );

        useEffect(() => {
            if (data && outputValueInOptions) {
                const options = data.map((dataRow, index) => {
                    const dataRowValue: Record<string, any> = dataRow;
                    const labelVal = dataRowValue[outputValueInOptions];

                    return {
                        label: checkIfMultiLanguageFieldByValue(labelVal)
                            ? labelVal[language]
                            : labelVal,
                        value: index
                    };
                });

                setOptions(options);
            }
        }, [data, outputValueInOptions, language]);

        const selectValue = useMemo(() => {
            const selectValue: number[] = [];
            value?.forEach((v) => {
                const index = data.findIndex((data) => (data as { id: number }).id === v.id);
                if (index !== -1) {
                    selectValue.push(index);
                }
            });

            return selectValue;
        }, [data, value]);

        const renderInput = useCallback(() => {
            return (
                <Select
                    loading={loading}
                    allowClear
                    getPopupContainer={
                        popoverContainerHtmlId
                            ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                            : undefined
                    }
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder={t('no_value')}
                    style={{ width: '100%' }}
                    maxCount={maxCount}
                    ref={inputRef}
                    value={selectValue}
                    options={options}
                    tagRender={tagRender}
                    dropdownRender={!options.length ? () => <EmptyMarker /> : undefined}
                    maxTagPlaceholder={(omittedValues) => (
                        <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                            <span>+{omittedValues.length} ...</span>
                        </Tooltip>
                    )}
                    onChange={(valueIndexes) => {
                        if (data && onChange && valueIndexes) {
                            const valueOnUpdate = data.filter((_, index) =>
                                valueIndexes.includes(index)
                            );
                            onChange(valueOnUpdate as SupabaseViewType[]);
                        }
                    }}
                />
            );
        }, [
            data,
            loading,
            maxCount,
            onChange,
            options,
            popoverContainerHtmlId,
            selectValue,
            t,
            tagRender
        ]);

        const renderView = useCallback(() => {
            return (
                <Select
                    loading={loading}
                    mode="multiple"
                    variant="borderless"
                    maxTagCount="responsive"
                    suffixIcon={null}
                    notFoundContent={null}
                    placeholder={t('no_value')}
                    style={{ width: '100%' }}
                    maxCount={maxCount}
                    value={selectValue}
                    onClear={onClear}
                    defaultValue={selectValue}
                    tagRender={tagRender}
                    maxTagPlaceholder={(omittedValues) => (
                        <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                            <span>+{omittedValues.length} ...</span>
                        </Tooltip>
                    )}
                />
            );
        }, [loading, maxCount, selectValue, t, tagRender]);

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                withLabel={withLabel}
                value={value}
                mode={mode}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                validateStatus={validateStatus}
                withTooltip={false}
                validateMessage={validateMessage}
            />
        );
    }
);
