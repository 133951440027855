import { isEqual } from 'lodash';
import { createContext, ReactNode, useContext, useState } from 'react';

import { MRTTableInstance } from './MRT_Types';

interface TablePageInfoContextType {
    tableInfo: MRTTableInstance | undefined;
    setTableInfoValid: (tableInstance: MRTTableInstance) => void;
}

const TablePageInfo = createContext<TablePageInfoContextType | undefined>(undefined);

interface TablePageInfoProviderProps {
    children: ReactNode;
}

export const TablePageInfoProvider: React.FC<TablePageInfoProviderProps> = ({ children }) => {
    const [tableInfo, setTableInfo] = useState<MRTTableInstance | undefined>(undefined);
    const setTableInfoValid = (tableInstance: MRTTableInstance) => {
        if (tableInfo === undefined) {
            if (!isEqual(tableInstance, tableInfo)) {
                setTableInfo(tableInstance);
            }
        }
    };

    return (
        <TablePageInfo.Provider value={{ tableInfo, setTableInfoValid }}>
            {children}
        </TablePageInfo.Provider>
    );
};

export const useTablePageInfo = () => {
    const context = useContext(TablePageInfo);
    if (!context) {
        throw new Error('useTablePageInfo must be used within a TablePageInfoProvider');
    }
    return context;
};
