import { useMemo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPage_DataTable } from 'components/DataTable/DetailPage_DataTable';
import { MRTColumnDef } from 'components/DataTable/MRT_Types';
// import { GroupCollapse } from 'components/GroupdCollaps/GroupCollaps';
//
import { ViewName } from 'modules/supabase/types/Dataset';

import { TableRefType } from './useTableBackendFeatures';

interface TurnoverClassesDetail_DataTableProps {
    childViewName: ViewName;
}
export const TurnoverClassesDetail_DataTable = forwardRef<
    TableRefType,
    TurnoverClassesDetail_DataTableProps
>(({ childViewName }, ref) => {
    const { t } = useTranslation();

    const columns: (MRTColumnDef & Record<string, any>)[] = useMemo(
        () => [
            {
                accessorKey: 'turnover_class_code',
                header: t('turnover_class_code') as string
            }
        ],
        [t]
    );

    return (
        <DetailPage_DataTable childViewName={childViewName} predefinedColumns={columns} ref={ref} />
    );
});
