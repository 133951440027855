import { MRT_Localization } from 'material-react-table';
import { getMaterialReactTableLocalization } from 'utils/i18n/libs/material-react-table/translateUtils';

let cachedLocalization: null | Partial<MRT_Localization>;
const prevIsHideColumnHeader: boolean | null = null;

export const getLocalization = (hideColumnHeader: boolean) => {
    if (!cachedLocalization || hideColumnHeader !== prevIsHideColumnHeader) {
        const localization = getMaterialReactTableLocalization();
        cachedLocalization = {
            ...getMaterialReactTableLocalization(),
            select: hideColumnHeader ? '' : localization.select,
            move: hideColumnHeader ? '' : localization.move,
            expand: hideColumnHeader ? '' : localization.expand,
            actions: hideColumnHeader ? '' : localization.actions
        };
    }

    return cachedLocalization;
};
