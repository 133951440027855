import { useContext, useEffect, useState } from 'react';

import { PostgrestError } from '@supabase/supabase-js';
import { GlobalFilterType } from 'components/DataTable/useTableBackendFeatures';

import { SupabaseRow, ViewOrTableName } from 'modules/supabase/types/Dataset';
import { Filter, OrderBy, getSupabaseViewOrTableData } from '../supabaseClient';

import {
    DictContext,
    DictDataType,
    DictsViews
} from '../../../../contexts/DictsContextProvider/DictsContext';

export type UseSupabaseViewOrTableDataProps<T extends ViewOrTableName> = {
    viewOrTableName: T;
    pageSize?: number;
    pageNumber?: number;
    virtualization?: boolean;
    filters?: Filter[];
    orderBy?: OrderBy[];
    globalFilter?: GlobalFilterType;
    refresh?: boolean;
    setRefresh?: Function;
    doRequest?: boolean;
    channelName?: string;
    autoRefresh?: boolean;
};

export type UseSupabaseViewOrTableDataResult<T> = {
    data: T;
    isLoading: boolean;
    error: PostgrestError | null;
    count: number;
};

// Hook для получения данных из таблицы или View и автоматического рефреша данных при изменении
export const useSupabaseViewOrTableData = <T extends ViewOrTableName>(
    props: UseSupabaseViewOrTableDataProps<T>
): UseSupabaseViewOrTableDataResult<SupabaseRow<T>[]> => {
    const {
        viewOrTableName,
        filters,
        orderBy,
        pageNumber,
        pageSize,
        globalFilter,
        virtualization,
        doRequest = true
    } = props;
    // TODO: use reducer here and one complex state:
    // { data, isLoading, error, count, setPage, setFilters, setOrderBy }
    const [data, setData] = useState<SupabaseRow<T>[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [count, setCount] = useState(0);

    const { dispatch: dictsContextDispatch, getDictByRequest } = useContext(DictContext);

    useEffect(() => {
        // const controller = new AbortController();
        // TODO: Здесь нужно return callback в теле этой функции

        // FIXED: перенес деструктуризацию сюда, чтобы не было лишнего ререндеринга
        const fetchData = async () => {
            setError(null);
            setIsLoading(true);
            const {
                data: newData,
                count: numberOfRows,
                error: newError
            } = await getSupabaseViewOrTableData({
                filters,
                // abortController: controller,
                orderBy,
                pageNumber,
                pageSize,
                virtualization,
                ...props
            });

            if (newError) {
                setError(newError);
                console.error(newError);
            }

            if (props.setRefresh && props.refresh) props.setRefresh(false);

            const newCount = numberOfRows || 0;

            setData(newData || []);
            setCount(newCount || 0);

            if (newData && (newCount || newCount === 0)) {
                setIsLoading(false);
            }

            // Определяем что словарь
            if (viewOrTableName.includes('dicts')) {
                console.log(
                    'ADD NEW DICT BY REQUEST:',
                    JSON.stringify({
                        dictViewName: viewOrTableName,
                        filters,
                        orderBy,
                        pageNumber,
                        pageSize,
                        virtualization
                    })
                );

                // Добавляем словарь в storage
                dictsContextDispatch({
                    type: 'ADD_DICT',
                    payload: {
                        request: {
                            dictViewName: viewOrTableName as DictsViews,
                            filters,
                            orderBy,
                            pageNumber,
                            pageSize,
                            virtualization
                        },

                        fetchResult: {
                            data: (newData as DictDataType<DictsViews>[]) || [],
                            error: newError
                        }
                    }
                });
            }
        };

        if (viewOrTableName && doRequest) {
            // Для словарей есть отдельный Storage
            if (viewOrTableName.includes('dicts')) {
                // Ищим уже загруженный по запросу словарь
                const dictInStorage = getDictByRequest({
                    dictViewName: viewOrTableName as DictsViews,
                    filters,
                    orderBy,
                    pageNumber,
                    pageSize,
                    virtualization
                });

                // Если нашли возвращаем
                if (dictInStorage) {
                    setData((dictInStorage.data as SupabaseRow<T>[]) || []);
                    setCount(dictInStorage.data.length);
                    setIsLoading(false);
                } else {
                    // Если не нашли - делаем запрос
                    fetchData();
                }
            } else {
                // Если не словарь делаем запрос
                fetchData();
            }
        }

        // FIXED: Любой useEffect будет здесь ругаться.
        // В переданном callback'e ты используешь то, что передается в props.
        // Он хочет актуализировать эффект, если props изменились

        // return () => {
        //     controller.abort();
        // };
    }, [
        viewOrTableName,
        props.refresh,
        virtualization,
        filters,
        orderBy,
        pageNumber,
        pageSize,
        globalFilter,
        doRequest
    ]);

    return {
        data,
        isLoading,
        error,
        count
    };
};
