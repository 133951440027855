import { Input, Typography } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

interface StringFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    htmlId?: string;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    validateStatus?: ValidateStatus;
    validateMessage?: string;
}

export const StringField = memo<StringFieldProps>(
    ({
        id,
        value,
        mode,
        validateStatus,
        validateMessage,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false,
        htmlId = id
    }) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (
                inputValue: string,
                onInputChange?: (newValue: string) => void,
                onBlur?: () => void
            ) => {
                return (
                    <Input
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        allowClear
                        value={inputValue}
                        onChange={(e) => {
                            if (onInputChange) {
                                onInputChange(e.target.value);
                            }
                        }}
                        onBlur={onBlur}
                    />
                );
            },
            [htmlId, t]
        );

        const renderView = useCallback(
            (viewValue: string) => {
                return (
                    <Typography.Text
                        id={`${htmlId}-value`}
                        style={{
                            paddingLeft: '10px',
                            color: viewValue ? undefined : '#aeaeae'
                        }}
                        type={viewValue ? undefined : 'secondary'}
                    >
                        {viewValue || t('no_value')}
                    </Typography.Text>
                    // <Input
                    //     id={`${htmlId}-value`}
                    //     placeholder={t('no_value') as string}
                    //     variant="borderless"
                    //     value={viewValue}
                    //     readOnly
                    // />
                );
            },
            [htmlId, t]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
                validateStatus={validateStatus}
                validateMessage={validateMessage}
            />
        );
    }
);
