import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Dispatch, SetStateAction, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Coordinate = {
    Latitude: number;
    Longitude: number;
};

interface SmartCoordinateFieldProps {
    value: Coordinate | null;
    onChange: (newValue: Coordinate | null) => void;
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

export const SmartCoordinateField = memo(
    ({
        value,
        popoverContainerHtmlId,
        onChange,
        disabled,
        style,
        className
    }: SmartCoordinateFieldProps) => {
        const { t } = useTranslation();

        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>, fieldName: 'Latitude' | 'Longitude') => {
                const copyValue = { ...value };

                copyValue[fieldName] = parseFloat(e.target.value);

                onChange(copyValue as Coordinate);
            },
            [value, onChange]
        );

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={t('latitude')}>
                        <Input
                            placeholder={t('no_value') as string}
                            value={value?.Latitude || ''}
                            // addonBefore={t('latitude')}
                            allowClear
                            style={style}
                            type="number"
                            className={className}
                            showCount={false}
                            disabled={disabled}
                            onChange={(e) => {
                                handleChange(e, 'Latitude');
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={t('longitude')}>
                        <Input
                            placeholder={t('no_value') as string}
                            value={value?.Longitude || ''}
                            // addonBefore={t('longitude')}
                            allowClear
                            style={style}
                            type="number"
                            showCount={false}
                            className={className}
                            disabled={disabled}
                            onChange={(e) => {
                                handleChange(e, 'Longitude');
                            }}
                        />
                    </Tooltip>
                </div>
            </>
        );
    }
);
