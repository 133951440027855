import { Warehouse as WmsIcon } from '@mui/icons-material';
import { lazy } from 'react';
import { Routes } from 'routing/route';
import { RouteTypeMassive } from 'routing/routesTypes';

const WarehouseAccessAreas_TablePage = lazy(() =>
    import('pages/docs/warehouse-access-areas/WarehouseAccessAreas_TablePage').then((module) => ({
        default: module.WarehouseAccessAreas_TablePage
    }))
);
const WarehouseBarcodes_TablePage = lazy(() =>
    import('pages/docs/warehouse-barcodes/WarehouseBarcodes_TablePage').then((module) => ({
        default: module.WarehouseBarcodes_TablePage
    }))
);
const WarehouseColumns_TablePage = lazy(() =>
    import('pages/docs/warehouse-colomns/WarehouseColumns_TablePage').then((module) => ({
        default: module.WarehouseColumns_TablePage
    }))
);
const WarehouseLevels_TablePage = lazy(() =>
    import('pages/docs/warehouse-levels/WarehouseLevels_TablePage').then((module) => ({
        default: module.WarehouseLevels_TablePage
    }))
);
const WarehousePreparationAreas_TablePage = lazy(() =>
    import('pages/docs/warehouse-preparation-areas/WarehousePreparationAreas_TablePage').then(
        (module) => ({
            default: module.WarehousePreparationAreas_TablePage
        })
    )
);
const WarehouseResourceGroups_TablePage = lazy(() =>
    import('pages/docs/warehouse-resource-groups/WarehouseResourceGroups_TablePage').then(
        (module) => ({
            default: module.WarehouseResourceGroups_TablePage
        })
    )
);
const WarehouseResources_TablePage = lazy(() =>
    import('pages/docs/warehouse-resources/WarehouseResources_TablePage').then((module) => ({
        default: module.WarehouseResources_TablePage
    }))
);
const WarehouseRows_TablePage = lazy(() =>
    import('pages/docs/warehouse-rows/WarehouseRows_TablePage').then((module) => ({
        default: module.WarehouseRows_TablePage
    }))
);
const WarehouseStorageAreas_TablePage = lazy(() =>
    import('pages/docs/warehouse-storage-areas/WarehouseStorageAreas_TablePage').then((module) => ({
        default: module.WarehouseStorageAreas_TablePage
    }))
);
const WarehouseStorageLocationSizes_TabelPage = lazy(() =>
    import(
        'pages/docs/warehouse-storage-location-sizes/WarehouseStorageLocationSizes_TabelPage'
    ).then((module) => ({
        default: module.WarehouseStorageLocationSizes_TabelPage
    }))
);
const WarehouseStorageLocations_TablePage = lazy(() =>
    import('pages/docs/warehouse-storage-locations/WarehouseStorageLocations_TablePage').then(
        (module) => ({
            default: module.WarehouseStorageLocations_TablePage
        })
    )
);
const WarehouseTareGroups_TablePage = lazy(() =>
    import('pages/docs/warehouse-tare-groups/WarehouseTareGroups_TablePage').then((module) => ({
        default: module.WarehouseTareGroups_TablePage
    }))
);
const WarehouseTares_TabelPage = lazy(() =>
    import('pages/docs/warehouse-tares/WarehouseTares_TabelPage').then((module) => ({
        default: module.WarehouseTares_TabelPage
    }))
);
const WarehouseZones_TablePage = lazy(() =>
    import('pages/docs/warehouse-zones/WarehouseZones_TablePage').then((module) => ({
        default: module.WarehouseZones_TablePage
    }))
);
const WarehouseAisles_TablePage = lazy(() =>
    import('pages/docs/warehouse_aisles/WarehouseAisles_TablePage').then((module) => ({
        default: module.WarehouseAisles_TablePage
    }))
);
const WmsOverviewPage = lazy(() =>
    import('pages/wms/WmsOverviewPage').then((module) => ({
        default: module.WmsOverviewPage
    }))
);

const WmsApps: RouteTypeMassive[] = [
    {
        path: '',
        tablePage: WmsOverviewPage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'warehouse-zones',
        tablePage: WarehouseZones_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-aisles',
        tablePage: WarehouseAisles_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-rows',
        tablePage: WarehouseRows_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-columns',
        tablePage: WarehouseColumns_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-levels',
        tablePage: WarehouseLevels_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'warehouse-storage-location-sizes',
        tablePage: WarehouseStorageLocationSizes_TabelPage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-storage-areas',
        tablePage: WarehouseStorageAreas_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-preparation-areas',
        tablePage: WarehousePreparationAreas_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-access-areas',
        tablePage: WarehouseAccessAreas_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-storage-locations',
        tablePage: WarehouseStorageLocations_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-resource-groups',
        tablePage: WarehouseResourceGroups_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-resources',
        tablePage: WarehouseResources_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-tare-groups',
        tablePage: WarehouseTareGroups_TablePage,
        config: {
            icon: <WmsIcon />
        }
    },

    {
        path: 'warehouse-tares',
        tablePage: WarehouseTares_TabelPage,
        config: {
            icon: <WmsIcon />
        }
    },
    {
        path: 'warehouse-barcodes',
        tablePage: WarehouseBarcodes_TablePage,
        config: {
            icon: <WmsIcon />
        }
    }
];

export const WmsRoutes = new Routes('/warehouse-master-data', WmsApps);
