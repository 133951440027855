import { MRT_Updater, MRT_ColumnOrderState as TableColumnOrderState } from 'material-react-table';
import { useEffect, useState } from 'react';

import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { useLocalStorage } from './useLocalStorage';

interface Feautures {
    expanding?: boolean;
    rowOrdering?: boolean;
    rowSelection?: boolean;
}

interface IUseColumnsVisibilityParams {
    columns?: MRTColumnDef[];
    features?: Feautures;
    lsKey: string;
    columnSorting?: (fieldA: string, fieldB: string) => number;
}

// export const getColumnsVisibility = (columns: MRTColumnDef[]): TableColumnOrderState =>
//     Object.fromEntries(columns.map((column) => [column.accessorKey, true]));

export const useColumnOrdering = ({
    columns,
    features = {},
    lsKey,
    columnSorting
}: IUseColumnsVisibilityParams) => {
    const { get: getLsValue, set: setLsValue } = useLocalStorage();
    const [orderState, setOrderState] = useState<TableColumnOrderState>([]);

    useEffect(() => {
        const lsOrderKey = `${lsKey}:order`;

        let columnOrder = getLsValue<TableColumnOrderState>(lsOrderKey);

        if (!columnOrder || columnOrder.length === 0) {
            columnOrder = [];

            if (features.expanding) {
                columnOrder!.push('mrt-row-expand');
            }
            if (features.rowOrdering) {
                columnOrder!.push('mrt-row-drag');
            }
            if (features.rowSelection) {
                columnOrder!.push('mrt-row-select');
            }

            if (columns) {
                columns.forEach((column) => {
                    if (column && column.accessorKey) {
                        columnOrder?.push(column.accessorKey);
                    }
                });
            }
        }

        if (columnSorting) {
            columnOrder.sort(columnSorting as (a: string, b: string) => number);
        }

        setOrderState(columnOrder!);
    }, [columns, setOrderState, lsKey, columnSorting]);

    const onOrderChange = (updates: MRT_Updater<TableColumnOrderState>) => {
        const lsOrderKey = `${lsKey}:order`;

        if (typeof updates === 'function') {
            return;
        }

        setLsValue(lsOrderKey, updates);

        setOrderState(updates);
    };

    return { orderState, onOrderChange };
};
