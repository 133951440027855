import { CheckOutlined } from '@ant-design/icons';
import { List, Modal } from 'antd';
import _ from 'lodash';
import { Dispatch, SetStateAction, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabItemProps } from 'components/Tabs/TabsBox';

interface TabsModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    tabList: TabItemProps[];
    setTabList: Dispatch<SetStateAction<TabItemProps[]>>;
}

export const TabsModal = memo(({ open, setOpen, tabList, setTabList }: TabsModalProps) => {
    const { t } = useTranslation();

    const [tabs, setTabs] = useState<TabItemProps[]>([]);

    const handleSave = useCallback(() => {
        setTabList(tabs);
        setOpen(false);
    }, [setOpen, setTabList, tabs]);

    const toggleVisibility = useCallback(
        (key: string) => {
            const updated = tabs.map((item) => {
                if (item.key === key) {
                    return { ...item, visible: !item.visible };
                }
                return item;
            });
            setTabs(updated);
        },
        [tabs]
    );

    useEffect(() => {
        setTabs(tabList);
    }, [tabList]);

    return (
        <Modal
            centered
            title={t('tabs-changes')}
            open={open}
            onCancel={() => setOpen(false)}
            okText={t('save')}
            cancelText={t('cancel')}
            onOk={handleSave}
        >
            <List header={t('visible-tabs')} variant="borderless">
                {_.filter(tabs, { visible: true }).map((tab) => (
                    <List.Item
                        key={tab.key}
                        extra={<CheckOutlined />}
                        onClick={() => toggleVisibility(tab.key)}
                    >
                        {tab.label}
                    </List.Item>
                ))}
            </List>
            <List header={t('not-visible-tabs')} variant="borderless">
                {_.filter(tabs, { visible: false }).map((tab) => (
                    <List.Item key={tab.key} onClick={() => toggleVisibility(tab.key)}>
                        {tab.label}
                    </List.Item>
                ))}
            </List>
        </Modal>
    );
});
