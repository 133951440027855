import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Flex, Input } from 'antd';
import { memo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ButtonWithTooltips } from 'ui';

interface LocalSearchComponentProps {
    searchFilter?: string;
    setFilter: (filter: string) => void;
    placeholder: string;
}

export const LocalSearchComponent = memo<LocalSearchComponentProps>(
    ({ searchFilter, setFilter, placeholder }) => {
        const [isLocalFilter, setIsLocalFilter] = useState(!!searchFilter);
        const [localFilterChange, setLocalFilterChange] = useState(searchFilter || '');

        // useEffectOnce(() => {
        //     setLocalFilterChange(searchFilter || '');
        //     setIsLocalFilter(!!searchFilter);
        // });

        return (
            <Flex>
                {isLocalFilter ? (
                    <Input.Search
                        allowClear
                        enterButton
                        placeholder={placeholder}
                        value={localFilterChange}
                        onChange={({ target }) => {
                            setLocalFilterChange(target.value);
                        }}
                        onSearch={setFilter}
                        onBlur={() => setFilter(localFilterChange)}
                    />
                ) : (
                    <ButtonWithTooltips
                        id="filter-local"
                        // icon={isLocalFilter ? <CloseOutlined /> : <SearchOutlined />}
                        icon={<SearchOutlined />}
                        type="default"
                        className="filter-local"
                        onClick={() => setIsLocalFilter((prev) => !prev)}
                    />
                )}
            </Flex>
        );
    }
);
