import { PlainObject } from '@gilbarbara/types';

import { MetaField } from 'modules/services/backend-api/generated_info';

export const getExcludeFieldsPattern = (
    self: PlainObject<any>,
    list: PlainObject<any>[],
    options?: { isDetail?: boolean; replaceFieldNameKey?: string }
) => {
    const FieldNameKey = (options?.replaceFieldNameKey || 'FieldName') as keyof MetaField;

    const FieldName = self[FieldNameKey];

    const defaultPattern =
        !FieldName?.includes('ValueType') &&
        // FieldName !== 'Property' &&
        // FieldName !== 'Parameter' &&
        // composite measure value
        !(
            FieldName?.includes('MeasureUnit') &&
            list.find((field) =>
                field[FieldNameKey]?.includes(`${FieldName?.split('MeasureUnit')?.join('')}Value`)
            )
        ) &&
        // composite curreny value
        !(
            FieldName?.includes('Currency') &&
            FieldName !== 'Currency' &&
            !FieldName?.includes('CurrencyValue') &&
            list.find((field) =>
                field[FieldNameKey]?.includes(`${FieldName?.split('Currency')?.join('')}Value`)
            )
        );

    if (options?.isDetail)
        return (
            !(
                FieldName?.includes('Interval') &&
                list.find((field) => field[FieldNameKey] === 'DaysPattern')
            ) && defaultPattern
        );

    return defaultPattern;
};
