import { SupabaseClient } from '@supabase/supabase-js';
import { useUserWarehouse } from 'utils/hooks/usePublicUser';
import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Database } from 'modules/supabase/types/database.types';
import { ISettingsModulesContext, SettingsModules } from './types';

export const SettingsModulesContext = createContext<ISettingsModulesContext>({
    settingsModules: null,
    userWarehouse: null
});

// 1. Запросить юзера и его активный склад
// 2. Запросить настройки
// 2.1 Настройки со складом, который относится к юзеру
// 2.2 Дефолтные настройки

export const SettingsModulesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const userWarehouse = useUserWarehouse();
    const [settingsModules, setSettingsModules] = useState<SettingsModules[] | null>(null);

    useEffect(() => {
        const fetchModules = async () => {
            const { data, error } = await (supabaseClient as SupabaseClient<Database, 'public'>)
                .from('settings_organization_modules')
                .select(
                    'warehouse_id,title, modules: settings_organization_module_enablements(is_enabled,module: settings_modules(id,title,for_warehouse,components: settings_module_components(component_name,component_path,is_accessible)))'
                )
                .eq('warehouse_id', userWarehouse!)
                .returns<SettingsModules[]>();
            if (error) {
                console.error(error);
            }
            if (data) {
                setSettingsModules(data);
            }
        };

        if (userWarehouse) fetchModules();
    }, [userWarehouse]);

    return (
        <SettingsModulesContext.Provider value={{ settingsModules, userWarehouse }}>
            {children}
        </SettingsModulesContext.Provider>
    );
};
