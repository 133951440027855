import { memo, useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';

import * as beautiful from 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/snippets/json';
import { Button, Modal, Typography } from 'antd';
import { useBoolean } from 'react-use';

import './JsonField.scss';
import { EditOutlined } from '@ant-design/icons';

interface JsonFieldType {
    jsonValue: any | undefined;
    onChange: (newFieldValue: any) => void;
    readOnly: boolean;
    displayMode?: 'inline' | 'dialog';
}

const unbeautify = (value: string) => {
    return JSON.stringify(JSON.parse(value));
};

export const JsonField = memo<JsonFieldType>(
    ({ jsonValue, readOnly, onChange, displayMode = 'modal' }) => {
        const editorRef = useRef<AceEditor | null>(null);

        const [openModal, setOpenModal] = useBoolean(false);

        const [modalValue, setModalValue] = useState(
            jsonValue ? JSON.stringify(jsonValue) : undefined
        );

        const beautify = () => {
            if (editorRef.current?.editor.getSession())
                beautiful.beautify(editorRef.current.editor.getSession());
        };

        useEffect(() => {
            if (editorRef.current?.editor.getSession() && openModal)
                beautiful.beautify(editorRef.current.editor.getSession());
        }, [openModal]);

        if (displayMode === 'inline') {
            return (
                <AceEditor
                    ref={editorRef}
                    mode="json"
                    value={modalValue}
                    name="json-editor"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true
                    }}
                    onBlur={beautify}
                    fontSize="1em"
                    width="100%"
                    readOnly={readOnly}
                    // Enable live editing for syntax highlighting and error detection
                    onChange={onChange}
                />
            );
        }

        return (
            <>
                <Typography.Link
                    ellipsis
                    className="json_field"
                    style={{
                        color: 'rgba(0,0,0,.88)',
                        padding: 0,
                        marginBottom: 0
                    }}
                    onClick={() => setOpenModal(true)}
                >
                    {modalValue || (
                        <Button
                            disabled={readOnly}
                            icon={<EditOutlined />}
                            onClick={() => setOpenModal(true)}
                        >
                            JSON
                        </Button>
                    )}
                </Typography.Link>
                <Modal
                    open={openModal}
                    onOk={() => {
                        beautify();
                        onChange(modalValue ? JSON.parse(unbeautify(modalValue)) : undefined);
                        setOpenModal(false);
                    }}
                    onCancel={() => {
                        setOpenModal(false);
                        setModalValue(undefined);
                    }}
                    centered
                    width="75vw"
                    height="75vh"
                    title={<> </>}
                    style={{ padding: 20 }}
                >
                    <AceEditor
                        ref={editorRef}
                        mode="json"
                        value={modalValue}
                        onBlur={beautify}
                        name="json-editor"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true
                        }}
                        fontSize="1em"
                        width="100%"
                        readOnly={readOnly}
                        // Enable live editing for syntax highlighting and error detection
                        onChange={setModalValue}
                    />
                </Modal>
            </>
        );
    }
);
