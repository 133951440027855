export const getProgressColor = (value: number) => {
    if (value < 30) {
        return '#D71A21';
    }

    if (value < 40) {
        return '#EBBA3D';
    }

    return '#3185D2';
};
