import { Flex, Tooltip, Typography } from 'antd';
import {
    MRT_Column,
    MRT_Header,
    MRT_SelectCheckbox,
    MRT_TableHeadCellColumnActionsButton,
    MRT_TableHeadCellResizeHandle,
    MRT_TableHeadCellSortLabel,
    MRT_TableInstance
} from 'material-react-table';
import { useTranslation } from 'react-i18next';

export interface TableHeadCellProps {
    table: MRT_TableInstance<any>;
    column: MRT_Column<any>;
}

export const TableHeadCell = ({ table, column }: TableHeadCellProps) => {
    const { t } = useTranslation();

    const header = table
        .getHeaderGroups()[0]
        .headers.find((head) => head.column.id === column.id) as MRT_Header<any>;

    const isActionColumn = column.id?.includes('mrt-row');
    const isCheckbox = column.id === 'mrt-row-select';
    const isActions = column.id === 'mrt-row-actions';
    const isKey = column.id === 'key';

    const isEnableActions = table.options.enableColumnActions;
    const isEnableResizing = table.options.enableColumnResizing;
    const isEnablePinning = !!column.getIsPinned();

    const headerContent = isCheckbox ? (
        <MRT_SelectCheckbox
            table={table}
            sx={{ width: '1rem', height: '1rem', margin: '-0.4rem' }}
        />
    ) : isActionColumn ? null : (
        column.columnDef.header || t(column.id as string)
    );

    const cellWidth = (id: string) => `calc(var(--header-${id?.replaceAll('-', '_')}-size) * 1px)`;
    const cellMinWidth = (id: string) =>
        `max(calc(var(--header-${id?.replaceAll('-', '_')}-size)* 1px), 25px)`;

    return (
        <Flex
            style={{
                padding: '0.2rem',
                width: cellWidth(column.id),
                minWidth: cellMinWidth(column.id),
                position: isEnablePinning ? 'sticky' : 'relative',
                left: isKey ? cellWidth('mrt-row-select') : 0,
                background: 'white',
                zIndex: isEnablePinning ? 2 : 1,
                flex:
                    !isActionColumn || isActions
                        ? `var(--header-${column.id?.replaceAll('-', '_')}-size) 0 auto`
                        : undefined
            }}
            justify="space-between"
            align="center"
        >
            <Flex
                style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}
            >
                <Tooltip
                    title={isActionColumn ? null : t(column.id as string)}
                    mouseEnterDelay={0.5}
                    destroyTooltipOnHide
                >
                    <Typography.Text
                        strong
                        style={{
                            padding: 0,
                            paddingLeft: 6.5,
                            paddingRight: 6,
                            borderRadius: 0,
                            textOverflow: !isActionColumn ? 'ellipsis' : undefined,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            cursor: 'pointer'
                        }}
                        onClick={(e) => header.column.getToggleSortingHandler()?.(e)}
                    >
                        {headerContent}
                    </Typography.Text>
                </Tooltip>
                {!isActionColumn && header.column.getIsSorted() ? (
                    <MRT_TableHeadCellSortLabel table={table} header={header} />
                ) : null}
            </Flex>
            {!isActionColumn && isEnableActions ? (
                <MRT_TableHeadCellColumnActionsButton
                    table={table}
                    header={header}
                    sx={{
                        padding: 0,
                        height: '22px',
                        width: '22px',
                        borderRadius: '6px',
                        marginRight: '2px'
                    }}
                />
            ) : null}
            {!isActionColumn && isEnableResizing ? (
                <MRT_TableHeadCellResizeHandle
                    table={table}
                    header={header}
                    sx={{
                        marginRight: '4px',
                        transform: 'translateX(0px)'
                    }}
                />
            ) : null}
        </Flex>
    );
};
