import { memo, useCallback, useEffect, useState } from 'react';

import { TooltipPlacement } from 'antd/es/tooltip';
import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import {
    BaseTableRow,
    SupabaseTableRow,
    SupabaseViewRow,
    TableName,
    TableRow,
    ViewName
} from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { SelectField } from '../SelectField/SelectField';

type SupabaseViewType = SupabaseViewRow<ViewName> & BaseTableRow;

type SupabaseTableType = SupabaseTableRow<TableName> & BaseTableRow;

export interface PrefixedSelectFieldProps {
    prefix: string;
    id: string;
    value: TableRow | null;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    searchLishColumns?: MRTColumnDef[];
    searchListFilter?: Filter[];
    searchListGetFilter?: () => Promise<Filter[]>;
    viewName: ViewName;
    withLabel?: boolean;
    required?: boolean;
    asDictionarySelectFieldWithOneColumn?: boolean;

    expandOptions?: {
        enabled: boolean;
    };
    // тип newValue - тип данных по TableName если ViewName пустое, иначе тип данных по ViewName
    onChange?: (newValue: SupabaseViewType | null) => void;
    popoverPlacement?: TooltipPlacement;
    navigateLinkInViewMode?: {
        navPath: string;
        navParamName: string;
    };
    skipBlur?: boolean;
    htmlId?: string;
    alwaysFetchAfterOpen?: boolean;
}

type ValueWithoutPrefix = {
    [key: string]: any;
};

type ValueWithPrefix = {
    [key: string]: any;
};

/*
 * @param {string} id The id of the element (html identifier). Need field without postfix format
 * Example lifecycle_status insead of lifecycle_status_code
 */
export const PrefixedSelectField = memo<PrefixedSelectFieldProps>(
    ({
        id,
        prefix,
        value,
        mode,
        popoverContainerHtmlId,
        searchLishColumns,
        viewName,
        searchListFilter,
        searchListGetFilter,
        expandOptions,
        asDictionarySelectFieldWithOneColumn,
        popoverPlacement,
        withLabel = true,
        required = false,

        alwaysFetchAfterOpen = false,

        onChange = () => {},
        navigateLinkInViewMode,
        skipBlur = false,
        htmlId = id
    }) => {
        const [valueWithoutPrefix, setValueWithoutPrefix] = useState<ValueWithoutPrefix | null>(
            null
        );

        useEffect(() => {
            if (mode === 'view') {
                setValueWithoutPrefix(value);
            } else {
                setValueWithoutPrefix(null);
            }
        }, [mode, value]);

        // Есть View для поиска юзера , в таком случае хотим вернуть все поля по префиксу + поле id с префиксом будет prefix + _ + uu + id
        // Пример: префикс user_, обращаясь к vusers поле id хотим вернуть в user_uuid
        const isUserView = (viewName: ViewName): boolean => {
            if (viewName === 'vusers') {
                return true;
            }

            return false;
        };

        const userView = isUserView(viewName);

        const restorePrefix = useCallback(
            (valueWithoutPrefix: SupabaseViewType | SupabaseTableType) => {
                // Получаем от SelectField значения без префикса, восстановляем префикс
                const keysWithPrefix = Object.keys(
                    // value as T extends undefined ? SupabaseTableType : SupabaseViewType,
                    value as object
                );

                // Если поданы колонки берем в оборот accessorKey из searchLishColumns
                // Нужно для случая когда у нас пустая структура изначально(пример: создание новой записи в Detail_TablePage)
                let searchListColumnsKey = searchLishColumns
                    ? searchLishColumns.map((columnDef: MRTColumnDef) => columnDef.accessorKey)
                    : [];

                // Если не заданы колонки, то берем структуру как есть которая приходит. TODO: тут чет намудрено надо подумать как опмитимизировать
                if (searchListColumnsKey.length === 0 && valueWithoutPrefix) {
                    searchListColumnsKey = Object.keys(valueWithoutPrefix as object);
                    // .map((keyWithoutPrefix) => `${prefix}${keyWithoutPrefix}`);
                }

                const prefixValue: ValueWithPrefix = {};
                // Добавила, если мы создаем объект и value приходит пустым
                const hasPrefixKeys = Object.keys(keysWithPrefix).some((key) =>
                    key.startsWith(prefix)
                );
                const defaultFields = hasPrefixKeys ? [] : ['code', 'short_title', 'long_title'];

                if (valueWithoutPrefix) {
                    Object.keys(valueWithoutPrefix).forEach((keyWithoutPrefix: string) => {
                        // Проверяем что поле изначально передавалось с префиксом
                        if (
                            keysWithPrefix.includes(prefix + keyWithoutPrefix) ||
                            searchListColumnsKey.includes(keyWithoutPrefix) ||
                            defaultFields.includes(keyWithoutPrefix)
                        ) {
                            // Исключения для полей id для view юзеров(vusers и прочих)
                            if (keyWithoutPrefix === 'id' && userView) {
                                prefixValue[`${prefix}uu${keyWithoutPrefix}`] =
                                    valueWithoutPrefix[
                                        keyWithoutPrefix as keyof typeof valueWithoutPrefix
                                    ];
                                return;
                            }

                            prefixValue[prefix + keyWithoutPrefix] =
                                valueWithoutPrefix[
                                    keyWithoutPrefix as keyof typeof valueWithoutPrefix
                                ];
                        }
                    });
                } else {
                    keysWithPrefix.forEach((key) => {
                        if (key.startsWith(prefix)) prefixValue[key] = null;
                        else prefixValue[prefix + key] = null;
                    });
                }

                // Возвращаем обратно выбранную(из SelectField) структуру с префиксом
                onChange(prefixValue as SupabaseViewType | SupabaseTableType);
            },
            // Не включено: searchLishColumns, userView
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [value, onChange, prefix]
        );

        const handleChange = (newValue: (SupabaseViewType | SupabaseTableType) | null) => {
            // console.log('value : ', newValue);
            restorePrefix(newValue as NonNullable<typeof newValue>);
        };

        // Remove Prefix
        useEffect(() => {
            if (value && mode === 'edit') {
                // Ищем все ключи с префиксом
                let prefixKeys = Object.keys(value).filter((key: string) =>
                    prefix === '' ? true : key.startsWith(prefix)
                );

                if (prefixKeys.length === 0 && searchLishColumns) {
                    prefixKeys = searchLishColumns.map(
                        (columnDef: MRTColumnDef) => prefix + columnDef.accessorKey
                    );
                }

                const noPrefixValue: ValueWithoutPrefix = {};

                //   Передаем в SelectField новую структуру с значениями без префикса
                prefixKeys.forEach((prefixKey: string) => {
                    const keyWithoutPrefix = prefixKey.substring(prefix.length, prefixKey.length);
                    noPrefixValue[keyWithoutPrefix] = value[prefixKey];
                });

                setValueWithoutPrefix(noPrefixValue);
            }
            // Не включено: searchLishColumns, userView
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, prefix, mode]);

        return (
            <SelectField
                popoverContainerHtmlId={popoverContainerHtmlId}
                withLabel={withLabel}
                id={id}
                htmlId={htmlId}
                required={required}
                searchLishColumns={searchLishColumns}
                value={valueWithoutPrefix as TableRow}
                searchListFilter={searchListFilter}
                searchListGetFilter={searchListGetFilter}
                viewName={viewName}
                mode={mode}
                asDictionarySelectFieldWithOneColumn={asDictionarySelectFieldWithOneColumn}
                expandOptions={expandOptions}
                popoverPlacement={popoverPlacement}
                onChange={handleChange}
                navigateLinkInViewMode={navigateLinkInViewMode}
                skipBlur={skipBlur}
                prefix={prefix}
                alwaysFetchAfterOpen={alwaysFetchAfterOpen}
            />
        );
    }
);
