// KeyField.tsx
import { memo } from 'react';

import { StringField } from '../StringField/StringField';

interface KeyFieldProps {
    id: string;
    value: string | null;
    required?: boolean;
    skipBlur?: boolean;
    withLabel?: boolean;
}

export const KeyField = memo<KeyFieldProps>(
    ({ value, id, required = false, skipBlur = false, withLabel = true }) => (
        <StringField
            id={id}
            value={value}
            mode="view"
            required={required}
            skipBlur={skipBlur}
            withLabel={withLabel}
        />
    )
);
