import { Card, Tabs, TabsProps } from 'antd';
import { ReactNode, memo } from 'react';

import { AccessControl } from 'modules/auth/components';
import { usePermissions } from 'modules/auth/contexts';
import { useDataset } from 'pages/base/DetailPage/hooks/useDataset';
import { InspectModuleComponentAccess } from 'components/ModuleComponentAccess/ModuleComponentAccess';

export interface TabItemProps {
    children: ReactNode;
    label: ReactNode;
    key: string;
    id: string;
    disabled?: boolean;
    visible?: boolean;
}

export const TabsBox = memo(
    ({ withCardWrapper = true, ...props }: TabsProps & { withCardWrapper?: boolean }) => {
        const dataset = useDataset(false);
        const { permissions } = usePermissions();
        const tabs = (
            <Tabs
                {...props}
                type="card"
                size="small"
                // Для того, чтобы засунуть id в таб, придется переопределять ноду в разметке
                // и добавлять туда руками id и при этом сохранит навесы со стороны ant design !!!
                renderTabBar={(tabBarProps, DefaultTabBar) => (
                    <DefaultTabBar {...tabBarProps}>
                        {(node) => {
                            const id =
                                props.items && props.items.length
                                    ? (props.items[Number(node.key) || 0].id as string)
                                    : `ant-tabs-tab-${node.key || 'unknown'}`;

                            return (
                                <AccessControl id={id} permissions={permissions}>
                                    <InspectModuleComponentAccess
                                        component_name={id}
                                        component_path={
                                            (dataset.length > 0 &&
                                                dataset[0].tables[0].tableName) ||
                                            ''
                                        }
                                    >
                                        {/* без предупреждения не правьте строку пож */}
                                        <div {...node.props} id={id}>
                                            {node.props.children}
                                        </div>
                                    </InspectModuleComponentAccess>
                                </AccessControl>
                            );
                        }}
                    </DefaultTabBar>
                )}
            />
        );

        return withCardWrapper ? <Card>{tabs}</Card> : tabs;
    }
);
