import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField } from '../BaseField/BaseField';

interface LocalDateTimeFieldProps {
    id: string;
    value: string | null; // ISO string (from Supabase DB) aka DbDateTime
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    onChange: (newValue: string | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
}
// The component is used to display and edit date and time values using user's preferred format!

export const LocalDateTimeField = memo<LocalDateTimeFieldProps>(
    ({
        id,
        value,
        mode,
        popoverContainerHtmlId,
        withLabel = true,
        required = false,
        onChange = () => {},
        skipBlur = false
    }) => {
        const { t } = useTranslation();

        // Определение функции для рендеринга компонента выбора даты и времени
        const renderInput = useCallback(
            (
                inputValue: string | null,
                onInputChange?: (newValue: string | null) => void,
                onBlur?: () => void
            ) => {
                const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
                    e.stopPropagation();
                    if (onInputChange) {
                        onInputChange(null);
                    }
                };

                return (
                    <DatePicker
                        id={`${id}-value`}
                        placeholder={t('no_value') as string}
                        data-testid={`${id}-edit`}
                        value={inputValue ? dayjs(inputValue) : null}
                        onChange={(date: dayjs.Dayjs | null) => {
                            if (onInputChange && onBlur) {
                                onInputChange(date && date.toISOString());
                                onBlur();
                            }
                        }}
                        allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                        showTime
                        onBlur={onBlur}
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                );
            },
            [id, popoverContainerHtmlId, t]
        );
        // Определение функции для рендеринга компонента просмотра даты и времени
        const renderView = useCallback(
            (viewValue: string) => {
                return (
                    <DatePicker
                        id={`${id}-value`}
                        data-testid={`${id}-view`}
                        placeholder={t('no_value') as string}
                        value={dayjs(viewValue)}
                        variant="borderless"
                        inputReadOnly
                        allowClear={false}
                        open={false}
                        showTime
                    />
                );
            },
            [id, t]
        );
        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <BaseField
                id={id}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
