import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Flex, Input } from 'antd';
import React, { memo, useState } from 'react';
import { ButtonWithTooltips } from 'ui';

interface LocalSearchComponentProps {
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    placeholder: string;
}

export const LocalSearchComponent = memo<LocalSearchComponentProps>(
    ({ placeholder, setFilter }) => {
        const [isLocalFilter, setIsLocalFilter] = useState(false);
        const [localFilterChange, setLocalFilterChange] = useState('');

        return (
            <Flex>
                {isLocalFilter ? (
                    <Input
                        placeholder={placeholder}
                        value={localFilterChange}
                        onChange={({ target }) => setLocalFilterChange(target.value)}
                        onBlur={() => setFilter(localFilterChange)}
                        prefix={<SearchOutlined />}
                        addonAfter={
                            <CloseOutlined
                                onClick={() => {
                                    setIsLocalFilter((prev) => !prev);
                                    setFilter('');
                                    setLocalFilterChange('');
                                }}
                            />
                        }
                    />
                ) : (
                    <ButtonWithTooltips
                        id="filter-local"
                        icon={isLocalFilter ? <CloseOutlined /> : <SearchOutlined />}
                        type="default"
                        className="filter-local"
                        onClick={() => setIsLocalFilter((prev) => !prev)}
                    />
                )}
            </Flex>
        );
    }
);
