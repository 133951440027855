import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { ReactNode, createContext, useContext } from 'react';

// Create a new Supabase client instance with the given URL and key
const createSupabaseClient: () => SupabaseClient<any, 'public', any> = () => {
    const supabaseUrl = window.env.SUPABASE_URL;
    const supabaseAnonKey = window.env.SUPABASE_ANON_KEY;

    const client = createClient(`${supabaseUrl}`, `${supabaseAnonKey}`);
    return client;
};

export const supabaseClient = createSupabaseClient();

export const SupabaseContext = createContext<SupabaseClient | null>(null);

// FIX: УСТАРЕВШЕЕ, используем импорт supabaseClient
export const useSupabase = (): SupabaseClient<any, 'public', any> => {
    const context = useContext(SupabaseContext);
    if (!context) {
        throw new Error('useSupabase must be used within a SupabaseProvider');
    }
    return context;
};

export const SupabaseProvider = ({ children }: { children: ReactNode }) => {
    // TODO: try this instead of global var supabase!
    // const [supabase, setSupabase] = useState<SupabaseClient | null>(null);

    return <SupabaseContext.Provider value={supabaseClient}>{children}</SupabaseContext.Provider>;
};
