import { CloudDownloadOutlined, CloudUploadOutlined, PrinterOutlined } from '@ant-design/icons';
import { PartialDeep } from '@gilbarbara/types';
import { Flex, Typography } from 'antd';
import { toJS } from 'mobx';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { SelectPrintingFormModal } from 'components/Modals';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { TableName, ViewName } from 'modules/supabase/types/Dataset';
import { ButtonWithTooltips } from 'ui';
import { toSnakeCase } from 'utils';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { Response } from 'modules/services/backend-api/generated_api';
import { exportDataToExcel } from 'smart/utils/exportTable';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useNotifications } from 'utils/hooks';
import { getMockActionDataByGetPartially } from '../../utils';

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    selectedRows: IObjectWithId[];
    hasSelected: boolean;
    totalDataCount: number;
    fieldsSource: MetaField[];
    onClickAction: (action: Action, hardIds?: string[]) => Promise<Response | undefined>;
}

export const SmartTablePageFooterToolbar = memo<SmartTablePageToolbarProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        totalDataCount,
        selectedRows,
        fieldsSource,
        onClickAction
    }) => {
        const { t } = useTranslation();

        const actions = toJS(metaStore.meta.get(meta)?.info?.Actions);

        const { openNotify } = useNotifications({ message: '' });

        // const [isImportModalOpen, setImportModalOpen] = useState(false);
        const [showPrinttingFormsModal, setPrinttingFormsModal] = useState(false);

        const handleExportToExcel = async () => {
            const error: null | Error = null;

            if (error) {
                openNotify({
                    message: t('error'),
                    description: makeErrorReadable((error as Error).message),
                    type: 'error'
                });
            }

            exportDataToExcel({
                metaName: meta,
                tableData: selectedRows,
                columns: fieldsSource
            });
        };

        return (
            <>
                <Flex justify="space-between" className="smart_table__footer">
                    <Flex gap={5} className="smart_table__footer_left">
                        {/* ### импорт/экспорт */}
                        <ButtonWithTooltips
                            type="default"
                            className=""
                            id="download_outlined"
                            tooltipTitle={t('download_outlined')}
                            tooltipPlacement="top"
                            icon={<CloudUploadOutlined />}
                            onClick={() => {
                                // TODO: 19.08.2024 пока закомментили поиск экшна
                                // if (actions) {
                                //     const importAction = actions.find(
                                //         // (action) => action.Handler_Code === 'ImportFromExcel'
                                //         (action) => action.Handler?.MethodName === 'ImportFromExcel'
                                //     );

                                //     if (importAction) onClickAction(importAction, [v4()]);
                                // }

                                const action = getMockActionDataByGetPartially({
                                    Handler_Code: 'ImportFromExcel',
                                    Meta_Code: meta,
                                    Name: { ru: 'Импорт из Excel' },
                                    Handler: {
                                        ChildParams: [
                                            {
                                                ParentHandler_Code: '',
                                                ParamName: 'document_type_code',
                                                Name: {
                                                    en: 'Document type',
                                                    ru: 'Тип документа'
                                                },
                                                ValueType: 'code;ref:DctDocumentTypes.Code',
                                                IsRequested: true,
                                                IsMandatory: false,
                                                ChildIndex: 0
                                            },
                                            {
                                                ParentHandler_Code: '',
                                                ParamName: 'file_path',
                                                Name: { en: 'File', ru: 'Файл' },
                                                ValueType: 'file;bucket:downloads',
                                                IsRequested: true,
                                                IsMandatory: false,
                                                ChildIndex: 1
                                            }
                                        ],
                                        Code: 'ImportFromExcel',
                                        Name: { ru: 'Импорт из Excel' },
                                        Meta_Code: meta
                                    }
                                });

                                onClickAction(action, [v4()]);
                            }}
                        />

                        <ButtonWithTooltips
                            type="default"
                            className=""
                            id="download_up_outlined"
                            tooltipTitle={t('download_up_outlined')}
                            tooltipPlacement="top"
                            icon={<CloudDownloadOutlined />}
                            onClick={() => {
                                if (actions) {
                                    const exportAction = actions.find(
                                        // (action) => action.Handler_Code === 'ExportToExcel'
                                        (action) => action.Handler?.MethodName === 'ExportToExcel'
                                    );

                                    if (exportAction) onClickAction(exportAction);
                                }

                                // const action = getMockActionDataByGetPartially({
                                //     Handler_Code: 'ExportToExcel',
                                //     Meta_Code: meta,
                                //     Name: { ru: 'Экспорт в Excel' },
                                //     Handler: {
                                //         ChildParams: [],
                                //         Code: 'ExportToExcel',
                                //         Name: { ru: 'Экспорт в Excel' },
                                //         Meta_Code: meta
                                //     }
                                // });

                                handleExportToExcel();

                                // onClickAction(action);
                            }}
                            disabled={selectedRowKeys.length === 0}
                        />

                        <ButtonWithTooltips
                            type="default"
                            className=""
                            id="massPrintBtn"
                            disabled={!hasSelected}
                            tooltipTitle={t('massPrintBtn')}
                            tooltipPlacement="top"
                            onClick={() => {
                                setPrinttingFormsModal(true);
                            }}
                            icon={<PrinterOutlined />}
                        />
                    </Flex>

                    <Flex className="smart_table__footer_center">
                        {/* TODO: center part of toolbar */}
                        <></>
                    </Flex>

                    <Flex className="smart_table__footer_right">
                        {/* TODO: right part of toolbar */}
                        <Typography.Text strong>
                            {selectedRowKeys.length
                                ? `${t('selected')} ${selectedRowKeys.length} ${t('out_of')} `
                                : null}
                            {totalDataCount} {t('rows')}
                        </Typography.Text>
                    </Flex>
                </Flex>

                {/* <ImportFromExcelModal
                    open={isImportModalOpen}
                    setOpen={setImportModalOpen}
                    meta={meta}
                /> */}

                <SelectPrintingFormModal
                    open={showPrinttingFormsModal}
                    setOpen={setPrinttingFormsModal}
                    documentIds={selectedRowKeys.map((id) => Number(id))}
                    tableName={toSnakeCase(meta) as TableName}
                    viewName={`v${toSnakeCase(meta)}` as ViewName}
                />
            </>
        );
    }
);
