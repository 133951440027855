import { LabelValue } from '@gilbarbara/types';
import { LinkOutlined } from '@mui/icons-material';
import { Button, Select, SelectProps, TreeSelect, TreeSelectProps, Typography } from 'antd';
import { toJS } from 'mobx';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useBoolean } from 'react-use';

import { Route } from 'modules/services/backend-api/generated_api';
import { ButtonWithTooltips } from 'ui';
import { Loader } from 'ui/Loader/Loader';
import { i18n } from 'utils/i18n/i18n';
import { checkAndMakeTreeData, fnv1aHash } from 'smart/utils';
import { useStoreNavigate } from 'utils/store';
import { emitter } from 'utils/emitter';
import { metaStore } from 'utils/store/MetaStore';

import { SmartSearchList } from './components';
import {
    filterOption,
    filterSort,
    findRoute,
    renderOptionLabel,
    transformDataToOptions,
    useSelectData
} from './utils';

import './SmartSelectField.scss';
import { SmartSelectInner } from './ui';

export const MAX_VISIBLE_OPTIONS = 10;

interface SmartSelectFieldProps {
    value: any | null;
    meta: string;
    onChange: (newValue: any) => void;
    popoverContainerHtmlId?: string;
    simpleMode?: boolean;
    label?: string;
    loading?: boolean;
    disabled?: boolean;
    filters?: string;
    style?: React.CSSProperties;
    className?: string;
    navigateMetaOptions?: {
        metaName: string;
    };
    variant?: 'filled' | 'outlined' | 'borderless';
    myViewFieldName?: string;
    treeOptions?: {
        forceDisable?: boolean;
        parentFieldName?: string;
    };
}

export const SmartSelectField = memo<SmartSelectFieldProps>(
    ({
        value,
        popoverContainerHtmlId,
        meta,
        onChange,
        label,
        loading,
        disabled,
        style,
        navigateMetaOptions,
        variant,
        treeOptions,
        className = '',
        filters = '',
        simpleMode = false,
        myViewFieldName = ''
    }) => {
        const location = useLocation();
        const { t } = useTranslation();
        const navigate = useStoreNavigate();
        const { modalTitle, dataSource, viewFieldName, modalFields, fetchData, isLoading } =
            useSelectData({
                meta,
                filters
            });

        const metaData = toJS(metaStore.meta.get(meta));
        const isMetaReadOnly = metaData?.info?.IsReadOnly || false;

        const [isOpenMore, setOpenMore] = useBoolean(false);

        useEffect(() => {
            const cb = (e: unknown) => {
                onChange(e);
            };

            if (!simpleMode && !isLoading) {
                emitter.on(`create_${meta}`, cb);
            }

            return () => emitter.off(`create_${meta}`, cb);
        }, [isLoading, meta, onChange, simpleMode]);

        const isWithMetaNavParams =
            navigateMetaOptions && value && !!findRoute(navigateMetaOptions.metaName);

        const outputOptions = useMemo<LabelValue[]>(() => {
            const treeData = checkAndMakeTreeData(dataSource, treeOptions?.parentFieldName, false);

            const options = transformDataToOptions(treeData, myViewFieldName || viewFieldName);

            // const options = dataSource.map((item) => ({
            //     value: item.Code || item.Id,
            //     label: renderOptionLabel(item, myViewFieldName || viewFieldName)
            // }));
            // console.log('[SmartSelectField] options:', options);

            return options;
        }, [dataSource, myViewFieldName, treeOptions?.parentFieldName, viewFieldName]);

        const displayValue = useMemo(() => {
            const selectedOption = outputOptions.find(
                (opt) => value && opt.value === (value.Code || value.Id)
            );

            return selectedOption
                ? selectedOption.label
                : renderOptionLabel(value, 'ShortTitle') || renderOptionLabel(value, 'Name');
        }, [outputOptions, value]);

        const handleNavigate = useCallback(
            async (event: React.MouseEvent) => {
                event.stopPropagation();

                if (!navigateMetaOptions) {
                    console.warn('[SmartSelectField.handleNavigate] navigateMetaOptions is empty.');
                    return;
                }

                const needle = findRoute(navigateMetaOptions.metaName);

                if (!needle) {
                    console.warn(
                        `[SmartSelectField.handleNavigate] ${navigateMetaOptions.metaName} no found.`
                    );
                    return;
                }

                const navId = needle.detail_route_id || value.Id;

                navigate(`${needle.path}/${navId}`);
            },
            [navigate, navigateMetaOptions, value?.Id]
        );

        const handleCloseMore = useCallback(() => {
            setOpenMore(false);
        }, []);

        const handleOpenMore = useCallback(() => {
            setOpenMore(true);
        }, []);

        const handleCreate = useCallback(() => {
            const metaInfo = toJS(metaStore.meta.get(meta)?.info);
            const metaRoutes = toJS(metaStore.meta.get('all')?.routes);

            let needle = metaRoutes?.find((route) => route.meta === meta);

            if (!needle) needle = { path: `/other/${meta}` } as Route;

            let pathname = `${needle?.path}/new`;

            if (location.pathname.endsWith('/')) {
                pathname = `${needle.path}new`;
            }

            const singularName =
                metaInfo?.SingularName?.[i18n.language] || t(pathname.split('/').at(-2) || ''); // get pre last pathname element for correctly displaying object context

            navigate(
                { pathname },
                {
                    state: {
                        ...location.state,
                        cacheKey: fnv1aHash(`${meta}_new_edit`),
                        pageTitle: `${t('new')} (${singularName})`
                    }
                }
            );
        }, [location.pathname, location.state, meta, navigate, t]);

        return (
            <div
                className={`${isWithMetaNavParams ? 'select--with-meta-navigate' : ''}`}
                style={{ position: 'relative', width: '100%' }}
            >
                <SmartSearchList
                    fields={modalFields}
                    title={modalTitle}
                    meta={meta}
                    open={isOpenMore}
                    onCancel={handleCloseMore}
                    data={dataSource}
                    initialSelectedRows={value ? [value] : []}
                    onOk={(selectedRows) => {
                        onChange(selectedRows[0]);
                        handleCloseMore();
                    }}
                />
                {label && <Typography.Text type="secondary">{label}</Typography.Text>}
                <SmartSelectInner
                    disableTree={treeOptions?.forceDisable}
                    onFocus={fetchData}
                    variant={variant}
                    loading={loading}
                    disabled={disabled}
                    showSearch={!simpleMode}
                    className={className}
                    style={{ width: '100%', ...style }}
                    value={typeof displayValue === 'number' ? String(displayValue) : displayValue}
                    allowClear
                    placeholder={!simpleMode ? t('no_value') : undefined}
                    options={outputOptions}
                    popupMatchSelectWidth={false}
                    onChange={(value) => {
                        onChange(dataSource.find((item) => item.Id === value));
                    }}
                    filterOption={filterOption}
                    filterTreeNode={filterOption}
                    filterSort={filterSort}
                    notFoundContent={isLoading ? <Loader size="small" /> : undefined}
                    dropdownRender={
                        !simpleMode && !isLoading
                            ? (menu) => (
                                  <>
                                      {menu}
                                      {outputOptions.length > 0 && (
                                          <Button type="link" onClick={handleOpenMore}>
                                              {t('show_more')}
                                          </Button>
                                      )}
                                      <br />
                                      <Button
                                          disabled={isMetaReadOnly}
                                          type="link"
                                          onClick={handleCreate}
                                      >
                                          {t('create')}
                                      </Button>
                                  </>
                              )
                            : undefined
                    }
                    getPopupContainer={
                        popoverContainerHtmlId
                            ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                            : undefined
                    }
                />
                {isWithMetaNavParams ? (
                    <ButtonWithTooltips
                        id={`${meta}-nav-button`}
                        className="meta-nav-button"
                        onClick={handleNavigate}
                        icon={<LinkOutlined />}
                    />
                ) : null}
            </div>
        );
    }
);
