import { TranslationOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { ButtonWithTooltips } from 'ui';
import { languagesStore } from 'utils/store/languages/languagesStore';
import './style.scss';

const initialLanguages = [
    {
        value: 'ru',
        label: 'Русский'
    },
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'tech',
        label: 'Technical'
    }
];

export const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();

    const [storageLang, setStorageLang] = useLocalStorage('lang', 'ru');

    useEffect(() => {
        if (storageLang) {
            i18n.changeLanguage(storageLang as string);
        } else {
            setStorageLang('ru');
            i18n.changeLanguage('ru');
        }
    }, [i18n, setStorageLang, storageLang]);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setStorageLang(lang);
    };

    const languages = useMemo(() => {
        if (languagesStore.languages?.length) {
            return languagesStore.languages.map((language) => ({
                value: language.Code.toLowerCase(),
                label: language.Name?.[storageLang === 'tech' ? 'en' : i18n.language] || 'Technical'
            }));
        }

        return initialLanguages;
    }, [i18n.language, storageLang]);

    return (
        <ButtonWithTooltips
            id="language-switcher"
            className=""
            type="text"
            icon={<TranslationOutlined />}
        >
            <Select
                dropdownStyle={{ width: 'max-content' }}
                className="header__languages"
                value={storageLang}
                options={languages}
                onChange={handleLanguageChange}
            />
        </ButtonWithTooltips>
    );
};
