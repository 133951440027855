import { MRTColumnDef } from 'components/DataTable/MRT_Types';

let cachedColumns: null | MRTColumnDef[] = null;

export const formatColumns = (columns: MRTColumnDef[]) => {
    if (!cachedColumns || columns !== cachedColumns) {
        cachedColumns = columns.map((column) => {
            const accessorKey = column.accessorKey;
            if (accessorKey?.match('_at')) {
                return { ...column, size: 250 };
            }
            if (accessorKey?.match('_range')) return { ...column, size: 400 };

            return column;
        });
    }

    return cachedColumns;
};
