import { MRT_Localization } from 'material-react-table';

export const MRT_Localization_KK: Partial<MRT_Localization> = {
    actions: 'Әрекеттер',
    and: 'және',
    cancel: 'Бас тарту',
    changeFilterMode: 'Сүзгі режимін өзгертіңіз',
    changeSearchMode: 'Іздеу режимін өзгертіңіз',
    clearFilter: 'Сүзгіні тазалаңыз',
    clearSearch: 'Нақты іздеу',
    clearSort: 'Нақты сұрыптау',
    clickToCopy: 'Көшіру үшін басыңыз',
    collapse: 'Құлау',
    collapseAll: 'Барлығын бүктеңіз',
    columnActions: 'Бағандармен әрекеттер',
    copiedToClipboard: 'Аралық сақтағышқа көшірілді',
    dropToGroupBy: '{column} бойынша топқа өтіңіз',
    edit: 'Өңдеу',
    expand: 'Кеңейту',
    expandAll: 'Барлығын кеңейтіңіз',
    filterArrIncludes: 'Қамтиды',
    filterArrIncludesAll: 'Барлығын қамтиды',
    filterArrIncludesSome: 'Қамтиды',
    filterBetween: 'Арасында',
    filterBetweenInclusive: 'Инклюзивті Арасында',
    filterByColumn: '{column} бойынша сүзу',
    filterContains: 'Құрамында',
    filterEmpty: 'Бос',
    filterEndsWith: 'Сөздермен Аяқталады',
    filterEquals: 'Тең',
    filterEqualsString: 'Тең',
    filterFuzzy: 'Бұлыңғыр',
    filterGreaterThan: 'Артық',
    filterGreaterThanOrEqualTo: 'Үлкен Немесе Тең',
    filterInNumberRange: 'Арасында',
    filterIncludesString: 'Құрамында',
    filterIncludesStringSensitive: 'Құрамында',
    filterLessThan: 'Aз',
    filterLessThanOrEqualTo: 'Аз Немесе Тең',
    filterMode: 'Сүзу режимі: {filterType}',
    filterNotEmpty: 'Бос емес',
    filterNotEquals: 'Тең емес',
    filterStartsWith: 'Басталады',
    filterWeakEquals: 'Тең',
    filteringByColumn: 'Бойынша сүзу {column} - {filterType} {filterValue}',
    goToFirstPage: 'Бірінші бетке өтіңіз',
    goToLastPage: 'Соңғы бетке өтіңіз',
    goToNextPage: 'Келесі бетке өтіңіз',
    goToPreviousPage: 'Алдыңғы бетке өтіңіз',
    grab: 'Түсіру',
    groupByColumn: '{column} бойынша топтау',
    groupedBy: 'Бойынша топтастырылған ',
    hideAll: 'Барлығын жасыру',
    hideColumn: '{column} бағанын жасыру',
    max: 'Макс',
    min: 'Мин',
    move: 'Жылжыту',
    noRecordsToDisplay: 'Көрсетілетін жазбалар жоқ',
    noResultsFound: 'Нәтижелер табылмады',
    of: 'бастап',
    or: 'немесе',
    pinToLeft: 'Солға бекітіңіз',
    pinToRight: 'Оңға бекітіңіз',
    resetColumnSize: 'Баған өлшемін қалпына келтіріңіз',
    resetOrder: 'Қалпына келтіру тәртібі',
    rowActions: 'Жолдағы әрекеттер',
    rowNumber: '#',
    rowNumbers: 'Жол нөмірлері',
    rowsPerPage: 'Беттегі жолдар',
    save: 'Сақтау',
    search: 'Іздеу',
    selectedCountOfRowCountRowsSelected: 'таңдалған жолдардың {rowCount} ішінен {selectedCount}',
    select: 'Таңдау',
    showAll: 'Барлығын көрсету',
    showAllColumns: 'Барлық бағандарды көрсету',
    showHideColumns: 'Бағандарды көрсету/жасыру',
    showHideFilters: 'Сүзгілерді көрсету/жасыру',
    showHideSearch: 'Іздеуді көрсету/жасыру',
    sortByColumnAsc: '{column} өсуі бойынша сұрыптау',
    sortByColumnDesc: '{column} бойынша кему ретімен сұрыптау',
    sortedByColumnAsc: '{column} өсуі бойынша сұрыпталған',
    sortedByColumnDesc: 'Кему бойынша {column} бойынша сұрыпталған',
    thenBy: ', содан кейін көмегімен ',
    toggleDensity: 'Ауыстыру тығыздығы',
    toggleFullScreen: 'Бүкіл экранға ауысу',
    toggleSelectAll: 'Ауыстыру Барлығын таңдау',
    toggleSelectRow: 'Жолды таңдауды ауыстыру',
    toggleVisibility: 'Көрінуді ауыстыру',
    ungroupByColumn: 'топтан шығару {column}',
    unpin: 'босату',
    unpinAll: 'барлығын босатыңыз'
};
