import { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrefixedSelectField } from 'components/fields';
import { useSupabase } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { Database } from 'modules/supabase/types/database.types';
import { useNotifications, useResize } from 'utils/hooks';

interface StatusPanelProps {
    fields: any;
    mode: 'view' | 'edit';
    onChange: (newValue: any) => void;
    statusCodes?: string[];
}

export const StatusPanel: FC<StatusPanelProps> = memo(({ fields, mode, onChange, statusCodes }) => {
    const supabase = useSupabase();
    const { isScreenMd } = useResize();

    const { t } = useTranslation();

    const { openNotify } = useNotifications({ message: '' });

    const extraFilters = useMemo(
        () =>
            statusCodes
                ? statusCodes.map((statusCode) => ({
                      column: 'status_code',
                      operator: 'eq',
                      value: statusCode
                  }))
                : [],
        [statusCodes]
    );

    const searchListFilter = useMemo(
        () => [
            {
                column: 'status_group_id',
                operator: 'eq',
                value: fields?.lifecycle_status_group_id || 1 // Standart Statuses
            },
            ...extraFilters
        ],
        [fields?.lifecycle_status_group_id, extraFilters]
    );

    // Автоматическое проставление статуса Создано в контексте создания
    useEffect(() => {
        const queryLocation = new URLSearchParams(window.location.search);
        const modeFromURL = queryLocation.get('mode');

        if (modeFromURL === 'create' && !fields.lifecycle_status_code) {
            const setCreatedStatus = async () => {
                const result = await supabase
                    .from('dicts_statuses')
                    .select('*')
                    .filter('code', 'eq', 'CREATED');

                const newData = result.data ?? [];

                if (newData.length === 0) {
                    openNotify({
                        message: t('create_status_info'),
                        description: t('create_status_not_found') as string,
                        type: 'warning'
                    });

                    return;
                }

                const status = newData[0] as Database['public']['Tables']['dicts_statuses']['Row'];

                const createdStatusData = {
                    lifecycle_status_code: status.code,
                    lifecycle_status_short_title: status.short_title,
                    lifecycle_status_long_title: status.long_title
                };

                onChange(createdStatusData);
            };

            setCreatedStatus();
        }
    }, [fields.lifecycle_status_code, onChange, openNotify, supabase, t]);

    return (
        <>
            <div className="status-panel">
                <PrefixedSelectField
                    id="lifecycle"
                    viewName="vstatuses_lifecycle"
                    prefix="lifecycle_"
                    value={fields}
                    mode={mode}
                    onChange={onChange}
                    searchListFilter={searchListFilter}
                    asDictionarySelectFieldWithOneColumn
                    withLabel={!isScreenMd}
                />
            </div>
        </>
    );
});
