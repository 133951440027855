// TypeField.tsx
import { t } from 'i18next';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'modules/supabase/types/Dataset';
import { StringField } from '../StringField/StringField';

interface TypeFieldType {
    id: string;
    value: TableRow | null;
    withLabel?: boolean;
    required?: boolean;
    skipBlur?: boolean;
}

export const TypeField = memo<TypeFieldType>(
    ({ value, id, withLabel = true, required = false, skipBlur = false }) => {
        const {
            i18n: { language: currentLanguage }
        } = useTranslation();

        // Если перевод undefined то показать перевод code, например для DEFAULT, как DEFAULT перестанем использовать можно будет и убрать проверку
        const typeTitle = value?.type_short_title?.[currentLanguage] || t(value?.type_code);

        return (
            <StringField
                id={id}
                value={typeTitle}
                mode="view"
                withLabel={withLabel}
                required={required}
                skipBlur={skipBlur}
            />
        );
    }
);
