import {
    CaretRightOutlined,
    CloseOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    HolderOutlined,
    MoreOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { CheckboxProps, MenuItem, TableCellProps } from '@mui/material';
import { TableMeta } from '@tanstack/react-table';
import {
    MRT_Column,
    MRT_DensityState,
    MRT_Header,
    MRT_Localization,
    MRT_Row,
    MRT_SelectCheckbox,
    MRT_TableHeadCellColumnActionsButton,
    MRT_TableHeadCellResizeHandle,
    MRT_TableHeadCellSortLabel,
    MRT_TableInstance,
    MaterialReactTable,
    useMaterialReactTable
} from 'material-react-table';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router-dom';

import { Flex, Input, Tooltip, Typography } from 'antd';
import { t } from 'i18next';
import { AccessControl } from 'modules/auth/components';
import { usePermissions } from 'modules/auth/contexts';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import {
    RootTableRow,
    SupabaseRow,
    TableName,
    ViewName,
    ViewOrTableName
} from 'modules/supabase/types/Dataset';
import { ButtonWithTooltips } from 'ui/ButtonWithTooltips/ButtonWithTooltips';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';
import { Loader } from 'ui/Loader/Loader';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useMessages, useNotifications } from 'utils/hooks';
import { getMaterialReactTableLocalization } from 'utils/i18n/libs/material-react-table/translateUtils';
import { useStoreNavigate } from 'utils/store';
import type {
    MRTColumnDef,
    MRTRow,
    MRTTableInstance,
    MRTTableOptions,
    MRTTableState
} from './MRT_Types';

interface CollapseIconProps {
    style: React.CSSProperties;
}

const muiTableBodyCellProps: TableCellProps = {
    classes: {
        root: 'outline-none'
    },
    size: 'small'
};

const muiTableDetailPanelProps = { sx: { zIndex: 1 } };

const muiTablePaperProps = ({ table }: { table: MRTTableInstance }) => ({
    style: {
        height: table.getState().isFullScreen ? '100vh-64px' : undefined,
        width: table.getState().isFullScreen ? '100vw-50px' : undefined,
        marginTop: table.getState().isFullScreen ? 64 : undefined,
        marginLeft: table.getState().isFullScreen ? 50 : undefined
    }
});

let cachedLocalization: null | Partial<MRT_Localization>;
const prevIsHideColumnHeader: boolean | null = null;

const getLocalization = (hideColumnHeader: boolean) => {
    if (!cachedLocalization || hideColumnHeader !== prevIsHideColumnHeader) {
        const localization = getMaterialReactTableLocalization();
        cachedLocalization = {
            ...getMaterialReactTableLocalization(),
            select: hideColumnHeader ? '' : localization.select,
            move: hideColumnHeader ? '' : localization.move,
            expand: hideColumnHeader ? '' : localization.expand,
            actions: hideColumnHeader ? '' : localization.actions
        };
    }

    return cachedLocalization;
};

const muiCheckboxProps: CheckboxProps = {
    size: 'small',
    color: 'primary',
    sx: { width: '1rem', height: '1rem', margin: '0rem' }
};

export const CollapseIcon: React.FC<CollapseIconProps> = ({ style }) => {
    return (
        <CaretRightOutlined
            className="collapse-icon"
            style={{
                ...style,
                transform: `rotate(${style.transform?.includes('-180') ? 90 : 0}deg)`
            }}
        />
    );
};

const renderEmptyRowsFallback = () => <EmptyMarker />;

const icons = {
    ExpandMoreIcon: CollapseIcon,
    MoreVertIcon: () => <MoreOutlined style={{ color: 'black' }} />,
    FullscreenIcon: FullscreenOutlined,
    FullscreenExitIcon: FullscreenExitOutlined,
    DragHandleIcon: HolderOutlined,
    MoreHorizIcon: () => <MoreOutlined style={{ color: 'black' }} />
};

interface IBase_DataTable extends MRTTableOptions {
    viewName: ViewName;
    data: any[];
    hideColumnHeader?: boolean;
    columnVisibilityKey?: string;
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
    isHasActionColumn?: boolean;
    state?: Partial<MRTTableState & { virtualization: boolean }>;
    tableInstanceRef?: React.MutableRefObject<MRTTableInstance | null>;
    emptyRowsMarker?: ((props: { table: MRT_TableInstance<any> }) => React.ReactNode) | undefined;
    extraExpandHide?: (props: { row: MRT_Row<any>; table?: MRT_TableInstance<any> }) => boolean;
    renderFetchedInfo?: (selectedCount?: number) => React.ReactNode;
}

type TTableMeta = TableMeta<object> & { tableName: TableName; viewName: ViewName };

// TODO: вынести в либу
const formatLocation = (location: Location, id: number | string) => {
    let pathname = `${location.pathname}/${id}`;

    if (location.pathname.endsWith('/')) {
        pathname = `${location.pathname}${id}`;
    }

    return pathname;
};

let cachedColumns: null | MRTColumnDef[] = null;

const formatColumns = (columns: MRTColumnDef[]) => {
    if (!cachedColumns || columns !== cachedColumns) {
        cachedColumns = columns.map((column) => {
            const accessorKey = column.accessorKey;
            if (accessorKey?.match('_at')) {
                return { ...column, size: 250 };
            }
            if (accessorKey?.match('_range')) return { ...column, size: 400 };

            return column;
        });
    }

    return cachedColumns;
};

function filterData(data: any[], filterString: string, fields: string[], language: string) {
    return data.filter((item) => {
        if (!fields.length) return true;
        return fields.some((field) => {
            if (field.includes('code')) return false;
            if (item[field] !== null) {
                if (typeof item[field] === 'string' || typeof item[field] === 'number') {
                    return String(item[field]).toLowerCase().includes(filterString.toLowerCase());
                }
                if (typeof item[field] === 'object' && item[field][language]) {
                    return String(item[field][language])
                        .toLowerCase()
                        .includes(filterString.toLowerCase());
                }
                return false;
            }
            return false;
        });
    });
}

const LocalSearchComponent = memo<{
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    placeholder: string;
}>(({ placeholder, setFilter }) => {
    const [isLocalFilter, setIsLocalFilter] = useState(false);
    const [localFilterChange, setLocalFilterChange] = useState('');

    return (
        <Flex>
            {isLocalFilter ? (
                <Input
                    placeholder={placeholder}
                    value={localFilterChange}
                    onChange={({ target }) => setLocalFilterChange(target.value)}
                    onBlur={() => setFilter(localFilterChange)}
                    prefix={<SearchOutlined />}
                    addonAfter={
                        <CloseOutlined
                            onClick={() => {
                                setIsLocalFilter((prev) => !prev);
                                setFilter('');
                                setLocalFilterChange('');
                            }}
                        />
                    }
                />
            ) : (
                <ButtonWithTooltips
                    id="filter-local"
                    icon={isLocalFilter ? <CloseOutlined /> : <SearchOutlined />}
                    type="default"
                    className="filter-local"
                    onClick={() => setIsLocalFilter((prev) => !prev)}
                />
            )}
        </Flex>
    );
});

const muiTableHeadCellProps = ({
    table,
    column
}: {
    table: MRTTableInstance;
    column: MRT_Column<SupabaseRow<ViewOrTableName>>;
}) => ({
    classes: {
        root: 'head-cell-content-wrapper'
    },
    component: () => {
        const header = table
            .getHeaderGroups()[0]
            .headers.find((head) => head.column.id === column.id) as MRT_Header<
            SupabaseRow<ViewOrTableName>
        >;

        const isActionColumn = column.id?.includes('mrt-row');
        const isCheckbox = column.id === 'mrt-row-select';
        const isActions = column.id === 'mrt-row-actions';

        const isEnableActions = table.options.enableColumnActions;
        const isEnableResizing = table.options.enableColumnResizing;

        const headerContent = isCheckbox ? (
            <MRT_SelectCheckbox
                table={table}
                sx={{ width: '1rem', height: '1rem', margin: '-0.4rem' }}
            />
        ) : isActionColumn ? null : (
            column.columnDef.header || t(column.id as string)
        );

        return (
            <Flex
                style={{
                    padding: '0.2rem',
                    width: `calc(var(--header-${column.id?.replaceAll('-', '_')}-size) * 1px)`,
                    minWidth: `max(calc(var(--header-${column.id?.replaceAll(
                        '-',
                        '_'
                    )}-size)* 1px), 25px)`,
                    position: 'relative',
                    flex:
                        !isActionColumn || isActions
                            ? `var(--header-${column.id?.replaceAll('-', '_')}-size) 0 auto`
                            : undefined
                }}
                justify="space-between"
                align="center"
            >
                <Flex
                    style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Tooltip
                        title={isActionColumn ? null : t(column.id as string)}
                        mouseEnterDelay={0.5}
                        destroyTooltipOnHide
                    >
                        <Typography.Text
                            strong
                            style={{
                                padding: 0,
                                paddingLeft: 6.5,
                                paddingRight: 6,
                                borderRadius: 0,
                                textOverflow: !isActionColumn ? 'ellipsis' : undefined,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer'
                            }}
                            onClick={(e) => header.column.getToggleSortingHandler()?.(e)}
                        >
                            {headerContent}
                        </Typography.Text>
                    </Tooltip>
                    {!isActionColumn && header.column.getIsSorted() ? (
                        <MRT_TableHeadCellSortLabel table={table} header={header} />
                    ) : null}
                </Flex>
                {!isActionColumn && isEnableActions ? (
                    <MRT_TableHeadCellColumnActionsButton
                        table={table}
                        header={header}
                        sx={{
                            padding: 0,
                            height: '22px',
                            width: '22px',
                            borderRadius: '6px',
                            marginRight: '2px'
                        }}
                    />
                ) : null}
                {!isActionColumn && isEnableResizing ? (
                    <MRT_TableHeadCellResizeHandle
                        table={table}
                        header={header}
                        sx={{
                            marginRight: '4px',
                            transform: 'translateX(0px)'
                        }}
                    />
                ) : null}
            </Flex>
        );
    }
});

const muiTableHeadRowProps = { sx: { display: 'flex' } };

// Base component for displaying data tables - a wrapper over MaterialReactTable
export const Base_DataTable: React.FC<IBase_DataTable> = memo((props) => {
    const {
        data,
        columns,
        viewName,
        state,
        hideColumnHeader = true,
        meta,
        setRefresh,
        isHasActionColumn = false,
        tableInstanceRef,
        emptyRowsMarker,
        extraExpandHide,
        ...otherProps
    } = props;

    // const newColumnsProp = useMemo(() => (data.length > 0 ? columns : []), [columns, data.length]);

    const [localFilter, setLocalFilter] = useState('');

    const { t, i18n } = useTranslation();
    const navigate = useStoreNavigate();

    const { permissions } = usePermissions();

    const { openMessage, loadingMessage } = useMessages({ message: '' });

    const { openNotify } = useNotifications({ message: '' });

    const localization = getLocalization(hideColumnHeader);
    const location = useLocation();

    const handleEdit = useCallback(
        (rowId: string | number) => {
            const pathname = formatLocation(location, rowId);
            navigate(`${pathname}?mode=edit`);
        },
        [location, navigate]
    );

    const handleClone = useCallback(
        (rowId: string | number) => {
            const pathname = formatLocation(location, rowId);

            navigate(`${pathname}?mode=clone`);
        },
        [location, navigate]
    );

    // TODO: в одну либу вынести
    const handleDelete = useCallback(
        async (row: RootTableRow) => {
            const messageKey = 'deleting_documents';

            loadingMessage(messageKey);

            const promises: any = [];

            if ((meta as TTableMeta)?.tableName.includes('settings')) {
                promises.push(
                    supabaseClient
                        .from((meta as TTableMeta)?.tableName)
                        .delete()
                        .match({ id: row.id, ver: row.ver })
                );
            } else {
                let recordsToUpdate: object = {
                    lifecycle_status_code: 'DELETED'
                };

                if (meta) {
                    if ((meta as TTableMeta).tableName === 'docs_transportation_trips') {
                        recordsToUpdate = {
                            ...recordsToUpdate,
                            planning_status_code: null,
                            approval_status_code: null
                        };
                    }

                    promises.push(
                        supabaseClient
                            .from((meta as TTableMeta)?.tableName)
                            .update(recordsToUpdate)
                            .match({ id: row.id, ver: row.ver })
                    );
                }
            }

            try {
                await Promise.all(promises);

                openMessage({
                    key: messageKey,
                    message: t('deleting_success'),
                    type: 'success'
                });

                if (setRefresh) {
                    setRefresh(true);
                }
            } catch (error) {
                // В UI
                openMessage({
                    key: messageKey,
                    message: t('deliting_failed_dataset'),
                    type: 'error'
                });
                openNotify({
                    message: t('deleting_error'),
                    description: makeErrorReadable(
                        (error as Error)?.message || t('deliting_failed_dataset')
                    ),
                    type: 'error',
                    duration: 0 // Выключаем автоматическое закрытие нотификации
                });

                // В консоль
                console.warn(t('deleting_error'));
                console.error(error);
            }
        },
        [meta, setRefresh, t]
    );

    const forceDisableExpandIfNoSubRows = useCallback(() => {
        const isNotTree = data.every((item) => item.subRows && item.subRows.length === 0);

        if (isNotTree) return { enableExpanding: false, enableExpandAll: false };

        return {
            enableExpanding: props.enableExpanding,
            enableExpandAll: props.enableExpandAll
        };
    }, [data, props.enableExpandAll, props.enableExpanding]);

    const { enableExpanding, enableExpandAll } = forceDisableExpandIfNoSubRows();

    const generateClassNames = `data_table_wrapper ${
        !props.data.length ? 'data_table_wrapper__not_loaded' : ''
    }`;

    // const renderBottomToolbarCustomActions = !enableExpanding
    //     ? props.renderBottomToolbarCustomActions
    //     : undefined;

    const renderRowActionMenuItems: (props: {
        closeMenu: () => void;
        row: MRT_Row<any>;
        table: MRT_TableInstance<any>;
    }) => React.ReactNode[] = useCallback(
        ({ row }) => [
            <MenuItem id="edit" key={row.original.id} onClick={() => handleEdit(row.original.id)}>
                {t('edit')}
            </MenuItem>,
            <AccessControl key={`${row.original.id}-2`} id="clone" permissions={permissions}>
                <MenuItem id="clone" onClick={() => handleClone(row.original.id)}>
                    {t('clone')}
                </MenuItem>
            </AccessControl>,
            <AccessControl key={`${row.original.id}-3`} id="delete" permissions={permissions}>
                <MenuItem
                    id="delete"
                    style={{ color: 'red' }}
                    onClick={() => handleDelete(row.original)}
                    disabled={row.original?.lifecycle_status_code === 'DELETED'}
                >
                    {row.original?.lifecycle_status_code === 'DELETED' ? t('deleted') : t('delete')}
                </MenuItem>
            </AccessControl>
        ],
        [handleClone, handleDelete, handleEdit, permissions, t]
    );

    const modifiedState = useMemo(
        () => ({
            ...state,
            columnSizing: {
                ...state?.columnSizing,
                'mrt-row-select': 40,
                'mrt-row-drag': 50,
                'mrt-row-actions': 20
            },
            columnOrder: ['mrt-row-drag', 'mrt-row-select', ...(state?.columnOrder || [])],
            showProgressBars: false
        }),
        [state]
    );

    const modifiedInitialState = useMemo(
        () => ({
            ...props.initialState,
            density: 'compact' as MRT_DensityState
        }),
        [props.initialState]
    );

    const newDataProp = useMemo(
        () =>
            columns.length > 0
                ? filterData(
                      data,
                      localFilter,
                      Object.keys(modifiedState?.columnVisibility || {}),
                      i18n.language
                  )
                : [],
        [columns.length, data, i18n.language, localFilter, modifiedState?.columnVisibility]
    );

    const muiExpandButtonProps = useCallback(
        ({ row }: { row: MRTRow }) => {
            if (
                (row.original as { subRows: object[] }).subRows?.length === 0 ||
                (extraExpandHide && extraExpandHide({ row }))
            ) {
                return { sx: { display: 'none' } };
            }
            return {};
        },
        [extraExpandHide]
    );

    const table = useMaterialReactTable<SupabaseRow<ViewOrTableName>>({
        data: newDataProp,
        columns: formatColumns(columns),
        memoMode: 'cells',
        enableFilterMatchHighlighting: false,
        enableRowSelection:
            props.enableRowSelection !== undefined ? props.enableRowSelection : true,
        enableExpanding,
        enableExpandAll,
        meta,
        enableRowActions: isHasActionColumn,
        enableStickyHeader: true,
        enableStickyFooter: true,
        // renderBottomToolbarCustomActions,
        enableDensityToggle: false,
        renderRowActionMenuItems: props.renderRowActions ? undefined : renderRowActionMenuItems,
        initialState: modifiedInitialState,
        icons,
        muiTableBodyCellProps,
        muiExpandButtonProps,
        muiDetailPanelProps: muiTableDetailPanelProps,
        muiTablePaperProps,
        state: modifiedState,
        localization,
        renderEmptyRowsFallback: emptyRowsMarker || renderEmptyRowsFallback,
        muiSelectAllCheckboxProps: muiCheckboxProps,
        ...otherProps,
        // ## pagination
        paginationDisplayMode: 'pages',
        // ### custom table header ###
        layoutMode: 'grid',
        muiTableHeadCellProps,
        muiTableHeadRowProps,
        defaultColumn: {
            minSize: 10 // allow columns to get smaller than default
            // maxSize: 1000, // allow columns to get larger than default
            // size: 260 // make columns wider by default
        },
        muiSelectCheckboxProps: muiCheckboxProps,
        // ## disable row selection toolbar alert ##
        positionToolbarAlertBanner: 'none',
        positionToolbarDropZone: 'none',
        // ### disable column dragging ###
        enableColumnDragging: false,
        // ### disable default table filters ###
        // columnFilterDisplayMode: 'custom',
        enableColumnFilters: false,
        enableColumnFilterModes: false,
        // ### loading status styles ###
        muiCircularProgressProps: {
            Component: <Loader />
        },
        // ### render custom toolbar for correct displaying ###
        renderTopToolbarCustomActions:
            otherProps.renderTopToolbarCustomActions || (() => <div></div>),
        renderBottomToolbar: ({ table }) => (
            <Flex justify="space-between" style={{ padding: '5px' }}>
                {otherProps.renderBottomToolbarCustomActions ? (
                    <otherProps.renderBottomToolbarCustomActions table={table} />
                ) : (
                    <div />
                )}

                {otherProps.renderFetchedInfo
                    ? otherProps.renderFetchedInfo(table.getSelectedRowModel().rows.length)
                    : null}
            </Flex>
        ),
        renderToolbarInternalActions(propsTable) {
            return (
                <Flex gap={5}>
                    {data.length >= 20 ? (
                        <LocalSearchComponent
                            placeholder={`${t('search')}...`}
                            setFilter={setLocalFilter}
                        />
                    ) : null}
                    {props.renderToolbarInternalActions
                        ? props.renderToolbarInternalActions(propsTable)
                        : undefined}
                </Flex>
            );
        }
    });

    if (tableInstanceRef) tableInstanceRef.current = table;

    return (
        <>
            <AccessControl id={viewName} permissions={permissions}>
                <div className={generateClassNames} id={viewName}>
                    <MaterialReactTable table={table as any} />
                </div>
            </AccessControl>
        </>
    );
});
