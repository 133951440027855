import { Input } from 'antd';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

interface EmailFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    htmlId?: string;
}

export const EmailField = memo<EmailFieldProps>(
    ({
        id,
        mode,
        value,
        onChange = () => {},
        withLabel = true,
        required = false,
        skipBlur = false,
        htmlId = id
    }) => {
        const { t } = useTranslation();

        const renderInput = useCallback(
            (
                inputValue: string,
                onInputChange?: (newValue: string) => void,
                onBlur?: () => void
            ) => {
                return (
                    <Input
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        allowClear
                        value={inputValue}
                        onChange={(e) => {
                            if (onInputChange) {
                                onInputChange(e.target.value);
                            }
                        }}
                        onBlur={onBlur}
                    />
                );
            },
            [htmlId, t]
        );

        const renderView = useCallback(
            (viewValue: string) => {
                return (
                    <Input
                        id={`${htmlId}-value`}
                        placeholder={t('no_value') as string}
                        variant="borderless"
                        value={viewValue}
                        readOnly
                    />
                );
            },
            [htmlId, t]
        );

        return (
            <BaseField
                id={id}
                htmlId={htmlId}
                required={required}
                value={value}
                mode={mode}
                withLabel={withLabel}
                onChange={onChange}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
            />
        );
    }
);
