import { singular } from 'pluralize';

// Берет первое представление таблицы или словаря и возвращает
// имя таблицы словаря, либо дерево словарей
export const getTableDictNames = (table_name: string) => {
    let newTableName = singular(table_name);

    // singular не знает слова sku
    if (table_name === 'docs_product_skus') {
        newTableName = newTableName.slice(0, -1);
    }

    let typeTableName;

    // Некоторые настройки имеют типы
    if (table_name.startsWith('settings_warehouse_') && table_name.endsWith('rules')) {
        typeTableName = `${newTableName.replace('settings_', 'dicts_settings_')}_types`;
    } else if (table_name.startsWith('settings_')) {
        return {
            newTableName: null,
            typeTableName: null,
            treeTypeTableName: null
        };
    } else {
        typeTableName = `${newTableName.replace('docs_', 'dicts_')}_types`;
    }

    const treeTypeTableName = `vtree_${typeTableName}`;

    return { newTableName, typeTableName, treeTypeTableName };
};
