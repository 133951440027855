import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseCircleFilled } from '@ant-design/icons';
import { BaseTableRow, SupabaseViewRow, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { useSupabaseViewOrTableDataWithAutoRefresh } from 'modules/supabase/utils/hooks/useSupabaseViewOrTableDataWithAutoRefresh';
import { Filter, OrderBy } from 'modules/supabase/utils/supabaseClient';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';
import { checkIfMultiLanguageFieldByValue } from '../MultilanguageField/MultilanguageField';

interface SelectWithViewHelpProps<
    V extends ViewName,
    SupabaseViewType extends SupabaseViewRow<V> & BaseTableRow
> {
    id: string;
    mode: 'view' | 'edit';
    popoverContainerHtmlId?: string;
    viewName: V;
    value: TableRow | null;
    viewNameFieldOutput?: string; // Определяет какое поле показываем из view при выборе и из контекста при просмотре
    searchListFilter?: Filter[];
    isMultilanguageValue?: boolean;
    onChange?: (newValue: SupabaseViewType | null) => void;
    searchListOrdering?: OrderBy[];
    required?: boolean;
}

export const SelectWithViewHelp = <
    T extends ViewName,
    SupabaseViewType extends SupabaseViewRow<T> & BaseTableRow // Тип данных по ViewName
>({
    id,
    mode,
    popoverContainerHtmlId,
    viewName,
    value,
    viewNameFieldOutput = 'short_title',
    isMultilanguageValue = false,
    searchListFilter,
    onChange,
    searchListOrdering,
    required = false
}: // viewNameFieldOutput = 'short_title'
SelectWithViewHelpProps<T, SupabaseViewType>) => {
    const {
        i18n: { language },
        t
    } = useTranslation();

    // Select Option таблица с id/value. ID чтобы не дублировать - делаем равным Label.
    // Для отдельного определения ID нам нужен был бы пропс по которому понимать какое поле считаем ID,
    // если ID=Label потребности нет, думаю вполне допустимо учитывая что данный компонент используется в основном для словорей
    const [options, setOptions] = useState<DefaultOptionType[]>([]);

    // Запрашиваем данные по ViewName
    const { data, isLoading } = useSupabaseViewOrTableDataWithAutoRefresh({
        viewOrTableName: viewName,
        filters: searchListFilter,
        orderBy: searchListOrdering
    });

    const filteredData = useMemo(
        () =>
            data.filter((dataRow) => {
                const dataRowValue: Record<string, any> = dataRow;
                if (dataRowValue.is_active !== undefined && viewName.includes('vdicts') >= 0) {
                    return dataRowValue.is_active;
                }

                return true;
            }),
        [data]
    );

    // Формируем options по данным
    useEffect(() => {
        if (filteredData) {
            const options = filteredData.map((dataRow, index) => {
                const dataRowValue: Record<string, any> = dataRow;

                const labelVal = dataRowValue[viewNameFieldOutput];

                return {
                    label: checkIfMultiLanguageFieldByValue(labelVal)
                        ? labelVal[language]
                        : labelVal,
                    value: index
                };
            });
            setOptions(options);
        }
    }, [filteredData, viewNameFieldOutput, language]);

    const selectValue = value
        ? isMultilanguageValue && value[viewNameFieldOutput]
            ? value[viewNameFieldOutput][language]
                ? value[viewNameFieldOutput][language]
                : null
            : value[viewNameFieldOutput]
        : null;

    return (
        <Select
            id={`${id}-value`}
            placeholder={t('no_value')}
            disabled={mode === 'view'}
            value={selectValue}
            loading={isLoading}
            allowClear={{
                clearIcon: (
                    <CloseCircleFilled
                        onClick={() => {
                            if (onChange) onChange(null);
                        }}
                    />
                )
            }}
            style={{ width: '100%' }}
            options={options}
            dropdownRender={!options.length ? () => <EmptyMarker /> : undefined}
            onChange={(valueIndex) => {
                if (
                    filteredData &&
                    onChange &&
                    (valueIndex || (valueIndex as unknown as number) === 0)
                ) {
                    const valueOnUpdate = filteredData[valueIndex as unknown as number];
                    onChange(valueOnUpdate as SupabaseViewType);
                }
            }}
            getPopupContainer={
                popoverContainerHtmlId
                    ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                    : undefined
            }
        />
    );
};
