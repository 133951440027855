import { TranslationOutlined } from '@ant-design/icons';
import { Input, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltips } from 'ui';
import { i18n, LANGUAGES } from 'utils/i18n/i18n';
import { LanguageTranslateDialog } from './components/LanguageTranslateDialog';
import { BaseField } from '../BaseField/BaseField';
import { MultilanguageValueType } from '../types/FieldTypes';

type ValueType = {
    [key: string]: any;
};

export const checkIfMultiLanguageFieldByValue = (value: ValueType): boolean => {
    if (!value || typeof value !== 'object') {
        return false;
    }

    const languageKeys = Object.keys(LANGUAGES);
    for (const languageKey of languageKeys) {
        if (value[languageKey] === 'undefined') {
            return false;
        }
    }

    return true;
};

export type { MultilanguageValueType };

interface MultilanguageFieldProps {
    id: string;
    value: MultilanguageValueType;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    tooltip?: boolean;
    htmlId?: string;
    onChange?: (newValue: MultilanguageValueType) => void;
    skipBlur?: boolean;
}

const replaceCommaToSpaceInMultilang = (value: NonNullable<MultilanguageValueType>) => {
    const finalValue = { ...value };
    (Object.keys(finalValue) as LANGUAGES[]).forEach((key) => {
        finalValue[key] = finalValue[key]?.replace(/,/g, ' ').replace(/\s+/g, ' ');
    });

    return finalValue;
};

const initialValue = _.zipObject(i18n.languages, Array(i18n.languages.length).fill(''));

export const MultilanguageField = memo<MultilanguageFieldProps>(
    ({
        id,
        mode,
        onChange = () => {},
        value,
        withLabel = true,
        required = false,
        tooltip = false,
        htmlId = id
    }) => {
        const {
            i18n: { language: currentLanguage },
            t
        } = useTranslation();

        const [visibleValue, setVisibleValue] = useState(value);
        const [languageTranslateDialogOpen, setLanguageTranslateDialogOpen] = useState(false);

        const onBlurMultiLanguageFieldValue = useCallback(() => {
            if (visibleValue) {
                const val = replaceCommaToSpaceInMultilang(visibleValue);
                onChange(val);
                setVisibleValue(val);
            } else onChange(visibleValue);
        }, [visibleValue, onChange]);

        const handleVisibleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const copyValue = { ...value };

                copyValue[currentLanguage as LANGUAGES] = e.target.value;

                setVisibleValue(copyValue);
            },
            [value, currentLanguage]
        );

        const renderInput = useCallback(
            () => (
                <Input
                    id={`${htmlId}-value`}
                    placeholder={t('no_value') as string}
                    allowClear
                    suffix={
                        <ButtonWithTooltips
                            id="language-translate-button"
                            className="language-translate-button"
                            type="text"
                            onClick={() => {
                                setLanguageTranslateDialogOpen(true);
                            }}
                            icon={<TranslationOutlined />}
                            style={{ padding: 0, width: '100%', height: '100%' }}
                        />
                    }
                    value={
                        (visibleValue && visibleValue[currentLanguage as LANGUAGES]) || undefined
                    }
                    onChange={handleVisibleChange}
                    onBlur={onBlurMultiLanguageFieldValue}
                />
            ),
            [
                htmlId,
                t,
                visibleValue,
                currentLanguage,
                handleVisibleChange,
                onBlurMultiLanguageFieldValue
            ]
        );

        const renderView = useCallback(() => {
            const hasValue = Boolean(value && value[currentLanguage as LANGUAGES]);

            const viewValue = `${
                hasValue && value !== null ? value[currentLanguage as LANGUAGES] : ''
            }`;

            return (
                <Typography.Text
                    id={`${htmlId}-value`}
                    style={{
                        paddingLeft: '10px',
                        color: viewValue ? undefined : '#aeaeae'
                    }}
                    type={viewValue ? undefined : 'secondary'}
                >
                    {viewValue || t('no_value')}
                </Typography.Text>
            );
        }, [value, currentLanguage, htmlId, t]);

        return (
            <>
                <LanguageTranslateDialog
                    open={languageTranslateDialogOpen}
                    setOpen={setLanguageTranslateDialogOpen}
                    value={value || initialValue}
                    setValue={setVisibleValue}
                />
                <BaseField
                    id={id}
                    htmlId={htmlId}
                    required={required}
                    mode={mode}
                    onChange={onChange}
                    withLabel={withLabel}
                    renderInput={renderInput}
                    renderView={renderView}
                    value={mode === 'edit' ? visibleValue : value}
                />
            </>
        );
    }
);
