import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Database } from 'modules/supabase/types/database.types';

// const rootViewName: ViewName = 'vmisc_async_tasks';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type ErrorOutputProps = {
    task: ViewType;
};

export const SuccessOutput = ({ task }: ErrorOutputProps) => {
    const { t } = useTranslation();

    return (
        <div style={{ margin: '2rem' }}>
            <Alert message={t('task_completed')} type="success" closable={false} />
        </div>
    );
};
