import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import '../../supabase/customWindow'; // No need to import anything, just include the file

export async function callBackendFunction<T>(
    endpoint: string,
    params: any
): Promise<{ data: T | null; error: Error | null; additionalData?: any }> {
    const REACT_APP_BACKEND_FUNCTIONS_API_URL = window.env.BACKEND_FUNCTIONS_API_URL; // Set this in your .env file.
    const SUPABASE_ANON_KEY = window.env.SUPABASE_ANON_KEY;
    const fullEndpoint = `${REACT_APP_BACKEND_FUNCTIONS_API_URL}/${endpoint}`;

    const requestOptions: AxiosRequestConfig = {
        method: 'POST',
        url: fullEndpoint,
        headers: {
            'Content-Type': 'application/json'
        },
        data: params // No need to stringify here, axios does it automatically
    };

    if (!requestOptions.headers) {
        requestOptions.headers = {};
    }

    if (supabaseClient.auth) {
        const { data, error } = await supabaseClient.auth.getSession();
        if (error) {
            return { data: null, error };
        }
        if (!data) {
            return { data: null, error: new Error(makeErrorReadable('no_session_data')) };
        }
        const session = data.session;
        if (!session) {
            return { data: null, error: new Error(makeErrorReadable('no_session')) };
        }

        // Check if headers is defined, if not, initialize it
        requestOptions.headers.Apikey = `${SUPABASE_ANON_KEY}`;
        requestOptions.headers.Authorization = `Bearer ${session.access_token}`;
    } else {
        const supabaseKey = process.env.SUPABASE_SERVICE_ROLE_KEY;
        requestOptions.headers.Apikey = `${supabaseKey}`;
        requestOptions.headers.Authorization = `Bearer ${supabaseKey}`;
    }

    console.log(`Request backend function '${fullEndpoint}' with body ${JSON.stringify(params)}`);

    try {
        const response = await axios(requestOptions); // Use requestOptions as axios config

        const result = response.data;

        console.log('Response:', JSON.stringify(result));

        return { data: result, error: null };
    } catch (error) {
        console.error(error);

        const axiosError = error as AxiosError;

        let errorMessage = axiosError.message;

        // If response data exists and not in production, use it as the error message
        if (axiosError.response && axiosError.response.data) {
            if (typeof axiosError.response.data === 'object') {
                return {
                    data: null,
                    error: { name: 'reselect', message: 'reselect' },
                    additionalData: axiosError.response.data
                };
            }
            errorMessage = axiosError.response.data as string;
        }

        return {
            data: null,
            error: new Error(
                `${makeErrorReadable('api_request_failed')}: ${makeErrorReadable(errorMessage)}`
            )
        };
    }
}
