import { Input } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseField } from '../BaseField/BaseField';

interface TextAreaFieldProps {
    id: string;
    value: string | null;
    mode: 'view' | 'edit';
    withLabel?: boolean;
    required?: boolean;
    onChange?: (newValue: string) => void;
    skipBlur?: boolean;
    withTooltip?: boolean;
}

export const TextAreaField = memo<TextAreaFieldProps>(
    ({
        id,
        value,
        mode,
        onChange = () => {},
        withLabel = true,
        required = false,
        skipBlur = false,
        withTooltip = true
    }) => {
        const { t } = useTranslation();

        const renderInput = (
            inputValue: string,
            onTextAreaChange?: (newValue: string) => void,
            onBlur?: () => void
        ) => {
            return (
                <Input.TextArea
                    id={`${id}-value`}
                    placeholder={t('no_value') as string}
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    value={inputValue || ''}
                    onChange={(e) => {
                        if (onTextAreaChange) {
                            onTextAreaChange(e.target.value);
                        }
                    }}
                    onBlur={onBlur}
                    allowClear
                />
            );
        };

        const renderView = (viewValue: string) => {
            return (
                <Input.TextArea
                    id={`${id}-value`}
                    placeholder={t('no_value') as string}
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    variant="borderless"
                    value={viewValue}
                    readOnly
                />
            );
        };

        return (
            <BaseField
                id={id}
                value={value}
                mode={mode}
                onChange={onChange}
                withLabel={withLabel}
                required={required}
                renderInput={renderInput}
                renderView={renderView}
                skipBlur={skipBlur}
                withTooltip={withTooltip}
            />
        );
    }
);
