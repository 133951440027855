import { Collapse, CollapseProps, ConfigProvider, Dropdown, Flex, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import * as JsSearch from 'js-search';
import { useTranslation } from 'react-i18next';

import { useMemo, useRef, useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { useList } from 'react-use';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { StoreLink } from 'ui';
import { LocalSearchComponent } from 'smart/modules/SmartTablePage/components/LocalSearchComponent/LocalSearchComponent';

interface IModalLogMessages {
    logMessages: LogMessage[];
}
const sorter = (a: LogMessage, b: LogMessage, column: keyof LogMessage) => {
    if (typeof a[column] === 'string' && typeof b[column] === 'string')
        return a[column].localeCompare(b[column]);
    if (Array.isArray(a[column] && Array.isArray(b[column]))) {
        if (typeof a[column].at(0) === 'string' && typeof b[column].at(0) === 'string') {
            return (a[column] as string[])
                .join(',')
                .localeCompare((b[column] as string[]).join(','));
        }

        return a[column].length - b[column].length;
    }

    return 0;
};

const transformArray = (inputArray: any[]) => {
    // Создаем объект для хранения промежуточных данных
    const metaMap = {} as {
        [key: string]: {
            Meta: string;
            children: { Link: React.ReactNode; Type: React.ReactNode }[];
        };
    };

    // Проходим по каждому элементу входного массива
    inputArray.forEach((item) => {
        const { Meta, Link, Type } = item;

        // Если ключ Meta уже существует в metaMap, добавляем в его children новый объект
        if (metaMap[Meta]) {
            metaMap[Meta].children.push({ Link, Type });
        } else {
            // Иначе создаем новый ключ и добавляем первый элемент в его children
            metaMap[Meta] = {
                Meta,
                children: [{ Link, Type }]
            };
        }
    });

    // Преобразуем объект metaMap обратно в массив
    return Object.values(metaMap);
};

export const LogMessagesTable: React.FC<IModalLogMessages> = ({ logMessages = [] }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    if (logMessages.length === 0) return null;

    const [activeKeys, { set: setActiveKeys }] = useList<string>(['logs']);
    const [localSearchFilter, setLocalSearchFilter] = useState('');

    // const isBothOpen = activeKeys.length === 2;

    const tblRef: Parameters<typeof Table>[0]['ref'] = useRef(null);

    const logs = useMemo(
        () => logMessages.filter((item) => !item.Categories?.includes('OBJECT_CREATED')),
        [logMessages]
    );

    const results = useMemo(
        () =>
            transformArray(
                logMessages
                    .filter((item) => item.Categories?.includes('OBJECT_CREATED'))
                    .map((item) => ({
                        Meta:
                            item.Fields?.Meta?.SingularName?.[language] || item.Fields?.Meta?.Code,
                        Link: (
                            <StoreLink
                                style={{ padding: 0 }}
                                to={`/other/${item.Fields?.Meta?.Code}/${item.Fields?.Object?.Id}`}
                                state={{
                                    data: item.Fields?.Object
                                }}
                            >
                                {item.Fields?.Object?.Name?.[language] ||
                                    item.Fields?.Object?.Key ||
                                    item.Fields?.Object?.Code}
                            </StoreLink>
                        ),
                        Type: item.Fields?.Object?.Type_Code
                        // Type: (
                        //     <StoreLink style={{ padding: 0 }} to={item.Fields?.Object?.Type?.Id}>
                        //         {item.Fields?.Object?.Type?.Name ||
                        //             item.Fields?.Object?.Type?.Key ||
                        //             item.Fields?.Object?.Type?.Code}
                        //     </StoreLink>
                        // )
                    }))
            ) as any[],
        [language, logMessages]
    );

    // console.log(results, logs);
    // console.log(logMessages);

    const resultColumns = useMemo(
        () => [
            {
                title: 'Meta',
                dataIndex: 'Meta',
                key: 'Meta',
                width: '33.3%'
            },
            {
                title: 'Link',
                dataIndex: 'Link',
                key: 'Link',
                width: '33.3%'
            },
            {
                title: 'Type',
                dataIndex: 'Type',
                key: 'Type',
                width: '33.3%'
            }
        ],
        []
    );

    const logColumns = useMemo<ColumnsType>(
        () => [
            {
                title: 'Categories',
                dataIndex: 'Categories',
                key: 'Categories',
                width: '15%',
                sorter: (a, b) => sorter(a, b, 'Categories')
            },
            {
                title: 'Message',
                dataIndex: 'Message',
                key: 'Message',
                width: '58%',
                render: (value) => {
                    if (value.length > 300) {
                        return (
                            <Dropdown
                                destroyPopupOnHide
                                menu={{
                                    items: [
                                        {
                                            label: t('copy'),
                                            icon: <CopyOutlined />,
                                            key: 'copy',
                                            onClick: () => {
                                                navigator.clipboard.writeText(value);
                                            }
                                        }
                                    ]
                                }}
                                trigger={['contextMenu']}
                            >
                                <div>{`${value.slice(0, 300)}...`}</div>
                            </Dropdown>
                        );
                    }

                    return value;
                }
            },
            {
                title: 'Level',
                dataIndex: 'Level',
                key: 'Level',
                width: '12%',
                sorter: (a, b) => sorter(a, b, 'Level')
            },
            {
                title: 'Timestamp',
                dataIndex: 'Timestamp',
                key: 'Timestamp',
                width: '15%',
                sorter: (a, b) => sorter(a, b, 'Timestamp'),
                render: (value) => dayjs(value).format('HH:mm:ss')
            }
        ],
        [t]
    );

    const searchedLogs = useMemo(() => {
        if (localSearchFilter) {
            const index = new JsSearch.Search('Id');
            index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

            logColumns.forEach((item) => {
                index.addIndex(item.key as string);
            });

            index.addDocuments(
                logs.map((d) => ({ ...d, Parent: undefined, children: undefined })) || []
            );

            return index.search(localSearchFilter);
        }

        return logs || [];
    }, [localSearchFilter, logColumns, logs]);

    const collapseItems = useMemo<NonNullable<CollapseProps['items']>>(() => {
        const items = [
            {
                key: 'logs',
                label: <strong>{t('logs')}</strong>,
                children: (
                    <Table
                        scroll={{
                            // y: window.innerHeight / (isBothOpen ? 4.1 : 2.05),
                            y: window.innerHeight / 2.05,
                            x: 750
                        }}
                        pagination={false}
                        rowKey="Id"
                        ref={tblRef}
                        bordered
                        columns={logColumns}
                        dataSource={searchedLogs}
                        virtual
                        showSorterTooltip={false}
                        title={() => (
                            <Flex justify="end" style={{ width: '100%' }}>
                                <LocalSearchComponent
                                    searchFilter={localSearchFilter}
                                    setFilter={setLocalSearchFilter}
                                    placeholder={`${t('search')}...`}
                                />
                            </Flex>
                        )}
                        summary={() => (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={0}>
                                        <Typography.Text style={{ padding: 0 }} strong>
                                            {t('total')}:
                                        </Typography.Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                        {(
                                            Number(logMessages.at(-1)?.Fields?.elapsed) / 1000000
                                        ).toFixed(2)}
                                        ms
                                        {/* {dayjs(
                                    dayjs(logMessages?.at(0)?.Timestamp).diff(
                                        dayjs(logMessages?.at(-1)?.Timestamp),
                                        'seconds',
                                        true
                                    )
                                )
                                    .utc()
                                    .format('HH:mm:ss')} */}
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )}
                        // tableLayout="auto"
                    />
                )
            }
        ];

        if (results?.length)
            items.unshift({
                key: 'results',
                label: <strong>{t('results')}</strong>,
                children: (
                    <Table
                        showHeader={false}
                        scroll={{
                            y: window.innerHeight / 2.05,
                            // y: window.innerHeight / (isBothOpen ? 4.1 : 2.05),
                            x: 750
                        }}
                        pagination={false}
                        rowKey="Id"
                        ref={tblRef}
                        bordered
                        columns={resultColumns}
                        dataSource={results}
                        virtual
                        showSorterTooltip={false}
                    />
                )
            });

        return items;
    }, [localSearchFilter, logColumns, logMessages, resultColumns, results, searchedLogs, t]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        cellPaddingBlock: 8
                    }
                }
            }}
        >
            {collapseItems.length > 1 ? (
                <Collapse
                    size="small"
                    activeKey={activeKeys}
                    onChange={(keys) => setActiveKeys(typeof keys === 'string' ? [keys] : keys)}
                    style={{ maxHeight: '85vh', overflow: 'auto' }}
                    items={collapseItems}
                />
            ) : (
                collapseItems?.at(0)?.children || null
            )}
        </ConfigProvider>
    );
};
