import { CheckOutlined, CloseOutlined, InfoOutlined, WarningOutlined } from '@ant-design/icons';
import { Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';

import { Database } from 'modules/supabase/types/database.types';

type ViewType = Database['public']['Views']['vmisc_async_tasks']['Row'] | null;

type ProcessLogProps = {
    task: ViewType;
};

type Log = {
    message: string;
    type: string;
}[];

export const ProcessLog = ({ task }: ProcessLogProps) => {
    const { t } = useTranslation();

    console.log('task ProcessLog', task);

    let processLog: Log;
    try {
        processLog = (task?.output_data as Record<string, any>)?.process_log
            ? ((task?.output_data as Record<string, any>)?.process_log as Log)
            : [];
    } catch (error) {
        processLog = [];
    }

    return (
        <>
            <Divider orientation="left">{t('log')}</Divider>

            <List
                bordered
                pagination={{ position: 'bottom', align: 'center', pageSize: 5 }}
                dataSource={processLog || []}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <>
                                    {item.type === 'success' && (
                                        <CheckOutlined
                                            style={{
                                                fontSize: 20,
                                                color: 'green'
                                            }}
                                        />
                                    )}

                                    {item.type === 'error' && (
                                        <CloseOutlined
                                            style={{
                                                fontSize: 20,
                                                color: 'red'
                                            }}
                                        />
                                    )}

                                    {item.type === 'warning' && (
                                        <WarningOutlined
                                            style={{
                                                fontSize: 20,
                                                color: 'orange'
                                            }}
                                        />
                                    )}

                                    {item.type === 'info' && (
                                        <InfoOutlined
                                            style={{
                                                fontSize: 20,
                                                color: 'blue'
                                            }}
                                        />
                                    )}
                                </>
                            }
                            title={item.message}
                            description={t(item.type)}
                        />
                    </List.Item>
                )}
            />
        </>
    );
};
