import { lazy } from 'react';
import { RoutesType } from 'routing/routesTypes';
import { transactionalDataRoutes } from 'routing/transactiondata/transactionalDataRoutes';
import { profileRoutes } from './profile/profileRoutes';
import { TmsReportsRoutes } from './tmsreports/TmsReportsRoutes';
import { WmsRoutes } from './wms/WmsRoutes';
import { WmsDocsRoutes } from './wmsdocs/WmsDocsRoutes';
import { WmsReportsRoutes } from './wmsreports/WmsReportsRoutes';
import { WmsEmployeeRoutes } from './worker-terminal';

const SupersetDashboardMain = lazy(() =>
    import('pages/dashboards/SupersetDashboardMain/SupersetDashboardMain').then((module) => ({
        default: module.SupersetDashboardMain
    }))
);
const NoAccessPage = lazy(() =>
    import('pages/NoAccessPage').then((module) => ({
        default: module.NoAccessPage
    }))
);
const NotFoundPage = lazy(() =>
    import('pages/NotFoundPage').then((module) => ({
        default: module.NotFoundPage
    }))
);
const ServerErrorPage = lazy(() =>
    import('pages/ServerErrorPage').then((module) => ({
        default: module.ServerErrorPage
    }))
);

export const baseRoutes: RoutesType[] = [
    {
        path: '/',
        component: SupersetDashboardMain // HomePage,
    },
    { path: '/no-access', component: NoAccessPage },
    { path: '/500', component: ServerErrorPage },
    { path: '/404', component: NotFoundPage }
];
export const transactional_data: RoutesType[] = [...transactionalDataRoutes.getRoutes()];
export const wms: RoutesType[] = [...WmsRoutes.getRoutes()];
export const wmsDocs: RoutesType[] = [...WmsDocsRoutes.getRoutes()];
export const wmsReports: RoutesType[] = [...WmsReportsRoutes.getRoutes()];
export const wmsEmployee: RoutesType[] = [...WmsEmployeeRoutes.getRoutes()];
export const tmsReports: RoutesType[] = [...TmsReportsRoutes.getRoutes()];
export const profile: RoutesType[] = [...profileRoutes];

export const other: RoutesType[] = [{ path: '*', component: NotFoundPage }];
