import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { MRT_FilterOption } from 'material-react-table';
import { SupabaseColumns } from 'modules/supabase/utils/tableCellUtils/generateColumnsFromType';

export type FilterOption = MRT_FilterOption | 'in_list' | 'not_in_list' | 'dynamic_range';

export const AVAILABLE_FILTER_OPERATORS: MRT_FilterOption[] = ['contains', 'equals', 'notEquals'];
export const DEFAULT_FILTER_OPERTAION: MRT_FilterOption = 'equals';

export type FilterFieldValue = {
    operator?: FilterOption;
    value?: any;
};

export type InitialFilterFieldValue = {
    field?: string;
    values: FilterFieldValue[];
};

export type ValuesWithErrorType = {
    field?: string;
    operator?: string;
    error?: string;
};

export type FilterField = {
    field: string;
    fieldSetting: SupabaseColumns;
    UIColumn: MRTColumnDef;
    values: FilterFieldValue[];
};

export type LocalFilterValueType = FilterFieldValue & {
    index: number;
    lastValue: boolean;
};
