import { useTranslation } from 'react-i18next';

import { DetailPage_DataTable } from 'components/DataTable/DetailPage_DataTable';
import { MRTColumnDef } from 'components/DataTable/MRT_Types';

import { useTableContext } from 'pages/base/DetailPage/BaseDetailPageProvider';
import { ViewName } from 'modules/supabase/types/Dataset';
import { forwardRef } from 'react';
import { TableRefType } from './useTableBackendFeatures';

interface OperationsDetail_DataTableProps {
    childViewName: ViewName;
}

export const OperationsDetail_DataTable = forwardRef<TableRefType, OperationsDetail_DataTableProps>(
    ({ childViewName }, ref) => {
        const [data] = useTableContext(childViewName);
        const { t } = useTranslation();

        const columns: (MRTColumnDef & Record<string, any>)[] = [
            {
                accessorKey: 'warehouse_storage_location_operation_code',
                header: t('operation') as string,
                view_help_filter: [
                    // ...[1], ..не понятно ...[1] что это и из-за него не подтягивает значения
                    {
                        column: 'code',
                        operator: 'not.in',
                        value: `(${data.rows
                            .map((value) => value.warehouse_storage_location_operation_code)
                            .join(',')})`
                    }
                ]
                // TODO: на будущее, если надо будет оставить выбранное значение в списке опций
                // то надо будет вывести кастомно SelectField и кастомно выводить в список
                // в зависимости от row.key и выбранного кода
                // Cell: ({ row }) => {
                //     console.log(row, 'row');
                // }
            }
        ];

        return (
            <DetailPage_DataTable
                childViewName={childViewName}
                predefinedColumns={columns}
                enableRowOrdering={false}
                ref={ref}
            />
        );
    }
);
