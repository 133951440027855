import { Modal } from 'antd';
import * as JsSearch from 'js-search';
import { toJS } from 'mobx';
import { memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useList } from 'react-use';

import { MetaField } from 'modules/services/backend-api/generated_info';
import { SmartTablePageHeaderToolbar } from 'smart/modules/SmartTablePage/components';
import { metaStore } from 'utils/store/MetaStore';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';

import { SmartTable } from '../../../SmartTable/SmartTable';

interface SmartSearchListProps {
    meta: string;
    open: boolean;
    onCancel: () => void;
    onOk: (selectedRows: any[]) => void;
    fields?: MetaField[];
    data?: any[];
    title?: string;
    selectionType?: 'checkbox' | 'radio';
    initialSelectedRows?: any[];
}

export const SmartSearchList = memo<SmartSearchListProps>(
    ({
        meta,
        title,
        open,
        onCancel,
        onOk,
        data,
        fields,
        initialSelectedRows,
        selectionType = 'radio'
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        const [selectedRows, { set: setSelectedRows }] = useList<any>([]);

        useEffect(() => {
            if (initialSelectedRows) setSelectedRows(initialSelectedRows);
        }, [initialSelectedRows, setSelectedRows]);

        const [localSearchFilter, setLocalSearchFilter] = useState('');

        const titleRender = useCallback(
            () => (
                <SmartTablePageHeaderToolbar
                    meta={meta}
                    searchFilter={localSearchFilter}
                    setSearchFilter={setLocalSearchFilter}
                    filters={[]}
                    showComponents={{
                        isCreate: false,
                        isDelete: false,
                        isDescription: false,
                        isFilter: false,
                        isReload: false,
                        isDisplayViewPreferences: false,
                        isActions: false,
                        isFollows: false,
                        isReports: false,
                        isActionButtons: false
                    }}
                    selectedRowKeys={selectedRows.map((r) => r?.Id)}
                    hasSelected={selectedRows.length > 0}
                    setFilters={(value: SetStateAction<FilterField[]>) => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onRefresh={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onCreate={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onCopy={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onDelete={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    logs={[]}
                    setLogs={(value: SetStateAction<LogMessage[]>) => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    selectedRows={selectedRows}
                />
            ),
            [localSearchFilter, meta, selectedRows]
        );

        // ### НАЙДЕННЫЕ по ПОИСКУ данные из dataSource
        const searchedDataSource = useMemo(() => {
            if (localSearchFilter) {
                const index = new JsSearch.Search('Id');
                index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

                fields?.forEach((item) => {
                    index.addIndex(item.FieldName);
                    index.addIndex([item.FieldName, 'ShortTitle', language]);
                    index.addIndex([item.FieldName, 'LongTitle', language]);
                    index.addIndex([item.FieldName, 'Name', language]);
                    index.addIndex([item.FieldName, 'Title', language]);
                    index.addIndex([item.FieldName, 'PluralName', language]);
                    index.addIndex([item.FieldName, 'SingularName', language]);
                    index.addIndex([item.FieldName, language]);
                });

                index.addDocuments(
                    (data || []).map((d) => ({ ...d, Parent: undefined, children: undefined }))
                );

                return index.search(localSearchFilter);
            }

            return data || [];
        }, [data, fields, language, localSearchFilter]);

        const handleOk = () => {
            onOk(selectedRows);
            setSelectedRows([]);
            onCancel();
        };

        const handleCancel = () => {
            onCancel();
            setSelectedRows([]);
        };

        const viewFieldName = toJS(metaStore.meta.get(meta)?.info?.Type?.ViewFieldName);

        return (
            <Modal
                centered
                destroyOnClose
                width="95%"
                title={t(title || 'search')}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <SmartTable
                    meta={meta}
                    data={searchedDataSource}
                    titleRender={titleRender}
                    fields={fields}
                    selectionType={selectionType}
                    selectedRows={selectedRows}
                    onRowSelectionChange={setSelectedRows}
                    layout="fixed"
                    fixedFields={{
                        left: viewFieldName ? [viewFieldName] : ['Key', 'Code', 'Name']
                    }}
                />
            </Modal>
        );
    }
);
