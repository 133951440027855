import {
    ArrowDownOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { SupabaseClient } from '@supabase/supabase-js';
import { Flex, Form } from 'antd';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { saveDataset } from 'modules/services/backend-functions/save-dataset';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import {
    BaseTableRow,
    Dataset,
    DatasetTable,
    DocsRootTableRow,
    IdMapper,
    ModeExtended,
    TableName,
    TableRow,
    ViewName,
    ViewOrTableName
} from 'modules/supabase/types/Dataset';
import {
    Filter,
    TableDef,
    TableMetaInfo,
    getSupabaseViewOrTableData,
    isTableMetaInfo
} from 'modules/supabase/utils/supabaseClient';

import { DialogModal } from 'components/Modals/DialogModal';
import { TabItemProps, TabsBox } from 'components/Tabs/TabsBox';
import { ButtonWithTooltips } from 'ui';

import { createInvoiceFromEtranAct } from 'modules/services/backend-functions/create-invoice';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Database } from 'modules/supabase/types/database.types';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { toPascalCase } from 'utils/helpers/toPascalCase';
import { useMessages, useNotifications, useResize } from 'utils/hooks';
import { routeStore, useStoreNavigate } from 'utils/store';
import { detailPageStore } from 'utils/store/DetailPageStore/DetailPageStore';
import { metaStore } from 'utils/store/MetaStore';
import { ActionsPanel } from './ActionsPanel';
import { StatusPanel } from './StatusPanel';
import { DatasetContext, useDataset } from './hooks/useDataset';

export interface ContextFilter {
    key: string;
    value: number | string | boolean;
    currView?: ViewOrTableName;
}

interface BaseDetailPageContextProps {
    tableDefs: TableDef[];
    tabs: any;
    onTabsChange: (newValue: string) => void;
    children?: React.ReactNode;
    filter?: ContextFilter[] | null;
    setFilter?: Dispatch<SetStateAction<ContextFilter[] | null>>;
    typeInfo?: Record<string, any>;

    callbackDatasetChange?: (dataset: Dataset) => void;
    addExtraButtons?: (dataset: Dataset, handleRefresh: () => void) => React.ReactNode[];

    disablePageTitle?: boolean;
    disableActionsPanel?: boolean;
    disableStatus?: boolean;

    statusCodes?: string[];
}

type TypeExtraFields = {
    type_short_title: object;
    type_long_title: object;
    lifecycle_status_group_id: number;
};

type RowData<V extends object> = {
    isLoading: boolean;
    error: any;
    fields: V & BaseTableRow;
};

const useQuery = () => new URLSearchParams(useLocation().search);

export const useTableContext = (
    viewName: ViewOrTableName,
    filterColumn?: string,
    workCondition = true
) => {
    if (!workCondition) {
        return [];
    }

    const [dataset, setDataset, filter, setFilter] = useDataset();
    const tableIndex = dataset.tables.findIndex((table) => table.viewName === viewName);
    const query = useQuery();

    // Проверяем, существует ли таблица с указанным представлением в наборе данных
    if (tableIndex < 0) {
        throw new Error(`Table for view ${String(viewName)} in dataset was not found`);
    }

    const context = React.useContext(DatasetContext);

    // Проверяем, что useTableContext используется внутри TableContextProvider
    if (!context) {
        throw new Error('useTableContext must be used within a TableContextProvider');
    }

    const [filteredRows, setFilteredRows] = useState<DatasetTable>(dataset.tables[tableIndex]); // Отфильтрованная таблица
    const originalData = dataset.tables[tableIndex]; // Данные без фильтров

    // Функция для установки отфильтрованной таблицы
    const setFilteredTable = useCallback(
        (newTable: DatasetTable) => {
            const updatedTables = [...dataset.tables];

            // Создаем копию текущей таблицы
            const copyTable = { ...updatedTables[tableIndex] };

            // Удаляем строки из копии таблицы, которые есть в новой таблице
            for (let i = 0; i < newTable.rows.length; i += 1) {
                const curRow = newTable.rows[i];

                // Удаляем строку из копии таблицы, если она имеет такой же идентификатор
                copyTable.rows = copyTable.rows.filter((row) => row.id !== curRow.id);

                // Если строка является новой (состояние 'Inserted'), назначаем ей временный идентификатор и корневой идентификатор
                if (curRow._state === 'Inserted') {
                    if (curRow.id === 0 || !curRow.id) {
                        curRow.id = dataset.getNextTempId();
                    }

                    if (typeof curRow.child_index === 'number') {
                        curRow.root_id = dataset.root_id; // @ZaburdaevAA убрал так как мешало созданию. Вернул обратно в компонент таблицы (DetailPage_DataTable.tsx).
                    }
                    // Была идея проверять на child_index, и добавлять root_id только где есть child_index. Но это тут тоже не сработает,

                    // Применяем фильтр к новой строке, если фильтр определен
                    if (filter) {
                        for (let j = 0; j < filter.length; j += 1) {
                            if (filter[j].currView === viewName) {
                                const { key, value } = filter[j];
                                // если такое поле уже существует то пропускаем запись
                                if (curRow[key]) {
                                    continue;
                                }
                                curRow[key] = value;
                            }
                        }
                    }
                }
            }

            // в Датасет не попадает элемент только что созданный и сразу удаленный. Иначе это приведет к ошибке на сервере. Это серьезный баг // FIXED by Konstantin K.
            const filteredCopyTableRows = copyTable.rows.filter((row) => {
                return !(row._state === 'Deleted' && row.id <= 0);
            });

            // Добавляем новые строки в копию таблицы
            copyTable.rows = [...filteredCopyTableRows, ...newTable.rows];

            updatedTables[tableIndex] = copyTable;

            // Создаем обновленный набор данных с обновленными таблицами
            const updatedDataset = new Dataset(
                dataset.root_id,
                dataset.mode,
                updatedTables,
                dataset.tempCounter
            );

            console.log('---UPDATED---', updatedDataset);
            // Обновляем набор данных и отфильтрованную таблицу
            setDataset(updatedDataset);
            setFilteredRows(copyTable);
        },
        [dataset, tableIndex, setDataset, filter]
    );

    useEffect(() => {
        const extendedMode = (query.get('mode') as ModeExtended) ?? 'view';

        if (extendedMode === 'create') {
            detailPageStore.setDetailPageCreateInfo(
                dataset.tables[0].tableName,
                dataset.tables[0].rows[0]
            );
        }
    }, [dataset]);

    // Функция применения фильтра к таблице
    const applyFilter = useCallback(
        (newFilter: ContextFilter[] | null) => {
            setFilter((prevFilter) => {
                const result: ContextFilter[] = [];
                if (prevFilter) {
                    for (let i = 0; i < prevFilter.length; i += 1) {
                        if (prevFilter[i].currView !== viewName) {
                            result.push(prevFilter[i]);
                        }
                    }
                }
                if (newFilter) {
                    for (let j = 0; j < newFilter.length; j += 1) {
                        const jNewFilter = { ...newFilter[j] };
                        jNewFilter.currView = viewName;
                        result.push(jNewFilter);
                    }
                }
                return result;
            });
        },
        [setFilter, viewName]
    );

    useEffect(() => {
        // Применяем фильтр при изменении фильтра или текущего представления
        const table = dataset.tables[tableIndex];
        const filteredRows = [];

        for (let i = 0; i < table.rows.length; i += 1) {
            const row = table.rows[i];
            let satisfiesAllConditions = true;

            if (filter) {
                const prevKeys: string[] = [];
                for (let i = 0; i < filter.length; i += 1) {
                    if (filter[i].currView === viewName) {
                        const curViewFilter: ContextFilter = filter[i];
                        const { key, value } = curViewFilter;

                        if (row[key] !== value) {
                            if (!prevKeys.includes(key)) {
                                prevKeys.push(key);

                                if (filter.every((item) => item.value !== row[key])) {
                                    satisfiesAllConditions = false;
                                    break;
                                }

                                if (
                                    filter.some((item) => item.key === key && item.value !== value)
                                ) {
                                    continue;
                                }

                                satisfiesAllConditions = false;
                                break;
                            }
                        }

                        if (prevKeys.includes(key)) {
                            continue;
                        }

                        if (!prevKeys.includes(key)) {
                            prevKeys.push(key);
                        }
                    }
                }
            }

            if (satisfiesAllConditions) {
                filteredRows.push(row);
            }
        }

        // Создаем новую отфильтрованную таблицу
        const filteredTable = {
            ...table,
            rows: filteredRows
        } as DatasetTable;

        // Обновляем отфильтрованную таблицу и фильтр
        setFilteredRows(filteredTable);
    }, [dataset.tables, filter, tableIndex, viewName]);

    const filteredTable = filteredRows;

    return [filteredTable, setFilteredTable, applyFilter, originalData] as const;
};

export const useTableRowContext = <V extends object>(
    viewName: ViewName,
    rowIndex: number,
    filterColumn?: string,
    workCondition = true
) => {
    if (!workCondition) {
        return [];
    }
    const query = useQuery();
    const [table, setTable] = useTableContext(viewName, filterColumn);
    const extendedMode = (query.get('mode') as ModeExtended) ?? 'view';

    const tableRow: RowData<V> = useMemo(() => {
        return {
            isLoading: table?.isLoading,
            error: table?.error,
            fields: (table?.rows[rowIndex] || {}) as V & BaseTableRow
        };
    }, [table, rowIndex]);

    const setTableRow = useCallback(
        (newFields: V) => {
            const newRow: V & BaseTableRow & TypeExtraFields = {
                ...newFields
            } as V & BaseTableRow & TypeExtraFields;
            if (
                extendedMode !== 'clone' &&
                extendedMode !== 'create' &&
                newRow._state !== 'Inserted' // Нужно было мне чтобы у новых строк _state updated не ставился и я не получал ошибку при сохранение
            ) {
                newRow._state = 'Updated';
            }
            const newTable = { ...table };
            newTable.rows[rowIndex] = newRow;
            setTable(newTable);
        },
        [extendedMode, table, rowIndex, setTable]
    );

    const handleSingleFieldChange = useCallback(
        (newFieldValue: any, fieldName: string) => {
            const changedValues = { [fieldName]: newFieldValue };
            setTableRow({
                ...tableRow.fields,
                ...changedValues
            });
        },
        [setTableRow, tableRow.fields]
    );

    const handleMultipleFieldsChange = useCallback(
        (newValues: any) => {
            setTableRow({
                ...tableRow.fields,
                ...newValues
            });
        },
        [setTableRow, tableRow.fields]
    );
    // return table and setTable hook
    return [tableRow, setTableRow, handleSingleFieldChange, handleMultipleFieldsChange] as const;
};

export const useModeContext = (): ['view' | 'edit'] => {
    try {
        const [dataset] = useDataset();

        return [dataset.mode];
    } catch {
        return ['view'];
    }
};

export async function fetchTableData(
    index: number,
    tableDef: TableDef,
    root_id?: number
    // ver: number
): Promise<DatasetTable> {
    const { tableName, viewName, referenceField, extraFilters } = tableDef;
    const filters: Filter[] = [];

    if (!root_id) {
        const datasetTable: DatasetTable = {
            tableName,
            viewName,
            rows: [],
            error: null,
            is_root: index === 0 || Boolean(referenceField),
            isLoading: false
        };

        return datasetTable;
    }
    if (index === 0 && !referenceField) {
        filters.push({
            column: 'id',
            operator: 'eq',
            value: root_id
        });
    } else if (referenceField) {
        filters.push({
            column: referenceField,
            operator: 'eq',
            value: root_id
        });
    } else {
        filters.push({
            column: 'root_id',
            operator: 'eq',
            value: root_id
        });
    }
    if (extraFilters) {
        extraFilters.forEach((filter) => {
            filters.push(filter);
        });
    }
    const { data, error } = await getSupabaseViewOrTableData({
        viewOrTableName: viewName,
        filters
    });
    let rows: TableRow[] = [];
    if (data !== null) {
        rows = (data.map((row) => ({ ...row, _state: (row as TableRow)?._state || 'Unchanged' })) ||
            []) as TableRow[];
    }

    const datasetTable: DatasetTable = {
        tableName,
        viewName,
        rows,
        error,
        is_root: index === 0 || Boolean(referenceField),
        isLoading: false
    };

    return datasetTable;
}

async function fetchTableColumns(tableDef: any): Promise<TableMetaInfo | undefined> {
    const { tableName } = tableDef;
    if (tableName) {
        const { data } = await supabaseClient.rpc('table_columns', {
            table_name: tableName // TODO: надо сделать консистентно. в SaveDataset формат camelCase а тут snake_case
        });

        if (isTableMetaInfo(data)) {
            return data;
        }
        console.error('Data is not of type TableMetaInfo');
    }

    return undefined;
}

const UUID_REGEX =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

const useUserId = (uuid = '') => {
    const [userId, setUserId] = useState<number>();

    useEffect(() => {
        const fetchUserId = async () => {
            const { data } = await (supabaseClient as SupabaseClient<Database, 'public'>)
                .from('settings_users')
                .select('id')
                .eq('user_uuid', uuid)
                .maybeSingle();

            if (data) {
                setUserId(data.id);
            }
        };

        if (uuid.match(UUID_REGEX)) {
            fetchUserId();
        }
    }, [uuid]);

    return userId;
};

const getInitDataset = (root_id: number, mode: 'view' | 'edit', tableDefs: TableDef[]) => {
    const initDataset = new Dataset(root_id, mode, []);

    for (let index = 0; index < tableDefs.length; index += 1) {
        const tableDef = tableDefs[index];
        const { tableName, viewName, referenceField } = tableDef;

        initDataset.tables.push({
            tableName,
            viewName,
            is_root: index === 0 || Boolean(referenceField),
            rows: [],
            error: null,
            isLoading: true // начальное состояние - загрузка
        });
    }

    return initDataset;
};

export const BaseDetailPageProvider = observer(
    ({
        tableDefs,
        tabs,
        onTabsChange,
        children,
        typeInfo,
        callbackDatasetChange,
        addExtraButtons,
        disablePageTitle = false,
        disableActionsPanel = false,
        statusCodes,
        disableStatus = false
    }: BaseDetailPageContextProps) => {
        const query = useQuery();
        const navigate = useStoreNavigate();
        const oldNavigate = useNavigate();
        const location = useLocation();
        const { t, i18n } = useTranslation();
        const { isScreenMd } = useResize();
        const { meta } = metaStore;

        const { openMessage, loadingMessage } = useMessages({ message: '' });
        const { openNotify } = useNotifications({ message: '' });

        const extendedMode = (query.get('mode') as ModeExtended) ?? 'view';
        const mode = extendedMode === 'view' ? 'view' : 'edit'; // если create или clone или edit то edit, иначе view

        const { id } = useParams<{ id: string }>();

        const userId = useUserId(id);

        // parse id to int, if id is nothing or canot parse then set to 0 and set error state
        // settings to -1 for create
        let root_id = -1;

        if (typeof id === 'string') {
            root_id = userId || parseInt(id, 10) || -1;
        }

        const isEtranModel = tableDefs[0].viewName.includes('etran');
        const isOrderModel = tableDefs[0].viewName === 'vdocs_transportation_orders';
        const isInvoiceModel = tableDefs[0].viewName === 'vdocs_invoices';
        const isActModel = tableDefs[0].viewName === 'vdocs_etran_acts';
        const isRfqRequestModel = tableDefs[0].viewName === 'vdocs_rfq_requests';
        const isRfqResponseModel = tableDefs[0].viewName === 'vdocs_rfq_responses';

        const [dataset, setDataset] = useState<Dataset>(getInitDataset(root_id, mode, tableDefs));

        useEffect(() => {
            const fetchMeta = async () => {
                await metaStore.getInfo(toPascalCase(tableDefs[0].tableName));
            };

            fetchMeta();
        }, []);

        useEffect(() => {
            if (callbackDatasetChange && dataset) {
                callbackDatasetChange(dataset);
            }
        }, [callbackDatasetChange, dataset]);

        // если 0 то грузим любую версию. если не ноль то грузим только эту версию!
        const [ver, setVer] = useState(0);
        // boolean открывающий DialogModal для force lock
        const [isModalOpen, setModalOpen] = useState(false);
        // если isDeleted == true, то модалка открывается с кнопками для обработки логики на удаление и текст модалки устанавливается соответствующий для удаления
        const [isDeleted, setDeleted] = useState(false);
        // проверка на то, удалена ли запись
        const [isRecordDeleted, setRecordDeleted] = useState(false);
        // iterate via tableDefs with index because for index = 0 we should load by id,
        // for next by root_id
        const [disableUpdate, setDisableUpdate] = useState(false);

        const [modalTitleAndDescription, setModalTitleAndDescription] = useState<{
            title: any;
            description: any;
        }>({ title: null, description: null });

        const [pageTitle, setPageTitle] = useState('');

        const [filter, setFilter] = useState<ContextFilter[] | null>(null);
        // Добавляем к каждому табу состояние visible - изначально все видны. В будущем, видимо, будет браться из userPreferences
        const [tabList, setTabList] = useState<TabItemProps[]>([]);

        const [formWidth, setFormWidth] = useState(0);

        // Добавить обработчик события изменения размера окна
        useEffect(() => {
            const updateFormSize = () => {
                const detailForm = document.querySelector('.detail-page-form');
                if (detailForm) {
                    const rect = detailForm.getBoundingClientRect();
                    const newWidth = rect.width;
                    setFormWidth((prevWidth) => {
                        if (Math.abs(prevWidth - newWidth) > 100) return newWidth;
                        return prevWidth;
                    });
                }
            };

            // Добавить слушателя события "resize" при монтировании компонента
            window.addEventListener('resize', updateFormSize);

            // Удалить слушателя события "resize" при размонтировании компонента
            return () => {
                window.removeEventListener('resize', updateFormSize);
            };
        }, []);

        useEffect(() => {
            const newTabs: TabItemProps[] = _.map(tabs, (o) => _.extend({ visible: true }, o));
            setTabList(newTabs);
        }, [tabs]);

        const setTitle = (dataset: Dataset, mode: ModeExtended) => {
            const [rootTable] = dataset.tables as any[];

            const {
                tableName,
                rows: [detailData]
            } = rootTable;

            const modelName = tableName.replace('docs_', '').replace('settings_', '').slice(0, -1);

            // let long_title = '';
            let short_title = '';
            let key = '';

            // dataset.tables[0] has type DatasetTable anyway, but also could have keys from TableRow type @karpov
            if (detailData) {
                // long_title = detailData.long_title
                //     ? detailData.long_title[i18n.language]
                //     : rootTable.long_title && rootTable.long_title[i18n.language];

                short_title = detailData.short_title
                    ? detailData.short_title[i18n.language]
                    : rootTable.short_title && rootTable.short_title[i18n.language];

                key = detailData.key || rootTable.key;
            }

            let pageTitle = '';

            const metaAll = meta.get('all');

            if (metaAll) {
                const metaInfo = meta.get(toPascalCase(tableDefs[0].tableName));

                if (metaInfo && metaInfo.info && metaAll.routes) {
                    const pathnameLastElem = location.pathname.split('/').at(-2)?.trim() as string;

                    const singularName =
                        metaInfo.info.SingularName.ru ||
                        t(pathnameLastElem) ||
                        metaInfo.info.SingularName.en;

                    pageTitle = singularName;

                    if (key) pageTitle = `${key} (${singularName})`;
                    else if (short_title) pageTitle = `${short_title} (${singularName})`;

                    // console.log(detailData, 'detailData');
                    // console.log(location, 'location');

                    if (detailData) {
                        routeStore.replace(
                            {
                                pathname: location.pathname,
                                search: location.search,
                                state: location.state
                            },
                            {
                                pathname: location.pathname,
                                search: location.search,
                                state: {
                                    ...location.state,
                                    pageTitle
                                }
                            }
                        );

                        oldNavigate(
                            { ...location },
                            {
                                state: {
                                    ...location.state,
                                    pageTitle
                                }
                            }
                        );
                    }
                }
            }

            // Setting page title and description
            setPageTitle(() => {
                switch (mode) {
                    case 'view':
                        return `${t(modelName)} ${key ? `«${key}»` : ''}`; // если нет ключа, то не выводим

                    case 'create':
                        return t(`creating_${modelName}`);

                    case 'clone':
                        return t(`creating_${modelName}`);

                    case 'edit':
                        return `${t(`editing_${modelName}`)} ${key ? `«${key}»` : ''}`; // если нет ключа, то не выводим

                    default:
                        return `${t(modelName)}`; // если нет ключа, то не выводим
                }
            });
        };

        useEffect(() => {
            setDataset((preDataset) => {
                const updatedDataset = new Dataset(
                    preDataset.root_id,
                    extendedMode === 'view' ? 'view' : 'edit',
                    preDataset.tables,
                    preDataset.tempCounter
                );

                return updatedDataset;
            });
        }, [extendedMode]);

        useEffect(() => {
            setTitle(dataset, extendedMode);
        }, [dataset, extendedMode, i18n.language]);

        const fetchData = useCallback(
            async (root_id: number, refreshFromCreate?: boolean) => {
                const datasetTablePromises = [];
                for (let index = 0; index < tableDefs.length; index += 1) {
                    const tableDef = tableDefs[index];
                    if (!tableDef.rootTable) {
                        const tableDataPromise = fetchTableData(index, tableDef, root_id);
                        datasetTablePromises.push(tableDataPromise);
                    }
                }

                // ВАЖНО что мы получаем таблицы в том порядке в котором они были в tableDefs! Иначе сохранение не будет работать!
                const initialUpdatedTables = await Promise.all(datasetTablePromises);
                const extraDatasetTablePromises = [];

                // Если у нас есть детские таблицы, которые привязаны к другой root таблице, то находим ее id
                for (let index = 0; index < tableDefs.length; index += 1) {
                    const tableDef = tableDefs[index];
                    if (tableDef.rootTable) {
                        const selectedTable = initialUpdatedTables.find(
                            (table) => table.tableName === tableDef.rootTable
                        );
                        if (selectedTable) {
                            const rootId =
                                selectedTable.rows && selectedTable.rows.length > 0
                                    ? selectedTable.rows[0].id
                                    : 0;
                            const tableDataPromise = fetchTableData(index, tableDef, rootId);
                            extraDatasetTablePromises.push(tableDataPromise);
                        }
                    }
                }

                const extraUpdatedTables = await Promise.all(extraDatasetTablePromises);
                const updatedTables = [...initialUpdatedTables, ...extraUpdatedTables];

                // Блокируем кнопку Update, если у заказа есть привязка к заявке
                if (isOrderModel) {
                    const { rows } = updatedTables[0];
                    if (
                        rows[0] &&
                        rows[0].parent_transportation_request_id &&
                        !location.pathname.includes('transportation-orders') // FIXME: 24.07.2023. После показа 25 го числа вернуть функционал обратно
                    ) {
                        setDisableUpdate(true);
                    }
                }

                if (isRfqRequestModel) {
                    const { rows } = updatedTables[0];
                    if (
                        rows[0] &&
                        rows[0].result_status_code &&
                        rows[0].result_status_code === 'SUSPENDED_LOT'
                    ) {
                        setDisableUpdate(true);
                    } else {
                        setDisableUpdate(false);
                    }
                }

                if (isRfqResponseModel) {
                    const { rows } = updatedTables[0];

                    if (
                        (rows[0] &&
                            rows[0].response_status_code &&
                            rows[0].response_status_code === 'REJECTED_RESPONCE') ||
                        rows[0].response_status_code === 'RECIVEED_RESPONCE'
                    ) {
                        setDisableUpdate(true);
                    } else {
                        setDisableUpdate(false);
                    }
                }

                // Теперь далее разная логика:
                // Если View или Edit, то просто обновляем Dataset
                // Если Clone то делаем клонирование!
                // А Create тут никогда не будет
                if (extendedMode === 'view' || extendedMode === 'edit' || refreshFromCreate) {
                    setDataset((prevDataset) => {
                        const updatedDataset = new Dataset(
                            root_id,
                            prevDataset.mode,
                            updatedTables,
                            prevDataset.tempCounter
                        );

                        const row = updatedDataset.tables[0].rows[0];

                        if (row && row.lifecycle_status_code) {
                            if (row.lifecycle_status_code === 'DELETED') {
                                setRecordDeleted(true);
                            }
                        }

                        return updatedDataset;
                    });
                } else if (extendedMode === 'clone') {
                    // done: сделать клонирование
                    // Найти все id / ref и заменить их temp id
                    // Нам нужна мета-информация!
                    // Получаем из Supabase мета-информацию о таблицах и колонках - и самое главное - ссылках!
                    // Получить информацию можно в параллельных запросах
                    const metaDataPromises = [];

                    for (let index = 0; index < tableDefs.length; index += 1) {
                        const tableDef = tableDefs[index];
                        metaDataPromises.push(fetchTableColumns(tableDef));
                    }
                    const tableColumnsResults = await Promise.all(metaDataPromises);

                    let newTables: DatasetTable[] = [];

                    // console.log('update dataset from clone');

                    setDataset((prevDataset) => {
                        const idMapper = new IdMapper();
                        // go thru all table defs (different tables)

                        for (let index = 0; index < tableDefs.length; index += 1) {
                            const curTable = JSON.parse(JSON.stringify(updatedTables[index]));
                            // cycle thru all rows
                            for (let rowIndex = 0; rowIndex < curTable.rows.length; rowIndex += 1) {
                                const oldId = curTable.rows[rowIndex].id;
                                const newId = prevDataset.getNextTempId();
                                idMapper.add(curTable.tableName, oldId, newId);
                                curTable.rows[rowIndex].id = newId;
                                curTable.rows[rowIndex]._state = 'Inserted'; // все что мы склонировали важно пометить как Inserted чтобы правильно сохранилось!
                                if (rowIndex === 0 && curTable.rows[rowIndex].key) {
                                    curTable.rows[rowIndex].key = null;
                                }
                            }

                            newTables = [...newTables, curTable];
                        }

                        // now all ids are updated - we need to update all references
                        for (let index = 0; index < tableColumnsResults.length; index += 1) {
                            const metaInfo = tableColumnsResults[index];
                            if (metaInfo === undefined) {
                                continue;
                            }
                            const curTable = newTables[index];
                            // cycle thru all columns where we have references
                            for (let colIndex = 0; colIndex < metaInfo.length; colIndex += 1) {
                                const colInfo = metaInfo[colIndex];
                                if (colInfo.referenced_table !== null) {
                                    // we replace reference only if it refers one of our tables
                                    const refTableIndex = tableDefs.findIndex(
                                        (td) => td.tableName === colInfo.referenced_table
                                    );
                                    if (refTableIndex === -1) {
                                        continue;
                                    }
                                    // cycle thru all rows and replace references in current column
                                    for (
                                        let rowIndex = 0;
                                        rowIndex < curTable.rows.length;
                                        rowIndex += 1
                                    ) {
                                        const curRow = curTable.rows[rowIndex];
                                        const oldId = curRow[colInfo.column_name];
                                        const newId = idMapper.get(
                                            colInfo.referenced_table as TableName,
                                            oldId
                                        );
                                        if (newId === undefined) {
                                            continue;
                                        }
                                        curRow[colInfo.column_name] = newId;
                                    }
                                }
                            }
                        }

                        const root_id = newTables[0]?.rows[0]?.id;

                        const updatedDataset = new Dataset(
                            root_id, // prevDataset.root_id,
                            prevDataset.mode,
                            // updatedTables, // Use the new updatedTables value
                            newTables, // Use the new updatedTables value
                            prevDataset.tempCounter
                        );

                        return updatedDataset;
                    });
                }
            },
            [extendedMode, isOrderModel, location.pathname, tableDefs]
        );

        useEffect(() => {
            if (dataset.tables[0].tableName === 'settings_users' && !userId) return;
            // done: реализовать логику create и clone
            // На Create мы должны в корневую таблицу вставить новую запись и сгенерить ей temp id (отрицательный). Остальные таблицы оставляем пустыми. Лок не нужен
            // На Clone мы должны как и на View/Edit всё загрузить, а потом пользуясь мета-данными в каждой таблице найти все id / ref на корневую таблицу и других детей и заменить их temp id. Лок не нужен
            // На Edit мы должны как и на View загрузить всё и сделать lock
            // На View мы должны как и на Edit загрузить всё

            const createData = async () => {
                console.log('createData: ');
                setDataset((prevDataset) => {
                    // создадим все таблицы - пустыми, кроме корневой - в ней будет одна запись и root_id со сгенерированным temp id (=-1)
                    const updatedTables: DatasetTable[] = [];

                    for (let index = 0; index < tableDefs.length; index += 1) {
                        const tableDef = tableDefs[index];
                        const { tableName, viewName, referenceField } = tableDef;
                        const rows: TableRow[] = [];

                        console.log('tableDef: ', tableDef);
                        console.log('index: ', index);
                        console.log('typeInfo: ', typeInfo);

                        if (index === 0 && typeInfo) {
                            let id = -1;
                            if (prevDataset.tempCounter === 0) {
                                id = prevDataset.getNextTempId();
                            }
                            console.log('prevDataset.tempCounter: ', prevDataset.tempCounter);
                            console.log('id: ', id);
                            rows.push({
                                id, // we update tempCounter in prevDataset and React is not aware of it - but we call setDataset anyway!
                                _state: 'Inserted',
                                type_code: typeInfo.code || 'DEFAULT',
                                parent_type_code: typeInfo.parent_code || null,
                                type_short_title: typeInfo.short_title,
                                type_long_title: typeInfo.short_title,
                                lifecycle_status_group_id:
                                    typeInfo.settings_lifecycle_status_group_id,
                                ...typeInfo,
                                ...(tableName === 'docs_transportation_trips'
                                    ? {
                                          execution_status_code: 'NOT_STARTED',
                                          execution_status_group_id: 7,
                                          execution_status_long_title: {
                                              en: 'Not started',
                                              ru: 'Не начато',
                                              kk: 'Күтуде',
                                              az: null,
                                              tr: null
                                          },
                                          execution_status_short_title: {
                                              en: 'Not started',
                                              ru: 'Не начато',
                                              kk: 'Күтуде',
                                              az: null,
                                              tr: null
                                          }
                                      }
                                    : {}),
                                ...(tableName === 'docs_rfq_requests'
                                    ? {
                                          result_status_code: 'FORMED_LOT',
                                          result_status_long_title: {
                                              en: 'The lot has been formed',
                                              ru: 'Лот сформирован',
                                              kk: null,
                                              az: null,
                                              tr: null
                                          },
                                          result_status_short_title: {
                                              en: 'The lot has been formed',
                                              ru: 'Лот сформирован',
                                              kk: null,
                                              az: null,
                                              tr: null
                                          }
                                      }
                                    : {})
                            });
                        }

                        const datasetTable: DatasetTable = {
                            tableName,
                            viewName,
                            is_root: index === 0 || Boolean(referenceField),
                            rows,
                            error: null,
                            isLoading: false
                        };

                        updatedTables.push(datasetTable);
                    }

                    const updatedDataset = new Dataset(
                        prevDataset.root_id,
                        prevDataset.mode,
                        updatedTables, // Use the new updatedTables value
                        prevDataset.tempCounter // тут уже будет с измененным счетчиком!
                    );

                    return updatedDataset;
                });
            };

            const messageKey = 'fetch_or_create';

            // loadingMessage(messageKey);

            // if clone or view or edit then fetch data
            // else create empty
            if (extendedMode === 'view' || extendedMode === 'edit' || extendedMode === 'clone') {
                try {
                    if (dataset.root_id > 0) {
                        fetchData(dataset.root_id);

                        // // Успех
                        // openMessage({
                        //     key: messageKey,
                        //     message: t('fetch_data_success'),
                        //     type: 'success'
                        // });
                    }
                } catch (error) {
                    // в UI
                    openMessage({
                        key: messageKey,
                        message: t('fetch_data_error_message'),
                        type: 'error'
                    });
                    openNotify({
                        message: t('fetch_data_error'),
                        description:
                            makeErrorReadable((error as Error)?.message) ||
                            (t('fetch_data_error_message') as string),
                        type: 'error',
                        duration: 0
                    });

                    // в консоль
                    console.warn(t('fetch_data_error_message'));
                    console.error((error as Error)?.message || error);
                }
            } else {
                try {
                    createData();

                    // Успех
                    // openMessage({ key: messageKey, message: t('fetch_data_success'), type: 'success' });
                } catch (error) {
                    // в UI
                    openMessage({
                        key: messageKey,
                        message: t('fetch_data_error_message'),
                        type: 'error'
                    });
                    openNotify({
                        message: t('fetch_data_error'),
                        description:
                            makeErrorReadable((error as Error)?.message) ||
                            (t('fetch_data_error_message') as string),
                        type: 'error',
                        duration: 0
                    });

                    // в консоль
                    console.warn(t('fetch_data_error_message'));
                    console.error((error as Error)?.message || error);
                }
            }
        }, [extendedMode, dataset.root_id]);

        const handleRefresh = useCallback(
            (root_id?: number, refreshFromCreate?: boolean) => {
                const messageKey = 'refresh';

                loadingMessage(messageKey);

                try {
                    if (typeof root_id === 'number') fetchData(root_id);
                    else fetchData(dataset.root_id, refreshFromCreate);

                    // Успех
                    openMessage({
                        key: messageKey,
                        message: t('refresh_success'),
                        type: 'success'
                    });
                } catch (error) {
                    // в UI
                    openMessage({
                        key: messageKey,
                        message: t('refresh_dataset_error'),
                        type: 'error'
                    });
                    openNotify({
                        message: t('refresh_error'),
                        description:
                            makeErrorReadable((error as Error)?.message) ||
                            (t('refresh_dataset_error') as string),
                        type: 'error',
                        duration: 0
                    });

                    // в консоль
                    console.warn(t('refresh_dataset_error'));
                    console.error((error as Error)?.message || error);
                }
            },
            [dataset.root_id]
        );

        const handleDelete = useCallback(async () => {
            // При удалении объекта
            // mode=view: из формы просмотра
            // - Если lock не пустой то ругаемся "Вы точно хотите удалить?
            //   Объект заблокирован пользователем таким-то". Варианты: Нет и Да
            // - Если lock пустой или выбрали Да:
            // - Делаем Update указывая ver и isDeleted = true.
            //   В таком режиме если у нас не самая последняя версия то будет ошибка
            // mode=edit: из формы редактирования (лок, как мы думаем, у нас):
            // - Пытаемся сделать Update указав ver и lock=null, isDeleted = true.
            // - Если неуспешно то предлагаем попытку удалить
            // TODO: implenent this!
            // const rootTable = dataset.tables[0];
            // const rootRow = rootTable.rows[0] as DocsRootTableRow;

            const messageKey = 'delete'; // Уникальный ключ для изменения состяния сообщения

            loadingMessage(messageKey);

            const rootTable = dataset.tables[0];
            const rootRow = rootTable.rows[0] as DocsRootTableRow;
            const { tableName } = tableDefs[0];
            const route_path = location.pathname
                .substring(0, location.pathname.lastIndexOf('/'))
                .substring(1);

            if (tableName.includes('settings')) {
                const { error } = await supabaseClient
                    .from(tableName)
                    .delete()
                    .match({ id: rootRow.id, ver: rootRow.ver });

                if (error) {
                    // В UI
                    openMessage({
                        key: messageKey,
                        message: t('deliting_failed_dataset'),
                        type: 'error'
                    });
                    openNotify({
                        message: t('deleting_error'),
                        description: makeErrorReadable(error.message),
                        type: 'error',
                        duration: 0 // Выключаем автоматическое закрытие нотификации
                    });

                    // В консоль
                    console.warn(t('deleting_error'));
                    console.error(error.message);

                    return;
                }

                openMessage({
                    key: messageKey,
                    message: t('deleting_success'),
                    type: 'success'
                });

                navigate({
                    pathname: `/${route_path}`
                });
            } else {
                rootRow.lifecycle_status_code = 'DELETED';
                rootRow.locked_by_uuid = null;
                rootRow.locked_at = null;
                rootRow.locked_by_email = null;

                if (rootRow._state === 'Unchanged') {
                    rootRow._state = 'Updated';
                }

                if (rootTable.tableName === 'docs_transportation_trips') {
                    rootRow.planning_status_code = null;
                    rootRow.approval_status_code = null;
                }

                const { error } = await saveDataset(dataset);

                setDeleted(true);

                if (error) {
                    const errMessage = error.message.split('error:').at(-1) || '';

                    setModalOpen(true);
                    setModalTitleAndDescription({
                        title: `${t('object_deleting')} ${rootRow.key}`,
                        description: (
                            <div>
                                <div>{makeErrorReadable(errMessage.trim())}</div>
                                <br />
                                <div>{t('are_you_sure_to_delete')}</div>
                            </div>
                        )
                    });

                    return;
                }

                openMessage({
                    key: messageKey,
                    message: t('deleting_success'),
                    type: 'success'
                });

                setDeleted(false);
                setRecordDeleted(true);
                handleRefresh();
            }
        }, [
            dataset,
            handleRefresh,
            loadingMessage,
            location.pathname,
            navigate,
            openMessage,
            openNotify,
            tableDefs
        ]);

        const handleClone = useCallback(async () => {
            const searchString = `?mode=clone`;
            navigate({
                pathname: location.pathname,
                search: searchString
            });
        }, [navigate, location.pathname]);

        const handleCloseNav = () => {
            const { pathname, search, state } = location;
            const closedIndex = routeStore.findIndex({ pathname, search, state });

            const route = toJS(routeStore.routes[closedIndex]);

            routeStore.remove(route);
            detailPageStore.removeByTableName(dataset.tables[0].tableName);

            const storeItem = routeStore.routes[closedIndex - 1];

            navigate(
                { pathname: storeItem.pathname, search: storeItem.search },
                { state: storeItem.state }
            );
        };
        const handleCancel = useCallback(async () => {
            const extendedMode = (query.get('mode') as ModeExtended) ?? 'view';

            if (extendedMode === 'create') {
                handleCloseNav();
                return true;
            }
            // При переходе из Edit➝View: Через закрытие без сохранения
            // - Пытаемся сделать ReleaseLock указав ver
            const messageKey = 'release_lock';

            loadingMessage(messageKey);

            const rootTable = dataset.tables[0];

            // сбрасывыаем запоминание последнего открытого create page
            detailPageStore.removeCreateInfo(rootTable.tableName);

            const rootRow = rootTable.rows[0] as DocsRootTableRow;

            let navigationOptions: { [key: string]: any } = {
                pathname: location.pathname.replace('/:', '')
            };

            // Если мы создаем объект, то release lock на него делать не надо
            if (rootRow.id !== -1) {
                const { error } = await supabaseClient.rpc('release_locked_version', {
                    root_table_name: tableDefs[0].tableName,
                    root_id,
                    ver: rootRow.ver
                });

                if (error) {
                    // в UI
                    openMessage({
                        key: messageKey,
                        message: t('release_lock_dataset_error'),
                        type: 'error'
                    });
                    openNotify({
                        message: t('release_lock_error'),
                        description: makeErrorReadable(error.message),
                        type: 'error'
                    });

                    // в консоль
                    console.warn(t('relese_lock_description'));
                    console.error(error.message);

                    return false;
                }

                openMessage({
                    key: messageKey,
                    message: t('release_lock_success'),
                    type: 'success'
                });

                const searchString = `?mode=view`;
                navigationOptions = {
                    pathname: location.pathname,
                    search: searchString
                };
            }

            // После отмены сразу вызываем обновление данных (так как датасет локально изменен) для актуализации и подтягивания системных данных с сервера
            handleRefresh();

            navigate(navigationOptions);

            return true;
        }, [
            dataset,
            // dataset.tables,
            handleRefresh,
            loadingMessage,
            location.pathname,
            navigate,
            openMessage,
            openNotify,
            root_id,
            tableDefs
        ]);

        useEffect(() => {
            const verForUnmount = dataset?.tables[0]?.rows[0]?.ver;
            const idForUnmount = dataset?.tables[0]?.rows[0]?.ver;
            if (!verForUnmount) return;
            const cancel = async () => {
                if (idForUnmount !== -1) {
                    await supabaseClient.rpc('release_locked_version', {
                        root_table_name: tableDefs[0].tableName,
                        root_id,
                        ver: verForUnmount
                    });
                }
            };

            return cancel;
        }, [dataset, tableDefs, root_id]);

        const handleEdit = useCallback(async () => {
            // При переходе из View ➝ Edit:
            // - Пытаемся сделать AcquireLock
            // - Если успешно то мы получаем все СВЕЖИЕ (ведь они могли измениться)
            //   поля объекта - их и надо показывать для редактирования!
            // UPDATE!! Мы больше НЕ получаем свежие данные на AcquireLock!
            // Т.к. много детских моделей и не обязательно их грузить.
            // Мы просто знаем что лок за нами и грузим конкретную нашу версию
            // - Если неуспешно, то надо предложить сделать попытку AcquireLock с force

            // Возможные ситуации:
            // 1. Открытие странице сразу в режиме mode=edit
            // 2. Открытие старнице в режиме mode=view и переход в режим mode=edit
            // 3. Мы в режиме mode=edit unlocked: пытаемся сделать лок
            // 4. Мы в режиме mode=edit unlocked: пытаемся сделать лок и открытое окно force
            // 5. Мы в режиме mode=edit locked ver: тут загружаем данные с указанием ver
            // 6. Открытие режиме view из загрузка данных без версии

            // из supabase загружаем данные:
            // - если mode=view то загружаем без указания версии
            // - если mode=edit то загружаем с указанием версии в стейте если она есть а иначе не загружаем

            // - если mode=edit и ver = 0 то пытаемся сделать AcquireLock

            // const rootTable = dataset.tables[0];

            const { data, error } = await supabaseClient.rpc('acquire_locked_version', {
                root_table_name: tableDefs[0].tableName,
                root_id,
                force: false
            });
            const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
            rootRow.ver = data || ver || 0;

            if (error) {
                console.error(error);
                if (error.code === 'P0001') {
                    setModalOpen(true);
                    setModalTitleAndDescription({
                        title: t('are_you_sure_to_update'),
                        description: makeErrorReadable(error.code)
                    });
                }
                return;
            }

            const searchString = `?mode=edit`;
            navigate({
                pathname: location.pathname,
                search: searchString
            });

            // if locked_ver is 0 then lock is not acquired
            // otherwise - lock is acquired and locked_ver is version of locked row
            // - need to reload the data with this version and switch to edit mode
            setVer(data);
        }, [dataset.tables, location.pathname, navigate, root_id, tableDefs, ver]);

        const canRecordBeSaved = useCallback(async () => {
            const stopsTable = dataset.tables.filter((row) => row.tableName.includes('_stops'));
            let canSave = true;

            const tripTable = dataset.tables.find(
                (table) => table.tableName === 'docs_transportation_trips'
            );
            if (tripTable && tripTable.rows.length > 0) {
                if (tripTable.rows[0].type_code === 'YARD_OUT_TRIP') {
                    return canSave;
                }
            }

            const orderTable = dataset.tables.find(
                (table) => table.tableName === 'docs_transportation_orders'
            );
            if (orderTable && orderTable.rows.length > 0) {
                if (orderTable.rows[0].type_code === 'YARD_OUT_ORDER') {
                    return canSave;
                }
            }

            if (stopsTable && stopsTable.length > 0) {
                stopsTable.forEach((table) => {
                    const stopsTableNotDeleted = table.rows.filter(
                        (row) => row._state !== 'Deleted'
                    );
                    if (
                        stopsTableNotDeleted.length <= 1 &&
                        table.tableName !== 'docs_transportation_trip_vehicle_unit_stage_stops' &&
                        table.tableName !== 'docs_standard_route_stops' &&
                        table.tableName !== 'docs_standard_route_stops_vehicles' &&
                        table.tableName !== 'docs_rfq_request_round_stops' &&
                        table.tableName !== 'docs_rfq_request_stops' &&
                        table.tableName !== 'docs_rfq_request_round_direction_stops' &&
                        table.tableName !== 'docs_rfq_response_direction_stops' &&
                        table.tableName !== 'docs_rfq_request_direction_stops' &&
                        table.tableName !== 'docs_order_movement_stops' &&
                        table.tableName !== 'docs_route_schedule_stops' &&
                        table.tableName !== 'docs_trip_group_stops'
                    ) {
                        canSave = false;
                    }
                });

                if (stopsTable[0].tableName === 'docs_transportation_request_direction_stops') {
                    const directions = dataset.tables.filter(
                        (row) => row.tableName === 'docs_transportation_request_directions'
                    );

                    if (directions && directions[0].rows.length > 0) {
                        for (let i = 0; i < directions[0].rows.length; i++) {
                            const directionStops = stopsTable[0].rows.filter(
                                (row) =>
                                    row.parent_transportation_request_direction_id ===
                                    directions[0].rows[i].id
                            );
                            if (directionStops.length <= 1) {
                                canSave = false;
                            } else {
                                canSave = true;
                            }
                        }
                    } else {
                        canSave = true;
                    }
                }
            }

            return canSave;
        }, [dataset.tables]);

        const handleSave = useCallback(async () => {
            // При переходе из Edit➝View: Через сохранение результатов:
            // - Пытаемся сделать Insert/Update указав ver и lock=null
            //   - ver тут уже правильный - тот с которым мы загружали данные
            //   - lock будем обнулять - т.к. мы его снимаем\

            const messageKey = 'save_detail'; // Ключ для изменения состояния сообщения

            if (!(await canRecordBeSaved())) {
                openMessage({
                    key: messageKey,
                    message: t('infoStopsQuantityValidation'),
                    type: 'error'
                });
                return false;
            }

            loadingMessage(messageKey);

            // сбрасывыаем запоминание последнего открытого create page
            detailPageStore.removeCreateInfo(dataset.tables[0].tableName);

            const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
            rootRow.locked_by_uuid = null;
            rootRow.locked_at = null;
            rootRow.locked_by_email = null;
            if (rootRow._state === 'Unchanged') {
                rootRow._state = 'Updated'; // Для того чтобы Бэк обязательно сохранял и сбрасывал блокировку
            } // иначе там уже правильное состояние: Inserted в случае Clone или Create

            // convert dataset to JSONB for postgres
            if (extendedMode === 'create' || extendedMode === 'clone') {
                rootRow._state = 'Inserted';
            }
            console.log('call save_dataset() with dataset=', dataset);
            // Испльзуем rpc процедуру save_dataset;

            const { data, error } = await saveDataset(dataset);

            if (error) {
                // в UI
                openMessage({
                    key: messageKey,
                    message: t('saving_failed_dataset'),
                    type: 'error'
                });
                openNotify({
                    message: t('saving_error'),
                    type: 'error',
                    description: makeErrorReadable(error.message),
                    duration: 0 // выключаем авто. закрытие нотификации
                });

                // в консоль
                console.warn(t('saving_error'));
                console.error(error.message);

                // прерываем выполнение функции
                return false;
            }

            openMessage({ key: messageKey, message: t('saving_success'), type: 'success' });

            if (data) {
                // Чистим path от :, который в url при создании и клонировании
                const route_path = `${location.pathname.substring(
                    0,
                    location.pathname.lastIndexOf('/')
                )}/${data}`;

                // не даем перейти на :id в виде айдишника, остаемся на uuid
                if (dataset.tables[0].tableName === 'settings_users') {
                    oldNavigate({
                        search: `?mode=view`
                    });
                } else {
                    oldNavigate({
                        pathname: route_path,
                        search: `?mode=view`
                    });
                }

                setDataset((prevDataset) => {
                    const prevDatasetTables = [...prevDataset.tables];

                    prevDataset.tables.forEach((table, index) => {
                        prevDatasetTables[index].rows = table.rows.filter(
                            (row) => !(row._state === 'Deleted' && row.id <= 0)
                        );
                    });

                    const updatedDataset = new Dataset(
                        data as number,
                        prevDataset.mode,
                        prevDatasetTables,
                        prevDataset.tempCounter // тут уже будет с измененным счетчиком!
                    );

                    return updatedDataset;
                });

                return true;
            }

            return true;
        }, [
            canRecordBeSaved,
            dataset,
            extendedMode,
            handleRefresh,
            location.pathname,
            navigate,
            openMessage,
            openNotify
        ]);

        const handleForce = useCallback(async () => {
            const messageKey = 'acquire_lock';

            loadingMessage(messageKey);

            const { data, error } = await supabaseClient.rpc('acquire_locked_version', {
                root_table_name: tableDefs[0].tableName,
                root_id,
                force: true
            });

            if (error) {
                // в UI
                openMessage({
                    key: messageKey,
                    message: t('acquire_locking_dataset_error'),
                    type: 'error'
                });
                openNotify({
                    message: t('acquire_locking_error'),
                    description: makeErrorReadable(error.message),
                    type: 'error',
                    duration: 0
                });

                // в консоль
                console.warn(t('acquire_locking_dataset_error'));
                console.error(error.message);

                return;
            }

            openMessage({
                key: messageKey,
                message: t('acquire_locking_success'),
                type: 'success'
            });

            setVer(data);

            const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
            rootRow.ver = data;

            const searchString = `?mode=edit`;
            navigate({
                pathname: location.pathname,
                search: searchString
            });
        }, [
            dataset,
            loadingMessage,
            location.pathname,
            navigate,
            openMessage,
            openNotify,
            root_id,
            tableDefs
        ]);

        const handleForceAndDelete = useCallback(async () => {
            const messageKey = 'asquire_lock_and_delete';

            loadingMessage(messageKey);

            const { data, error } = await supabaseClient.rpc('acquire_locked_version', {
                root_table_name: tableDefs[0].tableName,
                root_id,
                force: true
            });

            if (error) {
                // в UI
                openMessage({
                    key: messageKey,
                    message: t('acquire_locking_dataset_error'),
                    type: 'error'
                });
                openNotify({
                    message: t('acquire_locking_error'),
                    description: makeErrorReadable(error.message),
                    type: 'error',
                    duration: 0
                });

                // в консоль
                console.warn(t('acquire_locking_dataset_error'));
                console.error(error.message);

                return;
            }

            openMessage({
                key: messageKey,
                message: t('acquire_locking_success'),
                type: 'success'
            });

            setVer(data);
            const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
            rootRow.ver = data;
            handleDelete();
        }, [dataset, handleDelete, loadingMessage, openMessage, openNotify, root_id, tableDefs]);

        const handeSendToSystem = useCallback(
            async (status: string) => {
                if (isInvoiceModel) {
                    const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
                    if (rootRow._state === 'Unchanged') {
                        rootRow._state = 'Updated';
                    }
                    rootRow.accounting_status_code = status;
                    const { data, error } = await saveDataset(dataset);
                    if (data) {
                        handleRefresh();
                    }
                }
            },
            [dataset, handleRefresh, isInvoiceModel]
        );

        const disableButton = (statuses: any[], field: string) => {
            const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
            if (rootRow) {
                return statuses.includes(rootRow[field]);
            }
            return false;
        };

        const handleApproveAct = useCallback(async () => {
            if (isActModel) {
                const rootRow = dataset.tables[0].rows[0] as DocsRootTableRow;
                // const refsTable = dataset.tables.filter((row) => row.tableName === 'docs_etran_act_sf_sf_recs');
                // let refIds = refsTable[0].rows.filter(row => row.selected).map(a => a.id);
                // const { data, error } = await createInvoiceFromEtranAct(supabase, refIds, true);
                // if (rootRow._state === 'Unchanged') {
                //     rootRow._state = 'Updated';
                // }
                // rootRow.lifecycle_status_code = 'ACCEPTED';
                const { error } = await supabaseClient
                    .from('docs_etran_acts')
                    .update({ lifecycle_status_code: 'ACCEPTED' })
                    .match({ id: rootRow.id });
                // const { data, error } = await saveDataset(supabase, dataset);
                if (error) {
                    openMessage({
                        key: 'act_accept',
                        message: t('act_accept_error'),
                        type: 'error'
                    });
                } else {
                    openMessage({
                        key: 'act_accept_success',
                        message: t('act_accept_success'),
                        type: 'success'
                    });

                    handleRefresh();
                }
            }
        }, [dataset.tables, handleRefresh, isActModel, openMessage]);

        const handleCreateInvoice = useCallback(async () => {
            if (isActModel) {
                const refsTable = dataset.tables.filter(
                    (row) => row.tableName === 'docs_etran_act_sf_sf_recs'
                );
                const refIds = refsTable[0].rows.filter((row) => row.selected).map((a) => a.id);
                if (refIds.length > 0) {
                    const { data, error } = await createInvoiceFromEtranAct(refIds, true);
                    if (error) {
                        openMessage({
                            key: 'create_invoice',
                            message: t('create_invoice_error'),
                            type: 'error'
                        });
                    } else {
                        openMessage({
                            key: 'create_invoice_success',
                            message: t('create_invoice_success'),
                            type: 'success'
                        });
                    }
                } else {
                    openMessage({
                        key: 'positions_not_selected',
                        message: t('positions_not_selected'),
                        type: 'error'
                    });
                }
            }
        }, [dataset.tables, handleRefresh, isActModel, openMessage, t]);

        const getExtraButtons = useCallback(() => {
            if (isInvoiceModel) {
                return [
                    <ButtonWithTooltips
                        id="sendToSystem"
                        tooltipTitle={t('sendToSystem')}
                        onClick={() => handeSendToSystem('SENT')}
                        icon={<ArrowDownOutlined />}
                        disabled={disableButton(['SENT'], 'accounting_status_code')}
                    />,
                    <ButtonWithTooltips
                        id="cancelSending"
                        tooltipTitle={t('cancelSending')}
                        onClick={() => handeSendToSystem('REVERSAL_REQUESTED')}
                        icon={<CloseCircleOutlined />}
                        disabled={disableButton(
                            ['NOT_SENT', 'REVERSAL_REQUESTED', null],
                            'accounting_status_code'
                        )}
                    />
                ];
            }
            if (isActModel) {
                return [
                    <ButtonWithTooltips
                        id="create-invoice"
                        tooltipTitle={t('create-invoice')}
                        onClick={handleCreateInvoice}
                        icon={<PlusOutlined />}
                        // disabled={disableButton(['ACCEPTED'], 'lifecycle_status_code')}
                    />,
                    <ButtonWithTooltips
                        id="accept"
                        tooltipTitle={t('accept')}
                        onClick={handleApproveAct}
                        icon={<CheckOutlined />}
                        disabled={disableButton(['ACCEPTED'], 'lifecycle_status_code')}
                    />
                ];
            }
            return (
                (addExtraButtons &&
                    addExtraButtons(dataset, () => handleRefresh(dataset.root_id, true))) ||
                []
            );
        }, [handeSendToSystem, handleApproveAct, isActModel, isInvoiceModel, t, addExtraButtons]);

        const handleUpdateLifecycleStatus = useCallback((newValues: TableRow) => {
            setDataset((prevDataset) => {
                if (prevDataset.tables[0].rows) {
                    const mainTable = prevDataset.tables[0];
                    const mainTableRows = mainTable.rows.map((row, index) => {
                        if (index === 0) {
                            if (mainTable.tableName === 'docs_transportation_trips') {
                                return {
                                    ...row,
                                    ...newValues,
                                    approval_status_code: null,
                                    planning_status_code: null
                                };
                            }
                            return { ...row, ...newValues };
                        }

                        return row;
                    });

                    const updatedDataset = new Dataset(
                        prevDataset.root_id,
                        prevDataset.mode,
                        [
                            { ...mainTable, rows: [...mainTableRows] },
                            ...prevDataset.tables.slice(1, prevDataset.tables.length)
                        ],
                        prevDataset.tempCounter
                    );

                    return updatedDataset;
                }

                const mainTable = { ...prevDataset.tables[0], ...newValues };

                const updatedDataset = new Dataset(
                    prevDataset.root_id,
                    prevDataset.mode,
                    [{ ...mainTable }, ...prevDataset.tables.slice(1, prevDataset.tables.length)], // Use the new updatedTables value
                    prevDataset.tempCounter
                );

                return updatedDataset;
            });
        }, []);

        const providerValue = useMemo<
            [
                Dataset,
                React.Dispatch<React.SetStateAction<Dataset>>,
                ContextFilter[] | null,
                Dispatch<SetStateAction<ContextFilter[] | null>>,
                () => void
            ]
        >(
            () => [
                dataset,
                setDataset,
                filter,
                setFilter,
                () => handleRefresh(dataset.root_id, true)
            ],
            [dataset, filter, handleRefresh]
        );
        // add MUI panel of buttons to close or edit in View mode and cancel, save in Edit mode

        return (
            <>
                <DatasetContext.Provider value={providerValue}>
                    <Form labelWrap className="detail-page-form">
                        {/* <PageHeader
                            title={pageTitle}
                            disabled={disablePageTitle}
                            pageWidth={formWidth}
                            onVertical={900}
                            right={
                                !disableActionsPanel && (
                                    
                                )
                            }
                        /> */}
                        <Flex
                            vertical={!!(formWidth && formWidth < 500)}
                            wrap={isScreenMd ? 'wrap-reverse' : 'wrap'}
                            justify="flex-end"
                            align="center"
                            gap="small"
                            style={{
                                padding: isScreenMd ? '5px 0' : undefined,
                                width: isScreenMd ? '55%' : undefined,
                                position: 'absolute',
                                // paddingBottom: location.search.includes('edit') ? 5 : undefined,
                                top: 'calc(1vh + 2.9rem)',
                                left: `calc(100vw - ${location.search.includes('edit') ? 44 : 33}rem)`
                            }}
                        >
                            {!disableStatus && (
                                <StatusPanel
                                    mode={mode}
                                    fields={dataset.tables[0].rows[0] || dataset.tables[0]}
                                    onChange={handleUpdateLifecycleStatus}
                                    statusCodes={statusCodes}
                                />
                            )}
                            <ActionsPanel
                                mode={mode}
                                onCancel={handleCancel}
                                onClone={handleClone}
                                onEdit={handleEdit}
                                onSave={handleSave}
                                onDelete={handleDelete}
                                onRefresh={handleRefresh}
                                tabList={tabList}
                                setTabList={setTabList}
                                disableUpdate={disableUpdate || isEtranModel}
                                isRecordDeleted={isRecordDeleted}
                                extraButtons={getExtraButtons()}
                            />
                        </Flex>
                        <TabsBox
                            withCardWrapper={!disablePageTitle}
                            items={tabList
                                .filter((tab: any) => tab.visible)
                                .map((tab, index) => ({ ...tab, key: String(index) }))}
                            onChange={onTabsChange}
                        />

                        {children}
                    </Form>
                </DatasetContext.Provider>
                <DialogModal
                    open={isModalOpen}
                    setOpen={setModalOpen}
                    handleClick={isDeleted ? handleForceAndDelete : handleForce}
                    title={modalTitleAndDescription.title}
                    description={modalTitleAndDescription.description}
                />
            </>
        );
    }
);
