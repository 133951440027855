import { Meta } from 'modules/services/backend-api/generated_info';
import { getNestedValueFromObject } from './getNestedValueFromObject';

type ColumnOptionsRefType = { meta: string; fieldName?: string };

interface Context {
    root?: any;
    current?: any;
    self?: any;
    info?: Meta;
}

export type ColumnOptionsType = {
    ref?: ColumnOptionsRefType;
    default?: number | string | boolean;
    filters?: string;
    fields?: string[];
    path?: string;
    file_name?: string;
    bucket?: string;
    group?: string;
    display?: 'inline' | 'dialog';
    is_root_id?: boolean;
    json_type?: string;
    value?: string;
    [key: string]: any; // TODO: а зачем мы это поддерживаем? Может, лучше убрать и не создавать рисков с неожиданными ключами?
};

const parseTemplate = (template: string, contextData: Context) => {
    if (template.includes('{{') && template.includes('}}')) {
        const match = template.match(/\{\{([^}]+)\}\}/);
        const objectPath = match
            ? match[1]
                  .replace('Current.', '')
                  .replace('Root.', '')
                  .replace('Info.', '')
                  .replace('Context.', '')
            : '';

        let nestedValue;

        if (match?.[1]?.includes('Root') && contextData.root) {
            nestedValue = getNestedValueFromObject(contextData.root, objectPath);
        } else if (match?.[1]?.includes('Current') && contextData?.current) {
            nestedValue = getNestedValueFromObject(contextData.current, objectPath);
        } else if (match?.[1]?.includes('Self') && contextData?.self) {
            nestedValue = getNestedValueFromObject(contextData.self, objectPath);
        } else if (match?.[1]?.includes('Info') && contextData?.info) {
            nestedValue = getNestedValueFromObject(contextData.info, objectPath);
        }

        return nestedValue;
    }

    return template;
};

export const parseValueType = (valueType: string, contextData?: Context) => {
    // console.log(typeof valueType, valueType);
    const [type, ...optionParts] = valueType.split(';');

    const options: ColumnOptionsType = optionParts.reduce<ColumnOptionsType>((acc, part) => {
        if (part.startsWith('ref:')) {
            const [meta, fieldName] = part.replace('ref:', '').split('.');
            acc.ref = { meta, fieldName };
        } else if (part.startsWith('default:')) {
            const value = part.replace('default:', '');
            acc.default = Number.isNaN(Number(value)) ? value : Number(value);
        } else if (part.startsWith('json_type:')) {
            const value = part.replace('json_type:', '');
            acc.json_type = value;
        } else if (part.startsWith('filters:')) {
            acc.filters = part.replace('filters:', '');

            if (contextData) {
                const nestedValue = parseTemplate(acc.filters, contextData);

                if (nestedValue !== acc.filters) {
                    const before = acc.filters?.split('{{').at(0);
                    const after = acc.filters.split('}}').at(-1);

                    acc.filters = nestedValue ? `${before}${nestedValue}${after}` : acc.filters;
                }
            }
        } else if (part.startsWith('value:')) {
            acc.value = part.replace('value:', '');

            if (contextData) {
                const nestedValue = parseTemplate(acc.value, contextData);
                acc.value = nestedValue || undefined;
            }
        } else if (part.startsWith('file_name:')) {
            acc.file_name = part.replace('file_name:', '');

            if (contextData) {
                const nestedValue = parseTemplate(acc.file_name, contextData);
                acc.file_name = nestedValue || undefined;
            }
        } else if (part.startsWith('path:')) {
            acc.path = part.replace('path:', ''); // TODO: прикрутить тут шаблон
        } else if (part.startsWith('bucket:')) {
            acc.bucket = part.replace('bucket:', '');
        } else if (part.startsWith('fields:')) {
            acc.fields = part.replace('fields:', '').split(',');
        } else if (part.startsWith('group:')) {
            acc.group = part.replace('group:', '');
        } else if (part.startsWith('display:')) {
            acc.display = part.replace('display:', '') as 'inline' | 'dialog';
        } else if (part === 'is_root_id') {
            acc.is_root_id = true;
        } else {
            const [key, value] = part.split(':');
            acc[key] = Number.isNaN(Number(value)) ? value : Number(value);
        }
        return acc;
    }, {});

    return { type, options: Object.keys(options).length ? options : null };
};
