import { Form, FormItemProps, Skeleton } from 'antd';
import { useContext } from 'react';

import { EditableContext } from '../../contexts';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    dataIndex: string;
    title: string;
    row: any;
    rowIndex: number;
    index: number;
    validation: { rules: FormItemProps['rules'] };
    render: (
        value: any,
        row: any,
        rowIndex: any,
        onChange?: (key: string, value: any) => void
    ) => React.ReactNode;
    onTableDataChange: (value: any) => void;
}

export const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    row,
    rowIndex,
    dataIndex,
    title,
    render,
    onTableDataChange,
    validation,
    index,
    children,
    ...restProps
}) => {
    const { form, initialized } = useContext(EditableContext)!;

    const handleChange = (key: string, newValue: any) => {
        try {
            const keys = key.split('.');

            if (keys.length > 1) {
                form.setFieldValue(keys[0], {
                    ...form.getFieldValue(keys[0]),
                    [keys[1]]: newValue
                });

                onTableDataChange({ ...row, [dataIndex]: newValue });
                onTableDataChange({
                    ...row,
                    [keys[0]]: { ...row[keys[0]], [keys[1]]: newValue }
                });
            } else {
                form.setFieldValue(key, newValue);
                onTableDataChange({ ...row, [key]: newValue });
            }
            // TODO: валидация
            // const values = await form.validateFields();
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const value = form.getFieldValue(dataIndex);

    return (
        <td {...restProps} style={{ flex: 'auto', ...restProps.style }}>
            <Form.Item
                name={dataIndex}
                style={{ margin: 0 }}
                rules={validation ? validation.rules : undefined}
            >
                {initialized ? (
                    render ? (
                        render(value, row, rowIndex, handleChange)
                    ) : (
                        children
                    )
                ) : (
                    <Skeleton.Input active size="small" />
                )}
            </Form.Item>
        </td>
    );
};
