import { FLOW_MARKERS, STATUS_MARKERS } from 'utils/config/constants';
import { ViewName } from 'modules/supabase/types/Dataset';

export const statusColorMarker = (
    value: string,
    viewName?: ViewName
): React.CSSProperties | undefined => {
    if (!value) return undefined;

    let style: React.CSSProperties = {
        color: 'white',
        backgroundColor: 'lightgray',
        textAlign: 'center'
    };

    if (viewName === 'vdocs_transportation_units') {
        // Временная правка. Доработка по задаче Цветовая схема статусов планирования(8693d2tmw)
        style = { ...style, color: '#09A953', backgroundColor: '#DBF5EA' };
    } else if (STATUS_MARKERS[value]) {
        style = { ...style, ...STATUS_MARKERS[value] };
    }

    return style;
};

export const flowColorMapper = (value: string): React.CSSProperties | undefined => {
    if (!value) return undefined;

    let style: React.CSSProperties = {};

    if (FLOW_MARKERS[value]) {
        style = { ...FLOW_MARKERS[value] };
    }

    return style;
};
