import { TableName, ViewName } from 'modules/supabase/types/Dataset';
import { Filter } from 'modules/supabase/utils/supabaseClient';

type TableReference = {
    navigate_path?: string;
};

type ColumnReference = {
    referenced_column: string;
    referenced_table?: TableName;
    navigate_path?: string;
    view_help?: ViewName;

    view_help_filter?: Filter[];
};
export const TableMapping: Partial<Record<TableName, TableReference>> = {
    docs_transportation_trips: {
        navigate_path: '/transactional-data/transportation-trips/'
    },
    docs_transportation_requests: {
        navigate_path: '/orders-management/transportation-requests/'
    },
    docs_transportation_units: {
        navigate_path: '/orders-management/transportation-units/'
    },
    docs_transportation_tasks: {
        navigate_path: '/execution/transportation-tasks/'
    },
    docs_business_partners: {
        navigate_path: '/master-data/business-partners/'
    },
    docs_transportation_orders: {
        navigate_path: '/orders-management/transportation-orders/'
    },
    docs_transportation_bookings: {
        navigate_path: '/execution/transportation-bookings/'
    },
    docs_agreements: {
        navigate_path: '/mutual-settlements/agreements/'
    },
    docs_products: {
        navigate_path: '/master-data/products/'
    },
    docs_locations: {
        navigate_path: '/master-data/locations/'
    },
    docs_gates: {
        navigate_path: '/master-data/gates/'
    },
    docs_rfq_requests: {
        navigate_path: '/mutual-settlements/rfq-requests/'
    },
    docs_decision_tables: {
        navigate_path: '/mutual-settlements/decision-tables/'
    },
    docs_transportation_cargo_units: {
        navigate_path: '/orders-management/transportation-cargo-units/'
    },
    docs_vehicle_groups: {
        navigate_path: '/master-data/vehicle-groups/'
    },
    docs_order_movements: {
        navigate_path: '/transactional-data/order-movements/'
    },
    docs_route_schedules: {
        navigate_path: '/transactional-data/route-schedules/'
    },
    docs_trip_groups: {
        navigate_path: '/transactional-data/trip-groups/'
    },
    docs_transportation_lanes: {
        navigate_path: '/master-data/transportation-lanes/'
    },
    docs_standard_routes: {
        navigate_path: '/master-data/standard-routes/'
    },
    docs_schedules: {
        navigate_path: '/master-data/schedules/'
    },
    docs_product_groups: {
        navigate_path: '/master-data/product-groups/'
    },
    docs_product_kinds: {
        navigate_path: '/master-data/product-kinds/'
    },
    docs_product_transport_categories: {
        navigate_path: '/master-data/product-transport-categories/'
    },
    docs_transportation_relations: {
        navigate_path: '/master-data/transportation-relations/'
    },
    docs_compatibilities: {
        navigate_path: '/master-data/compatibilities/'
    },
    docs_services: {
        navigate_path: '/master-data/services/'
    },
    docs_rate_services: {
        navigate_path: '/master-data/rate-services/'
    },
    docs_calculation_schemas: {
        navigate_path: '/mutual-settlements/calculation-schemas/'
    },
    docs_invoices: {
        navigate_path: '/mutual-settlements/invoices/'
    },
    docs_warehouse_zones: {
        navigate_path: '/warehouse-master-data/warehouse-zones/'
    },
    docs_warehouse_aisles: {
        navigate_path: '/warehouse-master-data/warehouse-aisles/'
    },
    docs_warehouse_rows: {
        navigate_path: '/warehouse-master-data/warehouse-rows/'
    },
    docs_warehouse_columns: {
        navigate_path: '/warehouse-master-data/warehouse-columns/'
    },
    docs_warehouse_levels: {
        navigate_path: '/warehouse-master-data/warehouse-levels/'
    },
    docs_warehouse_storage_location_sizes: {
        navigate_path: '/warehouse-master-data/warehouse-storage-location-sizes/'
    },
    docs_warehouse_storage_areas: {
        navigate_path: '/warehouse-master-data/warehouse-storage-areas/'
    },
    docs_warehouse_preparation_areas: {
        navigate_path: '/warehouse-master-data/warehouse-preparation-areas/'
    },
    docs_warehouse_access_areas: {
        navigate_path: '/warehouse-master-data/warehouse-access-areas/'
    },
    docs_warehouse_storage_locations: {
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/'
    },
    docs_warehouse_resource_groups: {
        navigate_path: '/warehouse-master-data/warehouse-resource-groups/'
    },
    docs_warehouse_resources: {
        navigate_path: '/warehouse-master-data/warehouse-resources/'
    },
    docs_warehouse_tare_groups: {
        navigate_path: '/warehouse-master-data/warehouse-tare-groups/'
    },
    docs_warehouse_tares: {
        navigate_path: '/warehouse-master-data/warehouse-tares/'
    },
    docs_product_skus: {
        navigate_path: '/warehouse-master-data/product-skus/'
    },
    docs_product_sku_batches: {
        navigate_path: '/warehouse-master-data/product-sku-batches/'
    },
    docs_product_sku_stamps: {
        navigate_path: '/warehouse-master-data/product-sku-stamps/'
    },
    docs_product_processing_groups: {
        navigate_path: '/warehouse-master-data/product-processing-groups/'
    },
    docs_warehouse_inbound_orders: {
        navigate_path: '/warehouse-docs/warehouse-inbound-orders/'
    },
    docs_warehouse_selection_waves: {
        navigate_path: '/warehouse-docs/warehouse-selection-waves/'
    },
    docs_warehouse_executor_tasks: {
        navigate_path: '/warehouse-docs/warehouse-executor-tasks/'
    },
    docs_warehouse_tasks: {
        navigate_path: '/warehouse-docs/warehouse-tasks/'
    },
    docs_quotas: {
        navigate_path: '/mutual-settlements/quotas/'
    },
    docs_etran_claims: {
        navigate_path: '/etran-rails/etran-claims/'
    },
    docs_etran_invoices: {
        navigate_path: '/etran-rails/etran-invoices/'
    },
    docs_etran_cars: {
        navigate_path: '/etran-rails/etran-cars/'
    },
    docs_etran_containers: {
        navigate_path: '/etran-rails/etran-containers/'
    },
    docs_etran_acts: {
        navigate_path: '/etran-rails/etran-acts/'
    },
    docs_administrative_areas: {
        navigate_path: '/master-data/administrative-areas/'
    },
    settings_warehouse_scheduler_commons: {
        navigate_path: '/warehouse-settings/warehouse_scheduler_commons/'
    },
    docs_transportation_resources: {
        navigate_path: '/master-data/transportation-resources/'
    }
};
// будем содрежать маппинг имен полей на ссылочную таблицу и поле в ней
export const Mapping: Record<string, ColumnReference> = {
    country_code: {
        referenced_table: 'dicts_countries',
        referenced_column: 'code',
        navigate_path: '/dictionaries/countries/',
        view_help: 'vdicts_countries'
    },

    role_code: {
        referenced_table: 'dicts_business_partner_roles',
        referenced_column: 'code',
        navigate_path: '/dictionaries/business-partner-roles',
        view_help: 'vdicts_business_partner_roles'
    },

    identifier_category_code: {
        referenced_table: 'dicts_identifier_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/identifier-categories',
        view_help: 'vdicts_identifier_categories'
    },

    relation_category_code: {
        referenced_table: 'dicts_relation_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaties/relation-categories',
        view_help: 'vdicts_relation_categories'
    },

    region: {
        referenced_table: 'dicts_regions',
        referenced_column: 'code',
        navigate_path: '/dictionaries/regions/',
        view_help: 'vdicts_regions'
    },

    parking_place_priority_code: {
        referenced_table: 'dicts_parking_place_priorities',
        referenced_column: 'code',
        navigate_path: '/dictionaries/parking-place-priorities/',
        view_help: 'vdicts_parking_place_priorities'
    },

    schedule_id: {
        referenced_column: 'id',
        referenced_table: 'docs_schedules',
        navigate_path: '/master-data/schedules/',
        view_help: 'vdocs_schedules'
    },

    schedule_key: {
        referenced_column: 'key',
        referenced_table: 'docs_schedules',
        navigate_path: '/master-data/schedules/',
        view_help: 'vdocs_schedules'
    },

    tag_category_code: {
        referenced_table: 'dicts_tag_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/tag-categories/',
        view_help: 'vdicts_tag_categories'
    },

    business_partner_id: {
        referenced_column: 'id',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },

    relation_business_partner_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },

    driver_business_partner_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners_with_roles',
        view_help_filter: [
            {
                column: 'role_code',
                operator: 'eq',
                value: 'DRIVER'
            }
        ]
    },
    organization_unit_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    suborganization_unit_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },

    first_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    delivery_location_id: {
        referenced_column: 'id',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    pickup_location_id: {
        referenced_column: 'id',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    quota_key: {
        referenced_column: 'key',
        referenced_table: 'docs_quotas',
        navigate_path: '/master-data/quotas/',
        view_help: 'vdocs_quotas'
    },
    last_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    business_partner_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    exhibitor_business_partner_id: {
        referenced_column: 'id',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    recipient_business_partner_id: {
        referenced_column: 'id',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },

    business_partner_role_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_business_partner_roles',
        navigate_path: '/dictionaries/business-partner-roles/',
        view_help: 'vdicts_business_partner_roles'
    },

    closing_condition_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_lot_closing_conditions',
        navigate_path: '/dictionaries/lot_closing_conditions/',
        view_help: 'vdicts_lot_closing_conditions'
    },

    business_partner_language_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_languages',
        navigate_path: '/dictionaries/languages/',
        view_help: 'vdicts_languages'
    },

    language_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_languages',
        navigate_path: '/dictionaries/languages/',
        view_help: 'vdicts_languages'
    },

    address_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_address_categories',
        navigate_path: '/dictionaries/address_categories/',
        view_help: 'vdicts_address_categories'
    },

    ownership_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_ownership_categories',
        navigate_path: '/dictionaries/ownership_categories/',
        view_help: 'vdicts_ownership_categories'
    },

    timezone_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_timezones',
        navigate_path: '/dictionaries/timezones/',
        view_help: 'vdicts_timezones'
    },

    request_timezone_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_timezones',
        navigate_path: '/dictionaries/timezones/',
        view_help: 'vdicts_timezones'
    },

    short_text_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_short_text_categories',
        navigate_path: '/dictionaries/short_text_categories/',
        view_help: 'vdicts_short_text_categories'
    },

    event_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_event_categories',
        navigate_path: '/dictionaries/event_categories/',
        view_help: 'vdicts_event_categories'
    },

    characteristic_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_characteristic_categories',
        navigate_path: '/dictionaries/characteristic_categories/',
        view_help: 'vdicts_characteristic_categories'
    },

    service_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_service_categories',
        navigate_path: '/dictionaries/service_categories/',
        view_help: 'vdicts_service_categories'
    },

    generation_method_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_generation_methods',
        navigate_path: '/dictionaries/generation_methods/',
        view_help: 'vdicts_generation_methods'
    },

    measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units'
    },

    location_id: {
        referenced_column: 'id',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    first_planned_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    last_planned_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    source_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    destination_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    unit_finish_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    unit_start_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },

    product_key: {
        referenced_column: 'key',
        referenced_table: 'docs_products',
        navigate_path: '/master-data/products/',
        view_help: 'vdocs_products'
    },

    product_id: {
        referenced_column: 'id',
        referenced_table: 'docs_products',
        navigate_path: '/master-data/products/',
        view_help: 'vdocs_products'
    },

    checkpoint_id: {
        referenced_column: 'id',
        referenced_table: 'docs_checkpoints',
        navigate_path: '/master-data/checkpoints/',
        view_help: 'vdocs_checkpoints'
    },

    warehouse_id: {
        referenced_column: 'id',
        referenced_table: 'docs_warehouses',
        navigate_path: '/master-data/warehouses/',
        view_help: 'vdocs_warehouses'
    },
    internal_warehouse_id: {
        referenced_column: 'id',
        referenced_table: 'docs_warehouses',
        navigate_path: '/master-data/warehouses/',
        view_help: 'vdocs_warehouses'
    },

    warehouse_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouses',
        navigate_path: '/master-data/warehouses/',
        view_help: 'vdocs_warehouses'
    },

    rfq_request_key: {
        referenced_column: 'key',
        referenced_table: 'docs_rfq_requests',
        navigate_path: '/mutual-settlements/rfq-requests/',
        view_help: 'vdocs_rfq_requests'
    },

    rfq_response_key: {
        referenced_column: 'key',
        referenced_table: 'docs_rfq_responses',
        navigate_path: '/mutual-settlements/rfq-responses/',
        view_help: 'vdocs_rfq_responses'
    },

    expeditor_id: {
        referenced_column: 'id',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    expeditor_business_partner_id: {
        referenced_column: 'id',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    expeditor_business_partner_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },

    transportation_order_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_orders',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders'
    },

    transportation_order_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_orders',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders'
    },
    executor_task_transportation_order_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_orders',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders'
    },

    transportation_trip_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_trips',
        navigate_path: '/transactional-data/transportation-trips/',
        view_help: 'vdocs_transportation_trips'
    },

    transportation_trip_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_trips',
        navigate_path: '/transactional-data/transportation-trips/',
        view_help: 'vdocs_transportation_trips'
    },

    trip_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_trips',
        navigate_path: '/transactional-data/transportation-trips/',
        view_help: 'vdocs_transportation_trips'
    },

    expeditor_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },

    parent_warehouse_id: {
        referenced_column: 'id',
        referenced_table: 'docs_warehouses',
        navigate_path: '/master-data/warehouses/',
        view_help: 'vdocs_warehouses'
    },

    parent_warehouse_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouses',
        navigate_path: '/master-data/warehouses/',
        view_help: 'vdocs_warehouses'
    },

    product_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_product_groups',
        navigate_path: '/master-data/product-groups/',
        view_help: 'vdocs_product_groups'
    },

    product_product_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_product_groups',
        navigate_path: '/master-data/product-groups/',
        view_help: 'vdocs_product_groups'
    },

    product_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_product_groups',
        navigate_path: '/master-data/product-groups/',
        view_help: 'vdocs_product_groups'
    },

    parent_product_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_product_groups',
        navigate_path: '/master-data/product-groups/',
        view_help: 'vdocs_product_groups'
    },

    parent_product_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_product_groups',
        navigate_path: '/master-data/product-groups/',
        view_help: 'vdocs_product_groups'
    },

    registry_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_registry_groups',
        navigate_path: '/master-data/registry-groups/',
        view_help: 'vdocs_registry_groups'
    },

    registry_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_registry_groups',
        navigate_path: '/master-data/registry-groups/',
        view_help: 'vdocs_registry_groups'
    },

    parent_registry_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_registry_groups',
        navigate_path: '/master-data/registry-groups/',
        view_help: 'vdocs_registry_groups'
    },

    parent_registry_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_registry_groups',
        navigate_path: '/master-data/registry-groups/',
        view_help: 'vdocs_registry_groups'
    },

    // TODO: тут возмодно docs_warehouse_schedules и подобное
    warehouse_schedule_id: {
        referenced_column: 'id',
        referenced_table: 'docs_schedules',
        navigate_path: '/master-data/schedules/',
        view_help: 'vdocs_schedules'
    },

    warehouse_schedule: {
        referenced_table: 'docs_schedules',
        referenced_column: 'id',
        navigate_path: '/master-data/schedules/',
        view_help: 'vdocs_schedules'
    },

    planned_net_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },

    actual_net_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },

    net_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },

    gross_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },
    delivery_item_gross_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },
    planned_gross_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },
    actual_gross_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },

    volume_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'VOLUME'
            }
        ]
    },
    delivery_item_volume_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'VOLUME'
            }
        ]
    },

    actual_volume_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'VOLUME'
            }
        ]
    },
    planned_volume_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'VOLUME'
            }
        ]
    },

    depth_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'DIMENSION'
            }
        ]
    },

    width_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'DIMENSION'
            }
        ]
    },

    height_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'DIMENSION'
            }
        ]
    },

    package_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_package_categories',
        navigate_path: '/dictionaries/package-categories/',
        view_help: 'vdicts_package_categories'
    },

    financial_perspective_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_financial_perspectives',
        navigate_path: '/dictionaries/financial-perspectives/',
        view_help: 'vdicts_financial_perspectives'
    },

    vehicle_unit_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_vehicle_unit_categories',
        navigate_path: '/dictionaries/vehicle_unit_categories/',
        view_help: 'vdicts_vehicle_unit_categories'
    },

    transportation_unit_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_units',
        navigate_path: '/orders-management/transportation-units/',
        view_help: 'vdocs_transportation_units'
    },

    warehouse_storage_location_id: {
        referenced_column: 'id',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },

    warehouse_storage_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },
    destination_warehouse_storage_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },

    source_warehouse_storage_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },

    parent_parking_place_id: {
        referenced_table: 'docs_parking_places',
        referenced_column: 'id',
        navigate_path: '/master-data/parking-places/',
        view_help: 'vdocs_parking_places'
    },

    parent_parking_place_key: {
        referenced_table: 'docs_parking_places',
        referenced_column: 'key',
        navigate_path: '/master-data/parking-places/',
        view_help: 'vdocs_parking_places'
    },

    parent_trip_group_key: {
        referenced_table: 'docs_trip_groups',
        referenced_column: 'key',
        navigate_path: '/transactional-data/trip-groups/',
        view_help: 'vdocs_trip_groups'
    },

    parent_product_sku_key: {
        referenced_table: 'docs_product_skus',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/product-skus/',
        view_help: 'vdocs_product_skus'
    },

    parent_product_sku_id: {
        referenced_table: 'docs_product_skus',
        referenced_column: 'id',
        navigate_path: '/warehouse-master-data/product-skus/',
        view_help: 'vdocs_product_skus'
    },

    product_sku_stamp_id: {
        referenced_table: 'docs_product_sku_stamps',
        referenced_column: 'id',
        navigate_path: '/warehouse-master-data/product-stamps/',
        view_help: 'vdocs_product_sku_stamps'
    },

    product_sku_stamp_key: {
        referenced_table: 'docs_product_sku_stamps',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/product-stamps/',
        view_help: 'vdocs_product_sku_stamps'
    },

    product_stamp_id: {
        referenced_table: 'docs_product_sku_stamps',
        referenced_column: 'id',
        navigate_path: '/warehouse-master-data/product-stamps/',
        view_help: 'vdocs_product_sku_stamps'
    },

    product_stamp_key: {
        referenced_table: 'docs_product_sku_stamps',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/product-stamps/',
        view_help: 'vdocs_product_sku_stamps'
    },

    product_sku_key: {
        referenced_table: 'docs_product_skus',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/product-skus/',
        view_help: 'vdocs_product_skus'
    },
    vehicle_group_key: {
        referenced_table: 'docs_vehicle_groups',
        referenced_column: 'key',
        navigate_path: '/master-data/vehicle-groups/',
        view_help: 'vdocs_vehicle_groups'
    },
    vehicle_group_id: {
        referenced_table: 'docs_vehicle_groups',
        referenced_column: 'id',
        navigate_path: '/master-data/vehicle-groups/',
        view_help: 'vdocs_vehicle_groups'
    },
    product_sku_parent_product_key: {
        referenced_column: 'key',
        referenced_table: 'docs_products',
        navigate_path: '/master-data/products/',
        view_help: 'vdocs_products'
    },

    product_sku_id: {
        referenced_table: 'docs_product_skus',
        referenced_column: 'id',
        navigate_path: '/warehouse-master-data/product-skus/',
        view_help: 'vdocs_product_skus'
    },

    warehouse_storage_location_operation_code: {
        referenced_table: 'dicts_warehouse_task_types',
        referenced_column: 'code',
        navigate_path: '/dictionaries/warehouse-task-types/',
        view_help: 'vdicts_warehouse_task_types'
    },

    warehouse_tare_group_key: {
        referenced_table: 'docs_warehouse_tare_groups',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tare-groups/',
        view_help: 'vdocs_warehouse_tare_groups'
    },

    product_sku_batch_key: {
        referenced_table: 'docs_product_sku_batches',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/product-sku-batches/',
        view_help: 'vdocs_product_sku_batches'
    },

    arrived_in_level_3_tare_id: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'id',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    arrived_in_level_3_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    level_2_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_2'
            }
        ]
    },

    source_warehouse_level_2_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_2'
            }
        ]
    },

    destination_warehouse_level_2_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_2'
            }
        ]
    },

    level_2_tare_group_key: {
        referenced_table: 'docs_warehouse_tare_groups',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tare-groups/',
        view_help: 'vdocs_warehouse_tare_groups',
        view_help_filter: [
            {
                column: 'level_code',
                operator: 'eq',
                value: 'LEVEL_2'
            }
        ]
    },

    warehouse_level_2_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_2'
            }
        ]
    },

    level_3_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    source_warehouse_level_3_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    destination_warehouse_level_3_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    level_3_tare_group_key: {
        referenced_table: 'docs_warehouse_tare_groups',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tare-groups/',
        view_help: 'vdocs_warehouse_tare_groups',
        view_help_filter: [
            {
                column: 'level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    warehouse_level_3_tare_key: {
        referenced_table: 'docs_warehouse_tares',
        referenced_column: 'key',
        navigate_path: '/warehouse-master-data/warehouse-tares/',
        view_help: 'vdocs_warehouse_tares',
        view_help_filter: [
            {
                column: 'warehouse_tare_group_level_code',
                operator: 'eq',
                value: 'LEVEL_3'
            }
        ]
    },

    product_stock_category_code: {
        referenced_table: 'dicts_product_stock_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/product-stock-categories/',
        view_help: 'vdicts_product_stock_categories'
    },

    allowed_product_stock_category_code: {
        referenced_table: 'dicts_product_stock_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/product-stock-categories/',
        view_help: 'vdicts_product_stock_categories'
    },
    stop_category_code: {
        referenced_table: 'dicts_stop_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/stop-categories/',
        view_help: 'vdicts_stop_categories'
    },

    package_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'PACKAGE'
            }
        ]
    },

    product_package_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'PACKAGE'
            }
        ]
    },

    agreement_kind_code: {
        referenced_table: 'dicts_agreement_kind',
        referenced_column: 'code',
        navigate_path: '/dictionaries/agreement-kind/',
        view_help: 'vdicts_agreement_kind'
    },
    transport_kind_code: {
        referenced_table: 'dicts_transport_kinds',
        referenced_column: 'code',
        navigate_path: '/dictionaries/transport-kinds/',
        view_help: 'vdicts_transport_kinds'
    },
    special_condition_code: {
        referenced_table: 'dicts_special_conditions',
        referenced_column: 'code',
        navigate_path: '/dictionaries/special-conditions/',
        view_help: 'vdicts_special_conditions'
    },
    first_trip_stop_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },

    last_trip_stop_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },

    currency_rate_category_code: {
        referenced_table: 'dicts_currency_rates_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/currency-rates-categories/',
        view_help: 'vdicts_currency_rates_categories'
    },

    calculation_currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/currencies/',
        view_help: 'vdicts_currencies'
    },
    calculation_status_code: {
        referenced_table: 'dicts_calculation_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/calculation_statuses/',
        view_help: 'vdicts_calculation_statuses'
    },
    rounding_profile_code: {
        referenced_table: 'dicts_rounding_rules',
        referenced_column: 'code',
        navigate_path: '/dictionaries/rounding-rules/',
        view_help: 'vdicts_rounding_rules'
    },
    compatibility_characteristic_code: {
        referenced_table: 'dicts_compatibility_characteristics',
        referenced_column: 'code',
        navigate_path: '/dictionaries/compatibility-characteristics/',
        view_help: 'vdicts_compatibility_characteristics'
    },
    calculation_solution_base_code: {
        referenced_table: 'dicts_calculation_solution_base',
        referenced_column: 'code',
        navigate_path: '/dictionaries/calculation-solution-base/',
        view_help: 'vdicts_calculation_solution_base'
    },
    calculation_base_code: {
        referenced_table: 'dicts_calculation_base',
        referenced_column: 'code',
        navigate_path: '/dictionaries/calculation-base/',
        view_help: 'vdicts_calculation_base'
    },
    search_sign_code: {
        referenced_table: 'dicts_search_sign',
        referenced_column: 'code',
        navigate_path: '/dictionaries/search-sign/',
        view_help: 'vdicts_search_sign'
    },
    tax_rate_code: {
        referenced_table: 'dicts_tax_rates',
        referenced_column: 'code',
        navigate_path: '/dictionaries/tax-rates/',
        view_help: 'vdicts_tax_rates'
    },
    tax_code: {
        referenced_table: 'dicts_tax_rates',
        referenced_column: 'code',
        navigate_path: '/dictionaries/tax-rates/',
        view_help: 'vdicts_tax_rates'
    },
    payment_term_code: {
        referenced_table: 'dicts_payment_terms',
        referenced_column: 'code',
        navigate_path: '/dictionaries/payment-terms/',
        view_help: 'vdicts_payment_terms'
    },
    kind_position_code: {
        referenced_table: 'dicts_kind_positions',
        referenced_column: 'code',
        navigate_path: '/dictionaries/kind-positions/',
        view_help: 'vdicts_kind_positions'
    },
    cost_kind_code: {
        referenced_table: 'dicts_element_costs',
        referenced_column: 'code',
        navigate_path: '/dictionaries/element-costs/',
        view_help: 'vdicts_element_costs'
    },
    calculation_schema_id: {
        referenced_column: 'id',
        referenced_table: 'docs_calculation_schemas',
        navigate_path: '/mutual-settlements/calculation-schemas/',
        view_help: 'vdocs_calculation_schemas'
    },
    calculation_schema_key: {
        referenced_column: 'key',
        referenced_table: 'docs_calculation_schemas',
        navigate_path: '/mutual-settlements/calculation-schemas/',
        view_help: 'vdocs_calculation_schemas'
    },
    agreement_id: {
        referenced_column: 'id',
        referenced_table: 'docs_agreements',
        navigate_path: '/mutual-settlements/agreements/',
        view_help: 'vdocs_agreements'
    },
    charge_agreement_id: {
        referenced_column: 'id',
        referenced_table: 'docs_agreements',
        navigate_path: '/mutual-settlements/agreements/',
        view_help: 'vdocs_agreements'
    },
    charge_agreement_key: {
        referenced_column: 'key',
        referenced_table: 'docs_agreements',
        navigate_path: '/mutual-settlements/agreements/',
        view_help: 'vdocs_agreements'
    },
    charge_agreement_item_id: {
        referenced_column: 'id',
        referenced_table: 'docs_agreement_items',
        navigate_path: '/mutual-settlements/agreements/',
        view_help: 'vdocs_agreement_items'
    },
    agreement_key: {
        referenced_column: 'key',
        referenced_table: 'docs_agreements',
        navigate_path: '/mutual-settlements/agreements/',
        view_help: 'vdocs_agreements'
    },
    required_vehicle_type_code: {
        referenced_table: 'dicts_vehicle_types',
        referenced_column: 'code',
        navigate_path: '/dictionaries/vehicle-types/',
        view_help: 'vdicts_vehicle_types'
    },

    flow_code: {
        referenced_table: 'dicts_flows',
        referenced_column: 'code',
        navigate_path: '/dictionaries/flows/',
        view_help: 'vdicts_flows'
    },
    direction_category_code: {
        referenced_table: 'dicts_direction_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/direction-categories/',
        view_help: 'vdicts_direction_categories'
    },
    parent_transportation_request_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_requests',
        navigate_path: '/orders-management/transportation-requests/',
        view_help: 'vdocs_transportation_requests'
    },
    parent_transportation_request_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_requests',
        navigate_path: '/orders-management/transportation-requests/',
        view_help: 'vdocs_transportation_requests'
    },
    parent_transportation_trip_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_trips',
        navigate_path: '/transactional-data/transportation-trips/',
        view_help: 'vdocs_transportation_trips'
    },

    activity_type_code: {
        referenced_table: 'dicts_transportation_task_types',
        referenced_column: 'code',
        navigate_path: '/dictionaries/transportation-task-types/',
        view_help: 'vdicts_transportation_task_types'
    },
    transportation_request_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_requests',
        navigate_path: '/orders-management/transportation-requests/',
        view_help: 'vdocs_transportation_requests'
    },
    transportation_request_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_requests',
        navigate_path: '/orders-management/transportation-requests/',
        view_help: 'vdocs_transportation_requests'
    },
    currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/currencies/',
        view_help: 'vdicts_currencies'
    },
    parent_transportation_order_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_orders',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders'
    },
    parent_transportation_order_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_orders',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders'
    },
    transportation_unit_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_units',
        navigate_path: '/orders-management/transportation-units/',
        view_help: 'vdocs_transportation_units'
    },
    parent_transportation_unit_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_units',
        navigate_path: '/orders-management/transportation-units/',
        view_help: 'vdocs_transportation_units'
    },
    mega_transportation_unit_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_units',
        navigate_path: '/orders-management/transportation-units/',
        view_help: 'vdocs_transportation_units'
    },
    reg_country_code: {
        referenced_table: 'dicts_countries',
        referenced_column: 'code',
        navigate_path: '/dictionaries/countries/',
        view_help: 'vdicts_countries'
    },
    legal_form_code: {
        referenced_table: 'dicts_business_partner_legal_forms',
        referenced_column: 'code',
        navigate_path: '/dictionaries/business-partner-legal-forms/',
        view_help: 'vdicts_business_partner_legal_forms'
    },
    group_kind_code: {
        referenced_table: 'dicts_group_kinds',
        referenced_column: 'code',
        navigate_path: '/dictionaries/group-kind/',
        view_help: 'vdicts_group_kinds'
    },
    warehouse_category_code: {
        referenced_table: 'dicts_warehouse_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/warehouse-categories/',
        view_help: 'vdicts_warehouse_categories'
    },
    gate_category_code: {
        referenced_table: 'dicts_gate_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/gate-categories/',
        view_help: 'vdicts_gate_categories'
    },
    main_metric_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units'
    },
    main_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units'
    },
    expiration_date_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units'
    },
    measure_unit_definition_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units'
    },
    alter_metric_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/measure-units/',
        view_help: 'vdicts_measure_units'
    },
    parent_gate_id: {
        referenced_column: 'id',
        referenced_table: 'docs_gates',
        navigate_path: '/master-data/gates/',
        view_help: 'vdocs_gates'
    },
    parent_gate_key: {
        referenced_column: 'key',
        referenced_table: 'docs_gates',
        navigate_path: '/master-data/gates/',
        view_help: 'vdocs_gates'
    },
    source_route_schedule_id: {
        referenced_column: 'key',
        referenced_table: 'docs_route_schedules',
        navigate_path: '/transactional-data/route-schedules/',
        view_help: 'vdocs_route_schedules'
    },
    source_route_schedule_key: {
        referenced_column: 'key',
        referenced_table: 'docs_route_schedules',
        navigate_path: '/transactional-data/route-schedules/',
        view_help: 'vdocs_route_schedules'
    },

    exhibitor_business_partner_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    recipient_business_partner_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners'
    },
    // Etran заявка
    claimid: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_requests',
        navigate_path: '/orders-management/transportation-requests/',
        view_help: 'vdocs_transportation_requests'
    },
    claimstateid: {
        referenced_column: 'id',
        referenced_table: 'dicts_claim_states',
        navigate_path: '/transactional-data/claim-states/',
        view_help: 'vdicts_claim_states'
    },
    clmsenderid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    clmsenderaddressid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    clmsendkindid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    clmmsgkindid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    clmfromcountrycode: {
        referenced_table: 'dicts_country_etran',
        referenced_column: 'code',
        navigate_path: '/dictionaries/country-etran/',
        view_help: 'vdicts_country_etran'
    },
    clmfromstationcode: {
        referenced_table: 'dicts_etran_station',
        referenced_column: 'code',
        navigate_path: '/dictionaries/etran-station/'
        //    view_help: 'vdicts_etran_station', нет вью
    },
    clmenterstationcode: {
        referenced_table: 'dicts_etran_station',
        referenced_column: 'code',
        navigate_path: '/dictionaries/etran-station/'
        //    view_help: 'vdicts_etran_station', нет вью
    },
    clmfreightgroupcode: {
        referenced_table: 'dicts_etran_sum_freight',
        referenced_column: 'code',
        navigate_path: '/dictionaries/etran-SUM-FREIGHT/'
        //    view_help: 'vdicts_etran_sum_freight', нет вью
    },
    clmcarownertypeid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_cl_prizvag',
        navigate_path: '/dictionaries/etran-CL-PRIZVAG/'
        //    view_help: 'vdicts_etran_cl_prizvag', нет вью
    },
    clmtypepodid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_common15',
        navigate_path: '/dictionaries/etran-COMMON15/'
        //    view_help: 'vdicts_etran_common15', нет вью
    },
    clmloadwayownerid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    clmloadwayowneraddrid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    clmsenderagentid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    claimneedforecp_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_need_for_ecp',
        navigate_path: '/dictionaries/need-for-ecp/',
        view_help: 'vdicts_need_for_ecp'
    },
    clmloadwayownersogltype_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_agreement_owner_driveway_categories',
        navigate_path: '/dictionaries/agreement-owner-driveway-categories/',
        view_help: 'vdicts_agreement_owner_driveway_categories'
    },
    clmplantype_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_plan_categories',
        navigate_path: '/dictionaries/plan-categories/',
        view_help: 'vdicts_plan_categories'
        // не понял что тут должно быть
    },
    // Etran накладная
    invoiceid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invstateid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invfromcountrycode: {
        referenced_column: 'code',
        referenced_table: 'dicts_countries',
        navigate_path: '/dictionaries/countries/',
        view_help: 'vdicts_countries'
        // не понял что тут должно быть
    },
    invtocountrycode: {
        referenced_column: 'code',
        referenced_table: 'dicts_countries',
        navigate_path: '/dictionaries/countries/',
        view_help: 'vdicts_countries'
        // не понял что тут должно быть
    },
    invfromstationcode: {
        referenced_table: 'dicts_etran_station',
        referenced_column: 'code',
        navigate_path: '/dictionaries/etran-station/'
        //    view_help: 'vdicts_etran_station', нет вью
    },
    invrecipid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invrecipaddressid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invtopointcode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invtostationcode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invixtariffcode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invplancartypeid: {
        referenced_table: 'dicts_etran_wag_type',
        referenced_column: 'id',
        navigate_path: '/dictionaries/etran-wag-type/'
        //    view_help: 'vdicts_etran_station', нет вью
    },
    invplancarownertypeid: {
        referenced_table: 'dicts_etran_common7',
        referenced_column: 'id',
        navigate_path: '/dictionaries/etran-common7/'
        //    view_help: 'vdicts_etran_station', нет вью
    },
    invloadassetsid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invplanoutsizecode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invpayplacerwcode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invpayerid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invfrwsubcode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },

    invscaletypeid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_inv_scale_type_nsi',
        navigate_path: '/dictionaries/etran-inv-scale-type-nsi/'
        // нет вью
    },
    invtostationemptycode: {
        referenced_column: 'code',
        referenced_table: 'dicts_etran_station',
        navigate_path: '/dictionaries/etran-station/'
        // нет вью
    },
    invroutespfdocid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invrtid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invsenderid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invclaimid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invscalepersonid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_common101',
        navigate_path: '/dictionaries/etran-common101/'
        // нет вью
    },
    invcheckdepid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_common106',
        navigate_path: '/dictionaries/etran-common106/'
        // нет вью
    },
    invsendspeedid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_inv_speed_kind',
        navigate_path: '/dictionaries/etran-inv-speed-kind/'
        // нет вью
    },
    invpayplaceid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_inv_pay_place',
        navigate_path: '/dictionaries/etran-inv-pay-place/'
        // нет вью
    },
    invpayformid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_inv_pay_form',
        navigate_path: '/dictionaries/etran-inv-pay-form/'
        // нет вью
    },
    invplancartypecode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invloadtypeid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_common122',
        navigate_path: '/dictionaries/etran-common122/'
        // нет вью
    },
    invavcurrencyid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_common12',
        navigate_path: '/dictionaries/etran-common12/'
        // нет вью
    },
    invpayercode: {
        referenced_column: 'code'
        // не понял что тут должно быть
    },
    invsenderaddressid: {
        referenced_column: 'id'
        // не понял что тут должно быть
    },
    invsendkindid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_inv_send_type',
        navigate_path: '/dictionaries/etran-inv-send-type/'
        // нет вью
    },
    invtypeid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_common147',
        navigate_path: '/dictionaries/etran-common147/'
        // нет вью
    },
    invdispkindid: {
        referenced_column: 'id',
        referenced_table: 'dicts_etran_inv_disp_kind',
        navigate_path: '/dictionaries/etran-inv-disp-kind/'
        // нет вью
    },
    invneedforecp_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_need_for_ecp',
        navigate_path: '/dictionaries/need-for-ecp/',
        view_help: 'vdicts_need_for_ecp'
    },
    // WMS
    sorting_location_in_task_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_sorting_location_in_tasks',
        navigate_path: '/dictionaries/warehouse-sorting-location-in-tasks/',
        view_help: 'vdicts_warehouse_sorting_location_in_tasks'
    },
    user_starting_path_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_user_starting_paths',
        navigate_path: '/dictionaries/warehouse-user-starting-paths/',
        view_help: 'vdicts_warehouse_user_starting_paths'
    },
    warehouse_storage_mode_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_storage_modes',
        navigate_path: '/dictionaries/warehouse-storage-modes/',
        view_help: 'vdicts_warehouse_storage_modes'
    },
    warehouse_zone_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_zones',
        navigate_path: '/warehouse-master-data/warehouse-zones/',
        view_help: 'vdocs_warehouse_zones'
    },
    buffer_in_warehouse_storage_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },
    buffer_out_warehouse_storage_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },
    warehouse_aisle_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_aisles',
        navigate_path: '/warehouse-master-data/warehouse-aisles/',
        view_help: 'vdocs_warehouse_aisles'
    },
    warehouse_row_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_rows',
        navigate_path: '/warehouse-master-data/warehouse-rows/',
        view_help: 'vdocs_warehouse_rows'
    },
    warehouse_column_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_columns',
        navigate_path: '/warehouse-master-data/warehouse-columns/',
        view_help: 'vdocs_warehouse_columns'
    },
    weight_capacity_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },
    // parent_id: {
    //     referenced_column: 'id'
    //     // TODO: должно быть уникаольное поведение взависимости откуда вызывается в wms, участок подготовки, группа ресурсов, ресурсы
    // },
    warehouse_level_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_levels',
        navigate_path: '/warehouse-master-data/warehouse-levels/',
        view_help: 'vdocs_warehouse_levels'
    },
    warehouse_functional_purpose_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_functional_purposes',
        navigate_path: '/dictionaries/warehouse-functional-purposes/',
        view_help: 'vdicts_warehouse_functional_purposes'
    },
    warehouse_storage_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_storage_categories',
        navigate_path: '/dictionaries/warehouse-storage-categories/',
        view_help: 'vdicts_warehouse_storage_categories'
    },
    gate_id: {
        referenced_column: 'id',
        referenced_table: 'docs_gates',
        navigate_path: '/master-data/gates/',
        view_help: 'vdocs_gates'
    },
    gate_key: {
        referenced_column: 'key',
        referenced_table: 'docs_gates',
        navigate_path: '/master-data/gates/',
        view_help: 'vdocs_gates'
    },
    warehouse_storage_area_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_areas',
        navigate_path: '/warehouse-master-data/warehouse-storage-areas/',
        view_help: 'vdocs_warehouse_storage_areas'
    },
    warehouse_preparation_area_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_preparation_areas',
        navigate_path: '/warehouse-master-data/warehouse-preparation-areas/',
        view_help: 'vdocs_warehouse_preparation_areas'
    },
    warehouse_storage_location_size_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_location_sizes',
        navigate_path: '/warehouse-master-data/storage-location-sizes/',
        view_help: 'vdocs_warehouse_storage_location_sizes'
    },
    level_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_tare_group_levels',
        navigate_path: '/dictionaries/warehouse-tare-group-levels/',
        view_help: 'vdicts_warehouse_tare_group_levels'
    },
    parent_product_key: {
        referenced_column: 'key',
        referenced_table: 'docs_products',
        navigate_path: '/master-data/products/',
        view_help: 'vdocs_products'
    },
    turnover_class_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_turnover_classes',
        navigate_path: '/dictionaries/turnover-classes/',
        view_help: 'vdicts_turnover_classes'
    },
    // settings
    daily_quote_volume_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'VOLUME'
            }
        ]
    },
    daily_quote_weight_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'WEIGHT'
            }
        ]
    },
    daily_quote_quantity_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'PACKAGE'
            }
        ]
    },
    limit_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units'
    },
    approval_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_approval'
    },
    return_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_return'
    },
    accounting_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_accounting'
    },
    agreement_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_agreement'
    },
    assignment_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_assignment'
    },
    planning_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_planning'
    },

    booking_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_booking'
    },

    lifecycle_status_code: {
        referenced_table: 'dicts_statuses',
        referenced_column: 'code',
        navigate_path: '/dictionaries/status/',
        view_help: 'vstatuses_lifecycle'
    },
    etran_claim_id: {
        referenced_column: 'id',
        referenced_table: 'docs_etran_claims',
        navigate_path: '/etran-rails/etran-claims/',
        view_help: 'vdocs_etran_claims'
    },
    etran_claim_key: {
        referenced_column: 'key',
        referenced_table: 'docs_etran_claims',
        navigate_path: '/etran-rails/etran-claims/',
        view_help: 'vdocs_etran_claims'
    },
    etran_invoice_id: {
        referenced_column: 'id',
        referenced_table: 'docs_etran_invoices',
        navigate_path: '/etran-rails/etran-invoices/',
        view_help: 'vdocs_etran_invoices'
    },
    etran_invoice_key: {
        referenced_column: 'key',
        referenced_table: 'docs_etran_invoices',
        navigate_path: '/etran-rails/etran-invoices/',
        view_help: 'vdocs_etran_invoices'
    },
    invoice_id: {
        referenced_column: 'id',
        referenced_table: 'docs_invoices',
        navigate_path: '/mutual-settlements/invoices/',
        view_help: 'vdocs_invoices'
    },
    invoice_key: {
        referenced_column: 'key',
        referenced_table: 'docs_invoices',
        navigate_path: '/mutual-settlements/invoices/',
        view_help: 'vdocs_invoices'
    },
    vehicle_key: {
        referenced_column: 'key',
        referenced_table: 'docs_vehicles',
        navigate_path: '/master-data/vehicles/',
        view_help: 'vdocs_vehicles'
    },
    vehicle_id: {
        referenced_column: 'id',
        referenced_table: 'docs_vehicles',
        navigate_path: '/master-data/vehicles/',
        view_help: 'vdocs_vehicles'
    },
    vehicles_key: {
        referenced_column: 'key',
        referenced_table: 'docs_vehicles',
        navigate_path: '/master-data/vehicles/',
        view_help: 'vdocs_vehicles'
    },
    charter_cost_currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-currencies/',
        view_help: 'vdicts_currencies'
    },
    charter_cost_distance_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-measure-unit-categories/',
        view_help: 'vdicts_measure_units'
    },
    product_cost_currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-currencies/',
        view_help: 'vdicts_currencies'
    },
    product_cost_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-measure-unit-categories/',
        view_help: 'vdicts_measure_units'
    },
    vehicle_cost_currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-currencies/',
        view_help: 'vdicts_currencies'
    },
    vehicle_cost_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-measure-unit-categories/',
        view_help: 'vdicts_measure_units'
    },
    min_vehicle_cost_currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-currencies/',
        view_help: 'vdicts_currencies'
    },
    min_vehicle_cost_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-measure-unit-categories/',
        view_help: 'vdicts_measure_units'
    },
    variable_product_cost_currency_code: {
        referenced_table: 'dicts_currencies',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-currencies/',
        view_help: 'vdicts_currencies'
    },
    variable_product_cost_measure_unit_code: {
        referenced_table: 'dicts_measure_units',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-measure-unit-categories/',
        view_help: 'vdicts_measure_units'
    },
    definition_source_code: {
        referenced_table: 'dicts_definition_source',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-definition-source/',
        view_help: 'vdicts_definition_source'
    },
    vehicle_type_code: {
        referenced_table: 'dicts_vehicle_types',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-vehicle-types/',
        view_help: 'vdicts_vehicle_types'
    },
    vehicle_category_code: {
        referenced_table: 'dicts_vehicle_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts_vehicle_categories/',
        view_help: 'vdicts_vehicle_categories'
    },
    route_stop_category_code: {
        referenced_table: 'dicts_route_stop_categories',
        referenced_column: 'code',
        navigate_path: '/dictionaries/dicts-route-stop-categories/',
        view_help: 'vdicts_route_stop_categories'
    },

    transportation_unit_ids: {
        referenced_table: 'docs_transportation_units',
        referenced_column: 'code',
        navigate_path: '/orders-management/transportation-units/',
        view_help: 'vdocs_transportation_units'
    },

    transportation_order_ids: {
        referenced_table: 'docs_transportation_orders',
        referenced_column: 'code',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders'
    },
    turnover_classes: {
        referenced_table: 'dicts_turnover_classes',
        referenced_column: 'code',
        navigate_path: '/transactional-data/dicts-turnover-classes/',
        view_help: 'vdicts_turnover_classes'
    },
    transportation_request_ids: {
        referenced_table: 'docs_transportation_requests',
        referenced_column: 'id',
        navigate_path: '/orders-management/transportation-requests/',
        view_help: 'vdocs_transportation_requests'
    },
    track_cargo_unit_code: {
        referenced_table: 'dicts_track_cargo_units',
        referenced_column: 'code',
        view_help: 'vdicts_track_cargo_units',
        navigate_path: '/dictionaries/dicts-track-cargo-units/'
    },

    quantity_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'PACKAGE'
            }
        ]
    },

    product_quantity_measure_unit_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_units',
        navigate_path: '/dictionaries/measure_units/',
        view_help: 'vdicts_measure_units',
        view_help_filter: [
            {
                column: 'measure_unit_category_code',
                operator: 'eq',
                value: 'PACKAGE'
            }
        ]
    },

    tare_type_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_tare_types',
        navigate_path: '/dictionaries/warehouse-tare-types/',
        view_help: 'vdicts_warehouse_tare_types'
    },

    product_processing_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_product_processing_groups',
        navigate_path: '/warehouse-master-data/product-processing-groups/',
        view_help: 'vdocs_product_processing_groups'
    },

    product_processing_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_product_processing_groups',
        navigate_path: '/warehouse-master-data/product-processing-groups/',
        view_help: 'vdocs_product_processing_groups'
    },

    product_placement_availability_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_product_placement_availabilities',
        navigate_path: '/dictionaries/warehouse-product-placement-availabilities/',
        view_help: 'vdicts_warehouse_product_placement_availabilities'
    },
    storage_location_placement_target_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_storage_location_placement_targets',
        navigate_path: '/dictionaries/warehouse-storage-location-placement-targets/',
        view_help: 'vdicts_warehouse_storage_location_placement_targets'
    },

    placement_rule_for_pallet_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_placement_rule_for_pallets',
        navigate_path: '/dictionaries/warehouse-placement-rule-for-pallets/',
        view_help: 'vdicts_warehouse_placement_rule_for_pallets'
    },

    placement_rule_for_same_product_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_placement_rule_for_pallets',
        navigate_path: '/dictionaries/warehouse-placement-rule-for-pallets/',
        view_help: 'vdicts_warehouse_placement_rule_for_pallets'
    },

    placement_rule_for_different_batches_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_placement_rule_for_pallets',
        navigate_path: '/dictionaries/warehouse-placement-rule-for-pallets/',
        view_help: 'vdicts_warehouse_placement_rule_for_pallets'
    },

    source_storage_location_selection_rule_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_selection_rule_source_storage_locations',
        navigate_path: '/dictionaries/warehouse-selection-rule-source-storage-locations/',
        view_help: 'vdicts_warehouse_selection_rule_source_storage_locations'
    },

    level_2_tare_rule_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_replenishment_rule_for_pallets',
        navigate_path: '/dictionaries/warehouse-replenishment-rule-for-pallets/',
        view_help: 'vdicts_warehouse_replenishment_rule_for_pallets'
    },

    warehouse_task_type_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_task_types',
        navigate_path: '/dictionaries/warehouse-operation-types/',
        view_help: 'vdicts_warehouse_task_types'
    },

    order_type_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_transportation_order_types',
        navigate_path: '/dictionaries/transportation-order-types/',
        view_help: 'vdicts_transportation_order_types'
    },

    warehouse_resource_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_resource_groups',
        navigate_path: '/warehouse-master-data/warehouse-resource-groups/',
        view_help: 'vdocs_warehouse_resource_groups'
    },

    warehouse_resource_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_warehouse_resource_groups',
        navigate_path: '/warehouse-master-data/warehouse-resource-groups/',
        view_help: 'vdocs_warehouse_resource_groups'
    },

    warehouse_access_area_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_access_areas',
        navigate_path: '/warehouse-master-data/warehouse-access-areas/',
        view_help: 'vdocs_warehouse_access_areas'
    },
    outbound_order_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_orders',
        navigate_path: '/orders-management/transportation-orders/',
        view_help: 'vdocs_transportation_orders',
        view_help_filter: [
            {
                column: 'flow_code',
                operator: 'eq',
                value: 'OUT'
            }
        ]
    },

    tare_group_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_tare_groups',
        navigate_path: '/warehouse-master-data/warehouse-tare-groups/',
        view_help: 'vdocs_warehouse_tare_groups'
    },
    wslocation_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_storage_locations',
        navigate_path: '/warehouse-master-data/warehouse-storage-locations/',
        view_help: 'vdocs_warehouse_storage_locations'
    },
    wslocation_warehouse_functional_purpose_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_functional_purposes',
        navigate_path: '/dictionaries/warehouse-functional-purposes/',
        view_help: 'vdicts_warehouse_functional_purposes'
    },
    wslocation_warehouse_storage_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_storage_categories',
        navigate_path: '/dictionaries/warehouse-storage-categories/',
        view_help: 'vdicts_warehouse_storage_categories'
    },
    wslocation_product_key: {
        referenced_column: 'key',
        referenced_table: 'docs_products',
        navigate_path: '/master-data/products/',
        view_help: 'vdocs_products'
    },

    activation_state_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_modules_activation_states',
        navigate_path: '/dictionaries/warehouse-modules-activation-states/',
        view_help: 'vdicts_warehouse_modules_activation_states'
    },

    displacement_criteria_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_displacement_criteries',
        navigate_path: '/dictionaries/warehouse-displacement-criteries/',
        view_help: 'vdicts_warehouse_displacement_criteries'
    },

    tsd_planned_quantity_output_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_planned_quantity_criteries',
        navigate_path: '/dictionaries/warehouse-planned-quantity-criteries/',
        view_help: 'vdicts_warehouse_planned_quantity_criteries'
    },

    inventory_task_entity_code: {
        referenced_column: 'code',
        referenced_table: 'dics_warehouse_scheduler_inventory_entity_objects',
        navigate_path: '/dictionaries/warehouse-scheduler-inventory-entity-objects/',
        view_help: 'vdics_warehouse_scheduler_inventory_entity_objects'
    },

    remains_product_characteristic_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_warehouse_scheduler_task_characteristics',
        navigate_path: '/dictionaries/warehouse-scheduler-task-characteristics/',
        view_help: 'vdicts_warehouse_scheduler_task_characteristics'
    },
    warehouse_task_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_tasks',
        navigate_path: '/warehouse-docs/warehouse-tasks/',
        view_help: 'vdocs_warehouse_tasks'
    },

    executor_task_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouse_executor_tasks',
        navigate_path: '/warehouse-docs/warehouse-executor-tasks/',
        view_help: 'vdocs_warehouse_executor_tasks'
    },

    trip_start_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    start_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    finish_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    trip_finish_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },
    product_batch_key: {
        referenced_column: 'key',
        referenced_table: 'docs_product_sku_batches',
        navigate_path: '/warehouse-master-data/product-sku-batches/',
        view_help: 'vdocs_product_sku_batches'
    },
    product_sku_package_id: {
        referenced_column: 'id',
        referenced_table: 'docs_product_sku_packages',
        view_help: 'vdocs_product_sku_packages'
    },
    product_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_product_categories',
        navigate_path: '/dictionaries/product-categories/',
        view_help: 'vdicts_product_categories'
    },
    package_metric_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_package_metric_categories',
        navigate_path: '/dictionaries/package-metric-categories/',
        view_help: 'vdicts_package_metric_categories'
    },
    measure_unit_category_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_measure_unit_categories',
        navigate_path: '/dictionaries/dicts_measure_unit_categories',
        view_help: 'vdicts_measure_unit_categories'
    },
    last_task_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_tasks',
        navigate_path: '/execution/transportation-tasks/',
        view_help: 'vdocs_transportation_tasks'
    },
    task_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_tasks',
        navigate_path: '/execution/transportation-tasks/',
        view_help: 'vdocs_transportation_tasks'
    },
    last_task_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_tasks',
        navigate_path: '/execution/transportation-tasks/',
        view_help: 'vdocs_transportation_tasks'
    },
    last_task_location_key: {
        referenced_column: 'key',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },

    last_task_location_id: {
        referenced_column: 'id',
        referenced_table: 'docs_locations',
        navigate_path: '/master-data/locations/',
        view_help: 'vdocs_locations'
    },

    internal_warehouse_key: {
        referenced_column: 'key',
        referenced_table: 'docs_warehouses',
        navigate_path: '/master-data/warehouses/',
        view_help: 'vdocs_warehouses'
    },
    responsible_person_id: {
        referenced_column: 'id',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners',
        view_help_filter: [
            {
                column: 'role_code',
                operator: 'eq',
                value: 'RESPONSIBLE_PERSON'
            }
        ]
    },

    service_id: {
        referenced_column: 'id',
        referenced_table: 'docs_services',
        navigate_path: '/master-data/services/',
        view_help: 'vdocs_services'
    },
    service_key: {
        referenced_column: 'key',
        referenced_table: 'docs_services',
        navigate_path: '/master-data/services/',
        view_help: 'vdocs_services'
    },

    transportation_lane_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_lanes',
        navigate_path: '/master-data/transportation-lanes/',
        view_help: 'vdocs_transportation_lanes'
    },
    transportation_lane_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_lanes',
        navigate_path: '/master-data/transportation-lanes/',
        view_help: 'vdocs_transportation_lanes'
    },

    responsible_person_key: {
        referenced_column: 'key',
        referenced_table: 'docs_business_partners',
        navigate_path: '/master-data/business-partners/',
        view_help: 'vdocs_business_partners',
        view_help_filter: [
            {
                column: 'role_code',
                operator: 'eq',
                value: 'RESPONSIBLE_PERSON'
            }
        ]
    },
    precision_level_code: {
        referenced_column: 'code',
        referenced_table: 'dicts_precision_level',
        navigate_path: '/dictionaries/dicts_precision_level/',
        view_help: 'vdicts_precision_level'
    },
    tariff_decision_table_id: {
        referenced_column: 'id',
        referenced_table: 'docs_decision_tables',
        navigate_path: '/mutual-settlements/decision-tables/',
        view_help: 'vdocs_decision_tables'
    },
    tariff_decision_table_key: {
        referenced_column: 'key',
        referenced_table: 'docs_decision_tables',
        navigate_path: '/mutual-settlements/decision-tables/',
        view_help: 'vdocs_decision_tables'
    },
    item_generation_basis_code: {
        referenced_table: 'dicts_calculation_solution_base',
        referenced_column: 'code',
        navigate_path: '/dictionaries/calculation-solution-base',
        view_help: 'vdicts_calculation_solution_base'
    },
    round_profile_id: {
        referenced_column: 'id',
        referenced_table: 'settings_round_profiles',
        navigate_path: '/settings/round-profiles/',
        view_help: 'vsettings_round_profiles'
    },
    rfq_competition_profile_id: {
        referenced_column: 'id',
        referenced_table: 'settings_rfq_competition_profiles',
        navigate_path: '/settings/rfq_competition_profiles/',
        view_help: 'vsettings_rfq_competition_profiles'
    },
    warehouse_tare_group_id: {
        referenced_column: 'id',
        referenced_table: 'docs_warehouse_tare_groups',
        navigate_path: '/warehouse-master-data/warehouse-tare-groups/',
        view_help: 'vdocs_warehouse_tare_groups'
    },
    transportation_cargo_unit_id: {
        referenced_column: 'id',
        referenced_table: 'docs_transportation_cargo_units',
        navigate_path: '/orders-management/transportation-cargo-units/',
        view_help: 'vdocs_transportation_cargo_units'
    },
    transportation_cargo_unit_key: {
        referenced_column: 'key',
        referenced_table: 'docs_transportation_cargo_units',
        navigate_path: '/orders-management/transportation-cargo-units/',
        view_help: 'vdocs_transportation_cargo_units'
    },
    vehicle_selection_rule_code: {
        referenced_table: 'dicts_vehicle_selection_rules',
        referenced_column: 'code',
        navigate_path: '/dictionaries/vehicle-selection-rules',
        view_help: 'vdicts_vehicle_selection_rules'
    },
    multiplier_calculation_base_code: {
        referenced_table: 'dicts_calculation_base',
        referenced_column: 'code',
        navigate_path: '/dictionaries/calculation-base',
        view_help: 'vdicts_calculation_base'
    },
    etran_freight_id: {
        referenced_table: 'dicts_etran_freight',
        referenced_column: 'id',
        navigate_path: '/etran-rails/etran-dictionaries/dicts-etran-freight',
        view_help: 'vdicts_etran_freight'
    },
    bulk_plan_id: {
        referenced_table: 'docs_bulk_plans',
        referenced_column: 'id',
        navigate_path: '/transactional-data/bulk-plans/',
        view_help: 'vdocs_bulk_plans'
    },
    bulk_plan_key: {
        referenced_table: 'docs_bulk_plans',
        referenced_column: 'key',
        navigate_path: '/transactional-data/bulk-plans/',
        view_help: 'vdocs_bulk_plans'
    },
    order_movement_id: {
        referenced_table: 'docs_order_movements',
        referenced_column: 'id',
        navigate_path: '/transactional-data/order-movements/',
        view_help: 'vdocs_order_movements'
    },
    order_movement_key: {
        referenced_table: 'docs_order_movements',
        referenced_column: 'key',
        navigate_path: '/transactional-data/order-movements/',
        view_help: 'vdocs_order_movements'
    },
    standard_route_id: {
        referenced_table: 'docs_standard_routes',
        referenced_column: 'id',
        navigate_path: '/master-data/standard-routes/',
        view_help: 'vdocs_standard_routes'
    },
    standard_route_key: {
        referenced_table: 'docs_standard_routes',
        referenced_column: 'key',
        navigate_path: '/master-data/standard-routes/',
        view_help: 'vdocs_order_movements'
    }
};

export const ObjectLifecycleStatusGroupTitleFilter: Partial<Record<ViewName, string>> = {
    vdocs_transportation_tasks: 'Task Statuses',
    vdocs_transportation_bookings: 'Booking Statuses',
    vdocs_transportation_requests: 'Request statuses',
    vdocs_warehouse_tasks: 'Warehouse Task Statuses',
    vdocs_warehouse_executor_tasks: 'Warehouse Executor Task Statuses'
};
export const ObjectTypeViewMapping: Partial<Record<ViewName, ViewName>> = {
    vdocs_transportation_requests: 'vdicts_transportation_request_types',
    vdocs_business_partners: 'vdicts_business_partner_types',
    vdocs_products: 'vdicts_product_types',
    vdocs_product_groups: 'vdicts_product_group_types',
    vdocs_transportation_orders: 'vdicts_transportation_order_types',
    vdocs_transportation_tasks: 'vdicts_transportation_task_types',
    vdocs_gates: 'vdicts_gate_types',
    vdocs_schedules: 'vdicts_schedule_types',
    vdocs_locations: 'vdicts_location_types',
    vdocs_etran_claims: 'vdicts_etran_claim_types',
    vdocs_checkpoints: 'vdicts_checkpoint_types',
    vdocs_warehouses: 'vdicts_warehouse_types',
    vdocs_parking_places: 'vdicts_parking_place_types',
    vdocs_vehicles: 'vdicts_vehicle_types',
    vdocs_transportation_units: 'vdicts_transportation_unit_types',
    vdocs_transportation_trips: 'vdicts_transportation_trip_types',
    vdocs_transportation_bookings: 'vdicts_transportation_booking_types',
    vdocs_calculation_schemas: 'vdicts_calculation_schema_types',
    vdocs_agreements: 'vdicts_agreement_types',
    vdocs_settlements: 'vdicts_settlement_types',
    vdocs_parking_place_groups: 'vdicts_parking_place_group_types',
    vdocs_load_unload_spots: 'vdicts_load_unload_spot_types',
    vdocs_registry_groups: 'vdicts_registry_group_types',
    vdocs_etran_invoices: 'vdicts_etran_invoice_types',
    vdocs_etran_dislocations: 'vdicts_etran_dislocation_types',
    vdocs_etran_cars: 'vdicts_etran_car_types',
    vdocs_warehouse_zones: 'vdicts_warehouse_zone_types',
    vdocs_warehouse_aisles: 'vdicts_warehouse_aisle_types',
    vdocs_warehouse_columns: 'vdicts_warehouse_column_types',
    vdocs_warehouse_levels: 'vdicts_warehouse_level_types',
    vdocs_warehouse_storage_location_sizes: 'vdicts_warehouse_storage_location_size_types',
    vdocs_warehouse_storage_areas: 'vdicts_warehouse_storage_area_types',
    vdocs_warehouse_preparation_areas: 'vdicts_warehouse_preparation_area_types',
    vdocs_warehouse_tare_groups: 'vdicts_warehouse_tare_group_types',
    vdocs_warehouse_storage_locations: 'vdicts_warehouse_storage_location_types',
    vdocs_warehouse_resource_groups: 'vdicts_warehouse_resource_group_types',
    vdocs_warehouse_resources: 'vdicts_warehouse_resource_types',
    vdocs_warehouse_tares: 'vdicts_warehouse_tare_types',
    vdocs_product_skus: 'vdicts_product_sku_types',
    vdocs_product_processing_groups: 'vdicts_product_processing_group_types',
    vdocs_transportation_relations: 'vdicts_transportation_relation_types',
    vdocs_standard_routes: 'vdicts_standard_route_types',
    vdocs_warehouse_tasks: 'vdicts_warehouse_task_types',
    vdocs_warehouse_executor_tasks: 'vdicts_warehouse_executor_task_types',
    vdocs_warehouse_tasks_movement_report: 'vdicts_warehouse_task_types'
};

export type UIColumnType =
    | 'id'
    | 'array_id'
    | 'key'
    | 'product'
    | 'product_sku_stamps'
    | 'code'
    | 'phone'
    | 'email'
    | 'prefixField'
    | 'autoCompleteField'
    | 'url'
    | 'text'
    | 'code_text'
    | 'unit'
    | 'number'
    | 'date'
    | 'time'
    | 'datetime'
    | 'datetimelocal'
    | 'daterange'
    | 'daterange_with_datetime_return'
    | 'timerange'
    | 'datetimerange'
    | 'datetimelocalrange'
    | 'status'
    | 'userid'
    | 'coordinate'
    | 'multilang_text'
    | 'type'
    | 'value'
    | 'provision_indicator'
    | 'boolean'
    | 'flow'
    | 'barcode'
    | 'duration'
    | 'cron'
    | 'planning_vehicle_in_order'
    | 'planning_driver_in_order';

// Функция мэппит тип данных supabase с удобным для нас типом
// TODO: мб можно типизировать supabaseType
export const mapSupabaseAndUiType = (columnName: string, supabaseType: string): UIColumnType => {
    if (columnName.endsWith('uuid')) {
        return 'userid';
    }

    if (columnName === 'launch_schedule') {
        return 'cron';
    }

    if (columnName === 'product_key' || columnName === 'product_id') {
        return 'product';
    }

    if (columnName.endsWith('key')) {
        return 'key';
    }

    if (columnName.endsWith('status_code')) {
        return 'status';
    }
    if (columnName === 'flow_code') {
        return 'flow';
    }

    if (columnName === 'product_sku_stamp_ids') {
        return 'product_sku_stamps';
    }

    if (columnName === 'barcode') {
        return 'barcode';
    }

    if (columnName === 'post_code') {
        return 'text';
    }

    if (columnName === 'type_code') {
        return 'type';
    }

    if (columnName === 'code') {
        return 'code_text';
    }

    if (columnName.endsWith('_code')) {
        return 'code';
    }

    if (columnName === 'id') {
        return 'id';
    }

    if (columnName === 'dashboard_guid') {
        return 'text';
    }

    if (columnName.endsWith('_id')) {
        return 'id';
    }

    if (columnName.endsWith('ids')) {
        return 'array_id';
    }

    if (columnName.endsWith('email')) {
        return 'email';
    }

    if (columnName.endsWith('phone')) {
        return 'phone';
    }

    if (columnName.endsWith('hard_range')) {
        return 'datetimelocalrange';
    }

    if (columnName.endsWith('soft_range')) {
        return 'daterange_with_datetime_return';
    }

    if (columnName.endsWith('provisionindicator')) {
        return 'provision_indicator';
    }

    if (columnName === 'region') {
        return 'code';
    }

    if (supabaseType === 'multilang_text') {
        return 'multilang_text';
    }

    if (supabaseType === 'text') {
        return 'text';
    }

    if (supabaseType === 'datetime') {
        return 'datetime';
    }

    // TODO : поля для юзера?
    if (supabaseType === 'userid') {
        return 'userid';
    }

    if (supabaseType === 'coordinate') {
        return 'coordinate';
    }

    if (supabaseType === 'integer') {
        return 'number';
    }
    if (supabaseType === 'bigint') {
        return 'number';
    }

    // TODO: Вернула поле значения единиц измерения на обычное, с типом Number, так как viewName не корректно передаются
    // if (supabaseType === 'measure_value') {
    //   return 'unit';
    // }

    if (supabaseType === 'measure_value') {
        return 'value';
    }

    if (
        supabaseType === 'time' ||
        supabaseType === 'time with time zone' ||
        supabaseType === 'time without time zone'
    ) {
        return 'time';
    }

    if (supabaseType === 'date') {
        return 'date';
    }

    if (supabaseType === 'timestamp') {
        return 'datetime';
    }

    if (supabaseType === 'timestamp without time zone') {
        return 'datetimelocal';
    }

    if (supabaseType === 'local_datetime') {
        return 'datetimelocal';
    }

    if (supabaseType === 'hard_range') {
        return 'datetimelocalrange';
    }

    if (supabaseType === 'soft_range') {
        return 'daterange_with_datetime_return';
    }

    if (supabaseType === 'dates_range') {
        return 'daterange';
    }

    if (supabaseType === 'datetime_range') {
        return 'datetimerange';
    }

    if (supabaseType === 'time_range') {
        return 'timerange';
    }

    if (supabaseType === 'local_datetime_range') {
        return 'datetimelocalrange';
    }

    if (supabaseType === 'boolean') {
        return 'boolean';
    }

    if (columnName === 'planning_vehicle_in_order') {
        return 'planning_vehicle_in_order';
    }

    if (columnName === 'planning_driver_in_order') {
        return 'planning_driver_in_order';
    }

    if (columnName.endsWith('duration')) {
        return 'duration';
    }

    // Default type
    return 'text';
};
