import { toJS } from 'mobx';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectResponse } from 'modules/services/backend-api/generated_api';
import { MetaField } from 'modules/services/backend-api/generated_info';
import { toPascalCase } from 'utils/helpers/toPascalCase';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

export const useSelectData = ({ meta, filters }: { meta: string; filters: string }) => {
    const { t, i18n } = useTranslation();
    const [viewFieldName, setViewFieldName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState<IObjectWithId[]>([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalFields, setModalFields] = useState<MetaField[]>([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        let metaInfo = toJS(metaStore.meta.get(meta)?.info);
        if (!metaInfo) metaInfo = await metaStore.getInfo(meta);

        const fields = metaInfo?.Fields || [];
        setModalFields(fields);

        const snakedViewFieldName = metaInfo?.Type?.ViewFieldName || '';
        const viewFieldName = toPascalCase(snakedViewFieldName);
        setViewFieldName(viewFieldName);

        if (metaInfo) {
            setModalTitle(
                metaInfo.PluralName
                    ? metaInfo.PluralName[i18n.language] || (t(metaInfo.TableName) as string)
                    : (t(metaInfo.TableName) as string)
            );
        }

        const metaSelect = await metaStore.makeSelect({
            meta,
            // fields: fields.map(({ FieldName }) => FieldName),
            filters,
            sort:
                snakedViewFieldName && fields.find(({ FieldName }) => FieldName === viewFieldName)
                    ? `${snakedViewFieldName} asc`
                    : ''
            // page: 1,
            // page_size: 50000
        });

        setIsLoading(false);

        setDataSource(
            metaSelect?.objects.filter((item) => {
                if (typeof item.IsActive === 'boolean') {
                    return item.IsActive;
                }
                return true;
            }) || []
        );

        return metaSelect?.objects;
    }, [filters, i18n.language, meta, t]);

    return { viewFieldName, dataSource, modalTitle, modalFields, fetchData, isLoading };
};
