import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GlobalFilterType } from 'components/DataTable/useTableBackendFeatures';
import { TableName, ViewOrTableName } from 'modules/supabase/types/Dataset';
import { Filter, OrderBy, getSupabaseViewOrTableData } from 'modules/supabase/utils/supabaseClient';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';

type TableInfiniteQueryOptions<T extends ViewOrTableName, K extends TableName> = {
    viewOrTableName: T;
    pageSize?: number;
    pageNumber?: number;
    virtualization?: boolean;
    filters?: Filter[];
    getFilter?: () => Promise<Filter[]>;
    orderBy?: OrderBy[];
    globalFilter?: GlobalFilterType;
    setTotalDBRowCount: (total: number) => void;
    doRequest: boolean;
    refresh?: boolean;
    setRefresh?: Function;
};
export const useTableInfiniteQuery = <T extends ViewOrTableName>(
    SupabaseGetDataOptions: TableInfiniteQueryOptions<T, TableName>
) => {
    const fetchQueryKey = useMemo(() => {
        return [
            SupabaseGetDataOptions.viewOrTableName,
            SupabaseGetDataOptions.filters,
            SupabaseGetDataOptions.orderBy,
            SupabaseGetDataOptions.refresh,
            SupabaseGetDataOptions.globalFilter
        ];
    }, [SupabaseGetDataOptions]);

    const result = useInfiniteQuery({
        queryKey: fetchQueryKey,
        enabled: !!SupabaseGetDataOptions.doRequest,
        queryFn: async ({ pageParam }: { pageParam: number }) => {
            const {
                data: newData,
                // count: newCount,
                error: newError
            } = await getSupabaseViewOrTableData({
                ...SupabaseGetDataOptions,
                pageNumber: pageParam
            });

            if (newError) throw newError;

            if (SupabaseGetDataOptions?.setTotalDBRowCount)
                SupabaseGetDataOptions.setTotalDBRowCount(
                    newData.length < (SupabaseGetDataOptions?.pageSize || 0)
                        ? newData.length
                        : SupabaseGetDataOptions?.pageSize || 0
                );

            return newData;
        },
        initialPageParam: 0,
        getNextPageParam: (_: any, groups: Record<string, any>) => groups.length
    });
    return result;
};
