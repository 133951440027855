import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select_DataTable } from 'components/DataTable/Select_DataTable';
import { TableName, TableRow, ViewName } from 'modules/supabase/types/Dataset';
import { ButtonWithTooltips } from 'ui';

import type { MRTColumnDef } from 'components/DataTable/MRT_Types';
import { Filter } from 'modules/supabase/utils/supabaseClient';

interface SearchListProps {
    onSearchApply: (searchedRows: TableRow[]) => void;
    onSearchCancel: () => void;
    searchLishColumns?: MRTColumnDef[];
    viewNameOrTableName: ViewName | TableName;
    searchListFilter?: Filter[];
    searchListGetFilter?: () => Promise<Filter[]>;
    multiSelect?: boolean;
    expandOptions?: {
        enabled: boolean;
        columnSorting?: (fieldA: string, fieldB: string) => number;
    };

    refresh?: boolean;
    defaultTablePageSize?: number;
    title?: string;
}

export const SearchList = ({
    onSearchCancel,
    onSearchApply,
    viewNameOrTableName,
    searchLishColumns,
    searchListFilter,
    searchListGetFilter,
    expandOptions,
    refresh,
    multiSelect = false,
    defaultTablePageSize,
    title
}: SearchListProps) => {
    const [rowSelection, setRowSelection] = useState([]);

    const { t } = useTranslation();

    return (
        <div>
            <h3>{t(title || 'search')}</h3>
            <Select_DataTable
                viewNameOrTableName={viewNameOrTableName}
                searchLishColumns={searchLishColumns}
                onRowSelect={setRowSelection}
                multiSelect={multiSelect}
                filter={searchListFilter}
                getFilter={searchListGetFilter}
                expandOptions={expandOptions}
                refresh={refresh}
                defaultTablePageSize={defaultTablePageSize}
            />

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <ButtonWithTooltips
                    id="cancel"
                    className="btn-red"
                    style={{ margin: '0.5rem' }}
                    type="text"
                    onClick={onSearchCancel}
                >
                    {t('cancel')}
                </ButtonWithTooltips>

                <ButtonWithTooltips
                    id="apply"
                    style={{ margin: '0.5rem' }}
                    onClick={() => {
                        onSearchApply(rowSelection);
                    }}
                >
                    {t('apply')}
                </ButtonWithTooltips>
            </div>
        </div>
    );
};
