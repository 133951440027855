// BaseField.tsx
import { Form, Input } from 'antd';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseField } from '../BaseField/BaseField';

interface EtranBaseFieldProps {
    id: string;
    value: any;
    withLabel?: boolean;
}

export const EtranBaseField = memo(({ id, value, withLabel = true }: EtranBaseFieldProps) => {
    const { t } = useTranslation();

    const renderView = useCallback(
        (viewValue: string) => {
            return (
                <Form.Item>
                    <Input
                        id={`${id}-value`}
                        value={viewValue}
                        placeholder={t('no_value') as string}
                        variant="borderless"
                        readOnly
                    />
                </Form.Item>
            );
        },
        [id, t]
    );

    return (
        <BaseField
            id={id}
            required={false}
            value={value}
            mode="view"
            withLabel={withLabel}
            onChange={() => {}}
            renderInput={renderView}
            renderView={renderView}
        />
    );
});
