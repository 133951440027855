import React, { startTransition, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { UniversalBoundary } from 'components';
import { observer } from 'mobx-react-lite';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { TableName } from 'modules/supabase/types/Dataset';
import { CreatePage } from 'pages/base/CreatePage/CreatePage';
import { SkeletonLoader } from 'ui/Skeleton/Skeleton';
import { getTableNameByPath } from 'utils';
import { getTableDictNames } from 'utils/helpers/getTableDictNames';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useBreadcrumbs } from 'utils/hooks/useBreadcrumbs';
import { useStoreNavigate } from 'utils/store';
import { detailPageStore } from 'utils/store/DetailPageStore/DetailPageStore';

export interface IDetailPageLoaderProps {
    id?: string;
    mode?: string;
    typeCode?: string;
    typeInfo?: Record<string, any>;
}
interface TypeInfo extends Record<string, any> {
    code?: string;
    parent_code?: string;
    short_title?: Record<string, any>;
    long_title?: Record<string, any>;
    settings_lifecycle_status_group_id?: number;
}

type DetailComponentType = React.ComponentType<IDetailPageLoaderProps>;

type OptionalRecord<T> = {
    [K in keyof T]?: T[K];
};

type DetailPageRouteMap = OptionalRecord<Record<TableName, Record<string, DetailComponentType>>>;

const DetailPageRoutes: DetailPageRouteMap = {
    // TODO: Add more route_paths and their corresponding type_code to component mappings

    docs_warehouses: {
        DEFAULT: React.lazy(() => {
            return import('pages/docs/warehouses/Warehouse_DetailPage').then((module) => {
                return {
                    default: module.Warehouse_DetailPage
                };
            });
        })
    },
    docs_warehouse_inbound_orders: {
        DEFAULT: React.lazy(() => {
            return import('pages/docs/wms-inbound-orders/WmsInboundOrder_DetailPage').then(
                (module) => {
                    return {
                        default: module.WmsInboundOrder_DetailPage
                    };
                }
            );
        })
    },

    docs_warehouse_outbound_orders: {
        DEFAULT: React.lazy(() => {
            return import('pages/docs/wms-outbound-orders/WmsOutboundOrder_DetailPage').then(
                (module) => {
                    return {
                        default: module.WmsOutboundOrder_DetailPage
                    };
                }
            );
        })
    },

    docs_warehouse_selection_waves: {
        DEFAULT: React.lazy(() => {
            return import('pages/docs/wms-selection-waves/WmsSelectionWave_DetailPage').then(
                (module) => {
                    return {
                        default: module.WmsSelectionWave_DetailPage
                    };
                }
            );
        })
    },

    docs_warehouse_selection_controls: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/docs/warehouse-selection-controls/WarehouseSelectionControls_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehouseSelectionControls_DetailPage
                };
            });
        })
    },

    docs_warehouse_executor_tasks: {
        DEFAULT: React.lazy(() => {
            return import('pages/docs/wms-executor-tasks/WmsExecutorTask_DetailPage').then(
                (module) => {
                    return {
                        default: module.WmsExecutorTask_DetailPage
                    };
                }
            );
        })
    },

    settings_warehouse_acceptance_rules: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/warehouse-acceptance-rules/WarehouseAcceptanceRule_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehouseAcceptanceRule_DetailPage
                };
            });
        })
    },

    settings_business_partner_warehouses: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/business-partner-warehouses/BusinessPartnerWarehouses_DetailPage'
            ).then((module) => {
                return {
                    default: module.BusinessPartnerWarehouses_DetailPage
                };
            });
        })
    },

    settings_warehouse_replenishment_rules: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/warehouse-replenishment-rules/WarehouseReplenishmentRule_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehouseReplenishmentRule_DetailPage
                };
            });
        })
    },

    settings_warehouse_shipping_rules: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/warehouse-shipping-rules/WarehouseShippingRule_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehouseShippingRule_DetailPage
                };
            });
        })
    },

    settings_warehouse_selection_rules: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/warehouse-selection-rules/WarehouseSelectionRule_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehouseSelectionRule_DetailPage
                };
            });
        })
    },

    settings_warehouse_placement_rules: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/warehouse-placement-rules/WarehousePlacementRule_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehousePlacementRule_DetailPage
                };
            });
        })
    },

    settings_warehouse_scheduler_commons: {
        DEFAULT: React.lazy(() => {
            return import(
                'pages/wms-settings/warehouse-scheduler-commons/WarehouseScheduleCommon_DetailPage'
            ).then((module) => {
                return {
                    default: module.WarehouseScheduleCommon_DetailPage
                };
            });
        })
    }
};

const defaultTypeCodes: OptionalRecord<Record<TableName, string>> = {
    docs_products: 'DEFAULT',
    docs_checkpoints: 'DEFAULT',
    docs_locations: 'DEFAULT',
    docs_parking_places: 'DEFAULT',
    docs_registry_groups: 'DEFAULT',
    docs_product_groups: 'DEFAULT',
    settings_registry_group_day_limits: 'DEFAULT',
    settings_warehouse_product_group_day_limits: 'DEFAULT',
    settings_load_unload_durations: 'DEFAULT',
    settings_warehouses: 'DEFAULT',
    settings_warehouse_day_limits: 'DEFAULT',
    docs_vehicles: 'DEFAULT',
    docs_warehouses: 'DEFAULT',
    // docs_load_unload_spots: 'DEFAULT', TODO: В Докс написано что нужно удалить и перенести некоторый фукнционал в ворота
    docs_business_partners: 'DEFAULT',
    docs_gates: 'DEFAULT',
    docs_parking_place_groups: 'DEFAULT',
    docs_transportation_bookings: 'DEFAULT',
    docs_transportation_orders: 'DEFAULT',
    docs_transportation_requests: 'DEFAULT',
    docs_transportation_resources: 'DEFAULT',
    docs_transportation_trips: 'DEFAULT',
    docs_transportation_tasks: 'DEFAULT',
    docs_transportation_units: 'DEFAULT',
    docs_agreements: 'DEFAULT',
    docs_etran_claims: 'DEFAULT',
    docs_etran_invoices: 'DEFAULT',
    docs_etran_dislocations: 'DEFAULT',
    docs_etran_cars: 'DEFAULT',
    docs_warehouse_aisles: 'DEFAULT',
    docs_warehouse_access_areas: 'DEFAULT',
    docs_warehouse_columns: 'DEFAULT',
    docs_warehouse_levels: 'DEFAULT',
    docs_warehouse_preparation_areas: 'DEFAULT',
    docs_warehouse_resource_groups: 'DEFAULT',
    docs_warehouse_resources: 'DEFAULT',
    docs_warehouse_rows: 'DEFAULT',
    docs_warehouse_storage_areas: 'DEFAULT',
    docs_warehouse_storage_location_sizes: 'DEFAULT',
    docs_warehouse_storage_locations: 'DEFAULT',
    docs_warehouse_tare_groups: 'DEFAULT',
    docs_warehouse_tares: 'DEFAULT',
    docs_warehouse_zones: 'DEFAULT',
    docs_product_processing_groups: 'DEFAULT',
    docs_product_skus: 'DEFAULT',
    docs_product_sku_batches: 'DEFAULT',
    docs_product_sku_stamps: 'DEFAULT',
    // docs_tariff_tables: 'DEFAULT',
    docs_calculation_schemas: 'DEFAULT',
    docs_invoices: 'DEFAULT',
    docs_warehouse_executor_tasks: 'DEFAULT',
    docs_warehouse_selection_waves: 'DEFAULT',
    docs_warehouse_outbound_orders: 'DEFAULT',
    docs_warehouse_inbound_orders: 'DEFAULT',
    docs_etran_acts: 'DEFAULT',
    settings_warehouse_placement_rules: 'DEFAULT',
    settings_warehouse_selection_rules: 'DEFAULT',
    settings_warehouse_shipping_rules: 'DEFAULT',
    settings_warehouse_replenishment_rules: 'DEFAULT',
    settings_warehouse_acceptance_rules: 'DEFAULT',
    settings_warehouse_functional_modules: 'DEFAULT',
    settings_printing_forms: 'DEFAULT',
    docs_warehouse_tasks: 'DEFAULT',
    settings_warehouse_scheduler_commons: 'DEFAULT',
    settings_trip_planner: 'DEFAULT',
    docs_rfq_requests: 'DEFAULT',
    docs_rfq_responses: 'DEFAULT',
    docs_dashboards: 'DEFAULT',
    docs_quotas: 'DEFAULT',
    docs_incompatibilities: 'DEFAULT',
    docs_decision_tables: 'DEFAULT',
    settings_load_unload_norms: 'DEFAULT',
    settings_charges_agreement_types: 'DEFAULT',
    settings_charges_datetime_selections: 'DEFAULT',
    docs_vehicle_groups: 'DEFAULT',
    docs_transportation_cargo_units: 'DEFAULT',
    docs_order_movements: 'DEFAULT',
    docs_route_schedules: 'DEFAULT',
    docs_trip_groups: 'DEFAULT',
    docs_product_kinds: 'DEFAULT',
    docs_product_transport_categories: 'DEFAULT',
    settings_allocation_rules: 'DEFAULT',
    docs_compatibilities: 'DEFAULT',
    docs_services: 'DEFAULT',
    docs_transportation_lanes: 'DEFAULT',
    docs_rate_services: 'DEFAULT',
    docs_warehouse_quotas: 'DEFAULT',
    docs_administrative_areas: 'DEFAULT'
    // TODO: Add more route_paths and their default type_codes
};

function getDetailComponent(
    tableName: TableName,
    typeInfo: TypeInfo // про типу определим какой detail page компонент надо использовать
): DetailComponentType | null {
    const { code: type_code } = typeInfo;
    console.log(`Loading detail component for table '${tableName}' and type_code '${type_code}'.`);
    const routeComponents = DetailPageRoutes[tableName];

    if (!routeComponents) {
        console.error(`Error: Unable to find route components for table '${tableName}'.`);
        return null;
    }

    if (!type_code) {
        // хотя можно было бы загрузить дефолтный компонент но такого никогда не должно быть чтобы у объекта не было типа! поэтому не будем загружать дефолтный компонент
        console.error(`Error: No type_code found for table '${tableName}'.`);
        return null;
    }

    // сначала попрбуем для типа взять компонент
    let detailComponent = routeComponents[type_code];
    if (detailComponent) {
        return detailComponent;
    }
    // иначе не нашли для данного типа особого компонента - значит будем брать дефолтный
    const defaultTypeCode = defaultTypeCodes[tableName];
    if (!defaultTypeCode) {
        console.error(`Error: No default type_code found for table '${tableName}'.`);
        return null;
    }

    detailComponent = routeComponents[defaultTypeCode];
    if (detailComponent) {
        return detailComponent;
    }

    console.error(
        `Error: Unable to find detail component for table '${tableName}' and default type_code '${defaultTypeCode}'.`
    );
    return null;
}

// enum: model meta types
type MetaModelType = 'docs' | 'settings' | 'dicts' | 'profile';

const useShouldSkipCreatePage = ({
    tableName,
    handleOnCreateTypeSelected,
    setShouldDisplayCreatePage
}: {
    tableName: string;
    handleOnCreateTypeSelected: (type_info: TypeInfo) => void;
    setShouldDisplayCreatePage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const controller = new AbortController();
        const { typeTableName } = getTableDictNames(tableName);

        if (!typeTableName) {
            setShouldDisplayCreatePage(false);
            return;
        }

        const fetchData = async () => {
            const { data, error } = await supabaseClient
                .from(typeTableName)
                .select('*')
                .abortSignal(controller.signal);

            if (error) {
                return;
            }

            if (data) {
                if (data.length === 1) {
                    if (data[0].code === 'DEFAULT') {
                        handleOnCreateTypeSelected(data[0]);
                    }
                }

                if (data.length > 1) {
                    setShouldDisplayCreatePage(true);
                }
            }
        };

        if (!id || id === ':') {
            fetchData();
        }

        return () => {
            controller.abort();
        };
    }, []);
};

export const DetailPageLoader = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    // const location = useLocation();
    // const {openMessage, loadingMessage, successMessage, errorMessage } =
    //     useMessages({
    //         message: ''
    //     });

    // const {openNotify} = useNotifications({ message: '' });
    const location = useLocation();
    // console.log(location, id);
    const route_path = location.pathname
        .substring(0, location.pathname.lastIndexOf('/'))
        .substring(1);
    // replace "/" and "-" with "_" and
    // replace "master-data" with "docs" : "master-data/products" -> "docs_products",
    // replace "transactional-data" with "docs" : "transactional-data/transportation-order" -> "transportation_order"
    // replace "settings" with "settings" : "settings/registry-group-day-limits" -> "settings_registry_group_day_limits"

    const table_name = getTableNameByPath(location.pathname);

    const model_type = route_path
        .replace('warehouse-settings', 'settings')
        .split('/')[0] as MetaModelType; // settings or docs or dicts

    // first part of route_path is model type
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode') || 'view';
    const [error, setError] = useState<string | null>(null);
    const [DetailComponent, setDetailComponent] = useState<DetailComponentType | null>(null);
    const [typeInfo, setTypeInfo] = useState<Record<string, any>>({}); // меняется совместно с DetailComponent
    const [ShouldDisplayCreatePage, setShouldDisplayCreatePage] = useState<boolean>(false); // меняется совместно с DetailComponent
    const navigate = useStoreNavigate(); // Add this line to use the useHistory hook
    const { setKey } = useBreadcrumbs(); // для хлебных крошек, будем получать key для вывода в бар

    // Логика следующая:
    // Если это - settings (или в будущем еще dicts) то мы сразу знаем какой DetailComponent
    // Иначе (это docs):
    //  - если режим создания то покажем CreatePage и оттуда получим тип
    //  - иначе получим тип из supabase
    //  - когда знаем тип - по нему получим DetailComponent
    // Т.о. нам нужны состояния:
    //  - тип (type_code) или сразу компонент (DetailComponent) - т.к. его однозначно по типу определяем

    const handleOnCreateTypeSelected = (type_info: TypeInfo) => {
        // без startTransition падает в ошибку: Error: A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator. To fix, updates that suspend should be wrapped with startTransition.
        detailPageStore.setDetailPageCreateInfo(table_name, type_info);

        startTransition(() => {
            const detailComponent = getDetailComponent(table_name, type_info);
            if (detailComponent) {
                setDetailComponent(detailComponent);
                setTypeInfo({ ...type_info, id: -1 });
            } else {
                setError(t('detail_load_error')); // TODO: i18n it
            }
        });

        setShouldDisplayCreatePage(false);
    };

    // useShouldSkipCreatePage({
    //     tableName: table_name,
    //     handleOnCreateTypeSelected,
    //     setShouldDisplayCreatePage
    // });

    // TODO: надо доработать для Settings страниц - там нет типа!
    useEffect(() => {
        if (model_type === 'settings') {
            // для settings мы сразу знаем какой DetailComponent
            // для всех settings будем использвоать как бы DEFAULT тип - так проще всего. TODO: в будущем лучше отдельный DetailPageLoader написать для них
            const type_info = {
                code: 'DEFAULT'
            };
            const detailComponent = getDetailComponent(table_name, type_info);

            if (detailComponent) {
                setDetailComponent(detailComponent);
                setTypeInfo(type_info);
            }
            return;
        }

        if (mode === 'create' || id === undefined || id === ':') {
            // тогда тип и компонент определятся в CreatePage
            const hasCreateTypeInfo = !!detailPageStore.getDetailPageInfo(table_name, 'create');

            if (!hasCreateTypeInfo) {
                console.log('no create type info');
                setShouldDisplayCreatePage(true);
                return;
            }
            // return;
        }
        // setShouldDisplayCreatePage(false);

        // иначе у нас edit или view или clone
        const fetchType = async () => {
            try {
                // await detailPageStore.fecthDetailPageInfo(table_name, id);
                let type_info;

                if (mode === 'create' || id === undefined || id === ':') {
                    type_info = detailPageStore.getDetailPageInfo(table_name, 'create');
                } else {
                    type_info = detailPageStore.getDetailPageInfo(table_name, id);
                    if (!type_info) {
                        type_info = await detailPageStore.fecthDetailPageInfo(table_name, id);
                    }
                }

                if (setKey && type_info?.key) {
                    setKey(type_info.key);
                }

                if (type_info) {
                    const detailComponent = getDetailComponent(table_name, type_info);

                    if (detailComponent) {
                        setDetailComponent(detailComponent);
                        setTypeInfo(type_info);
                    } else {
                        setError(t('detail_find_error'));
                    }
                }
            } catch (error) {
                setError(error.message);
            }
            // мне надо из вьюшки получить key,settings_lifecycle_status_group_id, type_short/long_title,type_code, убрать type так как getDetailComponent на вход получает объект: TypeInfo
            // let type_info = {};
            // let response: PostgrestSingleResponse<{
            //     key: any;
            //     type_code: any;
            //     parent_type_code: any;
            //     type_short_title: any;
            //     type_long_title: any;
            //     lifecycle_status_group_id: any;
            // }>;

            // if (table_name === 'docs_transportation_tasks') {
            //     response = await supabaseClient
            //         .from(`v${table_name}`)
            //         .select(
            //             'key, type_code, parent_type_code, type_short_title, type_long_title, lifecycle_status_group_id'
            //         )
            //         .eq('id', id)
            //         .single();
            // } else {
            //     response = await supabaseClient
            //         .from(`v${table_name}`)
            //         .select(
            //             'key, type_code, type_short_title, type_long_title, lifecycle_status_group_id'
            //         )
            //         .eq('id', id)
            //         .single();
            // }
            // const { data, error } = response || {};

            // // беру из данных вьюшки, данные из типа
            // if (data) {
            //     const {
            //         type_code: code,
            //         type_long_title: long_title,
            //         type_short_title: short_title,
            //         parent_type_code: parent_code,
            //         lifecycle_status_group_id
            //     } = data;

            //     type_info = {
            //         code,
            //         parent_code,
            //         long_title,
            //         short_title,
            //         lifecycle_status_group_id
            //     };
            // }

            // if (error) {
            //     // console.log(error.message || error);
            //     return;
            // }

            // get the right DetailPage component using model's typeInfo
        };
        fetchType();
    }, [
        route_path,
        mode,
        id,
        location.search,
        location.pathname,
        model_type,
        table_name,
        setKey,
        t
    ]);

    const handleOnCreateCancel = useCallback(() => {
        navigate(-1);
        setShouldDisplayCreatePage(false);
    }, [navigate]);

    // useEffect(() => {
    //     if (error) {
    //         // TODO: продумать механизм страниц ошибки при отсутствии деталей. механизм с редиректом
    //         // TODO: NOTE: не удалять комментарии!
    //         // errorMessage();

    //         // openNotify({
    //         //     message: t('error'),
    //         //     description: makeErrorReadable(error) || (t('detail_error') as string),
    //         //     type: 'error',
    //         //     duration: 0,
    //         // });

    //         // navigate(-1);
    //     }
    // }, [error, navigate]);

    // TODO: продумать механизм страниц ошибки при отсутствии деталей. механизм с редиректом
    // TODO: NOTE: не удалять комментарии!
    if (error) {
        return (
            <UniversalBoundary
                subTitle={makeErrorReadable(error) || (t('detail_error') as string)}
            />
        );
    }

    if (ShouldDisplayCreatePage) {
        // может быть как docs так и settings - и в случае с settings надо просто подождать пока загрузится DetailComponent
        return (
            <CreatePage
                table_name={table_name}
                onTypeSelected={handleOnCreateTypeSelected}
                onCancel={handleOnCreateCancel}
            />
        );
    }

    if (!DetailComponent) {
        return <SkeletonLoader />;
    }

    return (
        // TODO: в режиме create надо передавать ТИП, а не id

        <DetailComponent id={id} mode={mode} typeInfo={typeInfo} />
    );
});
