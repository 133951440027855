import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { callBackendFunction } from './BackendFunctions';

type SaveMultiDatasetResponse = Record<string, any> | null;

export async function createInvoicesFromCharges(
    charges: number[],
    chargeBPAndAgreement: Record<string, any>[]
): Promise<{ data: Record<string, any> | null; error: Error | null }> {
    const endpoint = 'create-invoices-from-charges';

    const { data, error } = await callBackendFunction<SaveMultiDatasetResponse>(endpoint, {
        charges,
        chargeBPAndAgreement
    });
    if (error) {
        return { data: null, error: new Error(makeErrorReadable(error.message)) };
    }

    return { data, error: null };
}
