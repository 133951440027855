import { useCallback } from 'react';

// Утилита возвращающая undefined, если не может распарсить json
const safeParse = <Data>(value: string) => {
    try {
        return JSON.parse(value) as Data;
    } catch {
        return undefined;
    }
};

export const useLocalStorage = () => {
    const get = useCallback(<Data = unknown>(lsKey: string): Data | undefined => {
        const value = localStorage.getItem(lsKey);

        if (!value) {
            return undefined;
        }

        return safeParse<Data>(value);
    }, []);

    const set = useCallback(<Data>(lsKey: string, value: Data) => {
        localStorage.setItem(lsKey, JSON.stringify(value));
    }, []);

    return { get, set };
};
