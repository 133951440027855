import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, List, Select, Skeleton, Typography } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { parseValueType } from 'smart/utils';

import { FilterField, FilterFieldValue, LocalFilterValueType } from '../../types';
import { getAvailableFilterOperators, getDefaultFilterOperator, isRangeType } from '../../utils';
import { renderValue } from './utils';

interface IFilterItem {
    item: FilterField;
    changeItem: (item: FilterField) => void;
    removeAppliedField: (fieldName: string) => void;
}

export const FilterItem: FC<IFilterItem> = ({ item, changeItem, removeAppliedField }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { options } = parseValueType(item.field.ValueType || '');

    const addFilterValue = useCallback(() => {
        const copy = { ...item };
        copy.values = [
            ...copy.values,
            {
                operator: getDefaultFilterOperator(item)
            }
        ];

        changeItem(copy);
    }, [item, changeItem]);

    const deleteFilterValue = useCallback(
        (index: number) => {
            const copy = { ...item, values: [...item.values] };
            copy.values.splice(index, 1);
            changeItem(copy);
        },
        [item, changeItem]
    );

    const changeFilterValue = useCallback(
        (filterValue: LocalFilterValueType, changedFilterValue: FilterFieldValue) => {
            const copy = { ...item, values: [...item.values] };
            copy.values[filterValue.index] = {
                ...copy.values[filterValue.index],
                ...changedFilterValue
            };

            changeItem(copy);
        },
        [item, changeItem]
    );

    const listData: LocalFilterValueType[] = item.values.map((value, index) => {
        return {
            index,
            lastValue: index === item.values.length - 1,
            operator: value.operator,
            value: value.value,
            field: item.field
        };
    });

    if (listData.length < 0) return null;

    return (
        <div className="div-item">
            <List
                header={
                    <Flex align="center" justify="space-between">
                        <Typography.Text>
                            {item.field.Name[language] || t(item.field.ColumnName)}
                            <PlusOutlined
                                onClick={addFilterValue}
                                style={{
                                    cursor: 'pointer',
                                    color: 'green',
                                    marginLeft: 10
                                }}
                            />
                        </Typography.Text>
                        {!item.field.FilterIndex && (
                            <CloseOutlined
                                onClick={() => {
                                    removeAppliedField(item.field.ColumnName);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    color: 'red',
                                    padding: '0 8px'
                                }}
                            />
                        )}
                    </Flex>
                }
                dataSource={listData || []}
                itemLayout="horizontal"
                renderItem={(valueItem) => (
                    <List.Item
                        style={{ padding: '8px 0' }}
                        actions={
                            valueItem.index > 0
                                ? [
                                      <CloseOutlined
                                          onClick={() => {
                                              deleteFilterValue(valueItem.index);
                                          }}
                                          style={{
                                              cursor: 'pointer'
                                          }}
                                      />
                                  ]
                                : undefined
                        }
                    >
                        <Skeleton active loading={false}>
                            <List.Item.Meta />

                            <div
                                className="item_meta"
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                            >
                                <Select
                                    getPopupContainer={() =>
                                        document.getElementById('table_filter_menu') as HTMLElement
                                    }
                                    className="select_item_meta"
                                    style={{ width: '15rem' }}
                                    value={valueItem.operator}
                                    onChange={(operator) => {
                                        changeFilterValue(valueItem, {
                                            operator,
                                            value: undefined
                                        });
                                    }}
                                    options={getAvailableFilterOperators(item).map(
                                        (filterFNSKey) => {
                                            const isRange = isRangeType(item.field.ValueType);

                                            let label = filterFNSKey;

                                            if (
                                                isRange &&
                                                (filterFNSKey === 'lessThan' ||
                                                    filterFNSKey === 'lessThanOrEqualTo')
                                            ) {
                                                label = `${label}Start`;
                                            }

                                            if (
                                                isRange &&
                                                (filterFNSKey === 'greaterThan' ||
                                                    filterFNSKey === 'greaterThanOrEqualTo')
                                            ) {
                                                label = `${label}End`;
                                            }

                                            return {
                                                label: t(label),
                                                value: filterFNSKey
                                            };
                                        }
                                    )}
                                />

                                <div
                                    className="item_meta_value"
                                    style={{ width: '100%', marginLeft: '1rem' }}
                                >
                                    {renderValue({
                                        value: valueItem,
                                        onChange: changeFilterValue,
                                        data: item,
                                        options
                                    })}
                                </div>
                            </div>
                        </Skeleton>
                    </List.Item>
                )}
            ></List>
        </div>
    );
};
