import { RealtimeChannel } from '@supabase/supabase-js';
import { useCallback, useEffect, useState } from 'react';

import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { BaseTableRow, SupabaseRow } from 'modules/supabase/types/Dataset';

interface GetSupabaseSubscriptionData {
    tableName?: string;
    viewOrTableName?: string;
    autoRefresh?: boolean;
    channelName: string;
    flatData: SupabaseRow<any>[];
}

type RealtimePostgresChangesPayload<T> = {
    [key: string]: T;
};

type PendingUpdatesType = {
    [key: string]: RealtimePostgresChangesPayload<any>;
} & { deleted: Omit<PendingUpdatesType, 'deleted'> };

const DELAY = 3000;

export const getSupabaseSubscriptionData = ({
    tableName,
    viewOrTableName,
    autoRefresh,
    channelName,
    flatData
}: GetSupabaseSubscriptionData) => {
    const [data, setData] = useState<SupabaseRow<any>[]>([]);

    const [pendingUpdates, setPendingUpdates] = useState<PendingUpdatesType>({ deleted: {} });
    const [updateTimer, setUpdateTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setData(flatData);
    }, [flatData]);

    const handlePayload = useCallback(
        (payload: RealtimePostgresChangesPayload<any>) => {
            // тут получаем id для обновленных данных (INSERT, UPDATE) и для удаленных (DELETE) соответсвенно
            const newId = payload.new.id;
            const oldId = payload.old.id;

            let pendingUpdatesLocal: PendingUpdatesType = { deleted: {} };
            setPendingUpdates((prev) => {
                pendingUpdatesLocal = {
                    ...prev,
                    [newId]: payload, // сюда мапим все обновленные данные (INSERT, UPDATE)
                    deleted:
                        payload.eventType === 'DELETE' // сюда - удаленные (DELETE)
                            ? {
                                  ...prev.deleted,
                                  [oldId]: payload
                              }
                            : { ...prev.deleted }
                };

                return pendingUpdatesLocal;
            });

            // чистим таймер если прилетела еще пачка изменений в payload
            setUpdateTimer((v) => {
                if (v) clearTimeout(v);
                return null;
            });

            if (viewOrTableName) {
                setUpdateTimer(
                    setTimeout(async () => {
                        // Собираем массив id измененных записей, исключая удаленные
                        const idsToUpdate = Object.keys(pendingUpdatesLocal).filter(
                            (id) => id !== 'deleted' && id !== 'undefined'
                        );

                        // Выполняем один запрос на обновление для всех id
                        const result = await supabaseClient
                            .from(viewOrTableName)
                            .select('*')
                            .filter('id', 'in', `(${idsToUpdate.join(',')})`);

                        // получаем массив всех данных
                        const allData: BaseTableRow[] = result.data ?? [];

                        // разбиваем массив по категориям для INSERT и UPDATE соответственно
                        // для INSERT
                        const insertData = allData.filter(
                            ({ id }) =>
                                pendingUpdatesLocal[String(id)] &&
                                pendingUpdatesLocal[String(id)].eventType === 'INSERT'
                        );

                        // для UPDATE (мапка для удобства)
                        const updateData = allData.reduce((acc, item) => {
                            if (
                                pendingUpdatesLocal[String(item.id)] &&
                                pendingUpdatesLocal[String(item.id)].eventType === 'UPDATE'
                            ) {
                                acc[item.id] = item;
                            }
                            return acc;
                        }, {} as BaseTableRow);

                        // Обновляем состояние данных
                        setData((prevData) => [
                            ...insertData, // INSERT
                            ...Object.values(updateData).filter(
                                ({ id }) => !prevData.find((item) => item.id === id)
                            ), // обрабатываем случай, когда данные создались (INSERT) и затем сразу обновились (UPDATE)
                            ...prevData
                                .filter((item) => !pendingUpdatesLocal.deleted[item.id]) // не показываем удаленные записи (DELETE)
                                .map((item) => updateData[item.id] || item) // учитываем UPDATE
                        ]);

                        // Очищаем ожидающие обновления и таймер
                        setPendingUpdates({ deleted: {} });
                        setUpdateTimer(null);
                    }, DELAY)
                );
            }
        },
        [viewOrTableName]
    );

    useEffect(() => {
        let channel: RealtimeChannel | null;
        // TODO: вернуть realtime когда понадобится
        // if (tableName && autoRefresh) {
        //     channel = supabaseClient
        //         .channel(channelName)
        //         .on(
        //             'postgres_changes',
        //             {
        //                 event: '*',
        //                 schema: 'public',
        //                 table: tableName
        //             },
        //             async (payload) => {
        //                 switch (payload.eventType) {
        //                     case 'INSERT': {
        //                         handlePayload(payload);
        //                         break;
        //                     }
        //                     case 'UPDATE': {
        //                         handlePayload(payload);
        //                         break;
        //                     }
        //                     case 'DELETE': {
        //                         handlePayload(payload);
        //                         break;
        //                     }
        //                     default: {
        //                         break;
        //                     }
        //                 }
        //             }
        //         )
        //         .subscribe((status, err) => {
        //             // console.log(`useSupabaseSubscription: status=${status} err=${err}`);
        //         });
        //     if (channel) {
        //         // console.log('useSupabaseSubscription: channel.subscribe()');
        //     } else {
        //         // console.error('useSupabaseSubscription: channel is null');
        //     }
        // }

        return () => {
            if (channel) {
                channel.unsubscribe();
                // console.log('useSupabaseSubscription: channel.unsubscribe()');
            }
            if (updateTimer) {
                clearTimeout(updateTimer);
            }
        };
    }, [autoRefresh, channelName, handlePayload, tableName, updateTimer]);
    return data;
};
